import { cn } from '~/lib/utils';
import { Icon } from '../Icon';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from './DropdownMenu';
import { Tooltip } from '../tooltip';

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
  disabledMessage?: string;
}

interface FilterButtonProps<T extends Option> {
  onChange: (value: T) => void;
  options: T[];
  value: T;
  buttonText: string;

  disabled?: boolean;
  grid?: boolean;
}

export function FilterButton<T extends Option>({
  disabled = false,
  buttonText,
  grid = false,
  options,
  value,
  onChange
}: FilterButtonProps<T>) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={disabled} asChild>
        <button
          className={cn(
            'flex space-x-1 rounded bg-gray-200 py-1 pl-2 pr-1 hover:bg-gray-300',
            disabled &&
              'cursor-not-allowed border-gray-200 bg-gray-200 text-gray-400 hover:bg-gray-200'
          )}
        >
          {buttonText}
          <Icon className="text-gray-500" name="SelectSingle" />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup grid={grid}>
          {options.map(option => (
            <DropdownMenuItem
              className={cn([
                grid && 'flex-shrink-0 flex-grow-0 basis-[20%] justify-center'
                //value.value == option.value && 'bg-blue-100'
              ])}
              key={option.value}
              disabled={option.disabled}
              onClick={() => onChange(option)}
            >
              <Tooltip
                content={option.disabledMessage}
                disabled={!option.disabled || !option.disabledMessage}
              >
                <div className="pointer-events-auto w-full">{option.label}</div>
              </Tooltip>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
