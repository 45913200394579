import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  FrequencyOption,
  FrequencyOptions,
  Label,
  RunAfterSync,
  SchedulePicker
} from '~/components';
import { Frequency, Schedule, ScheduleOption } from '~/generated/graphql';
import { SyncConfigFormValues } from '~/utils';
import { StageCard } from '.';

export function StageSchedule({
  syncId,
  setIsDirty,
  options
}: {
  syncId: string;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  options: ScheduleOption[];
}) {
  const { control, setValue } = useFormContext<SyncConfigFormValues>();

  const schedule = useWatch({ control, name: 'schedule' });
  const runAfterSyncs = useWatch({ control, name: 'runAfterSyncs' }) || [];

  const handleScheduleChange = (schedule: Schedule) => {
    setValue('schedule', schedule);
    setIsDirty(true);
  };

  const handleRunAfterSyncsChange = (syncs: RunAfterSync[]) => {
    setValue('runAfterSyncs', syncs);
    setIsDirty(true);
  };

  const scheduleOptions = React.useMemo(() => {
    const items = options?.map<FrequencyOption>(option => ({
      ...FrequencyOptions[option?.frequency],
      schema: option.configuration
    }));

    if (!items.find(item => item.value === FrequencyOptions.dbtcloud.value)) {
      items.push({
        ...FrequencyOptions.dbtcloud,
        isDisabled: true
      });
    }

    return items;
  }, [options]);

  return (
    <StageCard
      hasStickyHeader={
        schedule?.frequency !== Frequency.Manual && schedule?.frequency !== Frequency.Continuous
      }
      step={4}
      header="Set sync schedule"
      className="px-6"
    >
      <Label>Frequency</Label>
      <SchedulePicker
        options={scheduleOptions}
        syncId={syncId}
        schedule={schedule}
        onScheduleChange={handleScheduleChange}
        runAfterSyncs={runAfterSyncs}
        onRunAfterSyncsChange={handleRunAfterSyncsChange}
      />
    </StageCard>
  );
}
