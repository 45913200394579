import { BulkExecutionStatus, SchemaExecutionStatus } from '~/generated/graphql';
import { Status } from '~/components/v3';

export function ExecutionStatusDisplay({
  status,
  message
}: {
  status: BulkExecutionStatus | SchemaExecutionStatus;
  message?: string;
}) {
  switch (status) {
    case (BulkExecutionStatus.Created, SchemaExecutionStatus.Created):
      return <Status icon="DotsH">Starting</Status>;
    case BulkExecutionStatus.Running:
    case BulkExecutionStatus.Processing:
    case SchemaExecutionStatus.Running:
      return (
        <Status icon="ArrowRightFilled" classNames={{ icon: 'text-blue-500 ' }}>
          Running
        </Status>
      );
    case BulkExecutionStatus.Exporting:
    case SchemaExecutionStatus.Exporting:
      return <Status icon="DotsH">Exporting</Status>;
    case BulkExecutionStatus.Completed:
    case SchemaExecutionStatus.Completed:
      return (
        <Status icon="CheckFilled" classNames={{ icon: 'text-green-500 ' }}>
          Completed
        </Status>
      );
    case BulkExecutionStatus.Errors:
      return (
        <Status
          icon="WarningFilled"
          classNames={{ icon: 'text-amber-500 ' }}
          tooltipContent={message || 'Completed with errors'}
        >
          Completed
        </Status>
      );
    case BulkExecutionStatus.Failed:
      return (
        <Status
          icon="DangerFilled"
          classNames={{ icon: 'text-red-500' }}
          tooltipContent={message || 'Bulk sync failed'}
          interactive={true}
        >
          Failed
        </Status>
      );
    case SchemaExecutionStatus.Failed:
      return (
        <Status
          icon="DangerFilled"
          classNames={{ icon: 'text-red-500' }}
          tooltipContent={message || 'Sync failed'}
          interactive={true}
        >
          Failed
        </Status>
      );
    case (SchemaExecutionStatus.Canceled, BulkExecutionStatus.Canceled):
      return (
        <Status icon="CloseFilled" classNames={{ icon: 'text-gray-500' }}>
          Canceled
        </Status>
      );
    default:
      return <p className="capitalize text-gray-800">{status}</p>;
  }
}
