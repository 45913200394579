import { useVirtualizer } from '@tanstack/react-virtual';
import cx from 'clsx';
import * as React from 'react';
import { GroupTypeBase, MenuListComponentProps, OptionProps, OptionTypeBase } from 'react-select';
import { Icon, ModelField } from '~/components';
import { Truncator } from '../components';
import { CompletionValue, TargetFieldFragment } from '../generated/graphql';
import { toSelectable } from './common.util';
import { Selectable } from './custom-types.util';
import { isTargetField } from './predicates.util';
import { SyncFilterField } from './union-types.util';
import { capitalize, groupBy } from 'lodash';

export function formatOptionLabel(option: Selectable | CompletionValue) {
  return <p className="text-sm">{option.label || option.value}</p>;
}

export function formatOptionLabelCapitalize(option: Selectable | CompletionValue) {
  return <p className="text-sm">{capitalize(option.label || option.value)}</p>;
}

export const formatOptionLabelWithTruncation = (option: Selectable | CompletionValue) => (
  <Truncator content={String(option.label || option.value)}>
    <p className="truncate text-sm">{option.label || option.value}</p>
  </Truncator>
);

export function toGroupedModelOptions(options: ModelField[]) {
  const groups = groupBy(options.map(toSelectable), option => option.fieldset.id);
  const groupOptions = Object.keys(groups).map(key => ({
    id: key,
    label: groups[key][0].fieldset.name,
    options: groups[key]
  }));
  return groupOptions;
}

export function toGroupedSyncFilterOptions(options: SyncFilterField[]) {
  const groups = groupBy(options.map(toSelectable), option =>
    isTargetField(option) ? 'Target' : option.fieldset.name
  );
  const groupOptions = Object.keys(groups).map(key => ({
    label: key,
    options: groups[key]
  }));
  return groupOptions;
}

export function requiredAtTop(a: TargetFieldFragment, b: TargetFieldFragment) {
  // Required fields go at the top of the list
  if (a.required && b.required) {
    return a.name.localeCompare(b.name);
  } else if (a.required) {
    return -1;
  } else if (b.required) {
    return 1;
  }
  return a.name.localeCompare(b.name);
}

export function DropdownIndicator() {
  return (
    <div className="p-0">
      <Icon
        name="Select"
        className="mr-1.5 h-5 w-5 shrink-0 text-gray-800 opacity-70 group-hover:opacity-100"
      />
    </div>
  );
}

interface GroupedHeadingProps {
  label: string;
  optionsCount: number;
  className?: string;
  children?: React.ReactNode;
}

export function GroupedHeading(props: GroupedHeadingProps) {
  return (
    <div
      className={cx(
        'sticky top-0 z-10 flex cursor-default items-center space-x-1 border-b border-gray-300 bg-white py-1 px-3',
        props.className
      )}
    >
      <Truncator content={props.label}>
        <h4 className="hide-native-tooltip truncate text-sm font-medium uppercase text-gray-500">
          {props.label}
        </h4>
      </Truncator>
      <span className="inline-block min-w-[1] rounded bg-gray-200 px-1.5 pt-1 pb-0.75 text-center text-sm leading-none text-gray-500">
        {props.optionsCount}
      </span>
      {props.children}
    </div>
  );
}

type SelectChildren = React.ReactChild | React.ReactFragment | React.ReactPortal;

/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
export function WindowedMenuList<Type extends OptionTypeBase>({
  children,
  getStyles,
  ...props
}: MenuListComponentProps<Type, false>) {
  const items = React.useMemo(() => {
    const items = React.Children.toArray(children);
    const head = items[0];

    if (
      React.isValidElement<OptionProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>>(
        head
      )
    ) {
      const { props: { data: { options = [] } = {} } = {} } = head;
      const groupedChildrenLength = options.length;
      const isGrouped = groupedChildrenLength > 0;
      const flattenedChildren = isGrouped && flattenGroupedChildren(items);

      return isGrouped ? flattenedChildren : items;
    }
    return [];
  }, [children]) as SelectChildren[];

  const groupHeadingStyles = getStyles('groupHeading', props);
  const optionStyles = getStyles('option', props);
  const getHeight = createGetHeight({
    groupHeadingStyles,
    optionStyles
  });

  const heights = React.useMemo(() => items.map(getHeight), [items, getHeight]) as number[];
  const focusIndex = React.useMemo(() => getFocusIndex(items), [items]);
  const itemCount = items.length;
  const [measuredHeights, setMeasuredHeights] = React.useState<Record<number, number>>({});

  // calc menu height
  const {
    maxHeight,
    paddingBottom = 0,
    paddingTop = 0,
    ...menuListStyle
  } = getStyles('menuList', props) as {
    maxHeight: number;
    paddingBottom?: number;
    paddingTop?: number;
  };

  const widestOptionLength = React.useMemo(() => {
    let result = 0;
    for (const item of items) {
      // @ts-expect-error I don't have the types for this sorted out yet
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const isOption = item.key.includes('option');
      // @ts-expect-error I don't have the types for this sorted out yet
      if (isOption && result < item.props.label.length) {
        // @ts-expect-error I don't have the types for this sorted out yet
        result = item.props.label.length;
      }
    }
    return result * 8.25;
  }, [items]);

  const totalHeight = React.useMemo(() => {
    return heights.reduce((sum: number, height: number, idx: number) => {
      if (measuredHeights[idx]) {
        return sum + measuredHeights[idx];
      } else {
        return sum + height;
      }
    }, 0);
  }, [heights, measuredHeights]);

  const totalMenuHeight = totalHeight + paddingBottom + paddingTop;
  const menuHeight = Math.min(maxHeight, totalMenuHeight);
  const estimatedItemSize = Math.floor(totalHeight / itemCount);

  React.useEffect(() => {
    setMeasuredHeights({});
  }, [children]);

  // method to pass to inner item to set this items outer height
  function setMeasuredHeight(index: number, measuredHeight: number) {
    if (measuredHeights[index] !== undefined && measuredHeights[index] === measuredHeight) {
      return;
    }
    setMeasuredHeights(measuredHeights => ({
      ...measuredHeights,
      [index]: measuredHeight
    }));
  }

  const parentRef = React.useRef<HTMLDivElement>(null);
  const { getTotalSize, getVirtualItems, measureElement, scrollToIndex } = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => estimatedItemSize
  });

  React.useEffect(() => {
    // enables scrolling on key down arrow
    if (focusIndex >= 0 && parentRef.current !== null) {
      scrollToIndex(focusIndex);
    }
  }, [focusIndex, items, parentRef]);

  const currentWidth = props.selectProps.width || 320;
  const width = widestOptionLength > currentWidth ? widestOptionLength : '100%';

  return (
    <div
      ref={parentRef}
      className="w-full overflow-y-scroll"
      style={{
        ...menuListStyle,
        contain: 'strict',
        height: `${menuHeight}px`,
        width: `${width}px`
      }}
    >
      <div className="relative" style={{ height: `${getTotalSize()}px` }}>
        {getVirtualItems().map(item => (
          <div
            key={item.key}
            ref={measureElement}
            className="absolute left-0 top-0 w-full"
            style={{ transform: `translateY(${item.start}px)` }}
            data-index={item.index}
          >
            <MenuItem index={item.index} setMeasuredHeight={setMeasuredHeight}>
              {items[item.index]}
            </MenuItem>
          </div>
        ))}
      </div>
    </div>
  );
}

function MenuItem({
  children,
  index,
  setMeasuredHeight
}: {
  children: React.ReactNode;
  index: number;
  setMeasuredHeight: (index: number, measuredHeight: number) => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  // using useLayoutEffect prevents bounciness of options of re-renders
  React.useLayoutEffect(() => {
    if (ref.current) {
      setMeasuredHeight(index, ref.current.clientHeight);
    }
  });

  return (
    <div key={`option-${index}`} ref={ref}>
      {children}
    </div>
  );
}

function flattenGroupedChildren(children: any[]) {
  return children.reduce((result, child) => {
    const {
      props: { children: nestedChildren = [] }
    } = child;

    return [
      ...result,
      React.cloneElement(child as React.ReactElement, { type: 'group' }, []),
      ...nestedChildren
    ] as SelectChildren[];
  }, []) as SelectChildren[];
}

function isFocused({ props: { isFocused } }: any) {
  return isFocused === true;
}

function getFocusIndex(children: React.ReactNode[]) {
  return Math.max(children.findIndex(isFocused), 0);
}

function createGetHeight({
  groupHeadingStyles,
  optionStyles
}: {
  groupHeadingStyles: React.CSSProperties;
  optionStyles: React.CSSProperties;
}) {
  return function getHeight(child: any) {
    const {
      props: { type }
    } = child;

    if (type === 'group') {
      const { height = 30 } = groupHeadingStyles;
      return height;
    } else if (type === 'option') {
      const { height = 36 } = optionStyles;
      return height;
    } else {
      return 36;
    }
  };
}

/* eslint-disable @typescript-eslint/no-unsafe-return */
export const selectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isDisabled ? '#FAFAFA' : 'white',
    borderRadius: '0.25rem',
    boxShadow: state.isFocused
      ? '0 0 0 1px #6366F1'
      : state.isDisabled
        ? 'none'
        : 'inset 0 1px 1px 0 rgba(0,0,0,0.175)',
    border: state.isFocused
      ? '1px solid #6366F1'
      : state.isDisabled
        ? '1px solid #E4E4E7'
        : '1px solid #A1A1AA',
    padding: 0,
    fontSize: '0.875rem',
    height: '2rem',
    minHeight: '2rem',
    '&:hover': {
      border: state.isFocused ? '1px solid #6366F1' : '1px solid #A1A1AA'
    }
  }),
  input: (base: any, state: any) => ({
    ...base,
    fontSize: '0.875rem',
    height: '1.875rem',
    minHeight: '1.875rem',
    margin: 0,
    padding: '3px 7px 0',
    color: '#27272A',
    border: state.isFocused ? 'none' : 'none',
    boxShadow: state.isFocused ? 'none' : 'none'
  }),
  placeholder: (base: any) => ({
    ...base,
    paddingLeft: '5px',
    whiteSpace: 'nowrap'
  }),
  option: (base: any, state: any) => ({
    ...base,
    color: '#27272A',
    maxWidth: 650,
    zIndex: 0,
    backgroundColor:
      !state.isDisabled &&
      (state.isSelected && state.isFocused
        ? '#c7d2fe'
        : state.isSelected
          ? '#F0F0F2'
          : state.isFocused
            ? '#E0E7FF'
            : 'white'),
    '&:hover': {
      backgroundColor: !state.isDisabled
        ? state.isSelected && state.isFocused
          ? '#c7d2fe'
          : state.isSelected
            ? '#E4E4E7'
            : state.isFocused
              ? '#E0E7FF'
              : 'white'
        : '#F0F0F2'
    },
    '&:focus': {
      backgroundColor:
        !state.isDisabled &&
        (state.isSelected && state.isFocused
          ? '#c7d2fe'
          : state.isSelected
            ? '#E4E4E7'
            : state.isFocused
              ? '#E0E7FF'
              : 'white')
    },
    fontSize: '0.875rem'
  }),
  valueContainer: (base: any) => ({
    ...base,
    minHeight: '1.875rem',
    height: '1.875rem',
    padding: '0 5px'
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    minHeight: '1.875rem',
    height: '1.875rem'
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 0
  }),
  singleValue: (base: any) => ({
    ...base,
    fontSize: '0.875rem',
    padding: '0 5px',
    color: '#27272A'
  }),
  menu: (base: any) => ({
    ...base,
    overflow: 'hidden',
    width: 'max-content',
    minWidth: '100%',
    maxWidth: 650,
    zIndex: 60
  }),
  menuList: (base: any) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  group: (base: any) => ({
    ...base,
    padding: 0
  }),
  multiValue: (base: any) => ({
    ...base,
    height: '1.25rem',
    margin: 0,
    marginRight: 4,
    marginLeft: 4,
    backgroundColor: '#e4e4e7',
    borderRadius: '0.25rem'
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    fontSize: '0.75rem',
    color: '#52525b',
    padding: 0,
    paddingLeft: 4,
    borderRadius: 0
  }),
  multiValueRemove: () => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    opacity: '40%',
    paddingLeft: 4,
    paddingRight: 6,
    '&:hover': {
      opacity: '100%'
    }
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 100 })
};
/* eslint-disable @typescript-eslint/no-unsafe-return */
export const mappingSelectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: '#FAFAFA',
    borderRadius: '0.25rem',
    border: '1px solid #FAFAFA',
    padding: 0,
    fontSize: '0.875rem',
    height: '2rem',
    minHeight: '2rem',
    '&:hover': {
      backgroundColor: '#eef2ff'
    }
  }),
  input: (base: any) => ({
    ...base,
    fontSize: '0.875rem',
    height: '1.875rem',
    minHeight: '1.875rem',
    margin: 0,
    padding: '3px 7px 0',
    color: '#27272A',
    border: 'none',
    boxShadow: 'none'
  }),
  placeholder: (base: any) => ({
    ...base,
    paddingLeft: '5px',
    whiteSpace: 'nowrap'
  }),
  option: (base: any, state: any) => ({
    ...base,
    color: '#27272A',
    maxWidth: 650,
    zIndex: 0,
    backgroundColor:
      !state.isDisabled &&
      (state.isSelected && state.isFocused
        ? '#c7d2fe'
        : state.isSelected
          ? '#F0F0F2'
          : state.isFocused
            ? '#E0E7FF'
            : 'white'),
    '&:hover': {
      backgroundColor: !state.isDisabled
        ? state.isSelected && state.isFocused
          ? '#c7d2fe'
          : state.isSelected
            ? '#E4E4E7'
            : state.isFocused
              ? '#E0E7FF'
              : 'white'
        : '#F0F0F2'
    },
    '&:focus': {
      backgroundColor:
        !state.isDisabled &&
        (state.isSelected && state.isFocused
          ? '#c7d2fe'
          : state.isSelected
            ? '#E4E4E7'
            : state.isFocused
              ? '#E0E7FF'
              : 'white')
    },
    fontSize: '0.875rem'
  }),
  valueContainer: (base: any) => ({
    ...base,
    minHeight: '1.875rem',
    height: '1.875rem',
    padding: '0 5px'
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    minHeight: '1.875rem',
    height: '1.875rem'
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 0
  }),
  singleValue: (base: any) => ({
    ...base,
    fontSize: '0.875rem',
    padding: '0 5px',
    color: '#27272A'
  }),
  menu: (base: any) => ({
    ...base,
    overflow: 'hidden',
    width: 'max-content',
    minWidth: '100%',
    maxWidth: 650,
    zIndex: 60
  }),
  menuList: (base: any) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  group: (base: any) => ({
    ...base,
    padding: 0
  }),
  multiValue: (base: any) => ({
    ...base,
    height: '1.25rem',
    margin: 0,
    marginRight: 4,
    marginLeft: 4,
    backgroundColor: '#e4e4e7',
    borderRadius: '0.25rem'
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    fontSize: '0.75rem',
    color: '#52525b',
    padding: 0,
    paddingLeft: 4,
    borderRadius: 0
  }),
  multiValueRemove: () => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    opacity: '40%',
    paddingLeft: 4,
    paddingRight: 6,
    '&:hover': {
      opacity: '100%'
    }
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 100 })
};
