import { useState, useEffect, useLayoutEffect, SetStateAction } from 'react';
import { SqlRunnerResult } from '~/generated/graphql';
import { LocalConnection } from '~/utils';
import * as Sentry from '@sentry/react';

export enum QueryPersistanceKey {
  Connections = 'PT-QueryRunner-Connections',
  Connection = 'PT-QueryRunner-Connection',
  LastQuery = 'PT-QueryRunner-LastQuery',
  QueryResult = 'PT-QueryRunner-QueryResult'
}

export const useQueryPersistance = () => {
  const [connections, setConnections] = useState<LocalConnection[]>([]);
  const [connection, setConnection] = useState<LocalConnection>(null);
  const [lastQuery, setLastQuery] = useState<string>('');
  const [queryResult, setQueryResult] = useState<SqlRunnerResult>(null);

  const LOAD_ERR_MSG = (key: QueryPersistanceKey) =>
    `QueryRunnerPersistance - Could not load ${key} from session storage`;
  const SAVE_ERR_MSG = (key: QueryPersistanceKey) =>
    `QueryRunnerPersistance - Could not save ${key} to session storage`;

  function loadItemFromStorage<T>(
    key: QueryPersistanceKey,
    dispatch: React.Dispatch<SetStateAction<T>>
  ) {
    try {
      const item = sessionStorage.getItem(key);
      if (item && item !== null && item !== 'undefined') {
        const parsed = JSON.parse(item);
        dispatch(parsed);
      }
    } catch (e) {
      Sentry.captureException(e, { extra: { key, message: LOAD_ERR_MSG(key) } });
    }
  }

  function saveItemToStorage(key: QueryPersistanceKey, item: any) {
    try {
      sessionStorage.setItem(key, JSON.stringify(item));
    } catch (e) {
      Sentry.captureException(e, { extra: { key, item, message: SAVE_ERR_MSG(key) } });
    }
  }

  function clearAllItemsFromStorage() {
    try {
      sessionStorage.removeItem(QueryPersistanceKey.Connections);
      sessionStorage.removeItem(QueryPersistanceKey.Connection);
      sessionStorage.removeItem(QueryPersistanceKey.LastQuery);
      sessionStorage.removeItem(QueryPersistanceKey.QueryResult);
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          message: 'QueryRunnerPersistance - Could not clear all items from session storage'
        }
      });
    }
  }

  // if any of the states change, save to session storage
  useEffect(() => {
    saveItemToStorage(QueryPersistanceKey.Connections, connections);
    saveItemToStorage(QueryPersistanceKey.Connection, connection);
    saveItemToStorage(QueryPersistanceKey.LastQuery, lastQuery);
    saveItemToStorage(QueryPersistanceKey.QueryResult, queryResult);
  }, [connections, connection, lastQuery, queryResult]);

  // on mount, check if there is anything in session storage
  useLayoutEffect(() => {
    loadItemFromStorage<LocalConnection[]>(QueryPersistanceKey.Connections, setConnections);
    loadItemFromStorage<LocalConnection>(QueryPersistanceKey.Connection, setConnection);
    loadItemFromStorage<string>(QueryPersistanceKey.LastQuery, setLastQuery);
    loadItemFromStorage<SqlRunnerResult>(QueryPersistanceKey.QueryResult, setQueryResult);
  }, []);

  return {
    connections,
    setConnections,
    connection,
    setConnection,
    lastQuery,
    setLastQuery,
    queryResult,
    setQueryResult,
    clearAllItemsFromStorage
  };
};
