import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { InlineMessage, MyCombobox, SideBySide, Tooltip } from '~/components';
import { Icon } from '~/components';
import {
  GetSettingsSyncEventLoggingDocument,
  SetEventTargetConnectionDocument
} from '~/generated/graphql';
import { useBannerDispatch } from '~/hooks';

interface Option {
  isDefault?: boolean;
  label: string;
  type?: string;
  value: string;
}

const DefaultOptions: Option[] = [
  { label: 'None', value: 'none' },
  {
    label: 'Datadog',
    isDefault: true,
    type: 'datadog',
    value: 'datadog'
  },
  {
    label: 'Honeycomb',
    isDefault: true,
    type: 'honeycomb',
    value: 'honeycomb'
  }
];

export default function SyncEventLogging() {
  const dispatchBanner = useBannerDispatch();
  const { data, loading } = useQuery(GetSettingsSyncEventLoggingDocument, {
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    fetchPolicy: 'network-only'
  });
  const [setEventTarget] = useMutation(SetEventTargetConnectionDocument);
  const [isSaved, setIsSaved] = useState<boolean>();

  const handleChange = async (option: Option) => {
    await setEventTarget({
      variables: {
        enabled: option.value !== 'none',
        connID: option.value === 'none' ? undefined : option.value
      }
    });
    setIsSaved(true);
  };

  const options = loading
    ? []
    : DefaultOptions.flatMap<Option>(option => {
        const connected = (data?.connections ?? [])
          .filter(connection => option.type === connection.type.id)
          .map(connection => ({
            label: connection.name,
            type: connection.type.id,
            value: connection.id
          }));

        if (connected.length === 0) {
          return option;
        }
        return connected;
      });

  const value = loading
    ? undefined
    : options.find(option => option.value === (data?.loggingTarget.connection?.id || 'none'));

  if (loading) return null;

  return (
    <SideBySide hasSectionWrap heading="Log sync events to">
      <div className="flex items-center space-x-2">
        <MyCombobox
          className="w-[21rem]"
          defaultValue={value}
          formatOptionLabel={option =>
            option.isDefault ? (
              <Tooltip content={`Create a Polytomic connection to ${option.label} to enable`}>
                <span className="flex space-x-2">
                  {option.type && (
                    <Icon match={option.type} className="h-5 w-5 text-gray-500 opacity-60" />
                  )}
                  <span className="text-gray-500">{option.label}</span>
                </span>
              </Tooltip>
            ) : (
              <span className="flex space-x-2">
                {option.type && <Icon match={option.type} className="h-5 w-5 text-gray-500" />}
                <span>{option.label}</span>
              </span>
            )
          }
          options={options}
          isOptionDisabled={option => option.isDefault === true}
          onChange={handleChange}
        />
        <InlineMessage show={isSaved} autoDismiss={() => setIsSaved(false)} text="Saved" />
      </div>
    </SideBySide>
  );
}
