import * as React from 'react';
import clsx from 'clsx';

import { TableTopper, TableWrap } from '.';

interface Props {
  children: React.ReactNode;
  rightSlot?: React.ReactNode;
  hidden?: boolean;
  heading: string;
  className?: string;
}

export const TopperWrapper = React.memo<Props>(props => {
  return (
    <TableWrap className={clsx(props.hidden && 'hidden', props.className)}>
      <TableTopper className="h-16 justify-between px-4">
        {props.heading}
        <div className="flex items-center space-x-2">{props.rightSlot}</div>
      </TableTopper>
      {props.children}
    </TableWrap>
  );
});

if (import.meta.env.MODE === 'development') {
  TopperWrapper.displayName = 'TopperWrapper';
}
