import * as React from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import { tv } from 'tailwind-variants';

import { cn, getClassNames, type VariantProps } from '~/lib/utils';
import { NumberSize, Resizable } from 're-resizable';
import { ResizeHandleH } from './Icons';
import { useState } from 'react';

const MIN_HEIGHT = 224;
const DEFAULT_HEIGHT = 500;

const Sheet = SheetPrimitive.Root;

const SheetPortal = ({ className, ...props }: SheetPrimitive.DialogPortalProps) => (
  <SheetPrimitive.Portal className={cn(className)} {...props} />
);
SheetPortal.displayName = SheetPrimitive.Portal.displayName;

const sheetVariants = tv({
  slots: {
    content: cn([
      'absolute z-90 gap-4 rounded-t-lg border border-gray-300 bg-gray-100 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-200 data-[state=open]:duration-200',
      'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom' // bottom sheet
    ]),
    header: 'flex items-center justify-between border-b p-4'
  }
});

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {
  header?: React.ReactNode;
  initialFocusRef?: React.RefObject<HTMLElement>;
  storageKey: string;
  container?: HTMLElement;
}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(({ header, initialFocusRef, storageKey, className, children, container, ...props }, ref) => {
  const classNames = getClassNames(sheetVariants, props);

  const [height, setHeight] = useState(() => {
    const stored = localStorage.getItem(storageKey);
    return stored ? Math.max(parseInt(stored), MIN_HEIGHT) : DEFAULT_HEIGHT;
  });

  const onResizeStop = (_e, _direction, _ref, d: NumberSize) => {
    const newHeight = height + d.height;
    setHeight(newHeight);
    if (newHeight > MIN_HEIGHT) {
      localStorage.setItem(storageKey, newHeight.toString());
    }
  };

  const onOpenAutoFocus = (e: Event) => {
    if (initialFocusRef) {
      e.preventDefault();
      initialFocusRef?.current?.focus();
    }
  };

  return (
    <SheetPortal container={container}>
      <SheetPrimitive.Content
        ref={ref}
        className={cn(classNames.content, className)}
        onOpenAutoFocus={onOpenAutoFocus}
        {...props}
      >
        <Resizable
          handleClasses={{
            right: 'pointer-events-none',
            bottom: 'pointer-events-none',
            left: 'pointer-events-none',
            topRight: 'pointer-events-none',
            bottomRight: 'pointer-events-none',
            bottomLeft: 'pointer-events-none',
            topLeft: 'pointer-events-none',
            top: `
                !w-[initial] flex flex-row items-center justify-center
                bg-transparent hover:bg-gray-200 active:bg-gray-300 transition
                absolute !top-[0.3rem] !right-4 !left-4 text-gray-400 hover:text-gray-500 active:text-gray-600
                rounded !h-[5px]
              `
          }}
          handleComponent={{ top: <ResizeHandleH className="h-6 w-6" /> }}
          maxHeight="75vh"
          minWidth="100%"
          minHeight={MIN_HEIGHT}
          size={{ height, width: '100%' }}
          onResizeStop={onResizeStop}
        >
          <div className="flex h-full w-full flex-col" ref={ref}>
            {header && <div className={classNames.header}>{header}</div>}
            <div className="flex-1 overflow-auto p-4">{children}</div>
          </div>
        </Resizable>
      </SheetPrimitive.Content>
    </SheetPortal>
  );
});
SheetContent.displayName = SheetPrimitive.Content.displayName;

export { Sheet, SheetPortal, SheetContent };
