import * as React from 'react';

export function createUseCtx<CtxType>(ctx: React.Context<CtxType>) {
  return function useCtx() {
    const res = React.useContext(ctx);
    // check for undefined as we might want to allow null or false or 0 values explicitly
    // ^^ this seems good for now but isn't set in stone
    if (res === undefined) {
      throw new Error("useContext hook not inside Context.Provider or has 'value: undefined'");
    }
    return res;
  };
}
