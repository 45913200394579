import { ActivityLogFragment, StringObj } from '~/generated/graphql';

export type LineDiff = {
  lineNumber: number;
  newlineContent: string;
  oldlineContent: string;
};

export type SqlDiff = {
  oldSql: string[];
  newSql: string[];
  lineDiffs: LineDiff[];
  linesAdded?: number;
  linesRemoved?: number;
};

export const buildDiff = (sqlLog: ActivityLogFragment) => {
  const splitByNewLine = (s: StringObj) => s.str.split('\n');

  if (!sqlLog?.old || !sqlLog?.new) return null;
  const oldSql = splitByNewLine(sqlLog.old as StringObj);
  const newSql = splitByNewLine(sqlLog.new as StringObj);

  const lineDiffs: LineDiff[] = [];

  const max = Math.max(oldSql.length, newSql.length);

  /* Calculate linesAdded and linesRemoved */
  let linesAdded = 0;
  let linesRemoved = 0;

  for (let i = 0; i < max; i++) {
    const oldLine = oldSql[i];
    const newLine = newSql[i];
    lineDiffs.push({
      lineNumber: i + 1,
      newlineContent: newLine || null,
      oldlineContent: oldLine || null
    });
    if (!oldLine && newLine) {
      linesAdded++;
    } else if (oldLine && !newLine) {
      linesRemoved++;
    } else if (!!oldLine && !!newLine && oldLine !== newLine) {
      linesAdded++;
      linesRemoved++;
    }
  }

  return {
    oldSql,
    newSql,
    linesAdded,
    linesRemoved,
    lineDiffs
  };
};
