import clsx from 'clsx';
import { HTMLAttributes, ReactNode } from 'react';

export type TableHeaderProps = {
  children: ReactNode;
  enableStickyHeader?: boolean;
} & HTMLAttributes<HTMLTableSectionElement>;

const TableHeader = ({ children, enableStickyHeader, className, ...rest }: TableHeaderProps) => {
  const theadClassName = clsx(className, enableStickyHeader && 'sticky top-0 z-10 bg-gray-50');
  return (
    <thead className={theadClassName} {...rest}>
      {children}
    </thead>
  );
};

export default TableHeader;
