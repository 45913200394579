import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { isEmpty } from 'lodash';
import { JSONSchemaForm, Section, SideBySide } from '~/components';
import { SchemaForm } from '~/components/common/SchemaForm';
import { useFieldsetState } from '~/hooks';
import { FieldsetFormValues } from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { FieldsTable } from '../components';

export function DefaultFieldsetConfig() {
  const { fieldset, loading, refreshing, applyUpdate } = useFieldsetState();
  const { control, getValues } = useFormContext<FieldsetFormValues>();

  const configuration = useWatch({ control, name: 'configuration' });

  const handleRefresh = React.useCallback(() => {
    applyUpdate({ ...getValues('configuration') }, { refresh: true });
  }, [applyUpdate, getValues]);

  const onChange = React.useCallback(
    (config: Record<string, unknown>) => {
      applyUpdate(config);
    },
    [applyUpdate]
  );

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          {fieldset.configurationForm && (
            <div className="w-full max-w-xs animate-fadeIn space-y-4">
              <JSONSchemaForm
                formData={configuration || fieldset.configuration}
                schema={fieldset?.configurationForm?.jsonschema}
                uiSchema={fieldset?.configurationForm?.uischema}
                onChange={onChange}
                refreshForm={() => {
                  applyUpdate({ ...getValues('configuration') }, { refreshConfiguration: true });
                }}
                loading={loading}
              />
            </div>
          )}
          {fieldset?.configurationSchema && !fieldset.configurationForm && (
            <div className="w-full max-w-xs animate-fadeIn space-y-4">
              <SchemaForm
                schema={fieldset.configurationSchema}
                values={configuration || fieldset.configuration || {}}
                onChange={onChange}
              />
            </div>
          )}
        </SideBySide>
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || (loading && isEmpty(fieldset?.fields))}
          disabled={loading}
          refresh={handleRefresh}
          hasWriteinFields={fieldset?.properties.writeinFields}
        />
      </Section>
      <AdditionalConfig />
    </>
  );
}
