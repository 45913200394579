import { useQuery } from '@apollo/client';
import { groupBy, map } from 'lodash';
import { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Icon, Permission, TableWrap } from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import EmptyPage from '~/components/v2/templates/EmptyPage';
import {
  ConnectionsDocument,
  ModelDocument,
  Operation,
  PeekSyncsDocument,
  ResourceType
} from '~/generated/graphql';
import { useBannerDispatch } from '~/hooks';
import { SyncRow } from '~/pages/syncs/sync-row';
import { PRIMARY_LINK_BTN_STYLES, filterConnections, hasItems, routes } from '~/utils';

const Syncs = () => {
  const history = useHistory();
  const dispatchBanner = useBannerDispatch();
  const { data: connectionsData, loading: connectionsLoading } = useQuery(ConnectionsDocument);
  const hasConnection =
    connectionsData?.connections && hasItems(filterConnections(connectionsData, Operation.Target));

  const { data: modelData, loading: modelLoading } = useQuery(ModelDocument);
  const hasFieldset = modelData?.model.fieldsets && hasItems(modelData.model.fieldsets);

  const { data, loading } = useQuery(PeekSyncsDocument, {
    errorPolicy: 'all',
    onError: error =>
      dispatchBanner({
        type: 'show',
        payload: {
          message: error,
          disableDismiss: true,
          wrapper: 'px-3 pt-3 max-w-3xl mx-auto'
        }
      })
  });

  const recentSyncs = useMemo(() => {
    return data && hasItems(data.peekSyncs) && data.peekSyncs?.length >= 7
      ? data.peekSyncs
          .filter(s => !!s.ViewedAt)
          .sort((a, b) => a.ViewedAt.localeCompare(b.ViewedAt))
          .reverse()
          .filter((_item, i) => i < 3)
      : [];
  }, [data]);

  const syncs = useMemo(() => {
    const groups =
      data && hasItems(data.peekSyncs)
        ? map(
            groupBy(data.peekSyncs, sync => (sync.Active ? sync.TargetConnectionID : 'Disabled')),
            (syncs, id) => ({
              id,
              name: id === 'Disabled' ? 'Disabled' : syncs[0].TargetConnectionName || '',
              logo: id === 'Disabled' ? 'disabled' : syncs[0].TargetConnectionType || '',
              syncs: syncs.sort((a, b) => a.TargetObjectName.localeCompare(b.TargetObjectName))
            })
          )
        : [];
    const disabledGroup = groups.find(group => group.id === 'Disabled');
    return [
      ...groups
        .filter(group => group.id !== 'Disabled')
        .sort((a, b) => a.name.localeCompare(b.name)),
      disabledGroup
    ].filter(Boolean);
  }, [data]);

  useQuery(PeekSyncsDocument, {
    onCompleted: () => dispatchBanner({ type: 'hide' }),
    pollInterval: 5000,
    fetchPolicy: 'network-only'
  });

  const isLoading = connectionsLoading || modelLoading || loading;

  if (!isLoading && !hasItems(syncs) && !hasConnection) {
    return (
      <EmptyPage
        topNavHeading="Syncs"
        message="To create a sync, you must have at least one destination connection defined."
      >
        <div className="flex flex-col items-start space-y-3">
          <Link className={PRIMARY_LINK_BTN_STYLES} to={routes.connectionsPicker}>
            Add connections
          </Link>
        </div>
      </EmptyPage>
    );
  }

  if (!isLoading && !hasItems(syncs) && !hasFieldset) {
    return (
      <EmptyPage
        topNavHeading="Syncs"
        message="To create a sync, you must have at least one model defined."
      >
        <div className="flex flex-col items-start space-y-3">
          <Link className={PRIMARY_LINK_BTN_STYLES} to={routes.createModel}>
            Add model
          </Link>
        </div>
      </EmptyPage>
    );
  }

  return (
    <PageLayout
      topNavHeading="Syncs"
      topNavActions={
        <Permission type={ResourceType.Sync}>
          <Button theme="outline" onClick={() => history.push(routes.createSync)}>
            Create sync
          </Button>
        </Permission>
      }
      loading={isLoading}
    >
      {!!recentSyncs?.length && (
        <div className="space-y-8 p-6">
          <div>
            <div className="mb-4 ml-4 inline-flex w-full items-center">
              <Icon
                name="Clock"
                className="mr-2.5 h-7 w-auto rounded-full bg-blue-500 p-1 text-white"
              />
              <h3 className="text-lg text-gray-800">Recently viewed</h3>
            </div>
            <TableWrap className="grid max-w-[84rem] grid-cols-[repeat(2,minmax(7rem,35rem)),repeat(2,8rem)] overflow-x-auto">
              {['Name', 'Definition', 'Schedule', 'Status'].map(heading => (
                <span key={heading} className="thead-label inline-flex items-center bg-gray-50">
                  {heading}
                </span>
              ))}

              {recentSyncs.map(sync => (
                <SyncRow key={sync.id} sync={sync} />
              ))}
            </TableWrap>
          </div>
        </div>
      )}
      {syncs.length === 0 ? (
        <section className="mx-auto mt-16 w-full max-w-lg px-6">
          <Icon name="InfoCircle" className="h-9 w-9 text-gray-400" />
          <p className="mt-2 mb-3 text-sm">
            Click <strong>Create sync</strong> in the top-right to set up a sync.
          </p>
        </section>
      ) : (
        <div className="space-y-8 p-6">
          {syncs.map(group => (
            <div key={group.id}>
              <div className="mb-4 ml-4 inline-flex w-full items-center">
                <Icon match={group.logo} className="mr-2.5 h-7 w-auto text-gray-400" />
                <h3 className="text-lg text-gray-800">{group.name}</h3>
              </div>
              <TableWrap className="grid max-w-[84rem] grid-cols-[repeat(2,minmax(7rem,34rem)),9rem,9rem] overflow-x-auto">
                {['Name', 'Definition', 'Schedule', 'Status'].map(heading => (
                  <span key={heading} className="thead-label inline-flex items-center bg-gray-50">
                    {heading}
                  </span>
                ))}

                {group.syncs.map(sync => (
                  <SyncRow key={sync.id} sync={sync} />
                ))}
              </TableWrap>
            </div>
          ))}
        </div>
      )}
    </PageLayout>
  );
};

export default Syncs;
