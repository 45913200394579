import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Button,
  DisabledSelect,
  EditPermission,
  FieldDetail,
  Icon,
  ModelFieldSelect,
  SideBySide,
  Tooltip
} from '~/components';
import { ModelField, useFieldsetState, useModelFields } from '~/hooks';
import { FieldsetFormValues, WINDOW_THRESHOLD, modelFieldToSelectable } from '~/utils';

export function JoinToModel() {
  const { fieldset } = useFieldsetState();
  const { control, resetField } = useFormContext<FieldsetFormValues>();
  const from = useWatch({ control, name: 'relatedTo.0.from' });
  const to = useWatch({ control, name: 'relatedTo.0.to' });

  const { modelFields: allFields } = useModelFields();
  const modelFields = allFields
    ?.filter(f => f?.fieldset?.id !== fieldset?.id)
    .filter(f => !(f as ModelField).enrichmentFieldset);

  function handleResetJoins() {
    resetField('relatedTo.0.from', { defaultValue: null, keepDirty: true });
    resetField('relatedTo.0.to', { defaultValue: null, keepDirty: true });
  }

  if (!fieldset || modelFields.length === 0) {
    return null;
  }

  return (
    <SideBySide
      hasSectionWrap
      heading={
        <div>
          <div className="flex items-center space-x-1">
            <h3 className="sbs-heading">Join to other models</h3>

            <Tooltip content="Joining lets you sync from multiple models in one go">
              <Icon className="hover:text-blue-400" name="InfoFilled" size="sm" variant="info" />
            </Tooltip>
          </div>
          <p className="text-sm font-normal text-gray-500">(Optional)</p>
        </div>
      }
      maxWidth="max-w-xs"
    >
      <Controller
        control={control}
        name="relatedTo.0.from"
        render={({ field }) => (
          <EditPermission fallback={<DisabledSelect valueLabel={field?.value?.label} />}>
            <ModelFieldSelect
              options={fieldset?.fields}
              placeholder="Select current model field..."
              value={field?.value ? modelFieldToSelectable(field?.value) : null}
              hideGroups
              hideConnections
              onChange={field?.onChange}
              isWindowed={fieldset?.fields?.length > WINDOW_THRESHOLD}
            />
          </EditPermission>
        )}
      />
      <p className="my-2 text-sm text-gray-500">
        joins to <strong className="font-bold">↓</strong>
      </p>
      <Controller
        control={control}
        name="relatedTo.0.to"
        render={({ field }) => (
          <>
            <EditPermission fallback={<DisabledSelect valueLabel={field?.value?.label} />}>
              <ModelFieldSelect
                options={modelFields}
                placeholder="Select other model field..."
                value={from && field?.value ? modelFieldToSelectable(field?.value) : null}
                onChange={field?.onChange}
                isWindowed={modelFields.length > WINDOW_THRESHOLD}
                showSourceName
              />
            </EditPermission>
            {from && field?.value && (
              <FieldDetail
                defaultStyles="mt-0.5 py-2 px-3.25 bg-gray-200"
                className="mt-1"
                logoId={field?.value?.fieldset?.connection?.type?.id}
                modelName={field?.value?.fieldset?.name}
              />
            )}
          </>
        )}
      />
      {from && to && (
        <EditPermission>
          <Button className="mt-2" onClick={handleResetJoins}>
            Clear join
          </Button>
        </EditPermission>
      )}
    </SideBySide>
  );
}
