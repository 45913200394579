import Tippy, { TippyProps } from '@tippyjs/react/headless';
import cx from 'clsx';
import * as React from 'react';

type Props = {
  content: React.ReactNode;
} & TippyProps;

export const MyPopover = ({ className, content, children, ...props }: Props) => {
  return (
    <Tippy
      interactive={true}
      {...props}
      render={attrs => (
        <div
          {...attrs}
          className={cx(
            'inline-flex animate-fadeIn flex-col divide-y divide-gray-300 rounded border border-gray-300 bg-white shadow-lg focus:outline-none',
            className
          )}
        >
          {content}
        </div>
      )}
    >
      {children}
    </Tippy>
  );
};
