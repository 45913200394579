import { ApolloError } from '@apollo/client';

import { createSplitReducerCtx } from '../factories';
import { BannerState } from '../utils';

type BannerActions = { type: 'show'; payload: Omit<BannerState, 'show'> } | { type: 'hide' };

const defaultState: BannerState = { show: false, message: undefined };

function bannerReducer(prev: BannerState, action: BannerActions) {
  switch (action.type) {
    case 'show':
      // merge the graphQLErrors together to avoid "last one wins"
      if (prev.message != null && action.payload.message != null) {
        if (
          typeof prev.message === 'object' &&
          'graphQLErrors' in prev.message &&
          typeof action.payload.message === 'object' &&
          'graphQLErrors' in action.payload.message
        ) {
          action.payload.message = {
            graphQLErrors: [...prev.message.graphQLErrors, ...action.payload.message.graphQLErrors]
          } as unknown as ApolloError;
        }
      }
      return { ...action.payload, show: true };
    case 'hide':
      return defaultState;
    default:
      return prev;
  }
}

export const {
  useCtxState: useBannerState,
  useCtxDispatch: useBannerDispatch,
  Provider: BannerProvider
} = createSplitReducerCtx(bannerReducer, defaultState);
