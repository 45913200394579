import React from 'react';
import Divider from '../../display/Divider';

type ActivityLogTimeLineDividerProps = {
  children: React.ReactNode;
};

const ActivityLogTimeLineDivider = ({ children }: ActivityLogTimeLineDividerProps) => {
  return (
    <li className="relative -top-1 mb-3 w-full">
      <span className="absolute top-1.5 -left-[13px] h-2 w-2 rounded-full bg-blue-500 ring-2 ring-white" />
      <div className="relative">
        <Divider textAlign="left" light dense>
          {children}
        </Divider>
      </div>
    </li>
  );
};

export default ActivityLogTimeLineDivider;
