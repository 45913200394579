import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox, EditPermission, MyInput, SideBySide, FormFileUpload } from '../../../components';

import { ConnectionTypeFragment } from '../../../generated/graphql';
import { getSchemaAsList, getSchemaNormalized } from '../../../utils';
import { ConnectionFormValues } from '../connection-config';

interface Props {
  connectionType: ConnectionTypeFragment;
}

export function SnowflakeConnectionConfig(props: Props) {
  const { register, formState, getValues, setValue } = useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  const schema = getSchemaNormalized(props.connectionType.configurationSchema);
  const schemaList = getSchemaAsList(props.connectionType.configurationSchema, 'configuration');
  const [checked, setChecked] = useState(false);
  const handleClick = () => {
    setChecked(!checked);
    if (checked) {
      setValue('configuration.private_key', null);
      setValue('configuration.private_key_passphrase', null);
    }
  };

  useEffect(() => {
    if (getValues('configuration.key_pair_auth')) {
      setChecked(Boolean(getValues('configuration.key_pair_auth')));
    }
  }, [getValues('configuration.key_pair_auth')]);

  return (
    <>
      <SideBySide hasSectionWrap maxWidth="max-w-xs" styles="space-y-3" heading="Options">
        <div>
          <EditPermission>
            <MyInput
              {...register('configuration.account', {
                required: `${schema?.account.title || 'Field'} is required`
              })}
              label={schema?.account.title}
              description={schema?.account.description}
              errors={errors}
            />
          </EditPermission>
        </div>
        <div>
          <EditPermission>
            <MyInput
              {...register('configuration.username', {
                required: `${schema?.username.title || 'Field'} is required`
              })}
              label={schema?.username.title}
              description={schema?.username.description}
              errors={errors}
            />
          </EditPermission>
        </div>
        <div>
          <EditPermission>
            <MyInput
              {...register('configuration.password', {})}
              type="password"
              label={schema?.password.title}
              description={schema?.password.description}
              errors={errors}
            />
          </EditPermission>
        </div>
        <div>
          <EditPermission>
            <MyInput
              {...register('configuration.dbname', {
                required: `${schema?.dbname.title || 'Field'} is required`
              })}
              label={schema?.dbname.title}
              description={schema?.dbname.description}
              errors={errors}
            />
          </EditPermission>
        </div>
        <div>
          <EditPermission>
            <Checkbox
              {...register('configuration.key_pair_auth', {})}
              label={schema?.key_pair_auth.title}
              description={schema?.key_pair_auth.description}
              errors={errors}
              onChange={handleClick}
              checked={checked}
            />
          </EditPermission>
        </div>
        <div>
          {checked && (
            <div className="space-y-3">
              <EditPermission>
                <FormFileUpload
                  item={schemaList.find(s => s.name === 'configuration.private_key')}
                />
              </EditPermission>
              <div>
                <EditPermission>
                  <MyInput
                    {...register('configuration.private_key_passphrase', {})}
                    label={schema?.private_key_passphrase.title}
                    description={schema?.private_key_passphrase.description}
                    errors={errors}
                    type="password"
                  />
                </EditPermission>
              </div>
            </div>
          )}
        </div>
      </SideBySide>
      <SideBySide hasSectionWrap maxWidth="max-w-xs" styles="space-y-3" heading="Optional Settings">
        <div>
          <EditPermission>
            <MyInput
              {...register('configuration.warehouse')}
              label={schema?.warehouse.title}
              description={schema?.warehouse.description}
            />
          </EditPermission>
        </div>
        <div>
          <EditPermission>
            <MyInput
              {...register('configuration.params')}
              label={schema?.params.title}
              description={schema?.params.description}
            />
          </EditPermission>
        </div>
      </SideBySide>
    </>
  );
}
