import cx from 'clsx';
import * as React from 'react';

import { BannerStatic } from './banner';

interface Props {
  message?: string;
  wrapper?: string;
}

export function LacksPermissionBanner({ message = 'You do not have permission.', wrapper }: Props) {
  return (
    <div className={cx('sticky top-0 left-0 z-[60]', wrapper)}>
      <BannerStatic theme="info" className="max-h-[7.25rem] break-words">
        <p className="text-sm">{`${message} Contact your Polytomic admin for details.`}</p>
      </BannerStatic>
    </div>
  );
}
