import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { type VariantProps as _VariantProps } from 'tailwind-variants';
import { mapValues } from 'lodash';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export type VariantProps<T extends (...args: any) => any> = _VariantProps<T> & {
  classNames?: Partial<{ [key in keyof ReturnType<T>]: string }>;
};

export function getClassNames<T extends (...args: any) => any>(tv: T, props: VariantProps<T>) {
  const { classNames, ...rest } = props;

  const slots = tv(rest);

  return mapValues(slots, (slot, k) => slot({ className: classNames?.[k] ?? '' })) as {
    [key in keyof ReturnType<T>]: string;
  };
}
