import { Reference, useMutation } from '@apollo/client';
import * as React from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';

import {
  AccessControlSolo,
  AccessControlWrap,
  EditPermission,
  FeatureFlag,
  LacksPermissionBanner,
  LastModified,
  Icon,
  MyInput,
  SideBySide
} from '~/components';
import ConfigTriggerDialog from '~/components/config-trigger-dialog';
import Divider from '~/components/v2/display/Divider';
import {
  CloneSyncDocument,
  CompletionValue,
  DeleteSyncDocument,
  PeekSyncsDocument,
  ResourceType,
  SyncDeletedSuccessResponse,
  SyncErrorResponse,
  SyncFragment
} from '~/generated/graphql';
import { useBannerDispatch } from '~/hooks';
import { hasItems, NO_EDIT_PERMISSION, routes } from '~/utils';
import { SyncInUse } from '~/pages/syncs/sync/sync-in-use';
import { SyncSummary } from '~/pages/syncs/sync-config/sync-summary';
import { StatusDisplay } from '~/pages/syncs/sync-status/status-display';
import { useMemo } from 'react';
import QueryString from 'qs';
import { ExploreFilter } from '~/pages/explore/ExploreFilters';

interface Props {
  sync: SyncFragment;
}

const SyncStatus = ({ sync }: Props) => {
  const history = useHistory();
  const dispatchBanner = useBannerDispatch();
  const [cloneTargetName, setCloneTargetName] = React.useState('');

  const [deletedSync, setDeletedSync] = React.useState<SyncFragment | undefined>();
  const [deleteSyncError, setDeleteSyncError] = React.useState<string>();
  const [usedBy, setUsedBy] = React.useState<SyncFragment[]>([]);

  const [cloneSync, { loading: cloneSyncLoading }] = useMutation(CloneSyncDocument, {
    notifyOnNetworkStatusChange: true,
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    onCompleted: data => {
      if (!data || !data.cloneSync) {
        return;
      }
      dispatchBanner({
        type: 'show',
        payload: {
          theme: 'success',
          message: (
            <>
              {'New sync created: '}
              <Link
                to={generatePath(routes.syncStatus, { id: data.cloneSync.id })}
                className="underline hover:opacity-75 focus:outline-none"
                onClick={() => dispatchBanner({ type: 'hide' })}
              >
                {data.cloneSync.name}
              </Link>
            </>
          ),
          wrapper: 'px-3 pt-3'
        }
      });
    }
  });

  const [deleteSync, { loading: deleteSyncLoading }] = useMutation(DeleteSyncDocument, {
    fetchPolicy: 'no-cache',
    onError: error => {
      // Todo - Expect this error, can this be avoided?
      if (error.message !== 'sync configuration not found') {
        dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
      }
    },
    update: (cache, { data }) => {
      if (!data || !data.deleteSync) {
        return;
      }
      if ('error' in (data.deleteSync as SyncErrorResponse)) {
        setDeleteSyncError((data.deleteSync as SyncErrorResponse).error);
        setUsedBy((data.deleteSync as SyncErrorResponse).usedBy);
        return;
      }
      const id = (data.deleteSync as SyncDeletedSuccessResponse).id;
      cache.modify({
        fields: {
          syncs: (existing: Reference[] = [], helpers) => {
            return existing.filter(ref => helpers.readField('id', ref) !== id);
          }
        }
      });
      cache.evict({ id: cache.identify({ __typename: 'Sync', id }) });
      cache.gc();
      handleDeleteDismiss();
    },
    refetchQueries: ({ data }) =>
      'error' in (data.deleteSync as SyncErrorResponse) ? undefined : [PeekSyncsDocument],
    onQueryUpdated: async query => {
      await query?.refetch();
      setTimeout(() => history.push(routes.syncs), 200);
    }
  });

  // F(x)
  const handleDelete = () => {
    setDeletedSync(sync);
    void deleteSync({ variables: { id: sync.id } });
  };
  const handleDeleteDismiss = () => {
    setDeletedSync(undefined);
    setDeleteSyncError(undefined);
    setUsedBy([]);
  };
  const handleCloneSync = () => {
    void cloneSync({
      variables: {
        id: sync.id,
        name: cloneTargetName
      }
    });
  };

  const cloneOnClick = () => setCloneTargetName(`Copy of ${sync.name}`);

  const sourceConnectionIds = [
    ...new Set(
      sync.fields
        .filter(mapping => !!mapping.model)
        .map(mapping => mapping.model.fieldset.connection.type.id)
    )
  ];

  const explorePath = useMemo(() => {
    const fields = [sync.identity, ...sync.fields].filter(Boolean);
    return `/explore?${QueryString.stringify({
      fields: fields.map(field => field.model.id),
      models: [...new Set(fields.map(field => field.model.fieldset.id))],
      filters: sync.filters.map<ExploreFilter>(filter => ({
        function: filter.function,
        label: filter.label,
        value: filter.value as string | string[] | CompletionValue,
        fieldID: filter.field?.id,
        fieldType: 'Model'
      })),
      logic: sync.filterLogic
    })}`;
  }, [sync.identity, sync.fields, sync.filters, sync.filterLogic]);

  return (
    <div className="relative animate-fadeIn">
      <EditPermission
        fallback={<LacksPermissionBanner message={NO_EDIT_PERMISSION} wrapper="px-3 pt-3" />}
      />
      <div className="divide-y divide-gray-300 pb-48">
        <StatusDisplay sync={sync} key={sync.id} />
        <SideBySide
          hasSectionWrap
          heading={
            <div className="flex flex-col">
              <h3 className="sbs-heading">Configuration summary</h3>
              <FeatureFlag feature="explore">
                <a
                  href={explorePath}
                  className="link mt-1 flex flex-row items-center space-x-2"
                  rel="noreferrer"
                  target="_blank"
                >
                  <span>Explore sync contents</span>
                  <Icon name="Search" size="sm" />
                </a>
              </FeatureFlag>
            </div>
          }
          maxWidth="max-w-full"
        >
          <SyncSummary
            sync={sync}
            hideSections={['Name', 'Access control']}
            className="-mr-6"
            readonly={true}
          />
        </SideBySide>
        <LastModified updatedAt={sync.updatedAt} updatedBy={sync.updatedBy} />
        <ConfigTriggerDialog
          cta="Clone sync"
          description="Duplicate this sync with the same settings."
          loading={cloneSyncLoading}
          triggerIcon="Copy"
          handleTrigger={cloneOnClick}
          handleAction={handleCloneSync}
          createPermission={ResourceType.Sync}
          contentPadding={false}
        >
          <SideBySide hasSectionWrap heading="Sync to clone">
            <p className="mb-2 text-sm font-medium text-gray-800">{sync.name}</p>
            <p className="flex items-center text-sm text-gray-800">
              {hasItems(sourceConnectionIds) && (
                <>
                  <Icon match={sourceConnectionIds[0]} className="h-5 w-auto" />
                  {sourceConnectionIds.slice(1).map(connId => (
                    <React.Fragment key={connId}>
                      <Icon name="PlusSmall" className="mx-0.5 h-5 w-5 text-gray-600" />
                      <Icon match={connId} className="h-5 w-auto" />
                    </React.Fragment>
                  ))}
                </>
              )}
              <Icon name="Syncs" className="mx-2.5 h-5 w-5 text-gray-600" />
              <Icon match={sync.targetConnection?.type.id || ''} className="mr-2 h-5 w-auto" />
              {sync.targetObject?.name || ''}
            </p>
          </SideBySide>
          <Divider />
          <SideBySide hasSectionWrap heading="Name of new sync">
            <MyInput
              defaultValue={cloneTargetName}
              onBlur={e => {
                const { value } = e.currentTarget;
                setCloneTargetName(value);
              }}
              name="cloned-sync-name"
            />
            <p className="mt-1 text-sm text-gray-500">
              The new sync will have the same settings and field mappings but be disabled upon
              creation.
            </p>
          </SideBySide>
        </ConfigTriggerDialog>
        <AccessControlWrap>
          <AccessControlSolo
            tags={sync?.tags || []}
            resourceId={sync?.id}
            resourceType={ResourceType.Sync}
          />
        </AccessControlWrap>
        <ConfigTriggerDialog
          isDelete={true}
          cta="Delete sync"
          loading={deleteSyncLoading}
          handleDismiss={handleDeleteDismiss}
          handleAction={handleDelete}
          hideAction={!!(deleteSyncError && deletedSync)}
          preventToggle={['action']}
          description="Deleting the sync will result in your source data no longer being pushed to the configured destination."
          dismissText={deleteSyncError && deletedSync ? 'Close' : 'Cancel'}
        >
          {deleteSyncError && deletedSync ? (
            <SyncInUse sync={deletedSync} error={deleteSyncError} usedBy={usedBy} />
          ) : (
            <>
              <p className="mb-4 text-sm font-medium text-gray-800">{sync.name}</p>
              <p className="mb-4 flex items-center text-sm font-medium text-gray-800">
                {hasItems(sourceConnectionIds) && (
                  <>
                    <Icon match={sourceConnectionIds[0]} className="h-5 w-auto" />
                    {sourceConnectionIds.slice(1).map(connId => (
                      <React.Fragment key={connId}>
                        <Icon name="PlusSmall" className="mx-0.5 h-5 w-5 text-gray-600" />
                        <Icon match={connId} className="h-5 w-auto" />
                      </React.Fragment>
                    ))}
                  </>
                )}
                <Icon name="Syncs" className="mx-2.5 h-5 w-5 text-gray-600" />
                <Icon match={sync.targetConnection?.type.id || ''} className="mr-2 h-5 w-auto" />
                {sync.targetObject?.name || ''}
              </p>
              <p className="text-sm text-gray-500 [max-width:65ch]">
                Deleting the sync will result in your source data no longer being pushed to the
                configured destination.
              </p>
            </>
          )}
        </ConfigTriggerDialog>
      </div>
    </div>
  );
};

export default SyncStatus;
