import { LazyQueryHookOptions, QueryLazyOptions, QueryTuple, useLazyQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as React from 'react';

import { isContinuation, isPoller } from '../utils';

export function useLazyContinuationQuery<Q, V>(
  query: DocumentNode,
  baseOptions?: LazyQueryHookOptions<Q, V>
): QueryTuple<Q, V> {
  const [pollingActive, setPollingActive] = React.useState<boolean>(false);

  const [applyQuery, applyOptions] = useLazyQuery<Q, V>(query, {
    ...baseOptions,
    pollInterval: undefined,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (applyOptions.called) {
        if (isContinuation(data) || isPoller(data)) {
          applyOptions.startPolling(baseOptions?.pollInterval || 1000);
          return;
        }
        applyOptions.stopPolling();
        setPollingActive(false);
        if (baseOptions?.onCompleted) {
          baseOptions.onCompleted(data);
        }
      }
    },
    onError: error => {
      if (applyOptions.called) {
        applyOptions.stopPolling();
      }
      setPollingActive(false);
      if (baseOptions?.onError) {
        baseOptions.onError(error);
      }
    }
  });

  const loading = React.useMemo<boolean>(
    () => pollingActive || applyOptions.loading,
    [applyOptions.loading, pollingActive]
  );

  const queryExecutable = React.useCallback(
    (options?: QueryLazyOptions<V> | undefined) => {
      setPollingActive(true);
      return applyQuery(options);
    },
    [applyQuery]
  );

  return [
    queryExecutable,
    {
      ...applyOptions,
      loading
    }
  ];
}
