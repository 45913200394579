import { SVGProps } from 'react';

export function ResizeHandleH(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 38 5" {...props}>
      <circle cy="2.5" cx="2.5" r="2.5" />
      <circle cy="2.5" cx="13.5" r="2.5" />
      <circle cy="2.5" cx="24.5" r="2.5" />
      <circle cy="2.5" cx="35.5" r="2.5" />
    </svg>
  );
}
