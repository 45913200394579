import ListItem, { ListItemProps } from './ListItem';
import { cn } from '~/lib/utils';

export type ListProps = {
  dense?: boolean;
  variant?: 'outlined' | 'flat';
  items?: ListItemProps[];
};

export const List = ({ dense = false, variant = 'flat', items }: ListProps) => {
  return (
    <ul
      className={cn(
        'flex h-full snap-y scroll-p-1 flex-col items-start overflow-x-clip overflow-y-scroll scroll-smooth',
        dense ? 'space-y-2' : 'space-y-4',
        variant === 'outlined' && 'rounded border border-gray-300 bg-gray-50 p-1 '
      )}
    >
      {items?.map((item, index) => (
        <ListItem key={index} scrollSnap={true} {...item} dense={dense} />
      ))}
    </ul>
  );
};

export default List;
