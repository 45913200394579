import cx from 'clsx';
import * as React from 'react';
import { Icon } from '../Icon';

const themes = {
  success: { styles: 'bg-green-500 border-green-600', icon: 'CheckFilled' },
  warning: { styles: 'bg-orange-500 border-orange-600', icon: 'WarningFilled' },
  danger: { styles: 'bg-red-500 border-red-600', icon: 'DangerFilled' },
  info: { styles: 'bg-sky-500 border-sky-600', icon: 'InfoFilled' }
} as const;

export interface BannerStaticProps {
  theme?: keyof typeof themes;
  children?: React.ReactNode;
  className?: string;
  rightSlot?: React.ReactNode;
}

export function BannerStatic({
  theme = 'warning',
  className,
  children,
  rightSlot
}: BannerStaticProps) {
  return (
    <div
      className={cx(
        'grid w-full grid-cols-[auto,1fr,auto] items-start gap-2 rounded border px-4 shadow-lg',
        themes[theme].styles
      )}
    >
      <Icon name={themes[theme].icon} className="my-4 h-5 w-5 text-white text-opacity-60" />
      <div className={cx('overflow-auto py-4 font-medium text-white', className)}>{children}</div>
      {rightSlot || null}
    </div>
  );
}
