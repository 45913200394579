import { Row } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Icon, Search } from '~/components';
import TooltipIcon from '~/components/tooltip-icon';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import {
  BulkDestinationVocabulary,
  BulkSourceSchemaDocument,
  BulkSourceSchemaQuery,
  BulkSourceSchemaQueryVariables
} from '~/generated/graphql';
import { useLazyContinuationQuery } from '~/hooks';
import { cn } from '~/lib/utils';
import { IBulkField, IBulkSchema, SchemaOrFieldKey } from '../components/BulkNamespaceUtil';

interface SchemaRowMenuProps {
  row: Row<IBulkSchema>;
  connectionId: string;
  supportsPartitionKeys: boolean;
  supportsTrackingFields: boolean;
  setNamespaceValue: (path: string, key: SchemaOrFieldKey, value: string) => void;
  vocabulary: BulkDestinationVocabulary;
}

export function SchemaRowMenu({
  row,
  setNamespaceValue,
  connectionId,
  supportsPartitionKeys,
  supportsTrackingFields,
  vocabulary
}: SchemaRowMenuProps) {
  const schema = row.original as IBulkSchema;

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<Partial<IBulkField>[]>(schema?.fields ?? []);

  const [loadSchemaFields] = useLazyContinuationQuery<
    BulkSourceSchemaQuery,
    BulkSourceSchemaQueryVariables
  >(BulkSourceSchemaDocument, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setFields(data?.bulkSourceForConnection?.namespaces?.[0]?.schemas?.[0]?.fields ?? []);
    }
  });

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadSchemaFields({
        variables: {
          connectionId: connectionId,
          namespaceId: row.getParentRow()?.id,
          schemaId: schema.id,
          continuation: uuid(),
          includeFields: true
        }
      });
      setLoading(false);
    };

    if (open && !isEmpty(schema.selectionState)) {
      load();
    }
  }, [open]);

  const [trackingFieldSearch, setTrackingFieldSearch] = useState<string>('');
  const [partitionKeySearch, setPartitionKeySearch] = useState<string>('');

  const trackingFieldOptions = useMemo(
    () =>
      fields
        .filter(field => field.trackable)
        .filter(field => field.name?.toLowerCase().includes(trackingFieldSearch?.toLowerCase())),
    [fields, trackingFieldSearch]
  );

  const partitionKeyOptions = useMemo(
    () =>
      fields
        .filter(field => field.partitionable)
        .filter(field => field.name?.toLowerCase().includes(partitionKeySearch?.toLowerCase())),
    [fields, partitionKeySearch]
  );

  const partitionKeyLabel = !isEmpty(vocabulary.partitionKey)
    ? vocabulary.partitionKey
    : 'Partition key';

  return (
    <div className={cn('flex flex-row items-start justify-end space-x-2 text-gray-500')}>
      {!!schema.partitionKey && (
        <TooltipIcon
          message={`${partitionKeyLabel}: ${schema.partitionKey}`}
          icon={<Icon name="PartitionKey" size="sm" className="align-top" />}
        />
      )}
      {(supportsPartitionKeys || supportsTrackingFields) && (
        <DropdownMenu open={open} onOpenChange={setOpen}>
          <DropdownMenuTrigger onClick={e => e.stopPropagation()}>
            <div className="invisible rounded p-[2px] hover:bg-gray-300 group-hover/row:visible">
              <Icon name="DotsH" size="sm" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" portal={false} onClick={e => e.stopPropagation()}>
            {!!supportsPartitionKeys && (
              <DropdownMenuSub onOpenChange={() => setPartitionKeySearch('')}>
                <DropdownMenuSubTrigger>{partitionKeyLabel}</DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent onClick={e => e.stopPropagation()}>
                    <DropdownMenuLabel>{partitionKeyLabel}</DropdownMenuLabel>
                    <div className="mb-2 px-2">
                      <Search
                        className="font-normal"
                        wrapperStyles="h-8 w-80"
                        placeholder="Search..."
                        defaultValue={partitionKeySearch}
                        onChange={setPartitionKeySearch}
                        onReset={() => setPartitionKeySearch('')}
                      />
                    </div>
                    {loading && <LoadingDots />}
                    {!loading && (
                      <DropdownMenuRadioGroup
                        value={schema.partitionKey}
                        onValueChange={v => setNamespaceValue(row.original.path, 'partitionKey', v)}
                      >
                        <DropdownMenuRadioItem value="" checked={!schema.partitionKey}>
                          None
                        </DropdownMenuRadioItem>
                        {partitionKeyOptions.map(field => (
                          <DropdownMenuRadioItem
                            key={field.id}
                            value={field.id}
                            checked={schema.partitionKey === field.id}
                          >
                            {field.name}
                          </DropdownMenuRadioItem>
                        ))}
                      </DropdownMenuRadioGroup>
                    )}
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            )}
            {!!supportsTrackingFields && (
              <DropdownMenuSub onOpenChange={() => setTrackingFieldSearch('')}>
                <DropdownMenuSubTrigger>Tracking field</DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent onClick={e => e.stopPropagation()}>
                    <DropdownMenuLabel>Tracking field</DropdownMenuLabel>
                    <div className="mb-2 px-2">
                      <Search
                        className="font-normal"
                        wrapperStyles="h-8 w-80"
                        placeholder="Search..."
                        defaultValue={trackingFieldSearch}
                        onChange={setTrackingFieldSearch}
                        onReset={() => setTrackingFieldSearch('')}
                      />
                    </div>
                    {loading && <LoadingDots />}
                    {!loading && (
                      <DropdownMenuRadioGroup
                        value={schema.trackingField}
                        onValueChange={v =>
                          setNamespaceValue(row.original.path, 'trackingField', v)
                        }
                      >
                        <DropdownMenuRadioItem value="" checked={!schema.trackingField}>
                          None
                        </DropdownMenuRadioItem>
                        {trackingFieldOptions.map(field => (
                          <DropdownMenuRadioItem
                            key={field.id}
                            value={field.id}
                            checked={schema.trackingField === field.id}
                          >
                            {field.name}
                          </DropdownMenuRadioItem>
                        ))}
                      </DropdownMenuRadioGroup>
                    )}
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
}
