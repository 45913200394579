import { ListBox as AriaListBox, Item, type Selection, ListBoxProps } from 'react-aria-components';
import * as React from 'react';
import { cn } from '~/lib/utils';

interface ListboxProps extends ListBoxProps<typeof Item> {
  onSelection: (selected: string) => void;
}

export const ListBox = React.forwardRef<React.ElementRef<typeof AriaListBox>, ListboxProps>(
  ({ className, onSelection, ...props }, ref) => {
    const onSelectionChange = (selection: Selection) => {
      onSelection((selection as Set<string>).values().next().value);
    };

    return (
      <AriaListBox
        selectionMode="single"
        className={cn('flex flex-col gap-2', className)}
        onSelectionChange={onSelectionChange}
        autoFocus="first"
        {...props}
        ref={ref}
      />
    );
  }
);

export const ListBoxItem = React.forwardRef<
  React.ElementRef<typeof Item>,
  React.ComponentPropsWithoutRef<typeof Item>
>(({ ...props }, ref) => <Item {...props} ref={ref} />);
