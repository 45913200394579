import cx from 'clsx';
import { JSONSchema4 } from 'json-schema';
import * as React from 'react';
import { ArrayPath, FieldValues, useFieldArray, useFormContext } from 'react-hook-form';

import { hasItems } from '../../utils';
import { EditPermission } from '../edit-permission';
import { FormElements } from './form-elements';
import { Label } from './label';
import { ParamButton } from './param-button';

function mungeName(name: string, idx: number) {
  const splitName = name.split('.');
  return `${splitName.slice(0, -1).join('.')}.${idx}.${splitName[splitName.length - 1]}`;
}

interface Props {
  item: JSONSchema4;
  className?: string;
}

export function FormArray<TFormValues extends FieldValues>(props: Props) {
  const { control } = useFormContext<TFormValues>();

  const arrayFields = (props?.item?.schema as JSONSchema4[]) || [];
  const { fields, append, remove } = useFieldArray({
    control,
    name: props.item.name as ArrayPath<TFormValues>
  });

  function add() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    append(props.item.append);
  }

  return (
    <div className={props.className}>
      <Label className="col-span-full block font-medium leading-none">{props.item.title}</Label>
      {hasItems(fields) ? (
        fields?.map((field, idx) => {
          return (
            <React.Fragment key={field.id}>
              {arrayFields?.map(({ title, name, ...fieldProps }: JSONSchema4, subIdx) => (
                <FormElements
                  key={`${field.id}.${title || subIdx}`}
                  item={{
                    ...fieldProps,
                    name: mungeName(name as string, idx)
                  }}
                />
              ))}
              <EditPermission>
                <ParamButton
                  className="mt-px focus-visible:ring-offset-gray-100"
                  action="delete"
                  onClick={() => remove(idx)}
                />
              </EditPermission>
              <EditPermission>
                <ParamButton
                  className={cx(
                    'mt-px focus-visible:ring-offset-gray-100',
                    idx === fields.length - 1 ? 'visible' : 'invisible'
                  )}
                  action="add"
                  onClick={add}
                />
              </EditPermission>
            </React.Fragment>
          );
        })
      ) : (
        <EditPermission>
          <ParamButton action="add" className="focus-visible:ring-offset-gray-100" onClick={add} />
        </EditPermission>
      )}
    </div>
  );
}
