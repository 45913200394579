import { useQuery } from '@apollo/client';
import {
  FieldType,
  FilterFunction,
  FilterFunctionsDocument,
  SupportedFilterFunction
} from '~/generated/graphql';

export function useFilterFunctions() {
  const { data } = useQuery(FilterFunctionsDocument);

  const filterFunctionsByType = data?.getFiltersForAllTypes?.reduce((acc, val) => {
    acc[val.type] = val.filters;
    return acc;
  }, {}) as Record<FieldType, SupportedFilterFunction[]>;

  const getFilterFunctions = (type: FieldType) => {
    return filterFunctionsByType?.[type];
  };

  const getFilterFunction = (type: FieldType, value: FilterFunction) => {
    return filterFunctionsByType?.[type]?.find(f => f.id === value);
  };

  return { filterFunctionsByType, getFilterFunctions, getFilterFunction };
}
