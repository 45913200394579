import React from 'react';
import { Truncator } from '~/components/truncator';
import { Icon } from '../../../Icon';

export type ActivityLogTimeLineMarkerProps = {
  type: 'plus' | 'minus';
  children: React.ReactNode;
};

const ActivityLogMarker = ({ type, children }: ActivityLogTimeLineMarkerProps) => {
  return (
    !!children && (
      <div className="flex items-center space-x-1 overflow-clip truncate">
        <Icon name={type === 'minus' ? 'MinusFilledSquare' : 'PlusFilledSquare'} size="sm" />
        <Truncator
          offset={[0, 4]}
          className="w-max max-w-lg"
          placement="auto"
          content={
            children ? (
              <span className="overflow-hidden">{children}</span>
            ) : (
              <span className="break-words">{String(children)}</span>
            )
          }
        >
          <span className="overflow-clip truncate">{children}</span>
        </Truncator>
      </div>
    )
  );
};

export default ActivityLogMarker;
