import { MyPopover, Permission, PopoverAction, PopoverButtonMinimal } from '~/components';
import { Action } from '~/generated/graphql';
import { useToggle } from '~/hooks';
import { RoleWithUser } from '~/utils';
import { DeleteRoleDialog } from './delete-role-dialog';
import { EditRoleDialog } from './edit-role-dialog';

export function RoleActions({ role }: { role: RoleWithUser }) {
  const [showPopover, togglePopover] = useToggle();
  const [showUpdateDialog, toggleUpdateDialog] = useToggle();
  const [showDeleteDialog, toggleDeleteDialog] = useToggle();

  return (
    <>
      <MyPopover
        visible={showPopover}
        onClickOutside={togglePopover}
        placement="bottom-end"
        offset={[0, 6]}
        className="z-10 w-40"
        content={
          <div className="py-1">
            <Permission type={Action.Edit}>
              <PopoverAction
                onClick={() => {
                  togglePopover();
                  toggleUpdateDialog();
                }}
              >
                Rename
              </PopoverAction>
            </Permission>
            <Permission type={Action.Delete}>
              <PopoverAction
                onClick={() => {
                  togglePopover();
                  toggleDeleteDialog();
                }}
              >
                Delete
              </PopoverAction>
            </Permission>
          </div>
        }
      >
        <PopoverButtonMinimal isShowingPopover={showPopover} onClick={togglePopover} />
      </MyPopover>
      {showUpdateDialog && (
        <EditRoleDialog type="edit" role={role} toggleDialog={toggleUpdateDialog} />
      )}
      {showDeleteDialog && <DeleteRoleDialog role={role} toggleDialog={toggleDeleteDialog} />}
    </>
  );
}
