import { useSetAtom } from 'jotai';
import { ReactNode, useEffect, useLayoutEffect } from 'react';

import { BottomNavStateAtom, BottomNavStateAtomType } from '~/components/v2/layout/BottomNav';
import PageLoader from '~/components/v2/layout/PageLoader';
import { PageNavStateAtom, PageNavStateAtomType } from '~/components/v2/layout/PageNav';
import {
  SideNavInnerStateAtom,
  SideNavInnerStateAtomType
} from '~/components/v2/layout/SideNavInner';
import { TopNavStateAtom, TopNavStateAtomType } from '~/components/v2/layout/TopNav';

export type PageLayoutProps = {
  loading?: boolean;
  children?: ReactNode;
} & TopNavStateAtomType &
  SideNavInnerStateAtomType &
  PageNavStateAtomType &
  BottomNavStateAtomType;

export const PageLayout = ({
  children,
  loading,
  topNavHeading,
  topNavActions,
  pageNavConf,
  sideNavInnerContent,
  sideNavInnerHeading,
  bottomNavContent,
  bottomNavShow
}: PageLayoutProps) => {
  // ⚛️ Atoms
  const setTopNavState = useSetAtom(TopNavStateAtom);
  const setSideNavInnerState = useSetAtom(SideNavInnerStateAtom);
  const setPageNavState = useSetAtom(PageNavStateAtom);
  const setBottomNavState = useSetAtom(BottomNavStateAtom);
  // State sync
  useLayoutEffect(() => {
    setTopNavState(s => ({
      ...s,
      topNavHeading: topNavHeading || s.topNavHeading,
      topNavActions: !loading && (topNavActions || s.topNavActions)
    }));
    setSideNavInnerState(s => ({
      ...s,
      sideNavInnerContent: sideNavInnerContent || s.sideNavInnerContent,
      sideNavInnerHeading: sideNavInnerHeading || s.sideNavInnerHeading
    }));
    setBottomNavState(s => ({
      ...s,
      bottomNavContent: bottomNavContent || s.bottomNavContent
    }));
    setPageNavState(s => ({ ...s, pageNavConf: pageNavConf || s.pageNavConf }));
  }, [
    setSideNavInnerState,
    setBottomNavState,
    setPageNavState,
    setTopNavState,
    topNavHeading,
    topNavActions,
    sideNavInnerContent,
    sideNavInnerHeading,
    pageNavConf,
    loading,
    bottomNavContent
  ]);

  useEffect(() => {
    setBottomNavState(s => ({ ...s, bottomNavShow: bottomNavShow }));
  }, [bottomNavShow, setBottomNavState]);

  return loading ? <PageLoader /> : <>{children}</>;
};

export default PageLayout;
