import { EditorView } from 'codemirror';
import * as React from 'react';

const EditorViewContext = React.createContext<EditorView | null>(null);
const EditorSetViewContext = React.createContext<
  React.Dispatch<React.SetStateAction<EditorView | null>>
>(() => null);

interface Props {
  children: React.ReactNode;
}

export function EditorProvider(props: Props) {
  const [state, setState] = React.useState<EditorView | null>(null);

  const ctxState = React.useMemo(() => state, [state]);
  const ctxSetState = React.useCallback(setState, [setState]);

  return (
    <EditorViewContext.Provider value={ctxState}>
      <EditorSetViewContext.Provider value={ctxSetState}>
        {props.children}
      </EditorSetViewContext.Provider>
    </EditorViewContext.Provider>
  );
}

export function useEditorViewState() {
  return React.useContext(EditorViewContext);
}

export function useSetEditorView() {
  return React.useContext(EditorSetViewContext);
}
