import cx from 'clsx';
import * as React from 'react';

import { COLOR } from '../../components/v2/configs/SX';
import Chip from '../../components/v2/display/Chip';

interface Props {
  show: boolean;
  autoDismiss: () => void;
  duration?: number;
  text: string;
  className?: string;
}

export const InlineMessage = ({ show, duration = 3000, autoDismiss, text, className }: Props) => {
  const [fade, setFade] = React.useState(false);

  const handleDismiss = () => {
    autoDismiss();
    setFade(false);
  };

  React.useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setFade(true);
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [show, duration]);

  if (!show) {
    return null;
  }
  return (
    <div className={cx(className, 'animate-fadeIn')}>
      <div className={cx(fade && 'animate-fadeOut')} onAnimationEnd={handleDismiss}>
        <Chip color={COLOR.SUCCESS}>{text}</Chip>
      </div>
    </div>
  );
};
