import * as React from 'react';

export function StatusLabel(props: { text: string; children: React.ReactNode }) {
  return (
    <>
      <label className="text-sm font-semibold text-gray-500">{props.text}</label>
      {props.children}
    </>
  );
}
