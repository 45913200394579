import { useParams } from 'react-router-dom';

import { AddSubscriber } from '../syncs/sync-notifications/add-subscriber';

const BulkSyncNotifications = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div className="animate-fadeIn divide-y divide-gray-300 pb-32">
      <AddSubscriber id={id} notificationEvent="bulksync.failed" />
    </div>
  );
};

export default BulkSyncNotifications;
