import { Icon } from '~/components';

export type ErrorTextProps = {
  children: React.ReactNode;
};

const ErrorText = ({ children }: ErrorTextProps) => {
  const ROOT_SX = 'flex items-start space-x-1';
  const ICON_SX = 'mt-px h-4 w-4 text-red-500';
  const TEXT_SX = 'text-sm font-medium text-red-500';
  return (
    <p className={ROOT_SX}>
      <Icon name="DangerFilled" className={ICON_SX} />
      <span className={TEXT_SX}>{children}</span>
    </p>
  );
};

export default ErrorText;
