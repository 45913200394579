import * as React from 'react';
import Select, { ActionMeta, createFilter, Props as SelectProps } from 'react-select';

import {
  DropdownIndicator,
  formatOptionLabel,
  formatOptionLabelWithTruncation,
  mappingSelectStyles,
  Selectable,
  selectStyles,
  WINDOW_THRESHOLD,
  WindowedMenuList
} from '../../utils';

export type ComboboxProps<Type extends { label: string; value: string | number } = Selectable> =
  Exclude<SelectProps<Type, false>, 'onChange'> & {
    onChange: (option: Type | null) => void;
    windowThreshold?: number;
    disabled?: boolean;
    readOnly?: boolean;
    // Ensures the menu's width is the same as the input's width
    fitMenuToInputWidth?: boolean;
    // Determines whether the options are fetched asynchronously
    isAsync?: boolean;
    // Todo -> turn into enum type for all inputs
    variant?: 'outlined' | 'flat';
  };

export function MyCombobox<Type extends { label: string; value: string | number } = Selectable>({
  components,
  options: propsOptions = [],
  isDisabled,
  disabled,
  fitMenuToInputWidth = false,
  isAsync = false,
  variant = 'outlined',
  ...props
}: ComboboxProps<Type>) {
  const [width, setWidth] = React.useState(320);
  const ref = React.useRef<Select<Type, false>>(null);

  const [options, optionsLength] = React.useMemo(() => {
    const options = propsOptions;
    const length = options.length;
    return [options, length];
  }, [propsOptions]);

  const isWindowed = optionsLength >= (props.windowThreshold || WINDOW_THRESHOLD);
  const MenuList = isWindowed ? { MenuList: WindowedMenuList } : {};

  const handleChange = (value: Type, actionMeta: ActionMeta<Type>) => {
    props.onChange(value);
  };
  const getStyles = React.useCallback(() => {
    const styles = variant === 'flat' ? mappingSelectStyles : selectStyles;
    if (fitMenuToInputWidth) {
      styles.option = (base: Record<string, unknown>) => ({
        ...base,
        ...selectStyles.option,
        maxWidth: width
      });
      styles.menu = (base: Record<string, unknown>) => ({
        ...base,
        ...selectStyles.menu,
        maxWidth: width
      });
    }
    return styles;
  }, [fitMenuToInputWidth, variant, width]);

  React.useLayoutEffect(() => {
    if (ref.current) {
      // @ts-expect-error Not sure of the types for this one
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setWidth(ref.current.select?.controlRef?.clientWidth || 320);
    }
  }, []);

  return (
    <Select
      // options that can be overridden
      isSearchable={true}
      formatOptionLabel={
        props.formatOptionLabel || fitMenuToInputWidth
          ? formatOptionLabelWithTruncation
          : formatOptionLabel
      }
      {...props}
      ref={ref}
      width={width}
      // options that won't be overridden
      filterOption={createFilter({ ignoreAccents: false })}
      components={{
        ...components,
        DropdownIndicator,
        IndicatorSeparator: null,
        ...MenuList
      }}
      isDisabled={(!isAsync && props.isLoading) || disabled || isDisabled || props.readOnly}
      styles={getStyles()}
      options={options}
      onChange={handleChange}
    />
  );
}
