export function addLineBreaks(str: string) {
  if (!str) {
    return '';
  }
  const result = [];
  let tempStr = '';

  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    const code = str.charCodeAt(i + 1);

    tempStr += char;

    if (
      (code >= 40 && code <= 47) ||
      (code >= 58 && code <= 62) ||
      (code >= 91 && code <= 95) ||
      (code >= 123 && code <= 125) ||
      code === 95 ||
      code === 171 ||
      code === 187
    ) {
      result.push(tempStr, <wbr key={`${code}-${i}`} />);
      tempStr = '';
    }
  }
  if (tempStr) {
    result.push(tempStr);
  }
  return result;
}
