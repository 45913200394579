import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import {
  ClientFieldsetFragment,
  FieldsetFragment,
  ModelDocument,
  ModelFieldFragment
} from '../generated/graphql';
import { keyBy, sortBy } from 'lodash';

export interface ModelField extends ModelFieldFragment {
  fieldset: FieldsetFragment;
  enrichmentFieldset?: Partial<FieldsetFragment>;
}

export function useModelFields() {
  const { data } = useQuery(ModelDocument);

  const modelFields = useMemo(
    () =>
      sortBy<ModelField>(
        data?.model?.fieldsets?.reduce((acc, fieldset) => {
          return acc.concat(
            ...fieldset.fields?.map(field => ({
              ...field,
              fieldset: getFieldsetMinimal(fieldset)
            })),
            ...fieldset.enrichments?.flatMap(enrichment =>
              enrichment.provider.fields.map(field => ({
                ...field,
                fieldset: getFieldsetMinimal(fieldset),
                enrichmentFieldset: {
                  id: enrichment.provider.id,
                  connection: enrichment.provider.connection
                }
              }))
            )
          );
        }, []) ?? [],
        f => f.label
      ),
    [data?.model]
  );

  const fieldsets = useMemo(() => keyBy(data?.model?.fieldsets, f => f.id), [data?.model]);

  return {
    modelFields,
    fieldsets
  };
}

function getFieldsetMinimal(fs: ClientFieldsetFragment) {
  return {
    id: fs.id,
    name: fs.name,
    realName: fs.realName,
    connection: {
      id: fs.connection.id,
      name: fs.connection.name,
      type: {
        id: fs.connection.type.id,
        name: fs.connection.type.name
      }
    },
    enrichments: fs.enrichments.map(enrichment => ({
      provider: {
        id: enrichment.provider.id,
        connection: enrichment.provider.connection
      }
    }))
  };
}
