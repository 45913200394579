import { useReactiveVar } from '@apollo/client';
import * as React from 'react';

import { expandedSchemasVar } from '../apollo';

export type ExpandedSchemasReducerActions =
  | { type: 'initial'; id: string }
  | { type: 'toggle'; id: string }
  | { type: 'reset' };

function expandedSchemasReducer(
  prev: Record<string, boolean>,
  action: ExpandedSchemasReducerActions
) {
  switch (action.type) {
    case 'initial':
      // prefer existing value, user might have collapsed it
      if (prev[action.id] != null) {
        return prev;
      }
      return {
        ...prev,
        [action.id]: true
      };
    case 'toggle':
      return {
        ...prev,
        [action.id]: !prev[action.id]
      };
    case 'reset':
      return Object.keys(prev).reduce((acc: Record<string, boolean>, id) => {
        acc[id] = false;
        return acc;
      }, {});
    default:
      return prev;
  }
}

export const useExpandedSchemas = (connectionId: string) => {
  const cache = useReactiveVar(expandedSchemasVar);
  const value = React.useMemo(() => cache[connectionId] || {}, [cache, connectionId]);
  return value;
};

export function dispatchExpandedSchemas(
  connectionId: string,
  action: ExpandedSchemasReducerActions
) {
  const prev = expandedSchemasVar()?.[connectionId] || {};
  expandedSchemasVar({
    ...expandedSchemasVar(),
    [connectionId]: expandedSchemasReducer(prev, action)
  });
}

export function resetAllExpandedSchemas() {
  expandedSchemasVar(
    Object.entries(expandedSchemasVar()).reduce(
      (
        acc: { [connectionId: string]: Record<string, boolean> },
        [key, val]: [string, Record<string, boolean>]
      ) => {
        acc[key] = Object.keys(val).reduce((inner: Record<string, boolean>, id) => {
          inner[id] = false;
          return inner;
        }, {});
        return acc;
      },
      {}
    )
  );
}
