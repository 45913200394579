import { generatePath, Link } from 'react-router-dom';
import {
  BulkSyncFragment,
  FieldsetFragment,
  SyncFragment,
  IntegrationSchedule
} from '../generated/graphql';
import { isFieldset, isSync, isSchedule, plural, routes } from '../utils';
import { Icon } from '~/components';

interface Props {
  name: string;
  logo?: string;
  type: string;
  usedBy: Array<FieldsetFragment | SyncFragment | BulkSyncFragment | IntegrationSchedule>;
}

interface UsedByItem {
  id: string;
  name: string;
  link: string;
}

export function InUse({ name, logo, usedBy, type }: Props) {
  if (usedBy.length === 0) {
    return null;
  }

  const items = usedBy.map<UsedByItem>(item => {
    if (isFieldset(item)) {
      return {
        id: item.id,
        name: `${item.name} model`,
        link: generatePath(routes.editModel, { fieldsetId: item.id })
      };
    }
    if (isSync(item)) {
      return {
        id: item.id,
        name: item.name,
        link: generatePath(routes.syncStatus, { id: item.id })
      };
    }
    if (isSchedule(item)) {
      return {
        id: item.syncId,
        name: item.syncId,
        link: generatePath(routes.syncStatus, { id: item.syncId })
      };
    }
    return {
      id: item.id,
      name: item.name,
      link: generatePath(routes.bulkSyncStatus, { id: item.id })
    };
  });

  return (
    <>
      <p className="flex items-center text-sm text-gray-500">
        <Icon match={logo} className="h-5 w-5" />
        <span className="mr-1 ml-2 max-w-xs truncate font-medium text-gray-800">{name}</span>
        <span>
          is used by
          {items.length > 1 ? ' these ' : ' this '}
          {plural(type, items.length > 1)}:
        </span>
      </p>
      <ul className="my-1.5 list-inside list-disc space-y-1 pl-2">
        {items.map(item => {
          return (
            <li key={item.id}>
              <Link to={item.link} rel="noopener noreferrer" target="_blank" className="link">
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}
