import { FormElements } from '~/components';
import { getSchemaAsList } from '~/utils';
import { AuthFields } from './AuthFields';
import type { FormBuilderProps } from './types';

export function CSV({ schema }: FormBuilderProps) {
  const items = getSchemaAsList(schema).filter(item =>
    ['url', 'headers', 'parameters'].includes(item.name as string)
  );
  return (
    <>
      <AuthFields />
      {items.map(item => (
        <FormElements key={item.name as string} item={item} />
      ))}
    </>
  );
}
