import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { DisabledSelect, EditPermission, MyCombobox, Section, SideBySide } from '~/components';
import { AthenaConfiguration } from '~/generated/graphql';
import { useFieldsetState, useModeSwitcher, useModelQueryRef } from '~/hooks';
import {
  FieldsetFormValues,
  ModeConfiguration,
  filterToIds,
  findName,
  getSchemaAsList,
  objToSelectables
} from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { EnumPicker, FieldsTable, ModelQueryEditor, TrackingColumns } from '../components';

const modeConfiguration: ModeConfiguration<AthenaConfiguration> = {
  modes: [
    { name: 'table', label: 'Table select' },
    { name: 'query', label: 'SQL query' }
  ],
  saveObj: (mode, conf) => ({
    configuration: {
      catalog: mode === 'table' ? conf.catalog : '',
      database: mode === 'table' ? conf.database : '',
      table: mode === 'table' ? conf.table : '',
      query: mode === 'query' ? conf.query : '',
      trackingColumns: conf.trackingColumns != null ? [...conf.trackingColumns] : []
    }
  }),
  reset: { configuration: { catalog: '', database: '', table: '', query: '', trackingColumns: [] } }
};

const queryUpdateObj = (conf: AthenaConfiguration | undefined) => ({
  configuration: {
    catalog: '',
    database: '',
    table: '',
    query: conf?.query || '',
    trackingColumns: conf?.trackingColumns != null ? filterToIds(conf.trackingColumns) : []
  }
});

export function AthenaFieldsetConfig() {
  const { fieldset, applyUpdate, loading, refreshing } = useFieldsetState();
  const { control, getValues } = useFormContext<FieldsetFormValues>();

  const { modes, mode, handleMode } = useModeSwitcher<AthenaConfiguration>(modeConfiguration);

  const catalog = useWatch({ control, name: 'configuration.catalog' });
  const database = useWatch({ control, name: 'configuration.database' });
  const table = useWatch({ control, name: 'configuration.table' });

  const list = React.useMemo(
    () => getSchemaAsList(fieldset?.configurationSchema, 'configuration'),
    [fieldset?.configurationSchema]
  );

  const defaultDoc = (fieldset?.configuration as AthenaConfiguration)?.query;
  const queryRef = useModelQueryRef('query', defaultDoc);

  const handleCatalogUpdate = React.useCallback(() => {
    applyUpdate(
      {
        catalog: getValues('configuration.catalog'),
        database: '',
        table: '',
        query: '',
        trackingColumns: []
      },
      { refresh: true, resetFields: true }
    );
  }, [applyUpdate, getValues]);

  const handleDatabaseUpdate = React.useCallback(() => {
    applyUpdate(
      {
        catalog: getValues('configuration.catalog'),
        database: getValues('configuration.database'),
        table: '',
        query: '',
        trackingColumns: []
      },
      { refresh: true, resetFields: true }
    );
  }, [applyUpdate, getValues]);

  const handleTableUpdate = React.useCallback(() => {
    applyUpdate(
      {
        catalog: getValues('configuration.catalog'),
        database: getValues('configuration.database'),
        table: getValues('configuration.table'),
        query: '',
        trackingColumns: filterToIds(getValues('configuration.trackingColumns'))
      },
      { refresh: true, resetFields: true }
    );
  }, [applyUpdate, getValues]);

  const handleRefresh = React.useCallback(() => {
    applyUpdate(
      {
        ...getValues('configuration'),
        trackingColumns: filterToIds(getValues('configuration.trackingColumns'))
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          <EditPermission
            fallback={<DisabledSelect className="max-w-xs" valueLabel={modes[mode]} />}
          >
            <MyCombobox
              className="max-w-xs"
              value={{ label: modes[mode], value: mode }}
              options={objToSelectables(modes, true)}
              onChange={handleMode}
              isDisabled={loading}
            />
          </EditPermission>
          <div className="mt-2 w-full max-w-xs animate-fadeIn space-y-2">
            {mode === 'table' && (
              <>
                <EnumPicker
                  item={findName(list, 'configuration.catalog')}
                  onChange={handleCatalogUpdate}
                />
                {catalog && (
                  <EnumPicker
                    item={findName(list, 'configuration.database')}
                    onChange={handleDatabaseUpdate}
                  />
                )}
                {database && (
                  <EnumPicker
                    item={findName(list, 'configuration.table')}
                    onChange={handleTableUpdate}
                  />
                )}
              </>
            )}
          </div>
        </SideBySide>
        {mode === 'query' && (
          <ModelQueryEditor
            heading="SQL query"
            path="configuration.query"
            updateObj={queryUpdateObj}
            defaultDoc={defaultDoc}
            queryRef={queryRef}
          />
        )}
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || (loading && !fieldset?.fields)}
          disabled={loading}
          refresh={table ? handleRefresh : undefined}
          hasWriteinFields={fieldset?.properties.writeinFields}
        />
      </Section>
      <AdditionalConfig />
      <TrackingColumns />
    </>
  );
}
