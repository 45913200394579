import { LazyQueryExecFunction, useQuery } from '@apollo/client';
import { Transition } from '@headlessui/react';
import { uuid4 } from '@sentry/utils';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { Icon } from '~/components';
import { FieldDetail } from '~/components/field-detail';
import { Button, Label, LinkButton, ParamButton } from '~/components/form-components';
import { COLOR } from '~/components/v2/configs/SX';
import Chip from '~/components/v2/display/Chip';
import Divider from '~/components/v2/display/Divider';
import { ANIMATION_VARIANT, Popover } from '~/components/v2/display/Popover';
import ErrorText from '~/components/v2/feedback/ErrorText';
import PageLayout from '~/components/v2/layout/PageLayout';
import {
  Exact,
  FieldMappingFragment,
  Maybe,
  ModelDocument,
  RemoteFieldTypeFragment,
  SyncMode,
  TargetFieldFragment,
  TargetObjectFragment,
  TargetObjectQuery
} from '~/generated/graphql';
import {
  ModelField,
  useFilterFunctions,
  useModelFields,
  useReachableFields,
  useToggle
} from '~/hooks';
import {
  FieldSyncMode,
  MappingIdentityFunction,
  NewFieldDialog,
  StageCard,
  StringInputDialog
} from '~/pages/syncs/sync-config';
import {
  EMPTY_MAPPING,
  LocalConnection,
  Mapping,
  SyncConfigFormValues,
  getMatchingTargetField,
  getMissingRequiredFields,
  getRemainingTargetFields,
  getTargetConnectionObjectLabel,
  getTargetOptions,
  handleMappingsUpdates,
  hasItems,
  isModelMapping,
  isOverrideMapping,
  isUnreachableMapping,
  removeExistingMappings,
  scrollElementIntoViewWithDrawer,
  supportsSyncMode,
  targetObjectIsCreatingTargets,
  targetObjectIsSourceOnly
} from '~/utils';
import FieldMappingBottomNav from './FieldMappingBottomNav';
import FieldMappingButton from './FieldMappingButton';
import StageMappingsFilters from './StageMappingsFilters';
import StageMappingsOverrides from './StageMappingsOverrides';

export enum FIELD_MAPPING_TYPE {
  NONE = 'NONE',
  IDENTITY_SOURCE = 'identitySource',
  IDENTITY_TARGET = 'identityTarget',
  MAPPING_SOURCE = 'mappingSource',
  MAPPING_TARGET = 'mappingTarget',
  FILTER_MODEL = 'modelFilters',
  FILTER_TARGET = 'targetFilters',
  OVERRIDE = 'overide'
}

export type StageMappingsProps = {
  syncMode: SyncMode;
  targetConnection: LocalConnection;
  targetObject: TargetObjectFragment;
  targetObjectFields: TargetFieldFragment[];
  targetFieldsLoading: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  validateForPreview: () => void;
  getTargetObject: LazyQueryExecFunction<
    TargetObjectQuery,
    Exact<{
      connectionId: string;
      targetObject: string;
      refresh?: Maybe<boolean> | undefined;
    }>
  >;
};
// prettier-ignore
export const StageMappings = ({
    syncMode,
    targetConnection,
    targetObject,
    targetObjectFields,
    targetFieldsLoading,
    setIsDirty,
    validateForPreview,
    getTargetObject
}: StageMappingsProps) => {
    // const DEV_TOOLS = useAtomValue(DevToolsStateAtom)
    // API
    // TODO
    const { modelFields } = useModelFields();
    const { getFilterFunctions } = useFilterFunctions();
    const getReachableFields = useReachableFields()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: modelData, refetch: refetchModel, loading: modelLoading } = useQuery(ModelDocument)
    // Internal State
    const [checkMappings, setCheckMappings] = useState<"model" | "target" | null>(null)
    const [showFieldDetails, toggleShowFieldDetails] = useToggle()
    const [selectedIdentitySourceId, setSelectedIdentitySourceId] = useState<string[]>([])
    const [selectedIdentityTargetId, setSelectedIdentityTargetId] = useState<string[]>([])
    const [selectedSourceFieldIds, setSelectedSourceFieldIds] = useState<string[]>([])
    const [selectedTargetFieldIds, setSelectedTargetFieldIds] = useState<string[]>([])
    const [bottomNavConfig, setBottomNavConfig] = useState({ config: FIELD_MAPPING_TYPE.NONE, mappingIndex: NaN })
    const [showBottomNav, setShowBottomNav] = useState(false)
    const [newFieldDialogConfig, setNewFieldDialogConfig] = useState<Record<string, unknown>>()
    const [showNewFieldDialog, setShowNewFieldDialog] = useState(false)
    const [stringInputDialogConfig, setStringInputDialogConfig] = useState<Record<string, unknown>>()
    const [showStringInputDialog, setShowStringInputDialog] = useState(false)
    // React Hook Form
    // TODO
    const { control, getValues, setValue, reset } = useFormContext<SyncConfigFormValues>()
    const { fields, append, remove, replace } = useFieldArray({ control, name: "mappings" })
    const identity = useWatch({ control, name: "identity" })
    const mappings = useWatch({ control, name: "mappings" })
    // Status
    const isWebhook = useMemo(() => targetConnection?.type.id === "webhook", [targetConnection.type.id])
    const optionalTargetMappings = useMemo(() => targetObject?.properties?.optionalTargetMappings, [targetObject?.properties?.optionalTargetMappings])
    const isSourceOnly = useMemo(() => targetObjectIsSourceOnly(targetObject?.properties), [targetObject?.properties])
    const isCreatingTargets = useMemo(() => targetObjectIsCreatingTargets(targetObject?.properties), [targetObject?.properties])
    const targetMode = useMemo(() => targetObject?.modes.find(m => m.mode === syncMode), [syncMode, targetObject?.modes])
    const supportsFieldCreation = useMemo(() => targetObject?.properties?.supportsFieldCreation, [targetObject?.properties?.supportsFieldCreation])
    const supportsFieldTypeSelection = useMemo(() => targetObject?.properties?.supportsFieldTypeSelection, [targetObject?.properties?.supportsFieldTypeSelection])
    const customProperty = useMemo(() => targetObject && targetObject.properties?.primaryMetadataObject !== ""
        ? targetObject?.properties?.primaryMetadataObject
        : false, [targetObject])

    const targetObjectFieldLabel = useMemo(() => targetObject?.connection?.type?.terminology?.fieldLabel, [targetObject?.connection?.type?.terminology?.fieldLabel])
    const supportsFieldSyncMode = useMemo(() => targetMode?.supportsFieldSyncMode, [targetMode?.supportsFieldSyncMode])
    const requiresIdentity = useMemo(() => targetMode?.requiresIdentity || false, [targetMode?.requiresIdentity])
    const validIdentity = useMemo(() => requiresIdentity ? !!identity?.model : true, [identity?.model, requiresIdentity])
    const missingRequiredFieldNames = useMemo(
        () =>
            [SyncMode.Create, SyncMode.UpdateOrCreate].includes(syncMode)
                ? getMissingRequiredFields({
                    mappings,
                    identityTarget: identity?.target,
                    targetFields: targetObjectFields
                }).map(field => field.name)
                : [],
        [identity?.target, mappings, syncMode, targetObjectFields])
    const hasRequiredFieldErrors = useMemo(() => hasItems(missingRequiredFieldNames) && !targetObject?.properties?.optionalTargetMappings && validIdentity, [missingRequiredFieldNames, targetObject?.properties?.optionalTargetMappings, validIdentity])
    const refreshFieldsDisabled = useMemo(() => modelLoading || targetFieldsLoading || !(targetConnection && targetObject), [modelLoading, targetConnection, targetFieldsLoading, targetObject])
    const previewDisabled = useMemo(() =>
      !mappings.some(m => (isModelMapping(m) || isOverrideMapping(m)) && !!m.target) && targetMode?.mode !== SyncMode.Remove,
      [mappings, targetMode?.mode]
    );
    const { reachableFields: reachables, reachableFieldsetIds } = useMemo(() => {
      const field = identity.model ?? (isModelMapping(mappings?.[0]) ? (mappings?.[0] as FieldMappingFragment)?.model : undefined);
      return getReachableFields(field?.fieldset?.id);
  }, [getReachableFields, identity.model?.fieldset.id, mappings, requiresIdentity])
    const reachableFields = useMemo(() => hasItems(reachables) ? reachables : modelFields, [modelFields, reachables])
    const [targetOptions, identityTargetOptions] = useMemo(() => {
        return isSourceOnly ? [targetObjectFields, targetObjectFields] : getTargetOptions(targetObjectFields, identity, mappings, syncMode)
    }, [targetObjectFields, identity, mappings, syncMode, isSourceOnly])
    const identityMappingOptions = useMemo(() => {
        return optionalTargetMappings ? removeExistingMappings(modelFields, mappings.concat([identity])) : modelFields
    }, [identity, mappings, modelFields, optionalTargetMappings])
    const identityFunctions = useMemo(() => {
        return identity?.target?.identityFunctions || []
    }, [identity?.target?.identityFunctions])
    const identityOnly = targetMode?.mode === SyncMode.Remove && targetMode?.requiresIdentity
    // get identity functions with supportsCreateMode set to true
    const identityFunctionsForCreate = useMemo(() => {
      return identityFunctions
        .filter(fn => fn.supportsCreateMode)
        .filter(
          fn => fn.compatibleTypes.includes(identity?.model?.type) || fn.compatibleTypes.length == 0
        );
    }, [identityFunctions, identity]);
    const identityFunctionsForUpdate = useMemo(() => {
        return identityFunctions
          .filter(fn => fn.compatibleTypes.includes(identity?.model?.type) || fn.compatibleTypes.length == 0);
    }, [identityFunctions, identity])

    useLayoutEffect(() => {
        if (checkMappings) {
            const availableTargetFields = getRemainingTargetFields({
                targetFields: targetObjectFields.filter(targetField => supportsSyncMode(targetField, syncMode)),
                identity,
                mappings
            })
            const remainingMappings = removeExistingMappings(reachableFields, requiresIdentity && identity ? mappings.concat([identity]) : mappings)
            if (mappings.every(mapping => (isModelMapping(mapping) || isOverrideMapping(mapping)) && mapping.target)) {
                if (optionalTargetMappings) {
                    hasItems(remainingMappings) && append(EMPTY_MAPPING, { shouldFocus: false })
                } else {
                    hasItems(availableTargetFields) && append(EMPTY_MAPPING, { shouldFocus: false })
                }
            }
            setCheckMappings(null)
        }
    }, [checkMappings, optionalTargetMappings, append, identity, mappings, syncMode, targetObjectFields, reachableFields, requiresIdentity])


    const scrollRefs = useRef<Record<string | number, HTMLDivElement | null>>({})

    // Todo -> Add comments
    const scrollToMapping = useCallback((id: string | number) => {
        scrollElementIntoViewWithDrawer(scrollRefs.current[id]);
    }, [])

    const buttonRefs = useRef<Record<string | number, HTMLButtonElement | null>>({})

    const focusOnMappingButton = useCallback((id?: string) => {
        const _id = id || `${bottomNavConfig.mappingIndex}${bottomNavConfig.config}`
        // This is the only way to prevent the focus from being triggered before reach-hook-form is done updating
        setTimeout(() => buttonRefs.current[_id]?.focus(), 0)
    }, [bottomNavConfig.config, bottomNavConfig.mappingIndex])

    // Todo -> Add comments
    const [autoSelectedModelId, setAutoSelectedModelId] = useState<string>('')
    const [popovers, setPopovers] = useState<Array<{ id: string, y?: number, x?: number, show?: boolean }>>([])
    const triggerPopoverChip = useCallback(({ x, y }: { x: number, y: number }) => {
        const id = uuid4()
        setPopovers(s => s.concat([{ id, x, y, show: true }]))
        setTimeout(() => {
            setPopovers(s => s.map((v) => v.id === id ? ({ ...v, show: false }) : v))
        }, 1000) //1000

        setTimeout(() => {
            setPopovers(s => s.filter((v) => v.id !== id))
        }, 1500) //1500

    }, [setPopovers])

    /**
     * This function is used to determine if the remove button should be hidden
     * If there are multiple mappigns, the remove button should never be hidden
     * If there is only one mapping, the remove button should be hidden if the mapping is empty
     * If there is multiple mappings, the remove button should be hidden if the mapping is empty
     */
    const isRemoveHidden = useCallback((idx: number) => {
        if (idx > 0 || mappings.length > 1) return false
        const mapping = mappings?.[idx]
        return isModelMapping(mapping) ? !mapping?.model : !mapping?.overrideValue
    }, [mappings, getValues])

    // Todo -> Add comments
    const refreshTarget = async () => {
        const { data } = await getTargetObject({
            variables: {
                connectionId: targetConnection.id,
                targetObject: targetObject.id,
                refresh: true
            }
        })
        await void refetchModel();
        reset(handleMappingsUpdates(getValues(), data?.targetObject?.fields ?? []));
      }

    // Todo -> Add comments
    const handleAdd = useCallback(() => {
        append(EMPTY_MAPPING, { shouldFocus: true })
        setBottomNavConfig({ config: FIELD_MAPPING_TYPE.MAPPING_SOURCE, mappingIndex: fields.length })
        // setShowBottomNav(true)
    }, [append, fields.length])

    // Todo -> Add comments
    const handleAddFields = useCallback((fieldType: FIELD_MAPPING_TYPE, mappingIndex: number, fieldIds: string[]) => {
        const fieldMappingAtIndex = fields[mappingIndex]
        switch (fieldType) {
            case FIELD_MAPPING_TYPE.IDENTITY_SOURCE:
                {
                    const modelField = identityMappingOptions.find(v => v.id === fieldIds[0])
                    setValue("identity.model", modelField)
                    // Add a single field as the identity source
                    if (isCreatingTargets) {
                        const newField = {
                            id: modelField?.label,
                            name: modelField?.label,
                            type: modelField?.type
                        } as TargetFieldFragment
                        setValue("identity.target", newField)
                        setValue("identity.newField", true)
                    } else {
                        const targetField = getValues("identity.target")
                        setValue(
                            "identity.target",
                            targetField == null
                                ? getMatchingTargetField({
                                    modelField: modelFields.find(f => f.id === fieldIds[0]),
                                    targetFields: targetObjectFields,
                                    mappings,
                                    identity,
                                    isIdentity: true
                                })
                                : targetField
                        )
                        setValue("identity.newField", false)
                    }
                    setSelectedIdentitySourceId([])
                    scrollToMapping(0)
                }
                focusOnMappingButton()
                break
            case FIELD_MAPPING_TYPE.IDENTITY_TARGET:
                // Add a single field as the identity destination
                setValue("identity.target", identityTargetOptions.find(v => v.id === fieldIds[0]))
                setSelectedIdentityTargetId([])
                scrollToMapping(0)
                setShowBottomNav(false)
                focusOnMappingButton()
                break
            case FIELD_MAPPING_TYPE.MAPPING_SOURCE:
                /**
                 * If multiple fields are added at the same time, the identity source field is filtered out
                 * This is only an edgecase during `AddAll`
                 */
                if (fieldIds.length > 1) {
                    fieldIds = fieldIds.filter(id => identity.model?.id !== id)
                }
                replace([
                    // Fields before the selected index
                    ...fields.slice(0, mappingIndex),
                    // Upsert & Populate selected field(s) at the selected index
                    ...fieldIds.map((id, i) => {
                      const modelField = modelFields.find(f => f.id === id)
                        if (isSourceOnly) {
                            return ({
                                model: modelField,
                                target: targetOptions[0]
                            })
                        } else if (isCreatingTargets) {
                            return {
                              model: modelField,
                              target: {
                                ...Object.assign({} as TargetFieldFragment, {
                                  id: modelField?.label,
                                  name: modelField?.label
                                })
                              },
                              newField: true
                            };
                        } else {
                            if (!fieldMappingAtIndex.target) {
                                return {
                                  model: modelField,
                                  target: getMatchingTargetField({
                                    modelField: modelField,
                                    targetFields: targetOptions,
                                    mappings: mappings,
                                    identity: identity
                                  }),
                                  newField: false
                                };
                            } else if (fieldMappingAtIndex.target && i === 0) {
                                return {
                                  model: modelField,
                                  target: fieldMappingAtIndex.target
                                };
                            } else {
                                return {
                                  model: modelField,
                                  overrideValue: undefined
                                };
                            }
                        }
                    }),
                    // Fields after the selected index
                    ...fields.slice(mappingIndex + 1, fields.length)
                ])
                scrollToMapping(mappingIndex + fieldIds.length)

                if (mappingIndex + 1 === fields.length) {
                    handleAdd()
                }
                break
            case FIELD_MAPPING_TYPE.MAPPING_TARGET:
                replace([
                    // Fields before the selected index
                    ...fields.slice(0, mappingIndex),
                    {
                        ...fieldMappingAtIndex,
                        ...Object.assign({ fieldMappingAtIndex }, { newField: false }),
                        target: targetOptions?.find(f => f.id === fieldIds[0]) || null
                    },
                    // Fields after the selected index
                    ...fields.slice(mappingIndex + 1, fields.length)
                ])
                setSelectedTargetFieldIds([])
                setShowBottomNav(false)
                // focusOnMappingButton(`${mappingIndex + 1}${FIELD_MAPPING_TYPE.MAPPING_SOURCE}`)
                focusOnMappingButton(`${mappingIndex}${FIELD_MAPPING_TYPE.MAPPING_TARGET}`)
                scrollToMapping(mappingIndex + 1)
                break
            default:
                break
        }
        setIsDirty(true)
    }, [fields, focusOnMappingButton, getValues, handleAdd, identity, identityMappingOptions, identityTargetOptions, isCreatingTargets, isSourceOnly, mappings, modelFields, replace, scrollToMapping, setIsDirty, setValue, targetObjectFields, targetOptions])

    // Todo -> Add comments
    const handleDelete = useCallback((mappingIndex: number, fieldsLength: number) => {
        remove(mappingIndex)
        if (mappingIndex === 0 && fieldsLength === 1) {
            append(EMPTY_MAPPING)
        }
        setIsDirty(true)
    }, [setIsDirty, append, remove])

    // Todo -> Add comments
    const identitySourceClick = useCallback((type: FIELD_MAPPING_TYPE, idx: number) => {
        setBottomNavConfig({ config: type, mappingIndex: idx })
        // Clear the preseelected model id
        setAutoSelectedModelId('')
        setShowBottomNav(true)
    }, [])

    // Todo -> Add comments
    const identityTargetClick = useCallback((type: FIELD_MAPPING_TYPE, idx: number) => {
        if (!isCreatingTargets) {
            setBottomNavConfig({ config: type, mappingIndex: idx })
            setShowBottomNav(true)
        } else {
            setShowBottomNav(false)
            setShowStringInputDialog(true)
            setStringInputDialogConfig({
                type: "create",
                input: identity?.target?.name || identity.model?.label,
                connectionName: targetConnection?.name || "destination",
                objectType: getTargetConnectionObjectLabel(targetConnection.type.id),
                targetObjectName: targetObject?.name || "",
                connectionId: targetConnection?.id,
                handleSave: (str: string) => {
                    setValue("identity", {
                        newField: true,
                        ...Object.assign(identity, {
                            target: {
                                ...identity.target,
                                id: str,
                                name: str
                            }
                        })
                    })
                    setIsDirty(true)
                    return
                }
            })
        }
    }, [identity, isCreatingTargets, setIsDirty, setValue, targetConnection?.id, targetConnection?.name, targetConnection.type.id, targetObject?.name])

    // Todo -> Add comments
    const mappingSourceClick = useCallback((type: FIELD_MAPPING_TYPE, idx: number) => {
        setBottomNavConfig({ config: type, mappingIndex: idx })
        // Clear the preseelected model id
        setAutoSelectedModelId('')
        setShowBottomNav(true)
    }, [])

    // Todo -> Add comments
    const mappingTargetClick = useCallback((type: FIELD_MAPPING_TYPE, idx: number) => {
        if (!isCreatingTargets) {
            setBottomNavConfig({ config: type, mappingIndex: idx })
            setShowBottomNav(true)
        } else {
            // TODO - Cleanup
            const currentMapping = mappings[idx]
            if (supportsFieldTypeSelection) {
                setShowNewFieldDialog(true)
                setNewFieldDialogConfig({
                    input: currentMapping.target?.name || '',
                    remoteFieldTypeId: currentMapping?.target?.remoteFieldTypeId || "",
                    connectionName: targetConnection?.name,
                    objectType: getTargetConnectionObjectLabel(targetConnection?.type?.id),
                    fieldTypes: targetConnection?.fieldTypes || [],
                    connectionId: !customProperty && targetConnection?.id,
                    handleSave: (typedValue: string, type?: RemoteFieldTypeFragment) => {
                        replace([
                            // Fields before the selected index
                            ...fields.slice(0, idx),
                            // Field to be updated
                            Object.assign(
                                fields[idx],
                                {
                                    newField: true,
                                    syncMode: undefined,
                                    target: {
                                        id: typedValue,
                                        name: typedValue,
                                        type: type?.ptType,
                                        remoteFieldTypeId:
                                            type?.remoteId
                                    }
                                }
                            ),
                            // Fields after the selected index
                            ...fields.slice(idx + 1, fields.length)
                        ])
                        setIsDirty(true)
                        return
                    }
                })
            } else {
                // Create new field with no type
                setShowStringInputDialog(true)
                setStringInputDialogConfig({
                    type: "create",
                    input: currentMapping.target?.name || '',
                    connectionName: targetConnection?.name || "destination",
                    targetObjectName: targetObject.name || "",
                    objectType: getTargetConnectionObjectLabel(targetConnection.type.id),
                    connectionId: !customProperty && targetConnection.id,
                    handleSave: (typedValue: string) => {
                        replace([
                            // Fields before the selected index
                            ...fields.slice(0, idx),
                            // Field to be updated
                            Object.assign(
                                fields[idx],
                                {
                                    newField: true,
                                    target: {
                                        name: typedValue,
                                        id: customProperty
                                            ? `${customProperty}.${typedValue}`
                                            : typedValue
                                    }
                                }
                            ),
                            // Fields after the selected index
                            ...fields.slice(idx + 1, fields.length)
                        ])
                        setIsDirty(true)
                        return
                    }
                })
            }
        }
    }, [customProperty, fields, isCreatingTargets, mappings, replace, setIsDirty, supportsFieldTypeSelection, targetConnection?.fieldTypes, targetConnection.id, targetConnection?.name, targetConnection.type.id, targetObject.name])

    // Todo -> Add comments
    // prettier-ignore
    const FIELD_MAPPING_BOTTOM_NAV_CONFIGS = {
      [FIELD_MAPPING_TYPE.NONE]: {},
      [FIELD_MAPPING_TYPE.FILTER_TARGET]: {},
      [FIELD_MAPPING_TYPE.FILTER_MODEL]: {},
      [FIELD_MAPPING_TYPE.OVERRIDE]: {},
      [FIELD_MAPPING_TYPE.IDENTITY_SOURCE]: {
        // BOTTOM NAV
        fieldType: FIELD_MAPPING_TYPE.IDENTITY_SOURCE,
        title: `Select model identity field`,
        fields: identityMappingOptions,
        selectedFieldIds: selectedIdentitySourceId,
        setSelectedFieldIds: setSelectedIdentitySourceId,
        mappedFieldIds: mappings
          .filter(isModelMapping)
          .map(m => m.model?.id)
          .concat(identity?.model?.id),
        disabledFieldIds: [],
        multiSelect: false,
        handleAddFields: handleAddFields,
        currentFieldId: identity?.model?.id,
        linkButton: undefined,
        // FIELD BUTTON
        fieldButton: useCallback(
          () => ({
            fieldType: FIELD_MAPPING_TYPE.IDENTITY_SOURCE,
            mappingIndex: 0,
            placeholder: `Select model identity field`,
            disabled: false, // <- TODO - Check
            sanitize: false,
            iconId:
              (identity?.model as ModelField)?.enrichmentFieldset?.connection?.type?.id ??
              identity?.model?.fieldset?.connection?.type?.id,
            label: identity?.model?.label,
            selected:
              bottomNavConfig.config === FIELD_MAPPING_TYPE.IDENTITY_SOURCE && showBottomNav,
            onClick: identitySourceClick
          }),
          [
            bottomNavConfig.config,
            identity?.model?.fieldset?.connection?.type?.id,
            identity?.model?.label,
            identitySourceClick,
            showBottomNav
          ]
        ),
        // FieldDetail
        fieldDetail: useCallback(() => {
          if (!isModelMapping(identity)) {
            return {};
          }
          const field = modelFields?.find(f => f.id === identity?.model?.id) as ModelField;
          const fieldset = field?.fieldset;
          return {
            logoId: fieldset?.connection?.type?.id,
            enrichmentLogoId: field?.enrichmentFieldset?.connection?.type?.id,
            modelId: fieldset?.id,
            modelName: fieldset?.name,
            columnName: field?.sourceName,
            type: field?.type
          };
        }, [identity])
      },
      [FIELD_MAPPING_TYPE.IDENTITY_TARGET]: {
        fieldType: FIELD_MAPPING_TYPE.IDENTITY_TARGET,
        title: `Select identity ${targetObjectFieldLabel?.singular?.toLowerCase() || 'field'}`,
        fields: isSourceOnly
          ? targetObjectFields
          : targetObjectFields.filter(f => f.supportsIdentity && !f.isFieldBag),
        selectedFieldIds: selectedIdentityTargetId,
        setSelectedFieldIds: setSelectedIdentityTargetId,
        disabledFieldIds: targetObjectFields
          .filter(f => !identityTargetOptions.find(o => o.id === f.id))
          .map(v => v.id),
        multiSelect: false,
        currentFieldId: identity?.target?.id,
        handleAddFields: handleAddFields,
        linkButton: !isCreatingTargets
          ? undefined
          : {
              label: 'Edit name',
              onClick: () => {
                if (supportsFieldTypeSelection) {
                  setShowNewFieldDialog(true);
                  setNewFieldDialogConfig({
                    input: targetObject?.properties?.optionalTargetMappings
                      ? identity.target?.name
                      : identity.model?.label,
                    remoteFieldTypeId: identity.target?.remoteFieldTypeId,
                    connectionName: targetConnection?.name,
                    objectType: getTargetConnectionObjectLabel(targetConnection?.type.id),
                    fieldTypes: targetConnection.fieldTypes || [],
                    connectionId: targetConnection.id,
                    handleSave: (str: string, type?: RemoteFieldTypeFragment) => {
                      setValue('identity', {
                        newField: true,
                        ...Object.assign(identity, {
                          target: {
                            ...identity.target,
                            id: str,
                            name: str,
                            type: type?.ptType,
                            remoteFieldTypeId: type?.remoteId
                          }
                        })
                      });
                      setShowNewFieldDialog(false);
                      setShowBottomNav(false);
                      setIsDirty(true);
                      return;
                    }
                  });
                } else {
                  setShowStringInputDialog(true);
                  setStringInputDialogConfig({
                    type: 'create',
                    input: getValues('identity.model.label'),
                    connectionName: targetConnection?.name || 'destination',
                    targetObjectName: targetObject?.name || '',
                    objectType: getTargetConnectionObjectLabel(targetConnection.type.id),
                    connectionId: targetConnection?.id,
                    handleSave: (str: string) => {
                      setValue('identity', {
                        newField: true,
                        ...Object.assign(identity, {
                          target: {
                            ...identity.target,
                            id: str,
                            name: str
                          }
                        })
                      });
                      setShowStringInputDialog(false);
                      setIsDirty(true);
                      return;
                    }
                  });
                }
              }
            },
        // FIELD BUTTON
        fieldButton: useCallback(
          () => ({
            fieldType: FIELD_MAPPING_TYPE.IDENTITY_TARGET,
            mappingIndex: 0,
            placeholder: `Select ${targetConnection.type.name} identity ${
              targetObjectFieldLabel?.singular?.toLowerCase() || 'field'
            }`,
            disabled: isCreatingTargets && !identity.target,
            iconId: targetConnection.type.id,
            connectionId: targetConnection.id,
            sanitize: isCreatingTargets,
            label: identity?.target?.name,
            isNew: !!identity?.newField,
            selected:
              bottomNavConfig.config === FIELD_MAPPING_TYPE.IDENTITY_TARGET && showBottomNav,
            onClick: identityTargetClick
          }),
          [
            bottomNavConfig.config,
            identity?.newField,
            identity.target,
            identityTargetClick,
            isCreatingTargets,
            showBottomNav,
            targetConnection.id,
            targetConnection.type.id
          ]
        ),
        // FieldDetail
        fieldDetail: {
          logoId: targetConnection?.type?.id,
          modelId: identity.model?.fieldset.id,
          modelName: undefined,
          columnName:
            supportsFieldTypeSelection || supportsFieldCreation
              ? identity?.target?.name
              : identity?.target?.id,
          type: identity?.target?.type || identity?.model?.type
        }
      },
      [FIELD_MAPPING_TYPE.MAPPING_SOURCE]: {
        fieldType: FIELD_MAPPING_TYPE.MAPPING_SOURCE,
        title: `Select model fields`,
        fields: reachableFields,
        selectedFieldIds: selectedSourceFieldIds,
        setSelectedFieldIds: setSelectedSourceFieldIds,
        mappedFieldIds: mappings
          .filter(isModelMapping)
          .map(m => m.model?.id)
          .concat(identity?.model?.id),
        disabledFieldIds: !optionalTargetMappings
          ? []
          : isWebhook
            ? mappings.filter(isModelMapping).map(m => m.model?.id)
            : reachableFields
                .filter(f => mappings.filter(isModelMapping).find(v => v?.model?.id === f.id))
                .map(v => v.id),
        multiSelect: true,
        currentFieldId:
          (fields[bottomNavConfig.mappingIndex] as FieldMappingFragment)?.model?.id || null,
        // TODO
        handleAddFields: handleAddFields,
        linkButton:
          isSourceOnly || isCreatingTargets
            ? undefined
            : {
                label: 'Use custom text',
                onClick: () => {
                  const currentMapping = mappings[bottomNavConfig.mappingIndex];
                  setShowStringInputDialog(true);
                  setStringInputDialogConfig({
                    type: 'override',
                    input:
                      (isOverrideMapping(currentMapping) && currentMapping?.overrideValue) || '',
                    connectionName: targetConnection?.name || 'destination',
                    targetObjectName: targetObject?.name || '',
                    objectType: getTargetConnectionObjectLabel(targetConnection?.type.id),
                    handleSave: (typedValue: string) => {
                      replace([
                        // Fields before the selected index
                        ...fields.slice(0, bottomNavConfig.mappingIndex),
                        Object.assign(fields[bottomNavConfig.mappingIndex], {
                          target: isSourceOnly
                            ? targetOptions[0]
                            : fields[bottomNavConfig.mappingIndex].target,
                          overrideValue: typedValue,
                          __typename: 'OverrideFieldMapping',
                          model: null
                        }),
                        // Fields after the selected index
                        ...fields.slice(bottomNavConfig.mappingIndex + 1, fields.length)
                      ]);
                      setShowStringInputDialog(false);
                      setIsDirty(true);
                      setShowBottomNav(false);
                      return;
                    }
                  });
                }
              },
        // FIELD BUTTON
        fieldButton: useCallback(
          (field: Mapping, idx: number) => ({
            fieldType: FIELD_MAPPING_TYPE.MAPPING_SOURCE,
            mappingIndex: idx,
            placeholder: `Select model field`,
            error: isUnreachableMapping(mappings[idx], reachableFieldsetIds) && 'Unreachable field',
            disabled: false,
            iconId: isModelMapping(field)
              ? (field?.model as ModelField)?.enrichmentFieldset?.connection?.type?.id ?? field
                  ?.model?.fieldset.connection.type.id
              : undefined,
            label: isModelMapping(field) ? field?.model?.label : field.overrideValue || null,
            isNew: false,
            selected:
              isEqual(bottomNavConfig, {
                config: FIELD_MAPPING_TYPE.MAPPING_SOURCE,
                mappingIndex: idx
              }) && showBottomNav,
            onClick: mappingSourceClick
          }),
          [bottomNavConfig, mappingSourceClick, mappings, reachableFieldsetIds, showBottomNav]
        ),
        // FieldDetail
        fieldDetail: useCallback((mapping: Mapping) => {
          if (!isModelMapping(mapping)) {
            return {};
          }
          // TODO @poindexd hack to look up parent fieldset for enriched fields
          // could this be hydrated or sent from backend
          const field = modelFields?.find(f => f.id === mapping?.model?.id) as ModelField;
          const fieldset = field?.fieldset;
          return {
            logoId: fieldset?.connection?.type?.id,
            enrichmentLogoId: field?.enrichmentFieldset?.connection?.type?.id,
            modelId: fieldset?.id,
            modelName: fieldset?.name,
            columnName: field?.sourceName,
            type: field?.type
          };
        }, [])
      },
      [FIELD_MAPPING_TYPE.MAPPING_TARGET]: {
        fieldType: FIELD_MAPPING_TYPE.MAPPING_TARGET,
        title: `Select ${targetObjectFieldLabel?.singular?.toLowerCase() || 'field'}`,
        fields: isSourceOnly
          ? targetObjectFields
          : targetObjectFields.filter(
              f => f.isFieldBag || (f.id !== identity?.target?.id && supportsSyncMode(f, syncMode))
            ),
        selectedFieldIds: selectedTargetFieldIds,
        setSelectedFieldIds: setSelectedTargetFieldIds,
        disabledFieldIds: targetObjectFields
          .filter(f => !targetOptions.find(o => o.id === f.id))
          .map(v => v.id),
        multiSelect: false,
        handleAddFields: handleAddFields,
        currentFieldId: fields[bottomNavConfig.mappingIndex]?.target?.id || null,
        linkButton: !(supportsFieldCreation || supportsFieldTypeSelection || !!customProperty)
          ? undefined
          : {
              label: `Create new ${getTargetConnectionObjectLabel(targetConnection?.type?.id)}`,
              onClick: () => {
                const currentMapping = mappings[bottomNavConfig.mappingIndex];
                const customProperty =
                  targetObject && targetObject.properties?.primaryMetadataObject !== ''
                    ? targetObject?.properties?.primaryMetadataObject
                    : false;
                if (supportsFieldTypeSelection) {
                  // Create new field with type
                  setShowNewFieldDialog(true);
                  setNewFieldDialogConfig({
                    input: currentMapping.target?.name || '',
                    remoteFieldTypeId: currentMapping?.target?.remoteFieldTypeId || '',
                    connectionName: targetConnection?.name,
                    objectType: getTargetConnectionObjectLabel(targetConnection.type.id),
                    fieldTypes: targetConnection.fieldTypes || [],
                    connectionId: !customProperty && targetConnection.id,
                    handleSave: (typedValue: string, type?: RemoteFieldTypeFragment) => {
                      replace([
                        // Fields before the selected index
                        ...fields.slice(0, bottomNavConfig.mappingIndex),
                        // Field to be updated
                        Object.assign(fields[bottomNavConfig.mappingIndex], {
                          newField: true,
                          syncMode: undefined,
                          target: {
                            id: typedValue,
                            name: typedValue,
                            type: type?.ptType,
                            remoteFieldTypeId: type?.remoteId
                          }
                        }),
                        // Fields after the selected index
                        ...fields.slice(bottomNavConfig.mappingIndex + 1, fields.length)
                      ]);
                      setShowNewFieldDialog(false);
                      setShowBottomNav(false);
                      setIsDirty(true);
                      return;
                    }
                  });
                } else {
                  // Create new field with no type
                  setShowStringInputDialog(true);
                  setStringInputDialogConfig({
                    type: 'create',
                    input: currentMapping.target?.name || '',
                    connectionName: targetConnection?.name || 'destination',
                    targetObjectName: targetObject.name || '',
                    objectType: getTargetConnectionObjectLabel(targetConnection.type.id),
                    connectionId: !customProperty && targetConnection.id,
                    handleSave: (typedValue: string) => {
                      replace([
                        // Fields before the selected index
                        ...fields.slice(0, bottomNavConfig.mappingIndex),
                        // Field to be updated
                        Object.assign(fields[bottomNavConfig.mappingIndex], {
                          newField: true,
                          target: {
                            name: typedValue,
                            id: customProperty ? `${customProperty}.${typedValue}` : typedValue
                          }
                        }),
                        // Fields after the selected index
                        ...fields.slice(bottomNavConfig.mappingIndex + 1, fields.length)
                      ]);
                      setShowStringInputDialog(false);
                      setShowBottomNav(false);
                      setIsDirty(true);
                      return;
                    }
                  });
                }
              }
            },
        // FIELD BUTTON
        fieldButton: useCallback(
          (field: Mapping, idx: number) => {
            const currentMapping = mappings[idx];
            return {
              fieldType: FIELD_MAPPING_TYPE.MAPPING_TARGET,
              mappingIndex: idx,
              placeholder: `Select ${targetConnection.type.name} ${
                targetObjectFieldLabel?.singular?.toLowerCase() || 'field'
              }`,
              disabled: isCreatingTargets && currentMapping?.target == null,
              iconId: targetConnection.type.id,
              label: field.target?.name,
              isNew: (isModelMapping(currentMapping) && currentMapping?.newField) || false,
              selected:
                isEqual(bottomNavConfig, {
                  config: FIELD_MAPPING_TYPE.MAPPING_TARGET,
                  mappingIndex: idx
                }) && showBottomNav,
              connectionId: targetConnection.id,
              sanitize: supportsFieldCreation || supportsFieldTypeSelection,
              onClick: mappingTargetClick
            };
          },
          [
            bottomNavConfig,
            isCreatingTargets,
            mappingTargetClick,
            mappings,
            showBottomNav,
            supportsFieldCreation,
            supportsFieldTypeSelection,
            targetConnection.id,
            targetConnection.type.id
          ]
        ),
        // FieldDetail
        fieldDetail: (field: Mapping, idx: number) => ({
          logoId: targetConnection.type.id,
          modelId: undefined,
          modelName: undefined,
          columnName:
            supportsFieldTypeSelection || supportsFieldCreation
              ? field.target?.name
              : field.target?.id,
          type: field.target?.type || (isModelMapping(field) && field.model?.type) || undefined,
          options: supportsFieldSyncMode && <FieldSyncMode index={idx} />
        })
      }
    };


    return (
      <StageCard
        step={3}
        hasStickyHeader
        header={
          <>
            {isSourceOnly ? 'Specify fields' : 'Specify field mappings'}
            {hasRequiredFieldErrors && (
              <span className="mx-8 mt-1">
                <ErrorText>
                  Required {targetObject?.name} field mappings:{' '}
                  {missingRequiredFieldNames.join(', ')}
                </ErrorText>
              </span>
            )}
            <div className="absolute right-6 top-4.5 space-x-1 text-xs font-normal">
              <LinkButton tabIndex={-1} onClick={toggleShowFieldDetails}>
                {showFieldDetails ? 'Hide' : 'Show'} field details
              </LinkButton>
              {!isSourceOnly && (
                <>
                  <span className="text-gray-500">·</span>
                  <LinkButton
                    tabIndex={-1}
                    disabled={refreshFieldsDisabled}
                    onClick={refreshTarget}
                  >
                    Refresh fields
                  </LinkButton>
                </>
              )}
            </div>
          </>
        }
        hasErrors={hasRequiredFieldErrors}
        footer={
          <div className="flex w-full justify-end p-6">
            <Button disabled={previewDisabled} onClick={validateForPreview}>
              Preview
            </Button>
          </div>
        }
      >
        {bottomNavConfig.config !== FIELD_MAPPING_TYPE.NONE &&
          bottomNavConfig.config !== FIELD_MAPPING_TYPE.OVERRIDE &&
          bottomNavConfig.config !== FIELD_MAPPING_TYPE.FILTER_MODEL &&
          bottomNavConfig.config !== FIELD_MAPPING_TYPE.FILTER_TARGET && (
            <PageLayout
              bottomNavShow={showBottomNav}
              bottomNavContent={
                <>
                  {popovers.map(v => (
                    <Popover
                      key={v.id}
                      id={v.id}
                      x={v.x}
                      y={v.y}
                      show={v.show}
                      enterDuration={75} // 75
                      leaveDuration={75} // 75
                      enterAnimation={ANIMATION_VARIANT.FLYUP}
                      leaveAnimation={ANIMATION_VARIANT.FADE}
                      preventFocus={true}
                    >
                      <Chip color={COLOR.SUCCESS} disabled>
                        Added
                      </Chip>
                    </Popover>
                  ))}
                  <FieldMappingBottomNav
                    autoSelectedModelId={autoSelectedModelId}
                    setAutoSelectedModelId={setAutoSelectedModelId}
                    mappingIndex={bottomNavConfig.mappingIndex}
                    targetConnection={targetConnection}
                    // fieldType={bottomNavConfig.config}
                    setShowBottomNav={setShowBottomNav}
                    {...FIELD_MAPPING_BOTTOM_NAV_CONFIGS[bottomNavConfig.config]}
                    triggerPopoverChip={triggerPopoverChip}
                    focusOnMappingButton={focusOnMappingButton}
                    isFocusTrapDisabled={showNewFieldDialog || showStringInputDialog}
                  />
                </>
              }
            />
          )}

        <div className="px-6">
          <div
            className="flex flex-col items-center gap-4"
            ref={el => (scrollRefs.current[0] = el)}
          >
            {requiresIdentity && (
              <>
                <div className="w-full pl-2">
                  <Label>Identity mapping</Label>
                </div>
                <div className={clsx('grid w-full grid-cols-[1fr,1.25rem,1fr,2.5rem] gap-x-2')}>
                  <FieldMappingButton
                    buttonRef={el =>
                      (buttonRefs.current[`${0}${FIELD_MAPPING_TYPE.IDENTITY_SOURCE}`] = el)
                    }
                    {...FIELD_MAPPING_BOTTOM_NAV_CONFIGS[
                      FIELD_MAPPING_TYPE.IDENTITY_SOURCE
                    ].fieldButton()}
                    fieldDetail={
                      showFieldDetails && (
                        <FieldDetail
                          defaultStyles="self-stretch p-2 pl-2.25"
                          {...FIELD_MAPPING_BOTTOM_NAV_CONFIGS[
                            FIELD_MAPPING_TYPE.IDENTITY_SOURCE
                          ].fieldDetail()}
                        />
                      )
                    }
                  />
                  <Icon name="ArrowNarrowRight" className="my-2 h-5 w-5 text-indigo-500" />
                  <FieldMappingButton
                    buttonRef={el =>
                      (buttonRefs.current[`${0}${FIELD_MAPPING_TYPE.IDENTITY_TARGET}`] = el)
                    }
                    {...FIELD_MAPPING_BOTTOM_NAV_CONFIGS[
                      FIELD_MAPPING_TYPE.IDENTITY_TARGET
                    ].fieldButton()}
                    fieldDetail={
                      showFieldDetails && (
                        <FieldDetail
                          defaultStyles="self-stretch p-2 pl-2.25"
                          {...FIELD_MAPPING_BOTTOM_NAV_CONFIGS[FIELD_MAPPING_TYPE.IDENTITY_TARGET]
                            .fieldDetail}
                        />
                      )
                    }
                  />
                  {/* Placeholders to keep uniform layout */}
                  <div className="my-2 flex gap-2">
                    <ParamButton hidden />
                    <ParamButton hidden />
                  </div>
                </div>
                {((syncMode === SyncMode.Update && hasItems(identityFunctionsForUpdate)) ||
                  ((syncMode == SyncMode.Create || syncMode == SyncMode.UpdateOrCreate) &&
                    hasItems(identityFunctionsForCreate))) && (
                  <div className="flex w-full items-center justify-center">
                    <MappingIdentityFunction
                      setIsDirty={setIsDirty}
                      source={identity.model}
                      identityFunctions={
                        syncMode === SyncMode.Update
                          ? identityFunctionsForUpdate
                          : identityFunctionsForCreate
                      }
                    />
                    <div className="flex gap-2">
                      <ParamButton hidden />
                      <ParamButton hidden />
                    </div>
                  </div>
                )}
                {!identityOnly && <Divider />}
              </>
            )}

            {!identityOnly && (
              <>
                <div className="w-full pl-2">
                  <Label>Field mappings</Label>
                </div>
                {fields.map((field, mappingIndex) => {
                  return (
                    <Transition
                      key={mappingIndex}
                      appear={true}
                      show={true}
                      className="flex w-full opacity-0"
                      enter="transition-all ease-in duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-all ease-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div
                        className={clsx(
                          'grid w-full items-start gap-x-2',
                          isSourceOnly
                            ? 'grid-cols-[1fr,2.5rem]'
                            : 'grid-cols-[1fr,1.25rem,1fr,2.5rem]'
                        )}
                        ref={el => (scrollRefs.current[mappingIndex + 1] = el)}
                      >
                        <FieldMappingButton
                          buttonRef={el =>
                            (buttonRefs.current[
                              `${mappingIndex}${FIELD_MAPPING_TYPE.MAPPING_SOURCE}`
                            ] = el)
                          }
                          {...FIELD_MAPPING_BOTTOM_NAV_CONFIGS[
                            FIELD_MAPPING_TYPE.MAPPING_SOURCE
                          ].fieldButton(field, mappingIndex)}
                          fieldDetail={
                            showFieldDetails && (
                              <FieldDetail
                                defaultStyles="self-stretch p-2 pl-2.25"
                                {...FIELD_MAPPING_BOTTOM_NAV_CONFIGS[
                                  FIELD_MAPPING_TYPE.MAPPING_SOURCE
                                ].fieldDetail(field)}
                              />
                            )
                          }
                        />
                        {!isSourceOnly && (
                          <Icon name="ArrowNarrowRight" className="my-2 h-5 w-5 text-gray-500" />
                        )}
                        {!isSourceOnly && (
                          <FieldMappingButton
                            buttonRef={el =>
                              (buttonRefs.current[
                                `${mappingIndex}${FIELD_MAPPING_TYPE.MAPPING_TARGET}`
                              ] = el)
                            }
                            {...FIELD_MAPPING_BOTTOM_NAV_CONFIGS[
                              FIELD_MAPPING_TYPE.MAPPING_TARGET
                            ].fieldButton(field, mappingIndex)}
                            fieldDetail={
                              showFieldDetails && (
                                <FieldDetail
                                  defaultStyles="self-stretch p-2 pl-2.25"
                                  {...FIELD_MAPPING_BOTTOM_NAV_CONFIGS[
                                    FIELD_MAPPING_TYPE.MAPPING_TARGET
                                  ].fieldDetail(field, mappingIndex)}
                                />
                              )
                            }
                          />
                        )}
                        <div className="my-2 flex gap-2">
                          <ParamButton
                            // className={clsx(mappingIndex === 0 && fields.length === 1 && "hidden")}
                            className={clsx(isRemoveHidden(mappingIndex) && 'hidden')}
                            action="delete"
                            onClick={() => handleDelete(mappingIndex, fields.length)}
                          />
                          {/* Add button is only visible hidden if last field mapping in list */}
                          <ParamButton
                            action="add"
                            onClick={handleAdd}
                            hidden={mappingIndex !== fields.length - 1}
                          />
                        </div>
                      </div>
                    </Transition>
                  );
                })}
              </>
            )}
          </div>
        </div>
        {(identity?.model || mappings.some(isModelMapping)) && (
          <StageMappingsFilters
            showBottomNav={showBottomNav}
            setShowBottomNav={setShowBottomNav}
            bottomNavConfig={bottomNavConfig}
            setBottomNavConfig={setBottomNavConfig}
            heading="model"
            showFieldDetails={showFieldDetails}
            mappings={mappings
              .filter(isModelMapping)
              .concat(
                hasItems(getFilterFunctions(identity?.model?.type)) ? [identity as FieldMappingFragment] : []
              )}
            fields={reachableFields.filter(field => hasItems(getFilterFunctions(field?.type)))}
            setIsDirty={setIsDirty}
            buttonRefs={buttonRefs}
            focusOnMappingButton={focusOnMappingButton}
          />
        )}
        {targetMode?.supportsTargetFilters &&
          targetObject?.properties?.supportsTargetFilters &&
          (identity?.model || mappings.some(isModelMapping)) && (
            <StageMappingsFilters
              showBottomNav={showBottomNav}
              setShowBottomNav={setShowBottomNav}
              bottomNavConfig={bottomNavConfig}
              setBottomNavConfig={setBottomNavConfig}
              heading={
                (targetConnection?.name &&
                  targetObject?.name &&
                  clsx(targetConnection?.name, targetObject?.name)) ||
                'target'
              }
              showFieldDetails={showFieldDetails}
              mappings={mappings
                .filter(isModelMapping)
                .concat(
                  hasItems(getFilterFunctions(identity?.model?.type))
                    ? [identity as FieldMappingFragment]
                    : []
                )}
              fields={targetObjectFields?.filter(field => hasItems(field.filterFunctions)) ?? []}
              setIsDirty={setIsDirty}
              buttonRefs={buttonRefs}
              focusOnMappingButton={focusOnMappingButton}
            />
          )}
        <StageMappingsOverrides
          showBottomNav={showBottomNav}
          setShowBottomNav={setShowBottomNav}
          bottomNavConfig={bottomNavConfig}
          setBottomNavConfig={setBottomNavConfig}
          setIsDirty={setIsDirty}
          showFieldDetails={showFieldDetails}
          buttonRefs={buttonRefs}
          focusOnMappingButton={focusOnMappingButton}
        />
        <NewFieldDialog
          show={showNewFieldDialog && !!newFieldDialogConfig}
          dismiss={() => setShowNewFieldDialog(false)}
          input={newFieldDialogConfig?.input as string}
          remoteFieldTypeId={(newFieldDialogConfig?.remoteFieldTypeId as string) || null}
          connectionName={newFieldDialogConfig?.connectionName as string}
          objectType={newFieldDialogConfig?.objectType as string}
          handleSave={newFieldDialogConfig?.handleSave as () => void}
          fieldTypes={(newFieldDialogConfig?.fieldTypes as RemoteFieldTypeFragment[]) || []}
          connectionId={(newFieldDialogConfig?.connectionId as string) || undefined}
        />
        <StringInputDialog
          show={showStringInputDialog && !!stringInputDialogConfig}
          type={stringInputDialogConfig?.type as 'override' | 'create'}
          dismiss={() => setShowStringInputDialog(false)}
          input={stringInputDialogConfig?.input as string}
          connectionName={(stringInputDialogConfig?.connectionName as string) || 'destination'}
          targetObjectName={(stringInputDialogConfig?.targetObjectName as string) || ''}
          objectType={stringInputDialogConfig?.objectType as string}
          handleSave={stringInputDialogConfig?.handleSave as (v: string) => void}
          connectionId={(stringInputDialogConfig?.connectionId as string) || undefined}
        />
      </StageCard>
    );
}

export default StageMappings;
