import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { PermissionTagFragment, ResourceType } from '../../generated/graphql';
import { ConnectionFormValues } from '../../pages/connections/connection-config';
import { BulkSyncForm, FieldsetFormValues, SyncConfigFormValues } from '../../utils';
import { AccessControl } from './access-control';

interface Props {
  resourceType: ResourceType;
}

export function AccessControlInForm({ resourceType }: Props) {
  const { control, getValues, setValue } = useFormContext<
    ConnectionFormValues | FieldsetFormValues | SyncConfigFormValues | BulkSyncForm
  >();

  const tags = useWatch({ control, name: 'tags' });

  const addTag = React.useCallback(
    (tag: PermissionTagFragment) => {
      const tags = getValues('tags');
      if (tags?.find(item => item.id === tag.id)) {
        return;
      }
      setValue('tags', tags?.concat(tag) || [tag], { shouldDirty: true });
    },
    [getValues, setValue]
  );

  const removeTag = React.useCallback(
    (tag: PermissionTagFragment) => {
      const tags = getValues('tags');
      setValue(
        'tags',
        tags.filter(item => item.id !== tag.id),
        { shouldDirty: true }
      );
    },
    [getValues, setValue]
  );

  return (
    <AccessControl
      resourceType={resourceType}
      resourceTags={tags}
      addTag={addTag}
      removeTag={removeTag}
    />
  );
}
