import { Button } from './Button';
import { Footer } from './Footer';
import { Spinner } from './Spinner';

export function Initializing() {
  return (
    <>
      <p className="font-semibold leading-5 text-gray-800">
        Connecting to Polytomic... <Spinner />
      </p>
      <Footer label="Please wait...">
        <Button disabled variant="link">
          Cancel
        </Button>
        <Button disabled>Continue</Button>
      </Footer>
    </>
  );
}
