import { useQuery, useSubscription } from '@apollo/client';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { capitalize } from 'lodash';
import { useMemo, useState } from 'react';
import { Button, Search, Truncator } from '~/components';
import { DataTable } from '~/components/v3';
import { Sheet, SheetContent } from '~/components/v3/Sheet';
import {
  BulkSyncSchemaExecutionsDocument,
  BulkSyncStatusDocument,
  SchemaExecutionStatus
} from '~/generated/graphql';
import { useBannerDispatch, useLocalStorageState } from '~/hooks';
import { emptyCell, getLongLocalTime, sortByNameAndId } from '~/utils';
import {
  getDurationInMilliseconds,
  getFormattedDuration,
  getSyncStatusOrder
} from '~/utils/bulk-sync-history.util';
import { ExecutionStatusDisplay } from '../components/ExecutionStatusDisplay';

interface SyncExecutionRow {
  id: string;
  name: string;
  startedAt?: string;
  completedAt?: string;
  recordCount?: number;
  errorCount?: number;
  status?: SchemaExecutionStatus;
  statusMessage?: string;
}

// Some schema names are "<schema>.<table>", when we want to display "<table>"
const getSchemaName = (isNamespaceRoot: boolean, depth: number, name: string) => {
  // If there's a single namespace, or multiple namespaces and we're at the root level
  if (isNamespaceRoot || depth === 0) {
    return name;
  }
  // Otherwise the name needs to have the schema name before "." removed
  return (
    name
      ?.split('.')
      ?.filter((_, i) => i > 0)
      ?.join('.') ?? name
  );
};

const getColumns = (
  namespaceLabel: string,
  isNamespaceRoot: boolean
): ColumnDef<SyncExecutionRow>[] => [
  {
    id: 'schema',
    accessorKey: 'schema',
    header: () => <span>{namespaceLabel}</span>,
    accessorFn: row => row.id,
    cell: ({ row }) => {
      const name = getSchemaName(isNamespaceRoot, row.depth, row.original.name ?? row.original.id);
      return (
        <Truncator content={name}>
          <span className="truncate">{name}</span>
        </Truncator>
      );
    },
    size: 200,
    sortDescFirst: false,
    enableGlobalFilter: true
  },
  {
    id: 'start',
    header: 'Start time',
    accessorFn: row => row.startedAt,
    cell: ({ row }) => getLongLocalTime(row.original.startedAt) ?? emptyCell,
    size: 200,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    id: 'end',
    header: 'End time',
    accessorFn: row => row.completedAt,
    cell: ({ row }) => getLongLocalTime(row.original.completedAt) ?? emptyCell,
    size: 200,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'duration',
    accessorFn: row => getDurationInMilliseconds(row.startedAt, row.completedAt),
    header: 'Duration',
    cell: ({ row }) =>
      getFormattedDuration(row.original.startedAt, row.original.completedAt) || emptyCell,
    size: 100,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'recordCount',
    accessorFn: row => row.recordCount,
    header: 'Total Records',
    cell: ({ row }) => row.original?.recordCount?.toLocaleString() || emptyCell,
    size: 125,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'errorCount',
    accessorFn: row => row.errorCount,
    header: 'Errored',
    cell: ({ row }) => row.original?.errorCount?.toLocaleString() || emptyCell,
    size: 125,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'status',
    accessorFn: row => getSyncStatusOrder(row.status),
    header: 'Status',
    cell: ({ row }) =>
      (
        <ExecutionStatusDisplay message={row.original.statusMessage} status={row.original.status} />
      ) || emptyCell,
    size: 100,
    sortDescFirst: false,
    enableGlobalFilter: false
  }
];

interface BulkSyncSchemaExecutionDrawerProps {
  id: string;
  schemaLabel: { plural: string; singular: string };
  namespaceLabel: string;
  open: boolean;
  onDismiss: (selected?: string[]) => void;
}

export function BulkSyncSchemaExecutionDrawer({
  id,
  schemaLabel,
  namespaceLabel,
  open,
  onDismiss
}: BulkSyncSchemaExecutionDrawerProps) {
  const [search, setSearch] = useState('');

  const dispatchBanner = useBannerDispatch();

  const { data, loading, refetch } = useQuery(BulkSyncSchemaExecutionsDocument, {
    skip: !open,
    variables: { id },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } })
  });
  const namespaces = data?.bulkSync?.namespaces;
  const objectExecutions = data?.bulkSync?.objectExecutions;

  const isNamespaceRoot = namespaces?.length === 1 && !namespaces[0].id;
  const columns = getColumns(namespaceLabel, isNamespaceRoot);

  // If namespace is an array of length one, only render children, otherwise render namespaces
  const schemas = useMemo(() => {
    const schemas = isNamespaceRoot
      ? [...(namespaces[0].schemas ?? [])].sort(sortByNameAndId)
      : namespaces
          ?.filter(({ id }) => !!id)
          .reduce((acc, namespace) => acc.concat(namespace.schemas ?? []), [])
          .sort(sortByNameAndId);
    return (
      schemas?.reduce((acc, schema) => {
        const execution = objectExecutions?.find(execution => execution.schema.id === schema.id);
        if (execution) {
          acc.push({ ...execution, id: schema.id, name: schema.name });
        }
        return acc;
      }, []) ?? []
    );
  }, [namespaces, objectExecutions, isNamespaceRoot]);

  const [sorting, setSorting] = useLocalStorageState<SortingState>(
    [{ id: 'schema', desc: false }],
    'bulkSyncSchemaExecutionSort'
  );

  useSubscription(BulkSyncStatusDocument, {
    skip: !open,
    variables: { syncId: id },
    onData: ({ data }) => {
      const { activeExecutions } = data.data?.bulkSyncStatus;
      if (activeExecutions?.length) {
        refetch();
      }
    }
  });

  return (
    <Sheet open={open} onOpenChange={open => !open && onDismiss()}>
      <SheetContent
        storageKey="bulkSyncPickerDrawer"
        header={
          <>
            <div className="flex flex-1 flex-wrap items-center">
              <h2 className="text-base font-medium">{capitalize(schemaLabel.singular)} status</h2>
            </div>
            <div className="flex flex-1 justify-center">
              <Search
                className="font-normal"
                wrapperStyles="h-8 w-80"
                placeholder={`Search ${schemaLabel.plural}...`}
                defaultValue={search}
                onChange={v => setSearch(v ?? '')}
                onReset={() => setSearch('')}
              />
            </div>
            <div className="flex flex-1 items-center justify-end space-x-2">
              <Button onClick={() => onDismiss()}>Close</Button>
            </div>
          </>
        }
      >
        <DataTable
          data={schemas}
          columns={columns}
          getRowId={row => row.id}
          loading={loading}
          // Filtering
          globalFilter={search}
          onGlobalFilterChange={setSearch}
          // Sorting
          sorting={sorting}
          onSortingChange={setSorting}
        />
      </SheetContent>
    </Sheet>
  );
}
