import { SideBySide } from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import { useAuth } from '~/hooks';
import { ApiKey } from '~/pages/user/api-key';
import { ChargebeeBilling } from '~/pages/user/chargebee-billing';
import { EmailNotification } from '~/pages/user/email-notification';
import { LoggingEnabled } from '~/pages/user/logging';
import { SamlSsoPortal } from '~/pages/user/saml-sso-portal';
import { Labels } from './labels';
import SyncEventLogging from './sync-event-logging';

const User = () => {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  const hasChargebee =
    user.organization.chargebee?.id && !user.organization.chargebee?.id.startsWith('reseller');

  return (
    <PageLayout topNavHeading="Settings">
      <div className="divide-y divide-gray-300">
        <SideBySide hasSectionWrap heading="Email subscriptions" styles="space-y-4">
          <EmailNotification
            orgId={user.organization.id}
            notificationEvent="digest.weekly_sync_summary"
            switchLabel="Subscribe to weekly syncs summary email"
          />
          <EmailNotification
            orgId={user.organization.id}
            notificationEvent="connection.unhealthy"
            switchLabel="Subscribe to connection failure emails"
          />
        </SideBySide>

        <LoggingEnabled />
        <SyncEventLogging />
        <ApiKey />
        <Labels />

        {hasChargebee && (
          <SideBySide hasSectionWrap heading="Billing details" styles="space-x-2">
            <ChargebeeBilling text="View billing details" />
          </SideBySide>
        )}
        {user.organization.workos?.id && (
          <SideBySide hasSectionWrap heading="SAML/SSO configuration">
            <SamlSsoPortal />
          </SideBySide>
        )}
      </div>
    </PageLayout>
  );
};

export default User;
