import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { EditPermission, MyInput, SideBySide } from '../../../components';
import { ConnectionFormValues } from '../connection-config';

export function ConnectionName() {
  const { connectionTypeId } = useParams<{ connectionTypeId: string }>();
  const { register, formState } = useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  return (
    <SideBySide hasSectionWrap heading="Name">
      <EditPermission>
        <MyInput
          className="max-w-xs"
          autoFocus={!!connectionTypeId}
          {...register('name', {
            required: 'Name is required',
            maxLength: {
              value: 50,
              message: 'Must be less than 50 characters'
            }
          })}
          errors={errors}
        />
      </EditPermission>
    </SideBySide>
  );
}
