import { LinkButton } from '~/components';
import { ExecutionStatus, SyncFragment, SyncStatusFragment } from '~/generated/graphql';
import { useToggle } from '~/hooks';
import { emptyCell, recordDialogHeading } from '~/utils';
import { SyncExecutionRecordsPreview } from '../sync-execution-records-preview';
import { WebhookSyncRequests } from '../sync-history/webhook-sync-requests';

interface Props {
  sync: SyncFragment;
  syncStatus: SyncStatusFragment;
}

export function SyncRecordCount({ sync, syncStatus }: Props) {
  const [showDialog, toggleDialog] = useToggle();
  if (
    !syncStatus?.execution ||
    syncStatus.execution.recordCount == null ||
    syncStatus.execution.status === ExecutionStatus.Created ||
    syncStatus.execution.status === ExecutionStatus.Queued ||
    syncStatus.execution.status === ExecutionStatus.Scheduled ||
    (syncStatus.execution.status === ExecutionStatus.Running &&
      syncStatus.execution?.recordCount === 0)
  ) {
    return <p>{emptyCell}</p>;
  }

  if (
    sync.targetConnection?.type.id !== 'webhook' &&
    syncStatus.execution?.records?.hasData &&
    syncStatus.execution?.recordCount > 0
  ) {
    return (
      <>
        <p>
          <LinkButton onClick={toggleDialog}>
            {syncStatus.execution.recordCount.toLocaleString()}
          </LinkButton>
        </p>
        {showDialog && (
          <SyncExecutionRecordsPreview
            heading={recordDialogHeading('records')}
            executionId={syncStatus.execution?.id}
            recordType="records"
            targetConnectionTypeId={sync.targetConnection?.type.id}
            targetConnectionName={sync.targetConnection?.name}
            targetObjectName={sync.targetObject?.name}
            handleDismiss={toggleDialog}
          />
        )}
      </>
    );
  }
  if (
    sync.targetConnection?.type.id === 'webhook' &&
    syncStatus.execution?.completedAt &&
    syncStatus.execution?.recordCount > 0
  ) {
    return (
      <>
        <p>
          <LinkButton onClick={toggleDialog}>
            {syncStatus.execution.recordCount.toLocaleString()}
          </LinkButton>
        </p>
        {showDialog && (
          <WebhookSyncRequests referenceId={syncStatus.execution?.id} handleClose={toggleDialog} />
        )}
      </>
    );
  }
  return <span>{syncStatus.execution?.recordCount.toLocaleString()}</span>;
}
