import cx from 'clsx';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export function TableWrap(props: Props) {
  return (
    <div className={cx('rounded border border-gray-300 bg-white shadow-card', props.className)}>
      {props.children}
    </div>
  );
}
