import cx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { Button, EditPermission, Icon } from '../../../components';
import { ConnectionFormValues } from '../connection-config';

interface Props {
  isConnected: boolean;
  oauthLoading: boolean;
  connectionTypeName: string;
  onSave: (force: boolean) => (data: ConnectionFormValues) => Promise<void>;
  className?: string;
}

export function OauthButton(props: Props) {
  const { handleSubmit } = useFormContext<ConnectionFormValues>();
  const isGoogle = ['Google Ads', 'Google Sheets', 'Google Workspace'].includes(
    props.connectionTypeName
  );

  return (
    <div className={cx('space-y-1', props.className)}>
      <EditPermission>
        {props.isConnected ? (
          <Button
            theme={isGoogle ? 'white' : 'default'}
            loading={props.oauthLoading}
            onClick={handleSubmit(props.onSave(true))}
          >
            {isGoogle ? (
              <span className="flex items-center space-x-3">
                <span>Force reconnect</span>
              </span>
            ) : (
              'Force reconnect'
            )}
          </Button>
        ) : (
          <Button
            theme={isGoogle ? 'white' : 'primary'}
            loading={props.oauthLoading}
            onClick={handleSubmit(props.onSave(false))}
          >
            {isGoogle ? (
              <span className="flex items-center space-x-3">
                <Icon name="GoogleG" />
                <span>Continue with Google</span>
              </span>
            ) : (
              `Connect to ${props.connectionTypeName}`
            )}
          </Button>
        )}
      </EditPermission>
      <p className="text-xs text-gray-500">
        Polytomic uses OAuth to securely connect to {props.connectionTypeName}.
      </p>
    </div>
  );
}
