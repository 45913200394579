import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Button,
  DisabledSelect,
  EditPermission,
  Label,
  MiddleEllipsis,
  MyCombobox,
  MyInput,
  Section,
  SideBySide
} from '~/components';
import { useFieldsetState } from '~/hooks';
import { FieldsetFormValues, flatToSelectable, hasItems } from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { ApiParam, FieldsTable } from '../components';

const delimOptions = ['Comma', 'Tab', 'Semi-colon'];

export function CsvFieldsetConfig() {
  const { fieldset, loading, refreshing, applyUpdate } = useFieldsetState();
  const { control, register, getValues } = useFormContext<FieldsetFormValues>();
  const hasPath = useWatch({ control, name: 'configuration.path' });

  function handleRefresh() {
    applyUpdate(
      { ...getValues('configuration') },
      { refresh: !!hasPath && hasItems(fieldset?.fields) }
    );
  }

  return (
    <>
      <div className="border-b border-gray-300">
        <Section className="space-y-6">
          <SideBySide heading="Build model using" styles="max-w-4xl">
            <div>
              <Label htmlFor="path">Path to CSV file</Label>
              <div className="flex items-start space-x-2">
                <label htmlFor="path" className="mt-2 max-w-xl truncate">
                  <MiddleEllipsis
                    text={fieldset?.connection.configuration.url as string | undefined}
                  />
                </label>
                <div>
                  <EditPermission>
                    <MyInput {...register('configuration.path')} className="min-w-[15rem]" />
                  </EditPermission>
                  <p className="mt-0.5 overflow-hidden whitespace-nowrap text-xs text-gray-500">
                    e.g. /static/table.csv
                  </p>
                </div>
              </div>
            </div>
            <Label>Delimiter</Label>
            <Controller
              control={control}
              name="configuration.delim"
              render={({ field }) => (
                <EditPermission
                  fallback={<DisabledSelect className="max-w-xs" valueLabel={field.value} />}
                >
                  <MyCombobox
                    className="max-w-xs"
                    value={flatToSelectable(field.value)}
                    options={delimOptions.map(flatToSelectable)}
                    onChange={option => field.onChange(option?.value)}
                  />
                </EditPermission>
              )}
            />
            <ApiParam className="mt-4" label="Headers" type="headers" />
            <ApiParam className="mt-4" label="Query string parameters" type="parameters" />

            {fieldset?.fields.length === 0 && (
              <Button className="mt-4" onClick={handleRefresh} loading={loading}>
                Continue
              </Button>
            )}
          </SideBySide>
          <FieldsTable
            fields={fieldset?.fields}
            loading={refreshing || (loading && !fieldset?.fields)}
            disabled={loading}
            refresh={handleRefresh}
            hasWriteinFields={fieldset?.properties.writeinFields}
          />
        </Section>
      </div>
      <AdditionalConfig />
    </>
  );
}
