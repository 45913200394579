import { useReactiveVar } from '@apollo/client';
import * as React from 'react';

import { refreshableSchemasVar } from '../apollo';

type RefreshableSchemaActions = { type: 'reset' } | { type: 'add'; payload: string };

function refreshableSchemasReducer(prev: string[], action: RefreshableSchemaActions) {
  switch (action.type) {
    case 'reset':
      return [];
    case 'add':
      // if we already have this string, return the prevState
      if (prev.includes(action.payload)) {
        return prev;
      }
      return prev.concat(action.payload);
    default:
      return prev;
  }
}

export function useRefreshableSchemas(connectionId: string) {
  const cache = useReactiveVar(refreshableSchemasVar);
  const value = React.useMemo(() => cache[connectionId] || [], [cache, connectionId]);
  return value;
}

export function dispatchRefreshableSchemas(connectionId: string, action: RefreshableSchemaActions) {
  const prev = refreshableSchemasVar()?.[connectionId] || [];
  refreshableSchemasVar({
    ...refreshableSchemasVar(),
    [connectionId]: refreshableSchemasReducer(prev, action)
  });
}
