import { createStateCtx } from '../factories';

export type BackendConfiguration = {
  on_premises: boolean;
  disable_session_capture: boolean;
  ga_measurement_id?: string;
  deployment_links: {
    name: string;
    url: string;
  }[];
  execution_logs_v2: boolean;
};

export const { useCtx: useConfiguration, Provider: ConfigurationProvider } =
  createStateCtx<BackendConfiguration>({
    on_premises: false,
    disable_session_capture: true,
    deployment_links: [],
    execution_logs_v2: false
  });
