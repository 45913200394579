import cx from 'clsx';
import * as React from 'react';
import { Icon } from '../Icon';

interface Props extends Pick<React.ComponentPropsWithoutRef<'button'>, 'onClick'> {
  isShowingPopover: boolean;
}

export const PopoverButtonMinimal = React.forwardRef<HTMLButtonElement, Props>(
  ({ isShowingPopover, onClick }, ref) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        className={cx(
          'mt-px ml-2 flex h-5 w-5 items-center justify-center rounded hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-300',
          isShowingPopover ? 'bg-gray-300' : 'bg-transparent'
        )}
      >
        <Icon name="DotsH" className="h-4 w-4 text-gray-500" />
      </button>
    );
  }
);

if (import.meta.env.MODE === 'development') {
  PopoverButtonMinimal.displayName = 'PopoverButtonMinimal';
}
