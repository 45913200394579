import * as React from 'react';

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
}

export const PopoverAction = React.forwardRef<HTMLDivElement, Props>(
  ({ children, disabled, readOnly, onClick }, ref) => {
    return (
      <div ref={ref}>
        {readOnly || disabled ? (
          <div className="flex w-full cursor-not-allowed items-center px-4 py-2.5 text-sm font-normal text-gray-400 focus:outline-none">
            {children}
          </div>
        ) : (
          <button
            className="flex w-full items-center px-4 py-2.5 text-sm font-normal text-gray-800 hover:bg-indigo-100 focus:outline-none"
            onClick={onClick}
          >
            {children}
          </button>
        )}
      </div>
    );
  }
);
