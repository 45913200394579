import * as React from 'react';

import { IS_MAC } from '../../utils';
import { Tooltip } from '../tooltip';
import { Button } from '.';

interface Props {
  onExecution: () => void;
  loading: boolean;
  disabled?: boolean;
  initial?: boolean;
  executionText?: string;
  showRefreshHelper?: boolean;
  refreshHelper?: string;
}

export const RefreshButton = React.memo<Props>(function RefreshButton({
  onExecution,
  disabled,
  loading,
  initial,
  executionText,
  showRefreshHelper,
  refreshHelper = 'Refresh query to update model fields'
}) {
  React.useEffect(() => {
    if (disabled) {
      return;
    }
    const callback = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        onExecution();
      }
    };
    document.addEventListener('keydown', callback, false);
    return () => {
      document.removeEventListener('keydown', callback, false);
    };
  }, [disabled, onExecution]);

  return (
    <>
      {showRefreshHelper && (
        <span className="text-sm font-normal text-gray-500">
          {disabled ? 'Query cannot be empty' : refreshHelper}
        </span>
      )}
      <Tooltip
        offset={[0, 6]}
        placement="top-end"
        content={`Hotkey: ${IS_MAC ? 'Command' : 'Control'} + Enter`}
      >
        <Button
          onClick={onExecution}
          loading={loading}
          disabled={disabled}
          iconEnd={executionText ? undefined : initial ? undefined : 'Refresh'}
        >
          {executionText ? executionText : initial ? 'Continue' : 'Refresh'}
        </Button>
      </Tooltip>
    </>
  );
});

if (import.meta.env.MODE === 'development') {
  RefreshButton.displayName = 'RefreshButton';
}
