import * as React from 'react';

import { LinkButton } from './form-components';

interface Props {
  loading: boolean;
  canceled: boolean;
  onClick: () => void;
}

export function OneWayCancel({ loading, canceled, onClick }: Props) {
  if (canceled) {
    return <p className="text-xs font-normal text-gray-500">Query canceled</p>;
  }

  if (loading) {
    return <Cancel onClick={onClick} />;
  }

  return null;
}

function Cancel({ onClick }: Pick<Props, 'onClick'>) {
  const [disabled, setDisabled] = React.useState(false);

  function handleClick() {
    onClick();
    setDisabled(true);
  }

  return (
    <LinkButton disabled={disabled} onClick={handleClick} className="text-xs font-normal">
      Cancel
    </LinkButton>
  );
}
