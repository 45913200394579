import { ApolloLink, from, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import { WebSocket } from 'ws';

import { dispatchResetStore } from '../hooks';
import { isServerError } from '../utils';
import { apiVersionVar, needsReloadVar } from './reactive-vars';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(error => {
  if (isServerError(error.networkError) && error.networkError.response.status === 401) {
    // postMessage because we don't have access to the Apollo store or Auth
    // context here
    window.postMessage({ event: 'logout', logout: true }, document.location.origin);
  }
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const hasPermissionError = !!error.graphQLErrors.find(
      err => err?.extensions?.CausedBy === 'insufficient permissions'
    );
    if (hasPermissionError) {
      dispatchResetStore();
    }
  }
});

const apiVersionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    const apiVersion = operation.getContext()?.response?.headers?.get('Polytomic-Api-Version');
    const savedVersion = apiVersionVar();

    if (savedVersion != null && apiVersion != null && savedVersion !== apiVersion) {
      needsReloadVar(true);
    } else if (savedVersion == null) {
      if (needsReloadVar() === true) {
        needsReloadVar(false);
      }
      apiVersionVar(apiVersion as string);
    }

    return response;
  });
});

const uploadOrHttpLink = createUploadLink({
  uri: `${import.meta.env.VITE_API_URL || ''}/api/query`,
  credentials: 'include'
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${import.meta.env.VITE_API_HOST || window.location.host}/api/query`,
    lazyCloseTimeout: 10000, // 10s,
    webSocketImpl: WebSocket
  })
);

const protocolLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  uploadOrHttpLink
);

export const link: ApolloLink = from([apiVersionLink, errorLink, protocolLink]);
