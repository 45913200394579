import { HierarchySelectColumn, HierarchySelectOption } from '../inputs/HierarchySelect';

export const mockHierarchySelectCols: HierarchySelectColumn[] = [
  {
    id: '0',
    label: 'Name',
    accessor: 'label'
  },
  {
    id: '1',
    label: 'Id',
    accessor: 'id'
  }
];
export const mockHierarchySelectOptions: HierarchySelectOption[] = [
  {
    id: '0',
    label: 'John',
    value: 'john',
    parentId: null
  },
  {
    id: '0-0',
    parentId: '0',
    label: 'Tom',
    value: 'tom'
  },
  {
    id: '0-0-0',
    parentId: '0-0',
    label: 'Sally',
    value: 'sally'
  },
  {
    id: '0-0-1',
    parentId: '0-0',
    label: 'Peter',
    value: 'peter'
  },
  {
    id: '0-1',
    parentId: '0',
    label: 'Samantha',
    value: 'samantha'
  },
  {
    id: '1',
    label: 'Francis',
    value: 'francis',
    parentId: null
  },
  {
    id: '2',
    parentId: null,
    label: 'Mary',
    value: 'mary'
  },
  {
    id: '2-0',
    parentId: '2',
    label: 'Sally',
    value: 'sally'
  },
  {
    id: '2-1',
    label: 'Peter',
    parentId: '2',
    value: 'peter'
  }
];
