import { gql } from '@apollo/client';

export const PolicyFragment = gql`
  fragment Policy on Policy {
    tagID
    policyActions {
      action
      roleIDs
    }
  }
`;

export const PermissionTagFragment = gql`
  fragment PermissionTag on PermissionTag {
    id
    name
    system
    systemResourceTypes
    acl
    policy {
      ...Policy
    }
  }
`;

export const PermissionRoleFragment = gql`
  fragment PermissionRole on PermissionRole {
    id
    name
    system
    acl
  }
`;

export const UserFragment = gql`
  fragment User on User {
    id
    email
    firstName
    lastName
    status
    organization {
      id
      name
      workos {
        id
        acl
      }
      chargebee {
        id
        acl
      }
      features
    }
    roles {
      ...PermissionRole
    }

    createPermissions
    acl

    tagsCanApply {
      ...PermissionTag
    }
    tags {
      ...PermissionTag
    }

    createdAt
    updatedAt
  }
`;

export const ConnectionTypeFragment = gql`
  fragment ConnectionType on ConnectionType {
    id
    name
    operations
    useOAuth
    envConfig
    initialConfiguration
    configurationSchema
    configurationForm {
      jsonschema
      uischema
    }
    destinationDataArchitecture
    enabled
    terminology {
      schemaLabel {
        singular
        plural
      }
      fieldLabel {
        singular
        plural
      }
    }
  }
`;

export const ConnectionIndexFragment = gql`
  fragment ConnectionIndex on Connection {
    id
    health {
      status
    }
    stats {
      callsLast24h
    }
  }
`;

export const RemoteFieldTypeFragment = gql`
  fragment RemoteFieldType on RemoteFieldType {
    remoteId
    label
    ptType
  }
`;

export const ConnectionFragment = gql`
  fragment Connection on Connection {
    id
    name
    type {
      id
      name
      operations
      destinationDataArchitecture
    }
    health {
      status
    }
    configuration
    stats {
      callsLast24h
    }
    fieldTypes {
      ...RemoteFieldType
    }
    acl
    tags {
      ...PermissionTag
    }
    schemaRefreshStatus
  }
`;

export const EditableConnectionFragment = gql`
  fragment EditableConnection on Connection {
    id
    name
    saved
    type {
      ...ConnectionType
    }
    health {
      status
      lastSuccessAt
      lastErrorAt
      errors
    }
    authURL
    configuration
    stats {
      callsLast24h
    }
    createdBy {
      id
      email
    }
    createdAt
    updatedAt
    acl
    tags {
      ...PermissionTag
    }
  }
`;

export const MinimalModelFieldFragment = gql`
  fragment MinimalModelField on ModelField {
    id
    sourceName
    sourceType
    label
    description
    example
    published
  }
`;

export const ClientModelFieldFragment = gql`
  fragment ClientModelField on ModelField {
    id
    sourceName
    sourceType
    label
    description
    example
    published
    type
    userAdded
  }
`;

export const ModelFieldFragment = gql`
  fragment ModelField on ModelField {
    id
    sourceName
    sourceType
    label
    description
    example
    published
    type
    userAdded
    fieldset {
      id
      name
      fieldsetType
      connection {
        id
        name
        type {
          id
          operations
        }
      }
      enrichments {
        provider {
          id
          fields {
            id
          }
        }
      }
    }
  }
`;

export const ModelFilterFragment = gql`
  fragment ModelFilter on ModelFilter {
    field
    function
    value
  }
`;

export const RelationshipFragment = gql`
  fragment Relationship on Relationship {
    from {
      ...ModelField
    }
    to {
      ...ModelField
    }
  }
`;
export const FieldsetConfigurationFragment = gql`
  fragment FieldsetConfiguration on Fieldset {
    configuration {
      ... on AffinityConfiguration {
        object
      }
      ... on AirtableConfiguration {
        tableobj {
          value
          label
        }
        viewobj {
          value
          label
        }
        baseobj {
          value
          label
        }
      }
      ... on APIConfiguration {
        path
        method
        recordPath
        body
        headers {
          name
          value
        }
        parameters {
          name
          value
        }
        pagination {
          ... on NextPagePagination {
            source
            nextPage
            persistPagination
          }
          ... on SequentialPagePagination {
            pageParameterName
          }
          ... on OffsetPagination {
            offsetParameterName
            limitParameterName
            pageSize
            recordLimit
          }
          ... on TokenPagination {
            tokenPath
            sendTokenAs {
              name
              location
            }
            tokenTransformation
          }
        }
        sampleResponse
      }
      ... on ApolloEnrichmentConfiguration {
        object
      }
      ... on AscendConfiguration {
        streamID
      }
      ... on AthenaConfiguration {
        query
        catalog
        database
        table
        trackingColumns {
          ...ModelField
        }
      }
      ... on AttioConfiguration {
        object
        list
      }
      ... on BigQueryConfiguration {
        query
        dataset
        table
        trackingColumns {
          ...ModelField
        }
      }
      ... on BlobstoreConfiguration {
        modelFrom
        fileFormat
        skipLines
        key
        subfolder
      }
      ... on ChargebeeConfiguration {
        streamID
        version
      }
      ... on ClariConfiguration {
        object
      }
      ... on CosmosDBConfiguration {
        query
        database
        collection
        filters {
          ...ModelFilter
        }
      }
      ... on CSVConfiguration {
        path
        delim
        headers {
          name
          value
        }
        parameters {
          name
          value
        }
      }
      ... on DataAccessConfiguration {
        object
      }
      ... on DatabricksConfiguration {
        query
        catalog
        schema
        table
        trackingColumns {
          ...ModelField
        }
      }
      ... on DataliteConfiguration {
        schemas {
          connectionID
          schemaID
          alias
          connectionType
          connectionName
        }
        query
      }
      ... on DelightedConfiguration {
        object
      }
      ... on DialpadConfiguration {
        object
      }
      ... on DynamoDBConfiguration {
        table
        query
        index
        consistent
      }
      ... on FrontConfiguration {
        streamID
      }
      ... on GongConfiguration {
        object
      }
      ... on GoogleWorkspaceConfiguration {
        streamID
      }
      ... on GSheetsConfiguration {
        sheet
        selectedSheet {
          value
          label
        }
      }
      ... on HarmonicConfiguration {
        source
        streamID
        entity
      }
      ... on HarmonicEnrichmentConfiguration {
        object
      }
      ... on HeronConfiguration {
        object
      }
      ... on HubspotConfiguration {
        streamID
      }
      ... on IroncladConfiguration {
        object
      }
      ... on KlaviyoConfiguration {
        streamID
      }
      ... on KustomerConfiguration {
        object
      }
      ... on MailerCheckEnrichmentConfiguration {
        object
      }
      ... on MarketoConfiguration {
        object
        list
      }
      ... on MongoDBConfiguration {
        query
        aggregation
        database
        collection
        trackingColumns {
          ...ModelField
        }
      }
      ... on NetsuiteConfiguration {
        object
      }
      ... on NetsuiteOpenairConfiguration {
        object
      }
      ... on OutreachConfiguration {
        streamID
      }
      ... on PipedriveConfiguration {
        streamID
        includeDeleted
      }
      ... on PredictLeadsEnrichmentConfiguration {
        object
      }
      ... on QuickBooksConfiguration {
        object
        dateMacro
        startDate
      }
      ... on ReplyioConfiguration {
        streamID
      }
      ... on SalesforceConfiguration {
        query
        table
        includeDeleted
      }
      ... on SalesloftConfiguration {
        object
      }
      ... on ScamalyticsConfiguration {
        object
      }
      ... on SeamAIConfiguration {
        enricher
      }
      ... on ShipbobConfiguration {
        object
      }
      ... on ShopifyConfiguration {
        object
      }
      ... on SQLConfiguration {
        query
        table
        view
        trackingColumns {
          ...ModelField
        }
      }
      ... on StrackrConfiguration {
        object
      }
      ... on StatsigConfiguration {
        streamID
      }
      ... on StripeConfiguration {
        streamID
      }
      ... on WarehouseConfiguration {
        query
        schema
        table
        trackingColumns {
          ...ModelField
        }
      }
      ... on SurvicateConfiguration {
        survey
      }
      ... on UserVoiceConfiguration {
        streamID
      }
      ... on VanillaConfiguration {
        streamID
      }
      ... on YotpoConfiguration {
        object
      }
      ... on ZendeskConfiguration {
        object
      }
      ... on ZoomInfoConfiguration {
        zoomSource
        topics
      }
      ... on ZoomInfoEnrichmentConfiguration {
        object
      }
    }
  }
`;
export const EditableFieldsetFragment = gql`
  fragment EditableFieldset on Fieldset {
    id
    name
    realName
    fieldsetType
    updatedBy {
      name
      email
    }
    updatedAt
    connection {
      ...Connection
    }
    fields {
      ...ModelField
    }
    trackableFields {
      ...ModelField
    }
    primaryKey {
      ...ModelField
    }
    properties {
      uniqueIdSelection
      writeinFields
      userTypeSelection
      enrichmentDisabled
    }
    configurationSchema(refresh: $refreshConfiguration)
    configurationForm(refresh: $refreshConfiguration) {
      jsonschema
      uischema
    }

    ...FieldsetConfiguration

    relatedTo {
      ...Relationship
    }
    relatedFrom {
      ...Relationship
    }
    acl
    tags {
      ...PermissionTag
    }
    labels {
      ...Label
    }
    enrichments {
      mappings
      provider {
        id
        ...FieldsetConfiguration
        connection {
          id
          name
          type {
            id
            name
          }
        }
        fields {
          ...ModelField
        }
      }
    }
  }
`;
export const NewFieldsetFragment = gql`
  fragment NewFieldset on Fieldset {
    id
    name
    realName
    fieldsetType
    updatedBy {
      name
      email
    }
    updatedAt
    fields {
      ...ModelField
    }
    configurationSchema
    configurationForm {
      jsonschema
      uischema
    }
    ...FieldsetConfiguration
    connection {
      ...Connection
    }
    properties {
      uniqueIdSelection
      writeinFields
      userTypeSelection
      enrichmentDisabled
    }
    acl
    tags {
      ...PermissionTag
    }
  }
`;

export const ClientFieldsetFragment = gql`
  fragment ClientFieldset on Fieldset {
    id
    name
    realName
    fieldsetType
    connection {
      ...Connection
    }
    fields {
      ...ClientModelField
    }
    trackableFields {
      ...ClientModelField
    }
    relatedTo {
      ...Relationship
    }
    relatedFrom {
      ...Relationship
    }
    createdBy
    acl
    tags {
      ...PermissionTag
    }
    labels {
      ...Label
    }
    enrichments {
      provider {
        id
        connection {
          id
          name
          type {
            id
            name
          }
        }
        fields {
          ...ClientModelField
        }
      }
    }
  }
`;

export const FieldsetFragment = gql`
  fragment Fieldset on Fieldset {
    id
    name
    realName
    fieldsetType
    connection {
      ...Connection
    }
    fields {
      ...ModelField
    }
    trackableFields {
      ...ModelField
    }
    relatedTo {
      ...Relationship
    }
    relatedFrom {
      ...Relationship
    }
    createdBy
    acl
    tags {
      ...PermissionTag
    }
    labels {
      ...Label
    }
    enrichments {
      provider {
        id
        connection {
          id
          name
          type {
            id
            name
          }
        }
        fields {
          ...ModelField
        }
      }
    }
  }
`;

export const FieldsetListItemFragment = gql`
  fragment FieldsetListItem on Fieldset {
    id
    name
    realName
    createdBy
    connection {
      name
      type {
        id
      }
    }
    fields {
      id
      sourceName
      label
    }
    relatedTo {
      from {
        label
        fieldset {
          name
        }
      }
      to {
        label
        fieldset {
          name
        }
      }
    }
    labels {
      ...Label
    }
    enrichments {
      provider {
        id
        connection {
          id
          name
          type {
            id
            name
          }
        }
        fields {
          ...ModelField
        }
      }
    }
  }
`;

export const ModelFragment = gql`
  fragment Model on Model {
    fieldsets {
      ...ClientFieldset
    }
  }
`;

export const TargetFieldFragment = gql`
  fragment TargetField on TargetField {
    id
    name
    description
    required
    type
    remoteFieldTypeId
    filterable
    createable
    updateable
    filterFunctions {
      id
      label
      requiresValue
      multiValue
      values {
        label
        value
      }
    }
    values {
      label
      value
    }
    supportsIdentity
    identityFunctions {
      id
      label
      supportsCreateMode
      compatibleTypes
    }
    isFieldBag
  }
`;

export const TargetPropertiesFragment = gql`
  fragment TargetProperties on TargetProperties {
    targetCreator
    newTargetNameLabel
    optionalTargetMappings
    primaryMetadataObject
    supportsTargetFilters
    supportsFieldCreation
    supportsFieldTypeSelection
    requiresConfiguration
    doesNotReportOperationCounts
    mappingsNotRequired
  }
`;

export const TargetObjectWithFieldsFragment = gql`
  fragment TargetObjectWithFields on TargetObject {
    id
    name
    modes {
      mode
      label
      description
      requiresIdentity
      supportsTargetFilters
      supportsFieldSyncMode
      supportsIncremental
    }
    properties {
      ...TargetProperties
    }
    connection {
      id
      type {
        id
        name
        terminology {
          schemaLabel {
            singular
            plural
          }
          fieldLabel {
            singular
            plural
          }
        }
      }
    }
    advancedConfiguration {
      jsonschema
      uischema
    }
    fieldsRefreshedAt
    fields {
      ...TargetField
    }
    scheduleOptions {
      frequency
      label
      description
      configuration {
        jsonschema
      }
    }
  }
`;

export const TargetObjectFragment = gql`
  fragment TargetObject on TargetObject {
    id
    name
    modes {
      mode
      label
      description
      requiresIdentity
      supportsTargetFilters
      supportsFieldSyncMode
      supportsIncremental
    }
    connection {
      id
      type {
        id
        name
        terminology {
          schemaLabel {
            singular
            plural
          }
          fieldLabel {
            singular
            plural
          }
        }
      }
    }
    properties {
      ...TargetProperties
    }
    advancedConfiguration {
      jsonschema
      uischema
    }
    fieldsRefreshedAt
    scheduleOptions {
      frequency
      label
      description
      configuration {
        jsonschema
      }
    }
  }
`;

export const IdentityMappingFragment = gql`
  fragment IdentityMapping on IdentityMapping {
    model {
      ...ModelField
    }
    target {
      ...TargetField
    }
    function
    newField
  }
`;

export const FieldMappingFragment = gql`
  fragment FieldMapping on FieldMapping {
    newField
    model {
      ...ModelField
    }
    target {
      ...TargetField
    }
    syncMode
  }
`;

export const OverrideFieldMappingFragment = gql`
  fragment OverrideFieldMapping on OverrideFieldMapping {
    overrideValue
    target {
      ...TargetField
    }
    syncMode
  }
`;

export const SyncRecordFragment = gql`
  fragment SyncRecord on SyncRecord {
    link
    data
  }
`;

export const SyncRecordMessageFragment = gql`
  fragment SyncRecordMessage on SyncRecordMessage {
    identityField
    identityValue
    message
  }
`;

export const SyncPreviewFragment = gql`
  fragment SyncPreview on SyncRecords {
    targetIDProperty
    records {
      ...SyncRecord
    }
  }
`;

export const SyncRecordsFragment = gql`
  fragment SyncRecords on SyncRecords {
    executionID
    hasData
    type
    targetIDProperty
    download
    records {
      ...SyncRecord
    }
  }
`;

export const SyncRecordWarningsFragment = gql`
  fragment SyncRecordWarnings on SyncRecordWarnings {
    executionID
    hasData
    download
    legacy
    recordMessages {
      ...SyncRecordMessage
    }
  }
`;

export const SyncRecordErrorsFragment = gql`
  fragment SyncRecordErrors on SyncRecordErrors {
    executionID
    hasData
    download
    canRetry
    recordMessages {
      ...SyncRecordMessage
    }
  }
`;

export const SyncExecutionErrorFragment = gql`
  fragment SyncExecutionError on SyncExecutionError {
    error
  }
`;

export const SyncExecutionFragment = gql`
  fragment SyncExecution on SyncExecution {
    id
    type
    startedAt
    completedAt
    resync
    status
    recordCount
    updateCount
    insertCount
    deleteCount
    errorCount
    warningCount
    deleteCount
    executionErrors {
      error
    }
    records {
      hasData
    }
    inserts {
      hasData
    }
    updates {
      hasData
    }
    deletes {
      hasData
    }
    warnings {
      hasData
    }
    errors {
      hasData
      canRetry
    }
    formattedDuration
    checkpointExecution {
      id
      startedAt
      completedAt
      status
    }
    checkpointable
  }
`;

export const SyncStatusFragment = gql`
  fragment SyncStatus on SyncStatus {
    execution {
      ...SyncExecution
    }
    nextExecutionTime
  }
`;

export const FilterFragment = gql`
  fragment Filter on Filter {
    field {
      ...TargetField
      ...ModelField
    }
    label
    function
    value
  }
`;

export const OverrideFragment = gql`
  fragment Override on Override {
    field {
      ...ModelField
    }
    function
    value
    overrideValue
  }
`;

export const SyncIndexModelFieldFragment = gql`
  fragment SyncIndexModelField on ModelField {
    id
    sourceName
    label
    description
    example
    published
    fieldset {
      connection {
        id
        name
        type {
          id
        }
      }
    }
  }
`;

export const SyncListItemFragment = gql`
  fragment SyncListItem on Sync {
    id
    name
    active
    identity {
      model {
        fieldset {
          connection {
            type {
              id
            }
          }
        }
      }
    }
    targetConnection {
      id
      health {
        status
      }
      type {
        id
      }
    }
    runAfterSyncs {
      id
      name
    }
    schedule {
      frequency
    }
    targetObject {
      name
    }
    overrideFields {
      overrideValue
    }
    fields {
      model {
        fieldset {
          connection {
            type {
              id
            }
          }
        }
      }
    }
    currentExecution {
      status
    }
    lastExecution {
      status
      startedAt
      errorCount
      executionErrors {
        error
      }
    }
    targetConnection {
      id
      name
      type {
        id
      }
    }
  }
`;

export const SyncIndexFragment = gql`
  fragment SyncIndex on Sync {
    id
    name
    targetObject {
      id
      name
    }
    targetConnection {
      id
      name
      health {
        status
      }
      type {
        id
      }
    }
    mode
    active
    updatedAt
    updatedBy {
      name
      email
    }
    identity {
      model {
        ...SyncIndexModelField
      }
    }
    fields {
      model {
        ...SyncIndexModelField
      }
    }
    overrideFields {
      overrideValue
    }
    runAfterSyncs {
      id
      name
    }
    runAfterBulkSyncs {
      id
      name
    }
    schedule {
      frequency
    }
    currentExecution {
      id
      startedAt
      status
    }
    lastExecution {
      id
      startedAt
      status
      errorCount
      executionErrors {
        error
      }
    }
    acl
    tags {
      ...PermissionTag
    }
  }
`;

export const SyncForFieldFragment = gql`
  fragment SyncForField on Sync {
    id
    name
    targetConnection {
      id
      type {
        id
      }
    }
    identity {
      ...IdentityMapping
    }
    fields {
      ...FieldMapping
    }
  }
`;

export const SyncFragment = gql`
  fragment Sync on Sync {
    id
    name
    notes
    targetObject {
      ...TargetObject
    }
    targetConnection {
      ...Connection
    }
    targetSearchValues
    targetObjectConfiguration {
      ... on AffinityTargetConfiguration {
        searchDomains
      }
      ... on AttioTargetConfiguration {
        removeInvalidReferences
      }
      ... on BlobTargetConfiguration {
        format
      }
      ... on DatabaseTargetConfiguration {
        preserveTableOnResync
      }
      ... on HubspotContactTargetConfiguration {
        writeNullValues
        ignoreAdditionalEmails
      }
      ... on HubspotTargetConfiguration {
        writeNullValues
      }
      ... on IntercomTargetConfiguration {
        writeNullValues
      }
      ... on MarketoTargetConfiguration {
        writeNullValues
      }
      ... on MultiTargetConfiguration {
        createRecordsAs
      }
      ... on SalesforceTargetConfiguration {
        ingestionAPI
        bulkBatchSize
        concurrencyMode
        insertNullValues
        ignoreInvalidReferences
        onlySyncDifferent
        sortBatchesBy
      }
      ... on SalesforceLeadTargetConfiguration {
        ingestionAPI
        bulkBatchSize
        concurrencyMode
        insertNullValues
        ignoreInvalidReferences
        onlySyncDifferent
        skipConverted
      }
      ... on SnowflakeTargetConfiguration {
        preserveTableOnResync
        writeNullValues
      }
      ... on WebhookTargetConfiguration {
        batchSize
        recordRequests
        metadata {
          key
          value
        }
      }
      ... on ZendeskTargetConfiguration {
        replaceRelationships
      }
    }
    mode
    active
    updatedAt
    updatedBy {
      name
      email
    }
    syncAllRecords
    identity {
      ...IdentityMapping
    }
    fields {
      ...FieldMapping
    }
    overrideFields {
      ...OverrideFieldMapping
    }
    filters {
      ...Filter
    }
    filterLogic
    targetFilterLogic
    overrides {
      ...Override
    }
    runAfterSyncs {
      id
      name
      targetConnection {
        id
        name
      }
    }
    runAfterBulkSyncs {
      id
      name
    }
    schedule {
      frequency
      ... on HourlySchedule {
        minute
      }
      ... on DailySchedule {
        hour
        minute
      }
      ... on WeeklySchedule {
        minute
        hour
        dayOfWeek
      }
      ... on CustomSchedule {
        minute
        hour
        dayOfMonth
        month
        dayOfWeek
      }
      ... on DBTCloudSchedule {
        jobId
        connectionId
      }
    }
    currentExecution {
      ...SyncExecution
    }
    lastExecution {
      ...SyncExecution
    }
    nextExecutionTime
    willTruncateOnResync
    resyncWarning
    acl
    tags {
      ...PermissionTag
    }
  }
`;

export const SyncDeleteErrorFragment = gql`
  fragment SyncDeleteError on SyncErrorResponse {
    error
    usedBy {
      ... on Sync {
        id
        name
      }
    }
  }
`;

export const SupportedBulkModeFragment = gql`
  fragment SupportedBulkMode on SupportedBulkMode {
    mode
    label
    description
  }
`;

export const BulkSourceConnectionFragment = gql`
  fragment BulkSourceConnection on BulkSourceConnection {
    id
    connection {
      ...Connection
    }
    schemaLabel {
      singular
      plural
    }
    namespaceLabel {
      singular
      plural
    }
    namespaces {
      ...BulkNamespaceOption
    }
    configurationForm {
      jsonschema
      uischema
    }
    properties {
      hideSystemName
      supportsNamespaces
      supportsTrackingFields
      supportsIncremental
      supportsSlowMode
    }
  }
`;

export const BulkDestinationConnectionFragment = gql`
  fragment BulkDestinationConnection on BulkDestinationConnection {
    id
    connection {
      ...Connection
    }
    supportedModes {
      ...SupportedBulkMode
    }
    configurationSchema
    configurationForm {
      jsonschema
      uischema
    }
    defaultConfiguration {
      ...BulkDestinationConfiguration
    }
    properties {
      sanitizeConfigurationInput
      supportsPartitions
      supportsIncremental
      vocabulary {
        partitionKey
      }
    }
  }
`;

export const BulkSyncExecutionFragment = gql`
  fragment BulkSyncExecution on BulkSyncExecution {
    id
    syncId
    type
    status
    statusMessage
    recordCount
    errorCount
    warningCount
    createdAt
    startedAt
    completedAt
    formattedDuration
    selectiveMode
  }
`;

export const BulkSyncSchemaExecutionFragment = gql`
  fragment BulkSyncSchemaExecution on BulkSyncSchemaExecution {
    id
    executionId
    schema {
      ...BulkSchemaSelection
    }
    status
    statusMessage

    recordCount
    errorCount
    errors {
      count
      preview {
        message
        polytomicID
      }
      downloadUrl
    }
    warnings {
      count
      preview {
        message
        polytomicID
      }
      downloadUrl
    }

    createdAt
    startedAt
    completedAt
  }
`;

export const BulkSyncStatusFragment = gql`
  fragment BulkSyncStatus on BulkSyncStatus {
    execution {
      ...BulkSyncExecution
    }
    activeExecutions {
      id
      status
    }
    nextExecutionTime
  }
`;

export const BulkSyncIndexFragment = gql`
  fragment BulkSyncIndex on BulkSync {
    id
    name
    source {
      connection {
        id
        name
        type {
          id
          name
        }
        health {
          status
        }
      }
    }
    destination {
      connection {
        id
        name
        type {
          id
          name
        }
        health {
          status
        }
      }
    }
    mode
    active
    schedule {
      frequency
    }
    execution {
      id
      status
      statusMessage
      errorCount
      startedAt
    }
    activeExecutions {
      status
    }
    nextExecutionTime
    acl
    tags {
      ...PermissionTag
    }
  }
`;

export const BulkDestinationConfigurationFragment = gql`
  fragment BulkDestinationConfiguration on BulkDestinationConfiguration {
    ... on AirtableDestinationConfiguration {
      base
    }
    ... on BigQueryDestinationConfiguration {
      dataset
      mirrorSchemas
      advanced {
        truncateExisting
        tablePrefix
        hardDeletes
        expireTablesAfterInactivity
        tableExpiryDays
      }
    }
    ... on BlobDestinationConfiguration {
      format
      subfolder
      writeIncremental
    }
    ... on DatabaseDestinationConfiguration {
      schema
      mirrorSchemas
      advanced {
        truncateExisting
        tablePrefix
      }
    }
    ... on DatabricksDestinationConfiguration {
      catalog
      schema
      mirrorSchemas
      externalLocationName
      advanced {
        truncateExisting
        tablePrefix
        hardDeletes
      }
    }
    ... on GleanDestinationConfiguration {
      datasource
    }
    ... on WarehouseDestinationConfiguration {
      schema
      mirrorSchemas
      advanced {
        truncateExisting
        tablePrefix
        hardDeletes
      }
    }
  }
`;

export const BulkSyncScheduleFragment = gql`
  fragment BulkSyncSchedule on BulkSchedule {
    frequency
    ... on HourlySchedule {
      minute
    }
    ... on DailySchedule {
      hour
      minute
    }
    ... on WeeklySchedule {
      minute
      hour
      dayOfWeek
    }
    ... on CustomSchedule {
      minute
      hour
      dayOfMonth
      month
      dayOfWeek
    }
    ... on MultiSchedule {
      multi {
        type
        schedules {
          item
          schedule {
            frequency
            ... on HourlySchedule {
              minute
            }
            ... on DailySchedule {
              hour
              minute
            }
            ... on WeeklySchedule {
              minute
              hour
              dayOfWeek
            }
            ... on CustomSchedule {
              minute
              hour
              dayOfMonth
              month
              dayOfWeek
            }
          }
        }
      }
    }
  }
`;

export const BulkSyncPeekFragment = gql`
  fragment BulkSyncPeek on BulkSync {
    id
    name
    source {
      connection {
        id
        name
        type {
          id
          name
          operations
        }
        health {
          status
        }
      }
      schemaLabel {
        singular
        plural
      }
      namespaceLabel {
        singular
        plural
      }
      properties {
        hideSystemName
        supportsNamespaces
        supportsTrackingFields
        supportsIncremental
      }
    }
    destination {
      connection {
        id
        name
        type {
          id
          name
          operations
        }
        health {
          status
        }
      }
      properties {
        sanitizeConfigurationInput
        supportsPartitions
        supportsIncremental
      }
    }
    mode
    active
    schedule {
      ...BulkSyncSchedule
    }
    execution {
      ...BulkSyncExecution
    }
    activeExecutions {
      status
    }
    nextExecutionTime
    acl
    tags {
      ...PermissionTag
    }
    updatedAt
    updatedBy {
      name
      email
    }
    namespaces {
      id
      schemas {
        id
        name
      }
    }
    features
  }
`;

export const BulkSyncFragment = gql`
  fragment BulkSync on BulkSync {
    id
    name
    source {
      ...BulkSourceConnection
    }
    destination {
      ...BulkDestinationConnection
    }
    destinationConfiguration {
      ...BulkDestinationConfiguration
    }
    sourceConfiguration {
      ... on GongSourceConfiguration {
        syncIncremental
      }
      ... on GoogleAdsSourceConfiguration {
        reportingLookback
      }
      ... on PostgresqlSourceConfiguration {
        replicationSlot
      }
      ... on StripeSourceConfiguration {
        reportingLookback
      }
    }
    namespaces {
      ...BulkNamespaceSelection
    }
    mode
    active
    updatedAt
    updatedBy {
      name
      email
    }
    schedule {
      ...BulkSyncSchedule
    }
    fieldDiscovery
    tableDiscovery
    disableRecordTimestamps
    execution {
      ...BulkSyncExecution
    }
    activeExecutions {
      status
    }
    nextExecutionTime
    acl
    tags {
      ...PermissionTag
    }
    features
  }
`;

export const ConfirmationRequestFragment = gql`
  fragment ConfirmationRequest on ConfirmationRequest {
    message
    confirm
  }
`;

export const ConnectionDeleteErrorFragment = gql`
  fragment ConnectionDeleteError on ConnectionErrorResponse {
    error
    usedBy {
      ... on Fieldset {
        id
        name
      }
      ... on Sync {
        id
        name
      }
      ... on BulkSync {
        id
        name
      }
      ... on IntegrationSchedule {
        syncId
        connectionId
      }
    }
  }
`;

export const NotificationFragment = gql`
  fragment Notification on Notification {
    scope
    event
    recipientId
    recipientType
  }
`;

export const WebhookRequestFragment = gql`
  fragment Request on Request {
    id
    referenceID
    method
    url
    headers {
      name
      values
    }
    body
    response {
      statusCode
      headers {
        name
        values
      }
      body
      error
    }
  }
`;

export const ApiKeyRecordFragment = gql`
  fragment ApiKeyRecord on ApiKeyRecord {
    apiKey
    createdAt
  }
`;

export const SqlRunnerResultFragment = gql`
  fragment SqlRunnerResult on SqlRunnerResult {
    columnNames
    rows
    count
  }
`;

export const QueryExportResultFragment = gql`
  fragment ExportSqlResult on ExportSqlResult {
    downloadLink
  }
`;

export const SqlRunnerColumnSchemaFragment = gql`
  fragment SqlRunnerColumnSchema on SqlRunnerColumnSchema {
    name: columnName
    type
    sourceType
  }
`;

export const TableFragment = gql`
  fragment Table on Table {
    label
    name
    columns {
      ...SqlRunnerColumnSchema
    }
  }
`;

export const DatabaseSchemaFragment = gql`
  fragment DatabaseSchema on DatabaseSchema {
    name
    label
    tables {
      ...Table
    }
  }
`;

export const SqlRunnerSchemaV2Fragment = gql`
  fragment SqlRunnerSchemaV2 on SqlRunnerSchemaV2 {
    name
    schemas {
      ...DatabaseSchema
    }
  }
`;

export const SqlRunnerSchemaV2MetaFragment = gql`
  fragment SqlRunnerSchemaV2Meta on SqlRunnerSchemaV2Meta {
    supportsDatabaseSelection
    databaseLabel
    schemaLabel
    tableLabel
  }
`;

export const HistoryQueryFragment = gql`
  fragment HistoryQuery on HistoryQuery {
    query
    lastExecutedAt: last_executed_at
  }
`;

export const TagDeletedSuccessResponseFragment = gql`
  fragment TagDeletedSuccessResponse on TagDeletedSuccessResponse {
    id
  }
`;

export const TagUsedByFragment = gql`
  fragment TagUsedBy on TagUsedBy {
    ... on Fieldset {
      id
      name
      connection {
        type {
          id
        }
      }
    }
    ... on Sync {
      id
      name
      identity {
        model {
          fieldset {
            connection {
              type {
                id
              }
            }
          }
        }
      }
      targetConnection {
        type {
          id
        }
      }
    }
    ... on BulkSync {
      id
      name
      source {
        connection {
          type {
            id
          }
        }
      }
      destination {
        connection {
          type {
            id
          }
        }
      }
    }
    ... on Connection {
      id
      name
      type {
        id
      }
    }
  }
`;

export const SyncPeekFragment = gql`
  fragment SyncPeek on SyncPeek {
    __typename
    id
    name
    Active
    LastRunTimestamp
    LastRunErrorCount
    LastRunError
    Frequency
    Status
    TargetConnectionID
    TargetConnectionName
    TargetConnectionType
    TargetObjectName
    RunAfterSyncs {
      id
      name
      Type
    }
    SourceConnections {
      ConnectionID
      ConnectionName
      ConnectionType
    }
    ViewedAt
  }
`;

export const DataliteSchemaMetadataFragment = gql`
  fragment DataliteSchemaMetadata on DataliteSchemaMetadata {
    connectionID
    schemaID
    alias
    connectionType
    connectionName
    initialized
    recordCount
    lastUpdated
  }
`;

export const ActivityEventFragment = gql`
  fragment ActivityEvent on ActivityEvent {
    ... on StringObj {
      str
    }
    ... on BoolObj {
      val
    }
    ... on ArrayObj {
      arr
    }
    ... on PairObj {
      elem1
      elem2
    }
    ... on RelationshipChangeEvent {
      fieldName
      fieldID
      foreignFieldName
      foreignFieldID
      foreignFieldsetType
      foreignFieldsetID
      foreignFieldsetName
    }
    ... on ValueLabelEvent {
      label
      value
    }
  }
`;

export const ActorFragment = gql`
  fragment Actor on Actor {
    id
    actorType
    displayName
  }
`;

export const QueryResultConnectionFragment = gql`
  fragment QueryResultConnection on QueryResultConnection {
    edges {
      ... on QueryResultEdge {
        node
        cursor
      }
    }
    pageInfo {
      ... on PageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
    debugQuery
  }
`;

export const QueryResultCountFragment = gql`
  fragment QueryResultCount on QueryResultCount {
    count
    debugQuery
  }
`;

export const ExploreStatusFragment = gql`
  fragment ExploreStatus on ExploreStatus {
    available
    lastUpdated
    fieldsetID
    formattedLastUpdated
  }
`;

export const ExploreStatusOrPoller = gql`
  fragment ExploreStatusOrPoller on ExploreStatusOrPoller {
    ... on ExploreStatus {
      ...ExploreStatus
    }
    ... on Poller {
      id
    }
  }
`;
