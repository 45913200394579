import { JSONSchema4 } from 'json-schema';
import { cloneDeep, has, keyBy, mapValues, omit, uniq } from 'lodash';
import { UseFormReturn } from 'react-hook-form';

import {
  ApiConfiguration,
  ClientFieldsetFragment,
  EditableFieldsetFragment,
  FieldsetConfiguration,
  FieldsetFragment,
  FieldsetType,
  GSheetsConfiguration,
  ModelFieldFragment,
  MongoDbConfiguration,
  ParameterLocation,
  PipedriveConfiguration,
  RelationshipFragment,
  RequestParameter,
  SqlConfiguration
} from '../generated/graphql';
import { FieldsetFormValues } from './custom-types.util';
import { filterToIds } from './helpers.util';
import { hasItems } from './predicates.util';

export function getRequestParams(type: FieldsetType, config: ApiConfiguration) {
  if (type !== FieldsetType.Api) {
    return {};
  }
  const result: { headers: RequestParameter[]; parameters: RequestParameter[] } = {
    headers: [],
    parameters: []
  };
  if (has(config, 'headers')) {
    result.headers = config.headers.filter(item => item.name !== '' && item.value !== '');
  }
  if (has(config, 'parameters')) {
    result.parameters = config.parameters.filter(item => item.name !== '' && item.value !== '');
  }
  return result;
}

export function sanitizeApiConfig(configuration: ApiConfiguration) {
  const config = cloneDeep(configuration);
  switch (config.pagination.__typename) {
    case 'NextPagePagination':
      config.pagination = {
        source: config.pagination.source,
        nextPage: config.pagination.nextPage,
        persistPagination: config.pagination.persistPagination
      };
      break;
    case 'TokenPagination':
      config.pagination = {
        tokenPath: config.pagination.tokenPath,
        sendTokenAs: {
          name: config.pagination.sendTokenAs.name,
          location: config.pagination.sendTokenAs.location
        },
        tokenTransformation: config.pagination.tokenTransformation
      };
      break;
    case 'SequentialPagePagination':
      config.pagination = {
        pageParameterName: config.pagination.pageParameterName
      };
      break;
    case 'OffsetPagination':
      config.pagination = {
        offsetParameterName: config.pagination.offsetParameterName,
        limitParameterName: config.pagination.limitParameterName,
        pageSize: config.pagination.pageSize,
        recordLimit: config.pagination.recordLimit
      };
      break;
    default:
      config.pagination = { ok: true };
      break;
  }

  config.sampleResponse = null;

  return config;
}

export function fieldsetToUpdate(type: FieldsetType, config: FieldsetConfiguration) {
  switch (type) {
    case FieldsetType.Api:
      return {
        ...omit(sanitizeApiConfig(config as ApiConfiguration), '__typename'),
        ...getRequestParams(type, config as ApiConfiguration)
      };
    case FieldsetType.Sql:
      return {
        ...omit(config, '__typename'),
        trackingColumns: filterToIds((config as SqlConfiguration).trackingColumns)
      };
    case FieldsetType.MongoDb:
      return {
        ...omit(config, '__typename'),
        trackingColumns: filterToIds((config as MongoDbConfiguration).trackingColumns)
      };
    case FieldsetType.GSheets:
      return {
        ...omit(config, '__typename'),
        selectedSheet: {
          label: (config as GSheetsConfiguration).selectedSheet?.label,
          value: (config as GSheetsConfiguration).selectedSheet?.value
        }
      };
    case FieldsetType.Pipedrive: {
      const cleanedConfig = omit(config, '__typename') as PipedriveConfiguration;
      if (cleanedConfig.streamID !== 'deals') {
        cleanedConfig.includeDeleted = false;
      }
      return cleanedConfig;
    }
    default:
      return omit(config, '__typename');
  }
}

export function getApiPaginationLabel(type?: string) {
  if (!type) {
    return '(None)';
  }
  switch (type) {
    case 'NextPagePagination':
      return 'Next Page URL';

    case 'TokenPagination':
      return 'Token';

    case 'SequentialPagePagination':
      return 'Sequential Pages';

    case 'OffsetPagination':
      return 'Offset';

    default:
      return '(None)';
  }
}

export function getApiLocationLabel(location?: ParameterLocation) {
  if (!location) {
    return '(None)';
  }
  switch (location) {
    case ParameterLocation.QueryString:
      return 'Query string';
    case ParameterLocation.Header:
      return 'Header';
    case ParameterLocation.Body:
      return 'Request body';
    default:
      return '(None)';
  }
}

export function setRelatedTo(
  newRelatedTo: RelationshipFragment[],
  newFields: ModelFieldFragment[],
  formValues?: RelationshipFragment[]
) {
  if (!Array.isArray(newRelatedTo)) {
    return [];
  }
  // preference new data coming in over existing form data
  if (hasItems(newRelatedTo)) {
    const [{ from, to }] = newRelatedTo;
    // this might be a guard from a bygone era
    // where this unexpectedly was undefined
    if (from) {
      // find the from field in the incoming fields list
      const newFrom = newFields.find(field => field.id === from.id);
      // if it's not there, relatedTo is not valid
      // send the empty [] to clear it
      if (!newFrom) {
        return [];
      }
      // we found it, so send it on through
      // to maintain form state for the user
      return [{ from: newFrom, to }];
    }
  }
  // same as above but we're checking
  // the prev form data; we want to preference
  // incoming data as it's more likely valid
  const prevRelatedTo = formValues || [];
  if (hasItems(prevRelatedTo)) {
    const [{ from, to }] = prevRelatedTo;
    if (from) {
      const newFrom = newFields.find(field => field.id === from.id);
      if (!newFrom) {
        return [];
      }
      return [{ from: newFrom, to }];
    }
  }
  return [];
}

export function setTrackingColumns(conf: FieldsetConfiguration, fields: ModelFieldFragment[]) {
  if (conf && 'trackingColumns' in conf) {
    if (conf.trackingColumns != null) {
      return {
        trackingColumns: conf.trackingColumns.filter(
          col => !!fields.find(field => field.id === col.id)
        )
      };
    }
    return { trackingColumns: [] };
  }
  return {};
}

export function updateFieldsetForm(
  reset: UseFormReturn<FieldsetFormValues>['reset'],
  getValues: UseFormReturn<FieldsetFormValues>['getValues'],
  fieldset: EditableFieldsetFragment
) {
  reset(
    {
      realName: fieldset.realName || 'Unnamed model',
      fields: fieldset.fields,
      relatedTo: setRelatedTo(fieldset.relatedTo, fieldset.fields, getValues('relatedTo')),
      primaryKey: fieldset.primaryKey,
      configuration: {
        ...fieldset.configuration,
        ...setTrackingColumns(fieldset.configuration as FieldsetConfiguration, fieldset.fields)
      },
      // this will preserve the tags values from the form
      tags: getValues('tags') || fieldset?.tags || [],
      labels: getValues('labels') || fieldset?.labels || [],
      enrichments: fieldset?.enrichments || [],
      enrichmentUpdates: getValues('enrichmentUpdates') || []
    },
    { keepDefaultValues: true, keepDirty: true }
  );
}

export function getReachableList(fieldsets: FieldsetFragment[]) {
  const fsById = keyBy(fieldsets, f => f.id);
  const list = mapValues(
    keyBy(fieldsets, f => f.id),
    () => []
  ) as { [id: string]: string[] };

  if (fieldsets.length === 0) {
    return list;
  }

  const seen = new Set<string>();

  for (const fs of fieldsets) {
    if (seen.has(fs.id)) {
      continue;
    }
    const s = [fs.id];

    const joinedIds: string[] = [];

    // traverse the joins
    while (s.length > 0) {
      const curId = s.pop();
      if (!curId || joinedIds.includes(curId)) {
        continue;
      }
      // push the current id into joined list
      joinedIds.push(curId);
      const fs = fsById[curId];
      // guard against a deleted fieldset but where the relationships still exist
      if (!fs) {
        continue;
      }
      // make list of fieldset ids that are joined
      const ids: string[] = [
        ...(fs.relatedFrom?.map(r => r.from.fieldset.id) ?? []),
        ...(fs.relatedTo?.map(r => r.to.fieldset.id) ?? []),
        ...(fs.enrichments?.map(e => e.provider.id) ?? [])
      ];
      // add the joins to the stack
      s.push(...ids);
    }
    list[fs.id] = joinedIds;
    // loop over traversed ids to add all to each other's list
    for (const id of joinedIds) {
      if (!!fsById[id]) {
        list[id] = uniq([...list[id], ...joinedIds.filter(id => !!fsById[id])]);
        seen.add(id);
      }
    }
  }
  return list;
}

export function findName(list: JSONSchema4[] | undefined, target: string) {
  return list?.find(({ name }) => name === target);
}
