import { useEffect, useState } from 'react';
import { useFieldsetState } from './fieldset-context';

export function useModelQueryRef(key: string, defaultQuery: string | undefined | null) {
  const { updatedConfig, setUpdatedConfig } = useFieldsetState();
  const [ref, setRef] = useState<string>(defaultQuery || '');

  useEffect(() => {
    if (updatedConfig) {
      const newRef = updatedConfig[key] as string | undefined;
      if (newRef) {
        setRef(newRef);
      }
    }
    return () => {
      setUpdatedConfig(null);
    };
  }, [key, updatedConfig, setUpdatedConfig]);

  return ref;
}
