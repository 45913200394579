import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { FieldDetail, Icon } from '~/components';
import { Pill } from '~/components/v3';
import {
  ConnectionFragment,
  FieldMappingFragment,
  OverrideFieldMappingFragment,
  SyncMode
} from '~/generated/graphql';
import { useModelFields } from '~/hooks';
import { LocalIdentityMapping, Mapping, isModelMapping, isOverrideMapping, routes } from '~/utils';
interface Props {
  syncMode: SyncMode | null;
  hasOptionalTargetMappings?: boolean;
  targetConnection?: ConnectionFragment | null;
  mappings: Array<Mapping | LocalIdentityMapping>;
}

const TRUNCATE_LENGTH = 36;

export function FieldMappingsSummary({
  syncMode,
  mappings,
  hasOptionalTargetMappings,
  targetConnection
}: Props) {
  return (
    <>
      {mappings.map((mapping, index) => {
        if (!(isModelMapping(mapping) || isOverrideMapping(mapping))) {
          return null;
        }
        return (
          <FieldMapping
            key={index}
            syncMode={syncMode}
            mapping={mapping}
            hasOptionalTargetMappings={hasOptionalTargetMappings}
            targetConnection={targetConnection}
          />
        );
      })}
    </>
  );
}

interface FieldMappingProps {
  syncMode: SyncMode | null;
  hasOptionalTargetMappings?: boolean;
  targetConnection?: ConnectionFragment | null;
  mapping: Mapping | LocalIdentityMapping;
}

function FieldMapping({
  mapping: _mapping,
  syncMode,
  hasOptionalTargetMappings,
  targetConnection
}: FieldMappingProps) {
  const { modelFields } = useModelFields();

  const mapping = _mapping as FieldMappingFragment | OverrideFieldMappingFragment;

  const field = useMemo(
    () => modelFields.find(f => f.id === (mapping as FieldMappingFragment)?.model?.id),
    [modelFields, mapping]
  );

  return (
    <div className="flex items-center">
      {isModelMapping(mapping) ? (
        <Pill
          icon={(field?.enrichmentFieldset ?? field?.fieldset)?.connection?.type?.id}
          text={mapping.model?.label}
          tooltipContent={
            <FieldDetail
              theme="dark"
              name={mapping.model?.label}
              logoId={field?.fieldset?.connection?.type?.id}
              enrichmentLogoId={field?.enrichmentFieldset?.connection?.type?.id}
              modelName={field?.fieldset?.name}
              columnName={mapping.model?.sourceName}
              type={mapping.model?.type}
            />
          }
          href={
            field?.fieldset?.id
              ? generatePath(routes.editModel, { fieldsetId: field?.fieldset?.id })
              : undefined
          }
          truncateLength={TRUNCATE_LENGTH}
        />
      ) : (
        <Pill text={mapping.overrideValue} icon="TypeString" />
      )}

      {!hasOptionalTargetMappings && (
        <>
          <Icon name="ArrowNarrowRight" className="mx-1 h-5 w-5 text-gray-500" />
          {mapping.target && (
            <Pill
              icon={targetConnection?.type.id}
              text={mapping.target.name}
              tooltipContent={
                <FieldDetail
                  theme="dark"
                  name={mapping.target.name}
                  logoId={targetConnection?.type.id}
                  columnName={mapping?.target.id}
                  type={mapping.target.type}
                  options={
                    syncMode === SyncMode.UpdateOrCreate && (
                      <>
                        <Icon name="RefreshSmall" className="h-4 w-4" />
                        <p className="text-xs">
                          {mapping.syncMode === SyncMode.Create
                            ? 'Create only'
                            : 'Update or create'}
                        </p>
                      </>
                    )
                  }
                />
              }
              truncateLength={TRUNCATE_LENGTH}
            />
          )}
        </>
      )}
    </div>
  );
}
