import { CompletionValue } from '../../generated/graphql';
import { getSchemaAsList, hasItems } from '../../utils';
import { FormElements } from '.';
import { FieldValues } from 'react-hook-form';

interface Props {
  schema: unknown | null | undefined;
  prefix?: string;
  promiseOptions?: (field: string, query?: string) => Promise<CompletionValue[]>;
  styleOverrides?: Record<string, string> | Record<string, { wrapper?: string; input?: string }>;
  features?: string[];
}

export function FormBuilder<TFormValues extends FieldValues>({
  features = [],
  prefix,
  promiseOptions,
  schema,
  styleOverrides
}: Props) {
  const array = getSchemaAsList(schema, prefix)?.filter(
    item => !item.features || (item.features && features.includes(item.features as string))
  );

  if (!hasItems(array)) {
    return null;
  }
  return (
    <>
      {array.map(item => (
        <FormElements<TFormValues>
          key={item.name as string}
          item={item}
          promiseOptions={promiseOptions}
          styleOverrides={styleOverrides}
        />
      ))}
    </>
  );
}
