// EXT
import React, { ReactNode, Ref, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
// INT
import { Tooltip } from '~/components/tooltip';
import { COLOR } from '~/components/v2/configs/SX';
import Chip from '~/components/v2/display/Chip';
import Divider from '~/components/v2/display/Divider';

// Todo - add comments
export type ListItemProps = {
  id?: string;
  // Sockets
  icon?: ReactNode;
  label?: ReactNode;
  endAdornment?: ReactNode;
  children?: ReactNode;
  // F(x)
  onClick?: (e?: React.MouseEvent<HTMLLIElement>) => void;
  // Numbers
  width?: number;
  countValue?: ReactNode; // Shows a chip/badge with a number
  // Booleans
  scrollSnap?: boolean;
  divider?: boolean;
  dense?: boolean;
  selected?: boolean;
  disabled?: boolean;
  focusRef?: Ref<HTMLLIElement>;
};

// Todo - add comments
export const ListItem = ({
  id,
  // Sockets
  icon,
  label,
  endAdornment,
  children,
  // F(x)
  onClick,
  // Numbers
  countValue,
  width,
  // Booleans
  scrollSnap = false,
  divider = false,
  dense = false,
  selected = false,
  disabled = false,
  focusRef
}: ListItemProps) => {
  // State
  const [tooltipDisabled, setTooltipDisabled] = useState(true);
  const elementRef = useRef<HTMLDivElement>(null);

  // SX
  const HOVER_SX = clsx(
    onClick && !selected && !disabled && 'hover:cursor-pointer hover:bg-indigo-50',
    disabled && 'hover:cursor-not-allowed',
    onClick && selected && !disabled && ' hover:cursor-default'
  );
  const ANIMATE_SX = clsx('transition duration-200 ease-in-out');
  const ROOT_SX = clsx(
    'flex w-full items-center gap-2 self-stretch overflow-x-clip p-1',
    'rounded border border-transparent',
    'outline-none focus:outline-none focus:ring-2 focus:ring-indigo-500',
    scrollSnap && 'snap-start',
    dense ? 'gap-2' : 'gap-4',
    selected && 'bg-indigo-100',
    disabled && 'opacity-50',
    HOVER_SX,
    ANIMATE_SX
  );
  const LABEL_SX = clsx(
    'overflow-x-clip truncate text-sm font-medium leading-5',
    dense ? 'gap-1' : 'gap-2',
    ANIMATE_SX
  );

  // F(X)
  /**
   * Handles the click event
   * If the item is disabled, we don't call the onClick function
   */
  const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
    !disabled && onClick?.(event);
  };

  /**
   * If the label is too long, we disable the tooltip
   * This is to prevent the tooltip from showing when the label is already visible
   */
  useEffect(() => {
    if (elementRef.current) {
      setTooltipDisabled(elementRef.current.scrollWidth <= elementRef.current.clientWidth);
    }
  }, [children, endAdornment, elementRef.current, width]);

  return (
    <>
      <Tooltip
        placement="top"
        offset={[0, 4]}
        content={label || children}
        disabled={tooltipDisabled}
      >
        <li
          key={id}
          ref={focusRef}
          id={id}
          tabIndex={-1}
          className={ROOT_SX}
          onFocus={e => e.currentTarget.click()}
          onClick={handleClick}
        >
          {/* ICON */}
          {icon && <div className="flex h-5 w-5 shrink-0">{icon}</div>}
          {/* LABEL */}
          <div className={LABEL_SX} ref={elementRef}>
            {label || children}
          </div>
          {/* END ADORNMENT || CHIP */}
          {endAdornment || (
            <div className="flex h-5 w-7 shrink-0 grow-0">
              {!!countValue && (
                <Chip color={selected ? COLOR.PRIMARY : COLOR.DEFAULT}>{countValue}</Chip>
              )}
            </div>
          )}
        </li>
      </Tooltip>
      {divider && <Divider />}
    </>
  );
};

export default ListItem;
