import { atom, useAtom } from 'jotai';
import { Ref, ReactNode } from 'react';
import { Sheet, SheetContent } from '~/components/v3/Sheet';

export type BottomNavStateAtomType = {
  bottomNavRef?: Ref<HTMLDivElement>;
  bottomNavContent?: ReactNode;
  bottomNavShow?: boolean;
};
export const BottomNavStateAtom = atom<BottomNavStateAtomType>({
  bottomNavRef: null,
  bottomNavContent: null,
  bottomNavShow: false
});

export type BottomNavProps = null;

const BottomNav = () => {
  const [bottomNavState] = useAtom(BottomNavStateAtom);

  return (
    <Sheet open={bottomNavState.bottomNavShow} modal={false}>
      <SheetContent
        storageKey="bottomNav"
        container={document.getElementsByTagName('main')[0]}
        ref={bottomNavState.bottomNavRef}
      >
        <div className="flex h-full w-full flex-col overflow-clip">
          {bottomNavState.bottomNavContent}
        </div>
      </SheetContent>
    </Sheet>
  );
};
export default BottomNav;
