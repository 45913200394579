import { JSONSchema4 } from 'json-schema';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { CompletionValue, ParameterValue, ParameterValueSource } from '../../../generated/graphql';
import { DropdownIndicator, formatOptionLabel, isSingleValue, selectStyles } from '../../../utils';
import { DisabledSelect, EditPermission, InlineFormError } from '../..';
import { SchemaFormElementProps } from './elements';

export interface CompletedElementProps {
  getOptions?: (field: string, query?: string) => Promise<CompletionValue[]>;
  options?: CompletionValue[];
}

export const TypeAheadInput = (props: SchemaFormElementProps & CompletedElementProps) => {
  let Select = AsyncSelect;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectProps: { [key: string]: any } = {};
  // see if we allow creation;
  if (
    props.field.oneOf &&
    props.field.oneOf.findIndex((one: JSONSchema4) => one.type === 'string' && !one.completions) >
      -1
  ) {
    // this input allows arbitrary strings
    Select = AsyncCreatableSelect;
    selectProps.createOptionPosition = 'first';
    selectProps.formatCreateLabel = (value: string): React.ReactNode => value;
  }

  const { control, getValues, setValue } = useFormContext();

  const promiseOptions = async (inputValue: string): Promise<CompletionValue[]> => {
    if (props.getOptions) {
      return await props.getOptions(props.id, inputValue);
    }
    return [];
  };

  return (
    <>
      <Controller
        name={props.name}
        render={({ field: { value, onChange } }) => (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          <EditPermission fallback={<DisabledSelect valueLabel={value?.label} />}>
            <Select<CompletionValue>
              {...selectProps}
              filterOption={createFilter({ ignoreAccents: false })}
              className={props.className}
              components={{
                DropdownIndicator,
                ClearIndicator: null,
                IndicatorSeparator: null
              }}
              styles={selectStyles}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              value={value}
              defaultOptions={true}
              getOptionValue={(option: CompletionValue): string => String(option.value)}
              getNewOptionData={(inputValue: string): ParameterValue => ({
                displayValue: inputValue,
                value: inputValue,
                source: ParameterValueSource.Literal
              })}
              loadOptions={promiseOptions}
              formatOptionLabel={formatOptionLabel}
              onChange={value => {
                isSingleValue(value) && onChange(value);
                (props.dependencies || []).forEach((dep: string) => {
                  setValue(dep, null);
                });
                props.onChange();
                return value;
              }}
            />
          </EditPermission>
        )}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        defaultValue={getValues(props.name) || props.defaultValue || null}
        control={control}
        rules={{
          required: props.required && `${props.field.title || ''} is required`,
          validate: (value: { value: boolean }): boolean | string => {
            if (value.value !== null || value.value !== undefined) {
              return true;
            }
            return `Value is invalid: ${JSON.stringify(value.value, null, 2)}`;
          }
        }}
      />
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      {props.errors ? <InlineFormError name={props.name} errors={props.errors} /> : null}
    </>
  );
};
