import * as React from 'react';

import { BulkSyncPeekFragment } from '../../../generated/graphql';

export const BulkSyncContext = React.createContext<BulkSyncPeekFragment | undefined>(undefined);

export function BulkSyncProvider({
  bulkSync,
  children
}: {
  bulkSync: BulkSyncPeekFragment;
  children: React.ReactNode;
}) {
  const value = React.useMemo(() => bulkSync, [bulkSync]);
  return <BulkSyncContext.Provider value={value}>{children}</BulkSyncContext.Provider>;
}

export function useBulkSyncState() {
  const contextIsDefined = React.useContext(BulkSyncContext);
  if (contextIsDefined == null) {
    throw new Error('useBulkSync must be used within a BulkSyncProvider');
  }
  return contextIsDefined;
}
