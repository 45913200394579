import * as React from 'react';
import { v4 as uuid } from 'uuid';

import { MyInput } from './input';

interface Props {
  values: string[];
  onUpdate: (values: [string, string]) => void;
  // Todo -> turn into enum type for all inputs
  variant?: 'outlined' | 'flat';
}

export function BetweenNumbersInput({ variant = 'outlined', ...props }: Props) {
  const [first, setFirst] = React.useState<string>(props.values?.[0] ?? '');
  const [second, setSecond] = React.useState<string>(props.values?.[1] ?? '');

  return (
    <div className="grid grid-cols-[1fr,2rem,1fr]">
      <MyInput
        variant={variant}
        name={uuid()}
        value={first}
        placeholder="Number..."
        onChange={e => {
          setFirst(e.target.value);
          props.onUpdate([e.target.value, second]);
        }}
      />
      <span className="flex items-center justify-center text-sm text-gray-500">and</span>
      <MyInput
        variant={variant}
        name={uuid()}
        value={second}
        placeholder="Number..."
        onChange={e => {
          setSecond(e.target.value);
          props.onUpdate([first, e.target.value]);
        }}
      />
    </div>
  );
}
