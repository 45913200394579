import { Controller, useFormContext } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  ModelFieldSelect,
  ParamButton,
  SideBySide
} from '~/components';
import { useFieldsetState } from '~/hooks';
import { FieldsetFormValues, WINDOW_THRESHOLD, hasItems, modelFieldToSelectable } from '~/utils';

export function PrimaryKey() {
  const { fieldset } = useFieldsetState();
  const { control } = useFormContext<FieldsetFormValues>();

  if (!fieldset?.properties?.uniqueIdSelection || !hasItems(fieldset?.fields)) {
    return null;
  }

  return (
    <SideBySide hasSectionWrap heading="Unique identifier">
      <Controller
        control={control}
        name="primaryKey"
        render={({ field }) => (
          <div className="flex items-center space-x-3">
            <EditPermission
              fallback={
                <DisabledSelect className="w-full max-w-xs" valueLabel={field?.value?.label} />
              }
            >
              <ModelFieldSelect
                aria-label="Unique identifier"
                className="w-full max-w-xs"
                options={fieldset?.fields}
                placeholder="Select current model field..."
                value={field?.value ? modelFieldToSelectable(field?.value) : undefined}
                hideGroups={true}
                hideConnections={true}
                onChange={field?.onChange}
                isWindowed={fieldset?.fields.length > WINDOW_THRESHOLD}
              />
            </EditPermission>
            {field?.value && (
              <EditPermission>
                <ParamButton
                  className="mt-px focus-visible:ring-offset-gray-100"
                  action="delete"
                  onClick={() => field?.onChange(null)}
                />
              </EditPermission>
            )}
          </div>
        )}
      />
    </SideBySide>
  );
}
