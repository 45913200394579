import { useQuery } from '@apollo/client';
import * as React from 'react';

import { LinkButton } from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import { AllRolesDocument, UsersDocument } from '~/generated/graphql';
import { useBannerDispatch, useToggle } from '~/hooks';
import { RoleWithUser } from '~/utils';
import { AddRole } from './add-role';
import { RoleTable } from './role-table';

const wrapperStyles = 'mb-3';

const TeamRoles = () => {
  const dispatchBanner = useBannerDispatch();
  const [expand, toggleExpand] = useToggle();
  const { data: rolesData } = useQuery(AllRolesDocument);
  const { data, loading } = useQuery(UsersDocument, {
    onError: error =>
      dispatchBanner({
        type: 'show',
        payload: {
          message: error,
          wrapper: wrapperStyles
        }
      })
  });

  const [toggled, setToggled] = React.useState(
    rolesData?.allRoles.reduce((acc: Record<string, boolean>, v) => {
      acc[v.id] = false;
      return acc;
    }, {}) || {}
  );

  const handleExpand = () => {
    if (expand) {
      setToggled(
        rolesData?.allRoles.reduce((acc: Record<string, boolean>, v) => {
          acc[v.id] = false;
          return acc;
        }, {}) || {}
      );
    } else {
      setToggled(
        rolesData?.allRoles.reduce((acc: Record<string, boolean>, v) => {
          acc[v.id] = true;
          return acc;
        }, {}) || {}
      );
    }
    toggleExpand();
  };
  const setToggle = React.useCallback((id: string) => {
    setToggled(prev => {
      const newValue = { ...prev };
      if (newValue[id] == null) {
        newValue[id] = true;
      } else {
        newValue[id] = !newValue[id];
      }
      return newValue;
    });
  }, []);

  const rolesWithUsers: RoleWithUser[] = React.useMemo(() => {
    if (!rolesData || rolesData?.allRoles.length === 0) {
      return [];
    }
    const groupedByRole =
      data?.users.reduce((acc: Record<string, Array<{ email: string; id: string }>>, v) => {
        for (const role of v.roles) {
          if (!acc[role.id]) {
            acc[role.id] = [];
          }
          acc[role.id].push({ email: v.email, id: v.id });
        }
        return acc;
      }, {}) || {};

    return (
      [...rolesData.allRoles]
        .sort((a, b) => +b.system - +a.system || a.name.localeCompare(b.name))
        .map(role => ({
          ...role,
          users: groupedByRole[role.id]?.sort((a, b) => a.email.localeCompare(b.email)) || []
        })) || []
    );
  }, [rolesData, data?.users]);

  return (
    <PageLayout
      loading={loading}
      topNavHeading="Roles"
      topNavActions={<AddRole setToggle={setToggle} />}
    >
      <div className="mx-auto w-full max-w-4xl space-x-4">
        <LinkButton onClick={handleExpand}>{expand ? 'Collapse' : 'Expand'} all</LinkButton>
      </div>
      {rolesWithUsers.map(role => (
        <RoleTable key={role.id} role={role} isToggled={toggled[role.id]} setToggle={setToggle} />
      ))}
    </PageLayout>
  );
};

export default TeamRoles;
