import cx from 'clsx';
import * as React from 'react';

import { Section } from './section';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  heading?: string | React.ReactNode;
  maxWidth?: string;
  styles?: string;
  hasSectionWrap?: boolean;
}

export const SideBySide = ({
  heading = '',
  children,
  className = 'grid grid-cols-[12.5rem,1fr]',
  maxWidth = 'max-w-2xl',
  hasSectionWrap = false,
  styles
}: Props) => {
  const component = (
    <div className={className}>
      {typeof heading === 'string' ? <h3 className="sbs-heading">{heading}</h3> : heading}
      <div className={cx(maxWidth, styles)}>{children}</div>
    </div>
  );
  if (hasSectionWrap) {
    return <Section>{component}</Section>;
  }
  return component;
};
