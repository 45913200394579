import * as React from 'react';

import { Action, ResourceType } from '../generated/graphql';
import { useLacksPermission } from '../hooks';
import { NO_PERMISSION } from '../utils';
import { Tooltip } from './tooltip';

export interface PermissionProps {
  children?: React.ReactNode;
  type?: ResourceType | Action;
  message?: string | React.ReactNode;
  fallback?: React.ReactNode;
  hideTooltip?: boolean;
}

export function Permission({
  children,
  message = NO_PERMISSION,
  fallback,
  type,
  hideTooltip = false
}: PermissionProps) {
  const lacksPermission = useLacksPermission(type);
  if (fallback) {
    if (!React.isValidElement(fallback)) {
      throw new Error('must be a single element');
    }
    if (lacksPermission) {
      return fallback;
    }
  }

  if (!children) {
    return null;
  }

  if (!React.isValidElement(children)) {
    throw new Error('must be a single element');
  }

  if (lacksPermission) {
    if (hideTooltip) {
      return React.cloneElement(React.Children.only(children), {
        // @ts-ignore
        readOnly: true,
        onClick: undefined,
        onFocus: undefined,
        onBlur: undefined
      });
    }
    return (
      <Tooltip content={message}>
        {React.cloneElement(React.Children.only(children), {
          // @ts-ignore
          readOnly: true,
          onClick: undefined,
          onFocus: undefined,
          onBlur: undefined
        })}
      </Tooltip>
    );
  }

  return children;
}
