import { SVGProps } from 'react';

export function CaretRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        stroke="currentColor"
        d="M7.29303 14.7068C7.10556 14.5191 7.00024 14.2645 7.00024 13.999C7.00024 13.7335 7.10556 13.4788 7.29303 13.2911L10.586 9.99396L7.29303 6.69683C7.11087 6.50799 7.01008 6.25508 7.01236 5.99255C7.01463 5.73003 7.1198 5.4789 7.30521 5.29326C7.49062 5.10762 7.74143 5.00232 8.00363 5.00004C8.26583 4.99776 8.51843 5.09868 8.70703 5.28106L12.707 9.28607C12.8945 9.47383 12.9998 9.72846 12.9998 9.99396C12.9998 10.2595 12.8945 10.5141 12.707 10.7018L8.70703 14.7068C8.5195 14.8946 8.26519 15 8.00003 15C7.73487 15 7.48056 14.8946 7.29303 14.7068Z"
      />
    </svg>
  );
}
