import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import { activityTypes, type Change } from './ActivityTypes';
import { keys, values, map } from 'lodash';
import { Truncator } from '~/components/truncator';

export const ActivityLogItem = ({ change }: { change: Change }) => {
  const hasMultipleLabels = keys(change.logs)?.length > 1;
  // If there's only a single group in the change, render the label of the first child
  const changeLabel = hasMultipleLabels ? 'Multiple changes' : values(change.logs)[0][0].label;
  const user = change?.performedBy?.displayName;
  const time = dayjs(change?.performedAt).format('hh:mm a');

  return (
    <li className="relative -top-1 mb-3 w-full">
      <span className="absolute top-1.5 -left-[13px] h-2 w-2 rounded-full bg-blue-500 ring-2 ring-white" />
      <div className="relative pl-3">
        <div className="flex flex-row items-center justify-start space-x-2">
          <div className="flex flex-col space-y-1 overflow-clip">
            <div className="flex flex-row items-center justify-start space-x-1">
              <Truncator content={changeLabel}>
                <span className="hide-native-tooltip cursor-default truncate text-sm font-medium text-gray-800">
                  {changeLabel}
                </span>
              </Truncator>
              <span className="text-sm text-gray-500">·</span>
              <span className="whitespace-nowrap text-sm text-gray-500">
                {user}, {time}
              </span>
            </div>
            {map(change.logs, (logs, label) => (
              <div key={label} className="contents">
                {hasMultipleLabels && (
                  <span className="relative pt-2 text-sm font-medium text-gray-800">{label}</span>
                )}
                {logs
                  .sort((b, a) => a.activityType.localeCompare(b.activityType))
                  .map(log => {
                    try {
                      const Component = activityTypes[log.activityType].render;
                      return (
                        <div key={log.id} className="space-y-1 overflow-clip truncate">
                          {Component({ log })}
                        </div>
                      );
                    } catch (error) {
                      Sentry.captureException(
                        `Activity log validation failed for type ${log.activityType} `,
                        { extra: { error, log } }
                      );
                      return null;
                    }
                  })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </li>
  );
};
