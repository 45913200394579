import { useFormContext, useWatch } from 'react-hook-form';
import { FormBuilder, Label, MyCombobox } from '~/components';
import { JiraAuthSchemas } from './schemas';

const Options = {
  apikey: {
    label: 'API Key',
    value: 'apikey'
  },
  pat: {
    label: 'Personal Access Token',
    value: 'pat'
  }
};
const DefaultOption = Options['apikey'];

const AuthMethodKey = 'auth_method';

export function Jira() {
  const { control, register, setValue } = useFormContext();
  const auth = useWatch({ control, name: AuthMethodKey });
  const selected = Options[auth] || DefaultOption;

  const handleSelect = (selected: { label: string; value: string }) => {
    setValue(AuthMethodKey, selected.value);
  };

  return (
    <>
      <div>
        <Label>Authentication method</Label>
        <MyCombobox options={Object.values(Options)} value={selected} onChange={handleSelect} />
        <input {...register(AuthMethodKey)} type="hidden" value={selected.value} />
      </div>
      <FormBuilder schema={JiraAuthSchemas[selected.value]} />
    </>
  );
}
