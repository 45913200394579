import * as React from 'react';

import { Tooltip, TooltipProps } from './tooltip';
import { isJsonString } from '~/utils';
import { isString } from 'lodash';

type ValidElement = HTMLDivElement | HTMLHeadingElement;

interface Props extends TooltipProps {
  children: React.ReactElement<ValidElement>;
}

export function Truncator({ children, content, ...props }: Props) {
  const [disabled, setDisabled] = React.useState(true);
  const elementRef = React.useRef<ValidElement | null>(null);

  const child = React.Children.only(children);

  React.useLayoutEffect(() => {
    if (elementRef.current) {
      setDisabled(elementRef.current.offsetWidth >= elementRef.current.scrollWidth);
    }
  }, [children]);

  if (!React.isValidElement(child)) {
    return child;
  }

  if (isString(content) && isJsonString(content)) {
    content = (
      <pre
        style={{
          maxHeight: '200px',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
        className="light-scrollbar"
      >
        {JSON.stringify(JSON.parse(content), null, 2)}
      </pre>
    );
    props.interactive = true;
    props.appendTo = document.body;
  }

  return (
    <Tooltip placement="top" offset={[0, 4]} disabled={disabled} content={content} {...props}>
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React.cloneElement(child as React.ReactElement<any>, {
          ref: elementRef
        })
      }
    </Tooltip>
  );
}
