import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { LinkButton, Section, SideBySide } from '~/components';
import { useFieldsetState } from '~/hooks';
import { FieldsetFormValues, findName, getSchemaAsList } from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { EnumPicker, FieldsTable } from '../components';

export function AffinityFieldsetConfig() {
  const { fieldset, loading, refreshing, applyUpdate } = useFieldsetState();
  const { getValues } = useFormContext<FieldsetFormValues>();

  const list = React.useMemo(
    () => getSchemaAsList(fieldset?.configurationSchema, 'configuration'),
    [fieldset?.configurationSchema]
  );

  const handleRefresh = React.useCallback(() => {
    applyUpdate({ ...getValues('configuration') }, { refresh: true });
  }, [applyUpdate, getValues]);

  const handleRefreshConfiguration = React.useCallback(() => {
    applyUpdate({ ...getValues('configuration') }, { refreshConfiguration: true });
  }, [applyUpdate, getValues]);

  const onChange = React.useCallback(() => {
    applyUpdate({ ...getValues('configuration') });
  }, [applyUpdate, getValues]);

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          {fieldset?.configurationSchema && (
            <div className="w-full max-w-xs animate-fadeIn space-y-4">
              <div className="flex justify-between">
                <label className="mb-1 block text-left text-sm font-medium text-gray-800">
                  Object
                </label>
                <LinkButton
                  tabIndex={-1}
                  disabled={false}
                  onClick={handleRefreshConfiguration}
                  className="mb-1 self-end text-xs"
                >
                  Refresh
                </LinkButton>
              </div>
              <EnumPicker
                item={findName(list, 'configuration.object')}
                onChange={onChange}
                loading={loading}
                hideLabel={true}
                className="!mt-0"
              />
            </div>
          )}
        </SideBySide>
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || (loading && !fieldset?.fields)}
          disabled={loading}
          refresh={handleRefresh}
          hasWriteinFields={fieldset?.properties.writeinFields}
        />
      </Section>
      <AdditionalConfig />
    </>
  );
}
