import { useQuery } from '@apollo/client';
import cx from 'clsx';
import { generatePath, Link, useParams } from 'react-router-dom';

import { SideBySide } from '~/components';
import { SyncsForModelDocument } from '~/generated/graphql';
import { routes } from '~/utils';

export function SyncsUsingFieldset() {
  const { fieldsetId } = useParams<{ fieldsetId: string }>();
  const { data, loading } = useQuery(SyncsForModelDocument, {
    fetchPolicy: 'network-only',
    skip: !fieldsetId,
    variables: { fieldsetId }
  });

  const syncs = data?.syncsForModel;
  if (loading || !syncs?.length) {
    return null;
  }

  return (
    <SideBySide hasSectionWrap heading="Syncs">
      <ul className={cx('space-y-2', syncs.length > 1 && 'list-disc')}>
        {syncs?.map(sync => (
          <li key={sync?.id}>
            <Link
              to={generatePath(routes.syncStatus, { id: sync?.id })}
              target="_blank"
              rel="noopener noreferrer"
              className="link text-sm"
            >
              {sync?.targetConnection?.name
                ? `${sync.targetConnection.name}: ${sync?.name}`
                : sync?.name}
            </Link>
          </li>
        ))}
      </ul>
    </SideBySide>
  );
}
