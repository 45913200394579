import Tippy, { TippyProps } from '@tippyjs/react/headless';
import cx from 'clsx';
import * as React from 'react';

export type TooltipProps = {
  content: React.ReactNode;
  removePadding?: boolean;
} & TippyProps;

export const Tooltip = ({
  className,
  content,
  children,
  removePadding,
  ...props
}: TooltipProps) => {
  return (
    <Tippy
      interactive={false}
      {...props}
      render={attrs => (
        <div
          {...attrs}
          className={cx(
            'inline-flex animate-fadeIn cursor-default flex-col rounded bg-black bg-opacity-90 font-medium text-white focus:outline-none',
            !removePadding && 'py-1.5 px-2',
            className
          )}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {content}
        </div>
      )}
    >
      {children}
    </Tippy>
  );
};
