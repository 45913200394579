import cx from 'clsx';
import { capitalize } from 'lodash';

import { Icon, Tooltip } from '~/components';
import { ExecutionStatus, SyncExecutionFragment, SyncFragment } from '~/generated/graphql';
import { useToggle } from '~/hooks';
import { plural, recordDialogHeading } from '~/utils';
import { SyncExecutionRecordsPreview } from '../sync-execution-records-preview';

interface Props {
  sync: SyncFragment;
  execution: SyncExecutionFragment;
}

export function ExecutionStatusDisplay({ sync, execution }: Props) {
  const [showDialog, toggleDialog] = useToggle();

  const dialog = showDialog && (
    <SyncExecutionRecordsPreview
      heading={recordDialogHeading('errors')}
      executionId={execution?.id}
      recordType="errors"
      targetConnectionTypeId={sync.targetConnection?.type.id}
      targetConnectionName={sync.targetConnection?.name}
      targetObjectName={sync.targetObject?.name}
      handleDismiss={toggleDialog}
    />
  );
  switch (execution.status) {
    case ExecutionStatus.Created:
      return <p className="text-sm text-gray-800">Starting...</p>;
    case ExecutionStatus.Running:
    case ExecutionStatus.Processing:
      return <p className="text-sm text-gray-800">{capitalize(execution.status)}...</p>;
    case ExecutionStatus.Completed:
      if (execution.errorCount && execution.errorCount > 0) {
        return (
          <Tooltip
            content={cx(
              execution.errorCount?.toLocaleString(),
              plural('error', execution.errorCount > 1)
            )}
          >
            <p className="flex w-max items-center space-x-1.5 text-sm text-gray-800">
              <span>Completed with errors</span>
              <span onClick={execution.errors?.hasData ? toggleDialog : undefined}>
                <Icon name="WarningFilled" className="h-4 w-4 text-amber-500" />
              </span>
              {dialog}
            </p>
          </Tooltip>
        );
      }
      return (
        <p className="flex items-center space-x-1.5 text-sm text-gray-800">
          <span>Completed</span>
          <Icon name="CheckFilled" className="h-4 w-4 text-green-500" />
        </p>
      );
    case ExecutionStatus.Failed:
      return (
        <Tooltip
          disabled={!execution.executionErrors || execution.executionErrors.length === 0}
          content={execution.executionErrors?.map(e => e.error) || 'Sync failed'}
        >
          <p className="flex w-max items-center space-x-1.5 text-sm text-gray-800">
            <span>Failed</span>
            <span onClick={execution.errors?.hasData ? toggleDialog : undefined}>
              <Icon name="DangerFilled" className="h-4 w-4 text-red-500" />
            </span>
            {dialog}
          </p>
        </Tooltip>
      );
    case ExecutionStatus.Interrupted:
      return <p className="text-sm text-gray-800">Restarting...</p>;
    default:
      return <p className="text-sm text-gray-800">{capitalize(execution.status)}</p>;
  }
}
