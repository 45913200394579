import cx from 'clsx';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export function TableTopper(props: Props) {
  return (
    <div
      className={cx(
        'inline-flex w-full items-center rounded-t border-b border-gray-300 text-sm font-medium text-gray-800',
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
