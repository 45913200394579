import { ReactElement } from 'react';
import { useAuth } from '../hooks';
import { isEmpty } from 'lodash';

interface Props {
  feature: string;
  children?: ReactElement;
  fallback?: ReactElement;
}

export function FeatureFlag({ feature, children = null, fallback = null }: Props) {
  const { user } = useAuth();
  return !isEmpty(feature) && !user?.organization.features.includes(feature) ? fallback : children;
}
