import cx from 'clsx';

import { Icon, TableWrap, Truncator } from '~/components';
import { AclProvider } from '~/hooks';
import { RoleWithUser, hasItems } from '~/utils';
import { RoleActions } from './role-actions';

export function RoleTable({
  role,
  isToggled,
  setToggle
}: {
  role: RoleWithUser;
  isToggled: boolean;
  setToggle: (id: string) => void;
}) {
  const toggle = () => {
    setToggle(role.id);
  };
  return (
    <TableWrap className="mx-auto w-full max-w-4xl overflow-x-auto">
      <table className="min-w-full text-left">
        <thead>
          <tr>
            <th className="py-2.75 pl-2 pr-4">
              <span className="flex items-baseline justify-between">
                <button
                  className="flex items-center space-x-1 rounded px-2 py-1 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-300 active:bg-gray-200"
                  onClick={toggle}
                >
                  <span className="text-base font-medium text-gray-800">{role.name}</span>
                  <Icon
                    name="SelectSingle"
                    className={cx('h-5 w-5 text-gray-500', isToggled && 'rotate-180')}
                  />
                </button>
                {role.system ? (
                  <span className="text-right text-xs font-normal text-amber-600">
                    Built-in role
                  </span>
                ) : (
                  <AclProvider value={role.acl}>
                    <RoleActions role={role} />
                  </AclProvider>
                )}
              </span>
            </th>
          </tr>
        </thead>
        {isToggled && (
          <tbody className="border-t border-gray-300">
            {hasItems(role.users) ? (
              role.users.map(user => (
                <tr key={user.id} className="align-top odd:bg-gray-50">
                  <td className="flex items-center py-2 px-4">
                    <Icon name="UserCircle" className="mr-2 h-4 w-4 text-gray-500" />
                    <Truncator content={cx(user.email)}>
                      <p className="truncate">
                        {user.email}
                        <span className="text-gray-500"></span>
                      </p>
                    </Truncator>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-gray-50 align-top">
                <td className="p-4 text-center text-gray-400">No one assigned.</td>
              </tr>
            )}
          </tbody>
        )}
      </table>
    </TableWrap>
  );
}
