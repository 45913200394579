import { useMutation } from '@apollo/client';
import cx from 'clsx';

import {
  Button,
  EditPermission,
  FrequencyOptions,
  Icon,
  MySwitch,
  Permission,
  SideBySide,
  Tooltip
} from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import {
  Action,
  ActivateBulkSyncDocument,
  BulkExecutionStatus,
  BulkSyncDocument,
  BulkSyncExecutionFragment,
  BulkSyncFragment,
  CancelBulkSyncDocument,
  ConnectionFragment,
  Operation,
  SelectiveMode,
  StartBulkSyncDocument
} from '~/generated/graphql';
import { useBannerDispatch, useConfirmation, useToggle } from '~/hooks';
import { Selectable, capsFirst, emptyCell, getLongLocalTime, isTerminal, plural } from '~/utils';
import { StatusLabel } from '../../syncs/sync-status';
import { useBulkSyncState } from '../components';
import { Dialog, FilterButton, useToast } from '~/components/v3';
import { useEffect, useMemo, useState } from 'react';
import { BulkSyncSchemaPickerDrawer } from './BulkSyncSchemaPickerDrawer';
import { isEmpty } from 'lodash';
import { BulkSyncSchemaExecutionDrawer } from './BulkSyncSchemaExecutionDrawer';
import { BulkStatusSub } from './bulk-status-sub';

interface SelectMode extends Selectable {
  selectionLabel: (schemas: string[]) => string;
  selectiveMode: SelectiveMode;
}

export function BulkStatusDisplay() {
  const bulkSync = useBulkSyncState();

  const schemaLabel = bulkSync?.source?.schemaLabel;
  let namespaceLabel = bulkSync?.source?.namespaceLabel?.plural;
  namespaceLabel = isEmpty(namespaceLabel) ? schemaLabel?.plural ?? 'objects' : namespaceLabel;

  const dispatchBanner = useBannerDispatch();
  const [isActive, setIsActive] = useState(bulkSync.active);
  const [showPicker, togglePicker] = useToggle();
  const [schemas, setSchemas] = useState<string[]>(null);
  const { confirmation, confirmationMessage, handleConfirmation, resetConfirmation } =
    useConfirmation();
  const [showSchemaExecutionDrawer, toggleSchemaExecutionDrawer] = useToggle();
  const { toast } = useToast();

  const syncModes = {
    NORMAL: {
      label: `Sync`,
      value: 'sync',
      disabled: !isActive,
      disabledMessage: 'Enable sync to activate'
    },
    FORCE: { label: `Force resync`, value: 'force' }
  };
  const selectModes = {
    FULL: {
      label: `All ${schemaLabel.plural}`,
      value: 'full',
      selectionLabel: () => `All ${schemaLabel.plural}`,
      selectiveMode: SelectiveMode.None
    },
    INCREMENTAL: {
      label: 'Incremental fields',
      value: 'incremental',
      selectionLabel: () => 'Incremental fields',
      selectiveMode: SelectiveMode.IncrementalFields
    },
    NONINCREMENTAL: {
      label: 'Non-incremental fields',
      value: 'nonincremental',
      selectionLabel: () => 'Non-incremental fields',
      selectiveMode: SelectiveMode.NonincrementalFields
    },
    PARTIAL: {
      label: `Select ${schemaLabel.plural}...`,
      value: 'partial',
      selectionLabel: schemas =>
        !schemas?.length
          ? `All ${schemaLabel.plural}`
          : `${schemas?.length} ${
              schemas?.length === 1 ? schemaLabel.singular : schemaLabel.plural
            }`,
      selectiveMode: SelectiveMode.None
    }
  } as const satisfies Record<string, SelectMode>;

  const [syncMode, setSyncMode] = useState<Selectable>(syncModes.NORMAL);
  const [selectMode, setSelectMode] = useState<SelectMode>(selectModes.FULL);

  const supportsIncremental =
    bulkSync?.source?.properties?.supportsIncremental &&
    bulkSync?.destination?.properties?.supportsIncremental;

  const selectModeOptions = useMemo(
    () => [
      selectModes.FULL,
      ...(supportsIncremental && syncMode.value !== syncModes.FORCE.value
        ? [selectModes.INCREMENTAL, selectModes.NONINCREMENTAL]
        : []),
      selectModes.PARTIAL
    ],
    [supportsIncremental, syncMode, selectModes]
  );

  const [activateSync, { loading: activateSyncLoading }] = useMutation(ActivateBulkSyncDocument, {
    optimisticResponse: vars => {
      return {
        activateBulkSync: {
          id: vars.id,
          active: vars.active
        }
      };
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    update: (cache, { data }) => {
      if (!data || !data.activateBulkSync) {
        return;
      }
      const id = data.activateBulkSync.id;
      cache.modify({
        id: cache.identify({ __typename: 'BulkSync', id }),
        fields: { active: () => data.activateBulkSync.active }
      });
    }
  });
  const [startBulkSync, { loading: startBulkSyncLoading }] = useMutation(StartBulkSyncDocument, {
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    update: (cache, { data }) => {
      if (!data || !data.startBulkSync) {
        return;
      }

      const bulkSync = data.startBulkSync as BulkSyncFragment;
      // make sure get bulkSync doesn't make network call
      cache.writeQuery({
        query: BulkSyncDocument,
        variables: { id: bulkSync.id },
        data: { bulkSync }
      });
    }
  });

  const [forceBulkSync, { loading: forceBulkSyncLoading }] = useMutation(StartBulkSyncDocument, {
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    onCompleted: data => handleConfirmation(data.startBulkSync)
  });

  const execution = bulkSync.execution || null;
  const isExecuting = bulkSync.activeExecutions && bulkSync.activeExecutions.length > 0;

  const executingStatus = isExecuting
    ? bulkSync.activeExecutions[0].status
    : bulkSync.execution?.status || BulkExecutionStatus.Canceled;

  const nextExecutionTime = bulkSync.nextExecutionTime;
  const multipleExecs = bulkSync.features.includes('bulk-multiple-execs');
  function handleEnable(active: boolean) {
    setIsActive(active);
    if (active) {
      toast({
        icon: <Icon match="InfoFilled" className="text-blue-400" />,
        description: 'Sync moved out of Disabled',
        duration: 3000
      });
    } else {
      toast({
        icon: <Icon match="InfoFilled" className="text-blue-400" />,
        description: 'Sync moved to Disabled',
        duration: 3000
      });
    }
    void activateSync({
      variables: { id: bulkSync.id, active }
    });
  }

  function handleStartBulkSync() {
    void startBulkSync({
      variables: { id: bulkSync.id, opts: { schemas, selectiveMode: selectMode.selectiveMode } }
    });
  }

  function handleTestSync() {
    void startBulkSync({ variables: { id: bulkSync.id, opts: { test: true } } });
  }

  function handleForceResync() {
    void forceBulkSync({
      variables: {
        id: bulkSync.id,
        opts: { resync: true, schemas }
      }
    });
  }

  function handleForceResyncConfirm() {
    void forceBulkSync({
      variables: {
        id: bulkSync.id,
        opts: {
          resync: true,
          schemas,
          selectiveMode: selectMode.selectiveMode
        },
        confirmation
      }
    });
    resetConfirmation();
  }

  const handleSyncModeChange = (mode: Selectable) => {
    if (
      selectMode.value === selectModes.INCREMENTAL.value ||
      selectMode.value === selectModes.NONINCREMENTAL.value
    ) {
      setSelectMode(selectModes.FULL);
      setSchemas(null);
    }
    setSyncMode(mode);
  };

  const handleSelectModeChange = (mode: SelectMode) => {
    if (mode.value === selectModes.PARTIAL.value) {
      togglePicker();
    } else {
      setSchemas(null);
    }

    setSelectMode(mode);
  };

  const handlePickerDismiss = (selected: string[]) => {
    togglePicker();

    if (selected === undefined) {
      return;
    }

    // If the picker is dismissed without a selection, set the mode to full resync but don't clear previous selection
    if (!selected.length && !schemas?.length) {
      setSelectMode(selectModes.FULL);
      return;
    }

    const totalObjects = bulkSync.namespaces.reduce(
      (acc, namespace) => acc.concat(namespace.schemas),
      []
    ).length;

    setSchemas(selected.length === totalObjects ? [] : selected);
  };

  const schemaSelectedLabel = useMemo(
    () => selectMode.selectionLabel(schemas),
    [schemas, schemaLabel, selectMode]
  );

  useEffect(() => {
    setSyncMode(isActive ? syncModes.NORMAL : syncModes.FORCE);
  }, [isActive]);

  return (
    <>
      <SideBySide hasSectionWrap heading="Status" maxWidth="max-w-full">
        <div className="-mr-6 space-y-4 divide-y divide-gray-300">
          <EditPermission>
            <MySwitch
              label="Enable sync"
              checked={isActive}
              disabled={
                activateSyncLoading ||
                (bulkSync.destination?.connection?.health.status !== 'healthy' && !isActive)
              }
              onChange={handleEnable}
            />
          </EditPermission>
          <div className="grid grid-cols-[6rem,1fr] gap-x-6 gap-y-4 pt-4">
            {execution ? (
              <>
                <StatusLabel text="Status">
                  <div className="flex flex-row space-x-2">
                    <ExecutionStatusDisplay
                      execution={execution}
                      connection={bulkSync.source?.connection as ConnectionFragment}
                    />
                    <a className="link" onClick={toggleSchemaExecutionDrawer}>
                      Details
                    </a>
                  </div>
                </StatusLabel>
                {execution?.startedAt && (
                  <>
                    <StatusLabel text={isExecuting ? 'Started' : 'Last run'}>
                      <p>{getLongLocalTime(execution.startedAt) || emptyCell}</p>
                    </StatusLabel>
                    {execution?.completedAt && (
                      <StatusLabel text="Duration">
                        <p>{execution.formattedDuration || emptyCell}</p>
                      </StatusLabel>
                    )}
                  </>
                )}
                <StatusLabel text="Total records">
                  <SyncRecordCount execution={execution} />
                </StatusLabel>
              </>
            ) : (
              <StatusLabel text="Status">
                <p>Never run</p>
              </StatusLabel>
            )}
          </div>
          {bulkSync.active && (
            <div className="grid grid-cols-[6rem,1fr] gap-x-6 pt-4">
              <StatusLabel text="Next run">
                <span>
                  {nextExecutionTime
                    ? getLongLocalTime(nextExecutionTime)
                    : FrequencyOptions[bulkSync.schedule?.frequency]?.label}
                </span>
              </StatusLabel>
            </div>
          )}
        </div>
      </SideBySide>
      <SideBySide hasSectionWrap heading="Controls" maxWidth="max-w-full">
        <div className="-mr-6">
          {!bulkSync.active && (multipleExecs || !isExecuting) && (
            <div className="mb-4 flex items-center space-x-2 border-b border-gray-300 pb-4">
              <Permission type={Action.Trigger}>
                <Button
                  onClick={handleTestSync}
                  disabled={startBulkSyncLoading || forceBulkSyncLoading}
                >
                  Run test sync
                </Button>
              </Permission>
              {startBulkSyncLoading ? (
                <LoadingDots />
              ) : (
                <Tooltip
                  placement="top"
                  content={cx(
                    'Sync five random records',
                    bulkSync.destination?.connection?.name &&
                      `to ${bulkSync.destination?.connection.name}`
                  )}
                >
                  <span className="mt-0.5">
                    <Icon
                      name="InfoFilled"
                      className="h-4 w-4 cursor-pointer text-blue-500 hover:text-blue-400"
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          )}
          {multipleExecs ? (
            <div className="mb-4 flex items-center space-x-2 border-b border-gray-300 pb-4">
              <Permission type={Action.Trigger}>
                <CancelButton status={executingStatus} />
              </Permission>
            </div>
          ) : (
            isExecuting && (
              <div className="mb-4 border-b border-gray-300 pb-4">
                <Permission type={Action.Trigger}>
                  <CancelButton status={executingStatus} />
                </Permission>
              </div>
            )
          )}
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-2">
              <FilterButton
                buttonText={syncMode.label}
                options={Object.values(syncModes)}
                value={syncMode}
                onChange={handleSyncModeChange}
                disabled={forceBulkSyncLoading || startBulkSyncLoading || isExecuting}
              />
              {!bulkSync?.destination?.connection?.type?.operations?.includes(
                Operation.BulkNoPartialSync
              ) && (
                <FilterButton
                  buttonText={schemaSelectedLabel}
                  options={selectModeOptions}
                  value={selectMode}
                  onChange={handleSelectModeChange}
                  disabled={forceBulkSyncLoading || startBulkSyncLoading || isExecuting}
                />
              )}
              <Permission type={Action.Trigger}>
                <Button
                  theme={syncMode.value === syncModes.FORCE.value ? 'warning' : 'default'}
                  disabled={forceBulkSyncLoading || startBulkSyncLoading || isExecuting}
                  onClick={() =>
                    syncMode.value === syncModes.FORCE.value
                      ? handleForceResync()
                      : handleStartBulkSync()
                  }
                >
                  Start
                </Button>
              </Permission>
              {isExecuting ? (
                <Tooltip placement="top" content="Disabled while sync is running">
                  <span className="mt-0.5">
                    <Icon
                      name="InfoFilled"
                      className="h-4 w-4 cursor-pointer text-blue-500 hover:text-blue-400"
                    />
                  </span>
                </Tooltip>
              ) : (
                forceBulkSyncLoading && <LoadingDots />
              )}
            </div>
          </div>
        </div>
      </SideBySide>
      <BulkSyncSchemaPickerDrawer
        namespaces={bulkSync.namespaces}
        selected={schemas}
        schemaLabel={schemaLabel}
        namespaceLabel={namespaceLabel}
        open={showPicker}
        onDismiss={handlePickerDismiss}
      />
      <BulkSyncSchemaExecutionDrawer
        id={bulkSync.id}
        schemaLabel={schemaLabel}
        namespaceLabel={namespaceLabel}
        open={showSchemaExecutionDrawer}
        onDismiss={toggleSchemaExecutionDrawer}
      />
      {bulkSync?.id && <BulkStatusSub execution={execution} />}

      <Dialog
        show={!!confirmation}
        onDismiss={resetConfirmation}
        heading="Force resync"
        actions={
          <>
            <Button onClick={resetConfirmation}>Cancel</Button>
            <Button theme="warning" onClick={handleForceResyncConfirm}>
              Continue
            </Button>
          </>
        }
      >
        <p>{confirmationMessage}</p>
      </Dialog>
    </>
  );
}

function ExecutionStatusDisplay({
  execution,
  connection
}: {
  execution: BulkSyncExecutionFragment;
  connection: ConnectionFragment;
}) {
  switch (execution.status) {
    case BulkExecutionStatus.Created:
      return <p className="text-sm text-gray-800">Starting...</p>;
    case BulkExecutionStatus.Exporting:
      return <p className="text-sm text-gray-800 ">Exporting from {connection.name}...</p>;
    case BulkExecutionStatus.Running:
    case BulkExecutionStatus.Processing:
      return <p className="text-sm text-gray-800">Running...</p>;
    case BulkExecutionStatus.Errors:
      return (
        <Tooltip
          content={cx(
            execution.errorCount?.toLocaleString(),
            plural('error', (execution.errorCount || 0) > 1)
          )}
        >
          <p className="flex w-max items-center space-x-1.5 text-sm text-gray-800">
            <span>Completed with errors</span>
            <Icon name="WarningFilled" className="h-4 w-4 text-amber-500" />
          </p>
        </Tooltip>
      );
    case BulkExecutionStatus.Completed:
      return (
        <p className="flex items-center space-x-1.5 text-sm text-gray-800">
          <span>Completed</span>
          <Icon name="CheckFilled" className="h-4 w-4 text-green-500" />
        </p>
      );
    case BulkExecutionStatus.Failed:
      return (
        <Tooltip content={execution.statusMessage || 'Bulk sync failed'}>
          <p className="flex w-max items-center space-x-1.5 text-sm text-gray-800">
            <span>Failed</span>
            <Icon name="DangerFilled" className="h-4 w-4 text-red-500" />
          </p>
        </Tooltip>
      );
    default:
      return <p className="text-sm text-gray-800">{capsFirst(execution.status)}</p>;
  }
}

function SyncRecordCount(props: { execution: BulkSyncExecutionFragment }) {
  if (
    !props.execution ||
    props.execution.recordCount == null ||
    props.execution.status === BulkExecutionStatus.Created ||
    props.execution.status === BulkExecutionStatus.Scheduled ||
    props.execution.status === BulkExecutionStatus.Exporting ||
    ((props.execution.status === BulkExecutionStatus.Running ||
      props.execution.status === BulkExecutionStatus.Processing) &&
      props.execution?.recordCount === 0)
  ) {
    return <p>{emptyCell}</p>;
  }
  return <span>{props.execution?.recordCount.toLocaleString()}</span>;
}

function CancelButton({ status }: { status?: BulkExecutionStatus }) {
  const bulkSync = useBulkSyncState();
  const dispatchBanner = useBannerDispatch();
  const [called, setCalled] = useState(false);
  const [cancelSync, { loading }] = useMutation(CancelBulkSyncDocument, {
    variables: { id: bulkSync.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } })
  });

  function cancel() {
    setCalled(true);
    void cancelSync();
  }

  useEffect(() => {
    if (status === BulkExecutionStatus.Canceled) {
      setCalled(false);
    }
    return () => setCalled(false);
  }, [status]);

  return (
    <div className="flex items-center space-x-2">
      <Button
        onClick={cancel}
        disabled={
          loading || called || status === BulkExecutionStatus.Canceling || isTerminal(status)
        }
      >
        Cancel sync
      </Button>
      {(loading || called || status === BulkExecutionStatus.Canceling) && <LoadingDots />}
    </div>
  );
}
