import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  FormBuilder,
  MyCombobox,
  MyInput,
  SideBySide
} from '../../../components';
import { CompletionValue, ConnectionTypeFragment } from '../../../generated/graphql';
import { getSchemaNormalized, isRequiredMsg, Selectable } from '../../../utils';
import { ConnectionFormValues, ConnectionWithoutType } from '../connection-config';
import { OauthButton } from '../connection-components';
import { omit } from 'lodash';

const FB_AUTH_METHODS = {
  OAUTH: { label: 'OAuth', value: 'oauth' },
  TOKEN: { label: 'Token', value: 'token' }
};

interface Props {
  connection?: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
  features?: string[];
  oauthLoading: boolean;
  saved: boolean;
  promiseOptions: (field: string, query?: string) => Promise<CompletionValue[]>;
  onSave: (force: boolean) => (data: ConnectionFormValues) => Promise<void>;
  setConnectionType: React.Dispatch<React.SetStateAction<ConnectionTypeFragment>>;
}

export function FacebookAudienceConnectionConfig(props: Props) {
  const {
    connection,
    connectionType,
    features,
    oauthLoading,
    saved,
    promiseOptions,
    onSave,
    setConnectionType
  } = props;

  const { control, setValue, register, getValues, formState, handleSubmit } =
    useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  const [authMethod, setAuthMethod] = React.useState<Selectable>(() => {
    const auth = getValues('configuration.auth_method');
    return auth === 'token' ? FB_AUTH_METHODS.TOKEN : FB_AUTH_METHODS.OAUTH;
  });

  const fields = getSchemaNormalized(connectionType.configurationSchema);
  const token = useWatch({ control, name: 'configuration.byo_app_token' });
  const graphVersion = useWatch({ control, name: 'configuration.graph_api_version' });

  const handleAuthMethodChange = (option: Selectable | null) => {
    if (option) {
      setValue('configuration.auth_method', option.value, {
        shouldValidate: true,
        shouldDirty: true
      });
      setAuthMethod(option);
      setConnectionType({ ...connectionType, useOAuth: option.value === 'oauth' });
    }
  };

  React.useEffect(() => {
    // If token auth and no connection yet, save and prevent redirect
    // so the user can edit the accounts
    if (
      getValues('configuration.byo_app_token') &&
      getValues('configuration.graph_api_version') &&
      !connection?.id
    ) {
      handleSubmit(onSave(false))();
    }
  }, [token, graphVersion]);

  const schema = connectionType.configurationSchema;
  // @ts-ignore
  const version = schema.definitions?.ConnectionConf?.properties?.graph_api_version?.default;

  return (
    <>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Authentication method">
        <EditPermission
          fallback={<DisabledSelect valueLabel={authMethod?.label} className="max-w-xs" />}
        >
          <MyCombobox
            className="max-w-xs"
            value={authMethod}
            options={Object.values(FB_AUTH_METHODS)}
            onChange={handleAuthMethodChange}
          />
        </EditPermission>
      </SideBySide>
      {authMethod && (
        <SideBySide hasSectionWrap styles="space-y-3" heading="Options">
          {authMethod?.value === FB_AUTH_METHODS.OAUTH.value && (
            <div className="max-w-xs">
              <OauthButton
                isConnected={saved || !!connection?.saved}
                oauthLoading={oauthLoading}
                connectionTypeName={connectionType.name}
                onSave={onSave}
              />
            </div>
          )}
          {authMethod?.value === FB_AUTH_METHODS.TOKEN.value && (
            <>
              <div className="max-w-xs">
                <EditPermission>
                  <MyInput
                    {...register('configuration.byo_app_token', {
                      required: isRequiredMsg(fields?.['byo_app_token']?.title)
                    })}
                    label={fields?.['byo_app_token']?.title}
                    description={fields?.['byo_app_token']?.description}
                    errors={errors}
                  />
                </EditPermission>
              </div>
              <div className="max-w-xs">
                <EditPermission>
                  <MyInput
                    {...register('configuration.graph_api_version', {
                      required: isRequiredMsg(fields?.['graph_api_version']?.title)
                    })}
                    defaultValue={version}
                    label={fields?.['graph_api_version']?.title}
                    description={fields?.['graph_api_version']?.description}
                    errors={errors}
                  />
                </EditPermission>
              </div>
              <div className="max-w-xs">
                <EditPermission>
                  <MyInput
                    {...register('configuration.account_id', {
                      required: isRequiredMsg(fields?.['account_id']?.title)
                    })}
                    label={fields?.['account_id']?.title}
                    description={fields?.['account_id']?.description}
                    errors={errors}
                  />
                </EditPermission>
              </div>{' '}
            </>
          )}
          {authMethod?.value === FB_AUTH_METHODS.OAUTH.value && connection?.saved && (
            <FormBuilder<ConnectionFormValues>
              features={features}
              schema={omit(props.connectionType.configurationSchema, [
                // Omit fields that are collected manually above
                'definitions.ConnectionConf.properties.auth_method',
                'definitions.ConnectionConf.properties.byo_app_token',
                'definitions.ConnectionConf.properties.graph_api_version',
                'definitions.ConnectionConf.properties.account_id'
              ])}
              prefix="configuration"
              promiseOptions={promiseOptions}
              styleOverrides={{
                'configuration.accounts': 'relative w-[32rem]'
              }}
            />
          )}
        </SideBySide>
      )}
    </>
  );
}
