import { TIME_FORMAT_24, defaultCron } from '~/utils';
import { type ScheduleProps } from './SchedulePicker';
import Cron from '../cron';
import { CronDisplay } from './CronDisplay';
import { useState } from 'react';

export function FrequencyBuilder({ schedule, onScheduleChange }: ScheduleProps) {
  const [cronBuilder, setCronBuilder] = useState<string>(defaultCron(schedule));
  function handleCronBuilderChange(cron: string) {
    const [minute, hour, dayOfMonth, month, dayOfWeek] = cron.trim().split(/[ ]+/);
    const newSchedule = {
      ...schedule,
      minute,
      hour,
      dayOfMonth,
      month,
      dayOfWeek
    };
    setCronBuilder(cron);
    onScheduleChange(newSchedule);
  }

  return (
    <div className="mt-4 flex flex-col space-y-2">
      <div className="flex items-center space-x-2">
        <Cron
          value={cronBuilder}
          setValue={handleCronBuilderChange}
          allowedPeriods={['day', 'hour', 'month', 'week', 'year']}
          leadingZero={true}
          clearButton={true}
          clockFormat={TIME_FORMAT_24 ? '24-hour-clock' : '12-hour-clock'}
        />
      </div>
      <div className="mt-2">
        <CronDisplay cron={cronBuilder} />
      </div>
    </div>
  );
}
