import { atom, useAtom } from 'jotai';
import { useLayoutEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { Action } from '~/generated/graphql';
import { NO_PERMISSION } from '~/utils';
import { Permission } from '~/components/permission';
import { Tooltip } from '~/components/tooltip';
import Divider from '~/components/v2/display/Divider';
import { Icon } from '~/components';

export type PageNavConfType = {
  label: string;
  to: string;
  path: string;
  slug: string;
  permission?: Action;
  permissionMessage?: string;
};
export type PageNavStateAtomType = {
  pageNavConf?: PageNavConfType[];
};
export const PageNavStateAtom = atom<PageNavStateAtomType>({
  pageNavConf: undefined
});

export const PageNav = () => {
  const [pageNavState, setPageNavState] = useAtom(PageNavStateAtom);
  const location = useLocation();

  useLayoutEffect(() => {
    // Todo: Cleanup if not needed
    setPageNavState(s => ({ ...s, pageNavConf: undefined }));
  }, [location, setPageNavState]);

  return !pageNavState.pageNavConf ? null : (
    <nav className="z-70 w-[16.5rem] min-w-[16.5rem] bg-white shadow-col-list xl:min-w-[21rem]">
      <div className="space-y-2 p-2">
        {pageNavState.pageNavConf?.map(link => {
          return (
            <Permission
              key={link.label}
              type={link.permission}
              fallback={
                <Tooltip
                  content={link.permissionMessage || NO_PERMISSION}
                  placement="right"
                  offset={[0, 12]}
                >
                  <div className="flex w-full cursor-not-allowed items-center justify-between rounded py-1.5 px-4 text-sm font-medium text-gray-400 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-200">
                    {link.label}
                  </div>
                </Tooltip>
              }
            >
              <NavLink
                to={link.to}
                exact={true}
                className="flex w-full items-center justify-between rounded py-1.5 px-4 text-sm font-medium text-gray-800 hover:bg-indigo-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-400"
                activeClassName="bg-indigo-100"
              >
                {link.label}
                {location.pathname.endsWith(link.slug) && (
                  <Icon name="FastForward" className="h-5 w-5 text-indigo-500" />
                )}
              </NavLink>
            </Permission>
          );
        })}
      </div>
      <Divider />
    </nav>
  );
};

export default PageNav;
