import { Tooltip } from '.';

export function MiddleEllipsis({ text, boundary = 32 }: { text?: string; boundary?: number }) {
  if (!text) {
    return null;
  }
  if (text.length <= boundary) {
    return <span>{text}</span>;
  }

  const half = Math.floor(boundary / 2);

  const truncatedText = `${text.slice(0, half - 1)}...${text.slice(-half + 1)}`;

  return (
    <Tooltip placement="top" offset={[0, 6]} content={text}>
      <span className="whitespace-nowrap">{truncatedText}</span>
    </Tooltip>
  );
}
