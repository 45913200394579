import { SVGProps } from 'react';

export function WarningIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25697 4.09898C9.02197 2.73898 10.979 2.73898 11.743 4.09898L17.323 14.019C18.073 15.353 17.11 16.999 15.581 16.999H4.41997C2.88997 16.999 1.92697 15.353 2.67697 14.019L8.25697 4.09898ZM11 14C11 14.2652 10.8946 14.5196 10.7071 14.7071C10.5195 14.8946 10.2652 15 9.99997 15C9.73475 15 9.4804 14.8946 9.29286 14.7071C9.10533 14.5196 8.99997 14.2652 8.99997 14C8.99997 13.7348 9.10533 13.4804 9.29286 13.2929C9.4804 13.1053 9.73475 13 9.99997 13C10.2652 13 10.5195 13.1053 10.7071 13.2929C10.8946 13.4804 11 13.7348 11 14V14ZM9.99997 5.99998C9.73475 5.99998 9.4804 6.10534 9.29286 6.29287C9.10533 6.48041 8.99997 6.73476 8.99997 6.99998V9.99998C8.99997 10.2652 9.10533 10.5195 9.29286 10.7071C9.4804 10.8946 9.73475 11 9.99997 11C10.2652 11 10.5195 10.8946 10.7071 10.7071C10.8946 10.5195 11 10.2652 11 9.99998V6.99998C11 6.73476 10.8946 6.48041 10.7071 6.29287C10.5195 6.10534 10.2652 5.99998 9.99997 5.99998Z"
        fill="currentColor"
      />
    </svg>
  );
}
