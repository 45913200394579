const apiUrl = () => `${import.meta.env.VITE_API_URL || ''}`;

async function fetcher(url: string, token: string, init: RequestInit = {}) {
  const response = await fetch(`${apiUrl()}/api/${url}?token=${token}`, {
    ...init,
    headers: {
      'Content-Type': 'application/json',
      'X-Polytomic-Version': '2023-04-25',
      origin: window.location.origin,
      ...(init.headers || {})
    },
    mode: 'cors'
  });
  if (response.ok) {
    return response.json();
  }
  const error = await response.json();
  throw new Error(error.message);
}

interface ConnectionArgs {
  configuration: Record<string, unknown> | undefined;
  name: string;
  redirect_url?: string;
  type: string;
}

interface ConnectionResponse {
  data: {
    id: string;
    auth_url: string;
    authUrl: string;
    configuration?: Record<string, unknown>;
    name: string;
    status: string;
    status_error?: string;
    type?: string;
  };
}

export async function createConnection(
  token: string,
  args: ConnectionArgs
): Promise<ConnectionResponse> {
  const { data } = await fetcher(`connections`, token, {
    method: 'POST',
    body: JSON.stringify(args)
  });
  return { data: { ...data, authUrl: data.auth_url } };
}

export async function updateConnection(
  token: string,
  id: string,
  args: ConnectionArgs
): Promise<ConnectionResponse> {
  const { data } = await fetcher(`connections/${id}`, token, {
    method: 'PATCH',
    body: JSON.stringify({ ...args, reconnect: true })
  });
  return { data: { ...data, authUrl: data.auth_url } };
}

interface ConnectionTypesResponse {
  data: {
    id: string;
    ConfigurationSchema: Record<string, unknown>;
    envConfig?: Record<string, unknown>;
    name: string;
    schema: Record<string, unknown>;
    useOAuth?: boolean;
    use_oauth?: boolean;
  }[];
}

export async function fetchConnectionTypes(token: string): Promise<ConnectionTypesResponse> {
  const { data } = await fetcher(`connection_types`, token);
  return {
    data: data.map(type => ({
      ...type,
      schema: type.ConfigurationSchema,
      useOAuth: type.use_oauth
    }))
  };
}

interface MeResponse {
  data: {
    email: string;
    id: string;
    is_organization: boolean;
    is_partner: boolean;
    is_system: boolean;
    is_user: boolean;
    organization_id: string;
    organization_name: string;
    role: string;
  };
}

export async function fetchMe(token: string): Promise<MeResponse> {
  return fetcher(`me`, token);
}

export async function fetchConnection(token: string, id: string): Promise<ConnectionResponse> {
  return fetcher(`connections/${id}`, token);
}
