import { Icon } from '~/components';

export type PageInfoBoxProps = {
  title: string;
};

const PageInfoBox = ({ title }: PageInfoBoxProps) => {
  return (
    <div className="flex w-full items-center justify-center">
      <div className="max-w-[480px]">
        <span className="text-gray-400">
          <Icon name="InfoCircle" className="h-10 w-10" />
        </span>
        <p className="text-gray-800">{title}</p>
      </div>
    </div>
  );
};

export default PageInfoBox;
