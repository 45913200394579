import { minuteHourSelectable, minuteHourSelectables } from '~/utils';
import { type ScheduleProps } from './SchedulePicker';
import { MyCombobox } from '../form-components';

export function FrequencyHourly({ schedule, onScheduleChange }: ScheduleProps) {
  return (
    <section className="grid grid-cols-2 items-center">
      <label className="label col-span-full">At</label>
      <MyCombobox
        placeholder="Select minutes..."
        options={minuteHourSelectables(['0', '15', '30', '45'])}
        value={minuteHourSelectable(schedule.minute)}
        onChange={({ value }) => onScheduleChange({ ...schedule, minute: value })}
      />
      <p className="ml-2 text-sm text-gray-600">minutes past the hour (UTC)</p>
    </section>
  );
}
