import * as React from 'react';
import { Icon } from '../Icon';

interface BannerTopProps {
  children?: React.ReactNode;
}

export function BannerTop({ children }: BannerTopProps) {
  return (
    <div className="flex w-full items-center justify-center space-x-2 bg-amber-300">
      <Icon name="InfoFilled" className="my-4 h-5 w-5 text-amber-700" />
      <div className="py-4 font-medium text-amber-900">{children}</div>
    </div>
  );
}
