import { InUse } from '~/components';
import { SyncFragment } from '~/generated/graphql';

interface Props {
  sync: SyncFragment;
  error: string;
  usedBy: SyncFragment[];
}

export function SyncInUse(props: Props) {
  return (
    <div className="animate-fadeIn">
      <h3 className="mb-4 text-sm font-medium">
        Unable to delete sync:
        <span className="ml-1 font-normal lowercase text-red-500">{props.error}</span>
      </h3>
      <InUse
        type="sync"
        name={props.sync.name}
        logo={props.sync.targetConnection?.type.id}
        usedBy={props.usedBy}
      />
    </div>
  );
}
