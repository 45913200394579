import { JSONSchema4 } from 'json-schema';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useEffect } from 'react';
import { DisabledSelect, EditPermission, MyCombobox, Section, SideBySide } from '~/components';
import { SqlConfiguration } from '~/generated/graphql';
import { useFieldsetState, useModeSwitcher, useModelQueryRef } from '~/hooks';
import {
  FieldsetFormValues,
  ModeConfiguration,
  filterToIds,
  findName,
  getSchemaAsList,
  hasItems,
  objToSelectables
} from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { EnumPicker, FieldsTable, ModelQueryEditor, TrackingColumns } from '../components';

const modeConfiguration: ModeConfiguration<SqlConfiguration> = {
  modes: [
    { name: 'table', label: 'Table select' },
    { name: 'query', label: 'SQL query' },
    { name: 'view', label: 'View select' }
  ],
  saveObj: (mode, conf) => ({
    configuration: {
      table: mode === 'table' ? conf.table : '',
      query: mode === 'query' ? conf.query : '',
      view: mode === 'view' ? conf.view : '',
      trackingColumns: conf.trackingColumns != null ? [...conf.trackingColumns] : []
    }
  }),
  reset: { configuration: { table: '', query: '', view: '', trackingColumns: [] } }
};

const queryUpdateObj = (conf: SqlConfiguration | undefined) => ({
  configuration: {
    table: '',
    query: conf?.query || '',
    view: '',
    trackingColumns: conf?.trackingColumns != null ? filterToIds(conf.trackingColumns) : []
  }
});

export function SqlFieldsetConfig() {
  const { fieldset, loading, refreshing, applyUpdate } = useFieldsetState();
  const { control, getValues } = useFormContext<FieldsetFormValues>();

  const { modes, mode, handleMode } = useModeSwitcher<SqlConfiguration>(modeConfiguration);

  const table = useWatch({ control, name: 'configuration.table' });
  const view = useWatch({ control, name: 'configuration.view' });

  const list = React.useMemo(
    () => getSchemaAsList(fieldset?.configurationSchema, 'configuration'),
    [fieldset?.configurationSchema]
  );
  const defaultDoc = (fieldset?.configuration as SqlConfiguration)?.query;
  const queryRef = useModelQueryRef('query', defaultDoc);

  useEffect(() => {
    const tables = findName(list, 'configuration.table')?.enum || [];
    const views = findName(list, 'configuration.view')?.enum || [];
    if (!hasItems(tables) && hasItems(views) && mode === 'table') {
      handleMode({ label: 'view', value: 'view' });
    }
  }, []);

  function filterModeOptions(list: JSONSchema4[] | undefined, modes: Record<string, string>) {
    const tables = findName(list, 'configuration.table')?.enum || [];
    const views = findName(list, 'configuration.view')?.enum || [];
    if (hasItems(tables) && !hasItems(views)) {
      const { view, ...rest } = modes;
      return objToSelectables(rest, true);
    }
    if (!hasItems(tables) && hasItems(views)) {
      const { table, ...rest } = modes;
      return objToSelectables(rest, true);
    }
    return objToSelectables(modes, true);
  }

  const handleTableUpdate = React.useCallback(() => {
    applyUpdate(
      {
        table: getValues('configuration.table'),
        query: '',
        view: '',
        trackingColumns: filterToIds(getValues('configuration.trackingColumns'))
      },
      { refresh: true, resetFields: true }
    );
  }, [applyUpdate, getValues]);

  const handleViewUpdate = React.useCallback(() => {
    applyUpdate(
      {
        table: '',
        query: '',
        view: getValues('configuration.view'),
        trackingColumns: filterToIds(getValues('configuration.trackingColumns'))
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  const handleRefresh = React.useCallback(() => {
    applyUpdate(
      {
        ...getValues('configuration'),
        trackingColumns: filterToIds(getValues('configuration.trackingColumns'))
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          <EditPermission
            fallback={<DisabledSelect className="max-w-xs" valueLabel={modes[mode]} />}
          >
            <MyCombobox
              aria-label="Build model using"
              className="max-w-xs"
              value={{ label: modes[mode], value: mode }}
              options={filterModeOptions(list, modes)}
              onChange={handleMode}
              isDisabled={loading}
            />
          </EditPermission>
          {fieldset?.configuration && fieldset.configurationSchema && (
            <div className="mt-2 w-full max-w-xs animate-fadeIn">
              {mode === 'table' && (
                <EnumPicker
                  aria-label="Table"
                  item={findName(list, 'configuration.table')}
                  onChange={handleTableUpdate}
                  grouped={true}
                />
              )}
              {mode === 'view' && (
                <EnumPicker
                  aria-label="View"
                  item={findName(list, 'configuration.view')}
                  onChange={handleViewUpdate}
                  grouped={true}
                />
              )}
            </div>
          )}
        </SideBySide>
        {mode === 'query' && (
          <ModelQueryEditor
            key={fieldset.connection.id}
            heading="SQL query"
            path="configuration.query"
            updateObj={queryUpdateObj}
            defaultDoc={defaultDoc}
            queryRef={queryRef}
          />
        )}
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || (loading && !fieldset?.fields)}
          disabled={loading}
          refresh={table || view ? handleRefresh : undefined}
          hasWriteinFields={fieldset?.properties.writeinFields}
        />
      </Section>
      <AdditionalConfig />
      <TrackingColumns />
    </>
  );
}
