import cx from 'clsx';
import * as React from 'react';

import { Button } from '../form-components';
import { Icon } from '../Icon';
import { BannerStatic, BannerStaticProps } from './banner-static';

export interface BannerProps extends BannerStaticProps {
  show?: boolean;
  wrapper?: string;
  disableDismiss?: boolean;
  handleDismiss?: () => void;
  duration?: number;
  autoFade?: boolean;
}

export function Banner({
  show,
  duration = 5000,
  autoFade = false,
  theme = 'warning',
  wrapper,
  disableDismiss = false,
  handleDismiss,
  children
}: BannerProps) {
  const [fade, setFade] = React.useState(false);

  const handleClick = () => {
    handleDismiss && handleDismiss();
    setFade(false);
  };

  React.useEffect(() => {
    if (show && autoFade) {
      const timeout = setTimeout(() => {
        setFade(true);
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [show, autoFade, duration]);

  if (!show) {
    return null;
  }

  return (
    <div
      className={cx('sticky top-0 left-0 z-[60]', wrapper, fade && 'animate-fadeOut')}
      onAnimationEnd={handleClick}
    >
      <BannerStatic
        theme={theme}
        className="max-h-[7.25rem]"
        rightSlot={
          !disableDismiss && (
            <Button className="m-2" theme="ghost-ko" size="icon" onClick={handleClick}>
              <Icon name="CloseX" className="h-5 w-5" />
            </Button>
          )
        }
      >
        {children}
      </BannerStatic>
    </div>
  );
}
