import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  Checkbox,
  DisabledSelect,
  EditPermission,
  MyCombobox,
  Section,
  SideBySide
} from '~/components';
import { DynamoDbConfiguration } from '~/generated/graphql';
import { useFieldsetState, useModeSwitcher, useModelQueryRef } from '~/hooks';
import {
  FieldsetFormValues,
  ModeConfiguration,
  findName,
  getSchemaAsList,
  objToSelectables
} from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { EnumPicker, FieldsTable, ModelQueryEditor } from '../components';

const modeConfiguration: ModeConfiguration<DynamoDbConfiguration> = {
  modes: [
    { name: 'table', label: 'Table select' },
    { name: 'query', label: 'SQL query' }
  ],
  saveObj: (mode, conf) => ({
    configuration: {
      index: mode === 'table' ? conf.index : '',
      table: mode === 'table' ? conf.table : '',
      query: mode === 'query' ? conf.query : '',
      consistent: conf.consistent
    }
  }),
  reset: { configuration: { index: '', table: '', query: '', consistent: false } }
};

const queryUpdateObj = (conf: DynamoDbConfiguration | undefined) => ({
  configuration: {
    table: '',
    query: conf?.query || '',
    index: '',
    consistent: conf?.consistent || false
  }
});

export function DynamoDBFieldsetConfig() {
  const { fieldset, loading, refreshing, applyUpdate } = useFieldsetState();
  const { control, getValues, setValue, watch } = useFormContext<FieldsetFormValues>();

  const { modes, mode, handleMode } = useModeSwitcher<DynamoDbConfiguration>(modeConfiguration);

  const table = useWatch({ control, name: 'configuration.table' });

  const list = React.useMemo(
    () => getSchemaAsList(fieldset?.configurationSchema, 'configuration'),
    [fieldset?.configurationSchema]
  );

  const defaultDoc = (fieldset?.configuration as DynamoDbConfiguration)?.query;
  const queryRef = useModelQueryRef('query', defaultDoc);

  const handleTableUpdate = React.useCallback(() => {
    applyUpdate(
      {
        index: '',
        table: getValues('configuration.table'),
        query: '',
        consitent: false
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  const handleIndexUpdate = React.useCallback(() => {
    applyUpdate(
      {
        index: getValues('configuration.index'),
        table: getValues('configuration.table'),
        query: '',
        consitent: getValues('configuration.consistent')
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  const handleConsistentUpdate = React.useCallback(
    e => {
      setValue('configuration.consistent', e.target.checked);
      applyUpdate(
        {
          ...getValues('configuration'),
          consitent: getValues('configuration.consistent')
        },
        { refresh: true }
      );
    },
    [applyUpdate, getValues]
  );

  const handleRefresh = React.useCallback(() => {
    applyUpdate(
      {
        ...getValues('configuration'),
        consitent: getValues('configuration.consistent')
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          <EditPermission
            fallback={<DisabledSelect className="max-w-xs" valueLabel={modes[mode]} />}
          >
            <MyCombobox
              className="max-w-xs"
              value={{ label: modes[mode], value: mode }}
              options={objToSelectables(modes, true)}
              onChange={handleMode}
              isDisabled={loading}
            />
          </EditPermission>
          {fieldset?.configuration && fieldset.configurationSchema && (
            <>
              <div className="mt-2 w-full max-w-xs animate-fadeIn space-y-2">
                {mode === 'table' && (
                  <EnumPicker
                    item={findName(list, 'configuration.table')}
                    onChange={handleTableUpdate}
                  />
                )}
              </div>
              <div className="mt-2 w-full max-w-xs animate-fadeIn space-y-2">
                {mode === 'table' && (
                  <div className="mt-2 w-full max-w-xs animate-fadeIn space-y-2">
                    <EnumPicker
                      item={findName(list, 'configuration.index')}
                      onChange={handleIndexUpdate}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          <EditPermission>
            <div className="mt-3">
              <Checkbox
                label={'Strongly consistent reads'}
                checked={watch('configuration.consistent')}
                onChange={handleConsistentUpdate}
              />
            </div>
          </EditPermission>
        </SideBySide>

        {mode === 'query' && (
          <ModelQueryEditor
            key={fieldset.connection.id}
            heading="SQL query"
            path="configuration.query"
            updateObj={queryUpdateObj}
            defaultDoc={defaultDoc}
            queryRef={queryRef}
          />
        )}
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || (loading && !fieldset?.fields)}
          disabled={loading}
          refresh={table ? handleRefresh : undefined}
          hasWriteinFields={fieldset?.properties.writeinFields}
        />
      </Section>
      <AdditionalConfig />
    </>
  );
}
