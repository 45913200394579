import { useQuery } from '@apollo/client';
import qs from 'query-string';
import * as React from 'react';
import { LogOnMount } from '~/lib/Amplitude';
import { generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { Icon } from '~/components';

import { Button, ConnectionStub, Lister, Permission } from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import EmptyPage from '~/components/v2/templates/EmptyPage';
import { ConnectionsDocument, ConnectionsUpdatesDocument, ResourceType } from '~/generated/graphql';
import { useHasCreatePermission, useToggle } from '~/hooks';
import { hasItems, routes } from '~/utils';
import { AddConnection, RequestIntegration } from '~/pages/connections/connection-components';
import { ConnectionConfig } from '~/pages/connections/connection-config';
import { ConnectionRow } from '~/pages/connections/connection-row';

export function Connections() {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const history = useHistory();
  const isEditing = useRouteMatch({ path: routes.editConnection, exact: true });
  const isAdding = useRouteMatch({ path: routes.addConnection, exact: true });
  const canCreateConnection = useHasCreatePermission(ResourceType.Connection);
  const [showAddDialog, toggleAddDialog] = useToggle();
  const [showRequestIntegration, toggleRequestIntegration] = useToggle();

  React.useLayoutEffect(() => {
    if ('add' in qs.parse(search)) {
      history.replace(routes.connections);
      if (canCreateConnection) {
        toggleAddDialog();
      }
    }
  }, [canCreateConnection, history, search, toggleAddDialog]);

  const { data, loading, error } = useQuery(ConnectionsDocument, {
    errorPolicy: 'all'
  });
  const connections = data?.connections || [];

  useQuery(ConnectionsUpdatesDocument, {
    skip: connections.length === 0,
    fetchPolicy: 'network-only'
  });

  return (
    <>
      <LogOnMount eventType="connections.view" />
      {(error || hasItems(connections) || isAdding) && (
        <PageLayout
          sideNavInnerHeading="Connections"
          sideNavInnerContent={
            <div className="h-full space-y-2 overflow-y-auto p-2">
              {error && (
                <div className="m-4 text-red-500">
                  <Icon name="DangerFilled" className="mb-1 h-6 w-6" />
                  {error.message ? (
                    <Lister items={error} />
                  ) : (
                    <p>Error retrieving connections. Try refreshing the page.</p>
                  )}
                </div>
              )}
              {isAdding && <ConnectionStub />}
              {hasItems(connections) &&
                connections
                  .filter(connection => connection.type.id !== 'datalite')
                  .map(connection => (
                    <ConnectionRow
                      isEditing={id === connection.id}
                      key={connection.id}
                      pathname={generatePath(routes.editConnection, {
                        id: connection.id
                      })}
                      connection={connection}
                    />
                  ))}
            </div>
          }
        />
      )}
      {isAdding || isEditing ? (
        // without this key, react-hook-form won't get
        // the updated state when the url param changes
        <ConnectionConfig key={id} />
      ) : (
        <EmptyPage
          topNavHeading={loading || error || hasItems(connections) ? undefined : 'Connections'}
          loading={loading}
          message={
            <>
              Click <strong>Add connection</strong> in the top-right to add a new connection.
            </>
          }
          topNavActions={
            <Permission type={ResourceType.Connection}>
              <Button theme="outline" onClick={toggleAddDialog}>
                Add connection
              </Button>
            </Permission>
          }
        />
      )}
      {showAddDialog && (
        <AddConnection
          onDismiss={toggleAddDialog}
          onRequestIntegration={toggleRequestIntegration}
        />
      )}
      <p>{showRequestIntegration}</p>
      {showRequestIntegration && <RequestIntegration onDismiss={toggleRequestIntegration} />}
    </>
  );
}
export default Connections;
