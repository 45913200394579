import { Reference, useMutation, useQuery } from '@apollo/client';
import { Fragment, useEffect, useMemo } from 'react';
import { Button, Icon, TableWrap } from '~/components';
import ErrorText from '~/components/v2/feedback/ErrorText';
import { Dialog } from '~/components/v3';
import {
  BulkSync,
  Connection,
  DeleteTagDocument,
  Fieldset,
  PermissionTagFragment,
  Sync,
  TagUsedByFragment,
  UsedByTagDocument
} from '~/generated/graphql';
import { useBannerDispatch } from '~/hooks';

const wrapperStyles = 'mb-3';

const getIconNamesFromEntity = (
  entity: BulkSync | Sync | Fieldset | Connection | TagUsedByFragment
) => {
  const iconNames = {
    source: undefined,
    target: undefined
  };
  switch (entity.__typename) {
    case 'BulkSync':
      iconNames.source = entity.source?.connection?.type?.id;
      iconNames.target = entity.destination?.connection?.type?.id;
      break;
    case 'Sync':
      iconNames.source = entity.identity?.model?.fieldset.connection.type.id;
      iconNames.target = entity.targetConnection?.type?.id;
      break;
    case 'Fieldset':
      iconNames.source = entity.connection?.type?.id;
      break;
    case 'Connection':
      iconNames.source = entity.type?.id;
      break;
  }
  return iconNames;
};

export type DeleteTagDialogProps = {
  tag: PermissionTagFragment;
  toggleDialog: () => void;
};

const DeleteTagDialog = ({ tag, toggleDialog }: DeleteTagDialogProps) => {
  const dispatchBanner = useBannerDispatch();

  const [deleteTag, { loading: isDeleting }] = useMutation(DeleteTagDocument, {
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
      toggleDialog();
    },
    update: (cache, { data }) => {
      if (!data || !data.deleteTag) {
        return;
      }
      cache.modify({
        fields: {
          allTags: (existing: Reference[], { readField }) => {
            const filtered = existing.filter(ref => readField('id', ref) !== tag.id);
            return filtered;
          }
        }
      });
      cache.evict({ id: cache.identify({ __typename: 'PermissionTag', id: tag.id }) });
      cache.gc();
      toggleDialog();
    }
  });

  const {
    data,
    loading: isUsedByLoading,
    error: usedByError
  } = useQuery(UsedByTagDocument, {
    fetchPolicy: 'network-only',
    variables: { id: tag.id }
  });

  const handleDelete = () => {
    dispatchBanner({ type: 'hide' });
    void deleteTag({ variables: { id: tag.id } });
  };
  const groups = useMemo(() => {
    const _groups: Array<{ id: string; display: string; items: Array<TagUsedByFragment> }> = [
      { id: 'Fieldset', display: 'Models', items: [] },
      { id: 'Sync', display: 'Syncs', items: [] },
      { id: 'BulkSync', display: 'Bulk syncs', items: [] },
      { id: 'Connection', display: 'Connections', items: [] },
      { id: 'Other', display: 'Other', items: [] }
    ];

    data?.usedByTag.forEach(item => {
      const group = _groups.find(g => g.id === item.__typename);
      if (group) {
        group.items.push(item);
      } else {
        _groups.find(g => g.id === 'Other')?.items.push(item);
      }
    });
    return _groups;
  }, [data?.usedByTag]);

  const isInUse = data?.usedByTag.length > 0 || !!usedByError;

  useEffect(() => {
    // Note: @kyle This is a bit hacky but, it will work for the time being.
    // Bypass the dialog if this policy is not in use.
    if (isUsedByLoading) {
      return;
    }
    if (isInUse) {
      return;
    }
    handleDelete();
  }, [isInUse, isUsedByLoading]);

  if (isUsedByLoading || !isInUse) {
    return null;
  }

  return (
    <Dialog
      show
      size="lg"
      onDismiss={toggleDialog}
      heading="Delete policy?"
      actions={
        <>
          <Button onClick={toggleDialog}>Cancel</Button>
          <Button
            theme="danger"
            onClick={handleDelete}
            disabled={isDeleting || !!usedByError}
            iconEnd={'Trashcan'}
          >
            Delete policy
          </Button>
        </>
      }
    >
      <div className="space-y-5">
        <div className="space-y-2">
          <p className="text-sm font-medium text-gray-800">{tag.name}</p>
          <p className="text-sm text-gray-800">
            This policy will be removed from the following resources:
          </p>
        </div>
        <TableWrap className="max-h-56 overflow-auto">
          {usedByError ? (
            <div className="flex w-full justify-center p-5">
              <ErrorText>Unable to retrieve resources</ErrorText>
            </div>
          ) : (
            <table className="min-w-full">
              <tbody>
                {groups.map(
                  group =>
                    group.items.length > 0 && (
                      <Fragment key={group.id}>
                        <tr>
                          <td className="thead-text bg-gray-50 p-2 text-gray-500">
                            {group.display}
                          </td>
                        </tr>
                        {group.items.map(item => {
                          const icons = getIconNamesFromEntity(item);
                          return (
                            <tr key={item.id}>
                              <td className="flex items-center p-2">
                                <Icon match={icons.source} />
                                {icons.target && (
                                  <>
                                    <Icon name="Syncs" className="mx-1.5 h-5 w-5 text-gray-500" />
                                    <Icon match={icons.target} />
                                  </>
                                )}
                                <p className="ml-2">{item.name}</p>
                              </td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    )
                )}
              </tbody>
            </table>
          )}
        </TableWrap>
      </div>
    </Dialog>
  );
};
export default DeleteTagDialog;
