import clsx from 'clsx';
import * as React from 'react';

import { Icon } from '~/components';
import { AccessControlInForm, AccessControlWrap } from '../../../components';
import { BulkSyncPeekFragment, ResourceType } from '../../../generated/graphql';
import { BulkSyncForm, routes } from '../../../utils';
import { ScheduleToDisplay, SummarySectionWrapper } from '../../syncs/sync-config';
import { Pill } from '~/components/v3';
import { generatePath } from 'react-router-dom';

const bulkSummarySections = ['connections', 'count', 'schedule', 'access-control'] as const;

const labelStyles = 'text-sm font-semibold text-gray-500';

type BulkSyncSummaryProps = {
  bulkSync: BulkSyncForm | BulkSyncPeekFragment;
  nameControls?: React.ReactNode;
  className?: string;
  hideSections?: Array<(typeof bulkSummarySections)[number]>;
  totalSelectedSchemaCount?: number;
};

export const BulkSyncSummary = ({
  bulkSync,
  nameControls,
  className,
  hideSections,
  totalSelectedSchemaCount
}: BulkSyncSummaryProps) => {
  function renderSection(label: string) {
    switch (label) {
      case 'connections':
        return (
          <>
            <p className={labelStyles}>Connections</p>
            <div className="flex items-center">
              <Pill
                icon={bulkSync.source?.connection?.type?.id}
                text={bulkSync.source?.connection?.name}
                href={
                  bulkSync.source?.connection?.id
                    ? generatePath(routes.editConnection, {
                        id: bulkSync.source?.connection?.id
                      })
                    : null
                }
              />
              <Icon name="ArrowNarrowRight" className="mx-1 h-5 w-5 text-gray-500" />
              <Pill
                icon={bulkSync.destination?.connection?.type?.id}
                text={bulkSync.destination?.connection?.name}
                href={
                  bulkSync.destination?.connection?.id
                    ? generatePath(routes.editConnection, {
                        id: bulkSync.destination?.connection?.id
                      })
                    : null
                }
              />
            </div>
          </>
        );
      case 'count':
        return (
          <>
            <p className={clsx(labelStyles)}>
              <span className="capitalize">
                {bulkSync?.source?.schemaLabel?.singular || 'object'}
              </span>{' '}
              count
            </p>
            <p className="text-sm text-gray-800">{totalSelectedSchemaCount}</p>
          </>
        );
      case 'schedule':
        return (
          <>
            <p className={labelStyles}>Schedule</p>
            <div className="text-sm text-gray-800">
              <ScheduleToDisplay schedule={bulkSync?.schedule} runAfterSyncs={[]} />
            </div>
          </>
        );
      case 'access-control':
        return (
          <>
            <p className={labelStyles}>Access control</p>
            <AccessControlWrap hasSideBySide={false}>
              <AccessControlInForm resourceType={ResourceType.Bulksync} />
            </AccessControlWrap>
          </>
        );
      default:
        return null;
    }
  }

  return (
    <>
      {nameControls && (
        <SummarySectionWrapper className={className}>{nameControls}</SummarySectionWrapper>
      )}
      {bulkSummarySections
        .filter(item => !hideSections?.includes(item))
        .map(label => (
          <SummarySectionWrapper key={label} className={className}>
            {renderSection(label)}
          </SummarySectionWrapper>
        ))}
    </>
  );
};
