import cx from 'clsx';
import { JSONSchema4 } from 'json-schema';
import * as React from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';

import { CompletionValue } from '../../../generated/graphql';
import { EditPermission, ParamButton } from '../..';
import { NestedSchemaForm } from '.';
import { SchemaFormElementProps } from './elements';

export interface ArrayElementProps {
  field: JSONSchema4;
  errors?: FieldErrors;
}

export function ArrayElement(props: SchemaFormElementProps & ArrayElementProps) {
  const [value, setLocalValue] = React.useState<{ id: string }[]>(
    (props.defaultValue as { id: string }[]) || []
  );

  const { reset, setValue, unregister } = useFormContext();

  return (
    <>
      <ul className="space-y-4">
        {value.length === 0 && (
          <EditPermission>
            <ParamButton
              action="add"
              onClick={(): void => {
                const newValue = [...value, { id: v4() }];
                setValue(props.name, newValue);
                setLocalValue(newValue);
              }}
            />
          </EditPermission>
        )}
        {value.map((item: { id: string }, index: number) => {
          return (
            <li key={index} className={cx('grid items-center gap-2', props.className)}>
              {/* for empty validation register make sure pass empty object  */}
              <NestedSchemaForm
                showFieldTitle={false}
                schema={props.field.items}
                getOptions={async (field: string, query?: string): Promise<CompletionValue[]> =>
                  props.getOptions ? props.getOptions(`${props.id}.${field}`, query) : []
                }
                onChange={props.onChange}
                errors={
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  (props.errors &&
                    props.field &&
                    props.field.name &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    props.errors[props.field.name]) ||
                  {}
                }
                values={item || {}}
                index={index}
                prefix={props.name}
              />
              <EditPermission>
                <ParamButton
                  action="delete"
                  onClick={() => {
                    // first unregister the nested keys so they aren't re-submitted
                    // we use the final index because everything will get renumbered in the next step
                    const keys = Object.keys(value[index]).map(
                      k => `${props.name}[${value.length - 1}].${k}`
                    );

                    unregister(keys);
                    reset(keys);
                    // now update the value proper w/new indices for remaining elements
                    const newValue = value.filter((_, idx) => idx !== index);
                    // const newValue = [...value];
                    // newValue.splice(index, 1);

                    setValue(props.name, newValue);
                    setLocalValue(newValue);
                    props.onChange();
                  }}
                />
              </EditPermission>
              <EditPermission>
                <ParamButton
                  className={cx(index === value.length - 1 ? 'visible' : 'invisible')}
                  action="add"
                  onClick={() => {
                    const newValue = [...value, { id: v4() }];
                    setValue(props.name, newValue);
                    setLocalValue(newValue);
                    props.onChange();
                  }}
                />
              </EditPermission>
            </li>
          );
        })}
      </ul>
    </>
  );
}
