import { useQuery } from '@apollo/client';
import { ColumnDef, Row, RowSelectionState } from '@tanstack/react-table';
import { keyBy, mapValues } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { Icon, Tooltip } from '~/components';
import { Button } from '~/components/form-components/button';
import { Search } from '~/components/form-components/input';
import { SideBySide } from '~/components/side-by-side';
import { Truncator } from '~/components/truncator';
import Card from '~/components/v2/display/Card';
import Checkbox from '~/components/v2/inputs/Checkbox';
import Select from '~/components/v2/inputs/Select';
import { DataTable } from '~/components/v3';
import {
  BulkSchemaOptionFragment,
  ConnectionCacheBulkSourceDocument,
  ConnectionCacheBulkSourceQuery,
  ConnectionCacheBulkSourceQueryVariables,
  ConnectionTypeFragment,
  DataliteSchemasMetadataDocument,
  Term
} from '~/generated/graphql';
import { useLazyContinuationQuery } from '~/hooks/use-lazy-continuation-query';
import {
  Selectable,
  emptyCell,
  getShortLocalTime,
  searchByNameAndId,
  sortByNameAndId
} from '~/utils';
import { ConnectionFormValues, ConnectionWithoutType } from '../connection-config';
export type ConnectionObjectCachingOptionProps = {
  connection?: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
};

type Schema = { __typename?: 'Schema'; id: string; name: string };
interface FilterOption {
  label: string;
  value: string;
  fn?: (row: Row<Schema>, search?: string) => boolean;
}

// Todo - add comments
const ConnectionObjectCachingOption = ({
  connection,
  connectionType
}: ConnectionObjectCachingOptionProps) => {
  const [enabled, setEnabled] = useState(
    !!(connection?.configuration?.cached_schemas as string[])?.length
  );
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    mapValues(
      keyBy(connection?.configuration?.cached_schemas as string[], key => key),
      () => true
    )
  );
  const visibilityFilterOptions = useMemo<FilterOption[]>(
    () => [
      { label: 'Show all', value: 'all', fn: () => true },
      { label: 'Show selected', value: 'selected', fn: row => !!rowSelection[row.id] }
    ],
    [rowSelection]
  );
  const [filter, setFilter] = useState('');
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [continuationID] = useState(uuid());
  const [visibilityFilter, setVisibilityFilter] = useState<FilterOption>(
    visibilityFilterOptions[0]
  );

  const [refetch, { loading, data }] = useLazyContinuationQuery<
    ConnectionCacheBulkSourceQuery,
    ConnectionCacheBulkSourceQueryVariables
  >(ConnectionCacheBulkSourceDocument, {
    fetchPolicy: 'network-only',
    variables: {
      connectionId: String(connection?.id),
      refresh: false,
      continuation: continuationID
    }
  });

  useEffect(() => {
    refetch();
  }, []);

  const {
    refetch: refetchMetadata,
    data: metadata,
    loading: metadataLoading
  } = useQuery(DataliteSchemasMetadataDocument, {
    fetchPolicy: 'network-only'
  });

  const { setValue } = useFormContext<ConnectionFormValues>();

  const selectedItems = useMemo(
    () => Object.keys(rowSelection).filter(key => !!rowSelection[key]),
    [rowSelection]
  );

  useEffect(() => {
    if (
      data?.bulkSourceForConnection.namespaces &&
      (selectedItems.length > 0 || connection?.configuration?.cached_schemas !== undefined)
    ) {
      setValue('configuration.cached_schemas', enabled ? selectedItems : [], {
        shouldDirty: true,
        shouldValidate: true
      });
    }
  }, [data?.bulkSourceForConnection.namespaces, rowSelection, setValue, enabled]);

  const handleEnabledSelect = current => {
    setEnabled(!current);
  };
  const handleRefresh = async () => {
    setRefreshLoading(true);
    await refetch();
    await refetchMetadata();
    setRefreshLoading(false);
  };
  const handleSearchInput = useCallback(
    (value: string | undefined) => setFilter(value?.trim() || ''),
    [setFilter]
  );
  const handleResetSearchInput = useCallback(() => setFilter(''), [setFilter]);

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault(); // avoid submitting if nested in <form>
      e.stopPropagation();
      return;
    }
  }

  const schemas = useMemo(
    () => data?.bulkSourceForConnection?.namespaces?.[0]?.schemas?.sort(sortByNameAndId) || [],
    [data]
  );

  const schemaLabel = useMemo(() => {
    return (
      data?.bulkSourceForConnection?.schemaLabel ||
      ({ singular: 'object', plural: 'objects' } as Term)
    );
  }, [data]);
  const cols: ColumnDef<Omit<BulkSchemaOptionFragment, 'slowMode' | 'fields'>>[] = [
    {
      accessorKey: 'name',
      header: () => (
        <div className="inline-flex items-center gap-2">
          <p>{schemaLabel.singular} label</p>
        </div>
      ),
      cell: ({ row }) => (
        <div className="flex items-center gap-2 overflow-clip">
          <Truncator content={row.original.name}>
            <p className="hide-native-tooltip cursor-default truncate text-gray-800">
              {row.original.name}
            </p>
          </Truncator>
        </div>
      )
    },
    {
      accessorKey: 'id',
      header: `${schemaLabel.singular} name`,
      cell: ({ row }) => (
        <Truncator content={row.original.id}>
          <p className="hide-native-tooltip cursor-default truncate text-gray-800">
            {row.original.id}
          </p>
        </Truncator>
      )
    },
    {
      accessorKey: 'count',
      header: 'Count',
      cell: ({ row }) => {
        const metadataItem = metadata?.dataliteMetadata?.find(
          m => m.schemaID === row.original.id && m.connectionID === connection.id
        );
        return metadataItem?.initialized ? <p>{metadataItem.recordCount}</p> : emptyCell;
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => {
        const metadataItem = metadata?.dataliteMetadata?.find(
          m => m.schemaID === row.original.id && m.connectionID === connection.id
        );
        if (metadataItem) {
          if (metadataItem?.initialized) {
            return (
              <Tooltip
                placement="left"
                content={`Last updated: ${getShortLocalTime(metadataItem?.lastUpdated)}`}
              >
                <div>
                  <Icon name="CheckFilled" className="h-4 w-4 text-green-500" />
                </div>
              </Tooltip>
            );
          } else {
            return <span className="pointer-events-none text-gray-400">Processing...</span>;
          }
        } else {
          return emptyCell;
        }
      }
    }
  ];

  return (
    <div>
      {/* Checkbox/Toggle for enable salesfore object caching */}
      <SideBySide
        heading="Polytomic Lightning"
        hasSectionWrap
        maxWidth="max-w-xs"
        styles="space-y-3"
      >
        <div className="align-center flex h-full items-center">
          <Checkbox
            label={`Expose ${connectionType.name} ${schemaLabel.plural} to Lightning`}
            checked={enabled}
            onChange={() => handleEnabledSelect(enabled)}
          />
        </div>
      </SideBySide>

      {/* Object caching table */}
      {enabled && (
        <div className="m-6 mx-auto mt-0 max-w-5xl">
          <Card headerTitle={`Select ${schemaLabel.plural}`} className="overflow-hidden">
            <div className="mt-2 mb-4 flex items-center justify-between">
              <Search
                debounce
                onChange={handleSearchInput}
                onReset={handleResetSearchInput}
                onKeyDown={onInputKeyDown}
                stopEscHotKey={false}
                placeholder={`Search ${schemaLabel.plural}...`}
                className="h-6"
              />
              <Button
                size="mini"
                iconEnd="Refresh"
                onClick={handleRefresh}
                loading={refreshLoading}
                children="Refresh"
              />
            </div>
            <DataTable
              data={schemas}
              columns={cols}
              loading={loading || metadataLoading || refreshLoading}
              classNames={{ wrapper: 'h-[26.5rem]' }}
              emptyMessage={`No ${schemaLabel.plural} found`}
              // Selection
              rowSelection={rowSelection}
              onRowSelectionChange={setRowSelection}
              getRowId={row => row.id}
              // Filtering
              globalFilter={row =>
                visibilityFilter.fn(row) && searchByNameAndId(row.original, filter)
              }
              onGlobalFilterChange={() => {}}
            />
            <div className="flex flex-row items-center gap-4 py-4">
              <Select
                variant="filled"
                options={visibilityFilterOptions}
                onChange={(v: Selectable) => setVisibilityFilter(v)}
                value={visibilityFilter}
                inputWidth="w-36"
              />
              {data?.bulkSourceForConnection?.namespaces &&
                `${selectedItems.length} of ${
                  data?.bulkSourceForConnection?.namespaces[0]?.schemas?.length || 0
                } ${schemaLabel.plural} selected`}
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default ConnectionObjectCachingOption;
