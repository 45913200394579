import * as React from 'react';

import { BannerInline } from '../../components';
import { SyncWarning } from '../../generated/graphql';

interface Props {
  warningTypes: SyncWarning[];
}

export const ExecutionWarningTypes = ({ warningTypes }: Props) => {
  if (warningTypes.length === 1) {
    return (
      <BannerInline theme="warning" className="mb-4">
        <WarningItem link={warningTypes[0].Link} message={warningTypes[0].Message} />
      </BannerInline>
    );
  }
  return (
    <BannerInline theme="warning" className="mb-4">
      <p className="mb-1 font-medium">{warningTypes.length} issues:</p>
      <ul className="list-outside list-disc space-y-1">
        {warningTypes.map(warning => (
          <li key={warning.id as string} className="ml-5">
            <WarningItem link={warning.Link} message={warning.Message} />
          </li>
        ))}
      </ul>
    </BannerInline>
  );
};

function WarningItem({ link, message }: { link: string; message: string }) {
  return (
    <span className="space-x-1">
      <span>{message}</span>
      <a className="link" href={link} target="_blank" rel="noopener noreferrer">
        Read more ↗
      </a>
    </span>
  );
}
