import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';

import { InlineMessage, MySwitch, Permission, SideBySide } from '~/components';
import {
  Action,
  LoggingConfigurationDocument,
  SetLoggingConfigurationDocument,
  StorageType
} from '~/generated/graphql';
import { AclProvider, useBannerDispatch } from '~/hooks';

const wrapperStyles = 'px-3 pt-3';

export function LoggingEnabled() {
  const dispatchBanner = useBannerDispatch();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [acl, setAcl] = React.useState<Action[]>([]);

  const [showInlineMessage, setShowInlineMessage] = React.useState(false);

  const { loading } = useQuery(LoggingConfigurationDocument, {
    onCompleted: data => {
      if (!data) {
        return;
      }
      if (data.getLoggingConfiguration) {
        setAcl(data.getLoggingConfiguration.acl);
        setIsChecked(data.getLoggingConfiguration.enabled);
      }
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } }),
    fetchPolicy: 'no-cache'
  });

  const [updateLoggingConfiguration] = useMutation(SetLoggingConfigurationDocument, {
    onCompleted: data => {
      if (!data || !data.setLoggingConfiguration) {
        return;
      }
      setShowInlineMessage(true);
      setIsChecked(data.setLoggingConfiguration.enabled);
    },
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
      setIsChecked(prev => !prev);
    }
  });

  function handleToggle(checked: boolean) {
    setIsChecked(checked);
    void updateLoggingConfiguration({
      variables: {
        conf: {
          enabled: checked,
          storageType: StorageType.PolytomicS3,
          conf: {}
        }
      }
    });
  }

  return (
    <SideBySide hasSectionWrap heading="Record logging">
      <AclProvider value={acl}>
        <div className="flex items-center space-x-2">
          <Permission type={Action.Edit}>
            <MySwitch
              loading={loading}
              label="Enable sync history record logging"
              labelPosition="right"
              checked={isChecked}
              onChange={handleToggle}
            />
          </Permission>
          <InlineMessage
            show={showInlineMessage}
            autoDismiss={() => setShowInlineMessage(false)}
            text="Saved"
          />
        </div>
      </AclProvider>
    </SideBySide>
  );
}
