import { atom, useAtom } from 'jotai';
import { ReactNode, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export type SideNavInnerProps = null;

export type SideNavInnerStateAtomType = {
  sideNavInnerContent?: ReactNode;
  sideNavInnerHeading?: ReactNode | undefined;
};
export const SideNavInnerStateAtom = atom<SideNavInnerStateAtomType>({
  sideNavInnerContent: null,
  sideNavInnerHeading: null
});

export const SideNavInner = () => {
  const location = useLocation();
  const [sideNavInnerState, setSideNavInnerState] = useAtom(SideNavInnerStateAtom);

  useLayoutEffect(() => {
    setSideNavInnerState(s => ({ ...s, sideNavInnerContent: null }));
  }, [location.pathname, setSideNavInnerState]);

  return !sideNavInnerState.sideNavInnerContent ? null : (
    <nav className={'z-80 flex h-full flex-col overflow-y-clip bg-white shadow-col-list'}>
      <div className="flex max-h-[4rem] min-h-[4rem] w-full flex-row items-center justify-between bg-indigo-600 px-6 py-4">
        <h2 className="text-xl text-white">{sideNavInnerState.sideNavInnerHeading}</h2>
      </div>
      <div className="h-[calc(100vh-4rem)] w-[16.5rem] min-w-[16.5rem] self-stretch overflow-clip xl:min-w-[21rem]">
        {sideNavInnerState.sideNavInnerContent}
      </div>
    </nav>
  );
};

export default SideNavInner;
