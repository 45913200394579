import { friendlyCronDescription } from '~/utils';

export function CronDisplay({ cron }: { cron: string }) {
  const description = friendlyCronDescription(cron);
  return description ? (
    <p className="rounded bg-blue-50 p-4 text-blue-500">{description}.</p>
  ) : (
    <p className="rounded bg-red-50 p-4 text-red-500">Invalid cron string</p>
  );
}
