import { Reference, useMutation } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  AccessControlSolo,
  AccessControlWrap,
  EditPermission,
  Icon,
  InUse,
  LacksPermissionBanner,
  LastModified,
  SideBySide
} from '~/components';
import ConfigTriggerDialog from '~/components/config-trigger-dialog';
import {
  DeleteBulkSyncDocument,
  ResourceType,
  SyncDeletedSuccessResponse,
  SyncErrorResponse
} from '~/generated/graphql';
import { useBannerDispatch } from '~/hooks';
import { NO_EDIT_PERMISSION, routes } from '~/utils';
import { BulkSyncSummary, useBulkSyncState } from '../components';
import { BulkStatusDisplay } from './bulk-status-display';

const BulkSyncStatus = () => {
  // hooks
  const history = useHistory();
  const bulkSync = useBulkSyncState();
  const dispatchBanner = useBannerDispatch();
  const [deleteError, setDeleteError] = useState<SyncErrorResponse>();

  // queries
  const [deleteSync, { loading: deleteSyncLoading }] = useMutation(DeleteBulkSyncDocument, {
    fetchPolicy: 'no-cache',
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    update: (cache, { data }) => {
      if (!data || !data.deleteBulkSync) {
        return;
      }
      const id = (data.deleteBulkSync as SyncDeletedSuccessResponse).id;
      if ('error' in (data.deleteBulkSync as SyncErrorResponse)) {
        setDeleteError(data.deleteBulkSync as SyncErrorResponse);
        return;
      }

      cache.modify({
        fields: {
          bulkSyncs: (existing: Reference[] = [], helpers) => {
            return existing.filter(ref => helpers.readField('id', ref) !== id);
          }
        }
      });
      cache.evict({ id: cache.identify({ __typename: 'BulkSync', id }) });
      cache.gc();
      history.push(routes.bulkSyncsRoot);
    }
  });
  // F(x)
  const handleDelete = () => void deleteSync({ variables: { id: bulkSync.id } });

  /**
   * Total number of schemas selected for sync
   * if namespace is selected, count all schemas under it
   * else count 1
   */
  const totalSelectedSchemaCount = useMemo(() => {
    // return bulkSync.schemas.map((schema) => schema.type === BulkSchemaTableOptType.Namespace ? schema?.children?.length : 1).reduce((a, b) => a + b, 0)
    return bulkSync.namespaces.map(schema => schema?.schemas?.length).reduce((a, b) => a + b, 0);
  }, [bulkSync]);

  return (
    <div className="relative animate-fadeIn">
      <EditPermission
        fallback={<LacksPermissionBanner message={NO_EDIT_PERMISSION} wrapper="px-3 pt-3" />}
      />
      <div className="divide-y divide-gray-300 pb-48">
        <BulkStatusDisplay key={bulkSync.id} />
        <SideBySide hasSectionWrap heading="Configuration summary" maxWidth="max-w-full">
          <BulkSyncSummary
            bulkSync={bulkSync}
            className="-mr-6"
            hideSections={['access-control']}
            totalSelectedSchemaCount={totalSelectedSchemaCount}
          />
        </SideBySide>
        <LastModified updatedAt={bulkSync.updatedAt} updatedBy={bulkSync.updatedBy} />
        <AccessControlWrap>
          <AccessControlSolo
            tags={bulkSync?.tags || []}
            resourceId={bulkSync?.id}
            resourceType={ResourceType.Bulksync}
          />
        </AccessControlWrap>
        <ConfigTriggerDialog
          isDelete={true}
          cta="Delete bulk sync"
          loading={deleteSyncLoading}
          handleAction={handleDelete}
          handleDismiss={() => setDeleteError(undefined)}
          hideAction={!!deleteError}
          preventToggle={['action']}
          description="Deleting the bulk sync will result in your source data no longer being pushed to the configured destination."
          dismissText={deleteError ? 'Close' : 'Cancel'}
        >
          {deleteError && (
            <div>
              <h3 className="mb-4 text-sm font-medium">
                Unable to delete bulk sync:
                <span className="ml-1 font-normal lowercase text-red-500">{deleteError.error}</span>
              </h3>
              <InUse
                type="sync"
                name={bulkSync.name}
                logo={bulkSync.destination.connection.type.id}
                usedBy={deleteError.usedBy}
              />
            </div>
          )}
          {!deleteError && (
            <>
              <p className="mb-4 text-sm font-medium text-gray-800">{bulkSync.name}</p>
              <span className="mb-4 flex items-center space-x-2.5">
                <span className="flex items-center space-x-1.5">
                  <Icon match={bulkSync.source?.connection?.type?.id} className="h-5 w-5" />
                  <p className="text-sm font-medium text-gray-800">
                    {bulkSync.source?.connection?.type?.name}
                  </p>
                </span>
                <Icon name="Syncs" className="h-5 w-5 text-gray-600" />
                <span className="flex items-center space-x-1.5">
                  <Icon match={bulkSync.destination?.connection?.type?.id} className="h-5 w-5" />
                  <p className="text-sm font-medium text-gray-800">
                    {bulkSync.destination?.connection?.name}
                  </p>
                </span>
              </span>
              <p className="text-sm text-gray-500 [max-width:65ch]">
                Deleting the bulk sync will result in your source data no longer being pushed to the
                configured destination.
              </p>
            </>
          )}
        </ConfigTriggerDialog>
      </div>
    </div>
  );
};

export default BulkSyncStatus;
