import { JSONSchema4 } from 'json-schema';

import { Action, ConnectionFragment, FileUpload, Operation } from '../generated/graphql';
import { ConnectionFormValues } from '../pages/connections/connection-config';
import { LocalConnection } from './custom-types.util';
import { getSchemaAsList } from './forms.util';

export function filterConnections(
  data: { connections: ConnectionFragment[] } | undefined,
  operation: Operation,
  action?: Action
): LocalConnection[] {
  if (!data) {
    return [];
  }
  const filtered = data.connections.filter(connection =>
    connection.type.operations.includes(operation)
  );
  if (action) {
    return filtered.map(connection => ({
      ...connection,
      disabled: !connection.acl.includes(action)
    }));
  }

  return filtered;
}

export function transformFormData(
  form: ConnectionFormValues,
  configSchema: JSONSchema4 | undefined | null
) {
  const fileKeys = (getSchemaAsList(configSchema) || []).reduce((acc: string[], cv) => {
    if (cv.widget === 'file') {
      acc.push(cv.name as string);
    }
    return acc;
  }, []);
  let configuration = { ...(form.configuration || {}) };
  let files: FileUpload[] = [];
  // figure out which config props to pull out and dump into files
  if (fileKeys.length > 0) {
    const { newConfig, newFiles } = Object.entries(configuration).reduce(
      (acc: { newConfig: Record<string, unknown>; newFiles: FileUpload[] }, [key, value]) => {
        const upload = value?.[0];
        if (fileKeys.includes(key) && upload && typeof upload !== 'string') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          acc.newFiles.push({ key: key, upload });
        } else {
          acc.newConfig[key] = value;
        }
        return acc;
      },
      { newConfig: {}, newFiles: [] }
    );
    configuration = newConfig;
    files = newFiles;
  }

  return {
    commonVars: { name: form.name, configuration, files },
    tags: form?.tags?.map(tag => tag.id) || []
  };
}
