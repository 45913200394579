import { LazyQueryExecFunction } from '@apollo/client';
import { ExpandedState, RowSelectionState, functionalUpdate } from '@tanstack/react-table';
import { debounce, merge } from 'lodash';
import { Resizable } from 're-resizable';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Button, Icon, Search } from '~/components';
import { ColumnDef, DataTable } from '~/components/v3';
import { SelectionList } from '~/components/v3/SelectionList';
import { Sheet, SheetContent } from '~/components/v3/Sheet';
import {
  BulkSourceSchemaQuery,
  Exact,
  FieldFilters,
  SchemaFilters,
  Term
} from '~/generated/graphql';
import { fieldTypeIconName, searchByNameAndId } from '~/utils';
import {
  EBulkEntityType,
  IBulkField,
  IBulkNamespace,
  IBulkSchema,
  getEntitiesFromPath
} from '../components/BulkNamespaceUtil';

interface BulkFieldPickerDrawerProps {
  namespaces: IBulkNamespace[];
  open: boolean;
  connectionId: string;
  refetchSchema: LazyQueryExecFunction<
    BulkSourceSchemaQuery,
    Exact<{
      connectionId: string;
      refresh?: boolean;
      continuation: string;
      namespaceId?: string;
      schemaId?: string;
      schemaFilters?: SchemaFilters;
      fieldFilters?: FieldFilters;
      includeFields: boolean;
    }>
  >;
  handleLoadFields: (row: IBulkSchema) => void;
  onDismiss: (field?: IBulkField) => void;
  bulkSyncSchemaLabel: Term;
  selectedPath: string;
}

export function BulkFieldPickerDrawer({
  open,
  connectionId,
  namespaces,
  refetchSchema,
  handleLoadFields,
  onDismiss,
  bulkSyncSchemaLabel,
  selectedPath
}: BulkFieldPickerDrawerProps) {
  const [search, setSearch] = useState<string>();
  const [width, setWidth] = useState(224);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const isNamespaceRoot = useMemo(
    () => namespaces?.length === 1 && !namespaces[0].id,
    [namespaces]
  );

  const [selectedSchema, setSelectedSchema] = useState<IBulkSchema>();

  const handleSearchChange = (value: string) => {
    if (value.length >= 3) {
      refetchSchema({
        variables: {
          connectionId: connectionId,
          continuation: uuid(),
          includeFields: true,
          fieldFilters: { search: value },
          schemaFilters: { fieldSearch: value }
        }
      });
    }

    setSearch(value);
  };

  const debouncedSearchChange = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, [handleSearchChange]);

  const handleRowSelectionChange = (selection: RowSelectionState) => {
    setRowSelection(selection);
    const { schema } = getEntitiesFromPath(namespaces, Object.keys(selection)[0]);
    handleLoadFields(schema);
    setSelectedSchema(schema);
  };

  useEffect(() => {
    if (selectedSchema) {
      const { schema } = getEntitiesFromPath(namespaces, selectedSchema.path);
      if (schema) {
        setSelectedSchema(schema);
      }
    }

    if (namespaces.length === 1) {
      if (namespaces[0].schemas.length === 1) {
        const schema = namespaces[0].schemas[0];
        handleLoadFields(schema);
        setSelectedSchema(schema);
        return;
      }
      setExpanded(merge(expanded, { [namespaces[0]?.path]: true }));
    }
  }, [namespaces]);

  useEffect(() => {
    if (selectedPath) {
      const { namespace, schema } = getEntitiesFromPath(namespaces, selectedPath);
      if (namespace?.id) {
        setExpanded(merge(expanded, { [namespace?.path]: true }));
      }
      handleRowSelectionChange({ ...rowSelection, [schema?.path]: true });
    }
  }, [selectedPath]);

  const columns: ColumnDef<IBulkField>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: 'Field Name'
    },
    {
      id: 'type',
      accessorKey: 'type',
      header: 'Type',
      cell: ({ row }) => {
        if (row.original.bulkEntityType === 'FIELD') {
          const field = row.original as IBulkField;
          return (
            <span className="flex flex-row content-center items-center space-x-1">
              <Icon name={fieldTypeIconName(field.type)} className="text-gray-500" />
              <p className="hide-native-tooltip cursor-default truncate text-left">{field.type}</p>
            </span>
          );
        }

        return null;
      }
    },
    {
      id: 'id',
      accessorKey: 'id',
      header: 'Source Name'
    }
  ];

  const reset = () => {
    setExpanded({});
    setSearch('');
    setRowSelection({});
    setSelectedSchema(undefined);
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onDismiss();
      reset();
    }
  };

  const handleFieldSelection = (field: IBulkField) => {
    onDismiss(field);
    reset();
  };

  return (
    <Sheet open={open} onOpenChange={handleOpenChange} modal={false}>
      <SheetContent
        container={document.getElementsByTagName('main')[0]}
        storageKey="bulkSyncFieldPickerDrawer"
        header={
          <>
            <div className="flex flex-1 flex-wrap items-center">
              <h2 className="text-base font-medium">Select filter field</h2>
            </div>
            <div className="flex flex-1 justify-center">
              <Search
                className="font-normal"
                wrapperStyles="h-8 w-80"
                placeholder={`Search...`}
                defaultValue={search}
                onChange={debouncedSearchChange}
                onReset={() => setSearch('')}
              />
            </div>
            <div className="flex flex-1 items-center justify-end space-x-2">
              <Button onClick={() => handleOpenChange(false)} theme="primary">
                Cancel
              </Button>
            </div>
          </>
        }
      >
        <div className="flex h-full w-full items-start gap-4 self-stretch overflow-y-auto overflow-x-clip">
          <div className="flex h-full self-stretch overflow-y-clip">
            <Resizable
              handleClasses={{
                top: 'pointer-events-none',
                bottom: 'pointer-events-none',
                left: 'pointer-events-none',
                topRight: 'pointer-events-none',
                bottomRight: 'pointer-events-none',
                bottomLeft: 'pointer-events-none',
                topLeft: 'pointer-events-none',
                right: `
                      h-full flex flex-col items-center justify-center
                      bg-transparent hover:bg-gray-200 active:bg-gray-300 transition
                      absolute !-right-2.5 text-gray-400 hover:text-gray-500 active:text-gray-600
                      rounded !w-[5px]
                    `
              }}
              handleComponent={{ right: <Icon name="ResizeHandleV" size="lg" /> }}
              minHeight="100%"
              size={{ width, height: '100%' }}
              onResizeStop={(e, direction, ref, d) => {
                setWidth(w => w + d.width);
              }}
            >
              <SelectionList<IBulkNamespace | IBulkSchema | IBulkField>
                data={isNamespaceRoot ? namespaces[0].schemas : namespaces}
                getName={row => row.name}
                getRowId={row => row.path}
                rowSelection={rowSelection}
                onRowSelectionChange={fn =>
                  handleRowSelectionChange(functionalUpdate(fn, rowSelection))
                }
                enableRowSelection={row => row.original.bulkEntityType === EBulkEntityType.SCHEMA}
                expanded={expanded}
                onExpandedChange={setExpanded}
                getRowCanExpand={row => row.original.bulkEntityType === EBulkEntityType.NAMESPACE}
                getSubRows={row => (row as IBulkNamespace).schemas ?? (row as IBulkSchema).fields}
                globalFilter={row => !search || searchByNameAndId(row.original, search)}
                onGlobalFilterChange={setSearch}
                maxLeafRowFilterDepth={2}
              />
            </Resizable>
          </div>
          <DataTable
            data={
              selectedSchema?.fields && !selectedSchema.selectionState ? selectedSchema.fields : []
            }
            columns={columns}
            loading={!!selectedSchema?.selectionState}
            showLoadingWhenRowsExist={true}
            getRowId={row => row.id}
            // Filtering
            globalFilter={search}
            onGlobalFilterChange={setSearch}
            classNames={{ wrapper: 'w-full', row: 'cursor-pointer' }}
            emptyMessage={
              selectedSchema
                ? 'No results.'
                : `Select a${
                    ['a', 'e', 'i', 'o', 'u'].some(letter =>
                      bulkSyncSchemaLabel?.singular?.startsWith(letter)
                    )
                      ? 'n'
                      : ''
                  } ${bulkSyncSchemaLabel?.singular} on the left.`
            }
            onRowClick={handleFieldSelection}
            isRowActive={row => row.path === selectedPath}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
}
