import { HighlightStyle, syntaxHighlighting } from '@codemirror/language';
import { Extension } from '@codemirror/state';
import { EditorView } from '@codemirror/view';
import { tags as t } from '@lezer/highlight';

const cursor = '#000',
  selection = '#bfdbfe',
  indigo200 = '#c7d2fe',
  indigo500 = '#6366f1',
  green500 = '#10b981',
  blue50 = '#eff6ff',
  blue100 = '#dbeafe',
  blue400 = '#60a5fa',
  gray50 = '#fafafa',
  gray100 = '#f4f4f5',
  gray200 = '#e4e4e7',
  gray300 = '#d4d4d8',
  gray400 = '#a1a1aa',
  gray600 = '#52525b',
  gray700 = '#3f3f46',
  gray800 = '#27272a';

interface EditorThemeOverrides {
  height?: string | null;
  maxHeight?: string;
  resizable?: boolean;
  readOnly?: boolean;
}

export const theme = ({ height, resizable, maxHeight, readOnly }: EditorThemeOverrides) =>
  EditorView.theme({
    '&': {
      color: gray800,
      backgroundColor: 'transparent',
      borderBottomRightRadius: '3px',
      borderBottomLeftRadius: '3px'
    },
    '&.cm-editor': {
      height: height === null ? null : height || '12.5rem',
      maxHeight: maxHeight || null,
      overflow: height === null ? null : 'auto',
      resize: resizable ? 'vertical' : 'none'
    },
    '.cm-scroller': {
      fontSize: '12px',
      fontFamily:
        'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
      minHeight: '100%'
    },
    '&.cm-editor.cm-focused': {
      outline: 'none'
    },
    '.cm-content': {
      caretColor: cursor,
      padding: readOnly ? '0.3125rem 0' : null
    },
    '.cm-cursor, .cm-dropCursor': { borderLeftColor: cursor },
    '&.cm-focused .cm-selectionBackground, .cm-selectionBackground, .cm-content ::selection': {
      backgroundColor: selection
    },
    '.cm-panels': { zIndex: 1, backgroundColor: gray100, color: gray800 },
    '.cm-panels.cm-panels-top': { top: '-24px !important' },
    '.cm-searchMatch': {
      backgroundColor: blue50,
      outline: `1px solid ${blue400}`
    },
    '.cm-searchMatch.cm-searchMatch-selected': {
      backgroundColor: indigo200,
      outline: `1px solid ${indigo500}`
    },
    '.cm-line': {
      padding: readOnly ? '0 0.75rem;' : null
    },
    '.cm-activeLine': { backgroundColor: gray100 },
    '.cm-selectionMatch': { backgroundColor: '#aafe661a' },
    '&.cm-focused .cm-matchingBracket, &.cm-focused .cm-nonmatchingBracket': {
      backgroundColor: 'rgba(0,0,0,0.0625)',
      outline: '1px solid rgba(0,0,0,0.3)'
    },
    '.cm-gutters': {
      backgroundColor: gray200,
      color: gray600,
      border: 'none',
      borderBottomLeftRadius: '3px'
    },
    '.cm-activeLineGutter': {
      backgroundColor: gray300
    },
    '.cm-foldPlaceholder': {
      backgroundColor: 'transparent',
      border: 'none',
      color: '#ddd'
    },
    '.cm-tooltip': {
      border: 'none',
      backgroundColor: '#fff',
      boxShadow: '0 1px 6px 0 rgba(0,0,0,0.15), 0 1px 1px 0 rgba(0,0,0,0.25)'
    },
    '.cm-tooltip .cm-tooltip-arrow:before': {
      borderTopColor: 'transparent',
      borderBottomColor: 'transparent'
    },
    '.cm-tooltip .cm-tooltip-arrow:after': {
      borderTopColor: gray200,
      borderBottomColor: gray200
    },
    '.cm-tooltip-autocomplete': {
      '& > ul > li[aria-selected]': {
        backgroundColor: blue100,
        color: gray800
      }
    },
    '.cm-completionIcon': {
      paddingRight: '18px'
    },
    '.cm-completionLabel': {
      fontSize: '12px',
      fontFamily:
        'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace'
    },
    '.cm-completionMatchedText': {
      textDecoration: 'none',
      fontWeight: 600
    },
    '.cm-textfield': {
      fontSize: '12px',
      height: '24px',
      lineHeight: 1,
      padding: '0 0 0 6px',
      borderRadius: '4px',
      '&:focus': {
        outline: 'none',
        borderColor: indigo500,
        boxShadow: `0 0 0 1px ${indigo500}`
      },
      '&:focus-visible': {
        outline: 'none',
        borderColor: indigo500,
        boxShadow: `0 0 0 1px ${indigo500}`
      }
    },
    '.cm-button': {
      height: '24px',
      color: gray700,
      background: gray50,
      fontSize: '12px',
      padding: '0 6px',
      border: `1px solid ${gray400}`,
      borderRadius: '4px',
      textTransform: 'capitalize',
      '&:focus-visible': {
        outline: 'none',
        borderColor: indigo500,
        boxShadow: `0 0 0 1px ${indigo500}`
      }
    },
    '.cm-panel.cm-search [name=close]': {
      top: '4px',
      right: '4px',
      padding: '4px 9px',
      borderRadius: '4px',
      '&:hover': {
        background: 'rgba(0,0,0,0.05)'
      },
      '&:focus-visible': {
        outline: 'none',
        borderColor: indigo500,
        boxShadow: `0 0 0 2px ${indigo500}`
      }
    },
    '.cm-panel.cm-search label': {
      textTransform: 'capitalize'
    },
    ".cm-panel.cm-search label input[type='checkbox']": {
      color: green500,
      cursor: 'pointer',
      marginTop: '-0.5px',
      marginRight: '4px',
      borderRadius: '4px',
      '&:focus': {
        outline: 'none',
        boxShadow: 'none'
      },
      '&:focus-visible': {
        outline: 'none',
        borderColor: indigo500,
        boxShadow: `0 0 0 1px ${indigo500}`
      }
    }
  });

export const defaultHighlight = HighlightStyle.define([
  { tag: t.meta, color: '#7a757a' },
  { tag: t.link, textDecoration: 'underline' },
  { tag: t.heading, textDecoration: 'underline', fontWeight: 'bold' },
  { tag: t.emphasis, fontStyle: 'italic' },
  { tag: t.strong, fontWeight: 'bold' },
  { tag: t.strikethrough, textDecoration: 'line-through' },
  { tag: t.keyword, color: '#c800a4' },
  // {tag: t.keyword, color: "#708"},
  { tag: [t.atom, t.bool, t.url, t.contentSeparator, t.labelName], color: '#219' },
  { tag: [t.literal, t.inserted], color: '#164' },
  { tag: [t.string, t.deleted], color: '#a11' },
  { tag: [t.regexp, t.escape, t.special(t.string)], color: '#e40' },
  { tag: t.definition(t.variableName), color: '#00f' },
  { tag: t.local(t.variableName), color: '#30a' },
  { tag: [t.typeName, t.namespace], color: '#085' },
  { tag: t.className, color: '#167' },
  { tag: [t.special(t.variableName), t.macroName], color: '#256' },
  { tag: t.definition(t.propertyName), color: '#00c' },
  { tag: t.comment, color: '#940' },
  { tag: t.invalid, color: '#f00' }
]);

export function editorTheme({
  height,
  maxHeight,
  resizable = true,
  readOnly = false
}: EditorThemeOverrides): Extension {
  return [
    theme({ height, resizable, maxHeight, readOnly }),
    syntaxHighlighting(defaultHighlight, { fallback: true })
  ];
}
