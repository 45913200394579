import { FormBuilder } from '~/components';
import { OAuthMessage } from './OAuthMessage';
import { FormBuilderProps } from './types';

export function Default(props: FormBuilderProps) {
  return (
    <>
      {props.useOAuth && <OAuthMessage {...props} />}
      <FormBuilder {...props} />
    </>
  );
}
