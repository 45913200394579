import clsx from 'clsx';
import { DetailedHTMLProps, forwardRef, HTMLAttributes, ReactNode } from 'react';

export type TableWrapperProps = {
  children: ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const TableWrapper = forwardRef<HTMLDivElement, TableWrapperProps>(
  ({ children, className, ...rest }, ref) => {
    const baseClassName = `overflow-x-auto [-webkit-transform:translateZ(0)]`;
    return (
      <div {...rest} className={clsx(baseClassName, className)} ref={ref}>
        {children}
      </div>
    );
  }
);

export default TableWrapper;
