import { Action, ResourceType } from '../generated/graphql';
import { isActionPermission, isResourceTypePermission } from '../utils';
import { useHasAclPermission } from './acl-context';
import { useHasCreatePermission } from './use-has-create-permission';

export function useLacksPermission(type?: ResourceType | Action) {
  const canCreate = useHasCreatePermission(isResourceTypePermission(type) ? type : undefined);
  const hasAction = useHasAclPermission(isActionPermission(type) ? type : undefined);
  return !canCreate || !hasAction;
}
