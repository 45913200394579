import clsx from 'clsx';
import qs from 'query-string';
import { Fragment, memo, useCallback, useEffect, useRef } from 'react';
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom';
import { FrequencyOptions, Icon } from '~/components';
import { Tooltip, Truncator } from '../../components';
import { ExecutionStatus, Frequency, SyncPeekFragment } from '../../generated/graphql';
import { addLineBreaks, capsFirst, getShortLocalTime, hasItems, routes } from '../../utils';
import { Status } from '~/components/v3';

const sharedStyles =
  'min-h-[3.75rem] flex px-4 border-t border-gray-300 group-hover:bg-indigo-50 h-full';
const iconStyles = 'w-5 h-5 text-gray-600';

export const SyncRow = memo<{
  sync: SyncPeekFragment;
}>(({ sync }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { search } = useLocation();
  const sourceConnectionIds = sync.SourceConnections?.map(c => c.ConnectionType) || [];
  const health = sync.Status === 'healthy' || true;

  const handleClick = useCallback(() => {
    history.replace({ search: `id=${sync.id}` });
  }, [history, sync.id]);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    const parsed = qs.parse(search);
    if (parsed.id === sync.id) {
      history.replace({ search: undefined });
      scrollRef.current.scrollIntoView({ block: 'center' });
    }
  }, []);

  return (
    <div className="group contents">
      <Link to={generatePath(routes.syncStatus, { id: sync.id })} onClick={handleClick}>
        <div ref={scrollRef} className={clsx('flex-col justify-center', sharedStyles)}>
          <span className="text-sm font-medium text-gray-800">{addLineBreaks(sync.name)}</span>
          <span className="mt-0.5 text-xs text-gray-500">
            Last run: {getShortLocalTime(sync.LastRunTimestamp) || 'Never'}
          </span>
        </div>
      </Link>
      {!health ? (
        sync.TargetConnectionID && (
          <RowSyncIconsWrapper
            hasOverrideFields={hasItems(sync.SourceConnections)}
            sourceConnectionIds={sourceConnectionIds}
          >
            <Link
              to={generatePath(routes.editConnection, { id: sync.TargetConnectionID })}
              onClick={handleClick}
              className="mx-2 break-words text-red-500 underline hover:no-underline"
            >
              Unhealthy connection
            </Link>
          </RowSyncIconsWrapper>
        )
      ) : (
        <Link to={generatePath(routes.syncStatus, { id: sync.id })} onClick={handleClick}>
          <RowSyncIconsWrapper
            hasOverrideFields={hasItems(sync.SourceConnections)}
            sourceConnectionIds={sourceConnectionIds}
          >
            <Icon name="Syncs" className={clsx('mx-2', iconStyles)} />
            {sync.TargetConnectionType && (
              <Icon match={sync.TargetConnectionType} className="mr-1.5" />
            )}
            <Truncator content={sync.TargetObjectName || 'Missing target object'}>
              <p
                className={clsx(
                  'truncate whitespace-nowrap text-sm',
                  sync.TargetObjectName ? 'text-gray-800' : 'text-red-500'
                )}
              >
                {sync.TargetObjectName || 'Missing target object'}
              </p>
            </Truncator>
          </RowSyncIconsWrapper>
        </Link>
      )}
      <Link to={generatePath(routes.syncStatus, { id: sync.id })} onClick={handleClick}>
        <div className={clsx('items-center text-sm text-gray-800', sharedStyles)}>
          {FrequencyOptions[sync.Frequency as Frequency]?.label}
          {sync.Frequency === Frequency.Runafter && (
            <Tooltip
              className="max-w-sm"
              interactive={true}
              trigger="mouseenter"
              offset={[0, 12]}
              content={
                <p>
                  Following completion of:{' '}
                  {sync.RunAfterSyncs.map(sync => (
                    <Link
                      key={sync.id}
                      to={generatePath(
                        sync.Type === 'model' ? routes.syncStatus : routes.bulkSyncStatus,
                        { id: sync.id }
                      )}
                      onClick={e => {
                        e.stopPropagation();
                        handleClick();
                      }}
                      className="link table"
                      target="_blank"
                    >
                      {sync.name}
                    </Link>
                  ))}
                </p>
              }
            >
              <div className="pl-2">
                <Icon name="InfoFilled" className="h-4 w-4 text-blue-500 hover:text-blue-400" />
              </div>
            </Tooltip>
          )}
        </div>
      </Link>
      <Link to={generatePath(routes.syncStatus, { id: sync.id })} onClick={handleClick}>
        <div className={clsx('items-center', sharedStyles)}>
          <SyncStatus sync={sync} />
        </div>
      </Link>
    </div>
  );
});

const SyncStatus = ({ sync }: { sync: SyncPeekFragment }) => {
  if (sync.Status === ExecutionStatus.Queued) {
    return (
      <Status
        icon="ClockFilled"
        classNames={{ icon: 'text-purple-500 ' }}
        tooltipContent="Only one sync can run against a destination object at a time"
      >
        Queued
      </Status>
    );
  }
  if (sync.Status === ExecutionStatus.Created) {
    return <Status icon="DotsH">Starting</Status>;
  }
  if (sync.Status === ExecutionStatus.Running || sync.Status === ExecutionStatus.Processing) {
    return (
      <Status icon="ArrowRightFilled" classNames={{ icon: 'text-blue-500 ' }}>
        Running
      </Status>
    );
  }
  if (!sync.Active) {
    return (
      <Status icon="Disabled" classNames={{ icon: 'text-gray-400', text: 'text-gray-400' }}>
        Disabled
      </Status>
    );
  }
  if (!sync.Status && sync.LastRunTimestamp === null) {
    return <p className="text-sm text-gray-400">Never run</p>;
  }
  if (sync.Status === ExecutionStatus.Completed) {
    if (sync.LastRunErrorCount > 0) {
      return (
        <Status
          icon="WarningFilled"
          classNames={{ icon: 'text-amber-500 ' }}
          tooltipContent={
            sync.LastRunErrorCount
              ? `${sync.LastRunErrorCount} error${sync.LastRunErrorCount > 1 ? 's' : ''}`
              : null
          }
          interactive={true}
        >
          <Link className="link" to={generatePath(routes.syncHistory, { id: sync.id })}>
            Completed
          </Link>
        </Status>
      );
    }
    return (
      <Status icon="CheckFilled" classNames={{ icon: 'text-green-500 ' }}>
        Completed
      </Status>
    );
  }
  if (sync.Status === ExecutionStatus.Failed) {
    return (
      <Status
        icon="DangerFilled"
        classNames={{ icon: 'text-red-500 ' }}
        tooltipContent={sync.LastRunError}
        interactive={true}
      >
        <Link className="link" to={generatePath(routes.syncHistory, { id: sync.id })}>
          Failed
        </Link>
      </Status>
    );
  }
  return <p className="text-sm">{capsFirst(sync.Status)}</p>;
};

const RowSyncIconsWrapper = ({
  sourceConnectionIds,
  hasOverrideFields,
  children
}: {
  hasOverrideFields: boolean;
  sourceConnectionIds: Array<string | undefined>;
  children: React.ReactNode;
}) => {
  return (
    <div className={clsx('items-center truncate', sharedStyles)}>
      {hasItems(sourceConnectionIds) ? (
        <>
          <Icon match={sourceConnectionIds[0]} />
          {sourceConnectionIds.slice(1).map(connId => (
            <Fragment key={connId}>
              <Icon name="PlusSmall" className={iconStyles} />
              <Icon match={connId} />
            </Fragment>
          ))}
        </>
      ) : (
        hasOverrideFields && <Icon name="TypeString" className="text-gray-600" />
      )}
      {children}
    </div>
  );
};
