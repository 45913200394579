import { useQuery } from '@apollo/client';
import * as React from 'react';

import {
  Action,
  AllTagsDocument,
  PermissionTagFragment,
  ResourceType
} from '../../generated/graphql';
import { AclProvider } from '../../hooks';
import { hasItems } from '../../utils';
import { Permission } from '../permission';
import { COLOR, SIZE } from '../v2/configs/SX';
import Chip from '../v2/display/Chip';
import { Icon } from '../Icon';
import { AccessControlTagInfo } from './access-control-tag-info';
import { AccessControlTagInput } from './access-control-tag-input';

interface Props {
  loading?: boolean;
  resourceType: ResourceType;
  resourceTags: PermissionTagFragment[] | undefined | null;
  addTag: (tag: PermissionTagFragment) => void;
  removeTag: (tag: PermissionTagFragment) => void;
}

export const AccessControl = ({
  resourceType,
  resourceTags,
  addTag,
  removeTag,
  loading
}: Props) => {
  const { data } = useQuery(AllTagsDocument);
  const userCreatedTags = React.useMemo(() => {
    if (!data || data.allTags.length === 0) {
      return [];
    }
    return [
      ...data.allTags.filter(t => !t.system || t.systemResourceTypes.includes(resourceType))
    ].sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  const filteredTags = userCreatedTags.filter(tag => !resourceTags?.find(t => t.id === tag.id));

  return (
    <>
      <div className="space-y-4">
        {hasItems(resourceTags) && (
          <div className="flex flex-wrap gap-2">
            {resourceTags?.map(tag => (
              <AclProvider key={tag.id} value={tag.acl}>
                <AccessControlTagInfo tag={tag}>
                  <Chip color={COLOR.DEFAULT} size={SIZE.SMALL}>
                    <span>{tag.name}</span>
                    {resourceTags.length > 1 && (
                      <Permission type={Action.ApplyPolicy} fallback={<></>}>
                        <button
                          className="ml-1.25 text-gray-400 focus:outline-none focus-visible:rounded focus-visible:text-indigo-500 focus-visible:ring-2 focus-visible:ring-indigo-300 group-hover:text-indigo-400"
                          onClick={() => removeTag(tag)}
                        >
                          <Icon name="CloseXFat" className="h-2.75 w-2.75 hover:text-indigo-600" />
                        </button>
                      </Permission>
                    )}
                  </Chip>
                </AccessControlTagInfo>
              </AclProvider>
            ))}
          </div>
        )}
        <div className="max-w-xs">
          <AccessControlTagInput tags={filteredTags} addTag={addTag} loading={loading} />
        </div>
      </div>
    </>
  );
};
