import { useFormContext, useWatch } from 'react-hook-form';
import { FormElements, Label, MyCombobox } from '~/components';
import { getSchemaAsList } from '~/utils';
import { OAuthMessage } from './OAuthMessage';
import type { FormBuilderProps } from './types';

const Options = {
  browser: {
    label: 'OAuth',
    value: 'browser',
    fields: []
  },
  clientcredentials: {
    label: 'Client credentials',
    value: 'clientcredentials',
    fields: ['client_id', 'client_secret']
  }
};
const DefaultOption = Options['browser'];
const AuthMethodKey = 'connect_mode';

export function Salesforce(props: FormBuilderProps) {
  const { control, setValue } = useFormContext();
  const auth = useWatch({ control, name: AuthMethodKey });
  const selected = Options[auth] || DefaultOption;

  const above = getSchemaAsList(props.schema).filter(item =>
    ['domain'].includes(item.name as string)
  );
  const middle = getSchemaAsList(props.schema).filter(item =>
    selected.fields.includes(item.name as string)
  );
  const below = getSchemaAsList(props.schema).filter(
    item => !['domain', 'client_id', 'client_secret', 'connect_mode'].includes(item.name as string)
  );

  const handleSelect = (selected: { label: string; value: string; fields: string[] }) => {
    setValue(AuthMethodKey, selected.value);
  };

  return (
    <>
      {above.map(item => (
        <FormElements key={item.name as string} item={item} />
      ))}
      <div>
        <Label>Authentication method</Label>
        <MyCombobox options={Object.values(Options)} value={selected} onChange={handleSelect} />
      </div>
      {selected.value === 'browser' && <OAuthMessage {...props} />}
      {middle.map(item => (
        <FormElements key={item.name as string} item={item} />
      ))}
      {below.map(item => (
        <FormElements key={item.name as string} item={item} />
      ))}
    </>
  );
}
