import qs from 'query-string';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { BulkExecutionsCondition } from '~/generated/graphql';
import { isSyncHistoryFilter, SyncHistoryFilter } from '~/utils';
import { transformFiltersForWhere } from '~/utils/bulk-sync-history.util';

function getFiltersFromSearch(search: string) {
  const query = qs.parse(search);
  const queryFilters: SyncHistoryFilter[] = [];
  if (!query.filters) {
    return [];
  }
  if (Array.isArray(query.filters)) {
    query.filters.forEach(item => {
      const filter: unknown = JSON.parse(item || '');
      if (isSyncHistoryFilter(filter)) {
        queryFilters.push(filter);
      }
    });
  } else {
    const filter: unknown = JSON.parse(query.filters || '');
    if (isSyncHistoryFilter(filter)) {
      queryFilters.push(filter);
    }
  }
  return queryFilters;
}

// Todo - this was copied over from another hook, this should be completely rewritten.
export function useBulkSyncFilters() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { search } = useLocation();

  const methods = useForm<{ filters: SyncHistoryFilter[] }>();
  const { reset, getValues, watch } = methods;

  const filters = watch('filters');
  const filtersRef = React.useRef<SyncHistoryFilter[]>([]);

  const queryFilters = getFiltersFromSearch(search);

  const [where, setWhere] = React.useState<BulkExecutionsCondition>(
    transformFiltersForWhere(queryFilters)
  );

  // effect to sync url search params
  React.useEffect(() => {
    reset({ filters: queryFilters });
    filtersRef.current = queryFilters;
    const parsedWhere = transformFiltersForWhere(queryFilters);
    setWhere(parsedWhere);
  }, [reset, search, id]);

  // effect to get history if filters are cleared
  // and the filters were a change from before
  React.useEffect(() => {
    if (
      filters &&
      filters.length === 0 &&
      filtersRef.current &&
      JSON.stringify(filtersRef.current) !== JSON.stringify(filters)
    ) {
      history.push({ search: undefined });
      setWhere({});
      filtersRef.current = [];
    }
  }, [filters, history, id]);

  const refreshHistory = React.useCallback(() => {
    const filters = getValues('filters');

    if (filters.length === 0) {
      history.push({ search: undefined });
      setWhere({});
      filtersRef.current = [];
      return;
    }

    const query = qs.parse(search);
    const validFilters = filters.filter(
      item =>
        item.column !== undefined &&
        item.column !== '' &&
        item.comparator !== undefined &&
        item.param !== undefined &&
        item.param !== ''
    );
    filtersRef.current = validFilters;
    query.filters = validFilters.map(item => JSON.stringify(item));

    history.push({ search: qs.stringify(query) });

    const updatedWhere = transformFiltersForWhere(filters);
    setWhere(updatedWhere);
  }, [getValues, history, search, id]);

  const clearFilters = React.useCallback(() => {
    history.push({ search: undefined });
  }, [history]);

  return {
    methods,
    filters,
    refreshHistory,
    clearFilters,
    where,
    isSameFilters:
      filtersRef.current && JSON.stringify(filtersRef.current) === JSON.stringify(filters)
  };
}
