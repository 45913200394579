import cx from 'clsx';
import * as React from 'react';
import { Icon } from '../Icon';

const themes = {
  warning: {
    bg: 'bg-amber-100',
    iconName: 'WarningFilled',
    icon: 'text-amber-500',
    text: 'text-amber-800'
  }
} as const;

interface BannerInlineProps {
  theme?: keyof typeof themes;
  children?: React.ReactNode;
  className?: string;
}

export function BannerInline({ theme = 'warning', className, children }: BannerInlineProps) {
  return (
    <div className={cx('flex w-full items-start rounded', themes[theme].bg, className)}>
      <div className="my-2 mx-2.5 flex flex-1 items-start space-x-2">
        <Icon name={themes[theme].iconName} className={cx('h-4.5 w-4.5', themes[theme].icon)} />
        <div className={cx('max-h-[7.25rem] flex-1 overflow-auto', themes[theme].text)}>
          {children}
        </div>
      </div>
    </div>
  );
}
