import cx from 'clsx';
import * as React from 'react';

import { Tooltip } from './tooltip';
import { Icon } from './Icon';

interface Props {
  href: string;
  message?: string;
  children?: React.ReactNode;
  className?: string;
}

export const LinkableIcon = ({
  message = 'Click to learn more',
  href,
  className,
  children = <Icon name="InfoFilled" className="h-4 w-4 text-blue-500 hover:text-blue-400" />
}: Props) => {
  return (
    <Tooltip content={message} offset={[0, 4]}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        className={cx(
          'focus:rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2',
          className
        )}
      >
        {children}
      </a>
    </Tooltip>
  );
};
