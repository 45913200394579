import { getCoreRowModel, Row, TableOptions, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import { HTMLAttributes, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useVirtual } from 'react-virtual';

import LoadingDots from '~/components/v2/feedback/LoadingDots';
import TableBody, { TableBodyProps } from '~/components/v2/table/TableBody';
import { TableBodyColProps } from '~/components/v2/table/TableBodyCol';
import TableBodyRow, { TableBodyRowProps } from '~/components/v2/table/TableBodyRow';
import TableHeader, { TableHeaderProps } from '~/components/v2/table/TableHeader';
import { TableHeaderColProps } from '~/components/v2/table/TableHeaderCol';
import TableHeaderRow, { TableHeaderRowProps } from '~/components/v2/table/TableHeaderRow';
import TableWrapper, { TableWrapperProps } from '~/components/v2/table/TableWrapper';

export type TableProps = HTMLAttributes<HTMLTableElement>;

export type TableBuilderProps<TData, TValue = unknown> = {
  // Configuration props
  enableStickyHeader?: boolean;
  alternatingRowColors?: boolean;
  scrollIndex?: number;
  noDataMessage?: ReactNode;
  loading?: boolean;
  disabledRowIds?: Array<string | undefined>;
  isRowDisabled?: (row: Row<TData>) => boolean;
  enableRowHover?: boolean;
  // Focus
  focusedRowIndex?: number;
  focusedRowRef?: React.Ref<HTMLTableRowElement>;
  // Socket Props
  TableProps?: Partial<TableProps>;
  TableWrapperProps?: Partial<TableWrapperProps>;
  TableBodyProps?: Partial<TableBodyProps>;
  TableBodyRowProps?: Partial<TableBodyRowProps<TData, TValue>>;
  TableBodyColProps?: Partial<TableBodyColProps<TData, TValue>>;
  TableHeaderProps?: Partial<TableHeaderProps>;
  TableHeaderColProps?: Partial<TableHeaderColProps<TData, TValue>>;
  TableHeaderRowProps?: Partial<TableHeaderRowProps<TData, TValue>>;
} & Partial<TableOptions<TData>>;

const Table = <TData,>({
  // Configuration props
  enableStickyHeader = true,
  noDataMessage = 'No objects found',
  alternatingRowColors = false,
  scrollIndex,
  loading = false,
  isRowDisabled,
  enableRowHover = false,
  // Focus
  focusedRowIndex,
  focusedRowRef,
  // Socket Props
  TableProps,
  TableWrapperProps,
  TableBodyProps,
  TableBodyRowProps,
  TableBodyColProps,
  TableHeaderProps,
  TableHeaderRowProps,
  TableHeaderColProps,
  ...rest
}: TableBuilderProps<TData>) => {
  // Refs
  const tableContainerRef = useRef<HTMLDivElement>(null);
  // React-Table Hooks
  const table = useReactTable({
    data: [],
    columns: [],
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    enableColumnResizing: false,
    ...rest
  });
  const { rows } = table.getRowModel();
  // React-Virtual Hooks
  const {
    virtualItems: vRows,
    scrollToIndex,
    totalSize: vRowsTotal
  } = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 20
  });
  // Variables
  const width = table.options.enableColumnResizing ? table.getCenterTotalSize() : undefined;
  const paddingTop = useMemo(() => (vRows.length > 0 ? vRows?.[0]?.start || 0 : 0), [vRows]);
  const paddingBottom = useMemo(
    () => (vRows.length > 0 ? vRowsTotal - (vRows?.[vRows.length - 1]?.end || 0) : 0),
    [vRowsTotal, vRows]
  );
  const visibleColumnCount = useMemo(() => table.getHeaderGroups()[0].headers.length, [table]);

  // Callbacks
  const isResizing = useCallback(
    () => !table.getAllColumns().every(c => !c.getIsResizing()),
    [table]
  );

  const TABLE_SX = clsx(
    'w-full table-fixed border-separate border-spacing-0',
    isResizing() && 'cursor-col-resize'
  );
  const TABLE_CELL_SX = 'py-8 px-4 text-center text-gray-600';

  useEffect(() => {
    if (focusedRowIndex !== undefined || focusedRowIndex !== null || focusedRowIndex > -1) {
      scrollToIndex(focusedRowIndex);
    }
  }, [scrollIndex, focusedRowIndex, scrollToIndex]);

  return (
    <TableWrapper {...TableWrapperProps} ref={tableContainerRef}>
      <table className={TABLE_SX} style={{ width }} {...TableProps}>
        <TableHeader enableStickyHeader={enableStickyHeader} {...TableHeaderProps}>
          {table.getHeaderGroups().map(headerGroup => (
            <TableHeaderRow
              key={headerGroup.id}
              headers={headerGroup.headers}
              TableHeaderColProps={TableHeaderColProps}
              {...TableHeaderRowProps}
              enableColumnResizing={table.options.enableColumnResizing}
            />
          ))}
        </TableHeader>
        <TableBody {...TableBodyProps}>
          {/* Virtualization top row */}
          {paddingTop > 0 && (
            <TableBodyRow
              {...TableBodyRowProps}
              TableBodyColProps={TableBodyColProps}
              style={{ height: `${paddingTop}px` }}
            />
          )}
          {/* Loading Indicator */}
          {loading && (
            <TableBodyRow>
              <td className={TABLE_CELL_SX} colSpan={visibleColumnCount}>
                <LoadingDots />
              </td>
            </TableBodyRow>
          )}
          {/* No-Data Message */}
          {!loading && rows.length === 0 && (
            <TableBodyRow>
              <td className={TABLE_CELL_SX} colSpan={visibleColumnCount}>
                {noDataMessage}
              </td>
            </TableBodyRow>
          )}
          {/* Data rows */}
          {!loading &&
            rows.length > 0 &&
            vRows.map(vRow => {
              const row = rows[vRow.index];
              return (
                <TableBodyRow
                  key={row.id}
                  row={row}
                  TableBodyColProps={TableBodyColProps}
                  className={clsx(vRow.index % 2 === 0 && alternatingRowColors && 'bg-gray-50')}
                  {...TableBodyRowProps}
                  enableColumnResizing={table.options.enableColumnResizing}
                  enableRowHover={enableRowHover}
                  disabled={isRowDisabled}
                  focused={focusedRowIndex === row.index}
                  focusRef={row.index === focusedRowIndex ? focusedRowRef : null}
                />
              );
            })}
          {/* Virtualization bottom row */}
          {paddingBottom > 0 && (
            <TableBodyRow
              {...TableBodyRowProps}
              TableBodyColProps={TableBodyColProps}
              style={{ height: `${!loading ? paddingBottom : 20}px` }}
            />
          )}
        </TableBody>
      </table>
    </TableWrapper>
  );
};

export default Table;
