import { JSONSchema4 } from 'json-schema';

export const BasicAuthSchema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  $ref: '#/definitions/BasicAuthConf',
  definitions: {
    BasicAuthConf: {
      properties: {
        username: {
          type: 'string',
          title: 'Username',
          examples: ['user@example.com']
        },
        password: {
          type: 'string',
          title: 'Password',
          examples: ['secret'],
          sensitive: 'true'
        }
      },
      required: ['username', 'password'],
      additionalProperties: false,
      type: 'object'
    }
  }
} as JSONSchema4;

export const HeaderKeySchema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  $ref: '#/definitions/RequestParameter',
  definitions: {
    RequestParameter: {
      properties: {
        name: {
          type: 'string',
          title: 'Name',
          examples: ['foo']
        },
        value: {
          additionalProperties: true,
          title: 'Value'
        }
      },
      required: ['name', 'value'],
      additionalProperties: false,
      type: 'object'
    }
  }
} as JSONSchema4;

export const OAuthSchema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  $ref: '#/definitions/ClientCredentialConf',
  definitions: {
    ClientCredentialConf: {
      properties: {
        client_id: {
          type: 'string',
          title: 'Client ID',
          examples: ['client_id']
        },
        client_secret: {
          type: 'string',
          title: 'Client secret',
          examples: ['client_secret'],
          sensitive: 'true'
        },
        token_endpoint: {
          type: 'string',
          title: 'Token endpoint',
          examples: ['https://example.com/oauth/token']
        },
        extra_form_data: {
          items: {
            $ref: '#/definitions/RequestParameter'
          },
          type: 'array',
          title: 'Extra form data'
        }
      },
      required: ['client_id', 'client_secret', 'token_endpoint', 'extra_form_data'],
      additionalProperties: false,
      type: 'object'
    },
    RequestParameter: {
      properties: {
        name: {
          type: 'string',
          title: 'Name',
          examples: ['foo']
        },
        value: {
          additionalProperties: true,
          title: 'Value'
        }
      },
      required: ['name', 'value'],
      additionalProperties: false,
      type: 'object'
    }
  }
} as JSONSchema4;

export const JiraAccessKeySchema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  $ref: '#/definitions/ConnectionConf',
  definitions: {
    ConnectionConf: {
      required: ['url', 'username', 'api_key'],
      properties: {
        url: {
          type: 'string',
          title: 'Jira URL'
        },
        username: {
          type: 'string',
          title: 'Username'
        },
        api_key: {
          type: 'string',
          title: 'API token'
        }
      },
      additionalProperties: false,
      type: 'object'
    }
  }
};

export const JiraPersonalAccessTokenSchema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  $ref: '#/definitions/ConnectionConf',
  definitions: {
    ConnectionConf: {
      required: ['url', 'access_token'],
      properties: {
        url: {
          type: 'string',
          title: 'Jira URL'
        },
        access_token: {
          type: 'string',
          title: 'Personal access token'
        }
      },
      additionalProperties: false,
      type: 'object'
    }
  }
};

export const JiraAuthSchemas = {
  apikey: JiraAccessKeySchema,
  pat: JiraPersonalAccessTokenSchema
};

export const AuthSchemas = {
  basic: BasicAuthSchema,
  header: HeaderKeySchema,
  oauth: OAuthSchema
};
