import * as React from 'react';

import { CustomProviderProps } from '../utils/custom-types.util';
import { createUseCtx } from './context-factories';

export function createSplitReducerCtx<StateType, ActionType>(
  reducer: React.Reducer<StateType, ActionType>,
  defaultState: StateType
) {
  const StateCtx = React.createContext<StateType>(defaultState);
  const DispatchCtx = React.createContext<React.Dispatch<ActionType>>(() => defaultState);

  function Provider({ children, initialState = defaultState }: CustomProviderProps<StateType>) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const memoState = React.useMemo(() => state, [state]);
    const memoDispatch = React.useCallback(dispatch, [dispatch]);

    return (
      <StateCtx.Provider value={memoState}>
        <DispatchCtx.Provider value={memoDispatch}>{children}</DispatchCtx.Provider>
      </StateCtx.Provider>
    );
  }

  const useCtxState = createUseCtx(StateCtx);
  const useCtxDispatch = createUseCtx(DispatchCtx);

  return { StateCtx, DispatchCtx, useCtxState, useCtxDispatch, Provider };
}
