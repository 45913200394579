import { useLazyQuery, useQuery } from '@apollo/client';
import { RJSFSchema } from '@rjsf/utils';
import Ajv2020 from 'ajv/dist/2020';
import {
  fromPairs,
  intersection,
  isEmpty,
  lowerCase,
  map,
  mapValues,
  reverse,
  toPairs,
  values
} from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { NIL } from 'uuid';
import {
  Button,
  EditPermission,
  Icon,
  JSONSchemaForm,
  Label,
  ModelField,
  ModelFieldSelect,
  MyCombobox,
  ParamButton,
  Search,
  Section,
  SideBySide,
  TableTopper,
  TableWrap,
  Tooltip
} from '~/components';
import TooltipIcon from '~/components/tooltip-icon';
import {
  ConnectionFragment,
  ConnectionTypesDocument,
  ConnectionsDocument,
  EnrichmentUpdate,
  EnrichmentsDocument,
  ModelFieldFragment,
  Operation,
  SchemaRefreshStatus
} from '~/generated/graphql';
import { useFieldsetState } from '~/hooks';
import {
  FieldsetFormValues,
  Selectable,
  WINDOW_THRESHOLD,
  mapFieldsToFieldUpdates,
  oxford,
  toSelectable
} from '~/utils';
import { FieldsPicker } from '../fields-picker';

interface EnrichmentSchema {
  definitions?: {
    FieldsetConfiguration: object;
    Mappings: {
      properties: Record<
        string,
        {
          title: string;
          type: string;
        }
      >;
      anyOf: { required: string[] }[];
    };
  };
}

interface EnrichmentConnection {
  id: string;
  name: string;
  type?: {
    id: string;
    name: string;
  };
  isDisconnected?: boolean;
}

type Mapping = [string, string];

function formatOptionLabel(connection: EnrichmentConnection & Selectable) {
  if (!connection.type) {
    return <p className="text-sm">{connection.label}</p>;
  }
  if (connection.isDisconnected) {
    return (
      <Tooltip content={`Create a Polytomic connection to ${connection.label} to enable`}>
        <span className="flex space-x-2">
          <Icon match={connection.type.id} className="h-5 w-5 text-gray-500 opacity-60" />
          <span className="text-gray-500">{connection.label}</span>
        </span>
      </Tooltip>
    );
  }
  return (
    <div className="flex items-start">
      <Icon match={connection.type.id} className="mr-2 h-5 w-5" />
      <p className="text-sm">{connection.name}</p>
    </div>
  );
}

interface EnrichmentChange {
  mappings: Mapping[];
  connection: ConnectionFragment;
  config: Record<string, string>;
  fields: ModelFieldFragment[];
}

const EMPTY_MAPPING: Mapping = [null, null];

export function Enrichment() {
  const { fieldset, applyUpdate, loading: updateLoading } = useFieldsetState();
  const { watch, setValue } = useFormContext<FieldsetFormValues>();

  const configuration = watch('configuration');
  const enrichments = watch('enrichments');
  const enrichment = fieldset.enrichments[0];

  useEffect(() => {
    if (fieldset.enrichments?.[0]?.provider?.configuration) {
      updateEnrichments({}, { shouldDirty: false });
      handleSchemaChange(
        fieldset.enrichments?.[0]?.provider.configuration as Record<string, string>
      );
    }
  }, []);

  useEffect(() => {
    if (enrichments?.[0]?.provider?.fields) {
      setFields(enrichments[0].provider.fields);
    }
  }, [enrichments]);

  const [search, setSearch] = useState<string>('');

  const [connection, setConnection] = useState<EnrichmentConnection>(
    enrichment?.provider.connection
  );
  const [schema, setSchema] = useState<RJSFSchema>();
  const [config, setConfig] = useState<Record<string, string>>(
    enrichment?.provider.configuration as Record<string, string>
  );
  const [enrichmentOptions, setEnrichmentOptions] = useState<ModelField[]>();
  const [mappings, setMappings] = useState<Mapping[]>(
    toPairs(
      !isEmpty(enrichment?.mappings)
        ? (enrichment.mappings as Record<string, string>)
        : EMPTY_MAPPING
    )
  );
  const [fields, setFields] = useState<ModelFieldFragment[]>(enrichment?.provider.fields);
  const [getSchema, { loading: schemaLoading }] = useLazyQuery(EnrichmentsDocument);

  const { data: connectionsData, loading: connectionsLoading } = useQuery(ConnectionsDocument);
  const { data: connectionsTypeData, loading: connectionTypesLoading } =
    useQuery(ConnectionTypesDocument);

  const defaultConnection = { id: 'none', label: 'None', name: 'none', value: null };
  const connectionsOptions = useMemo(
    () => [
      defaultConnection,
      ...(connectionsData?.connections ?? [])
        .filter(c => c.type.operations.includes(Operation.EnrichmentBackend))
        .map(toSelectable),
      ...(connectionsTypeData?.connectionTypes ?? [])
        .filter(
          t =>
            t.operations.includes(Operation.EnrichmentBackend) &&
            !connectionsData?.connections.find(c => c.type.id === t.id)
        )
        .map(type => ({
          ...toSelectable(type),
          type,
          isDisconnected: true
        }))
    ],
    [connectionsData, connectionsTypeData]
  );

  const handleSchemaChange = async (value: Record<string, string>, refresh?: boolean) => {
    if (isEmpty(value)) {
      return;
    }
    // submit
    const { data } = await getSchema({
      variables: {
        connectionID: connection.id,
        config: value
      }
    });

    const enrichmentSchema = data?.enrichmentSchema as EnrichmentSchema;

    const options = values(
      mapValues(
        enrichmentSchema?.definitions?.Mappings?.properties,
        (field, key) =>
          ({
            id: key,
            title: field.title,
            label: field.title,
            type: field.type,
            sourceName: key,
            fieldset: {
              id: '',
              name: '',
              connection: {
                name: connection.name,
                type: {
                  id: connection.type.id
                }
              }
            }
          }) as ModelField
      )
    );

    setEnrichmentOptions(options);
    setConfig(value);
    setSchema(enrichmentSchema as RJSFSchema);
    if (refresh) {
      setMappings([EMPTY_MAPPING]);
      setFields([]);
      updateEnrichments({ config: value, fields: [], mappings: [EMPTY_MAPPING] });
    }
  };

  const areConditionsMet = (mappings: Mapping[]) => {
    const data = fromPairs(map(mappings, m => reverse([...m])));
    const mappingSchema = schema?.definitions?.Mappings;
    return mappingSchema && data && new Ajv2020().validate(mappingSchema, data);
  };
  const isComplete = (mappings: Mapping[]) => mappings.every(mapping => mapping.every(v => v));
  const isValid = (mappings: Mapping[]) => isComplete(mappings) && areConditionsMet(mappings);

  const updateEnrichments = (
    change: Partial<EnrichmentChange>,
    // Local options
    { shouldApply, shouldDirty }: { shouldApply?: boolean; shouldDirty?: boolean } = {
      shouldApply: false,
      shouldDirty: true
    },
    // Apply options
    applyOptions: { refresh?: boolean } = {}
  ) => {
    const update: EnrichmentUpdate = {
      id: enrichment?.provider.id ?? NIL,
      configuation: change?.config ?? config,
      connectionId: change?.connection?.id ?? connection.id,
      mappings: fromPairs(change?.mappings ?? mappings),
      fields: mapFieldsToFieldUpdates(change.fields ?? fields)
    };

    setValue('enrichmentUpdates', [update], { shouldDirty });

    if (shouldApply && isValid(change.mappings ?? mappings)) {
      applyUpdate({ ...configuration }, applyOptions, [update]);
    }
  };

  const handleConnectionChange = async (connection: ConnectionFragment) => {
    if (connection.id === 'none') {
      setSchema({});
      setConfig({});
      setConnection(null);
      setMappings([EMPTY_MAPPING]);
      setFields([]);
      setEnrichmentOptions([]);
      setValue('enrichmentUpdates', null, { shouldDirty: true });
      return;
    }
    setConnection(connection);
    const { data } = await getSchema({
      variables: {
        connectionID: connection.id,
        config: {}
      }
    });
    const enrichmentSchema = data?.enrichmentSchema;

    setSchema(enrichmentSchema);
    setConfig({});
    setMappings([EMPTY_MAPPING]);
    setFields([]);
  };

  const hasMapping = useMemo(() => mappings?.[0]?.[0] && mappings?.[0]?.[1], [mappings]);

  const handleMappingFieldChange = (index: number, newMapping: Mapping) => {
    // Auto populate the mapping if field names match
    if (newMapping[0] && !newMapping[1]) {
      const sourceField = fieldset.fields
        .filter(field => !mappings.some(mapping => mapping[0] === field.sourceName))
        .find(field => field.sourceName === newMapping[0]);
      const targetField = enrichmentOptions
        .filter(field => !mappings.some(mapping => mapping[1] === field.sourceName))
        .find(
          field =>
            !!intersection(
              [sourceField.label, sourceField.sourceName].map(lowerCase),
              [field.label, field.sourceName].map(lowerCase)
            ).length
        );
      newMapping = [newMapping[0], targetField?.sourceName];
    }
    const newMappings = mappings.map((mapping, i) => (i === index ? newMapping : mapping));
    setMappings(newMappings);
    updateEnrichments({ mappings: newMappings }, { shouldApply: true });
  };

  const handleFieldsChanged = (newFields: ModelFieldFragment[]) => {
    setFields(newFields);
    updateEnrichments({ fields: newFields });
  };

  const handleAddMapping = () => {
    const newMappings = [...mappings, EMPTY_MAPPING];
    setMappings(newMappings);
  };

  const handleDeleteMapping = (index: number) => {
    const newMappings = mappings.filter((v, i) => i !== index);
    setMappings(newMappings);
    updateEnrichments({ mappings: newMappings }, { shouldApply: true });
  };

  const mappingProperties = (schema as EnrichmentSchema)?.definitions?.Mappings?.properties;

  const targetOptions = useMemo(
    () =>
      enrichmentOptions?.filter(
        field => !mappings.some(mapping => mapping[1] === field.sourceName)
      ),
    [enrichmentOptions, mappings]
  );

  const hideAddButton = (index: number) => {
    return index !== mappings?.length - 1 || !isComplete(mappings) || !targetOptions?.length;
  };

  const hideDeleteButton = (index: number) => {
    return index === 0 && mappings?.length === 1;
  };

  const handleRefresh = () => {
    updateEnrichments({}, { shouldApply: true }, { refresh: true });
  };

  return (
    <Section className="space-y-6">
      <SideBySide
        heading={
          <div>
            <h3 className="sbs-heading">Data enrichment</h3>
            <p className="text-sm font-normal text-gray-500">(Optional)</p>
          </div>
        }
      >
        {!connectionsData?.connections?.length &&
          !connectionsLoading &&
          !connectionTypesLoading && <p>You have no enrichment connections.</p>}
        {!!connectionsData?.connections?.length && (
          <div className="flex flex-col space-y-2">
            <div className="flex flex-col space-y-2">
              <div className="flex w-full items-center space-x-3">
                <MyCombobox
                  aria-label="Enrichment connection"
                  className="max-w-xs flex-1"
                  options={connectionsOptions}
                  value={!isEmpty(connection) ? toSelectable(connection) : defaultConnection}
                  onChange={connection => handleConnectionChange(connection)}
                  formatOptionLabel={formatOptionLabel}
                  placeholder="Select enrichment provider..."
                  isLoading={
                    connectionsLoading || connectionTypesLoading || (!schema && schemaLoading)
                  }
                  isOptionDisabled={o => (o as EnrichmentConnection).isDisconnected}
                  disabled={!!fieldset?.properties?.enrichmentDisabled}
                />
                {!!fieldset?.properties?.enrichmentDisabled && (
                  <TooltipIcon
                    message="Enrichment is not available for this model"
                    icon={
                      <Icon
                        name="InfoFilled"
                        className="h-5 w-5 text-blue-500 hover:text-blue-400"
                      />
                    }
                  />
                )}
                {schemaLoading &&
                  (connection as ConnectionFragment).schemaRefreshStatus ===
                    SchemaRefreshStatus.Uninitialized && (
                    <TooltipIcon
                      message="Waiting for initial data load"
                      icon={
                        <Icon
                          name="InfoFilled"
                          className="h-5 w-5 text-blue-500 hover:text-blue-400"
                        />
                      }
                    />
                  )}
              </div>
              {schema && (
                <div className="max-w-xs">
                  <JSONSchemaForm
                    formData={config}
                    schema={schema}
                    uiSchema={{}}
                    onChange={schema => handleSchemaChange(schema, true)}
                  />
                </div>
              )}
            </div>
            {!!enrichmentOptions?.length && (
              <div>
                <Label>Input Mappings</Label>
                <div className="flex flex-col space-y-2">
                  {mappings?.map((mapping, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <ModelFieldSelect
                        className="flex-1"
                        options={fieldset.fields.filter(
                          field => !mappings.some(mapping => mapping[0] === field.sourceName)
                        )}
                        placeholder="Select model field..."
                        value={fieldset.fields.find(field => field.sourceName === mapping[0])}
                        onChange={from =>
                          handleMappingFieldChange(index, [from.sourceName, mapping[1]])
                        }
                        isWindowed={fieldset.fields?.length > WINDOW_THRESHOLD}
                        showSourceName
                        hideGroups
                      />
                      <Icon name="ArrowNarrowRight" className="h-5 w-5 text-gray-500" />
                      <ModelFieldSelect
                        className="flex-1"
                        options={targetOptions}
                        placeholder="Select enrichment field..."
                        value={enrichmentOptions.find(option => option.sourceName === mapping[1])}
                        onChange={to =>
                          handleMappingFieldChange(index, [mapping[0], to.sourceName])
                        }
                        isWindowed={enrichmentOptions?.length > WINDOW_THRESHOLD}
                        showSourceName
                        hideGroups
                      />
                      <div className="flex gap-2">
                        <ParamButton
                          action="delete"
                          className={hideDeleteButton(index) && 'hidden'}
                          onClick={() => handleDeleteMapping(index)}
                        />
                        <ParamButton
                          action="add"
                          className={hideAddButton(index) && 'hidden'}
                          onClick={handleAddMapping}
                        />
                        {hideDeleteButton(index) && <ParamButton hidden />}
                        {hideAddButton(index) && <ParamButton hidden />}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </SideBySide>

      {hasMapping && (
        <div className="min-w-[calc(100%+1.5rem)] animate-fadeIn pr-6">
          <TableWrap className="min-w-full">
            <TableTopper className="h-16 justify-between space-x-4 bg-white px-4">
              <span>Enrichment fields</span>
              <Search onChange={setSearch} onReset={() => setSearch('')} />
              <EditPermission>
                <Button onClick={handleRefresh} loading={updateLoading} iconEnd="Refresh">
                  Refresh
                </Button>
              </EditPermission>
            </TableTopper>
            {!areConditionsMet(mappings) && (
              <div className="p-4 text-gray-500">
                <p>{connection.type.name} requires at least one of the following input mappings:</p>
                <ul className="my-1.5 list-inside list-disc space-y-1 pl-2">
                  {(schema as EnrichmentSchema)?.definitions?.Mappings?.anyOf?.map((group, i) => (
                    <li key={i}>
                      {oxford(group.required.map(field => mappingProperties[field].title))}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!!areConditionsMet(mappings) && (
              <FieldsPicker
                fields={fields}
                onFieldsChange={handleFieldsChanged}
                loading={updateLoading}
                showSourceIcon={true}
                search={search}
              />
            )}
          </TableWrap>
        </div>
      )}
    </Section>
  );
}
