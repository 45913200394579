import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  MyCombobox,
  MyInput,
  SideBySide
} from '../../../components';
import { ConnectionTypeFragment } from '../../../generated/graphql';
import { getSchemaNormalized, isRequiredMsg, Selectable } from '../../../utils';
import { ConnectionFormValues } from '../connection-config';

const JIRA_AUTH_METHODS = {
  API_KEY: { label: 'API Key', value: 'apikey' },
  PERSONAL_ACCESS_TOKEN: { label: 'Personal Access Token', value: 'pat' }
};

export type CSVConnectionConfigProps = {
  connectionType: ConnectionTypeFragment;
};

export function JiraConnectionConfig({ connectionType }: CSVConnectionConfigProps) {
  const { setValue, register, getValues, formState } = useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  const [authMethod, setAuthMethod] = React.useState<Selectable>(() => {
    const auth = getValues('configuration.auth_method');
    return auth === 'pat' ? JIRA_AUTH_METHODS.PERSONAL_ACCESS_TOKEN : JIRA_AUTH_METHODS.API_KEY;
  });

  const fields = getSchemaNormalized(connectionType.configurationSchema);

  const handleAuthMethodChange = (option: Selectable | null) => {
    if (option) {
      setValue('configuration.auth_method', option.value, {
        shouldValidate: true,
        shouldDirty: true
      });
      setAuthMethod(option);
    }
  };

  return (
    <>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Authentication method">
        <EditPermission
          fallback={<DisabledSelect valueLabel={authMethod?.label} className="max-w-xs" />}
        >
          <MyCombobox
            className="max-w-xs"
            value={authMethod}
            options={Object.values(JIRA_AUTH_METHODS)}
            onChange={handleAuthMethodChange}
          />
        </EditPermission>
      </SideBySide>
      {authMethod && (
        <SideBySide hasSectionWrap styles="space-y-3" heading="Options">
          <div className="max-w-xs">
            <EditPermission>
              <MyInput
                {...register('configuration.url', {
                  required: isRequiredMsg(fields?.['url']?.title)
                })}
                label={fields?.['url']?.title}
                description={fields?.['url']?.description}
                errors={errors}
              />
            </EditPermission>
          </div>
          {authMethod?.value === JIRA_AUTH_METHODS.API_KEY.value && (
            <div className="max-w-xs">
              <EditPermission>
                <MyInput
                  {...register('configuration.username', {
                    required: isRequiredMsg(fields?.['username']?.title)
                  })}
                  label={fields?.['username']?.title}
                  description={fields?.['username']?.description}
                  errors={errors}
                />
              </EditPermission>
            </div>
          )}
          {authMethod?.value === JIRA_AUTH_METHODS.API_KEY.value && (
            <div className="max-w-xs">
              <EditPermission>
                <MyInput
                  {...register('configuration.api_key', {
                    required: isRequiredMsg(fields?.['api_key']?.title)
                  })}
                  label={fields?.['api_key']?.title}
                  description={fields?.['api_key']?.description}
                  errors={errors}
                />
              </EditPermission>
            </div>
          )}
          {authMethod?.value === JIRA_AUTH_METHODS.PERSONAL_ACCESS_TOKEN.value && (
            <div className="max-w-xs">
              <EditPermission>
                <MyInput
                  {...register('configuration.access_token', {
                    required: isRequiredMsg(fields?.['access_token']?.title)
                  })}
                  label={fields?.['access_token']?.title}
                  description={fields?.['access_token']?.description}
                  errors={errors}
                />
              </EditPermission>
            </div>
          )}
        </SideBySide>
      )}
    </>
  );
}
