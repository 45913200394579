import { useMemo, useRef, useState } from 'react';

import { ColumnDef, ColumnSort, SortingState, functionalUpdate } from '@tanstack/react-table';
import {
  BulkExecutionStatus,
  BulkExecutionType,
  BulkExecutionsSort,
  BulkSyncDocument,
  BulkSyncExecutionsDocument,
  BulkSyncExecutionsQuery,
  BulkSyncPeekFragment,
  BulkSyncStatusDocument,
  SelectiveMode
} from '~/generated/graphql';
import { DataTableVirtual, PaginatedQueryParams } from '~/components/v3';
import { HISTORY_FILTERS, capsFirst, emptyCell, getLongLocalTime } from '~/utils';
import { useParams } from 'react-router-dom';
import { useBannerDispatch, useOnClickOutside } from '~/hooks';
import { useLazyQuery, useSubscription } from '@apollo/client';
import { ExecutionStatusDisplay } from '../components/ExecutionStatusDisplay';
import { ExecutionDetailDrawer } from './ExecutionDetailDrawer';
import { HistoryFilters } from '../../syncs/sync-history/history-filters';
import { useBulkSyncFilters } from './use-bulk-sync-filters';
import { Button } from '~/components';
import { invertBy, isEmpty } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { cn } from '~/lib/utils';
import LoadingDots from '~/components/v2/feedback/LoadingDots';

export interface HistoryItem {
  id: string;
  syncId: string;
  type: BulkExecutionType;
  status: BulkExecutionStatus;
  statusMessage?: string;
  recordCount: number;
  warningCount?: number;
  errorCount?: number | null;
  createdAt: string;
  startedAt?: string | null;
  completedAt?: string | null;
  formattedDuration: string;
  selectiveMode: SelectiveMode;
}

const FILTERED_FILTERS = {
  time: HISTORY_FILTERS.time,
  schema: HISTORY_FILTERS.schema,
  status: HISTORY_FILTERS.status
};

function getTypeLabel(item: HistoryItem) {
  let label = '';
  if (item.type === BulkExecutionType.Api) {
    label += 'API';
  } else {
    label += capsFirst(item.type);
  }

  if (item.selectiveMode === SelectiveMode.IncrementalFields) {
    label += ' (incremental)';
  } else if (item.selectiveMode === SelectiveMode.NonincrementalFields) {
    label += ' (non-incremental)';
  }

  return label;
}

function isRunning(item: HistoryItem) {
  return [
    BulkExecutionStatus.Running,
    BulkExecutionStatus.Created,
    BulkExecutionStatus.Processing,
    BulkExecutionStatus.Scheduled,
    BulkExecutionStatus.Exporting,
    BulkExecutionStatus.Canceling
  ].includes(item.status);
}

const columns: ColumnDef<HistoryItem>[] = [
  {
    id: 'Sync time',
    header: () => 'Sync time',
    accessorFn: row => row.startedAt,
    cell: ({ row }) => (
      <span className="flex">
        {getLongLocalTime(row?.original?.startedAt || row?.original?.createdAt) || emptyCell}
      </span>
    ),
    size: 200
  },
  {
    accessorKey: 'type',
    accessorFn: row => row.type,
    header: 'Type',
    cell: ({ row }) => getTypeLabel(row.original) || emptyCell,
    enableSorting: false,
    size: 100
  },
  {
    accessorKey: 'duration',
    accessorFn: row => row?.formattedDuration,
    header: 'Duration',
    cell: ({ row }) => row?.original?.formattedDuration || emptyCell,
    size: 100
  },
  {
    accessorKey: 'recordCount',
    accessorFn: row => row.recordCount,
    header: 'Total Records',
    cell: ({ row }) => row.original?.recordCount?.toLocaleString() || emptyCell,
    size: 80
  },
  {
    accessorKey: 'warningCount',
    accessorFn: row => row.warningCount,
    header: 'Warnings',
    cell: ({ row }) => row.original?.warningCount?.toLocaleString() || emptyCell,
    size: 80
  },
  {
    accessorKey: 'errorCount',
    accessorFn: row => row.errorCount,
    header: 'Errored',
    cell: ({ row }) => row.original?.errorCount?.toLocaleString() || emptyCell,
    enableSorting: false,
    size: 80
  },
  {
    accessorKey: 'status',
    accessorFn: row => row.status,
    header: 'Status',
    cell: ({ row }) =>
      (
        <ExecutionStatusDisplay message={row.original.statusMessage} status={row.original.status} />
      ) || emptyCell,
    enableSorting: false,
    size: 115
  }
];

// These sorts are binary, but the desc prop controls the arrow direction
const sortingStates: Record<keyof typeof BulkExecutionsSort, ColumnSort> = {
  [BulkExecutionsSort.CreatedAt]: { id: 'Sync time', desc: false },
  [BulkExecutionsSort.RecordCount]: { id: 'recordCount', desc: false },
  [BulkExecutionsSort.Runtime]: { id: 'duration', desc: false }
};

const defaultSort = BulkExecutionsSort.CreatedAt;

interface QueryData {
  pages: {
    edges: {
      node: unknown;
      cursor: string;
    }[];
  }[];
  pageParams: unknown;
}

const updateQueryData = (data: BulkSyncExecutionsQuery) => (queryData: QueryData) => {
  const newEdges =
    data?.bulkSyncExecutions?.edges?.filter(
      (edge, index) =>
        index <= 24 && !queryData?.pages?.[0]?.edges?.find(e => e.cursor === edge.cursor)
    ) ?? [];
  const updatedEdges = queryData?.pages?.[0]?.edges?.map(edge => {
    const updated = data.bulkSyncExecutions.edges.find(e => e.cursor === edge.cursor);
    return updated ?? edge;
  });
  return {
    pages: queryData?.pages.map((page, index) =>
      index === 0
        ? {
            ...page,
            edges: [...newEdges, ...updatedEdges]
          }
        : page
    ),
    pageParams: queryData?.pageParams
  };
};
interface BulkSyncHistoryProps {
  bulkSync: BulkSyncPeekFragment;
}

export default function BulkSyncHistory({ bulkSync }: BulkSyncHistoryProps) {
  const { id: syncId } = useParams<{ id: string }>();
  const dispatchBanner = useBannerDispatch();

  const [selectedRow, setSelectedRow] = useState<HistoryItem>();
  const [sort, setSort] = useState<BulkExecutionsSort>(defaultSort);

  const queryClient = useQueryClient();

  const { methods, where, isSameFilters, refreshHistory } = useBulkSyncFilters();

  const [getExecs, { fetchMore }] = useLazyQuery(BulkSyncExecutionsDocument, {
    notifyOnNetworkStatusChange: true,
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: data => {
      if (selectedRow) {
        const row = data?.bulkSyncExecutions?.edges
          ?.map(({ node }) => node)
          ?.find(node => node.id === selectedRow.id);
        setSelectedRow(row ?? selectedRow);
      }
    }
  });

  const [refetch] = useLazyQuery(BulkSyncExecutionsDocument, {
    notifyOnNetworkStatusChange: true,
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: data => {
      if (selectedRow) {
        const row = data?.bulkSyncExecutions?.edges
          ?.map(({ node }) => node)
          ?.find(node => node.id === selectedRow.id);
        setSelectedRow(row ?? selectedRow);
      }
      queryClient.setQueryData([`${syncId}-history-running`], updateQueryData(data));
      queryClient.setQueryData([`${syncId}-history-completed`], updateQueryData(data));
    }
  });

  useSubscription(BulkSyncStatusDocument, {
    skip: !syncId,
    variables: { syncId },
    onData: ({ data, client }) => {
      const { activeExecutions, execution } = data.data?.bulkSyncStatus;
      if (!activeExecutions?.length) {
        return;
      }
      refetch({ variables: { syncId, where, sort } });
      client.cache.updateQuery({ query: BulkSyncDocument, variables: { id: syncId } }, data =>
        data?.bulkSync ? { bulkSync: { ...data.bulkSync, execution } } : null
      );
    }
  });

  const [isRunningLoaded, setIsRunningLoaded] = useState<boolean>(false);
  const [isCompletedLoaded, setIsCompletedLoaded] = useState<boolean>(false);

  const getData = async ({ after }: PaginatedQueryParams) => {
    const { data } = after
      ? await fetchMore({ variables: { syncId, where, after, sort } })
      : await getExecs({ variables: { syncId, where, sort } });
    setIsCompletedLoaded(true);
    return data.bulkSyncExecutions;
  };

  const getRunningData = async () => {
    const { data } = await getExecs({
      variables: {
        syncId,
        where: isEmpty(where) ? { status: { status: BulkExecutionStatus.Running } } : where,
        sort
      }
    });
    setIsRunningLoaded(true);
    return data.bulkSyncExecutions;
  };

  const runningTableBodyRef = useRef();
  const completedTableBodyRef = useRef();

  const sheetRef = useRef();

  useOnClickOutside([runningTableBodyRef, completedTableBodyRef, sheetRef], () =>
    setSelectedRow(null)
  );

  // Get the namespaces from the bulk sync for filtering
  const namespaces = useMemo(() => {
    const namespaces = bulkSync.namespaces;
    const isNamespaceRoot = namespaces?.length === 1 && !namespaces[0].id;
    // If namespace is an array of length one, only render children, otherwise render namespaces
    const response = isNamespaceRoot
      ? [...(namespaces[0].schemas ?? [])].map(schema => schema.id)
      : namespaces
          .filter(({ id }) => !!id)
          .flatMap(namespace => namespace.schemas.map(schema => schema.id));
    return response.sort();
  }, [bulkSync.namespaces]);

  // Binary sorting, if the same column is selected, then unselect.
  const handleSortingChange = (sorting: SortingState) => {
    const id = sorting?.[0]?.id;
    const newSort = invertBy(sortingStates, state => state.id)?.[id]?.[0] as BulkExecutionsSort;
    setSort(!newSort || newSort === sort ? defaultSort : newSort);
  };

  // Sorting state used by react table
  const sorting = useMemo(() => (sort ? [sortingStates[sort]] : []), [sort]);

  return (
    <>
      <HistoryFilters
        historyFilters={FILTERED_FILTERS}
        historyFilterParams={{
          schema: namespaces
        }}
        methods={methods}
        applyFiltersControl={
          <Button
            className="whitespace-nowrap"
            disabled={isSameFilters}
            // Todo - why does loading persist, how to avoid fetching next when
            // original request doesn't have a next page
            loading={false}
            onClick={refreshHistory}
          >
            Apply filters
          </Button>
        }
      />
      {!(isCompletedLoaded && isRunningLoaded) && (
        <div className="mt-24 flex h-full w-full items-center justify-center">
          <LoadingDots />
        </div>
      )}
      <section
        className={cn(
          'flex max-h-full w-max min-w-full flex-col space-y-4 overflow-auto p-6',
          !(isCompletedLoaded && isRunningLoaded) && 'opacity-0'
        )}
      >
        <h3 className="text-lg text-gray-800">Running</h3>
        <DataTableVirtual
          columns={columns}
          getData={getRunningData}
          onRowClick={row => setSelectedRow(row.original)}
          selectedRow={selectedRow}
          filters={where}
          tableBodyRef={runningTableBodyRef}
          classNames={{
            row: 'cursor-pointer',
            wrapper: 'h-fit'
          }}
          queryKey={`${syncId}-history-running`}
          globalFilter={row => isRunning(row.original)}
          disableFetchMore={true}
          emptyComponent={
            <div className="rounded border bg-white py-2 px-4 text-gray-500 shadow-sm">
              <p>No syncs running.</p>
            </div>
          }
        />
        <h3 className="text-lg text-gray-800">Completed</h3>
        <DataTableVirtual
          columns={columns}
          getData={getData}
          onRowClick={row => setSelectedRow(row.original)}
          selectedRow={selectedRow}
          filters={where}
          tableBodyRef={completedTableBodyRef}
          sorting={sorting}
          onSortingChange={updater => {
            handleSortingChange(functionalUpdate(updater, sorting));
          }}
          classNames={{
            row: 'cursor-pointer',
            wrapper: 'flex-1'
          }}
          queryKey={`${syncId}-history-completed`}
          globalFilter={row => !isRunning(row.original)}
          emptyComponent={
            <div className="rounded border bg-white py-2 px-4 text-gray-500 shadow-sm">
              <p>No completed syncs.</p>
            </div>
          }
        />
      </section>
      <ExecutionDetailDrawer
        row={selectedRow}
        sheetRef={sheetRef}
        sync={bulkSync}
        onClose={() => setSelectedRow(null)}
      />
    </>
  );
}
