import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import intervalToDuration from 'date-fns/intervalToDuration';
import {
  BulkExecutionsCondition,
  BulkExecutionsSchemaEqualFilterFunc,
  BulkExecutionStatus,
  SchemaExecutionStatus
} from '../generated/graphql';
import { SyncHistoryFilter } from './custom-types.util';

export const transformFiltersForWhere = (filters: SyncHistoryFilter[]) => {
  if (!filters || filters.length === 0) {
    return {};
  }
  const where: BulkExecutionsCondition = {
    schema: undefined,
    status: undefined,
    time: undefined
  };

  filters.forEach(filter => {
    // this guard expects string or undefined on all values
    if (!(filter.column && filter.comparator && filter.param)) {
      return;
    }
    switch (filter.column) {
      case 'schema':
        where.schema = {
          schemaID: filter.param,
          comparator: (filter.comparator === 'eq'
            ? 'Equality'
            : 'Inequality') as BulkExecutionsSchemaEqualFilterFunc
        };
        break;
      case 'status':
        if (filter.param === 'completed with errors') {
          where.status = { status: BulkExecutionStatus.Errors };
          break;
        }
        where.status = { status: filter.param as BulkExecutionStatus };
        break;
      case 'time':
        switch (filter.comparator) {
          case 'between': {
            const [from, to] = filter.param.split('@');
            where.betweenTime = { from, to };
            break;
          }
          case 'eq': {
            where.time = { seconds: parseInt(filter.param) };
            break;
          }
        }
      default:
        break;
    }
  });

  return where;
};

export function getDurationInMilliseconds(began: string, completed: string) {
  const start = new Date(began);
  const end = new Date(completed);

  if (!began || !completed) {
    return null;
  }

  return differenceInMilliseconds(end, start);
}

// Uses same formatting as backend
// https://github.com/polytomic/app/blob/8e3fb2451775c288234e956f46f29ea8548b4e86/services/polytomic/util/duration.go#L14
export function getFormattedDuration(began: string, completed: string) {
  const start = new Date(began);
  const end = new Date(completed);

  const diff = differenceInMilliseconds(end, start);

  if (!began || !completed || !diff) {
    return '';
  }

  if (diff < 1000) {
    return '< 1s';
  }

  const duration = intervalToDuration({ start, end });

  let output = '';

  if (duration.days > 0 || duration.hours > 0) {
    output += `${duration.days * 24 + duration.hours}h `;
  }

  output += `${duration.minutes}m `;
  output += `${duration.seconds}s`;

  return output;
}

export function getSyncStatusOrder(status: SchemaExecutionStatus) {
  const order = [
    SchemaExecutionStatus.Created,
    SchemaExecutionStatus.Scheduled,
    SchemaExecutionStatus.Running,
    SchemaExecutionStatus.Exporting,
    SchemaExecutionStatus.Completed,
    SchemaExecutionStatus.Canceled,
    SchemaExecutionStatus.Failed
  ];

  return order.indexOf(status);
}
