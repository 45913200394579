import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';

import { InlineMessage, MySwitch } from '~/components';
import { NotificationsDocument, UpdateUserEmailNotificationDocument } from '~/generated/graphql';
import { useAuth, useBannerDispatch } from '~/hooks';

const wrapperStyles = 'px-3 pt-3';

interface Props {
  orgId: string;
  notificationEvent: string;
  switchLabel: string;
}

export function EmailNotification({ orgId, notificationEvent, switchLabel }: Props) {
  const { user } = useAuth();
  const dispatchBanner = useBannerDispatch();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [showInlineMessage, setShowInlineMessage] = React.useState(false);

  const { loading } = useQuery(NotificationsDocument, {
    variables: { scope: orgId, event: notificationEvent },
    onCompleted: data => {
      if (!data || !data.notificationRecipients || data.notificationRecipients.length === 0) {
        return;
      }
      const found = data.notificationRecipients.find(
        r => r.event === notificationEvent && r.recipientId === user?.email
      );
      if (found) {
        setIsChecked(true);
      }
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } }),
    fetchPolicy: 'no-cache'
  });

  const [updateUserNotifications, { loading: mutationLoading }] = useMutation(
    UpdateUserEmailNotificationDocument,
    {
      onCompleted: data => {
        setShowInlineMessage(true);
        if (!data.updateUserEmailNotification) {
          setIsChecked(false);
          return;
        }
        if (data.updateUserEmailNotification.event === notificationEvent) {
          setIsChecked(true);
        }
      },
      onError: error => {
        dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
        setIsChecked(prev => !prev);
      }
    }
  );

  function handleToggle(checked: boolean) {
    setIsChecked(checked);
    void updateUserNotifications({
      variables: {
        scope: orgId,
        event: notificationEvent,
        enabled: checked
      }
    });
  }

  function clearInline() {
    setShowInlineMessage(false);
  }

  return (
    <div className="flex items-center space-x-2">
      <MySwitch
        loading={loading}
        disabled={mutationLoading}
        label={switchLabel}
        labelPosition="right"
        checked={isChecked}
        onChange={handleToggle}
      />
      <InlineMessage show={showInlineMessage} autoDismiss={clearInline} text="Saved" />
    </div>
  );
}
