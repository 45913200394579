import { Alert } from '~/components/v3';
import { Button } from './Button';
import { Footer } from './Footer';

interface Props {
  failure?: string;
  onClose(): unknown;
}

function getMessage(props: Props) {
  switch (props.failure) {
    case 'token expired':
      return (
        <>
          Unable to continue. Your session has expired.
          <br />
          Close this window and restart the connect process.
        </>
      );
    default:
      return props.failure;
  }
}

export function Failure(props: Props) {
  if (!props.failure) {
    return null;
  }

  return (
    <>
      <Alert variant="warning">{getMessage(props)}</Alert>
      <Footer>
        <Button onClick={props.onClose}>Close</Button>
      </Footer>
    </>
  );
}
