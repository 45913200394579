import clsx from 'clsx';
import { useState, useMemo, Fragment, useEffect, useCallback } from 'react';
import { Button } from '~/components/form-components';
import { Truncator } from '~/components/truncator';
import { mockHierarchySelectCols, mockHierarchySelectOptions } from '../configs/Mock';
import LoadingDots from '../feedback/LoadingDots';
import { Icon } from '../../Icon';
import Checkbox from './Checkbox';
import { SelectOption } from './Select';
import { Resizable } from 're-resizable';

export enum EHierarchySelectState {
  SELECTED = 'selected',
  INDETERMINATE = 'indeterminate',
  UNSELECTED = 'unselected'
}

export type HierarchySelectOption = {
  id: string;
  label: string;
  value: string;
  parentId: string;
  selected?: EHierarchySelectState;
} & SelectOption;

export type HierarchySelectColumn = {
  id: string;
  label: string;
  accessor: string;
};

export type HierarchySelectProps = {
  selected?: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  options?: HierarchySelectOption[];
  cols?: HierarchySelectColumn[];
  mock?: boolean;
  loading?: boolean;
  error?: string;
  handleRefresh?: () => void;
};

const HierarchySelect = ({
  selected,
  setSelected,
  options,
  cols,
  mock = false,
  loading = false,
  error = null,
  handleRefresh = null
}: HierarchySelectProps) => {
  const [items, setItems] = useState<HierarchySelectOption[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [hovered, setHovered] = useState<string>();
  const [columns, setColumns] = useState<HierarchySelectColumn[]>([]);

  useEffect(() => {
    const _cols = cols ? cols : mock ? mockHierarchySelectCols : [];
    if (_cols) {
      setColumns(_cols);
    }
  }, [cols, mock, setColumns]);

  const getSelectedState = (id: string, _items) => {
    const item = _items.find(i => i.id === id);
    let state = item.selected;
    if (selected?.includes(id)) {
      state = EHierarchySelectState.SELECTED;
    }
    if (!selected?.includes(id)) {
      const children = _items.filter(i => i?.parentId === item?.id);
      if (children.length > 0) {
        const childStates = children.map(i => getSelectedState(i.id, _items));
        if (childStates.every(s => s === EHierarchySelectState.SELECTED)) {
          state = EHierarchySelectState.SELECTED;
        } else if (childStates.some(s => s === EHierarchySelectState.SELECTED)) {
          state = EHierarchySelectState.INDETERMINATE;
        } else {
          state = EHierarchySelectState.UNSELECTED;
        }
      } else {
        state = EHierarchySelectState.UNSELECTED;
      }
    }
    return state;
  };

  useEffect(() => {
    const _items = mock ? mockHierarchySelectOptions : options;

    if (_items) {
      const newItems = _items.map(i => ({
        ...i,
        selected: getSelectedState(i.id, _items)
      }));
      setItems(newItems);
    }
    return () => null;
  }, [mock, options, selected, setItems]);

  const handleSelect = useCallback(
    (clickedId: HierarchySelectOption['id']) => {
      const newState = items.map(i => ({ ...i }));
      // getters
      const getItemState = (id: string) => {
        return newState.find(i => i.id === id).selected;
      };
      // setters
      const updateParent = (id: string) => {
        const item = items.find(i => i.id === id);
        const parent = items.find(i => i.id === item.parentId);
        if (!parent) return;
        const childIds = items.filter(i => i.parentId === parent?.id).map(i => i.id);
        const childStates = childIds.map(childId => getItemState(childId));
        if (
          childStates.length ===
          childStates.filter(s => s === EHierarchySelectState.SELECTED).length
        ) {
          newState.find(i => i.id === parent.id).selected = EHierarchySelectState.SELECTED;
        } else if (
          childStates.length ===
          childStates.filter(s => s === EHierarchySelectState.UNSELECTED).length
        ) {
          newState.find(i => i.id === parent.id).selected = EHierarchySelectState.UNSELECTED;
        } else {
          newState.find(i => i.id === parent.id).selected = EHierarchySelectState.INDETERMINATE;
        }
        updateParent(parent.id);
      };
      const setUnchecked = (id: string) => {
        newState.find(i => i.id === id).selected = EHierarchySelectState.UNSELECTED;
        items
          .filter(i => i.parentId === id)
          .map(i => i.id)
          .forEach(childId => setUnchecked(childId));
        updateParent(id);
      };
      const setChecked = (id: string) => {
        newState.find(i => i.id === id).selected = EHierarchySelectState.SELECTED;
        items
          .filter(i => i.parentId === id)
          .map(i => i.id)
          .forEach(childId => setChecked(childId));
        updateParent(id);
      };
      // actual logic
      const itemState = getItemState(clickedId);
      if (itemState === EHierarchySelectState.SELECTED) {
        setUnchecked(clickedId);
      } else {
        setChecked(clickedId);
      }
      setSelected(
        newState.filter(i => i.selected === EHierarchySelectState.SELECTED).map(i => i.id)
      );
    },
    [items, setItems]
  );

  const handleExpand = (id: HierarchySelectOption['id']) => {
    if (expanded?.includes(id)) {
      setExpanded(expanded.filter(s => s !== id));
    } else {
      setExpanded([...expanded, id]);
    }
  };

  const handelHover = (id: HierarchySelectOption['id']) => {
    setHovered(h => (id === h ? undefined : h));
  };

  const isAllSelected = useMemo(() => {
    return items.every(item => item.selected === EHierarchySelectState.SELECTED);
  }, [items]);

  const isAllIndeterminate = useMemo(() => {
    return !isAllSelected && items.some(item => item.selected === EHierarchySelectState.SELECTED);
  }, [items, isAllSelected]);

  const toggleAllSelected = () => {
    if (isAllSelected) {
      setSelected([]);
    } else {
      setSelected(items.map(i => i.id));
    }
  };

  useEffect(() => {
    // expand first level if only one
    if (items.filter(i => i.parentId === null).length === 1) {
      setExpanded(e => [...new Set([...e, items.filter(i => i.parentId === null)[0].id]).values()]);
    }
  }, [items]);

  const noItemsHaveChildren = useMemo(() => {
    return items.every(i => i.parentId === null);
  }, [items]);

  const renderItems = (item: HierarchySelectOption, level: number, index: number) => {
    const children = items.filter(i => i.parentId === item.id);
    const isExpanded = expanded?.includes(item.id);
    return (
      <Fragment key={item.id}>
        <div
          className={clsx(
            `grid h-12 content-center items-center gap-5 hover:bg-indigo-50`,
            'auto-cols-fr grid-flow-col',
            'w-full space-y-1 px-5 ',
            children.length > 0 && 'cursor-pointer',
            level === 0 && index !== 0 && 'border-t border-gray-200',
            level !== 0 && (index + level) % 2 !== 0 ? 'bg-white' : 'bg-gray-50'
          )}
          onClick={() => handleExpand(item.id)}
          onMouseEnter={() => handelHover(item.id)}
          onMouseLeave={() => handelHover(item.id)}
        >
          {columns.map((col, idx) => {
            return (
              <div className="col-span-1 flex items-center" key={idx}>
                {idx === 0 && (
                  <Checkbox
                    checked={item.selected === EHierarchySelectState.SELECTED}
                    onChange={() => handleSelect(item.id)}
                    onClick={e => e.stopPropagation()}
                    indeterminate={item.selected === EHierarchySelectState.INDETERMINATE}
                    variant="indeterminate"
                  />
                )}
                <div className={clsx('flex items-center truncate')}>
                  <span
                    style={{
                      paddingLeft: idx === 0 && level * 22
                    }}
                  >
                    {idx === 0 && (
                      <Icon
                        name="SelectSingle"
                        className={clsx(
                          'h-4 w-4 font-medium text-gray-500',
                          !isExpanded ? '-rotate-90 transform' : 'rotate-0 transform',
                          children && children?.length > 0 ? 'opacity-100' : 'opacity-0',
                          noItemsHaveChildren && 'hidden'
                        )}
                      />
                    )}
                  </span>
                  <Truncator content={item?.[col?.accessor] || '-'}>
                    <p
                      className={clsx(
                        'hide-native-tooltip cursor-default truncate text-gray-800',
                        level <= 1 && 'font-medium',
                        noItemsHaveChildren && 'pl-1'
                      )}
                    >
                      {item?.[col?.accessor] || '-'}
                    </p>
                  </Truncator>
                </div>
              </div>
            );
          })}
        </div>
        {isExpanded &&
          children &&
          children.length > 0 &&
          children?.map(child => renderItems(child, level + 1, index++))}
      </Fragment>
    );
  };

  return (
    <div className="w-full overflow-clip rounded border border-gray-300">
      {/* HEADERS */}
      <div className="border-b border-gray-300 bg-gray-50">
        <div
          className={clsx(
            `thead-label mr-3.25 grid auto-cols-fr grid-flow-col content-center items-center gap-5 px-5 text-left`
          )}
        >
          {columns?.map((col, idx) => (
            <div key={col.id} className={'flex flex-row space-x-1'}>
              {idx === 0 && (
                <Checkbox
                  checked={isAllSelected}
                  onChange={toggleAllSelected}
                  variant="indeterminate"
                  indeterminate={isAllIndeterminate}
                  disabled={!!loading || !!error || items.length === 0}
                />
              )}
              <div>{col.label}</div>
            </div>
          ))}
        </div>
      </div>
      {/* NO DATA */}
      {!items.length && !loading && (
        <div className="flex h-[10.5rem] w-full flex-col items-center justify-center">
          <p className="text-sm text-gray-500">No items found</p>
          {handleRefresh && (
            <Button theme="ghost" className="mt-2" onClick={handleRefresh}>
              Refresh
            </Button>
          )}
        </div>
      )}
      {/* LOADING INDICATOR */}
      {loading && !error && (
        <div className="flex h-[10.5rem] w-full items-center justify-center">
          <LoadingDots />
        </div>
      )}
      {/* CELLS */}
      {items.length !== 0 && !loading && !error && (
        <Resizable
          handleClasses={{
            top: 'pointer-events-none',
            right: 'pointer-events-none',
            left: 'pointer-events-none',
            topRight: 'pointer-events-none',
            bottomRight: 'pointer-events-none',
            bottomLeft: 'pointer-events-none',
            topLeft: 'pointer-events-none'
          }}
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
          defaultSize={{ height: '170px', width: '100%' }}
          minHeight={170}
          maxHeight={500}
        >
          <div className="h-full w-full overflow-x-clip overflow-y-scroll text-left">
            {items?.filter(item => !item.parentId)?.map(item => renderItems(item, 0, 0))}
          </div>
          <Icon
            className="pointer-events-none absolute bottom-0.5 right-0.5 h-3 w-3 opacity-30"
            name="ResizeIndicator"
          />
        </Resizable>
      )}
    </div>
  );
};

export default HierarchySelect;
