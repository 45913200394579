import {
  ExpandedState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  RowSelectionState,
  useReactTable
} from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableRow } from './Table';
import { cn } from '~/lib/utils';
import { Icon } from '../Icon';
import LoadingDots from '../v2/feedback/LoadingDots';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { isFunction } from 'lodash';

import { tv } from 'tailwind-variants';
import { getClassNames, type VariantProps } from '~/lib/utils';
import { useVirtual, VirtualItem } from 'react-virtual';
import { ColumnDef } from './DataTableVirtual';
import { Truncator } from '../truncator';

const tableVariants = tv({
  slots: {
    wrapper: 'h-full max-h-full overflow-auto rounded-md border border-gray-300 bg-white py-2',
    table: 'h-full w-full',
    expander: 'flex flex-1 items-center overflow-hidden'
  }
});

export interface SelectionListProps<T> extends VariantProps<typeof tableVariants> {
  data: T[];
  getName: (originalRow: T) => string;
  loading?: boolean;

  // Filtering
  globalFilter?: string | FilterFn<T>;
  onGlobalFilterChange?: OnChangeFn<string>;

  // Selection
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;

  // Expansion
  expanded?: ExpandedState;
  onExpandedChange?: OnChangeFn<ExpandedState>;
  onRowExpanded?: (originalRow: T) => Promise<void>;
  getSubRows?: (originalRow: T) => T[];
  getRowId?: (originalRow: T) => string;
  getRowCanExpand?: (row: Row<T>) => boolean;
  enableRowSelection?: (row: Row<T>) => boolean;

  // Options
  emptyMessage?: string;
  maxLeafRowFilterDepth?: number;
  disableVirtualization?: boolean;
  showLoadingWhenRowsExist?: boolean;
  lockHeight?: boolean;
}

export function SelectionList<T>({
  data = [],
  getName,
  loading = false,
  globalFilter = '',
  onGlobalFilterChange,
  rowSelection = {},
  onRowSelectionChange,
  expanded = {},
  onExpandedChange,
  onRowExpanded = () => null,
  getSubRows,
  getRowId,
  getRowCanExpand,
  enableRowSelection,
  emptyMessage = 'No results.',
  maxLeafRowFilterDepth = 1,
  disableVirtualization = false,
  showLoadingWhenRowsExist = false,
  lockHeight = false,
  ...rest
}: SelectionListProps<T>) {
  const classNames = getClassNames(tableVariants, rest);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const enableGlobalFilter = isFunction(onGlobalFilterChange);

  const columns: ColumnDef<T>[] = [
    {
      accessorFn: item => getName(item),
      accessorKey: 'name',
      cell: ({ row }) => (
        <Truncator content={getName(row.original)}>
          <p className="truncate">{getName(row.original)}</p>
        </Truncator>
      )
    }
  ];

  const table = useReactTable({
    data,
    columns: columns.filter(column => column.isVisible !== false),
    state: {
      globalFilter,
      rowSelection,
      expanded
    },
    // Filtering
    onGlobalFilterChange,
    enableGlobalFilter,
    globalFilterFn: isFunction(globalFilter) ? globalFilter : 'includesString',

    // Selection
    onRowSelectionChange,

    // Expansion
    onExpandedChange,
    getSubRows,
    getRowId,

    // Models
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),

    //Default config
    enableRowSelection,
    getRowCanExpand,
    filterFromLeafRows: true,
    maxLeafRowFilterDepth,
    enableMultiRowSelection: false,
    enableSubRowSelection: false
  });

  const rawRows = table.getRowModel().rows;

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rawRows.length,
    overscan: 10,
    estimateSize: useCallback(() => 36, [])
  });

  let rows: Row<T>[] | VirtualItem[], paddingTop: number, paddingBottom: number;
  if (disableVirtualization) {
    rows = rawRows;
    paddingTop = 0;
    paddingBottom = 0;
  } else {
    rows = rowVirtualizer.virtualItems;
    paddingTop = rows.length > 0 ? rows?.[0]?.start || 0 : 0;
    paddingBottom =
      rows.length > 0 ? rowVirtualizer.totalSize - (rows?.[rows.length - 1]?.end || 0) : 0;
  }

  const onTableRowClick = (row: Row<T>) => {
    if (!row.getCanExpand()) {
      row.toggleSelected(true);
      return;
    }
    row.toggleExpanded();
    if (!row.getIsExpanded()) {
      onRowExpanded(row.original);
    }
  };

  // Lock height so filtering doesn't shift page
  const [minHeight, setMinHeight] = useState(0);
  useEffect(() => {
    if (lockHeight && !loading && rows.length && !minHeight && tableContainerRef.current) {
      setMinHeight(tableContainerRef.current.clientHeight);
    }
  }, [loading, rows]);

  return (
    <div className={classNames.wrapper} style={{ minHeight }} ref={tableContainerRef}>
      <Table
        className={cn(
          !rows?.length || (loading && showLoadingWhenRowsExist) ? 'inline-table' : 'table-fixed',
          classNames.table
        )}
      >
        <TableBody className="h-full overflow-auto bg-white">
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {(!rows?.length || (loading && showLoadingWhenRowsExist)) && (
            <TableRow className="h-24">
              <TableCell colSpan={columns.length} className="h-full bg-white text-center">
                {loading ? <LoadingDots /> : <span>{emptyMessage}</span>}
              </TableCell>
            </TableRow>
          )}
          {!(loading && showLoadingWhenRowsExist) &&
            rows
              .map<Row<T>>(row =>
                disableVirtualization ? row : rawRows[(row as VirtualItem).index]
              )
              .map(row => {
                return (
                  <Fragment key={row.id}>
                    <TableRow
                      className={cn(
                        'group/row',
                        //row.getCanExpand() || !!(row.index % 2) ? 'bg-gray-50' : 'bg-white',
                        //row.getCanExpand() ? 'cursor-pointer' : 'cursor-default',
                        row.getCanExpand() && 'font-medium',
                        'hover:bg-indigo-50',
                        row.getIsSelected() && 'bg-indigo-100 hover:bg-indigo-100',
                        'snap-start',
                        'mx-2 my-0 block cursor-pointer rounded'
                      )}
                      onClick={() => onTableRowClick(row)}
                    >
                      {row.getVisibleCells().map(cell => (
                        <TableCell key={cell.id} className="block w-full p-2">
                          <div className="space-between flex w-full items-center">
                            <div
                              className={classNames.expander}
                              style={{ paddingLeft: `${row.depth * 24}px` }}
                            >
                              {row.getCanExpand() && (
                                <Icon
                                  name={row.getIsExpanded() ? 'SelectSingle' : 'Disclosure'}
                                  className="h-5 w-5 text-gray-500"
                                />
                              )}
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </div>
                            {row.getIsSelected() && (
                              <Icon name="FastForward" className="text-indigo-500" />
                            )}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </Fragment>
                );
              })}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
