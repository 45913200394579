import * as React from 'react';
import { FieldPath, useFormContext, useWatch } from 'react-hook-form';

import { OneWayCancel, RefreshButton } from '~/components';
import { FieldsetConfiguration, FieldsetUpdate } from '~/generated/graphql';
import { useFieldsetState } from '~/hooks';
import { FieldsetFormValues, hasItems } from '~/utils';

interface Props<ConfType extends Exclude<FieldsetConfiguration, 'ApiConfiguration'>> {
  path: FieldPath<FieldsetFormValues>;
  updateObj: (conf: ConfType | undefined) => { configuration: FieldsetUpdate };
  queryRef: string | undefined | null;
  showRefreshHelper?: boolean;
}

export function ModelQueryButton<
  ConfType extends Exclude<FieldsetConfiguration, 'ApiConfiguration'>
>({ path, updateObj, queryRef, showRefreshHelper = true }: Props<ConfType>) {
  const { fieldset, preventSave, setPreventSave, loading, applyUpdate, abortQuery, abortSignal } =
    useFieldsetState();

  const { control, getValues } = useFormContext<FieldsetFormValues>();

  const watched = useWatch({ control, name: path }) as string;

  const checkEmptyWatch = watched == null || watched === '';
  const isEmpty = React.useMemo(() => checkEmptyWatch, [checkEmptyWatch]);

  const compareWatch = queryRef !== watched;
  const isChanged = React.useMemo(() => compareWatch, [compareWatch]);

  const handleQueryUpdate = React.useCallback(() => {
    const update = updateObj(getValues('configuration') as ConfType);
    applyUpdate({ ...update.configuration }, { refresh: true });
  }, [applyUpdate, getValues, updateObj]);

  React.useLayoutEffect(() => {
    let mounted = true;
    if (isEmpty || isChanged) {
      if (mounted) {
        setPreventSave(true);
      }
    } else {
      if (mounted) {
        setPreventSave(false);
      }
    }
    // cleanup function to re-enable save on unmount
    return () => {
      if (mounted) {
        setPreventSave(false);
      }
      mounted = false;
    };
  }, [isChanged, isEmpty, setPreventSave]);

  return (
    <>
      <OneWayCancel canceled={abortSignal.aborted} loading={loading} onClick={abortQuery} />
      <RefreshButton
        initial={fieldset?.fields.length === 0}
        onExecution={handleQueryUpdate}
        loading={!!loading}
        showRefreshHelper={showRefreshHelper && hasItems(fieldset?.fields) && preventSave}
        disabled={isEmpty}
      />
    </>
  );
}
