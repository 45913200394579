import { useLazyQuery, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { Icon } from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import {
  DatabaseSchemaFragment,
  SchemaMetaDocument,
  SearchSchemaDocument,
  SqlRunnerSchemaV2Fragment
} from '~/generated/graphql';
import {
  markHierarchiesOpen,
  useExpandedSchemas,
  useExpandedSearchedSchemas,
  useSchemaCache
} from '~/hooks';
import { filterByExpanded, flattenSchema, handleHierarchy, hasItems } from '~/utils';
import { Search } from '../../components';
import { RunnerList } from './runner-list';

interface Props {
  connectionId: string;
  refreshLoading: boolean;
}

export const SchemaExplorer = memo<Props>(({ connectionId, refreshLoading }) => {
  const [search, setSearch] = useState<string | undefined>();
  const [searchKey, setSearchKey] = useState('normal');
  // Because Apollo will clear the `data` return of a query when a new one is loading,
  // we need state to continue showing the old search list until the new list returns.
  const [searchedItems, setSearchedItems] = useState<Array<
    SqlRunnerSchemaV2Fragment | DatabaseSchemaFragment
  > | null>(null);
  const [searchSchema, { loading: searchLoading }] = useLazyQuery(SearchSchemaDocument, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (!data?.searchSchema) {
        return;
      }
      setSearchKey(search || 'normal');
      const items = handleHierarchy(data.searchSchema);
      markHierarchiesOpen(items);
      setSearchedItems(items);
    }
  });

  const handleSearch = useCallback(
    (search: string | undefined) => {
      setSearch(search);
      if (isEmpty(search)) {
        setSearchedItems(null);
        return;
      }
      void searchSchema({ variables: { connectionId, search } });
    },
    [connectionId, searchSchema]
  );
  const handleResetSearch = useCallback(() => {
    setSearch(undefined);
    setSearchedItems(null);
    setSearchKey('normal');
  }, []);

  const { data, loading: infoMetaLoading } = useQuery(SchemaMetaDocument, {
    variables: { connectionId }
  });
  const infoMeta = useMemo(() => data?.schemaInfoMeta, [data?.schemaInfoMeta]);

  const { schema, loading } = useSchemaCache(connectionId);
  const expanded = useExpandedSchemas(connectionId);
  const expandedSearched = useExpandedSearchedSchemas();

  const searchedFlat = useMemo(() => {
    return flattenSchema(
      filterByExpanded(searchedItems || [], expandedSearched, infoMeta?.supportsDatabaseSelection)
    );
  }, [searchedItems, expandedSearched, infoMeta?.supportsDatabaseSelection]);

  const expandedFlat = useMemo(
    () => flattenSchema(filterByExpanded(schema, expanded, infoMeta?.supportsDatabaseSelection)),
    [expanded, infoMeta?.supportsDatabaseSelection, schema]
  );

  // if user searches on standard data
  // - wait to re-render list until search data returns
  // if user searches on previous searched list
  // - wait to re-render list until search data returns
  let items = expandedFlat;
  if (searchLoading) {
    if (searchedItems != null && search) {
      items = searchedFlat;
    }
  } else {
    if (search) {
      items = searchedFlat;
    }
  }

  const isLoading = loading || infoMetaLoading || refreshLoading;

  return !search && isLoading ? (
    <div className="flex h-full grow content-center items-center justify-center">
      <LoadingDots />
    </div>
  ) : (
    <div className="flex h-full min-h-full flex-col">
      <div className="z-10 border-b border-gray-300 bg-white p-2">
        <Search
          loading={searchLoading}
          defaultValue={search}
          onChange={handleSearch}
          onReset={handleResetSearch}
          debounce
          delay={650}
        />
      </div>
      {hasItems(items) ? (
        <RunnerList
          key={searchKey}
          connectionId={connectionId}
          items={items}
          infoMeta={infoMeta}
          expanded={expanded}
          expandedSearched={expandedSearched}
          search={searchedItems != null && search ? search : undefined}
        />
      ) : searchLoading ? (
        <div className="flex h-20 w-full items-center justify-center">
          <LoadingDots />
        </div>
      ) : search ? (
        <p className="p-6 text-gray-500">Nothing matches your search query.</p>
      ) : (
        <div className="p-4">
          <Icon name="InfoFilled" className="mb-1 h-5 w-5 text-gray-400" />
          <p className="text-sm text-gray-500">Schema not found.</p>
        </div>
      )}
    </div>
  );
});

if (import.meta.env.MODE === 'development') {
  SchemaExplorer.displayName = 'SchemaExplorer';
}
