import * as PropTypes from 'prop-types';
import * as React from 'react';
// import { useAmplitudeContext, AmplitudeContext } from './AmplitudeProvider';

import { AmplitudeClient, Config } from 'amplitude-js';

export function isValidAmplitudeInstance(maybeInstance: any) {
  return (
    !!maybeInstance &&
    typeof maybeInstance.init === 'function' &&
    typeof maybeInstance.logEvent === 'function'
  );
}

declare type AmplitudeProviderProps = {
  ///
  // should be a AmplitudeClient -- but not requiring this
  amplitudeInstance: any;
  ///
  // Pass empty "" if you're testing/development
  apiKey: string;
  ///
  // User ID to identify this session with
  userId?: string;
  config?: Config;
  children: React.ReactNode;
};

declare type AmplitudeContextType = {
  amplitudeInstance?: AmplitudeClient;
  getParentContext?(): AmplitudeContextType;
  eventProperties?: any;
};

export const AmplitudeContext = React.createContext<AmplitudeContextType>({
  eventProperties: {}
});

export function useAmplitudeContext() {
  return React.useContext(AmplitudeContext);
}

function initAmplitude(
  amplitudeInstance: AmplitudeClient,
  apiKey: string,
  userId?: string,
  config?: Config
) {
  return () => {
    if (isValidAmplitudeInstance(amplitudeInstance)) {
      if (apiKey) {
        amplitudeInstance.init(apiKey, undefined, config);
      }
      if (userId) {
        amplitudeInstance.setUserId(userId);
      }
    }
  };
}

export function AmplitudeProvider(props: AmplitudeProviderProps) {
  const { amplitudeInstance, apiKey, userId, config } = props;

  // Memoize so it's only really called if the params change
  const init = React.useMemo(
    () => initAmplitude(amplitudeInstance, apiKey, userId, config),
    [amplitudeInstance, apiKey, userId, config]
  );

  // We need to init such that LogOnMount is happy
  init();

  return (
    <AmplitudeContext.Provider
      value={{
        amplitudeInstance: props.amplitudeInstance,
        eventProperties: {}
      }}
    >
      {props.children}
    </AmplitudeContext.Provider>
  );
}

AmplitudeProvider.propTypes = {
  amplitudeInstance: PropTypes.object.isRequired,
  apiKey: PropTypes.string,
  userId: PropTypes.string,
  config: PropTypes.object
};

type AmplitudeProps = {
  children: Function | React.ReactNode;
  eventProperties?: object | Function;
  instanceName?: string;
  userProperties?: object;
};

export type Callback = (
  responseCode: number,
  responseBody: string,
  details?: { reason: string }
) => void;

export function useAmplitude(eventProperties: object = {}, instanceName = '$default_instance') {
  const { amplitudeInstance, eventProperties: inheritedProperties } = useAmplitudeContext();

  return React.useMemo(() => {
    function logEvent<T extends string>(
      eventType: T,
      eventPropertiesIn: object = {},
      callback?: Callback
    ) {
      if (!amplitudeInstance) {
        return;
      }

      let computed = inheritedProperties;
      if (typeof eventProperties === 'function') {
        computed = eventProperties(computed);
      } else {
        computed = { ...computed, ...(eventProperties || {}) };
      }
      if (typeof eventPropertiesIn === 'function') {
        computed = eventPropertiesIn(computed);
      } else {
        computed = { ...computed, ...(eventPropertiesIn || {}) };
      }

      amplitudeInstance.logEvent(eventType, computed, callback);
    }

    function instrument<T extends Function>(eventType: string, func: T): T {
      function fn(...params: any) {
        const retVal = func ? func(...params) : undefined;
        logEvent(eventType);
        return retVal;
      }
      return fn as any;
    }

    return {
      logEvent: logEvent,
      instrument: instrument,
      eventProperties: inheritedProperties,
      amplitudeInstance: amplitudeInstance
    };
  }, [eventProperties, amplitudeInstance, inheritedProperties, instanceName]);
}

export function Amplitude(props: AmplitudeProps) {
  const { logEvent, instrument, eventProperties, amplitudeInstance } = useAmplitude(
    undefined,
    props.instanceName
  );

  // This is API compatible with Amplitude's API, but weird when you think about it
  React.useMemo(
    () => () => {
      if (props.userProperties && amplitudeInstance) {
        amplitudeInstance.setUserProperties(props.userProperties);
      }
    },
    [props.userProperties, amplitudeInstance]
  )();

  // If we're not providing any additional properties, just get out of the way and call the component
  if (!eventProperties) {
    return typeof props.children === 'function'
      ? props.children({ logEvent, instrument })
      : props.children || null;
  }

  // Memoizes the value prop object to avoid re-renders when eventProperties or amplitudeInstance don't change
  const value = React.useMemo(
    () => ({
      eventProperties: { ...eventProperties, ...(props.eventProperties || {}) },
      amplitudeInstance
    }),
    [props.eventProperties, amplitudeInstance]
  );

  return (
    <AmplitudeContext.Provider value={value}>
      {typeof props.children === 'function'
        ? props.children({ logEvent, instrument })
        : props.children || null}
    </AmplitudeContext.Provider>
  );
}

Amplitude.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  eventProperties: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  debounceInterval: PropTypes.number,
  instanceName: PropTypes.string,
  userProperties: PropTypes.object
};

type LogOnMountProps = {
  eventProperties?: object | Function;
  eventType: string;
  instanceName?: string;
  children?: React.ReactNode;
};

export const LogOnMount: React.StatelessComponent<LogOnMountProps> = (props: LogOnMountProps) => {
  const { logEvent } = useAmplitude(undefined, props.instanceName);

  React.useEffect(() => {
    logEvent(props.eventType, props.eventProperties);
  }, []);

  return props.children || (null as any);
};

LogOnMount.propTypes = {
  eventProperties: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  eventType: PropTypes.string.isRequired,
  instanceName: PropTypes.string
};
