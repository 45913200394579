import cx from 'clsx';
import { generatePath, Link } from 'react-router-dom';

import { SideBySide } from '~/components';
import { useFieldsetState } from '~/hooks';
import { routes } from '~/utils';

export function InboundJoins() {
  const { fieldset } = useFieldsetState();

  if (!fieldset || !fieldset?.relatedFrom || fieldset?.relatedFrom?.length === 0) {
    return null;
  }
  return (
    <SideBySide hasSectionWrap heading="Inbound joins">
      <ul className={cx('space-y-2', fieldset?.relatedFrom?.length > 1 && 'list-disc')}>
        {fieldset?.relatedFrom?.map(({ from, to }) => (
          <li key={from?.id}>
            <Link
              to={generatePath(routes.editModel, { fieldsetId: from?.fieldset?.id })}
              target="_blank"
              rel="noopener noreferrer"
              className="link text-sm"
            >
              {`${from?.fieldset?.name}.${from?.label} → ${to?.label}`}
            </Link>
          </li>
        ))}
      </ul>
    </SideBySide>
  );
}
