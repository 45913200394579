import * as React from 'react';

import { Action } from '../generated/graphql';
import { Permission, PermissionProps } from './permission';

export function EditPermission({ children, fallback }: Omit<PermissionProps, 'type'>) {
  return (
    <Permission hideTooltip={true} type={Action.Edit} fallback={fallback}>
      {children}
    </Permission>
  );
}
