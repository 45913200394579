import { Icon } from '~/components';
import { LoginWrapper } from '~/pages/login/login-wrapper';

const wrapperStyles = 'grid justify-center max-h-52 gap-4 -mt-0.5 py-1 pb-2 overflow-y-auto';

export function SSOError() {
  const params = new URLSearchParams(document.location.search);
  const error = params.get('error');

  return (
    <LoginWrapper>
      <div className={wrapperStyles}>
        <div className="z-20 flex flex-col items-center rounded-md border border-gray-300 bg-gray-100 px-4 pt-3 pb-4">
          <Icon name="DangerFilled" className="mb-1 h-8 w-8 text-orange-500" />
          <p>{error || 'An error occurred during single sign-on.'}</p>
          <p className="mt-1">
            <a href={`${import.meta.env.VITE_API_URL || ''}/logout`} className="link">
              ← Back to sign in
            </a>
          </p>
        </div>
      </div>
    </LoginWrapper>
  );
}
