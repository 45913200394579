import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import Select, { components, createFilter, SingleValueProps } from 'react-select';
import { FieldType } from '../../generated/graphql';
import { DropdownIndicator, fieldTypeIconName, selectStyles } from '../../utils';
import { Icon } from '~/components';
import { IconName } from '~/assets';

function formatOptionLabel({ value, label }: { value: FieldType; label: IconName }) {
  return (
    <span className="flex space-x-2">
      <Icon name={label} className="h-5 w-5 text-gray-500" />
      <span>{value}</span>
    </span>
  );
}

function SingleValue(props: SingleValueProps<{ value: FieldType; label: IconName }>) {
  return (
    <components.SingleValue {...props}>
      <span className="flex space-x-2">
        <Icon name={props.data.label} className="h-5 w-5 text-gray-500" />
        <span>{props.data.value || 'Not specified'}</span>
      </span>
    </components.SingleValue>
  );
}

interface Props<TFormValues extends FieldValues> {
  name: Path<TFormValues>;
  control: Control<TFormValues>;
}

export function FieldTypeSelect<TFormValues extends FieldValues>(props: Props<TFormValues>) {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <Select
          autoFocus={false}
          filterOption={createFilter({ ignoreAccents: false })}
          placeholder="Select type..."
          components={{
            SingleValue,
            DropdownIndicator,
            ClearIndicator: null,
            IndicatorSeparator: null
          }}
          value={
            field.value
              ? {
                  value: field.value as FieldType,
                  label: fieldTypeIconName(field.value as string)
                }
              : null
          }
          options={Object.values(FieldType)
            .map(type => ({
              value: type,
              label: fieldTypeIconName(type)
            }))
            .filter(({ value }) => value !== FieldType.Unknown)}
          onChange={(option: { label: IconName; value: FieldType } | null) => {
            if (!option) {
              return;
            }
            field.onChange(option.value);
          }}
          formatOptionLabel={formatOptionLabel}
          className="w-full max-w-xs"
          styles={selectStyles}
        />
      )}
    />
  );
}
