import * as React from 'react';

export function useAbortQuery() {
  const [controller, setController] = React.useState(new AbortController());

  const abortQuery = React.useCallback(() => {
    controller.abort();
  }, [controller]);

  const resetAbortQuery = React.useCallback(() => {
    setController(new AbortController());
  }, []);

  return { signal: controller.signal, abortQuery, resetAbortQuery };
}
