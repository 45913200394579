import {
  ExpandedState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingState,
  Table as TanstackTable,
  useReactTable
} from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './Table';
import { cn } from '~/lib/utils';
import { Icon } from '../Icon';
import LoadingDots from '../v2/feedback/LoadingDots';
import Checkbox from '../v2/inputs/Checkbox';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, isFunction } from 'lodash';

import { tv } from 'tailwind-variants';
import { getClassNames, type VariantProps } from '~/lib/utils';
import { useVirtual, VirtualItem } from 'react-virtual';
import { ColumnDef } from './DataTableVirtual';

const tableVariants = tv({
  slots: {
    wrapper: 'max-h-full overflow-auto rounded-md border border-gray-300',
    table: 'w-full',
    expander: 'flex flex-1 items-center overflow-hidden',
    row: 'group/row snap-start hover:bg-indigo-50'
  }
});

type SelectionState = 'none' | 'partial' | 'all';

export interface DataTableProps<T> extends VariantProps<typeof tableVariants> {
  columns: ColumnDef<T>[];
  data: T[];
  loading?: boolean;
  estimateSize?: (index: number) => number;

  // Filtering
  globalFilter?: string | FilterFn<T>;
  onGlobalFilterChange?: OnChangeFn<string>;

  // Sorting
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  enableSorting?: boolean;

  // Selection
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  getSelectionState?: (originalRow: T) => SelectionState;
  setSelectionState?: (originalRow: T, state: SelectionState) => void;

  // Expansion
  expanded?: ExpandedState;
  onExpandedChange?: OnChangeFn<ExpandedState>;
  onRowExpanded?: (originalRow: T) => Promise<void>;
  getSubRows?: (originalRow: T) => T[];
  getRowId?: (originalRow: T) => string;

  // Events
  onRowClick?: (originalRow: T) => void;

  // Extra
  isRowActive?: (originalRow: T) => boolean;

  // Options
  emptyMessage?: string;
  maxLeafRowFilterDepth?: number;
  disableVirtualization?: boolean;
  showLoadingWhenRowsExist?: boolean;
  lockHeight?: boolean;
}

export function DataTable<T>({
  columns = [],
  data = [],
  loading = false,
  estimateSize,
  globalFilter = '',
  onGlobalFilterChange,
  sorting = [],
  onSortingChange,
  enableSorting = true,
  rowSelection = {},
  onRowSelectionChange,
  getSelectionState = () => null,
  setSelectionState = () => null,
  expanded = {},
  onExpandedChange,
  onRowExpanded = () => null,
  getSubRows,
  getRowId,
  onRowClick,
  emptyMessage = 'No results.',
  maxLeafRowFilterDepth = 1,
  disableVirtualization = false,
  showLoadingWhenRowsExist = false,
  lockHeight = false,
  isRowActive,
  ...rest
}: DataTableProps<T>) {
  const classNames = getClassNames(tableVariants, rest);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const enableRowSelection = isFunction(onRowSelectionChange);
  const enableGlobalFilter = isFunction(onGlobalFilterChange);

  const table = useReactTable({
    data,
    columns: columns.filter(column => column.isVisible !== false),
    state: {
      sorting,
      globalFilter,
      rowSelection,
      expanded
    },
    // Filtering
    onGlobalFilterChange,
    enableGlobalFilter,
    globalFilterFn: isFunction(globalFilter) ? globalFilter : 'includesString',

    // Sorting
    enableSorting,
    onSortingChange,

    // Selection
    onRowSelectionChange,

    // Expansion
    onExpandedChange,
    getSubRows,
    getRowId,

    // Models
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),

    //Default config
    enableRowSelection: row => !(row.subRows && row.subRows.length > 0),
    getRowCanExpand: row => !isEmpty(getSelectionState(row.original)) || !!row.subRows?.length,
    filterFromLeafRows: true,
    maxLeafRowFilterDepth
  });

  // Hide rows where selection state exists on the parent, as they could be partially hydrated, but still needed for filtering
  const rawRows = table
    .getRowModel()
    .rows.filter(
      row => !(row.getParentRow() && !isEmpty(getSelectionState(row.getParentRow()?.original)))
    );

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rawRows.length,
    overscan: 10,
    estimateSize: useCallback(isFunction(estimateSize) ? estimateSize : () => 64, [])
  });

  let rows: Row<T>[] | VirtualItem[], paddingTop: number, paddingBottom: number;
  if (disableVirtualization) {
    rows = rawRows;
    paddingTop = 0;
    paddingBottom = 0;
  } else {
    rows = rowVirtualizer.virtualItems;
    paddingTop = rows.length > 0 ? rows?.[0]?.start || 0 : 0;
    paddingBottom =
      rows.length > 0 ? rowVirtualizer.totalSize - (rows?.[rows.length - 1]?.end || 0) : 0;
  }

  const onTableRowClick = (row: Row<T>) => {
    onRowClick?.(row.original);

    if (!row.getCanExpand()) {
      return;
    }
    row.toggleExpanded();
    if (!row.getIsExpanded()) {
      onRowExpanded(row.original);
    }
  };

  // Lock height so filtering doesn't shift page
  const [minHeight, setMinHeight] = useState(0);
  useEffect(() => {
    if (lockHeight && !loading && rows.length && !minHeight && tableContainerRef.current) {
      setMinHeight(tableContainerRef.current.clientHeight);
    }
  }, [loading, rows]);

  return (
    <div className={classNames.wrapper} style={{ minHeight }} ref={tableContainerRef}>
      <Table
        className={cn(
          !rows?.length || (loading && showLoadingWhenRowsExist) ? 'inline-table' : 'table-fixed',
          classNames.table
        )}
      >
        <TableHeader className="sticky top-0">
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => {
                return (
                  <TableHead
                    key={header.id}
                    className={cn(header.column.getCanSort() && 'cursor-pointer select-none')}
                    style={{ width: header.column.getSize() }}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div
                      className={cn(
                        ((enableRowSelection && i === 0) || header.column.getCanSort()) &&
                          'flex items-center space-x-2'
                      )}
                    >
                      {enableRowSelection && i === 0 && (
                        <HeaderCheckbox
                          table={table}
                          data={data}
                          getRowId={getRowId}
                          getSubRows={getSubRows}
                          rowSelection={rowSelection}
                          getSelectionState={getSelectionState}
                          setSelectionState={setSelectionState}
                        />
                      )}

                      {flexRender(header.column.columnDef.header, header.getContext())}

                      {!!header.column.getIsSorted() && (
                        <Icon
                          name="ArrowNarrowRight"
                          className={cn([
                            'h-3.5 w-3.5 transition-all',
                            header.column.getIsSorted() === 'asc' && 'rotate-90',
                            header.column.getIsSorted() === 'desc' && '-rotate-90'
                          ])}
                        />
                      )}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="overflow-auto">
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {(!rows?.length || (loading && showLoadingWhenRowsExist)) && (
            <TableRow className="h-24">
              <TableCell colSpan={columns.length} className="h-full bg-white text-center">
                {loading ? <LoadingDots /> : <span>{emptyMessage}</span>}
              </TableCell>
            </TableRow>
          )}
          {!(loading && showLoadingWhenRowsExist) &&
            rows.map(_row => {
              const row = disableVirtualization ? _row : rawRows[(_row as VirtualItem).index];
              return (
                <Fragment key={row.id}>
                  <TableRow
                    className={cn(
                      row.getCanExpand() || !!(_row.index % 2) ? 'bg-gray-50' : 'bg-white',
                      row.getCanExpand() ? 'cursor-pointer' : 'cursor-default',
                      row.getCanExpand() && row.depth == 0
                        ? 'border-t border-b-0 first:border-t-0'
                        : 'border-none',
                      isRowActive?.(row.original) && 'bg-indigo-50 hover:bg-indigo-50',
                      classNames.row
                    )}
                    onClick={() => onTableRowClick(row)}
                  >
                    {row.getVisibleCells().map((cell, i) => (
                      <TableCell key={cell.id} style={{ width: cell.column.getSize() }}>
                        {i === 0 ? (
                          <div className="flex items-center space-x-2">
                            {enableRowSelection && (
                              <RowCheckbox
                                row={row}
                                getRowId={getRowId}
                                getSubRows={getSubRows}
                                rowSelection={rowSelection}
                                getSelectionState={getSelectionState}
                                setSelectionState={setSelectionState}
                              />
                            )}
                            <div
                              className={classNames.expander}
                              style={{ paddingLeft: `${row.depth * 24}px` }}
                            >
                              {row.getCanExpand() && (
                                <Icon
                                  name={row.getIsExpanded() ? 'SelectSingle' : 'Disclosure'}
                                  className="h-5 w-5 text-gray-500"
                                />
                              )}
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </div>
                          </div>
                        ) : (
                          flexRender(cell.column.columnDef.cell, cell.getContext())
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  {getSelectionState(row.original) && row.getIsExpanded() && (
                    <TableRow className="bg-gray-50 text-center">
                      <TableCell colSpan={row.getVisibleCells().length}>
                        <LoadingDots />
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

function getAllDescendentRows<T>(
  rows: T[],
  getSubRows: (originalRow: T) => T[],
  getSelectionState: (originalRow: T) => SelectionState,
  all = false
): T[] {
  return rows.reduce((acc, row) => {
    const subRows = getSubRows?.(row) ?? [];
    if (!subRows?.length || getSelectionState(row) === 'partial') {
      return acc.concat(row);
    }
    return acc.concat(
      all ? [row] : [],
      getAllDescendentRows(subRows, getSubRows, getSelectionState, all)
    );
  }, []);
}
interface HeaderCheckboxProps<T> {
  table: TanstackTable<T>;
  data: T[];
  getSubRows: (originalRow: T) => T[];
  getRowId: (originalRow: T) => string;
  rowSelection: RowSelectionState;
  getSelectionState?: (originalRow: T) => SelectionState;
  setSelectionState?: (originalRow: T, state: SelectionState) => void;
}

function HeaderCheckbox<T>({
  table,
  data,
  getSubRows,
  getRowId,
  rowSelection,
  getSelectionState,
  setSelectionState
}: HeaderCheckboxProps<T>) {
  const subRows = useMemo(
    () => getAllDescendentRows(data, getSubRows, getSelectionState),
    [data, getSubRows, getSelectionState]
  );
  const everyRowSelected = useMemo(
    () =>
      subRows.length &&
      subRows.every(row => getSelectionState(row) === 'all' || rowSelection[getRowId?.(row)]),
    [subRows, rowSelection, getSelectionState, getRowId]
  );
  const someRowsSelected = useMemo(
    () =>
      !everyRowSelected &&
      subRows.some(
        row =>
          getSelectionState(row) === 'all' ||
          getSelectionState(row) === 'partial' ||
          rowSelection[getRowId?.(row)]
      ),
    [subRows, rowSelection, getSelectionState, getRowId]
  );

  return (
    <Checkbox
      checked={everyRowSelected}
      onChange={v => {
        getAllDescendentRows(data, getSubRows, getSelectionState, true).forEach(row => {
          if (!isEmpty(getSelectionState(row))) {
            setSelectionState(row, !!v.target.checked ? 'all' : 'none');
          }
        });
        table.toggleAllRowsSelected();
      }}
      onClick={e => e.stopPropagation()}
      indeterminate={someRowsSelected}
      variant="indeterminate"
    />
  );
}

interface RowCheckboxProps<T> {
  row: Row<T>;
  getSubRows: (originalRow: T) => T[];
  getRowId: (originalRow: T) => string;
  rowSelection: RowSelectionState;
  getSelectionState?: (originalRow: T) => SelectionState;
  setSelectionState?: (originalRow: T, state: SelectionState) => void;
}

function RowCheckbox<T>(props: RowCheckboxProps<T>) {
  const { row, getSubRows, getRowId, rowSelection, getSelectionState, setSelectionState } = props;
  const subRows = useMemo(
    () => getAllDescendentRows([row.original], getSubRows, getSelectionState),
    [row.original, getSubRows, getSelectionState]
  );
  const everyRowSelected = useMemo(
    () =>
      subRows.length &&
      subRows.every(row => getSelectionState(row) === 'all' || rowSelection[getRowId?.(row)]),
    [subRows, rowSelection, getSelectionState, getRowId]
  );
  const someRowsSelected = useMemo(
    () =>
      !everyRowSelected &&
      subRows.some(
        row =>
          getSelectionState(row) === 'all' ||
          getSelectionState(row) === 'partial' ||
          rowSelection[getRowId?.(row)]
      ),
    [everyRowSelected, subRows, getSelectionState, rowSelection, getRowId]
  );

  return (
    <Checkbox
      checked={row.getIsSelected() || everyRowSelected}
      onChange={v => {
        // This code will correct selection state when child rows are loaded async
        if (row.subRows.length || !isEmpty(getSelectionState(row.original))) {
          [row.original, ...row.subRows.map(row => row.original)].forEach(row => {
            if (!isEmpty(getSelectionState(row))) {
              setSelectionState(row, !!v.target.checked ? 'all' : 'none');
            }
          });
          row.toggleSelected(!everyRowSelected);
        } else {
          row.toggleSelected();
        }
      }}
      onClick={e => e.stopPropagation()}
      indeterminate={someRowsSelected || getSelectionState(row.original) === 'partial'}
      variant={row.subRows.length ? 'indeterminate' : 'determinate'}
    />
  );
}
