import { syntaxTree } from '@codemirror/language';
import { EditorState } from '@codemirror/state';
import { SyntaxNode, Tree } from '@lezer/common';
import { EditorView } from 'codemirror';

function getLineAt(state: EditorState, pos: number) {
  return state.doc.lineAt(pos).number;
}

function isAnchorBeforeEnd(node: SyntaxNode | null, anchor: number) {
  return node && anchor <= node.to;
}

function isAnchorAfterStart(node: SyntaxNode | null, anchor: number) {
  return node && anchor >= node.from;
}

function isAnchorBeforeStart(node: SyntaxNode | null, anchor: number) {
  return node && anchor <= node.from;
}

function isAnchorAfterEnd(node: SyntaxNode | null, anchor: number) {
  return node && anchor >= node.to;
}

// function startCloserToAnchor(targetNode: SyntaxNode, anchor: number, prevNode: SyntaxNode | null) {
//     if (!isAnchorBeforeStart(targetNode, anchor)) {
//         return false
//     }
//     if (!prevNode) {
//         return true
//     }
//     const prevStart = prevNode.from
//     const targetStart = targetNode.from
//     return targetStart - anchor <= prevStart - anchor
// }

function endCloserToAnchor(targetNode: SyntaxNode, anchor: number, prevNode: SyntaxNode | null) {
  if (!isAnchorAfterEnd(targetNode, anchor)) {
    return false;
  }
  if (!prevNode) {
    return true;
  }
  const prevEnd = prevNode.to;
  const targetEnd = targetNode.to;
  return anchor - targetEnd <= anchor - prevEnd;
}

function recurseParents(node: SyntaxNode | null): SyntaxNode | null {
  if (node == null) {
    return null;
  }
  if (node.name === 'Statement') {
    return node;
  }
  return recurseParents(node.parent);
}

function getStatements(tree: Tree) {
  const statements: SyntaxNode[] = [];
  tree?.iterate({
    enter: ({ node }) => {
      if (node.name === 'Statement') {
        statements.push(node);
      }
    }
  });
  if (statements.length === 0) {
    return null;
  }
  return statements;
}

function checkStatementsAtLine(
  statements: SyntaxNode[] | null,
  anchor: number,
  lineAt: (pos: number) => number
): SyntaxNode | null {
  if (!statements) {
    return null;
  }
  const anchorLine = lineAt(anchor);
  // Filter out the statements not starting or ending on the same line as anchor
  const sameLine = statements.filter(
    node => anchorLine === lineAt(node.from) || anchorLine === lineAt(node.to)
  );
  if (sameLine.length === 0) {
    return null;
  }
  // If there is just one match, use it and move on.
  // Or, use the closest match that's beyond the anchor, which will be the
  // first node in the arr since the anchor is before all those on that line
  if (sameLine.length === 1 || isAnchorBeforeStart(sameLine[0], anchor)) {
    return sameLine[0];
  }
  // Loop over sameLine to find the node's end that's closest to the anchor
  let nearest: SyntaxNode | null = null;
  for (const node of sameLine) {
    if (
      // startCloserToAnchor(node, anchor, nearest) ||
      endCloserToAnchor(node, anchor, nearest)
    ) {
      nearest = node;
    }
  }
  return nearest;
}

function handleNodes(state: EditorState) {
  const lineAt = (pos: number) => getLineAt(state, pos);
  const { anchor } = state.selection.main;

  const tree = syntaxTree(state);
  const prev = tree.resolveInner(anchor, -1);
  const next = tree.resolveInner(anchor, 1);

  const statements = getStatements(tree);
  const firstStatement = statements ? statements[0] : null;
  const lastStatement = statements ? statements[statements.length - 1] : null;

  let node: SyntaxNode | undefined;
  if (anchor === 0 || prev.name === 'Script') {
    node = next;
  } else {
    node = prev;
  }
  // it returned the whole doc, so we need to dig in and find a statement
  if (node.name === 'Script') {
    // handles if first and last child are the same node
    if (
      node.firstChild &&
      node.lastChild &&
      node.firstChild.from === node.lastChild.from &&
      node.firstChild.to === node.lastChild.to
    ) {
      const parent = recurseParents(node.firstChild);
      if (parent) {
        return parent;
      }
    }
    const sameLine = checkStatementsAtLine(statements, anchor, lineAt);
    if (sameLine) {
      return sameLine;
    }
    if (isAnchorBeforeEnd(firstStatement, anchor)) {
      return firstStatement;
    }
    if (isAnchorAfterStart(lastStatement, anchor)) {
      return lastStatement;
    }
    return null;
  }
  const parent = recurseParents(node);
  if (parent) {
    return parent;
  }
  const sameLine = checkStatementsAtLine(statements, anchor, lineAt);
  if ((node.name === 'BlockComment' || node.name === 'LineComment') && sameLine) {
    return sameLine;
  }
  if (isAnchorBeforeEnd(firstStatement, anchor)) {
    return firstStatement;
  }
  if (isAnchorAfterStart(lastStatement, anchor)) {
    return lastStatement;
  }
  return null;
}

export function handleWorksheet(state: EditorState) {
  if (state.doc.toString() === '') {
    return null;
  }
  const node = handleNodes(state);
  if (node) {
    return state.sliceDoc(node.from, node.to);
  }
  return null;
}

export function handleInsertQuery(view: EditorView | null, str: string) {
  if (!view || view.state.doc.toString() === str) {
    return;
  }
  const { to, from } = view.state.selection.ranges[0];
  const target = to === from ? `${str} ` : str;
  view.dispatch({
    changes: { from, to, insert: target },
    selection: { anchor: from + target.length },
    scrollIntoView: true
  });
}

export function handleReplaceQuery(view: EditorView | null, str: string) {
  if (!view || view.state.doc.toString() === str) {
    return;
  }
  view.dispatch({
    changes: { from: 0, to: view.state.doc.length, insert: str },
    selection: { anchor: str.length },
    scrollIntoView: true
  });
}
