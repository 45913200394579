import { useFieldsetState } from '~/hooks';
import { hasItems } from '~/utils';
import { Enrichment } from './enrichment';
import { PrimaryKey } from './primary-key';
import { JoinToModel } from './join-to-model';
import { InboundJoins } from './inbound-joins';
import { SyncsUsingFieldset } from './syncs-using-fieldset';

export function AdditionalConfig() {
  const { fieldset } = useFieldsetState();

  if (!hasItems(fieldset?.fields)) {
    return null;
  }
  return (
    <>
      <Enrichment />
      <PrimaryKey />
      <JoinToModel />
      <InboundJoins />
      <SyncsUsingFieldset />
    </>
  );
}
