import { useQuery } from '@apollo/client';
import * as React from 'react';

import { FieldsetFragment, ModelDocument } from '../generated/graphql';
import { GetReachableFields, getReachableList } from '../utils';
import { ModelField, useModelFields } from './use-model-fields';

export function useReachableFields(): GetReachableFields {
  const { data } = useQuery(ModelDocument);
  const { modelFields } = useModelFields();
  const cache = React.useMemo(() => {
    if (!data?.model) {
      return { empty: [] };
    }
    return getReachableList(data.model.fieldsets as FieldsetFragment[]);
  }, [data?.model]);

  const result = React.useCallback(
    (fieldsetId?: string) => {
      if (!fieldsetId) {
        return {
          reachableFields: [],
          reachableFieldsetIds: []
        };
      }
      const reachableFields =
        modelFields.filter(field =>
          cache[fieldsetId]?.includes((field as ModelField)?.fieldset?.id)
        ) ?? [];
      return {
        reachableFields,
        reachableFieldsetIds: cache[fieldsetId]?.filter(Boolean) ?? []
      };
    },
    [cache]
  );
  return result;
}
