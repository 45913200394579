import { useLazyQuery } from '@apollo/client';
import { json } from '@codemirror/lang-json';
import * as React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Button,
  Checkbox,
  DisabledSelect,
  Editor,
  EditPermission,
  JsonViewer,
  Label,
  LinkableIcon,
  LinkButton,
  Lister,
  MiddleEllipsis,
  MyCombobox,
  MyInput,
  ParamButton,
  Section,
  SideBySide,
  TableTopper,
  TableWrap
} from '~/components';
import ErrorText from '~/components/v2/feedback/ErrorText';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import {
  ApiConfiguration,
  HttpMethod,
  JsonPathResultDocument,
  NextSource,
  ParameterLocation,
  TokenPagination
} from '~/generated/graphql';
import { useFieldsetState, useModelQueryRef, useToggle } from '~/hooks';
import {
  FieldsetFormValues,
  flatToSelectable,
  getApiLocationLabel,
  getApiPaginationLabel,
  hasItems,
  sanitizeApiConfig
} from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { ApiParam } from '../components/api-param';
import { FieldsTable } from '../components/fields-table';

const MAX_RESPONSE_SIZE = 100000;

const paginationOptions = [
  { value: 'Unconfigured', label: '(None)' },
  {
    value: 'NextPagePagination',
    label: 'Next Page URL'
  },
  { value: 'TokenPagination', label: 'Token' },
  {
    value: 'SequentialPagePagination',
    label: 'Sequential Pages'
  },
  { value: 'OffsetPagination', label: 'Offset' }
];

const methodOptions = [
  {
    value: 'GET',
    label: 'GET'
  },
  { value: 'POST', label: 'POST' }
];

const paramOptions = [
  {
    value: ParameterLocation.QueryString,
    label: getApiLocationLabel(ParameterLocation.QueryString)
  },
  { value: ParameterLocation.Header, label: getApiLocationLabel(ParameterLocation.Header) },
  { value: ParameterLocation.Body, label: getApiLocationLabel(ParameterLocation.Body) }
];

const sourceOptions = [
  {
    value: 'body',
    label: 'Response body'
  },
  { value: 'header', label: 'Response header' }
];

const HelperText = (props: React.ComponentPropsWithoutRef<'p'>) => (
  <p className="mt-1 text-xs text-gray-500">{props.children}</p>
);

const jsonExamples = (
  <a
    href="https://docs.polytomic.com/docs/using-json-path-for-model-creation"
    target="_blank"
    className="link"
    rel="noopener noreferrer"
  >
    JSON Path examples
  </a>
);

export function ApiFieldsetConfig() {
  const { fieldset, loading, applyUpdate, refreshing } = useFieldsetState();

  const { control, register, getValues, setValue, resetField } =
    useFormContext<FieldsetFormValues>();

  const [showTokenTransform, toggleTokenTransform] = useToggle(
    !!((fieldset?.configuration as ApiConfiguration)?.pagination as TokenPagination)
      ?.tokenTransformation
  );

  const defaultDoc = (fieldset?.configuration as ApiConfiguration)?.body;
  const bodyRef = useModelQueryRef('body', defaultDoc);

  const method = useWatch({ control, name: 'configuration.method' });
  const paginationType = useWatch({ control, name: 'configuration.pagination.__typename' });
  const nextPageSource = useWatch({ control, name: 'configuration.pagination.source' });
  const nextTokenLocation = useWatch({
    control,
    name: 'configuration.pagination.sendTokenAs.location'
  });

  const sampleResponse = React.useMemo(
    () => (fieldset?.configuration as ApiConfiguration).sampleResponse,
    [fieldset?.configuration]
  );

  React.useEffect(() => {
    if (!method) {
      resetField('configuration.method', { defaultValue: HttpMethod.Get });
    }
  }, [method, resetField, getValues]);

  function handleTokenTransformToggle() {
    if (showTokenTransform) {
      setValue('configuration.pagination.tokenTransformation', null);
    } else {
      setValue('configuration.pagination.tokenTransformation', '{{ token() }}');
    }
    toggleTokenTransform();
  }

  const handleRefresh = React.useCallback(() => {
    applyUpdate(
      {
        ...sanitizeApiConfig(getValues('configuration') as ApiConfiguration)
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  const handleRefreshFalse = React.useCallback(() => {
    applyUpdate({
      ...sanitizeApiConfig(getValues('configuration') as ApiConfiguration)
    });
  }, [applyUpdate, getValues]);

  return (
    <>
      <div className="divide-y divide-gray-300 border-b border-gray-300">
        <SideBySide hasSectionWrap heading="HTTP request" styles="max-w-4xl space-y-4">
          <div>
            <Label>Method</Label>
            <Controller
              control={control}
              name="configuration.method"
              render={({ field }) => (
                <EditPermission
                  fallback={<DisabledSelect valueLabel={field.value} className="max-w-xs" />}
                >
                  <MyCombobox
                    className="max-w-xs"
                    value={flatToSelectable(field.value)}
                    options={methodOptions}
                    onChange={option => field.onChange(option?.value)}
                  />
                </EditPermission>
              )}
            />
          </div>
          <div>
            <Label htmlFor="configuration.path">Path</Label>
            <div className="flex items-center space-x-2">
              <Label htmlFor="configuration.path" className="font-normal">
                <MiddleEllipsis
                  text={fieldset?.connection.configuration.url as string | undefined}
                />
              </Label>
              <EditPermission>
                <MyInput {...register('configuration.path')} className="min-w-[15rem]" />
              </EditPermission>
            </div>
          </div>
          <ApiParam type="headers" label="Headers" />
          <ApiParam type="parameters" label="Query string parameters" />
          {method === 'POST' && (
            <ApiBodyField
              defaultDoc={defaultDoc}
              refreshSlot={<BodyRefreshButton bodyRef={bodyRef} handleRefresh={handleRefresh} />}
            />
          )}
        </SideBySide>
        {sampleResponse && fieldset?.fields.length === 0 && (
          <Section>
            <ApiResponse
              loading={loading}
              sampleResponse={sampleResponse}
              handleRefresh={handleRefresh}
            />
          </Section>
        )}
        {sampleResponse && (
          <SideBySide hasSectionWrap heading="Extract records" maxWidth="max-w-xs">
            <EditPermission>
              <MyInput {...register('configuration.recordPath')} label="Record JSON path" />
            </EditPermission>
            <HelperText>{jsonExamples} for extracting records from the response.</HelperText>
            <JsonPathChecker target="configuration.recordPath" />
          </SideBySide>
        )}
        <Section className="space-y-6">
          {method && fieldset?.fields.length === 0 && (
            <Button loading={loading} onClick={handleRefreshFalse}>
              Continue
            </Button>
          )}
          <FieldsTable
            fields={fieldset?.fields}
            loading={refreshing || (loading && !fieldset?.fields)}
            disabled={loading}
            hasWriteinFields={fieldset?.properties.writeinFields}
            userTypeSelection={fieldset?.properties.userTypeSelection}
          />
          {sampleResponse && hasItems(fieldset?.fields) && (
            <ApiResponse
              loading={loading}
              sampleResponse={sampleResponse}
              handleRefresh={handleRefresh}
            />
          )}
        </Section>
        {hasItems(fieldset?.fields) && (
          <>
            <SideBySide hasSectionWrap heading="Pagination" maxWidth="max-w-xs" styles="space-y-4">
              <Controller
                control={control}
                name="configuration.pagination.__typename"
                render={({ field }) => (
                  <EditPermission
                    fallback={<DisabledSelect valueLabel={getApiPaginationLabel(field.value)} />}
                  >
                    <MyCombobox
                      value={{
                        value: field.value || 'Unconfigured',
                        label: getApiPaginationLabel(field.value)
                      }}
                      options={paginationOptions}
                      onChange={option => field.onChange(option?.value)}
                    />
                  </EditPermission>
                )}
              />
              {paginationType === 'NextPagePagination' && (
                <>
                  <div>
                    <Label>Next page source</Label>
                    <Controller
                      control={control}
                      name="configuration.pagination.source"
                      render={({ field }) => (
                        <EditPermission
                          fallback={
                            <DisabledSelect
                              valueLabel={
                                sourceOptions.find(item => item.value === field.value)?.label
                              }
                            />
                          }
                        >
                          <MyCombobox
                            placeholder="Select source..."
                            value={sourceOptions.find(item => item.value === field.value) || null}
                            options={sourceOptions}
                            onChange={option => field.onChange(option?.value)}
                          />
                        </EditPermission>
                      )}
                    />
                  </div>
                  <div>
                    <EditPermission>
                      <MyInput
                        label={`Next page ${
                          nextPageSource === NextSource.Body ? 'locator' : 'header'
                        }`}
                        {...register('configuration.pagination.nextPage')}
                      />
                    </EditPermission>
                    {nextPageSource === NextSource.Body && (
                      <HelperText>
                        {jsonExamples} for extracting the next page URL from the response.
                      </HelperText>
                    )}
                    <JsonPathChecker target="configuration.pagination.nextPage" />
                  </div>
                  <div>
                    <EditPermission>
                      <Checkbox
                        label="Remember pagination position"
                        onChange={handleTokenTransformToggle}
                        {...register('configuration.pagination.persistPagination')}
                      />
                    </EditPermission>
                  </div>
                </>
              )}
              {paginationType === 'SequentialPagePagination' && (
                <div>
                  <EditPermission>
                    <MyInput
                      label="Query parameter name"
                      {...register('configuration.pagination.pageParameterName')}
                    />
                  </EditPermission>
                  <HelperText>Send sequential page number as this query parameter.</HelperText>
                </div>
              )}
              {paginationType === 'OffsetPagination' && (
                <>
                  <div>
                    <EditPermission>
                      <MyInput
                        label="Query parameter name"
                        {...register('configuration.pagination.offsetParameterName')}
                      />
                    </EditPermission>
                    <HelperText>Name of the offset query parameter (e.g. "offset").</HelperText>
                  </div>
                  <div>
                    <EditPermission>
                      <MyInput
                        label="Page size"
                        {...register('configuration.pagination.pageSize')}
                      />
                    </EditPermission>
                    <HelperText>
                      Number of records to retrieve at a time. It's generally best to pick the
                      maximum size permitted (e.g. "50").
                    </HelperText>
                  </div>
                  <div>
                    <EditPermission>
                      <MyInput
                        label="Page size query parameter (optional)"
                        {...register('configuration.pagination.limitParameterName')}
                      />
                    </EditPermission>
                    <HelperText>
                      Recommended. The query parameter used to set the page size. This will be used
                      with the Page Size from above (e.g. "limit").
                    </HelperText>
                  </div>
                  <div>
                    <EditPermission>
                      <MyInput
                        label="Record limit (optional)"
                        {...register('configuration.pagination.recordLimit')}
                      />
                    </EditPermission>
                    <HelperText>
                      Stop paging once the offset meets or exceeds this limit. If 0, there is no
                      limit.
                    </HelperText>
                  </div>
                </>
              )}
              {paginationType === 'TokenPagination' && (
                <>
                  <div>
                    <EditPermission>
                      <MyInput
                        label="Token locator"
                        {...register('configuration.pagination.tokenPath')}
                      />
                    </EditPermission>
                    <HelperText>
                      {jsonExamples} for extracting the next page token from the response.
                    </HelperText>
                    <JsonPathChecker target="configuration.pagination.tokenPath" />
                  </div>
                  <div>
                    <Label>Send token in</Label>
                    <Controller
                      control={control}
                      name="configuration.pagination.sendTokenAs.location"
                      render={({ field }) => (
                        <EditPermission
                          fallback={
                            <DisabledSelect valueLabel={getApiLocationLabel(field.value)} />
                          }
                        >
                          <MyCombobox
                            value={{
                              value: field.value || '',
                              label: getApiLocationLabel(field.value)
                            }}
                            options={paramOptions}
                            onChange={option => field.onChange(option?.value)}
                          />
                        </EditPermission>
                      )}
                    />
                  </div>
                  <div>
                    <EditPermission>
                      <MyInput
                        label={
                          nextTokenLocation === ParameterLocation.Body ? 'Next token path' : 'Name'
                        }
                        {...register('configuration.pagination.sendTokenAs.name')}
                      />
                    </EditPermission>
                    {nextTokenLocation === ParameterLocation.Header ? (
                      <HelperText>Header name to send the token as on the next request.</HelperText>
                    ) : nextTokenLocation === ParameterLocation.QueryString ? (
                      <HelperText>
                        Query string parameter to send the token as on the next request.
                      </HelperText>
                    ) : (
                      <HelperText>
                        JSON Path to the location of the next token in the request body.
                      </HelperText>
                    )}
                  </div>
                  <div>
                    <div className="flex items-center space-x-1.5">
                      <EditPermission>
                        <Checkbox
                          label="Transform token value"
                          name="transform-token-toggle"
                          checked={showTokenTransform}
                          onChange={handleTokenTransformToggle}
                        />
                      </EditPermission>
                      <LinkableIcon
                        href="https://docs.polytomic.com/docs/http-api-model#token-expressions"
                        className="focus-visible:ring-offset-gray-100"
                      />
                    </div>
                    {showTokenTransform && (
                      <div className="mt-2">
                        <EditPermission>
                          <MyInput
                            label="Token transformation"
                            {...register('configuration.pagination.tokenTransformation')}
                          />
                        </EditPermission>
                      </div>
                    )}
                  </div>
                </>
              )}
            </SideBySide>
          </>
        )}
      </div>
      <AdditionalConfig />
    </>
  );
}

const ApiResponse = React.memo<{
  handleRefresh: () => void;
  loading: boolean;
  sampleResponse: string;
}>(({ handleRefresh, loading, sampleResponse }) => {
  return (
    <TableWrap className="h-64 resize overflow-auto">
      <TableTopper className="sticky top-0 h-16 space-x-4 bg-white px-4">
        <span>API response</span>
        <EditPermission>
          <Button onClick={handleRefresh} loading={loading} iconEnd="Refresh">
            Refresh
          </Button>
        </EditPermission>
      </TableTopper>
      <div className="py-1">
        {sampleResponse?.length > MAX_RESPONSE_SIZE ? (
          <div className="w-full overflow-hidden break-words p-4">
            <p>{sampleResponse}</p>
          </div>
        ) : (
          <JsonViewer data={sampleResponse} />
        )}
      </div>
    </TableWrap>
  );
});

function JsonPathChecker({
  target
}: {
  target:
    | 'configuration.recordPath'
    | 'configuration.pagination.nextPage'
    | 'configuration.pagination.tokenPath';
}) {
  const { control, getValues } = useFormContext<FieldsetFormValues>();
  const jsonPath = useWatch({ control, name: target });

  const [getJsonPath, { data, loading, error }] = useLazyQuery(JsonPathResultDocument, {
    fetchPolicy: 'no-cache'
  });

  const response = getValues('configuration.sampleResponse');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const result = data?.jsonPathResult ? JSON.parse(data?.jsonPathResult) : null;

  if (
    !jsonPath ||
    !response ||
    response?.length > MAX_RESPONSE_SIZE ||
    typeof response !== 'string'
  ) {
    return null;
  }

  return (
    <div className="mt-2 space-y-4">
      <div className="space-y-2">
        <EditPermission>
          <Button
            onClick={() => {
              void getJsonPath({
                variables: {
                  jsonPath,
                  plaintext: response
                }
              });
            }}
            disabled={!jsonPath}
            loading={loading}
          >
            Check JSON path
          </Button>
        </EditPermission>
        {error && (
          <ErrorText>
            <Lister items={error} />
          </ErrorText>
        )}
        {data?.jsonPathResult &&
          (data.jsonPathResult === 'null' || data.jsonPathResult === '""') && (
            <TableWrap className="resize overflow-auto">
              <div className="break-all py-2 px-3 font-mono text-xs">{data?.jsonPathResult}</div>
            </TableWrap>
          )}
      </div>
      {result &&
        (typeof result === 'object' ? (
          <TableWrap className="h-40 resize overflow-auto">
            <div className="py-1">
              <JsonViewer data={result as Record<string, unknown>} />
            </div>
          </TableWrap>
        ) : (
          <TableWrap className="resize overflow-auto">
            <div className="break-all py-2 px-3 font-mono text-xs">{data?.jsonPathResult}</div>
          </TableWrap>
        ))}
    </div>
  );
}

function BodyRefreshButton({
  bodyRef,
  handleRefresh
}: {
  bodyRef: string | undefined | null;
  handleRefresh: () => void;
}) {
  const { preventSave, setPreventSave } = useFieldsetState();
  const { control } = useFormContext<FieldsetFormValues>();
  const watched = useWatch({ control, name: 'configuration.body' });

  const compareWatch = bodyRef !== watched;
  const isChanged = React.useMemo(() => compareWatch, [compareWatch]);

  React.useLayoutEffect(() => {
    let mounted = true;
    if (isChanged) {
      if (mounted) {
        setPreventSave(true);
      }
    } else {
      if (mounted) {
        setPreventSave(false);
      }
    }
    // cleanup function to re-enable save on unmount
    return () => {
      if (mounted) {
        setPreventSave(false);
      }
      mounted = false;
    };
  }, [isChanged, setPreventSave]);

  if (!isChanged || !preventSave) {
    return null;
  }

  return (
    <span className="text-sm font-normal text-gray-500">
      <span>– </span>
      <EditPermission>
        <LinkButton onClick={handleRefresh}>Refresh</LinkButton>
      </EditPermission>
      <span> to update model fields</span>
    </span>
  );
}

function ApiBodyField({
  defaultDoc,
  refreshSlot
}: {
  defaultDoc: string | undefined | null;
  refreshSlot: React.ReactNode;
}) {
  const { loading: updateLoading, setPreventSave } = useFieldsetState();

  const [showEditor, toggleEditor] = useToggle(!!defaultDoc);

  const { register, setValue, getValues } = useFormContext<FieldsetFormValues>();
  register('configuration.body');

  function show() {
    if (!getValues('configuration.body') && defaultDoc) {
      setValue('configuration.body', defaultDoc);
    }
    toggleEditor();
  }

  function remove() {
    if (getValues('configuration.body') && defaultDoc) {
      setPreventSave(true);
      setValue('configuration.body', '');
    } else {
      setPreventSave(false);
    }
    toggleEditor();
  }

  const onUpdate = React.useCallback(
    (value: string) => {
      void setValue('configuration.body', value);
    },
    [setValue]
  );

  return (
    <div>
      <label className="label flex items-center space-x-1">
        <span>Body</span>
        {updateLoading ? <LoadingDots /> : refreshSlot}
      </label>
      {showEditor ? (
        <>
          <EditPermission
            fallback={
              <TableWrap className="max-h-[15rem] min-h-[3.75rem] py-1.25">
                <JsonViewer data={defaultDoc || '{}'} />
              </TableWrap>
            }
          >
            <TableWrap>
              <Editor
                language={json()}
                onUpdate={onUpdate}
                defaultDoc={defaultDoc || undefined}
                height="12.5rem"
                placeholder="Enter JSON body contents..."
              />
            </TableWrap>
          </EditPermission>
          <EditPermission>
            <Button disabled={updateLoading} className="mt-2" size="mini" onClick={remove}>
              Remove body
            </Button>
          </EditPermission>
        </>
      ) : (
        <EditPermission>
          <ParamButton action="add" className="focus-visible:ring-offset-gray-100" onClick={show} />
        </EditPermission>
      )}
    </div>
  );
}
