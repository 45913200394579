import { useLazyQuery, useMutation } from '@apollo/client';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Button,
  DisabledSelect,
  EditPermission,
  FormFileUpload,
  Label,
  MyCombobox,
  MyInput,
  SideBySide
} from '../../../components';
import ErrorText from '../../../components/v2/feedback/ErrorText';
import {
  ApplyConnectionUpdateDocument,
  ConnectionTypeFragment,
  CreateConnectionDocument
} from '../../../generated/graphql';
import { useBannerDispatch, useScript } from '../../../hooks';
import { Selectable, getSchemaAsList, getSchemaNormalized } from '../../../utils';
import { OauthButton } from '../connection-components';
import { ConnectionFormValues, ConnectionWithoutType } from '../connection-config';

interface GoogleSheetsPickerArgs {
  accessToken: string;
  appId: string;
  developerKey: string;
  onPicked: (data: { id: string; name: string }) => unknown;
}

export function useGoogleSheetsPicker(args: GoogleSheetsPickerArgs) {
  const status = useScript('https://apis.google.com/js/api.js');
  const pickerRef = useRef<google.picker.Picker>();

  const isError = status === 'error';
  const isLoading = status === 'loading';
  const isReady = status === 'ready';

  const open = () => {
    if (isLoading || isError) {
      return;
    }
    setTimeout(() => {
      if (pickerRef.current) {
        pickerRef.current.setVisible(true);
      }
    }, 0);
  };
  const close = () => {
    if (isLoading || isError) {
      return;
    }
    setTimeout(() => {
      if (pickerRef.current) {
        pickerRef.current.setVisible(false);
      }
    }, 0);
  };

  const handleScriptLoaded = () => {
    if (status === 'ready') {
      window.gapi.load('client:auth', {
        callback: () => {
          window.gapi.client.setToken({ access_token: args.accessToken });
          window.gapi.auth.setToken({
            access_token: args.accessToken,
            error: '',
            expires_in: '',
            state: 'https://www.googleapis.com/auth/drive.file'
          });
        }
      });
      window.gapi.load('picker', {
        callback: () => {
          if (pickerRef.current) {
            pickerRef.current.dispose();
          }

          if (!args.accessToken) {
            return;
          }

          const view = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS)
            .setMode(google.picker.DocsViewMode.LIST)
            .setMimeTypes('application/vnd.google-apps.spreadsheet');

          const picker = new google.picker.PickerBuilder()
            .setAppId(args.appId)
            .setOAuthToken(args.accessToken)
            .addView(view)
            .setDeveloperKey(args.developerKey)
            .setCallback(data => {
              switch (data.action) {
                case 'cancel': {
                  break;
                }
                case 'picked': {
                  if (data.docs.length > 0) {
                    const [doc] = data.docs;
                    args.onPicked({ id: doc.id, name: doc.name });
                  }
                  break;
                }
              }
            })
            .build();

          pickerRef.current = picker;
        }
      });
    }
  };
  useEffect(handleScriptLoaded, [status, args.accessToken, args.appId, args.developerKey]);

  return {
    isError,
    isLoading,
    isReady,
    open,
    close
  };
}

export interface SelectedDoc {
  description: string;
  driveSuccess: boolean;
  embedUrl: string;
  iconUrl: string;
  id: string;
  isShared: boolean;
  lastEditedUtc: number;
  mimeType: string;
  name: string;
  serviceId: string;
  sizeBytes: number;
  type: string;
  url: string;
}

interface Props {
  connection?: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
  oauthLoading: boolean;
  onSave: (force: boolean) => (data: ConnectionFormValues) => Promise<void>;
  setConnection: Dispatch<SetStateAction<ConnectionWithoutType | undefined>>;
}

const GSHEETS_AUTH_METHODS = {
  OAUTH: { label: 'OAuth', value: 'browser' },
  SERVICE: { label: 'Service Account', value: 'jwt' }
};

export function GsheetsConnectionConfig(props: Props) {
  const dispatchBanner = useBannerDispatch();

  const { register, reset, setValue, getValues, control } = useFormContext<ConnectionFormValues>();

  const oauthToken: string = props.connection?.configuration?.oauth_access_token as string;
  const schema = getSchemaNormalized(props.connectionType.configurationSchema);
  const schemaList = getSchemaAsList(props.connectionType.configurationSchema, 'configuration');

  register('configuration.spreadsheet_id');

  const hasOauth = useWatch({ control, name: 'configuration.oauth_access_token' });
  const connectMode = useWatch({ control, name: 'configuration.connect_mode' });
  const hasServiceAccount = useWatch({ control, name: 'configuration.service_account' });
  const userEmail = useWatch({ control, name: 'configuration.user_email' });
  const sheet = useWatch({ control, name: 'configuration.spreadsheet_id' }) as
    | {
        label: string;
        value: string;
      }
    | undefined;

  const [pickedWrongSheet, setPickedWrongSheet] = useState(false);
  const picker = useGoogleSheetsPicker({
    accessToken: oauthToken,
    appId: props.connectionType.envConfig.appID as string,
    developerKey: props.connectionType.envConfig.developerKey as string,
    onPicked({ id, name }) {
      if (props.connection?.saved === true && sheet?.value && sheet.value !== id) {
        setPickedWrongSheet(true);
      } else {
        setPickedWrongSheet(false);
        setValue('configuration.spreadsheet_id', { value: id, label: name }, { shouldDirty: true });
      }
    }
  });

  const [authMethod, setAuthMethod] = useState<Selectable>(() =>
    connectMode === 'jwt' ? GSHEETS_AUTH_METHODS.SERVICE : GSHEETS_AUTH_METHODS.OAUTH
  );

  const handleAuthMethodChange = (option: Selectable | null) => {
    if (option) {
      setAuthMethod(option);

      const connect_mode = option.value;

      // We have to reset both RHF and React state :eyeroll:
      reset({
        name: props.connection?.name,
        configuration: {
          spreadsheet_id: sheet
        }
      });
      props.setConnection({
        ...props.connection,
        saved: false,
        configuration: {
          spreadsheet_id: sheet
        }
      });
      if (option === GSHEETS_AUTH_METHODS.OAUTH && hasServiceAccount) {
        updateConnection({
          variables: {
            type: props.connectionType.id,
            connection: {
              id: props.connection?.id,
              name: getValues('name'),
              configuration: {
                connect_mode,
                service_account: '',
                user_email: null,
                spreadsheet_id: sheet,
                oauth_access_token: null
              }
            }
          }
        });
      }
    }
  };

  const getServiceAccountString = async () => {
    return await (getValues('configuration.service_account') as FileList)?.[0]?.text?.();
  };

  // After create / update with the service account
  const onCompleted = async newConnection => {
    if (!newConnection) {
      return;
    }
    // Pass back the uploaded service account value, rather than the returned (***) value
    const service_account = await getServiceAccountString();
    props.setConnection({
      ...newConnection,
      configuration: {
        ...newConnection.configuration,
        service_account,
        spreadsheet_id: sheet
      }
    });
  };

  const [createConnection] = useMutation(CreateConnectionDocument, {
    onCompleted: data => onCompleted(data.createConnection),
    onError: error => dispatchBanner({ type: 'show', payload: { message: error } }),
    fetchPolicy: 'no-cache'
  });

  const [updateConnection] = useLazyQuery(ApplyConnectionUpdateDocument, {
    onCompleted: data => onCompleted(data.applyConnectionUpdate),
    onError: error => dispatchBanner({ type: 'show', payload: { message: error } }),
    fetchPolicy: 'no-cache'
  });

  const handleServiceAccount = async () => {
    const service_account = await getServiceAccountString();

    if (!service_account) {
      return;
    }

    if (props.connection?.id) {
      await updateConnection({
        variables: {
          type: props.connectionType.id,
          connection: {
            id: props.connection?.id,
            name: getValues('name'),
            configuration: {
              connect_mode: 'jwt',
              user_email: null,
              service_account,
              spreadsheet_id: sheet
            }
          }
        }
      });
    } else {
      await createConnection({
        variables: {
          type: props.connectionType.id,
          connection: {
            name: getValues('name'),
            configuration: {
              connect_mode: 'jwt',
              service_account
            },
            files: []
          },
          tags: []
        }
      });
    }
  };

  useEffect(() => {
    if (picker.isReady && hasOauth) {
      picker.open();
    }
  }, [hasOauth]);

  useEffect(() => {
    if (hasServiceAccount) {
      handleServiceAccount();
    }
  }, [hasServiceAccount]);

  return (
    <>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Authentication method">
        <EditPermission
          fallback={<DisabledSelect valueLabel={authMethod?.label} className="max-w-xs" />}
        >
          <MyCombobox
            className="max-w-xs"
            value={authMethod}
            options={Object.values(GSHEETS_AUTH_METHODS)}
            onChange={handleAuthMethodChange}
          />
        </EditPermission>
      </SideBySide>
      <SideBySide hasSectionWrap maxWidth="max-w-xs" styles="space-y-3" heading="Options">
        {authMethod === GSHEETS_AUTH_METHODS.OAUTH && (
          <OauthButton
            isConnected={props.connection?.saved === true && !!oauthToken}
            oauthLoading={props.oauthLoading}
            connectionTypeName={props.connectionType.name}
            onSave={() => props.onSave(true)}
          />
        )}
        {authMethod === GSHEETS_AUTH_METHODS.SERVICE && (
          <EditPermission>
            <FormFileUpload
              item={schemaList.find(s => s.name === 'configuration.service_account')}
            />
          </EditPermission>
        )}

        {(hasOauth || hasServiceAccount) && (
          <>
            {userEmail && (
              <div>
                <MyInput
                  {...register('configuration.user_email')}
                  label={schema?.user_email.title}
                  description={schema?.user_email.description}
                  readOnly={true}
                />
              </div>
            )}
            {sheet && sheet.value != '' ? (
              <>
                <div>
                  <Label>{schema?.spreadsheet_id.title}</Label>
                  {props.connection?.saved === false ? (
                    <div className="flex items-center">
                      <MyInput
                        {...register('configuration.spreadsheet_id.label')}
                        className="rounded-r-none ring-gray-400 focus:ring-gray-400"
                        readOnly={true}
                      />
                      <div
                        role="button"
                        className={clsx(
                          'border-gray-400 bg-gray-50 text-gray-700 hover:border-gray-500 hover:bg-gray-100',
                          'cursor-pointer rounded-r border py-1.5 px-3 text-sm font-medium'
                        )}
                        onClick={picker.open}
                      >
                        Browse...
                      </div>
                    </div>
                  ) : (
                    <a
                      className="link font-normal"
                      href={`https://docs.google.com/spreadsheets/d/${sheet.value}/#edit`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {sheet.label} ↗
                    </a>
                  )}
                </div>
                {pickedWrongSheet && (
                  <ErrorText>
                    Reconnecting to a different spreadsheet disallowed: please select the same "
                    {sheet.label}" in the picker to reconnect.
                  </ErrorText>
                )}
              </>
            ) : (
              <div>
                <Label>{schema?.spreadsheet_id.title}</Label>
                <Button loading={props.oauthLoading} onClick={picker.open}>
                  Browse...
                </Button>
              </div>
            )}
          </>
        )}
      </SideBySide>
    </>
  );
}
