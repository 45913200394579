import { defaultTo } from 'lodash';
import { ParamButton } from '../form-components';

interface RowControlProps {
  index: number;
  total: number;
  onAdd: () => void;
  onDelete: () => void;
  canAdd?: boolean;
  canDelete?: boolean;
  disabled?: boolean;
}

export function RowControl({
  index = 0,
  total = 0,
  onAdd,
  onDelete,
  canAdd,
  canDelete = true,
  disabled
}: RowControlProps) {
  const _canAdd = defaultTo(canAdd, index === total - 1);
  return (
    <div className="flex justify-end gap-[6px]">
      <ParamButton
        action="delete"
        className={!canDelete && 'hidden'}
        onClick={onDelete}
        disabled={disabled}
      />
      <ParamButton
        action="add"
        className={!_canAdd && 'hidden'}
        onClick={onAdd}
        disabled={disabled}
      />
      {!_canAdd && <ParamButton hidden />}
      {!canDelete && <ParamButton hidden />}
    </div>
  );
}
