import { ApolloError } from '@apollo/client';
import cx from 'clsx';
import * as React from 'react';

import { isStr } from '../utils';

function ListWrap(props: { children: React.ReactNode; multi: boolean }) {
  return (
    <ul className={cx('w-full', props.multi && 'list-outside list-disc space-y-0.5 pl-5')}>
      {props.children}
    </ul>
  );
}

export const Lister = ({
  items
}: {
  items: undefined | ApolloError | string[] | string | React.ReactElement;
}) => {
  if (!items) {
    return null;
  }
  if (isStr(items)) {
    return <p>{items}</p>;
  }
  if (Array.isArray(items)) {
    if (items.length === 0) {
      return null;
    }
    return (
      <ListWrap multi={items.length > 1}>
        {items.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ListWrap>
    );
  }
  if (!('graphQLErrors' in items) && !('networkError' in items)) {
    return items;
  }
  if (items.networkError) {
    // @ts-ignore
    return <p>{items.message}</p>;
  }

  return (
    // @ts-ignore
    <ListWrap multi={items.graphQLErrors.length > 1}>
      {/* @ts-ignore */}
      {items.graphQLErrors.map((error, index) => {
        const body = (error.extensions?.response as { body: string })?.body;
        return (
          <li key={index} className="space-y-1.25">
            <p>{error.message}</p>
            {body && <p>{body}</p>}
          </li>
        );
      })}
    </ListWrap>
  );
};
