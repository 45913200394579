import { MyPopover, Permission, PopoverAction, PopoverButtonMinimal } from '~/components';
import { Action, PermissionRoleFragment, UserFragment } from '~/generated/graphql';
import { useToggle } from '~/hooks';
import { DeleteUserDialog } from './delete-user-dialog';
import { EditUserDialog } from './edit-user-dialog';

interface Props {
  currentUser: UserFragment;
  user: UserFragment;
  roles: PermissionRoleFragment[];
}

export function UserActions({ currentUser, user, roles }: Props) {
  const [showPopover, togglePopover] = useToggle();
  const [showDeleteDialog, toggleDeleteDialog] = useToggle();
  const [showUpdateDialog, toggleUpdateDialog] = useToggle();

  return (
    <>
      <MyPopover
        visible={showPopover}
        onClickOutside={togglePopover}
        placement="bottom-end"
        offset={[0, 6]}
        className="z-10 w-40"
        content={
          <div className="py-1">
            <Permission type={Action.Edit}>
              <PopoverAction
                onClick={() => {
                  togglePopover();
                  toggleUpdateDialog();
                }}
              >
                Change roles
              </PopoverAction>
            </Permission>
            {currentUser.id !== user.id && (
              <Permission type={Action.Delete}>
                <PopoverAction
                  onClick={() => {
                    togglePopover();
                    toggleDeleteDialog();
                  }}
                >
                  Remove user
                </PopoverAction>
              </Permission>
            )}
          </div>
        }
      >
        <PopoverButtonMinimal isShowingPopover={showPopover} onClick={togglePopover} />
      </MyPopover>
      {showDeleteDialog && <DeleteUserDialog user={user} toggleDialog={toggleDeleteDialog} />}
      {showUpdateDialog && (
        <EditUserDialog
          isCurrentUser={currentUser.id === user.id}
          user={user}
          roles={roles}
          toggleDialog={toggleUpdateDialog}
        />
      )}
    </>
  );
}
