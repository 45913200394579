import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormBuilder, Label, MyCombobox } from '~/components';
import { AuthSchemas } from './schemas';

const Options = [
  {
    label: 'None',
    value: 'none'
  },
  {
    label: 'Basic authentication',
    value: 'basic'
  },
  {
    label: 'Header key',
    value: 'header'
  },
  {
    label: 'OAuth',
    value: 'oauth'
  }
];
const [DefaultOption, BasicOption, HeaderOption, OAuthOption] = Options;

type Option = (typeof Options)[number] | null;

export function AuthFields() {
  const { getValues, unregister } = useFormContext();
  const [selected, setSelected] = useState<Option>();

  const auth = (getValues('auth') as Record<string, unknown>) || {};
  useEffect(() => {
    if ('basic' in auth) {
      setSelected(BasicOption);
    } else if ('header' in auth) {
      setSelected(HeaderOption);
    } else if ('oauth' in auth) {
      setSelected(OAuthOption);
    } else {
      setSelected(DefaultOption);
    }
  }, [auth, setSelected]);

  const handleChange = (option: Option) => {
    unregister(`auth.${selected.value}`);
    setSelected(option);
  };

  if (selected) {
    return (
      <fieldset className="space-y-4 border-b pb-6">
        <div>
          <Label>Authentication method</Label>
          <MyCombobox options={Options} value={selected} onChange={handleChange} />
        </div>
        {AuthSchemas[selected.value] && (
          <FormBuilder prefix={`auth.${selected.value}`} schema={AuthSchemas[selected.value]} />
        )}
      </fieldset>
    );
  }
  return null;
}
