import cx from 'clsx';
import * as React from 'react';
import { Icon } from '../Icon';

interface Props {
  valueLabel: string | React.ReactNode | undefined;
  className?: string;
}

export const DisabledSelect = React.forwardRef<HTMLDivElement, Props>(
  ({ valueLabel, className }, ref) => {
    return (
      <div ref={ref} className={cx('relative cursor-not-allowed', className)}>
        <div className="flex h-8 w-full items-center truncate rounded border border-gray-200 bg-white pl-3 pr-6 pt-px">
          {typeof valueLabel === 'string' || typeof valueLabel === undefined ? (
            <p className="truncate text-sm text-gray-800">{valueLabel}</p>
          ) : (
            valueLabel
          )}
        </div>
        <Icon
          name="Select"
          className="absolute top-1.5 right-1.75 h-5 w-5 text-gray-800 opacity-70"
        />
      </div>
    );
  }
);

if (import.meta.env.MODE === 'development') {
  DisabledSelect.displayName = 'DisabledSelect';
}
