import { Icon } from '~/components';
interface ConnectUsingProps {
  whitelisted: {
    id: string;
    name: string;
  }[];
  onSelected(id: string): unknown;
}

export function ConnectUsing(props: ConnectUsingProps) {
  return (
    <>
      <p className="pb-3 text-xl text-gray-800">Connect using...</p>
      <ul className="space-y-1">
        {props.whitelisted.map(item => (
          <li key={item.id}>
            <button
              className="flex w-full items-center gap-2 rounded-[3px] p-2 hover:border-indigo-500 hover:bg-indigo-100"
              onClick={() => props.onSelected(item.id)}
            >
              <Icon match={item.id} className="h-8 w-8" />
              <span>{item.name}</span>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
}
