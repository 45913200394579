import { useMutation } from '@apollo/client';
import React from 'react';

import {
  BulkSyncDocument,
  BulkSyncQuery,
  PermissionTagFragment,
  ResourceType,
  SetTagsDocument,
  SyncDocument,
  SyncQuery
} from '../../generated/graphql';
import { useBannerDispatch } from '../../hooks';
import { AccessControl } from './access-control';

interface Props {
  resourceId: string;
  resourceType: ResourceType.Bulksync | ResourceType.Sync;
  tags: PermissionTagFragment[];
}

export function AccessControlSolo({ resourceId, resourceType, tags }: Props) {
  const dispatchBanner = useBannerDispatch();

  const [setTags, { loading }] = useMutation(SetTagsDocument, {
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
    },
    update: (cache, result) => {
      if (result.data === undefined) {
        // No results, so nothing to do
        return;
      }

      // Update the BulkSync or Sync in the cache with the modified tags, this could be extended
      // to also include other resources that use tags in the future.
      const { tags } = result.data.setTags;
      const options = {
        query: resourceType === ResourceType.Bulksync ? BulkSyncDocument : SyncDocument,
        variables: { id: resourceId }
      };
      const resource = cache.readQuery(options) as BulkSyncQuery | SyncQuery;
      const data = {
        bulkSync:
          'bulkSync' in resource
            ? {
                ...resource.bulkSync,
                tags
              }
            : undefined,
        sync:
          'sync' in resource
            ? {
                ...resource.sync,
                tags
              }
            : undefined
      };
      cache.writeQuery({ ...options, data });
    }
  });

  const updateTags = React.useCallback(
    (tagIds: string[]) => {
      void setTags({
        variables: {
          resource: {
            id: resourceId,
            type: resourceType,
            create: false
          },
          tagIds
        }
      });
    },
    [resourceId, resourceType, setTags]
  );

  const addTag = React.useCallback(
    (tag: PermissionTagFragment) => {
      if (tags.find(item => item.id === tag.id)) {
        return;
      }
      updateTags(tags.concat(tag).map(tag => tag.id));
    },
    [tags, updateTags]
  );

  const removeTag = React.useCallback(
    (tag: PermissionTagFragment) => {
      updateTags(tags.filter(item => item.id !== tag.id).map(tag => tag.id));
    },
    [tags, updateTags]
  );

  return (
    <AccessControl
      resourceType={resourceType}
      resourceTags={tags}
      addTag={addTag}
      removeTag={removeTag}
      loading={loading}
    />
  );
}
