import cx from 'clsx';
import * as React from 'react';

export const Label = React.forwardRef<HTMLLabelElement, React.ComponentPropsWithoutRef<'label'>>(
  ({ className = 'block font-medium mb-1', ...rest }, ref) => {
    return <label {...rest} ref={ref} className={cx('text-sm', className)} />;
  }
);

if (import.meta.env.MODE === 'development') {
  Label.displayName = 'Label';
}
