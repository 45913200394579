import clsx from 'clsx';
import { debounce as debounceFn } from 'lodash';
import * as React from 'react';
import { FieldErrors } from 'react-hook-form';

import { useOnClickOutside } from '~/hooks';
import { cn } from '~/lib/utils';
import { hasError } from '../../utils';
import { Icon } from '../Icon';
import { Tooltip } from '../tooltip';
import LoadingDots from '../v2/feedback/LoadingDots';
import { InlineDescription } from './inline-description';
import { InlineFormError } from './inline-form-error';
import { Label } from './label';

const INPUT_BASE_OUTLINED_SX = `w-full text-sm ring-1 border-none placeholder-gray-400 focus:outline-none`;
const INPUT_BASE_FLAT_SX = `w-full text-sm border-none placeholder-gray-400 focus:outline-none bg-gray-50`;

const INPUT_FOCUS_OUTLINED_SX = `focus:ring-indigo-500 focus:ring-2 focus:shadow-none`;
const INPUT_FOCUS_FLAT_SX = `focus:ring-indigo-500 focus:ring-2 focus:shadow-none`;

const INPUT_DISABLED_OUTLINED_SX = `bg-gray-50 ring-gray-200`;
const INPUT_DISABLED_FLAT_SX = `bg-gray-50 ring-gray-200`;

const INPUT_ERROR_OUTLINED_SX = `ring-red-500 ring-2 bg-red-50`;
const INPUT_ERROR_FLAT_SX = `ring-red-500 ring-2 bg-red-50`;

const INPUT_READONLY_OUTLINED_SX = `cursor-default bg-white ring-gray-200 focus:ring-gray-200`;
const INPUT_READONLY_FLAT_SX = `cursor-default bg-white ring-gray-200 focus:ring-gray-200`;

type SharedProps = {
  label?: string | React.ReactNode;
  name?: string;
  errors?: FieldErrors;
  description?: string | React.ReactNode;
  theme?: 'default' | 'table';
  // Todo -> turn into enum type for all inputs
  variant?: 'outlined' | 'flat';
};

export type InputProps = SharedProps & React.ComponentPropsWithoutRef<'input'>;

export const MyInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      label,
      theme = 'default',
      variant = 'outlined',
      errors,
      description,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <>
        {props.name &&
          label &&
          (typeof label === 'string' ? (
            <Label htmlFor={props.disabled || props.readOnly ? undefined : props.name}>
              {label}
            </Label>
          ) : (
            label
          ))}
        <input
          {...props}
          type={type}
          ref={ref}
          id={props.name}
          autoComplete="off"
          className={cn(
            'py-2 pr-0 leading-none',
            // BASE SX
            variant === 'outlined' ? INPUT_BASE_OUTLINED_SX : INPUT_BASE_FLAT_SX,
            props.disabled // DISABLED SX
              ? variant === 'outlined'
                ? INPUT_DISABLED_OUTLINED_SX
                : INPUT_DISABLED_FLAT_SX
              : props.readOnly // READONLY SX
                ? variant === 'outlined'
                  ? INPUT_READONLY_OUTLINED_SX
                  : INPUT_READONLY_FLAT_SX
                : variant === 'outlined' // FOCUS SX
                  ? INPUT_FOCUS_OUTLINED_SX
                  : INPUT_FOCUS_FLAT_SX,
            theme === 'default' && 'h-8 rounded pl-3',
            theme === 'table' && 'h-8 rounded pl-1',
            // ERROR SX
            hasError(errors, props.name)
              ? variant === 'outlined'
                ? INPUT_ERROR_OUTLINED_SX
                : INPUT_ERROR_FLAT_SX
              : !props.disabled &&
                  !props.readOnly && [
                    theme === 'default' && variant === 'outlined' && 'shadow-input ring-gray-400',
                    theme === 'table' && 'ring-gray-200'
                  ],
            className
          )}
          aria-invalid={errors ? 'true' : 'false'}
        />
        <InlineFormError errors={errors} name={props.name} />
        <InlineDescription description={description} />
      </>
    );
  }
);

if (import.meta.env.MODE === 'development') {
  MyInput.displayName = 'Input';
}

type TextAreaProps = SharedProps & React.ComponentPropsWithoutRef<'textarea'>;

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, className, description, theme = 'default', errors, ...props }, ref) => {
    return (
      <>
        {props.name &&
          label &&
          (typeof label === 'string' ? (
            <Label htmlFor={props.disabled || props.readOnly ? undefined : props.name}>
              {label}
            </Label>
          ) : (
            { label }
          ))}
        <textarea
          {...props}
          ref={ref}
          id={props.name}
          className={clsx(
            'min-h-[6rem] rounded leading-5',
            INPUT_BASE_OUTLINED_SX,
            props.disabled
              ? INPUT_DISABLED_OUTLINED_SX
              : props.readOnly
                ? INPUT_READONLY_OUTLINED_SX
                : INPUT_FOCUS_OUTLINED_SX,
            hasError(errors, props.name)
              ? INPUT_ERROR_OUTLINED_SX
              : !props.disabled &&
                  !props.readOnly && [
                    theme === 'default' && 'shadow-input ring-gray-400',
                    theme === 'table' && 'ring-gray-200'
                  ],
            className
          )}
          aria-invalid={errors ? 'true' : 'false'}
        />
        <InlineFormError errors={errors} name={props.name} />
        <InlineDescription description={description} />
      </>
    );
  }
);

if (import.meta.env.MODE === 'development') {
  Textarea.displayName = 'Textarea';
}

interface SearchProps {
  debounce?: boolean;
  delay?: number;
  wrapperStyles?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  stopEscHotKey?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange: (value: string | undefined) => void;
  onReset: () => void;
  defaultRef?: React.MutableRefObject<HTMLInputElement>;
  tooltip?: React.ReactNode;
}

export function Search({
  debounce,
  delay = 250,
  wrapperStyles,
  className,
  disabled,
  loading,
  defaultValue,
  placeholder = 'Search...',
  stopEscHotKey = true,
  onKeyDown,
  onChange,
  onReset,
  defaultRef,
  tooltip
}: SearchProps) {
  const [query, setQuery] = React.useState<string | undefined>(defaultValue);
  const ref = defaultRef || React.useRef<HTMLInputElement>(null);

  const debounced = React.useRef(
    debounceFn((value: string | undefined) => {
      setQuery(value);
      onChange(value);
    }, delay)
  ).current;

  const handleChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setQuery(e.currentTarget.value);
      onChange(e.currentTarget.value);
    },
    [onChange]
  );

  const handleChangeDebounced = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      debounced(e.currentTarget.value);
    },
    [debounced]
  );

  const reset = React.useCallback(() => {
    if (!ref.current || ref.current.value === '') {
      return;
    }
    ref.current.value = '';
    ref.current.focus();
    setQuery(undefined);
    onReset();
  }, [onReset]);

  React.useEffect(() => {
    if (stopEscHotKey) {
      return;
    }
    function callback(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        reset();
      }
    }
    document.addEventListener('keydown', callback, false);
    return () => {
      document.removeEventListener('keydown', callback, false);
    };
  }, [stopEscHotKey, reset]);

  React.useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  return (
    <Tooltip content={tooltip} disabled={!tooltip}>
      <div className={clsx('relative', wrapperStyles)}>
        <input
          ref={ref}
          defaultValue={defaultValue}
          type="text"
          disabled={disabled}
          placeholder={placeholder}
          className={clsx(
            'h-8 rounded-full pl-4 pr-8',
            INPUT_BASE_OUTLINED_SX,
            disabled
              ? INPUT_DISABLED_OUTLINED_SX
              : [INPUT_FOCUS_OUTLINED_SX, 'shadow-input ring-gray-400'],
            className
          )}
          onChange={debounce ? handleChangeDebounced : handleChange}
          onKeyDown={onKeyDown}
        />

        <div
          className={clsx(
            'group absolute inset-y-0 right-0 flex items-center',
            loading ? 'pr-2' : 'pr-3',
            query ? 'pointer-events-auto cursor-pointer' : 'pointer-events-none'
          )}
          onClick={loading ? undefined : reset}
        >
          {loading ? (
            <span className="h-5 w-5 ">
              <LoadingDots dense />
            </span>
          ) : (
            <Icon
              name={query ? 'CloseXSmall' : 'SearchSmall'}
              className={clsx('h-5 w-5 text-gray-500', query && 'group-hover:text-gray-800')}
              aria-hidden="true"
            />
          )}
        </div>
      </div>
    </Tooltip>
  );
}

interface ControlledSearchProps {
  id?: string;
  searchInputRef?: React.MutableRefObject<HTMLInputElement | null>;
  value: string;
  setValue: (v?: string) => void;
  wrapperStyles?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  tabIndex?: number;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

export function ControlledSearch({
  id,
  searchInputRef,
  value,
  setValue,
  wrapperStyles,
  className,
  disabled,
  loading,
  placeholder = 'Search...',
  autoFocus,
  onClick,
  tabIndex
}: ControlledSearchProps) {
  const handleReset = () => {
    setValue('');
  };
  React.useLayoutEffect(() => {
    autoFocus && searchInputRef?.current?.focus();
  }, [autoFocus, searchInputRef]);

  return (
    <div className={clsx('relative', wrapperStyles)}>
      <input
        id={id}
        ref={searchInputRef}
        tabIndex={tabIndex}
        onScroll={e => e.currentTarget.scrollLeft}
        value={value}
        type="text"
        disabled={disabled}
        placeholder={placeholder}
        onClick={onClick}
        className={clsx(
          'h-8 rounded-full pl-4 pr-8',
          INPUT_BASE_OUTLINED_SX,
          disabled
            ? INPUT_DISABLED_OUTLINED_SX
            : [INPUT_FOCUS_OUTLINED_SX, 'shadow-input ring-gray-400'],
          className
        )}
        onChange={e => setValue(e.currentTarget.value)}
      />

      <div
        className={clsx(
          'group absolute inset-y-0 right-0 flex items-center',
          loading ? 'pr-2' : 'pr-3',
          value ? 'pointer-events-auto cursor-pointer' : 'pointer-events-none'
        )}
        onClick={loading ? undefined : handleReset}
      >
        {loading ? (
          <span className="h-5 w-5">
            <LoadingDots dense />
          </span>
        ) : (
          <Icon
            name={value ? 'CloseXSmall' : 'SearchSmall'}
            className={clsx('h-5 w-5 text-gray-500', value && 'group-hover:text-gray-800')}
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  );
}

interface SearchWithActionProps {
  debounce?: boolean;
  wrapperStyles?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  stopEscHotKey?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange: (value: string | undefined) => void;
  onReset: () => void;
  frontAction?: React.ReactNode;
  endAction?: React.ReactNode;
}

export function SearchWithAction({
  debounce,
  frontAction,
  endAction,
  wrapperStyles,
  className,
  disabled,
  defaultValue,
  placeholder = 'Search...',
  stopEscHotKey = true,
  onKeyDown,
  onChange,
  onReset
}: SearchWithActionProps) {
  const [query, setQuery] = React.useState<string | undefined>(defaultValue);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const ref = React.useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);

  const debounced = React.useRef(
    debounceFn((value: string | undefined) => {
      setQuery(value);
      onChange(value);
    }, 250)
  ).current;

  const handleChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setQuery(e.currentTarget.value);
      onChange(e.currentTarget.value);
    },
    [onChange]
  );

  const handleChangeDebounced = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      debounced(e.currentTarget.value);
    },
    [debounced]
  );

  const reset = React.useCallback(() => {
    if (!ref.current || ref.current.value === '') {
      return;
    }
    ref.current.value = '';
    ref.current.focus();
    setQuery(undefined);
    onReset();
  }, [onReset]);

  React.useEffect(() => {
    if (stopEscHotKey) {
      return;
    }
    function callback(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        reset();
      }
    }
    document.addEventListener('keydown', callback, false);
    return () => {
      document.removeEventListener('keydown', callback, false);
    };
  }, [stopEscHotKey, reset]);

  React.useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  useOnClickOutside(ref, () => setIsFocused(false));

  return (
    <div
      className={clsx(
        'relative flex h-8 w-full rounded-full ring-1',
        disabled ? 'bg-gray-50 ring-gray-200' : 'ring-gray-400',
        isFocused && 'outline-none ring-2 ring-indigo-500',
        wrapperStyles
      )}
      ref={wrapperRef}
      onFocus={() => setIsFocused(true)}
    >
      {frontAction}
      <input
        ref={ref}
        defaultValue={defaultValue}
        type="text"
        disabled={disabled}
        placeholder={placeholder}
        className={clsx(
          'w-full placeholder-gray-400',
          'h-8 border-none bg-transparent pl-2 pr-0 text-sm focus:outline-none focus:ring-0 focus:ring-transparent',
          className
        )}
        onChange={debounce ? handleChangeDebounced : handleChange}
        onKeyDown={onKeyDown}
      />
      {endAction && isFocused ? (
        <>{endAction}</>
      ) : (
        <div
          className={clsx(
            'group flex items-center pr-3 pl-1',
            query ? 'pointer-events-auto cursor-pointer' : 'pointer-events-none'
          )}
          onClick={reset}
        >
          <Icon
            name={query ? 'CloseXSmall' : 'SearchSmall'}
            className={clsx('h-5 w-5 text-gray-500', query && 'group-hover:text-gray-800')}
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  );
}
