import { useApolloClient, useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { generatePath, useHistory } from 'react-router-dom';
import { IconName } from '~/assets';
import {
  BulkSyncsDocument,
  ConnectionsDocument,
  ModelDocument,
  PeekSyncsDocument
} from '~/generated/graphql';
import { ModelField, useModelFields } from '~/hooks';
import { useQueryPersistance } from '~/hooks/use-query-persistance';
import { routes, SelectableUser, selectIdentity, UserAccountsRepsonse } from '~/utils';
import { Icon } from '../Icon';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from './Command';
import { Dialog } from './Dialog';

const match = (paths: string[], search: string) => {
  return !search || paths.some(path => path.toLowerCase().includes(search.toLowerCase()));
};

interface CommandAction {
  name: string;
  icon: IconName;
  paths: string[];
  href: string;
}

interface QuickSearchProps {
  open: boolean;
  toggleOpen: () => void;
}

export function QuickSearch({ open, toggleOpen }: QuickSearchProps) {
  const [search, setSearch] = useState<string>('');
  const history = useHistory();

  const { data: connectionsData } = useQuery(ConnectionsDocument);
  const { data: modelData } = useQuery(ModelDocument);
  const { data: syncData } = useQuery(PeekSyncsDocument);
  const { data: bulkSyncData } = useQuery(BulkSyncsDocument);
  const { modelFields } = useModelFields();

  const { clearAllItemsFromStorage } = useQueryPersistance();

  const actionItems: CommandAction[] = [
    {
      name: 'New model',
      icon: 'PlusCircle',
      href: routes.createModel,
      paths: ['new model', 'create model']
    },
    {
      name: 'New model sync',
      icon: 'PlusCircle',
      href: routes.createSync,
      paths: ['new sync', 'create sync', 'new model sync', 'create model sync']
    },
    {
      name: 'New bulk sync',
      icon: 'PlusCircle',
      href: routes.createBulkSync,
      paths: ['new bulk sync', 'create bulk sync']
    }
  ];

  const navigationItems: CommandAction[] = [
    {
      name: 'Dashboard',
      icon: 'Pulse',
      href: routes.dashboard,
      paths: ['dashboard']
    },
    {
      name: 'Models',
      icon: 'Model',
      href: routes.models,
      paths: ['models']
    },
    {
      name: 'Model syncs',
      icon: 'Syncs',
      href: routes.syncs,
      paths: ['syncs', 'model syncs']
    },
    {
      name: 'Bulk syncs',
      icon: 'Truck',
      href: routes.bulkSyncsRoot,
      paths: ['bulk syncs']
    },
    {
      name: 'Connections',
      icon: 'Generic',
      href: routes.connections,
      paths: ['connections']
    },
    {
      name: 'Query runner',
      icon: 'Terminal',
      href: routes.queryRunner,
      paths: ['query runner']
    },
    {
      name: 'Explore',
      icon: 'Search',
      href: routes.explore,
      paths: ['explore']
    },
    {
      name: 'Team',
      icon: 'Team',
      href: routes.team,
      paths: ['team']
    },
    {
      name: 'Settings',
      icon: 'Gear',
      href: routes.user,
      paths: ['settings']
    }
  ];

  const [_accounts, setAccounts] = useState<SelectableUser[]>([]);
  const [_supportable, setSupportable] = useState<SelectableUser[]>([]);

  useEffect(() => {
    fetch(`${import.meta.env.VITE_API_URL || ''}/auth/identity`, {
      method: 'GET',
      cache: 'no-cache',
      redirect: 'manual',
      credentials: 'include',
      referrerPolicy: 'no-referrer'
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(data => {
        const identities = data as UserAccountsRepsonse;
        const { memberOf, supportOrgs = [] } = identities;

        setAccounts(sortBy(memberOf, 'orgName'));
        setSupportable(sortBy(supportOrgs, 'orgName'));
      });
  }, []);

  const connections = useMemo(
    () => (connectionsData?.connections ?? []).filter(c => search && match([c.id, c.name], search)),
    [search, connectionsData]
  );
  const models = useMemo(
    () =>
      (modelData?.model?.fieldsets ?? []).filter(
        m => search && match([m.id, m.name, m.connection.name], search)
      ),
    [search, modelData]
  );
  const syncs = useMemo(
    () =>
      (syncData?.peekSyncs ?? []).filter(
        s =>
          search &&
          match(
            [
              s.id,
              s.name,
              ...s.SourceConnections.map(c => c.ConnectionType),
              s.TargetConnectionType
            ],
            search
          )
      ),
    [search, syncData]
  );
  const bulkSyncs = useMemo(
    () =>
      (bulkSyncData?.bulkSyncs ?? []).filter(
        s =>
          search &&
          match(
            [s.id, s.name, s.source?.connection?.type?.name, s.destination?.connection?.type?.name],
            search
          )
      ),
    [search, bulkSyncData]
  );
  const actions = useMemo(
    () => actionItems.filter(i => match(i.paths, search)),
    [search, actionItems]
  );
  const navigations = useMemo(
    () => navigationItems.filter(n => match(n.paths, search)),
    [search, navigationItems]
  );
  const fields = useMemo(
    () =>
      (modelFields as ModelField[]).filter(
        f => search && match([f.id, f.label, f.sourceName, `${f.fieldset.name} ${f.label}`], search)
      ),
    [search, modelFields]
  );
  const accounts = useMemo(
    () => _accounts.filter(a => search && match([a.orgName], search)),
    [_accounts, search]
  );
  const supportable = useMemo(
    () => _supportable.filter(a => search && match([a.orgName], search)),
    [_supportable, search]
  );

  const client = useApolloClient();

  function handleIdentitySelection(identity: string) {
    client.resetStore();
    clearAllItemsFromStorage();
    selectIdentity(identity, async () => {
      history.replace(routes.dashboard);
      location.reload();
    });
  }

  const scrollRef = useRef<HTMLDivElement>();

  const handleSearchChange = (search: string) => {
    setSearch(search);
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };

  const handleSelect = (fn?: () => void) => () => {
    toggleOpen();
    setSearch('');
    setTimeout(fn);
  };

  useHotkeys('mod+k', toggleOpen, {
    enableOnFormTags: ['INPUT', 'TEXTAREA', 'SELECT'],
    enableOnContentEditable: true
  });

  return (
    <Dialog show={open} onDismiss={handleSelect()} classNames={{ body: 'p-0' }} size="lg">
      <Command className="h-full rounded-lg shadow-md md:min-w-[450px]" shouldFilter={false}>
        <CommandInput
          placeholder="Type a command or search models, syncs, fields and more"
          value={search}
          onValueChange={handleSearchChange}
        />
        <CommandList ref={scrollRef}>
          {!!actions.length && (
            <CommandGroup heading="Actions">
              {actions.map(action => (
                <CommandItem
                  key={action.name}
                  className="flex items-center gap-2"
                  onSelect={handleSelect(() => {
                    history.push(action.href);
                  })}
                >
                  <Icon name={action.icon} className="text-gray-500" />
                  <span>{action.name}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          {!!navigations.length && (
            <CommandGroup heading="Navigation">
              {navigations.map(navigation => (
                <CommandItem
                  key={navigation.name}
                  className="flex items-center gap-2"
                  onSelect={handleSelect(() => {
                    history.push(navigation.href);
                  })}
                >
                  <Icon name={navigation.icon} className="text-gray-500" />
                  <span>{navigation.name}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          {!!accounts.length && (
            <CommandGroup heading="Accounts">
              {accounts.map(account => (
                <CommandItem
                  key={account.orgName}
                  className="flex items-center gap-2"
                  onSelect={handleSelect(() => {
                    handleIdentitySelection(account.qualifiedUser);
                  })}
                >
                  <Icon name="Team" className="text-gray-500" />
                  <span>{account.orgName}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          {!!supportable.length && (
            <CommandGroup heading="Supportable Accounts">
              {accounts.map(account => (
                <CommandItem
                  key={account.orgName}
                  className="flex items-center gap-2"
                  onSelect={handleSelect(() => {
                    handleIdentitySelection(account.qualifiedUser);
                  })}
                >
                  <Icon name="Team" className="text-gray-500" />
                  <span>{account.orgName}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          {!!models.length && (
            <CommandGroup heading="Models">
              {models.map(model => (
                <CommandItem
                  key={model.id}
                  value={model.id}
                  onSelect={handleSelect(() =>
                    history.push(generatePath(routes.editModel, { fieldsetId: model.id }))
                  )}
                  className="flex items-center gap-2"
                >
                  <Icon match={model.connection.type.id} />
                  <span>{model.connection.name}</span>
                  <Icon name="Disclosure" className="text-gray-500" />
                  <span>{model.name}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          {!!connections.length && (
            <CommandGroup heading="Connections">
              {connections.map(connection => (
                <CommandItem
                  key={connection.id}
                  value={connection.id}
                  onSelect={handleSelect(() =>
                    history.push(generatePath(routes.editConnection, { id: connection.id }))
                  )}
                  className="flex items-center gap-2"
                >
                  <Icon match={connection.type.id} />
                  <span>{connection.name}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          {!!syncs.length && (
            <CommandGroup heading="Model syncs">
              {syncs.map(sync => (
                <CommandItem
                  key={sync.id}
                  value={sync.id}
                  onSelect={handleSelect(() =>
                    history.push(generatePath(routes.sync, { id: sync.id }))
                  )}
                  className="flex items-center gap-2"
                >
                  <div className="flex items-center gap-1">
                    {sync.SourceConnections.map((connection, i) => (
                      <Fragment key={connection.ConnectionID}>
                        {i > 0 && <Icon name="PlusSmall" className="-mx-1.5" />}
                        <Icon match={connection.ConnectionType} />
                      </Fragment>
                    ))}
                    <Icon name="Syncs" />
                    {sync.TargetConnectionType && (
                      <Icon match={sync.TargetConnectionType} className="mr-1.5" />
                    )}
                  </div>
                  <span>{sync.name}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          {!!bulkSyncs.length && (
            <CommandGroup heading="Bulk syncs">
              {bulkSyncs.map(sync => (
                <CommandItem
                  key={sync.id}
                  value={sync.id}
                  onSelect={handleSelect(() =>
                    history.push(generatePath(routes.bulkSync, { id: sync.id }))
                  )}
                  className="flex items-center gap-2"
                >
                  <div className="flex items-center gap-1">
                    <Icon match={sync.source.connection.type.id} />
                    <Icon name="Syncs" />
                    <Icon match={sync.destination.connection.type.id} />
                  </div>
                  <span>{sync.name}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          {!!fields.length && (
            <CommandGroup heading="Model fields">
              {fields.map(field => (
                <CommandItem
                  key={field.id}
                  value={field.id}
                  onSelect={handleSelect(() =>
                    history.push(
                      generatePath(routes.editField, {
                        fieldId: field.id,
                        fieldsetId: field.fieldset.id
                      })
                    )
                  )}
                  className="flex items-center gap-2"
                >
                  <Icon
                    match={
                      field.enrichmentFieldset?.connection?.type?.id ??
                      field.fieldset.connection.type.id
                    }
                  />
                  <div className="flex items-center gap-1">
                    <span>{field.fieldset.connection.name}</span>
                    <Icon name="Disclosure" className="text-gray-500" />
                    <span>{field.fieldset.name}</span>
                    <Icon name="Disclosure" className="text-gray-500" />
                    <span>{field.sourceName}</span>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          {[
            actions,
            navigations,
            syncs,
            bulkSyncs,
            models,
            connections,
            fields,
            accounts,
            supportable
          ].every(i => !i.length) && <CommandEmpty>No results</CommandEmpty>}
        </CommandList>
      </Command>
    </Dialog>
  );
}
