import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { uuid4 as uuid } from '@sentry/utils';
import clsx from 'clsx';
import { useSetAtom } from 'jotai';
import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  AccessControlInForm,
  AccessControlWrap,
  Button,
  EditPermission,
  Icon,
  LacksPermissionBanner,
  PromptUnsaved,
  SideBySide,
  Tooltip
} from '~/components';
import ActivityLog from '~/components/v2/experimental/activitylog/ActivityLog';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import PageLayout from '~/components/v2/layout/PageLayout';
import { Dialog } from '~/components/v3';
import {
  AllLabelsDocument,
  ApplyFieldsetUpdateDocument,
  ApplyFieldsetUpdateQuery,
  ApplyFieldsetUpdateQueryVariables,
  ClientFieldsetFragment,
  CosmosDbConfiguration,
  DataliteConfiguration,
  DeleteFieldsetDocument,
  EditableFieldsetDocument,
  EditableFieldsetFragment,
  EnrichmentUpdate,
  FieldsetConfiguration,
  FieldsetType,
  FilterFunction,
  ModelDocument,
  ResourceType,
  SuggestedNameDocument,
  UpdateFieldsetDocument
} from '~/generated/graphql';
import {
  AclProvider,
  FieldsetProvider,
  useAbortQuery,
  useBannerDispatch,
  useConfirmation,
  useLazyContinuationQuery
} from '~/hooks';
import {
  FieldsetFormValues,
  NO_EDIT_PERMISSION,
  fieldsetToUpdate,
  getDateOnly,
  hasItems,
  isNotFoundError,
  mapFieldsToFieldUpdates,
  routes,
  updateFieldsetForm
} from '~/utils';
import { ModelsStateAtom } from '../models/models';
import FieldsetConfigSwitcher from './fieldset-config-switcher';
import { FieldsetConnection } from './fieldset-connection';
import { FieldsetLabels } from './fieldset-labels';
import { FieldsetName } from './fieldset-name';

export function FieldsetConfig() {
  const { fieldsetId } = useParams<{ fieldsetId: string }>();
  const history = useHistory();
  const dispatchBanner = useBannerDispatch();
  const isAdding = useRouteMatch({ path: routes.createModel, exact: true });
  const [fieldset, setFieldset] = useState<EditableFieldsetFragment>();
  const [enableNameSuggestion, setEnableNameSuggestion] = useState(true);
  const [preventSave, setPreventSave] = useState<boolean>();
  const [updatedConfig, setUpdatedConfig] = useState<FieldsetConfiguration>();

  const methods = useForm<FieldsetFormValues>();
  const { reset, handleSubmit, getValues, formState, setValue } = methods;
  const { isDirty, dirtyFields } = formState;

  const { confirmation, confirmationMessage, handleConfirmation, resetConfirmation } =
    useConfirmation();

  const { data, loading: getFieldsetLoading } = useQuery(EditableFieldsetDocument, {
    skip: !fieldsetId,
    variables: { id: fieldsetId },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: data => {
      if (!data || !data.fieldset) {
        return;
      }
      setFieldset(data.fieldset);
      updateFieldsetForm(reset, getValues, data.fieldset);
    },
    onError: error => {
      if (isNotFoundError(error)) {
        history.push(routes.err404);
        return;
      }
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
      if (!data || !data.fieldset) {
        return;
      }
      setFieldset(data.fieldset);
      updateFieldsetForm(reset, getValues, data.fieldset);
    }
  });

  const { signal, abortQuery, resetAbortQuery } = useAbortQuery();

  const [applyFieldsetUpdate, { loading: updateLoading, variables: updateVars }] =
    useLazyContinuationQuery<ApplyFieldsetUpdateQuery, ApplyFieldsetUpdateQueryVariables>(
      ApplyFieldsetUpdateDocument,
      {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        context: { fetchOptions: { signal } },
        onCompleted: data => {
          if (!data || !data.applyFieldsetUpdate) {
            setPreventSave(false);
            return;
          }
          setPreventSave(false);
          const fieldset = data.applyFieldsetUpdate as EditableFieldsetFragment;
          fieldset.realName = getValues()?.realName ?? fieldset.realName;
          setFieldset(fieldset);
          updateFieldsetForm(reset, getValues, fieldset);
          setUpdatedConfig(fieldset.configuration as FieldsetConfiguration);
        },
        onError: error => {
          if (signal.aborted && error?.message.includes('aborted')) {
            return;
          }
          setPreventSave(false);
          if (
            error.extraInfo &&
            (error.extraInfo as Record<string, unknown>)['isComplete'] === false
          ) {
            return;
          }
          dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
        }
      }
    );

  const applyUpdate = (
    update: Record<string, unknown>,
    options?: { refresh?: boolean; resetFields?: boolean; refreshConfiguration?: boolean },
    enrichmentUpdates?: EnrichmentUpdate[]
  ) => {
    if (!fieldset?.id) {
      return;
    }
    dispatchBanner({ type: 'hide' });
    resetAbortQuery();
    const form = getValues();
    void applyFieldsetUpdate({
      variables: {
        id: fieldset.id,
        continuation: uuid(),
        name: form.realName,
        fields: options?.resetFields ? [] : mapFieldsToFieldUpdates(form.fields),
        primaryKey: form.primaryKey?.id,
        relatedTo: [],
        refresh: !!options?.refresh,
        refreshConfiguration: !!options?.refreshConfiguration,
        enrichments: enrichmentUpdates ?? form.enrichmentUpdates,
        update
      }
    });
  };

  const [deleteFieldset, { loading: deleteLoading }] = useMutation(DeleteFieldsetDocument, {
    fetchPolicy: 'no-cache',
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
    },
    update: (cache, { data }) => {
      if (!data?.deleteFieldset || handleConfirmation(data.deleteFieldset)) {
        return;
      }
      // This is to guard against the response sending down unpublished fields.
      // We don't want those in the Model. Ideally, this wouldn't be needed.
      const fieldsets = data.deleteFieldset.fieldsets.reduce(
        (acc: ClientFieldsetFragment[], fs) => {
          acc.push({ ...fs, fields: fs.fields.filter(f => f.published) });
          return acc;
        },
        []
      );
      cache.writeQuery({
        query: ModelDocument,
        data: { model: { fieldsets, __typename: 'Model' } }
      });
      // force syncs to refetch
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'syncs' });
      reset();
      history.push(routes.models);
    }
  });

  function handleDelete() {
    if (!fieldset) {
      return;
    }
    void deleteFieldset({ variables: { id: fieldset.id, confirmation } });
  }

  const setModelsState = useSetAtom(ModelsStateAtom);
  const [saveFieldset, { loading: saveLoading }] = useMutation(UpdateFieldsetDocument, {
    fetchPolicy: 'no-cache',
    refetchQueries: [AllLabelsDocument],
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
    },
    update: (cache, { data }) => {
      if (!data || !data.updateFieldset) {
        return;
      }
      if (!fieldsetId) {
        setModelsState(s => ({
          ...s,
          expandedFieldsetIds: [...s.expandedFieldsetIds, data.updateFieldset.fieldset.id]
        }));
      }
      // This is to guard against the response sending down unpublished fields.
      // We don't want those in the Model. Ideally, this wouldn't be needed.
      // @TODO @poindexd ^ welp, this comment said it. updated to filter
      // unpublished enrichment fields from the cache
      const fieldsets = data.updateFieldset.model.fieldsets.reduce(
        (acc: ClientFieldsetFragment[], fs) => {
          acc.push({
            ...fs,
            enrichments: fs.enrichments.map(enrichment => ({
              ...enrichment,
              provider: {
                ...enrichment.provider,
                fields: enrichment.provider.fields.filter(f => f.published)
              }
            })),
            fields: fs.fields.filter(f => f.published)
          });
          return acc;
        },
        []
      );
      cache.writeQuery({
        query: ModelDocument,
        data: { model: { fieldsets, __typename: 'Model' } }
      });
      // force syncs to refetch if updating
      // as creation flow won't have a fieldsetId
      if (fieldsetId) {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'syncs' });
      }
      reset();
      history.push(routes.models);
    }
  });
  // f(x)
  const handleCancel = () => history.push(routes.models);
  const handleSave = handleSubmit((form: FieldsetFormValues) => {
    if (!(fieldset && form)) {
      return;
    }
    const localErrors: string[] = [];
    if (fieldset.fieldsetType !== FieldsetType.Datalite) {
      if (!form.fields.some(field => field.published)) {
        localErrors.push('Must add at least one field to model');
      }
    }
    if (fieldset.fieldsetType === FieldsetType.Datalite) {
      if (!hasItems((form.configuration as DataliteConfiguration).schemas)) {
        localErrors.push('At least one object must be selected');
      }
      if (!(form.configuration as DataliteConfiguration).query) {
        localErrors.push('Must add a valid query');
      }
    }
    if (fieldset.fieldsetType === FieldsetType.CosmosDb) {
      if (hasItems((form.configuration as CosmosDbConfiguration).filters)) {
        (form.configuration as CosmosDbConfiguration).filters.forEach((filter, idx) => {
          if (
            (filter.function === FilterFunction.Equality ||
              filter.function === FilterFunction.Inequality) &&
            !filter.value
          ) {
            const field = fieldset.fields.find(f => f.sourceName === filter.field);
            localErrors.push(
              `Filter: "${field?.label || '#' + idx.toString()}" has missing or invalid values`
            );
          }
        });
      }
    }
    if (hasItems(localErrors)) {
      dispatchBanner({
        type: 'show',
        payload: { message: localErrors, wrapper: 'px-3 pt-3' }
      });
      return;
    }
    const variables = {
      id: fieldset.id,
      name: form.realName,
      fields: mapFieldsToFieldUpdates(form.fields),
      primaryKey: form.primaryKey?.id || null,
      relatedTo:
        form.relatedTo?.[0]?.from && form.relatedTo?.[0]?.to
          ? [{ from: form.relatedTo[0].from.id, to: form.relatedTo[0].to.id }]
          : [],
      update: fieldsetToUpdate(fieldset.fieldsetType, form.configuration as FieldsetConfiguration),
      tags: form?.tags?.map(tag => tag.id) || [],
      labels: form?.labels?.map(label => label.id) ?? [],
      enrichments: form?.enrichmentUpdates ?? []
    };
    void saveFieldset({ variables });
  });

  const [getSuggestedName] = useLazyQuery(SuggestedNameDocument, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data) {
        const newName = data?.suggestModelName === '' ? 'Unnamed model' : data?.suggestModelName;
        setValue('realName', newName);
      }
    }
  });

  useEffect(() => {
    if (isAdding && enableNameSuggestion && !updateLoading && !getFieldsetLoading && !saveLoading) {
      if (fieldset?.id && fieldset?.configurationSchema) {
        getSuggestedName({
          variables: {
            modelId: fieldset.id,
            config: omit(fieldset.configuration, 'sampleResponse')
          }
        });
      }
    }
  }, [
    fieldset?.id,
    fieldset?.configuration,
    fieldset?.connection,
    fieldset?.name,
    fieldset?.fieldsetType,
    updateLoading,
    enableNameSuggestion
  ]);

  return (
    <AclProvider value={fieldset?.acl}>
      <FormProvider {...methods}>
        <FieldsetProvider
          fieldset={fieldset}
          setFieldset={setFieldset}
          applyUpdate={applyUpdate}
          loading={updateLoading}
          refreshing={updateLoading && updateVars.refresh}
          updatedConfig={updatedConfig}
          setUpdatedConfig={setUpdatedConfig}
          preventSave={preventSave}
          setPreventSave={setPreventSave}
          abortQuery={abortQuery}
          abortSignal={signal}
        >
          <Helmet title={`${isAdding ? 'Add model' : fieldset?.name || 'Models'} | Polytomic`} />
          <PageLayout
            loading={getFieldsetLoading}
            topNavHeading={fieldsetId ? 'Edit model' : 'Add model'}
            topNavActions={
              <>
                <Button
                  theme="outline"
                  iconEnd="CloseX"
                  onClick={handleCancel}
                  disabled={saveLoading || updateLoading}
                >
                  Cancel
                </Button>
                <Tooltip
                  offset={[0, 12]}
                  placement="bottom-end"
                  disabled={!preventSave}
                  content={clsx(
                    'Model cannot be saved until',
                    fieldset?.fieldsetType !== FieldsetType.Api && 'query is',
                    'refreshed'
                  )}
                >
                  <span>
                    <EditPermission>
                      <Button
                        theme="outline"
                        onClick={handleSave}
                        loading={saveLoading}
                        disabled={updateLoading || deleteLoading || preventSave}
                        iconEnd="Check"
                      >
                        Save
                      </Button>
                    </EditPermission>
                  </span>
                </Tooltip>
              </>
            }
          >
            <EditPermission
              fallback={
                <LacksPermissionBanner message={NO_EDIT_PERMISSION} wrapper={'px-3 pt-3'} />
              }
            />
            <div className="divide-y divide-gray-300 pb-48">
              <FieldsetConnection fieldset={fieldset} setFieldset={setFieldset} />
              <div className="divide-y divide-gray-300">
                {fieldset && (
                  <>
                    <FieldsetName
                      disabled={updateLoading || saveLoading || deleteLoading}
                      onInputCapture={() => setEnableNameSuggestion(false)}
                    />
                    <FieldsetConfigSwitcher
                      key={fieldset.connection.id}
                      adding={!!isAdding}
                      type={fieldset.fieldsetType}
                      connectionId={fieldset.connection.type.id}
                    />
                  </>
                )}
              </div>
              {hasItems(fieldset?.fields) && (
                <>
                  <FieldsetLabels disabled={updateLoading || saveLoading || deleteLoading} />
                  <AccessControlWrap>
                    <AccessControlInForm resourceType={ResourceType.Model} />
                  </AccessControlWrap>
                </>
              )}
              {fieldsetId && (
                <SideBySide
                  hasSectionWrap
                  styles="flex items-center space-x-2 w-full"
                  heading="Last modified by"
                >
                  <ActivityLog
                    objectId={fieldsetId}
                    fieldset={fieldset}
                    title={
                      <span className="text-sm text-gray-800">
                        {!fieldset?.updatedBy
                          ? null
                          : clsx(
                              fieldset?.updatedBy?.name,
                              fieldset?.updatedBy?.email && `<${fieldset?.updatedBy?.email}>`,
                              fieldset?.updatedAt && `on ${getDateOnly(fieldset?.updatedAt) || ''}`
                            )}
                      </span>
                    }
                  />
                </SideBySide>
              )}
              {fieldsetId && (
                <>
                  <SideBySide hasSectionWrap heading="Delete Model" styles="pt-[3px]">
                    <p className="mb-2 mt-1 max-w-sm text-sm text-gray-800">
                      Deleting this model will result in the failure of any existing syncs that use
                      this model.
                    </p>
                    <div className="flex items-center space-x-2">
                      <Button
                        theme="danger"
                        iconEnd="Trashcan"
                        onClick={() => handleDelete()}
                        disabled={deleteLoading}
                      >
                        Delete Model
                      </Button>
                      {deleteLoading && <LoadingDots />}
                    </div>
                  </SideBySide>
                </>
              )}
            </div>
            <Dialog
              show={!!confirmation}
              heading="Delete model?"
              onDismiss={resetConfirmation}
              actions={
                <>
                  <Button onClick={resetConfirmation}>Cancel</Button>
                  <Button theme="danger" loading={deleteLoading} onClick={handleDelete}>
                    Delete Model
                  </Button>
                </>
              }
            >
              <p className="mb-4 text-sm font-medium text-gray-800">{fieldset?.name}</p>
              <p className="mb-4 flex items-center text-sm font-medium text-gray-800">
                <Icon match={fieldset?.connection.type.id} className="mr-2 h-5 w-auto" />
                {fieldset?.connection.name}
              </p>
              <p className="text-sm text-gray-600 [max-width:65ch]">{confirmationMessage}</p>
            </Dialog>
            <PromptUnsaved when={isDirty && hasItems(Object.keys(dirtyFields))} />
          </PageLayout>
        </FieldsetProvider>
      </FormProvider>
    </AclProvider>
  );
}
