import { ApolloError, useQuery } from '@apollo/client';
import cx from 'clsx';
import { find } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Icon, Lister, ModelStub, SearchWithAction } from '~/components';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItemCircle,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import { AllLabelsDocument, FieldsetListItemFragment, LabelFragment } from '~/generated/graphql';
import { useAuth, useLocalStorage, useToggle } from '~/hooks';
import { FieldsetRow } from '~/pages/models/models/fieldset-row';
import { hasItems, routes } from '~/utils';

const modelsFiltersOptions = ['All models', 'My models'];

interface Props {
  loading: boolean;
  error: ApolloError | undefined;
  fieldsets: FieldsetListItemFragment[];
}

export function ModelsListColumn({ fieldsets, ...props }: Props) {
  const { user } = useAuth();
  const editingField = useRouteMatch<{ fieldId: string }>({ path: routes.editField, exact: true });
  const editingModel = useRouteMatch<{ fieldsetId: string }>({
    path: routes.editModel,
    exact: true
  });
  const creatingModel = useRouteMatch({ path: routes.createModel, exact: true });
  const [search, setSearch] = useState<string | undefined>();
  const [modelsFilter, setModelsFilter] = useLocalStorage<string>(
    'modelsFilter',
    modelsFiltersOptions[0]
  );
  const [labelsFilter, setLabelsFilter] = useLocalStorage<string[]>('labelsFilter', []);
  const [labels, setLabels] = useState<LabelFragment[]>();
  const [dropdownOpen, toggleDropdownOpen] = useToggle();

  useQuery(AllLabelsDocument, {
    variables: {
      entityType: 'model'
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const l = data?.allLabels ?? [];
      // Remove any filters that no longer exist from the filters
      setLabelsFilter(labelsFilter.filter(f => l?.map(l => l.id)?.includes(f)));
      setLabels(l);
    }
  });

  const handleResetSearch = useCallback(() => {
    setSearch('');
  }, []);

  const dropdownLabel = useMemo(() => {
    if (labelsFilter?.length === 1) {
      return find(labels, { id: labelsFilter[0] })?.name ?? modelsFilter;
    }
    if (labelsFilter?.length > 1) {
      return `${labelsFilter.length} labels`;
    }
    return modelsFilter;
  }, [labelsFilter, labels, modelsFilter]);

  let items = fieldsets;
  if (modelsFilter === modelsFiltersOptions[1]) {
    items = items.filter(fs => fs.createdBy === user?.id);
  }
  if (labelsFilter?.length) {
    items = items.filter(fs => fs.labels.some(l => labelsFilter.includes(l.id)));
  }
  if (search) {
    const searchVal = search.toLocaleLowerCase().trim();
    items = items.filter(fs => {
      return (
        fs.name.toLocaleLowerCase().includes(searchVal) ||
        fs.realName.toLocaleLowerCase().includes(searchVal) ||
        fs.connection.name.toLocaleLowerCase().includes(searchVal) ||
        hasItems(
          fs.fields.filter(
            f =>
              f.label.toLocaleLowerCase().includes(searchVal) ||
              f.sourceName.toLocaleLowerCase().includes(searchVal)
          )
        )
      );
    });
  }

  return (
    <>
      {hasItems(fieldsets) && (
        <div className="border-b border-gray-300 bg-white p-2">
          <SearchWithAction
            frontAction={
              <DropdownMenu onOpenChange={toggleDropdownOpen}>
                <DropdownMenuTrigger asChild>
                  <button
                    className={cx(
                      'flex h-8 max-w-[6.625rem] items-center justify-between rounded-l-full border-r border-gray-300 p-1 focus:outline-none',
                      dropdownOpen
                        ? 'bg-gray-200'
                        : 'bg-gray-100 hover:bg-gray-200 active:bg-gray-200'
                    )}
                  >
                    <p className="ml-2 max-w-[6rem] overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium">
                      {dropdownLabel}
                    </p>
                    <span className="ml-1 mr-0.5">
                      <Icon
                        name="SelectSingle"
                        className={cx(
                          'h-5 w-5 transform text-gray-500',
                          dropdownOpen && 'rotate-180'
                        )}
                      />
                    </span>
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56" align="start">
                  <DropdownMenuLabel>Models</DropdownMenuLabel>
                  <DropdownMenuRadioGroup value={modelsFilter} onValueChange={setModelsFilter}>
                    {modelsFiltersOptions.map(opt => (
                      <DropdownMenuRadioItemCircle
                        key={opt}
                        value={opt}
                        onSelect={e => e.preventDefault()}
                        checked={modelsFilter === opt}
                      >
                        {opt}
                      </DropdownMenuRadioItemCircle>
                    ))}
                  </DropdownMenuRadioGroup>
                  {!!labels?.length && (
                    <>
                      <DropdownMenuSeparator />
                      <DropdownMenuLabel>Labels</DropdownMenuLabel>

                      {labels
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        .map(label => (
                          <DropdownMenuCheckboxItem
                            key={label.id}
                            checked={!!labelsFilter.find(l => l === label.id)}
                            onSelect={e => e.preventDefault()}
                            onCheckedChange={checked =>
                              setLabelsFilter(
                                checked
                                  ? [...labelsFilter, label.id]
                                  : labelsFilter.filter(l => l !== label.id)
                              )
                            }
                          >
                            {label.name}
                          </DropdownMenuCheckboxItem>
                        ))}
                    </>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            }
            debounce={true}
            onChange={setSearch}
            onReset={handleResetSearch}
            stopEscHotKey={!!(creatingModel || editingField || editingModel)}
          />
        </div>
      )}
      <div
        className={cx(hasItems(fieldsets) ? 'h-[calc(100%-49px)]' : 'h-full', 'overflow-y-auto')}
      >
        {creatingModel && <ModelStub />}
        {props.error && (
          <ul className="px-6 pt-6 text-red-500">
            <Icon name="DangerFilled" className="mb-1 h-6 w-6 text-red-500" />
            {props.error.message ? (
              <Lister items={props.error} />
            ) : (
              <p className="text-red-500">Error retrieving data models. Try refreshing the page.</p>
            )}
          </ul>
        )}
        {hasItems(items)
          ? items.map(fs => <FieldsetRow key={fs.id} fieldset={fs} />)
          : !creatingModel && (
              <div className="p-6">
                {fieldsets.length === 0 && (
                  <Icon name="InfoFilled" className="mb-1 h-5 w-5 text-gray-400" />
                )}
                <p className="text-gray-500">
                  {fieldsets.length === 0
                    ? 'No data models defined.'
                    : 'No models or fields match your search.'}
                </p>
              </div>
            )}
      </div>
    </>
  );
}
