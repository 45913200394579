import { useToggle } from '~/hooks';
import { cn } from '~/lib/utils';
import { CaretRight } from './Icons';

interface ShowMoreProps {
  summary: string;
  full: string;
}

export function ShowMore({ summary, full }: ShowMoreProps) {
  const [show, toggle] = useToggle();

  const handleToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    toggle();
  };

  if (summary.length === full.length) {
    return <span>{full}</span>;
  }

  return (
    <div className="flex flex-col items-start">
      <span>{show ? full : summary}</span>
      <button
        className="link flex font-sans"
        onClick={handleToggle}
        onPointerDown={e => e.stopPropagation()}
      >
        <span>{show ? 'Show less' : 'Show more'}</span>
        <CaretRight
          className={cn(
            show ? '-rotate-90' : 'translate-y-px rotate-90',
            'h-5 w-5 text-blue-400 duration-200 group-hover:text-blue-600'
          )}
        />
      </button>
    </div>
  );
}
