import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef, useCallback } from 'react';
import { Icon } from '../Icon';

interface ParamButtonProps extends ComponentPropsWithoutRef<'button'> {
  action?: 'add' | 'delete'; //Made optional so button can use as placeholder
  disabled?: boolean;
  hidden?: boolean;
  readOnly?: boolean;
}

export const ParamButton = forwardRef<HTMLButtonElement, ParamButtonProps>(
  ({ action, className, onClick, disabled, hidden, readOnly, ...props }, ref) => {
    // SX
    const SHARED_SX = clsx('h-5 w-5 focus:outline-none', hidden && 'invisible');
    const BUTTON_SX = clsx(
      'group focus:rounded-full focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2',
      SHARED_SX,
      disabled || readOnly ? 'cursor-not-allowed' : 'cursor-pointer',
      className
    );
    const ICON_SX = clsx(
      SHARED_SX,
      (disabled || readOnly) && 'text-gray-300',
      action === 'add' && 'text-green-500 group-hover:text-green-400',
      action === 'delete' && 'text-red-500 group-hover:text-red-400'
    );
    // FX
    const getIconName = useCallback(() => {
      switch (action) {
        case 'add':
          return 'PlusFilled';
        case 'delete':
          return 'MinusFilled';
        // What should the default be?
        default:
          return 'PlusFilled';
      }
    }, [action]);

    return (
      <button
        ref={ref}
        tabIndex={disabled || readOnly ? -1 : undefined}
        className={BUTTON_SX}
        onClick={disabled || readOnly ? undefined : onClick}
        {...props}
      >
        <Icon name={getIconName()} className={ICON_SX} />
      </button>
    );
  }
);

if (import.meta.env.MODE === 'development') {
  ParamButton.displayName = 'ParamButton';
}
export default ParamButton;
