import { Reference, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Button, MyInput, SideBySide } from '~/components';
import { Dialog } from '~/components/v3';
import { PermissionTagFragment, CloneTagDocument } from '~/generated/graphql';
import { useBannerDispatch } from '~/hooks';

export type CloneTagDialogProps = {
  tag: PermissionTagFragment;
  toggleDialog: () => void;
};

const CloneTagDialog = ({ tag, toggleDialog }: CloneTagDialogProps) => {
  const dispatchBanner = useBannerDispatch();
  const [cloneTargetName, setCloneTargetName] = useState('');

  const wrapperStyles = 'mb-3';

  const handleClone = () => {
    dispatchBanner({ type: 'hide' });
    void cloneTag({ variables: { name: cloneTargetName, id: tag.id } });
  };

  const [cloneTag, { loading: isCloning }] = useMutation(CloneTagDocument, {
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
      toggleDialog();
    },
    update: (cache, { data }) => {
      if (!data?.cloneTag) {
        return;
      }
      cache.modify({
        fields: {
          allTags: (existing: Reference[], { toReference }) => {
            return [...existing, toReference(data.cloneTag)];
          }
        }
      });
      toggleDialog();
    }
  });

  return (
    <Dialog
      show
      onDismiss={toggleDialog}
      heading={`Clone ${tag.name} policy`}
      actions={
        <>
          <Button onClick={toggleDialog}>Cancel</Button>
          <Button theme="primary" onClick={handleClone} disabled={isCloning}>
            Clone policy
          </Button>
        </>
      }
    >
      <div className="flex flex-col space-y-2 ">
        <SideBySide heading="Name of new policy">
          <MyInput
            defaultValue={`Copy of ${tag.name}`}
            onBlur={e => {
              const { value } = e.currentTarget;
              setCloneTargetName(value);
            }}
            name="cloned-tag-name"
          />
        </SideBySide>
      </div>
    </Dialog>
  );
};
export default CloneTagDialog;
