import { JSONSchema4 } from 'json-schema';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createFilter, OptionsType, ValueType } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { CreatableProps } from 'react-select/src/Creatable';

import { CompletionValue, ParameterValueSource } from '../../../generated/graphql';
import { DropdownIndicator, selectStyles } from '../../../utils';
import { EditPermission } from '../../edit-permission';
import { DisabledSelect } from '../../form-components';
import { SchemaFormElementProps } from './elements';

function isOptionsArray(
  opt: ValueType<CompletionValue, boolean>
): opt is OptionsType<CompletionValue> {
  return Array.isArray(opt);
}

export interface CompletedElementProps {
  getOptions?: (field: string, query?: string) => Promise<CompletionValue[]>;
}

export const TypeAheadParameterInput = (props: SchemaFormElementProps & CompletedElementProps) => {
  let Select = AsyncSelect;

  const selectProps: Partial<CreatableProps<Record<string, never>, boolean>> = {};

  // see if we allow creation;
  if (
    props.field.oneOf &&
    props.field.oneOf.findIndex((one: JSONSchema4) => one.type === 'string' && !one.completions) >
      -1
  ) {
    // this input allows arbitrary strings
    Select = AsyncCreatableSelect;
    selectProps.createOptionPosition = 'first';
    selectProps.formatCreateLabel = (value: string): React.ReactNode => value;
  }

  const [defaultOptions, setDefaultOptions] = React.useState<CompletionValue[]>([]);

  const { setValue, register } = useFormContext();

  React.useEffect(() => {
    let mounted = true;

    const updateDefaults = async (): Promise<void> => {
      if (props.getOptions) {
        const options = await props.getOptions(props.id);
        if (mounted) {
          setDefaultOptions(options);
        }
      }
    };
    void updateDefaults();
    return (): void => {
      mounted = false;
    };
  }, [props]);

  const promiseOptions = async (inputValue: string): Promise<CompletionValue[]> => {
    if (props.getOptions) {
      return await props.getOptions(props.id, inputValue);
    }
    return [];
  };

  function formatOptionLabel({ label }: CompletionValue) {
    return <div className="text-sm">{label}</div>;
  }

  const [value, setLocalValue] = React.useState<CompletionValue | null>(
    (props.defaultValue as CompletionValue) || null
  );
  React.useEffect(() => {
    if (value) {
      setValue(props.name, value.value);
    }
  }, [props.name, props.defaultValue, register, setValue, value]);

  return (
    <Controller
      name={props.name}
      defaultValue={value}
      render={() => (
        <EditPermission fallback={<DisabledSelect valueLabel={value?.label} />}>
          <Select
            {...selectProps}
            filterOption={createFilter({ ignoreAccents: false })}
            className={props.className}
            components={{
              DropdownIndicator,
              ClearIndicator: null,
              IndicatorSeparator: null
            }}
            styles={selectStyles}
            defaultOptions={defaultOptions}
            options={defaultOptions}
            loadOptions={promiseOptions}
            value={value}
            formatOptionLabel={formatOptionLabel}
            getNewOptionData={(inputValue: string): CompletionValue => ({
              label: inputValue,
              value: {
                displayValue: inputValue,
                value: inputValue,
                source: ParameterValueSource.Literal
              }
            })}
            onChange={(newValue): void => {
              // update the form value
              if (isOptionsArray(newValue)) {
                setValue(
                  props.name,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  newValue.map(nv => nv.value)
                );
              } else {
                setValue(props.name, newValue && newValue.value);
              }
              (props.dependencies || []).forEach((dep: string) => {
                setValue(dep, null);
              });
              // update our local state & fire the change event
              setLocalValue(newValue);
              props.onChange();
            }}
          />
        </EditPermission>
      )}
    />
  );
};
