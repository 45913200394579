import cx from 'clsx';
import * as React from 'react';

import { COLOR } from '../v2/configs/SX';
import LoadingDots from '../v2/feedback/LoadingDots';

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  loading?: boolean;
  readOnly?: boolean;
  position?: 'static' | 'fixed' | 'absolute' | 'relative' | 'sticky';
}

export const LinkButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ loading, position = 'relative', className, children, disabled, onClick, ...props }, ref) => {
    return (
      <button
        {...props}
        ref={ref}
        className={cx(
          position,
          disabled || props.readOnly ? 'cursor-not-allowed text-gray-400' : 'link',
          className
        )}
        disabled={loading || disabled}
        onClick={disabled || props.readOnly ? undefined : onClick}
      >
        {loading && (
          <span className="absolute left-1/2 mt-1 -translate-x-1/2 transform">
            <LoadingDots color={COLOR.INFO} />
          </span>
        )}
        <span className={cx(loading ? 'invisible' : 'visible')}>{children}</span>
      </button>
    );
  }
);
