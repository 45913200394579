import { JSONSchema4 } from 'json-schema';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { GroupProps, GroupTypeBase, OptionTypeBase } from 'react-select';

import { isString } from 'lodash';
import React from 'react';
import { ComboboxProps, DisabledSelect, EditPermission, Label, MyCombobox } from '~/components';
import { FieldsetFormValues, GroupedHeading, Selectable } from '~/utils';

export type GroupedHeadingProps<T> = GroupProps<T, false> & {
  data: {
    label: string;
    options: T[];
  };
};

const GroupHeading = <_, T = OptionTypeBase>(props: GroupedHeadingProps<T>) => {
  return <GroupedHeading label={props.data.label} optionsCount={props.data.options.length} />;
};

export type EnumPickerProps = Omit<ComboboxProps, 'onChange'> & {
  item: JSONSchema4 | undefined;
  onChange: () => void;
  className?: string;
  grouped?: boolean;
  noDataMsg?: string;
  loading?: boolean;
  hideLabel?: boolean;
  fieldOptions?: RegisterOptions;
};

export const EnumPicker = ({
  item,
  onChange,
  className,
  grouped,
  noDataMsg,
  loading,
  hideLabel,
  fieldOptions,
  ...props
}: EnumPickerProps) => {
  const { register, getValues, setValue } = useFormContext<FieldsetFormValues>();
  register(item?.name, fieldOptions);

  /**
   * Controlled value allows this component to manually control the selected value
   */
  const [controlledValue, setControlledValue] = React.useState<Selectable | null>(null);

  /**
   * This function is a handler for when the value changes
   * It sets the form value
   * Once the form value is set, it triggers an onChange event
   * After the form value is updated the useEffect will update the controlled value (see below)
   */
  const handleChange = (option: Selectable | null) => {
    if (option == null) {
      return;
    }
    if (option !== controlledValue) {
      setValue(item?.name, option?.value);
      onChange();
    }
  };

  /**
   * Options are memoized to prevent unnecessary re-renders
   * If the options are grouped, they are grouped by schema and formatted as GroupTypeBase<Selectable>
   * Otherwise, they are just a list of options formated as Selectables
   */
  const options: (Selectable | GroupTypeBase<Selectable>)[] = React.useMemo(() => {
    if (grouped) {
      const groupedOptions: GroupTypeBase<Selectable>[] = [];
      const cache: Record<string, GroupTypeBase<Selectable>> = {};
      item?.enum?.forEach(opt => {
        const val = String(opt);
        const [schema, ...table] = val.split('.');

        if (!cache[schema]) {
          cache[schema] = {
            label: schema,
            options: []
          };
          groupedOptions.push(cache[schema]);
        }
        cache[schema].options = cache[schema].options.concat({
          label: table.join('.'),
          value: val
        });
      });
      return groupedOptions;
    } else {
      const opts =
        (item?.enum as (string | Selectable)[])?.map(option => {
          if (typeof option === 'object') {
            return {
              label: option?.label || option?.value,
              value: option?.value
            };
          }
          return { label: option, value: option };
        }) || [];
      return opts;
    }
  }, [item, grouped]);

  /**
   * This effect is used to set the controlled value when the options change
   *
   * (This is verbose, but please read to understand why this is necessary)
   *
   * - This effect will only run when the following conditions are met:
   *  1. One of the following changes:
   *      - Item name
   *      - Value of the form for the item name
   *      - Memoized options
   *      - Whether the options are grouped
   *      - Whether the component is loading
   *  2. The component is not loading (loading === false)
   *
   * - When all the above conditions are met
   *  - If the options ARE grouped (grouped === true):
   *      - The options are flattened to a list of Selectables
   *      - The value of the form is used to find the corresponding Selectable
   *  - And if the options are NOT grouped (grouped === false):
   *      - If the form value (getValues(item.name)) is undefined:
   *          - Do nothing
   *          - Why?
   *              - tl;dr; This prevents unwanted `onChange` callbacks to be fired (see `handleChange` function).
   *              - Long answer:
   *                  Because the parent form is bootstraped asynchronously, the form may exit a loading state before the form
   *                    provider is updated. Basically, the API will return a response, which will cause the form to exit the
   *                    loading state, but the form provider will not be updated until the next render cycle.
   *                  Because of this:
   *                      - The form value to be undefined on the first render after the loading state exits
   *                      - The component to be rendered with no value.
   *                      - And once the form provider is updated with the response from the API, the form value will updated
   *                      - This will trigger an unwanted onChange callback (via `handleChange`), because this effect will rerun each time the form value changes
   *
   *     - If the form value is not undefined (formValue !== undefined):
   *          - If the form value is not null (formValue !== null) -> update the controlled value to the corresponding Selectable
   *              This is to keep the forms value in sync with the controlled value
   *          - If the form value is empty(formValue === null)
   *              This means either:
   *                  1. The form provider has been reset or initialized
   *                  2. The form value has been reset or initialized
   *              In either case we want autocomplete this input component through an `onChange` callback, setting the value to either:
   *                  1. The default value (item?.default)
   *                  2. Or if the item has an enum, (item?.enum) -> The first value in the enum (item?.enum[0])
   *              If the item has neither a default value or an enum, then no `onChange` callback will be fired
   *
   *  NOTE: Once the `onChange` callback is fired, the form value will be updated. The component will rerender, and this effect will rerun.
   *      Once this effect reruns, the form value will be defined, and the controlledValue will be updated to the form value.
   *      This will syncronize the controlledValue with the form value. This effect will not run again until the form value changes.
   *
   */
  React.useEffect(() => {
    if (!loading) {
      const formValue = getValues(item?.name);
      if (grouped) {
        setControlledValue(
          (options as GroupTypeBase<Selectable>[])
            .flatMap(group => group.options)
            .find(opt => opt.value === getValues(item?.name)) || null
        );
      } else if (item?.name && options) {
        if (formValue !== undefined) {
          if (formValue || isString(formValue)) {
            setControlledValue(
              (options?.find(option => option.value === getValues(item?.name)) as Selectable) ||
                null
            );
          } else if (item?.default) {
            handleChange(
              (options?.find(option => option.value === item?.default) as Selectable) || null
            );
          } else if (item?.enum?.length === 1) {
            handleChange(
              (options?.find(option => option.value === item?.enum[0]) as Selectable) || null
            );
          }
        }
      }
    }
  }, [item?.name, getValues(item?.name), options, grouped, loading]);

  return (
    // If the item is undefined, or the item has no enum, or the enum is empty
    !item || !item.enum || item.enum.length === 0 ? (
      // If there is a noDataMsg, and the component is not loading -> show the noDataMsg or nothing
      noDataMsg && !loading ? (
        <div className="flex h-16 items-center justify-center text-gray-500" children={noDataMsg} />
      ) : null
    ) : (
      // If the item is defined, and the item has an enum, and the enum is not empty -> render the component
      <div className={className}>
        {!hideLabel && (
          <div>
            <Label>{item.title}</Label>
          </div>
        )}
        <EditPermission fallback={<DisabledSelect valueLabel={controlledValue?.label} />}>
          <MyCombobox
            aria-label={props.ariaLabel}
            components={{ GroupHeading }}
            value={controlledValue}
            options={options}
            onChange={handleChange}
            isLoading={loading}
            {...props}
          />
        </EditPermission>
      </div>
    )
  );
};
