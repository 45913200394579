import { useReactiveVar } from '@apollo/client';
import * as React from 'react';

import { expandedSearchedSchemasVar } from '../apollo';
import { DatabaseSchemaFragment, SqlRunnerSchemaV2Fragment } from '../generated/graphql';
import { isRunnerCatalog, isRunnerSchema, isRunnerTable, SchemaNestedTypes } from '../utils';

export type ExpandedSearchedSchemasReducerActions =
  | { type: 'setOpen'; id: string }
  | { type: 'toggle'; id: string }
  | { type: 'reset' };

function expandedSchemasReducer(
  prev: Record<string, boolean>,
  action: ExpandedSearchedSchemasReducerActions
) {
  switch (action.type) {
    case 'setOpen':
      return {
        ...prev,
        [action.id]: true
      };
    case 'toggle':
      return {
        ...prev,
        [action.id]: !prev[action.id]
      };
    case 'reset':
      return {};
    default:
      return prev;
  }
}

export const useExpandedSearchedSchemas = () => {
  const cache = useReactiveVar(expandedSearchedSchemasVar);
  const value = React.useMemo(() => cache || {}, [cache]);
  return value;
};

export function markHierarchiesOpen(
  data: Array<SqlRunnerSchemaV2Fragment | DatabaseSchemaFragment> | undefined | null
) {
  if (!data) {
    return;
  }
  for (const item of data) {
    markHierarchyOpen(item);
  }
}

export function markHierarchyOpen(item: SchemaNestedTypes) {
  if (isRunnerCatalog(item)) {
    if (item.schemas) {
      dispatchExpandedSearchedSchemas({ type: 'setOpen', id: item.name || '' });
      for (const innerItem of item.schemas) {
        markHierarchyOpen(innerItem);
      }
    }
  }
  if (isRunnerSchema(item)) {
    if (item.tables) {
      dispatchExpandedSearchedSchemas({ type: 'setOpen', id: item.name || '' });
      for (const innerItem of item.tables) {
        markHierarchyOpen(innerItem);
      }
    }
  }
  if (isRunnerTable(item)) {
    if (item.columns && item.columns.length > 0) {
      dispatchExpandedSearchedSchemas({ type: 'setOpen', id: item.name || '' });
    }
  }
}

export function dispatchExpandedSearchedSchemas(action: ExpandedSearchedSchemasReducerActions) {
  const prev = expandedSearchedSchemasVar() || {};
  expandedSearchedSchemasVar(expandedSchemasReducer(prev, action));
}
