import { TypePolicies } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

import { cacheRedirect } from '../utils';

export const typePolicies: TypePolicies = {
  // this disables normalization of all
  // ModelFields in the app. Not ideal
  // but a workaround to avoid the nasty
  // cache bugs when editing fieldsets
  ModelField: { keyFields: false },
  // same reason for disabling as above
  Fieldset: { keyFields: false },
  Continuation: { keyFields: false },
  TargetField: { keyFields: false },
  TargetObject: { keyFields: false, merge: true },
  // merge: true is shorthand for
  // merging existing obj with incoming obj
  Connection: { merge: true },
  ConnectionType: { merge: true },
  IdentityMapping: { merge: true },
  BulkSync: {
    fields: {
      schedule: { merge: true },
      nextExecutionTime: { merge: true },
      schemas: { merge: false },
      namespaces: { merge: false }
    }
  },
  BulkSelectedNamespace: { keyFields: false },
  BulkSelectedSchema: { keyFields: false },
  BulkSelectedField: { keyFields: false },
  BulkSyncExecution: {
    merge: true,
    fields: {
      recordCount: {
        merge: (existing: number, incoming: number) => {
          if (existing != null && existing > incoming) {
            return existing;
          }
          return incoming;
        }
      }
    }
  },
  Sync: {
    merge: true,
    fields: {
      schedule: { merge: true },
      currentExecution: { merge: true },
      lastExecution: { merge: true },
      nextExecutionTime: { merge: true },
      // prefer incoming over existing
      fields: { merge: false },
      tags: { merge: false }
    }
  },
  SyncExecution: {
    merge: true,
    fields: {
      recordCount: {
        merge: (existing: number, incoming: number) => {
          if (existing != null && existing > incoming) {
            return existing;
          }
          return incoming;
        }
      },
      records: { merge: true },
      inserts: { merge: true },
      updates: { merge: true },
      warnings: { merge: true },
      errors: { merge: true }
    }
  },
  Field: {
    // HACK: this is a workaround for the ID of a field not being unique.
    // This was casuing an issue in bulk syncs with `slowMode` displaying
    // as `true` even though the value from the server was `true`
    keyFields: ['id', 'name', 'type', 'partitionable', 'trackable', 'slowMode', 'slowReason']
  },
  Query: {
    fields: {
      // prevent apollo from caching
      // queries with different args
      applyFieldsetUpdate: { keyArgs: false, merge: false },
      runQuery: { keyArgs: false, merge: false },
      exportQuery: { keyArgs: false, merge: false },
      field: { keyArgs: false },
      model: { keyArgs: false },
      // tell apollo which arg can be
      // considered the "id" for caching
      syncExecutionStatus: { keyArgs: ['executionID'] },
      getPolicy: { keyArgs: ['tagID'] },
      syncsForModel: { keyArgs: ['modelId'] },
      // sync history pagination
      syncExecutions: relayStylePagination(['syncID']),
      bulkSyncExecutions: relayStylePagination(['syncID']),
      // redirect these queries to the cache
      connectionType: { read: cacheRedirect('ConnectionType') }
    }
  },
  Subscription: {
    fields: {
      syncStatus: { keyArgs: ['syncID'] },
      bulkSyncStatus: { keyArgs: ['syncID'] }
    }
  },
  SupportedIdentityFunction: {
    keyFields: ['id', 'label']
  }
};
