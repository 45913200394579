import { useMutation } from '@apollo/client';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button, MyInput, Permission } from '~/components';
import { Dialog } from '~/components/v3';
import { AddUserDocument, ResourceType } from '~/generated/graphql';
import { useBannerDispatch, useToggle } from '~/hooks';
import { REGEX_EMAIL } from '~/utils';

const wrapperStyles = 'mb-3';

export function AddUser() {
  const [showAddDialog, toggleShowAddDialog] = useToggle();
  const { register, handleSubmit, formState, reset, clearErrors } = useForm<{ email: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });
  const { errors } = formState;
  const dispatchBanner = useBannerDispatch();
  const emailRef = React.useRef<string | null>(null);

  const [addUser] = useMutation(AddUserDocument, {
    onError: error =>
      dispatchBanner({
        type: 'show',
        payload: {
          message: error,
          wrapper: wrapperStyles
        }
      }),
    update: (cache, { data }) => {
      if (!data || !data.addUser) {
        return;
      }
      dispatchBanner({
        type: 'show',
        payload: {
          message: `Invitation sent to ${emailRef.current || 'email'}`,
          theme: 'success',
          autoFade: true,
          wrapper: wrapperStyles
        }
      });
      cache.modify({
        fields: {
          users: (_, { toReference }) => {
            const usersRef = data.addUser.map(user => {
              return toReference({
                __typename: 'User',
                id: user.id
              });
            });
            return usersRef;
          }
        }
      });
    }
  });

  function toggleAddDialog() {
    clearErrors();
    reset();
    toggleShowAddDialog();
  }

  const onAddUser: SubmitHandler<{ email: string }> = data => {
    if (!data || !data.email) {
      reset();
      return;
    }
    const email = data.email.trim();
    emailRef.current = email;
    void addUser({ variables: { email } });
    toggleAddDialog();
  };

  return (
    <div className="mx-auto w-full max-w-4xl">
      <Permission type={ResourceType.User}>
        <Button theme="outline" onClick={toggleAddDialog}>
          Add user
        </Button>
      </Permission>
      <Dialog
        size="md"
        show={showAddDialog}
        heading="Add user"
        actions={
          <>
            <Button onClick={toggleAddDialog}>Cancel</Button>
            <Button theme="primary" onClick={handleSubmit(onAddUser)}>
              Add user
            </Button>
          </>
        }
      >
        <form onSubmit={handleSubmit(onAddUser)}>
          <MyInput
            className="max-w-xs"
            label="Email address"
            placeholder="someone@company.com"
            errors={errors}
            {...register('email', {
              validate: {
                regex: (value: string) => {
                  if (!value) {
                    return true;
                  }
                  const val = value.trim();
                  if (val === '') {
                    return true;
                  }
                  return REGEX_EMAIL.test(val) || 'Invalid email address';
                }
              }
            })}
          />
        </form>
      </Dialog>
    </div>
  );
}
