import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  Button,
  EditPermission,
  InlineMessage,
  MyInput,
  ParamButton,
  SideBySide
} from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { NotificationsDocument, UpdateSyncNotificationsDocument } from '~/generated/graphql';
import { useBannerDispatch } from '~/hooks';
import { REGEX_EMAIL } from '~/utils';

const wrapperStyles = 'px-3 pt-3';

interface FormValues {
  email: string;
}

interface Props {
  id: string;
  notificationEvent: string;
}

export function AddSubscriber({ id, notificationEvent }: Props) {
  const dispatchBanner = useBannerDispatch();
  const [emails, setEmails] = React.useState<string[]>([]);
  const [showInlineMessage, setShowInlineMessage] = React.useState(false);
  const { register, handleSubmit, formState, reset, clearErrors } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });
  const { errors } = formState;

  const { loading: subscribersLoading } = useQuery(NotificationsDocument, {
    variables: { scope: id, event: notificationEvent },
    onCompleted: data => {
      if (!data || !data.notificationRecipients) {
        return;
      }
      setEmails(data.notificationRecipients.map(item => item.recipientId));
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } }),
    fetchPolicy: 'no-cache'
  });

  const [updateSubscribers, { loading: updateSubscribersLoading }] = useMutation(
    UpdateSyncNotificationsDocument,
    {
      onCompleted: data => {
        if (!data || !data.updateEmailRecipientsForNotification) {
          return;
        }
        setEmails(data.updateEmailRecipientsForNotification.map(item => item.recipientId));
        setShowInlineMessage(true);
        clearErrors();
        reset();
      },

      onError: error =>
        dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } })
    }
  );

  const handleAdd: SubmitHandler<FormValues> = data => {
    if (!data || !data.email) {
      reset();
      return;
    }
    dispatchBanner({ type: 'hide' });
    const newSubscribers = [...emails, data.email.trim()];
    void updateSubscribers({
      variables: { scope: id, event: notificationEvent, emails: newSubscribers }
    });
  };

  function handleDelete(email: string) {
    dispatchBanner({ type: 'hide' });
    const newSubscribers = emails.filter(item => item !== email);
    void updateSubscribers({
      variables: { scope: id, event: notificationEvent, emails: newSubscribers }
    });
  }

  return (
    <SideBySide hasSectionWrap heading="Error subscribers" styles="relative space-y-6">
      <form className="absolute flex w-full" onSubmit={handleSubmit(handleAdd)}>
        <div className="w-full max-w-[15rem]">
          <EditPermission>
            <MyInput
              label="Email address"
              placeholder="someone@company.com"
              errors={errors}
              disabled={subscribersLoading || updateSubscribersLoading}
              {...register('email', {
                validate: {
                  regex: (value: string) => {
                    if (!value) {
                      return true;
                    }
                    const val = value.trim();
                    if (val === '') {
                      reset();
                      return true;
                    }
                    return REGEX_EMAIL.test(val) || 'Invalid email address';
                  },
                  dupe: (value: string) => {
                    if (!value) {
                      return true;
                    }
                    const val = value.trim();
                    if (val === '') {
                      reset();
                      return true;
                    }
                    return !emails.includes(val) || 'Email address is already added';
                  }
                }
              })}
            />
          </EditPermission>
        </div>
        <EditPermission>
          <Button
            className="mt-6 ml-2 overflow-hidden whitespace-nowrap"
            type="submit"
            disabled={subscribersLoading || updateSubscribersLoading}
          >
            Add subscriber
          </Button>
        </EditPermission>
        <InlineMessage
          className="ml-2 mb-1.25 self-end"
          show={showInlineMessage}
          autoDismiss={() => setShowInlineMessage(false)}
          text="Saved"
        />
      </form>

      <ul className="absolute top-16 w-full max-w-[23.5rem] space-y-2 pb-6">
        {!subscribersLoading ? (
          emails.map((email, idx) => (
            <li key={email || idx} className="flex w-full items-center justify-between">
              <p className="text-sm text-gray-800">{email}</p>
              <EditPermission>
                <ParamButton
                  action="delete"
                  className="focus-visible:ring-offset-gray-100"
                  disabled={subscribersLoading || updateSubscribersLoading}
                  onClick={() => handleDelete(email)}
                />
              </EditPermission>
            </li>
          ))
        ) : (
          <LoadingDots />
        )}
      </ul>
    </SideBySide>
  );
}
