import { JSONSchemaForm } from '../JSONSchemaForm';
import { ScheduleProps } from './SchedulePicker';

export function FrequencyDbtcloud({ schedule, onScheduleChange, schema }: ScheduleProps) {
  return (
    <div className="w-1/2">
      <JSONSchemaForm
        schema={schema?.jsonschema}
        uiSchema={{
          'ui:submitButtonOptions': {
            norender: true
          }
        }}
        formData={schedule}
        onChange={values => onScheduleChange({ ...schedule, ...values })}
      />
    </div>
  );
}
