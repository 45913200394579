import clsx from 'clsx';

import { COLOR, SIZE, TextColor, TextSize } from '../configs/SX';

export type InputLabelProps = {
  inputId?: string;
  enablePointer?: boolean;
  children?: React.ReactNode;
  color?: keyof typeof TextColor;
  size?: keyof typeof TextSize;
};

const InputLabel = ({
  inputId = undefined,
  enablePointer = true,
  children,
  color = COLOR.DEFAULT,
  size = SIZE.MEDIUM
}: InputLabelProps) => {
  return (
    <label
      className={clsx(
        'leading-tight',
        TextColor[color],
        TextSize[size],
        enablePointer && 'cursor-pointer'
      )}
      htmlFor={inputId}
    >
      {children}
    </label>
  );
};

export default InputLabel;
