import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { omit, sortBy } from 'lodash';
import { useState } from 'react';
import { Button, Icon, Label, MyInput, SideBySide, TableWrap } from '~/components';
import { Dialog } from '~/components/v3';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import {
  DeleteLabelDocument,
  FieldsetsForLabelDocument,
  LabelMetadata,
  LabelMetadataDocument,
  UpdateLabelDocument
} from '~/generated/graphql';
import { useBannerDispatch } from '~/hooks';
import { cn } from '~/lib/utils';
import { plural } from '~/utils';

type Label = Omit<LabelMetadata, '__typename'>;

type FieldsetPreview = {
  id: string;
  name: string;
  connection: {
    type: {
      id: string;
    };
  };
};

interface LabelRowProps {
  label: Label;
  onEdit: () => void;
  onDelete: () => void;
}

function LabelRow({ label, onEdit, onDelete }: LabelRowProps) {
  const [hovering, setHovering] = useState<boolean>();
  const [open, setOpen] = useState<boolean>();

  const handleDropdownChange = open => {
    setOpen(open);
  };

  return (
    <tr
      key={label.id}
      className={cn('even:bg-gray-50 hover:bg-indigo-50', open ? '!bg-indigo-100' : '')}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <td className="inline-flex w-full items-center py-2 px-4">{label.name}</td>
      <td className="py-2 px-4">{label.modelCount}</td>
      <td className="py-2 pr-2">
        <DropdownMenu onOpenChange={handleDropdownChange}>
          <DropdownMenuTrigger asChild>
            <button
              className={clsx(
                'rounded p-[2px] hover:bg-gray-300',
                hovering || open ? 'opacity-100' : 'opacity-0'
              )}
            >
              <Icon name="DotsH" size="sm" />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56" align="end">
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={onEdit}>Edit label</DropdownMenuItem>
              <DropdownMenuItem onClick={onDelete}>Delete</DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </td>
    </tr>
  );
}

export function Labels() {
  const [showEditDialog, setShowEditDialog] = useState<boolean>();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>();
  const [activeLabel, setActiveLabel] = useState<Label>();
  const [newLabelName, setNewLabelName] = useState<string>('');
  const [affectedModels, setAffectedModels] = useState<FieldsetPreview[]>([]);

  const dispatchBanner = useBannerDispatch();

  const { data, loading, refetch } = useQuery(LabelMetadataDocument, {
    fetchPolicy: 'no-cache'
  });
  const [loadAffectedModels] = useLazyQuery(FieldsetsForLabelDocument);

  const [updateLabel] = useMutation(UpdateLabelDocument);
  const [deleteLabel] = useMutation(DeleteLabelDocument);

  const reset = (shouldRefetch?: boolean) => {
    setActiveLabel(null);
    setAffectedModels([]);
    setShowEditDialog(false);
    setShowDeleteDialog(false);
    if (shouldRefetch) {
      refetch();
    }
  };

  const promptEdit = (label: Label) => {
    setActiveLabel(label);
    setNewLabelName(label.name);
    setShowEditDialog(true);
  };

  const promptDelete = async (label: Label) => {
    if (!label.modelCount) {
      handleDelete(label.id);
      return;
    }

    const { data } = await loadAffectedModels({ variables: { labelId: label.id } });
    setAffectedModels(data?.fieldsetsForLabel ?? []);
    setActiveLabel(label);
    setShowDeleteDialog(true);
  };

  const handleEdit = async () => {
    await updateLabel({
      variables: {
        label: {
          id: activeLabel.id,
          name: newLabelName
        }
      },
      onError: error => {
        dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
      }
    });
    reset(true);
  };

  const handleDelete = async (labelId?: string) => {
    await deleteLabel({
      variables: {
        id: labelId ?? activeLabel.id
      },
      onError: error => {
        dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
      }
    });
    reset(true);
  };

  const labels = sortBy(omit(data?.labelMetadata, '__typename'), 'name') as Label[];

  return (
    <>
      <SideBySide hasSectionWrap heading="Model labels">
        {!loading && !labels?.length && (
          <p className="mt-[2px] text-gray-500">No model labels in use.</p>
        )}
        {!!labels?.length && (
          <TableWrap className="mx-auto w-full max-w-4xl overflow-x-auto">
            <table className="min-w-full table-fixed text-left">
              <thead className="h-11 bg-gray-50">
                <tr>
                  <th className="thead-label w-[calc(60%-2.5rem)] border-b border-gray-300 first:rounded-tl">
                    Label
                  </th>
                  <th className="thead-label w-2/5 border-b border-gray-300 first:rounded-tl">
                    Used by
                  </th>
                  <th className="w-10 rounded-tr border-b border-gray-300">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {labels.map(label => (
                  <LabelRow
                    key={label.id}
                    label={label}
                    onEdit={() => promptEdit(label)}
                    onDelete={() => promptDelete(label)}
                  />
                ))}
              </tbody>
            </table>
          </TableWrap>
        )}
      </SideBySide>
      <Dialog
        show={showEditDialog}
        heading="Edit Label"
        onDismiss={reset}
        actions={
          <>
            <Button onClick={() => reset()}>Cancel</Button>
            <Button theme="primary" onClick={() => handleEdit()} disabled={loading}>
              Save
            </Button>
          </>
        }
      >
        <Label>Label</Label>
        <MyInput
          value={newLabelName}
          onChange={e => setNewLabelName(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleEdit();
            }
          }}
        />
      </Dialog>
      <Dialog
        show={showDeleteDialog}
        heading="Delete Label"
        onDismiss={reset}
        actions={
          <>
            <Button onClick={() => reset()}>Cancel</Button>
            <Button
              iconEnd="Trashcan"
              theme="danger"
              onClick={() => handleDelete()}
              disabled={loading}
            >
              Delete Label
            </Button>
          </>
        }
      >
        <div className="flex flex-col gap-4">
          <div>
            <Label>{activeLabel?.name}</Label>
            <p>
              This label will be removed from {activeLabel?.modelCount}{' '}
              {plural('model', activeLabel?.modelCount > 1)}
            </p>
          </div>
          <div className="mx-auto w-full max-w-4xl overflow-x-auto rounded border border-gray-300 bg-white shadow-card">
            {affectedModels?.map(model => (
              <div key={model.id} className="flex flex-row items-center gap-4 px-4 py-2">
                <Icon match={model.connection.type.id} className="mt-1 h-6" />
                <span>{model.name}</span>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </>
  );
}
