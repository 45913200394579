import cx from 'clsx';

import { UpdatedBy } from '../generated/graphql';
import { getDateOnly } from '../utils';
import { SideBySide } from '.';

interface Props {
  updatedBy?: UpdatedBy | null;
  updatedAt?: string | null;
}

export function LastModified(props: Props) {
  if (!props.updatedAt || !props.updatedBy) {
    return null;
  }
  return (
    <SideBySide hasSectionWrap heading="Last modified by" styles="pt-[3px]">
      <span className="text-sm text-gray-800">
        {cx(
          props.updatedBy.name,
          props.updatedBy.email && `<${props.updatedBy.email}>`,
          props.updatedAt && `on ${getDateOnly(props.updatedAt) || ''}`
        )}
      </span>
    </SideBySide>
  );
}
