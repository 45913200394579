const MODELS = 'models';
const SYNCS = 'syncs';
const BULK_SYNCS = 'bulk-syncs';
const CXNS = 'connections';
const RUNNER = 'query-runner';
const TEAM = 'team';
const USER = 'user';

const devRoutes = {
  dev: '/dev'
};

export const routes = {
  root: '/',
  login: '/login',
  identities: '/identities',
  loginError: '/login/error',
  connect: `/polytomic-connect/:type?`,

  // TODO: remove, only needed for testing
  connectInitiate: `/testing/initiate`,
  connectComplete: `/testing/complete`,

  dashboard: '/dashboard',
  connections: `/${CXNS}`,
  connectionsPicker: `/${CXNS}?add`,
  addConnection: `/${CXNS}/add/:connectionTypeId`,
  editConnection: `/${CXNS}/:id`,
  models: `/${MODELS}`,
  createModel: `/${MODELS}/add`,
  editModel: `/${MODELS}/:fieldsetId`,
  editField: `/${MODELS}/:fieldsetId/field/:fieldId`,
  queryRunner: `/${RUNNER}`,
  syncs: `/${SYNCS}`,
  createSync: `/${SYNCS}/create`,
  sync: `/${SYNCS}/:id`,
  syncStatus: `/${SYNCS}/:id/status`,
  syncConfig: `/${SYNCS}/:id/config`,
  syncHistory: `/${SYNCS}/:id/history`,
  syncErrorHandling: `/${SYNCS}/:id/error_handling`,
  createBulkSync: `/${BULK_SYNCS}/create`,
  bulkSyncsRoot: `/${BULK_SYNCS}`,
  bulkSync: `/${BULK_SYNCS}/:id`,
  bulkSyncStatus: `/${BULK_SYNCS}/:id/status`,
  bulkSyncConfig: `/${BULK_SYNCS}/:id/config`,
  bulkSyncHistory: `/${BULK_SYNCS}/:id/history`,
  bulkSyncErrorHandling: `/${BULK_SYNCS}/:id/error_handling`,
  team: `/${TEAM}`,
  teamUsers: `/${TEAM}/users`,
  teamRoles: `/${TEAM}/roles`,
  teamAccessControl: `/${TEAM}/access-control`,
  user: `/${USER}`,
  err404: '/404',
  /* EXPLORE */
  explore: '/explore',
  // serverRedirect - frontend url that handles external links (ex. CSV download links from email)
  serverRedirect: '/o/:org_id/r/:id',
  legacyServerRedirect: '/r/:id',
  // devRoutes - only available in development
  ...(process.env.NODE_ENV === 'development' ? devRoutes : {})
} as const;
