import React, { useCallback, useMemo, useRef } from 'react';

import { formatValue, parsePartArray, partToString } from '../converter';
import { Clicks, CustomSelectProps } from '../types';
import { sort } from '../utils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import { cn } from '~/lib/utils';
import { isEmpty } from 'lodash';
import { Icon } from '~/components';

export default function CustomSelect(props: CustomSelectProps) {
  const {
    value,
    grid = true,
    optionsList,
    setValue,
    humanizeLabels,
    disabled,
    readOnly,
    leadingZero,
    clockFormat,
    unit,
    periodicityOnDoubleClick,
    mode,
    filterOption = () => true,
    placeholder
  } = props;

  const stringValue = useMemo(() => {
    if (value && Array.isArray(value)) {
      return value.map((value: number) => value.toString());
    }
  }, [value]);

  const options = useMemo(
    () => {
      if (optionsList) {
        return optionsList.map((option, index) => {
          const number = unit.min === 0 ? index : index + 1;

          return {
            value: number.toString(),
            label: option
          };
        });
      }

      return [...Array(unit.total)]
        .map((e, index) => {
          const number = unit.min === 0 ? index : index + 1;

          return {
            value: number.toString(),
            label: formatValue(number, unit, humanizeLabels, leadingZero, clockFormat)
          };
        })
        .filter(filterOption);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionsList, leadingZero, humanizeLabels, clockFormat]
  );

  const simpleClick = useCallback(
    (newValueOption: number | number[]) => {
      const newValueOptions = Array.isArray(newValueOption)
        ? sort(newValueOption)
        : [newValueOption];
      let newValue: number[] = newValueOptions;

      if (value) {
        newValue = mode === 'single' ? [] : [...value];

        newValueOptions.forEach(o => {
          const newValueOptionNumber = Number(o);

          if (value.some(v => v === newValueOptionNumber)) {
            newValue = newValue.filter(v => v !== newValueOptionNumber);
          } else {
            newValue = sort([...newValue, newValueOptionNumber]);
          }
        });
      }

      if (newValue.length === unit.total) {
        setValue([]);
      } else {
        setValue(newValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, value]
  );

  const doubleClick = useCallback(
    (newValueOption: number) => {
      if (newValueOption !== 0 && newValueOption !== 1) {
        const limit = unit.total + unit.min;
        const newValue: number[] = [];

        for (let i = unit.min; i < limit; i++) {
          if (i % newValueOption === 0) {
            newValue.push(i);
          }
        }
        const oldValueEqualNewValue =
          value &&
          newValue &&
          value.length === newValue.length &&
          value.every((v: number, i: number) => v === newValue[i]);
        const allValuesSelected = newValue.length === options.length;

        if (allValuesSelected) {
          setValue([]);
        } else if (oldValueEqualNewValue) {
          setValue([]);
        } else {
          setValue(newValue);
        }
      } else {
        setValue([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, options, setValue]
  );

  const clicksRef = useRef<Clicks[]>([]);
  const onOptionClick = useCallback(
    (newValueOption: string) => {
      if (!readOnly) {
        const doubleClickTimeout = 300;
        const clicks = clicksRef.current;

        clicks.push({
          time: new Date().getTime(),
          value: Number(newValueOption)
        });

        const id = window.setTimeout(() => {
          if (
            periodicityOnDoubleClick &&
            clicks.length > 1 &&
            clicks[clicks.length - 1].time - clicks[clicks.length - 2].time < doubleClickTimeout
          ) {
            if (clicks[clicks.length - 1].value === clicks[clicks.length - 2].value) {
              doubleClick(Number(newValueOption));
            } else {
              simpleClick([clicks[clicks.length - 2].value, clicks[clicks.length - 1].value]);
            }
          } else {
            simpleClick(Number(newValueOption));
          }

          clicksRef.current = [];
        }, doubleClickTimeout);

        return () => {
          window.clearTimeout(id);
        };
      }
    },
    [clicksRef, simpleClick, doubleClick, readOnly, periodicityOnDoubleClick]
  );

  const buttonText = useMemo(() => {
    if (isEmpty(stringValue)) {
      return placeholder;
    }
    const parsedArray = parsePartArray(value, unit);
    const cronValue = partToString(parsedArray, unit, humanizeLabels, leadingZero, clockFormat);
    return cronValue;
  }, [stringValue, placeholder]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={disabled} asChild>
        <button className={'flex space-x-1 rounded bg-gray-200 py-1 pl-2 pr-1 hover:bg-gray-300'}>
          {buttonText}
          <Icon className="text-gray-500" name="SelectSingle" />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup grid={grid}>
          {options.map(option => (
            <DropdownMenuItem
              className={cn([
                grid && 'flex-shrink-0 flex-grow-0 basis-[20%] justify-center',
                stringValue?.includes(option.value.toString()) && 'bg-blue-100'
              ])}
              key={option.value}
              onClick={() => onOptionClick(option.value)}
            >
              {option.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
