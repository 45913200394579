import * as React from 'react';

const MOUSEDOWN = 'mousedown';
const TOUCHSTART = 'touchstart';

type HandledEvents = [typeof MOUSEDOWN, typeof TOUCHSTART];
type HandledEventsType = HandledEvents[number];
type PossibleEvent = {
  [Type in HandledEventsType]: HTMLElementEventMap[Type];
}[HandledEventsType];

type Handler = (event: PossibleEvent) => void;

export function useOnClickOutside(
  ref: React.RefObject<HTMLElement> | React.RefObject<HTMLElement>[],
  handler: Handler | null
) {
  React.useEffect(
    () => {
      if (!handler) {
        return;
      }
      const listener = (event: PossibleEvent) => {
        const refs = Array.isArray(ref) ? ref : [ref];
        // Do nothing if clicking ref's element or descendent elements
        if (refs.some(ref => ref?.current?.contains(event.target as Node))) {
          return;
        }
        handler(event);
      };
      document.addEventListener(MOUSEDOWN, listener);
      document.addEventListener(TOUCHSTART, listener);
      return () => {
        document.removeEventListener(MOUSEDOWN, listener);
        document.removeEventListener(TOUCHSTART, listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new
    // function on every render that will cause this effect
    // callback/cleanup to run every render. It's not a big deal
    // but to optimize you can wrap handler in useCallback before
    // passing it into this hook.
    [ref, handler]
  );
}
