import { isValidCronPart, scheduleToCron } from '~/utils';
import { type ScheduleProps } from './SchedulePicker';
import { useMemo } from 'react';
import { CronDisplay } from './CronDisplay';
import { cn } from '~/lib/utils';
import { capitalize, lowerCase } from 'lodash';

const parts = ['minute', 'hour', 'dayOfMonth', 'month', 'dayOfWeek'];

export function FrequencyCustom({ schedule, onScheduleChange }: ScheduleProps) {
  const cronFormString = useMemo(
    () => (schedule ? scheduleToCron(schedule) : undefined),
    [schedule]
  );

  return (
    <div className="mt-4 flex flex-col space-y-4">
      <p>
        Enter a schedule using standard cron syntax{' '}
        <a href="https://crontab.guru/" target="_blank" rel="noopener noreferrer" className="link">
          Assistant ↗
        </a>
      </p>
      <section className="flex w-full items-center justify-between space-x-2">
        {parts.map(part => {
          const isValid = isValidCronPart(schedule[part] ?? '*', parts.indexOf(part));
          return (
            <div key={part} className="min-w-0 max-w-full flex-1">
              <input
                className={cn(
                  'h-8 max-w-full rounded border border-gray-400 px-3 text-sm placeholder-gray-400 shadow-input',
                  'focus:border-indigo-500 focus:shadow-none focus:ring-2 focus:ring-indigo-500',
                  !isValid && 'bg-red-50 ring-2 ring-red-500'
                )}
                type="text"
                id={part}
                name={part}
                value={schedule[part] ?? '*'}
                onChange={e =>
                  onScheduleChange({
                    ...schedule,
                    [part]: e.target.value
                  })
                }
              />
              <label
                className={cn('mt-2 text-xs text-gray-500', !isValid && 'text-red-500')}
                htmlFor="minute"
              >
                {capitalize(lowerCase(part))}
              </label>
            </div>
          );
        })}
      </section>
      <CronDisplay cron={cronFormString} />
    </div>
  );
}
