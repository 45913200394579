import { useCallback, useMemo } from 'react';

import { DEFAULT_LOCALE_EN } from '../locale';
import { PeriodProps, PeriodType } from '../types';
import { classNames } from '../utils';
import { Icon } from '~/components';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import { cn } from '~/lib/utils';

export default function Period(props: PeriodProps) {
  const { value, setValue, locale, className, disabled, readOnly, shortcuts, allowedPeriods } =
    props;
  const options: { label: string; value: PeriodType; disabled?: boolean }[] = [];

  if (allowedPeriods.includes('year')) {
    options.push({
      value: 'year',
      label: locale.yearOption || DEFAULT_LOCALE_EN.yearOption
    });
  }

  if (allowedPeriods.includes('month')) {
    options.push({
      value: 'month',
      label: locale.monthOption || DEFAULT_LOCALE_EN.monthOption
    });
  }

  if (allowedPeriods.includes('week')) {
    options.push({
      value: 'week',
      label: locale.weekOption || DEFAULT_LOCALE_EN.weekOption
    });
  }

  if (allowedPeriods.includes('day')) {
    options.push({
      value: 'day',
      label: locale.dayOption || DEFAULT_LOCALE_EN.dayOption
    });
  }

  if (allowedPeriods.includes('hour')) {
    options.push({
      value: 'hour',
      label: locale.hourOption || DEFAULT_LOCALE_EN.hourOption
    });
  }

  if (allowedPeriods.includes('minute')) {
    options.push({
      value: 'minute',
      label: locale.minuteOption || DEFAULT_LOCALE_EN.minuteOption
    });
  }

  if (
    allowedPeriods.includes('reboot') &&
    shortcuts &&
    (shortcuts === true || shortcuts.includes('@reboot'))
  ) {
    options.push({
      value: 'reboot',
      label: locale.rebootOption || DEFAULT_LOCALE_EN.rebootOption
    });
  }

  const handleChange = useCallback(
    (newValue: PeriodType) => {
      if (!readOnly) {
        setValue(newValue);
      }
    },
    [setValue, readOnly]
  );

  const internalClassName = useMemo(
    () =>
      classNames({
        'react-js-cron-field': true,
        'react-js-cron-period': true,
        [`${className}-field`]: !!className,
        [`${className}-period`]: !!className
      }),
    [className]
  );

  return (
    <div className={internalClassName}>
      {locale.prefixPeriod !== '' && (
        <span>{locale.prefixPeriod || DEFAULT_LOCALE_EN.prefixPeriod}</span>
      )}

      <DropdownMenu>
        <DropdownMenuTrigger disabled={disabled} asChild>
          <button className={'flex space-x-1 rounded bg-gray-200 py-1 pl-2 pr-1 hover:bg-gray-300'}>
            {options.find(o => o?.value === value)?.label}
            <Icon className="text-gray-500" name="SelectSingle"></Icon>
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuGroup>
            {options.map(option => (
              <DropdownMenuItem
                className={cn(option.value === value && 'bg-blue-100')}
                key={option.value}
                onClick={() => handleChange(option.value)}
              >
                {option.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
