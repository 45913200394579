import cx from 'clsx';
import * as React from 'react';

import { InputProps } from './input';
import { Label } from './label';

export const Checkbox = React.forwardRef(
  (
    { label, className, errors, disabled, readOnly, ...props }: InputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <Label
        htmlFor={props.name}
        className={cx(
          !disabled && !readOnly && 'cursor-pointer',
          'focus:outline-none focus-visible:outline-none'
        )}
      >
        <input
          {...props}
          type="checkbox"
          ref={ref}
          id={props.name}
          className={cx(
            'h-4 w-4 self-start border-2 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2',
            disabled || readOnly
              ? 'border-gray-300 bg-gray-100 text-gray-300'
              : 'cursor-pointer border-gray-500 bg-white text-green-500  hover:border-gray-600 hover:text-green-400',
            'rounded',
            label && 'mt-[-3px] mr-1.5',
            className
          )}
          aria-invalid={errors ? 'true' : 'false'}
          disabled={disabled || readOnly}
        />
        {label || null}
      </Label>
    );
  }
);

if (import.meta.env.MODE === 'development') {
  Checkbox.displayName = 'Checkbox';
}
