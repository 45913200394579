import { useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import { Button, MyInput as Input } from '~/components';
import { Dialog } from '~/components/v3';
import { Icon } from '~/components';
import { RequestIntegrationDocument } from '~/generated/graphql';

// TODO: Figure out a better way to manage the state, I don't think this is the
// best way to do this.
type RequestIntegrationStatus = 'incomplete' | 'requesting' | 'success' | 'errored';

interface RequestIntegrationProps {
  onDismiss(): unknown;
}

export function RequestIntegration({ onDismiss }: RequestIntegrationProps) {
  const [requestedIntegration] = useMutation(RequestIntegrationDocument);
  const [request, setRequest] = useState<string>('');
  const [status, setStatus] = useState<RequestIntegrationStatus>('incomplete');

  const handleRequest = async () => {
    if (request.length === 0) {
      // Don't allow submitting if no request
      return;
    }

    setStatus('requesting');

    try {
      await requestedIntegration({
        variables: {
          request
        }
      });
      setStatus('success');
    } catch (e) {
      setStatus('errored');
      captureException(e, {
        extra: {
          message: 'Error while requesting integration.',
          request
        }
      });
    }

    setTimeout(onDismiss, 3000);
  };

  return (
    <Dialog
      actions={
        <>
          <Button disabled={status !== 'incomplete'} onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            disabled={status !== 'incomplete' || request.length === 0}
            theme="primary"
            loading={status === 'requesting'}
            onClick={handleRequest}
          >
            Submit request
          </Button>
        </>
      }
      heading="Request an integration"
      show
      onDismiss={onDismiss}
    >
      <Input
        disabled={status !== 'incomplete'}
        label="What integrations would you like?"
        name="requested"
        placeholder="Enter a list here..."
        value={request}
        onChange={e => setRequest(e.target.value)}
        onKeyUp={e => e.key === 'Enter' && handleRequest()}
      />

      {status === 'success' && (
        <div className="mt-6 flex gap-2 rounded-sm bg-emerald-100 p-2 text-sm text-gray-800">
          <Icon className="h-4 w-4 text-emerald-500" name="CheckFilled" />
          <p>{`Thanks! We'll get in touch about your request.`}</p>
        </div>
      )}
      {status === 'errored' && (
        <div className="mt-6 flex gap-2 rounded-sm bg-red-100 p-2 text-sm text-gray-800">
          <Icon className="h-4 w-4 text-red-500" name="CheckFilled" />
          <p>{`An error occured while making your request. We're looking into it.`}</p>
        </div>
      )}
    </Dialog>
  );
}
