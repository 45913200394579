import dayjs from 'dayjs';
import { parseISO } from 'date-fns';

export function getDateOnly(isoString: string | undefined | null, locale = 'en') {
  if (!isoString) {
    return null;
  }
  const date = dayjs(isoString).toDate();
  return new Intl.DateTimeFormat(locale, {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  }).format(date);
}

export function getShortLocalTime(isoString: string | undefined | null, locale = 'en') {
  if (!isoString) {
    return null;
  }
  const serverTime = dayjs(isoString).toDate();
  return new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  }).format(serverTime);
}

export function getLongLocalTime(isoString: string | undefined | null, locale = 'en') {
  if (!isoString) {
    return null;
  }
  const serverTime = dayjs(isoString).toDate();
  return new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  }).format(serverTime);
}

export function getQueryHistoryTime(isoString: string | undefined | null, locale = 'en') {
  if (!isoString) {
    return null;
  }
  const serverTime = dayjs(isoString).toDate();
  return new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }).format(serverTime);
}

export const formatReadableDate = (date: string) => {
  try {
    const isToday = dayjs(date)?.isSame(dayjs(), 'day');
    if (isToday) {
      return `today at ${dayjs(date)?.format('h:mm A')}`;
    } else {
      const isYesterday = dayjs(date)?.isSame(dayjs().subtract(1, 'day'), 'day');
      if (isYesterday) {
        return `yesterday at ${dayjs(date)?.format('hh:mm a')}`;
      } else {
        return `${dayjs(date)?.format('MMMM DD, YYYY')} at ${dayjs(date).format('hh:mm a')}`;
      }
    }
  } catch (e) {
    return '';
  }
};
function isValidDate(d) {
  return d && !isNaN(d.getTime());
}

export const getISOWithoutTimezone = (date: string) => {
  const d = parseISO(date?.length ? date.replace(/Z+$/, '') : date);
  return isValidDate(d) ? d : undefined;
};
