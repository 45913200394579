import { HTMLAttributes, ReactNode } from 'react';

export type TableBodyProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLTableSectionElement>;

const TableBody = ({ children, ...rest }: TableBodyProps) => {
  return <tbody {...rest}>{children}</tbody>;
};

export default TableBody;
