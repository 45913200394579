import { FieldType, FieldUpdate, ModelFieldFragment } from '../generated/graphql';
import { isStr } from './predicates.util';

export function mapFieldsToFieldUpdates(fields?: ModelFieldFragment[]): FieldUpdate[] {
  if (!fields) {
    return [];
  }
  return fields.map(field => ({
    id: field.id,
    label: field.label,
    example: field.userAdded && isStr(field.example) ? field.example : undefined,
    type: field.type || FieldType.Unknown,
    description: field.description,
    published: field.published,
    userAdded: field.userAdded,
    sourceName: field.sourceName
  }));
}

export function modelFieldToSelectable(field: ModelFieldFragment) {
  if (!field) {
    return;
  }
  return { ...field, value: field.id };
}
