import { JSONSchema4 } from 'json-schema';

import { joinPath } from './helpers.util';
import { isJsonSchema } from './predicates.util';

type KeyVal = Record<string, string>;
type KeyArr = Record<string, string[]>;
type KeySchema = Record<string, JSONSchema4>;

function getKey(ref: string) {
  return ref.replace('#/definitions/', '');
}

function getDependentsList(deps: KeyArr | undefined | null, prependStr?: string) {
  if (!deps) {
    return {};
  }
  return Object.entries(deps).reduce((cache: KeyVal, [key, value]) => {
    const fullKey = joinPath(prependStr, key);
    if (typeof value === 'string') {
      cache[value] = fullKey;
    }
    if (Array.isArray(value)) {
      for (const field of value) {
        cache[field] = fullKey;
      }
    }
    return cache;
  }, {});
}

export function deRefSchema(schema: unknown | undefined | null, definitions?: KeySchema) {
  if (!isJsonSchema(schema)) {
    return;
  }
  const defs = (schema.definitions || definitions) as Record<
    string,
    {
      required: string[] | undefined;
      properties: KeySchema;
      dependentRequired: KeyArr | undefined;
    }
  >;
  if (!schema.$ref || !defs) {
    return;
  }
  return defs[getKey(schema.$ref)];
}

export function getSchemaNormalized(
  schema: unknown | null | undefined,
  prefix?: string,
  cache: KeySchema = {},
  definitions?: KeySchema
): KeySchema | undefined {
  if (!isJsonSchema(schema)) {
    return;
  }
  const defs = (schema.definitions || definitions) as Record<
    string,
    {
      required: string[] | undefined;
      properties: KeySchema;
    }
  >;
  if (!schema.$ref || !defs) {
    return;
  }
  const { properties, required } = defs[getKey(schema.$ref)];
  Object.entries(properties).forEach(([key, val]) => {
    const path = joinPath(prefix, key);
    if (val.items) {
      getSchemaNormalized(val.items, path, cache, defs);
    } else if (val.$ref) {
      getSchemaNormalized(val, path, cache, defs);
    }
    cache[path] = {
      ...val,
      required: required?.includes(key)
    };
  });
  return cache;
}

function getArrayAppendDefault(props: JSONSchema4 | undefined) {
  if (!props) {
    return;
  }
  return Object.keys(props).reduce((acc: KeyVal, path: string) => {
    acc[path] = '';
    return acc;
  }, {});
}

export function getSchemaAsList(
  schema: unknown | null | undefined,
  prefix?: string,
  definitions?: KeySchema
): JSONSchema4[] | undefined {
  if (!isJsonSchema(schema)) {
    return;
  }
  const defs = (schema.definitions || definitions) as Record<
    string,
    {
      required: string[] | undefined;
      properties: KeySchema;
      dependentRequired: KeyArr | undefined;
    }
  >;
  if (!schema.$ref || !defs) {
    return;
  }
  const { properties, dependentRequired, required } = defs[getKey(schema.$ref)];
  const dependentsList = getDependentsList(dependentRequired, prefix);
  return Object.entries(properties).map(([key, val]) => {
    const path = joinPath(prefix, key);
    return {
      ...val,
      name: path,
      required: required?.includes(key),
      dependsOn: dependentsList[key],
      schema: getSchemaAsList(val, path, defs),
      ...handleArrayType(val, path, defs)
    };
  });
}

function handleArrayType(schema: JSONSchema4, path: string, definitions?: KeySchema) {
  if (schema.type === 'array' && schema.items) {
    const items = schema.items as JSONSchema4;
    return {
      schema: getSchemaAsList(items, path, definitions),
      append: getArrayAppendDefault(items?.properties)
    };
  }
  return {};
}

const starter: KeyVal = {
  parent: '',
  child: ''
};

export function getSchemaHierarchy(schema: unknown | null | undefined) {
  if (!isJsonSchema(schema)) {
    return starter;
  }
  const defs = schema.definitions as Record<
    string,
    { properties: KeySchema; dependentRequired: KeyArr | undefined }
  >;
  if (!schema.$ref || !defs) {
    return starter;
  }
  const { properties, dependentRequired } = defs[getKey(schema.$ref)];
  // this handles singletons for now
  // it will fail with 3+ levels
  if (!dependentRequired) {
    return Object.keys(properties).reduce(
      (acc, key) => {
        acc.child = key;
        return acc;
      },
      { ...starter }
    );
  }
  return Object.entries(dependentRequired).reduce(
    (acc, [key, value]) => {
      if (typeof value === 'string') {
        acc.parent = key;
        acc.child = value;
      }
      if (Array.isArray(value)) {
        acc.parent = key;
        acc.child = value[0];
      }
      return acc;
    },
    { ...starter }
  );
}

export function getDataArchitecturePath(schema: JSONSchema4): string[] {
  if (!schema) {
    return [];
  }
  const defs = schema.definitions as Record<
    string,
    { properties: KeySchema; dependentRequired: KeyArr | undefined }
  >;
  if (!schema.$ref || !defs) {
    return [];
  }
  const { dependentRequired, properties } = defs[getKey(schema.$ref)];

  const path: string[] = [];
  if (!dependentRequired) {
    if (Object.entries(properties).length === 1) {
      path.push(Object.keys(properties)[0]);
    }
    return path;
  }
  Object.entries(dependentRequired).forEach(([key, value]) => {
    if (path.includes(key)) {
      // insert values after key
      path.splice(path.indexOf(key) + 1, 0, ...value);
    } else if (path.includes(value[0])) {
      // insert key before value
      path.splice(path.indexOf(value[0]), 0, key);
    } else {
      // push to end
      path.push(key, ...value);
    }
  });
  return path;
}
