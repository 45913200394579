import { Tooltip } from '~/components';
import { Frequency, ScheduleUpdate } from '~/generated/graphql';
import { DAYS, capsFirst, friendlyCronDescription, scheduleToCron } from '~/utils';
import { RunAfterSyncDisplay } from '../run-after-sync-display';

export function ScheduleToDisplay(props: {
  schedule: ScheduleUpdate;
  runAfterSyncs: { __typename?: 'BulkSync' | 'Sync'; id: string; name: string }[];
}) {
  if (!props.schedule) {
    return <span>Schedule is not defined</span>;
  }
  const frequencyDisplay = capsFirst(props.schedule.frequency);
  const hourDisplay = props.schedule.hour != null ? props.schedule.hour.padStart(2, '0') : '';
  const minuteDisplay = props.schedule.minute != null ? props.schedule.minute.padStart(2, '0') : '';
  const dayDisplay =
    props.schedule.dayOfWeek != null ? DAYS[parseInt(props.schedule.dayOfWeek)]?.label : '';
  const cronString = scheduleToCron(props.schedule);

  switch (props.schedule.frequency) {
    case Frequency.Hourly:
      return <span>{`${frequencyDisplay} at ${minuteDisplay} minutes past the hour (UTC)`}</span>;
    case Frequency.Daily:
      return <span>{`${frequencyDisplay} at ${hourDisplay}:${minuteDisplay} (UTC)`}</span>;
    case Frequency.Weekly:
      return (
        <span>{`${frequencyDisplay} on ${dayDisplay} at ${hourDisplay}:${minuteDisplay} (UTC)`}</span>
      );
    case Frequency.Custom:
    case Frequency.Builder:
      return (
        <Tooltip content={`${cronString}`}>
          <span>{friendlyCronDescription(cronString)}</span>
        </Tooltip>
      );
    case Frequency.Runafter:
      return <RunAfterSyncDisplay runAfterSyncs={props.runAfterSyncs} />;
    case Frequency.Multi:
      return <span>Multiple schedules</span>;
    case Frequency.Dbtcloud:
      return <span>dbt Cloud run</span>;
    default:
      return <span>{frequencyDisplay}</span>;
  }
}
