import { ReactElement, useState } from 'react';
import { ActivityLogFragment, EditableFieldsetFragment } from '~/generated/graphql';
import Marker from './ActivityLogMarker';
import { z } from 'zod';
import { buildDiff } from '~/utils/v2/sql-diff';
import { Icon } from '../../../Icon';
import { Button } from '~/components/form-components';
import ActivityLogSqlDiffDialog from './ActivityLogSqlDiffDialog';
import { Tooltip } from '~/components';
import { fieldTypeIconName } from '~/utils';
import { find } from 'lodash';

// Todo - we could reshape the data to be easier to manage on the FE,
// but it would be harder to debug missing activityTypes
export interface ActivityLogItem extends ActivityLogFragment {
  label: string;
}

export interface Change {
  changeID: string;
  performedAt: string;
  performedBy: {
    id?: string | null;
    actorType: 'user' | 'system' | 'partner' | 'organization' | 'support';
    displayName: string;
  };
  logs: {
    [label: string]: ActivityLogItem[];
  };
}
interface Category {
  label: string | ((props: { fieldset?: EditableFieldsetFragment }) => string);
  value: string;
  shouldRender?: (props: { fieldset: EditableFieldsetFragment }) => boolean;
}

// Categories are used for filtering
export const categories: Record<string, Category> = {
  modelConfig: { label: 'Model config', value: 'modelConfig' },
  modelFields: { label: 'Model fields', value: 'modelFields' },
  modelLabels: { label: 'Model labels', value: 'modelLabels' },
  modelName: { label: 'Model name', value: 'modelName' },
  sqlQuery: {
    label: ({ fieldset }) =>
      fieldset.connection?.type?.id === 'salesforce' ? 'SOQL query' : 'SQL query',
    value: 'sqlQuery',
    shouldRender: ({ fieldset }) => (fieldset?.configuration as any)?.query !== undefined
  },
  trackingField: {
    label: 'Tracking field',
    value: 'trackingField',
    shouldRender: ({ fieldset }) => (fieldset?.configuration as any)?.trackingColumns !== undefined
  },
  joinToModels: { label: 'Join to other models', value: 'joinToModels' },
  permissions: { label: 'Permissions', value: 'permissions' },
  pagination: { label: 'Pagination', value: 'pagination' },
  enrichment: { label: 'Enrichment', value: 'enrichment' }
};

const relationshipChange = z.object({
  fieldName: z.string(),
  fieldID: z.string(),
  foreignFieldName: z.string(),
  foreignFieldID: z.string(),
  foreignFieldsetType: z.string(),
  foreignFieldsetID: z.string(),
  foreignFieldsetName: z.string()
});

const valueLabel = z.object({
  label: z.string(),
  value: z.string()
});

const schemas = {
  newStr: z.object({ new: z.object({ str: z.string() }) }),
  oldStr: z.object({ old: z.object({ str: z.string() }) }),
  newBool: z.object({ new: z.object({ val: z.boolean() }) }),
  oldBool: z.object({ old: z.object({ val: z.boolean() }) }),
  newArray: z.object({ new: z.object({ arr: z.array(z.string()) }) }),
  oldArray: z.object({ old: z.object({ arr: z.array(z.string()) }) }),
  newPair: z.object({ new: z.object({ elem1: z.string(), elem2: z.string() }) }),
  oldPair: z.object({ old: z.object({ elem1: z.string(), elem2: z.string() }) }),
  fieldContext: z.object({ context: z.object({ source_name: z.string() }) }),
  newRelationship: z.object({ new: relationshipChange }),
  oldRelationship: z.object({ old: relationshipChange }),
  oldValueLabel: z.object({ old: valueLabel }),
  newValueLabel: z.object({ new: valueLabel })
};

const renderers = {
  strDiff: ({ log }) => {
    const l = z.intersection(schemas.newStr, schemas.oldStr).parse(log);
    return (
      <>
        <Marker type="minus">{l.old.str}</Marker>
        <Marker type="plus">{l.new.str}</Marker>
      </>
    );
  },
  arrayDiff: ({ log }) => {
    const l = z.intersection(schemas.newArray, schemas.oldArray).parse(log);
    return (
      <>
        {l.old?.arr?.length > 0 && <Marker type="minus">{l.old.arr.join(', ')}</Marker>}
        {l.new?.arr?.length > 0 && <Marker type="plus">{l.new.arr.join(', ')}</Marker>}
      </>
    );
  },
  pairDiff: ({ log }) => {
    const l = z.intersection(schemas.newPair, schemas.oldPair).parse(log);
    return (
      <>
        <Marker type="minus">{`${l.old.elem1} → ${l.old.elem2}`}</Marker>
        <Marker type="plus">{`${l.new.elem1} → ${l.new.elem2}`}</Marker>
      </>
    );
  },
  valueLabelDiff: ({ log }) => {
    const l = z.intersection(schemas.newValueLabel, schemas.oldValueLabel).parse(log);
    return (
      <>
        {l.old?.label?.length > 0 && (
          <Marker type="minus">
            <Tooltip content={`Internal value: ${l.old.value}`} offset={[0, 4]}>
              <span>{l.old.label}</span>
            </Tooltip>
          </Marker>
        )}
        {l.new?.label?.length > 0 && (
          <Marker type="plus">
            <Tooltip content={`Internal value: ${l.new.value}`} offset={[0, 4]}>
              <span>{l.new.label}</span>
            </Tooltip>
          </Marker>
        )}
      </>
    );
  }
};

// Filter rules allow logs to be filtered out from being displayed
type FilterRule = ({
  log,
  logs
}: {
  log: ActivityLogFragment;
  logs: ActivityLogFragment[];
}) => boolean;
export const filterRules: FilterRule[] = [
  // Filter out field created and field deleted events unless context has published: true
  ({ log }: { log: ActivityLogFragment }) => {
    if (['field.created', 'field.deleted'].includes(log.activityType)) {
      return !!log.context?.published;
    }
    return true;
  },
  // Filter out any logs with the same changeID as the model created log
  ({ log, logs }) => {
    const createdLog = find(logs, { activityType: 'model.created' });
    return log.changeID !== createdLog?.changeID || log.id === createdLog?.id;
  }
];

interface ActivityType {
  // Category is used for filtering
  category: Category;
  // Label is rendered at the group level
  label:
    | string
    | ((props: { log?: ActivityLogFragment; fieldset?: EditableFieldsetFragment }) => string);
  // Render will throw an error message from zod parse if the data shape doesn't match
  render: (props: { log: ActivityLogItem }) => ReactElement;
}

export const activityTypes: Record<string, ActivityType> = {
  'field.created': {
    category: categories.modelFields,
    label: 'Model fields',
    render: ({ log }) => {
      const l = schemas.newStr.parse(log);
      return <Marker type="plus">{l.new.str}</Marker>;
    }
  },
  'field.deleted': {
    category: categories.modelFields,
    label: 'Model fields',
    render: ({ log }) => {
      const l = schemas.oldStr.parse(log);
      return <Marker type="minus">{l.old.str}</Marker>;
    }
  },
  'field.label': {
    category: categories.modelFields,
    label: ({ log }) => `Field label: ${log.context?.source_name}`,
    render: renderers.strDiff
  },
  'field.primary_key': {
    category: categories.modelFields,
    label: 'Unique identifier',
    render: ({ log }) => {
      const l = schemas.fieldContext.and(schemas.newBool).and(schemas.oldBool).parse(log);
      return (
        <>
          {l.old.val !== false && <Marker type="minus">{l.context.source_name}</Marker>}
          {l.new.val !== false && <Marker type="plus">{l.context.source_name}</Marker>}
        </>
      );
    }
  },
  'field.published': {
    category: categories.modelFields,
    label: 'Model fields',
    render: ({ log }) => {
      // Todo - old value is unused, new value is not present in case of remove
      const l = z
        .intersection(
          schemas.fieldContext,
          z.object({ new: z.object({ val: z.boolean() }).or(z.null()) })
        )
        .parse(log);
      return (
        <>
          {l.new?.val !== true && <Marker type="minus">{l.context.source_name}</Marker>}
          {l.new?.val == true && <Marker type="plus">{l.context.source_name}</Marker>}
        </>
      );
    }
  },
  'field.relationshipupdate.join.created': {
    category: categories.joinToModels,
    label: 'Join to other models',
    render: ({ log }) => {
      const l = schemas.newRelationship.parse(log);
      return (
        <Marker type="plus">
          <span>{l.new.fieldName}</span>
          <span className="text-gray-500"> joins to </span>
          <Tooltip content={l.new.foreignFieldsetName} offset={[0, 4]}>
            <div className="inline-block cursor-default">
              <Icon match={l.new.foreignFieldsetType} className="inline-flex" />
              <span> {l.new.foreignFieldName}</span>
            </div>
          </Tooltip>
        </Marker>
      );
    }
  },
  'field.relationshipupdate.join.deleted': {
    category: categories.joinToModels,
    label: 'Join to other models',
    render: ({ log }) => {
      const l = schemas.oldRelationship.parse(log);
      return (
        <Marker type="minus">
          <span>{l.old.fieldName}</span>
          <span className="text-gray-500"> joins to </span>
          <Tooltip content={l.old.foreignFieldsetName} offset={[0, 4]}>
            <div className="inline-block cursor-default">
              <Icon match={l.old.foreignFieldsetType} className="inline-flex" />
              <span> {l.old.foreignFieldName}</span>
            </div>
          </Tooltip>
        </Marker>
      );
    }
  },
  'model.configuration.aggregation_changed': {
    category: categories.modelConfig,
    label: 'Model aggregation',
    render: renderers.strDiff
  },
  'model.configuration.base_changed': {
    category: categories.modelConfig,
    label: 'Base',
    render: renderers.strDiff
  },
  'model.configuration.catalog_changed': {
    category: categories.modelConfig,
    label: 'Catalog',
    render: renderers.strDiff
  },
  'model.configuration.collection_changed': {
    category: categories.modelConfig,
    label: 'Collection',
    render: renderers.strDiff
  },
  'model.configuration.database_changed': {
    category: categories.modelConfig,
    label: 'Database',
    render: renderers.strDiff
  },
  'model.configuration.dataset_changed': {
    category: categories.modelConfig,
    label: 'Dataset',
    render: renderers.strDiff
  },
  'model.configuration.entity_changed': {
    category: categories.modelConfig,
    label: 'Entity',
    render: renderers.strDiff
  },
  'model.configuration.delim_changed': {
    category: categories.modelConfig,
    label: 'Model delimiter',
    render: renderers.strDiff
  },
  'model.configuration.object_changed': {
    category: categories.modelConfig,
    label: 'Model object',
    render: renderers.strDiff
  },
  'model.configuration.project_id_changed': {
    category: categories.modelConfig,
    label: 'Project ID',
    render: renderers.strDiff
  },
  'model.configuration.path_changed': {
    category: categories.modelConfig,
    label: 'Model path',
    render: renderers.strDiff
  },
  'model.configuration.query_changed': {
    category: categories.sqlQuery,
    label: ({ fieldset }) =>
      fieldset?.connection?.type?.id === 'salesforce' ? 'SOQL query' : 'SQL query',
    render: ({ log }) => {
      const [show, setShow] = useState<boolean>();
      const l = z.intersection(schemas.newStr, schemas.oldStr).parse(log);
      const sqlDiff = buildDiff({ ...log, ...l });
      return (
        <>
          {sqlDiff.linesAdded > 0 && (
            <Marker type="plus">
              {sqlDiff.linesAdded} {sqlDiff.linesAdded > 1 ? 'lines' : 'line'}
            </Marker>
          )}
          {sqlDiff.linesRemoved > 0 && (
            <Marker type="minus">
              {sqlDiff.linesRemoved} {sqlDiff.linesRemoved > 1 ? 'lines' : 'line'}
            </Marker>
          )}
          <Button size="mini" onClick={() => setShow(true)}>
            Details
          </Button>
          <ActivityLogSqlDiffDialog show={show} setShow={setShow} log={log} />
        </>
      );
    }
  },
  'model.configuration.schema_changed': {
    category: categories.modelConfig,
    label: 'Schema',
    render: renderers.strDiff
  },
  'model.configuration.table_changed': {
    category: categories.modelConfig,
    label: 'Table',
    render: renderers.strDiff
  },
  'model.configuration.tracking_columns_updated': {
    category: categories.trackingField,
    label: 'Tracking field',
    render: ({ log }) => {
      const l = z.intersection(schemas.newArray, schemas.oldArray).parse(log);
      return (
        <>
          {l.old?.arr?.length > 0 && <Marker type="minus">{l.old.arr.join(', ')}</Marker>}
          {l.new?.arr?.length > 0 && <Marker type="plus">{l.new.arr.join(', ')}</Marker>}
        </>
      );
    }
  },
  'model.name_change': {
    category: categories.modelName,
    label: 'Model name',
    render: renderers.strDiff
  },
  'model.created': {
    // We render a group label and no children for created
    category: null,
    label: 'Model created',
    render: () => null
  },
  'model.deleted': {
    category: null,
    label: 'Model deleted',
    render: () => null
  },
  'model.configuration.view_changed': {
    category: categories.modelConfig,
    label: 'View',
    render: renderers.strDiff
  },
  'model.configuration.includes_deleted': {
    category: categories.modelConfig,
    label: 'Includes deleted',
    render: ({ log }) => {
      // todo missing context.source_name from backend
      const l = schemas.fieldContext.and(schemas.newBool).and(schemas.oldBool).parse(log);
      return (
        <>
          <Marker type="minus">{l.old.val ? 'true' : 'false'}</Marker>
          <Marker type="plus">{l.new.val ? 'true' : 'false'}</Marker>
        </>
      );
    }
  },
  'model.configuration.recordpath_changed': {
    category: categories.modelConfig,
    label: 'Record path',
    render: renderers.strDiff
  },
  'model.configuration.sheet_changed': {
    category: categories.modelConfig,
    label: 'Sheet',
    render: renderers.strDiff
  },
  'model.configuration.source_changed': {
    category: categories.modelConfig,
    label: 'Source',
    render: renderers.strDiff
  },
  'model.configuration.survey_changed': {
    category: categories.modelConfig,
    label: 'Survey',
    render: renderers.strDiff
  },
  'model.configuration.topics_changed': {
    category: categories.modelConfig,
    label: 'Topics',
    render: renderers.arrayDiff
  },
  'model.label.created': {
    category: categories.modelLabels,
    label: 'Model labels',
    render: ({ log }) => {
      const l = schemas.newStr.parse(log);
      return <Marker type="plus">{l.new.str}</Marker>;
    }
  },
  'model.label.deleted': {
    category: categories.modelLabels,
    label: 'Model labels',
    render: ({ log }) => {
      const l = schemas.oldStr.parse(log);
      return <Marker type="minus">{l.old.str}</Marker>;
    }
  },
  'model.configuration.mode_changed': {
    category: categories.modelConfig,
    label: 'Mode changed',
    render: renderers.strDiff
  },
  'model.configuration.parameters_changed': {
    category: categories.modelConfig,
    label: 'Query string parameters',
    render: renderers.arrayDiff
  },
  'model.configuration.headers_changed': {
    category: categories.modelConfig,
    label: 'Headers',
    render: renderers.arrayDiff
  },
  'model.configuration.pagination.mode_changed': {
    category: categories.modelConfig,
    label: 'Pagination mode changed',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.parameter_definition.name_changed': {
    category: categories.pagination,
    label: 'Pagination: Token send-as name',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.parameter_definition.location_changed': {
    category: categories.pagination,
    label: 'Pagination: Token location',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.token_transformation_changed': {
    category: categories.pagination,
    label: 'Pagination: Token transformation',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.terminate_on_empty': {
    category: categories.pagination,
    label: 'Pagination: Terminate on empty',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.more_results_path_changed': {
    category: categories.pagination,
    label: 'Pagination: "More results" path',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.token_path_changed': {
    category: categories.pagination,
    label: 'Pagination: Token path',
    render: renderers.strDiff
  },
  'model.configuration.pagination.next_page.next_page_location': {
    category: categories.pagination,
    label: 'Pagination: Next page path',
    render: renderers.strDiff
  },
  'model.configuration.pagination.next_page.next_page_path': {
    category: categories.pagination,
    label: 'Pagination: Next page path',
    render: renderers.strDiff
  },
  'model.configuration.pagination.offset.limit_parameter_changed': {
    category: categories.pagination,
    label: 'Pagination: Limit',
    render: renderers.strDiff
  },
  'model.configuration.pagination.offset.offset_parameter_changed': {
    category: categories.pagination,
    label: 'Pagination: Offset paramater',
    render: renderers.strDiff
  },
  'model.configuration.pagination.offset.page_size_changed': {
    category: categories.pagination,
    label: 'Pagination: Page size',
    render: renderers.strDiff
  },
  'model.configuration.pagination.offset.record_limit_changed': {
    category: categories.pagination,
    label: 'Pagination: Record limit',
    render: renderers.strDiff
  },
  'model.configuration.pagination.offset.stops_on_partial_page': {
    category: categories.pagination,
    label: 'Pagination: Stop on partial page',
    render: renderers.strDiff
  },
  'model.configuration.pagination.sequential.parameter_name_changed': {
    category: categories.pagination,
    label: 'Pagination: Parameter name',
    render: renderers.strDiff
  },
  'model.configuration.pagination.sequential.page_parameter_name_changed': {
    category: categories.pagination,
    label: 'Pagination: Page parameter name',
    render: renderers.strDiff
  },
  'permissions_tag.added': {
    category: categories.permissions,
    label: 'Permission added',
    render: ({ log }) => {
      const l = schemas.newStr.parse(log);
      return <Marker type="plus">{l.new.str}</Marker>;
    }
  },
  'permissions_tag.removed': {
    category: categories.permissions,
    label: 'Permission removed',
    render: ({ log }) => {
      const l = schemas.oldStr.parse(log);
      return <Marker type="minus">{l.old.str}</Marker>;
    }
  },
  'field.type_changed': {
    category: categories.modelFields,
    label: ({ log }) => `Field type changed: ${log.context?.source_name}`,
    render: ({ log }) => {
      const l = z.intersection(schemas.newStr, schemas.oldStr).parse(log);
      return (
        <>
          <Marker type="minus">
            <div className="flex items-center space-x-1.5">
              <Icon name={fieldTypeIconName(l.old.str)} className="h-5 w-5 text-gray-500" />
              <span className="text-gray-800">{l.old.str}</span>
            </div>
          </Marker>
          <Marker type="plus">
            <div className="flex items-center space-x-1.5">
              <Icon name={fieldTypeIconName(l.new.str)} className="h-5 w-5 text-gray-500" />
              <span className="text-gray-800">{l.new.str}</span>
            </div>
          </Marker>
        </>
      );
    }
  },
  'model.configuration.base.updated': {
    category: categories.modelConfig,
    label: 'Base',
    render: renderers.valueLabelDiff
  },
  'model.configuration.table.updated': {
    category: categories.modelConfig,
    label: 'Table',
    render: renderers.valueLabelDiff
  },
  'model.configuration.view.updated': {
    category: categories.modelConfig,
    label: 'View',
    render: renderers.valueLabelDiff
  },
  'model.enrichment.configuration.object_changed': {
    category: categories.enrichment,
    label: 'Enrichment object',
    render: renderers.strDiff
  },
  'model.enrichment.created': {
    category: categories.enrichment,
    label: 'Enrichment',
    render: renderers.strDiff
  },
  'model.enrichment.deleted': {
    category: categories.enrichment,
    label: 'Enrichment',
    render: renderers.strDiff
  },
  'model.enrichment.field.label': {
    category: categories.enrichment,
    label: 'Enrichment fields',
    render: renderers.strDiff
  },
  'model.enrichment.field.published': {
    category: categories.enrichment,
    label: 'Enrichment fields',
    render: ({ log }) => {
      // Todo - old value is unused, new value is not present in case of remove
      const l = z
        .intersection(
          schemas.fieldContext,
          z.object({ new: z.object({ val: z.boolean() }).or(z.null()) })
        )
        .parse(log);
      return (
        <>
          {l.new?.val !== true && <Marker type="minus">{l.context.source_name}</Marker>}
          {l.new?.val == true && <Marker type="plus">{l.context.source_name}</Marker>}
        </>
      );
    }
  },
  'model.enrichment.mapping_added': {
    category: categories.enrichment,
    label: 'Enrichment mapping',
    render: ({ log }) => {
      const l = schemas.newPair.parse(log);
      return <Marker type="plus">{`${l.new.elem1} → ${l.new.elem2}`}</Marker>;
    }
  },
  'model.enrichment.mapping_removed': {
    category: categories.enrichment,
    label: 'Enrichment mapping',
    render: ({ log }) => {
      const l = schemas.oldPair.parse(log);
      return <Marker type="minus">{`${l.old.elem1} → ${l.old.elem2}`}</Marker>;
    }
  },
  'model.enrichment.mapping_changed': {
    category: categories.enrichment,
    label: 'Enrichment mapping',
    render: renderers.pairDiff
  },
  'model.configuration.static_list.updated': {
    category: categories.modelConfig,
    label: 'Static list',
    render: renderers.strDiff
  }
};
