import * as React from 'react';

import { Action } from '../generated/graphql';

type AclContextType = Action[] | undefined;

const AclContext = React.createContext<AclContextType>(undefined);

interface Props {
  children: React.ReactNode;
  value: AclContextType;
}

export function AclProvider(props: Props) {
  return <AclContext.Provider value={props.value}>{props.children}</AclContext.Provider>;
}

export function useHasAclPermission(action: Action | undefined) {
  const acl = React.useContext(AclContext);
  // no acl means we can't verify, and !action means "ignore"
  if (!acl || !action) {
    return true;
  }
  return acl.includes(action);
}
