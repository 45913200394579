import { ServerError, useQuery, useReactiveVar } from '@apollo/client';

import { needsReloadVar } from '../apollo';
import { BannerTop } from './banner';
import { Button } from './form-components';

import { useState } from 'react';
import { CurrentUserDocument } from '~/generated/graphql';

const CHECK_SESSION_POLL_INTERVAL = 60000;

export function ReloadNotice() {
  const newVersion = useReactiveVar(needsReloadVar);
  const [expired, setExpired] = useState<boolean>();

  useQuery(CurrentUserDocument, {
    fetchPolicy: 'no-cache',
    pollInterval: CHECK_SESSION_POLL_INTERVAL,
    onError: e => {
      const serverError = e?.networkError as ServerError;
      if (serverError?.statusCode === 401) {
        setExpired(true);
      }
    }
  });

  if (expired) {
    return (
      <BannerTop>
        <div className="flex items-center space-x-2">
          <div className="text-sm">
            <p>Your Polytomic session has expired. Please refresh your browser.</p>
          </div>
          <Button onClick={() => window.location.reload()} size="mini">
            Refresh now
          </Button>
        </div>
      </BannerTop>
    );
  }

  if (newVersion) {
    return (
      <BannerTop>
        <div className="flex items-center space-x-2">
          <div className="text-sm">
            <p>Newer version available. Reload now to get the latest features and bug fixes.</p>
          </div>
          <Button onClick={() => window.location.reload()} size="mini">
            Reload app
          </Button>
        </div>
      </BannerTop>
    );
  }

  return null;
}
