import { Connection, ConnectionPeek, SyncFragment, SyncPeek } from '~/generated/graphql';
import { isModelMapping } from '~/utils';

export const syncToSyncPeek = (sync: SyncFragment): SyncPeek => {
  return {
    __typename: 'SyncPeek',
    id: sync.id,
    name: sync.name,
    Active: sync.active,
    LastRunTimestamp: sync.lastExecution?.startedAt || '',
    LastRunErrorCount: sync.lastExecution?.errorCount || 0,
    LastRunError: sync.lastExecution?.executionErrors?.map(e => e.error).join(', ') || '',
    Frequency: sync.schedule?.frequency || '',
    Status: sync.currentExecution?.status || '',
    TargetConnectionID: sync.targetConnection?.id || '',
    TargetConnectionName: sync.targetConnection?.name || '',
    TargetConnectionType: sync.targetConnection?.type?.id || '',
    TargetObjectName: sync.targetObject?.name || '',
    RunAfterSyncs: sync.runAfterSyncs.map(({ __typename, id, name }) => ({
      id,
      name,
      Type: __typename === 'Sync' ? 'model' : 'bulk'
    })),
    SourceConnections: [
      ...new Map(
        sync.fields
          .filter(isModelMapping)
          .map(f => connectionToConnectionPeek(f.model?.fieldset.connection as Partial<Connection>))
          .map(item => [item.ConnectionID, item])
      ).values()
    ],
    ViewedAt: null
  };
};

export const connectionToConnectionPeek = (connection?: Partial<Connection>): ConnectionPeek => {
  return {
    __typename: 'ConnectionPeek',
    ConnectionID: connection?.id || '',
    ConnectionName: connection?.name || '',
    ConnectionType: connection?.type?.id || ''
  };
};
