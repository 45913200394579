import cx from 'clsx';
import * as React from 'react';

export const Section = (props: React.ComponentPropsWithoutRef<'section'>) => {
  return (
    <section className="p-6">
      <div className={cx('mx-auto max-w-6xl', props.className)}>{props.children}</div>
    </section>
  );
};
