import { forwardRef } from 'react';
import { InlineDescription, InlineFormError, Label } from '..';
import { tv } from 'tailwind-variants';
import { cn, getClassNames } from '~/lib/utils';
import { FieldErrors } from 'react-hook-form';
import { hasError } from '~/utils';

interface FileUploadProps {
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  errors?: FieldErrors;
  description?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const fileUpload = tv({
  slots: {
    wrapper: '',
    input:
      'w-full truncate rounded-l border-t border-l border-b border-gray-400 bg-white py-1.5 pl-3 pr-0 text-sm shadow-input',
    button:
      'rounded-r border border-gray-400 bg-gray-50 py-1.5 px-3 text-sm font-medium text-gray-700 hover:border-gray-500 hover:bg-gray-100'
  },
  variants: {
    disabled: {
      true: {
        input: 'border-gray-300 bg-white',
        button: 'border-gray-300 bg-gray-200 text-gray-400'
      }
    },
    error: {
      true: {
        input: 'border-red-500 bg-red-50 ring-1 ring-red-500',
        button: 'border-red-500 ring-1 ring-red-500 hover:border-red-700 hover:ring-red-700'
      }
    }
  },
  defaultVariants: {
    disabled: false
  }
});

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  ({ label, name, disabled, value, description, errors, onChange, ...rest }, ref) => {
    const { wrapper, input, button } = getClassNames(fileUpload, {
      disabled,
      error: hasError(errors, name),
      ...rest
    });

    return (
      <div className={wrapper}>
        <Label htmlFor={name} className="block">
          <div className="font-medium text-gray-800">{label}</div>
          <input
            hidden
            type="file"
            ref={ref}
            id={name}
            name={name}
            onChange={async e => {
              if (e.target.validity.valid && e.target.files?.[0]) {
                onChange(e);
              }
            }}
            className="focus:outline-none"
            disabled={disabled}
          />
          <div className={cn(!disabled && 'cursor-pointer', 'mt-1 flex items-center')}>
            <div className={cn(input, value ? 'text-gray-400' : 'text-gray-800')}>
              {value ? 'Replace saved file...' : 'Choose file...'}
            </div>
            <div className={button}>Browse...</div>
          </div>
        </Label>
        <InlineFormError errors={errors} name={name as string} />
        <InlineDescription description={description} />
      </div>
    );
  }
);
