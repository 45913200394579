import * as React from 'react';

import { SideBySide } from '../side-by-side';

interface Props {
  hasSideBySide?: boolean;
  children: React.ReactNode;
}

export const AccessControlWrap = ({ hasSideBySide = true, children }: Props) => {
  if (hasSideBySide) {
    return (
      <SideBySide
        hasSectionWrap
        heading={
          <div>
            <div className="flex items-center space-x-1">
              <h3 className="sbs-heading">Access control</h3>
              {/* <LinkableIcon href="https://docs.polytomic.com/docs/" /> */}
            </div>
            <p className="text-sm font-normal text-gray-500">(Optional)</p>
          </div>
        }
      >
        {children}
      </SideBySide>
    );
  }
  return <>{children}</>;
};
