import { useLazyQuery } from '@apollo/client';
import * as React from 'react';

import { SanitizeIdentifierDocument } from '../generated/graphql';

export function useSanitizeIdText() {
  const [getSanitized, { loading: sanitizeIdTextLoading }] = useLazyQuery(
    SanitizeIdentifierDocument
  );

  const sanitizeIdText = React.useCallback(
    async (connectionId: string | undefined | null, text: string | undefined | null) => {
      if (!connectionId || !text) {
        return text;
      }
      const { data } = await getSanitized({
        variables: { connectionId, idString: text },
        context: {
          queryDeduplication: false
        }
      });
      return data?.sanitizeIdentifier || text;
    },
    [getSanitized]
  );

  return { sanitizeIdTextLoading, sanitizeIdText };
}
