import { useLazyQuery } from '@apollo/client';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Button,
  EditPermission,
  FormArray,
  Label,
  MyInput,
  SideBySide,
  Tooltip
} from '../../../components';
import { ApplyConnectionUpdateDocument, ConnectionTypeFragment } from '../../../generated/graphql';
import { useBannerDispatch } from '../../../hooks';
import { getSchemaAsList, getSchemaNormalized } from '../../../utils';
import { ConnectionFormValues, ConnectionWithoutType } from '../connection-config';

interface Props {
  connection?: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
  setConnection: React.Dispatch<React.SetStateAction<ConnectionWithoutType | undefined>>;
}

export function WebhookConnectionConfig(props: Props) {
  const [inputType, setInputType] = React.useState<'password' | 'text'>('password');

  const { register, watch, formState, resetField, getValues } =
    useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  const dispatchBanner = useBannerDispatch();

  const schema = getSchemaNormalized(props.connectionType.configurationSchema);
  const list = getSchemaAsList(props.connectionType.configurationSchema, 'configuration');
  const arrayProps = list?.find(s => s.type === 'array');

  const hasUrl = watch('configuration.url');
  const hasSecret = watch('configuration.secret');

  const [applyConnectionUpdate] = useLazyQuery(ApplyConnectionUpdateDocument, {
    onCompleted: data => {
      if (!data || !data.applyConnectionUpdate) {
        return;
      }
      const connection = data.applyConnectionUpdate;
      if (connection.saved) {
        resetField('configuration.secret', {
          defaultValue: data.applyConnectionUpdate.configuration.secret
        });
      } else {
        props.setConnection(data.applyConnectionUpdate);
      }
    },
    onError: error => dispatchBanner({ type: 'show', payload: { message: error } }),
    fetchPolicy: 'no-cache'
  });

  function handleUpdate(updates: Record<string, unknown>) {
    void applyConnectionUpdate({
      variables: {
        type: props.connectionType.id,
        connection: {
          id: props.connection?.id,
          name: getValues('name'),
          configuration: {
            ...getValues('configuration'),
            ...updates
          }
        }
      }
    });
  }

  const url = register('configuration.url', {
    required: `${schema?.url.title || 'Field'} is required`
  });

  return (
    <SideBySide hasSectionWrap heading="Options" styles="space-y-3">
      <div>
        <EditPermission>
          <MyInput
            label={schema?.url.title}
            description={schema?.url.description}
            name={url.name}
            ref={url.ref}
            onChange={url.onChange}
            onBlur={e => {
              url.onBlur(e);
              handleUpdate({});
            }}
            errors={errors}
          />
        </EditPermission>
      </div>
      {hasUrl && hasSecret && (
        <div>
          <EditPermission>
            <>
              <Label>{schema?.secret.title}</Label>
              <div className="flex gap-x-2">
                <MyInput
                  {...register('configuration.secret')}
                  className="flex-fill"
                  description={schema?.secret.description}
                  type={inputType}
                  onMouseEnter={() => setInputType('text')}
                  onMouseLeave={() => setInputType('password')}
                  readOnly={true}
                  errors={errors}
                />
                <Tooltip content="Copy">
                  <Button
                    iconEnd="Copy"
                    onClick={() => {
                      navigator.clipboard?.writeText(hasSecret as string);
                    }}
                  />
                </Tooltip>
                <Button
                  className="flew-grow"
                  iconEnd="Refresh"
                  onClick={() => {
                    handleUpdate({ secret: '' });
                  }}
                >
                  Regenerate
                </Button>
              </div>
            </>
          </EditPermission>
        </div>
      )}
      {arrayProps && (
        <FormArray
          item={arrayProps}
          className="grid grid-cols-[8rem,1fr,repeat(2,1.25rem)] items-center gap-2"
        />
      )}
    </SideBySide>
  );
}
