import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  label?: ReactNode;
}

export function Footer({ children, label }: Props) {
  return (
    <footer
      className="
      flex w-full flex-row items-center justify-between rounded bg-gray-100 p-2
      "
    >
      <p className="text-sm text-gray-800">{label}</p>
      <div className="flex items-center justify-end gap-4">{children}</div>
    </footer>
  );
}
