import { MultiScheduleType, SelectiveMode } from '~/generated/graphql';
import { SideBySide } from '../side-by-side';
import { SchedulePicker, type Schedule, ScheduleProps, FrequencyOptions } from './SchedulePicker';

export function FrequencyMulti({ schedule, onScheduleChange }: ScheduleProps) {
  const handleScheduleChange = (item: SelectiveMode, subSchedule: Schedule) => {
    onScheduleChange({
      ...schedule,
      multi: {
        type: MultiScheduleType.Incremental,
        schedules: (
          schedule.multi?.schedules ?? [
            { item: SelectiveMode.IncrementalFields },
            { item: SelectiveMode.NonincrementalFields }
          ]
        ).map(s => (s.item === item ? { item, schedule: subSchedule } : s))
      }
    });
  };

  const options = [
    FrequencyOptions.manual,
    FrequencyOptions.continuous,
    FrequencyOptions.hourly,
    FrequencyOptions.daily,
    FrequencyOptions.weekly,
    FrequencyOptions.builder,
    FrequencyOptions.custom
  ];

  return (
    <div className="flex flex-col">
      <div className="border-t py-3">
        <SideBySide
          heading={
            <div className="flex flex-col">
              <span className="text-sm font-medium">Incremental fields</span>
              {/* TODO: update link when available */}
              {/* <a
                href=""
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Learn more ↗
              </a> */}
            </div>
          }
          styles="min-w-0"
        >
          <SchedulePicker
            options={options}
            schedule={
              schedule.multi?.schedules?.find(s => s.item === SelectiveMode.IncrementalFields)
                .schedule
            }
            onScheduleChange={s => handleScheduleChange(SelectiveMode.IncrementalFields, s)}
          />
        </SideBySide>
      </div>
      <div className="border-t pt-3">
        <SideBySide
          heading={<span className="text-sm font-medium">Non-incremental fields</span>}
          styles="min-w-0"
        >
          <SchedulePicker
            options={options}
            schedule={
              schedule.multi?.schedules?.find(s => s.item === SelectiveMode.NonincrementalFields)
                .schedule
            }
            onScheduleChange={s => handleScheduleChange(SelectiveMode.NonincrementalFields, s)}
          />
        </SideBySide>
      </div>
    </div>
  );
}
