import { ServerError, ServerParseError } from '@apollo/client';
import { JSONSchema4 } from 'json-schema';
import { OptionsType, OptionTypeBase, ValueType } from 'react-select';
import { IconName } from '~/assets';

import {
  Action,
  BulkExecutionStatus,
  BulkSyncFragment,
  ConfirmationRequestFragment,
  ConnectionFragment,
  Continuation,
  DailySchedule,
  DatabaseSchemaFragment,
  ExecutionStatus,
  FieldMappingFragment,
  FieldsetFragment,
  Frequency,
  HourlySchedule,
  ModelFieldFragment,
  OverrideFieldMappingFragment,
  PermissionRoleFragment,
  Poller,
  ResourceType,
  SqlRunnerColumnSchemaFragment,
  SqlRunnerSchemaV2Fragment,
  SyncExecutionError,
  SyncFragment,
  SyncListItemFragment,
  SyncRecordErrorsFragment,
  SyncRecordFragment,
  SyncRecordMessageFragment,
  SyncRecordsFragment,
  SyncRecordWarningsFragment,
  TableFragment,
  TargetFieldFragment,
  UserFragment,
  WeeklySchedule
} from '../generated/graphql';
import { ADMIN_ROLE_ID, HISTORY_FILTERS } from './constants.util';
import { LabeledObj, NamedObj, Selectable, SyncHistoryFilter } from './custom-types.util';
import { SyncExecutionErrors, SyncRecordPreview, SyncRecordPreviewData } from './union-types.util';
import { IntegrationSchedule } from '~/gql/graphql';

export function truthyBoolean<Type>(
  value: Type
): value is Exclude<Type, false | null | undefined | '' | 0> {
  return Boolean(value);
}

export function isNum(value: unknown): value is number {
  return typeof value === 'number';
}

export function isStr(value: unknown): value is string {
  return typeof value === 'string';
}

export function isBool(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isNamedObj(obj: unknown): obj is NamedObj {
  return (obj as NamedObj)?.id != null && (obj as NamedObj)?.name != null;
}

export function isLabeledObj(obj: unknown): obj is LabeledObj {
  return (obj as LabeledObj)?.id != null && (obj as LabeledObj)?.label != null;
}

export function isSelectable(obj: unknown): obj is Selectable {
  return (obj as Selectable)?.label != null && (obj as Selectable)?.value != null;
}

export function isJsonSchema(obj: unknown): obj is JSONSchema4 {
  return (obj as JSONSchema4)?.$ref != null;
}

export function isServerError(
  err: Error | ServerError | ServerParseError | undefined | null
): err is ServerError {
  if (err && (err as ServerError)?.response) {
    return true;
  }
  return false;
}

export function isContinuation(data: unknown): data is Continuation {
  if (typeof data !== 'object' || Array.isArray(data) || data == null) {
    return false;
  }
  for (const response of Object.values(data)) {
    if ((response as Continuation)?.__typename === 'Continuation') {
      return true;
    }
  }
  return false;
}

export function isPoller(data: unknown): data is Continuation {
  if (typeof data !== 'object' || Array.isArray(data) || data == null) {
    return false;
  }
  for (const response of Object.values(data)) {
    if ((response as Poller)?.__typename === 'Poller') {
      return true;
    }
  }
  return false;
}

export function isConnection(value: unknown): value is ConnectionFragment {
  return (value as ConnectionFragment)?.__typename === 'Connection';
}

export function isModelField(field: unknown): field is ModelFieldFragment {
  return (field as ModelFieldFragment)?.__typename === 'ModelField';
}

export function isModelFieldArray(fields: unknown): fields is ModelFieldFragment[] {
  return (fields as ModelFieldFragment[]).every(f => f?.__typename === 'ModelField');
}

export function isTargetField(field: unknown): field is TargetFieldFragment {
  return (field as TargetFieldFragment)?.__typename === 'TargetField';
}

export function isTargetFieldArray(fields: unknown): fields is TargetFieldFragment[] {
  return (fields as TargetFieldFragment[]).every(f => f?.__typename === 'TargetField');
}

export function isModelMapping(mapping: unknown | null): mapping is FieldMappingFragment {
  return (mapping as FieldMappingFragment)?.model != null;
}

export function isOverrideMapping(
  mapping: unknown | null
): mapping is OverrideFieldMappingFragment {
  return (mapping as OverrideFieldMappingFragment)?.overrideValue != null;
}

export function isSingleValue<T extends OptionTypeBase>(
  option: ValueType<T, boolean>
): option is T {
  return option == null || (option as OptionsType<T>)?.flat === undefined;
}

export function isSyncRecord(record: SyncRecordPreview): record is SyncRecordFragment {
  return (record as SyncRecordFragment)?.data != null;
}

export function isSyncRecords(data: SyncRecordPreviewData): data is SyncRecordsFragment {
  return (data as SyncRecordsFragment)?.records != null;
}

export function isSyncRecordMessage(
  record: SyncRecordPreview
): record is SyncRecordMessageFragment {
  return (record as SyncRecordMessageFragment)?.message != null;
}

export function isSyncRecordMessages(
  data: SyncRecordPreviewData
): data is SyncRecordWarningsFragment | SyncRecordErrorsFragment {
  return (data as SyncRecordWarningsFragment | SyncRecordErrorsFragment).recordMessages != null;
}

export function isSyncExecutionError(record: SyncRecordPreview): record is SyncExecutionError {
  return (record as SyncExecutionError).error != null;
}

export function isSyncExecutionErrors(data: SyncRecordPreviewData): data is SyncExecutionErrors {
  return (data as SyncExecutionErrors).errors != null;
}

export function isLegacyExecutionWarnings(
  data: SyncRecordPreviewData
): data is SyncRecordWarningsFragment {
  return (data as SyncRecordWarningsFragment).legacy === true;
}

export function isFieldset(obj: unknown): obj is FieldsetFragment {
  return (obj as FieldsetFragment)?.__typename === 'Fieldset';
}

export function isSync(obj: unknown): obj is SyncFragment {
  return (obj as SyncFragment)?.__typename === 'Sync';
}

export function isBulkSync(obj: unknown): obj is BulkSyncFragment {
  return (obj as BulkSyncFragment)?.__typename === 'BulkSync';
}

export function isSchedule(obj: unknown): obj is IntegrationSchedule {
  return (obj as IntegrationSchedule)?.__typename === 'IntegrationSchedule';
}

export function isSyncHistoryFilter(item: unknown): item is SyncHistoryFilter {
  return (
    (item as SyncHistoryFilter)?.column != null &&
    Object.keys(HISTORY_FILTERS).includes((item as SyncHistoryFilter).column || '')
  );
}

export function isActionPermission(item: unknown): item is Action {
  return isStr(item) && Object.values(Action).includes(item as Action);
}

export function isResourceTypePermission(item: unknown): item is ResourceType {
  return isStr(item) && Object.values(ResourceType).includes(item as ResourceType);
}

export function isPermissionRole(obj: unknown): obj is PermissionRoleFragment {
  return (obj as PermissionRoleFragment)?.__typename === 'PermissionRole';
}

export function isHourlySchedule(obj: unknown): obj is HourlySchedule {
  return (obj as HourlySchedule)?.frequency === Frequency.Hourly;
}

export function isDailySchedule(obj: unknown): obj is DailySchedule {
  return (obj as DailySchedule)?.frequency === Frequency.Daily;
}

export function isWeeklySchedule(obj: unknown): obj is WeeklySchedule {
  return (obj as WeeklySchedule)?.frequency === Frequency.Weekly;
}

export function isCompletedWithErrors(sync: SyncFragment | SyncListItemFragment): boolean {
  if (sync.lastExecution && sync.lastExecution.errorCount != null) {
    return (
      sync.lastExecution.status === ExecutionStatus.Completed && sync.lastExecution.errorCount > 0
    );
  }
  return false;
}

export function isValidDate(d: unknown): d is Date {
  return d instanceof Date && !isNaN(d.valueOf());
}

export function isAdmin(user: UserFragment | undefined): boolean {
  return user?.roles.filter(role => role.id === ADMIN_ROLE_ID).length === 1;
}

export function hasItems<Type = unknown>(arr: Type[] | undefined | null): arr is Type[] {
  return Array.isArray(arr) && arr.length > 0;
}

export function isRunnerCatalog(obj: unknown): obj is SqlRunnerSchemaV2Fragment {
  return (obj as SqlRunnerSchemaV2Fragment)?.__typename === 'SqlRunnerSchemaV2';
}

export function isRunnerSchema(obj: unknown): obj is DatabaseSchemaFragment {
  return (obj as DatabaseSchemaFragment)?.__typename === 'DatabaseSchema';
}

export function isRunnerTable(obj: unknown): obj is TableFragment {
  return (obj as TableFragment)?.__typename === 'Table';
}

export function isRunnerColumn(obj: unknown): obj is SqlRunnerColumnSchemaFragment {
  return (obj as SqlRunnerColumnSchemaFragment)?.__typename === 'SqlRunnerColumnSchema';
}

export function isConfirmationRequest(obj: unknown): obj is ConfirmationRequestFragment {
  return (obj as ConfirmationRequestFragment)?.__typename === 'ConfirmationRequest';
}

export function isIconName(v: unknown): v is IconName {
  return !!(v as IconName);
}

export function isTerminal(obj: BulkExecutionStatus | undefined): boolean {
  switch (obj) {
    case BulkExecutionStatus.Completed:
    case BulkExecutionStatus.Failed:
    case BulkExecutionStatus.Canceled:
    case BulkExecutionStatus.Errors:
      return true;
    default:
      return false;
  }
}
