// selectIdentity sets the current Identity ID for the session,
// returns true if successful. If unsuccessful it throws an error.
export async function selectIdentity(identity: string, onSuccess: () => void): Promise<void> {
  const form = new FormData();
  form.append('identity', identity);

  const res = await fetch(`${import.meta.env.VITE_API_URL || ''}/auth/identity`, {
    method: 'POST',
    cache: 'no-cache',
    redirect: 'manual',
    referrerPolicy: 'no-referrer',
    credentials: 'include',
    body: form
  });
  if (res.status === 202) {
    onSuccess();
  } else if (res.status === 404) {
    throw new Error('User not found');
  }
}
