import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Label, MyInput } from '~/components';
import { useGoogleSheetsPicker } from '~/pages/connections/backends/gsheets-connection';
import { FormBuilderProps } from './types';
export function GoogleSheetsPicker(props: FormBuilderProps) {
  const { control, setValue } = useFormContext();
  const accessToken = useWatch({ control, name: 'oauth_access_token' });
  const sheet = useWatch({ control, name: 'spreadsheet_id' }) || { value: '', label: '' };

  const picker = useGoogleSheetsPicker({
    accessToken,
    appId: props.envConfig.appID as string,
    developerKey: props.envConfig.developerKey as string,
    onPicked: ({ id, name }) => {
      setValue('spreadsheet_id', { value: id, label: name });
    }
  });

  useEffect(() => {
    if (!picker.isReady) {
      return;
    }
    if (sheet.value.length > 0) {
      return;
    }

    picker.open();
  }, [sheet, picker.isReady]);

  if (!accessToken) {
    return null;
  }

  return (
    <>
      <div>
        <Label>Sheet</Label>
        <div className="flex gap-[1px]">
          <MyInput
            className="cursor-pointer rounded-r-none ring-gray-400"
            readOnly
            value={sheet.label}
            onClick={picker.open}
          />
          <Button
            className="h-8 rounded-l-none border-none shadow-none ring-1 ring-gray-400"
            type="button"
            onClick={picker.open}
          >
            Browse...
          </Button>
        </div>
      </div>
      {/*
        HACK: This will force a rerender of the component if no sheet
        is selected causing the effect to trigger the picker to open
      */}
      <input
        type="hidden"
        {...control.register('spreadsheet_id.value', {
          required: true
        })}
      />
    </>
  );
}
