import * as React from 'react';

interface Props {
  description: string | React.ReactNode;
}

export function InlineDescription(props: Props) {
  if (!props.description || props.description === '') {
    return null;
  }
  return <p className="mt-1 text-left text-xs text-gray-500">{props.description}</p>;
}
