import { LazyQueryExecFunction, useQuery } from '@apollo/client';
import * as React from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { ConnectionSelect, JSONSchemaForm, MyInput } from '~/components';
import {
  Action,
  ConnectionsDocument,
  Exact,
  Maybe,
  Operation,
  TargetObjectFragment,
  TargetObjectQuery
} from '~/generated/graphql';
import {
  CREATE_TARGET_SCHEMA,
  CREATE_TARGET_TABLE,
  LocalConnection,
  Selectable,
  SyncConfigFormValues,
  filterConnections,
  getDataArchitecturePath,
  getSchemaHierarchy,
  getSchemaNormalized
} from '~/utils';
import { DestDataArchitectureSelect, StageCard } from '.';

interface Props {
  getTargetObject: LazyQueryExecFunction<
    TargetObjectQuery,
    Exact<{
      connectionId: string;
      targetObject: string;
      refresh?: Maybe<boolean> | undefined;
    }>
  >;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setDataArchitectureList: React.Dispatch<React.SetStateAction<Selectable[]>>;
}

export const StageTarget = ({ getTargetObject, ...props }: Props) => {
  const { control, setValue, getValues, register, formState, watch } =
    useFormContext<SyncConfigFormValues>();
  const { errors } = formState;

  const { field } = useController({ control, name: 'targetConnection' });

  const targetConnection = useWatch({ control, name: 'targetConnection' });
  const targetObject = useWatch({ control, name: 'targetObject' });
  const searchValues = useWatch({ control, name: 'targetSearchValues' });

  const { data: connectionsData, loading } = useQuery(ConnectionsDocument);

  const hasInlineConfig = !!targetConnection?.type.operations.includes(
    Operation.DestinationRequireConfiguration
  );

  const { parent, child } = React.useMemo(
    () => getSchemaHierarchy(targetConnection?.type.destinationDataArchitecture),
    [targetConnection?.type.destinationDataArchitecture]
  );
  const path = React.useMemo(
    () => getDataArchitecturePath(targetConnection?.type.destinationDataArchitecture),
    [targetConnection?.type.destinationDataArchitecture]
  );
  const schema = React.useMemo(
    () => getSchemaNormalized(targetConnection?.type.destinationDataArchitecture),
    [targetConnection?.type.destinationDataArchitecture]
  );

  const parentValue = React.useMemo(() => searchValues?.[parent], [parent, searchValues]);

  const handleConnection = (connection: LocalConnection) => {
    field.onChange(connection);
    if (targetObject) {
      setValue('targetObject', null);
      setValue('targetSearchValues', null);
    }
    // this is for blob storage destinations
    if (connection.type.operations.includes(Operation.DestinationRequireConfiguration)) {
      void getTargetObject({
        variables: {
          connectionId: connection.id,
          targetObject: 'file',
          refresh: false
        }
      });
    }
    props.setIsDirty(true);
  };

  const handleTargetObjectEffects = React.useCallback(
    (object: TargetObjectFragment) => {
      if (!targetConnection) {
        return;
      }

      void getTargetObject({
        variables: {
          connectionId: targetConnection.id,
          targetObject: object.id,
          refresh: false
        }
      });
      const name = getValues('name');
      if (name || !object.name) {
        return;
      }

      const objectName = object.name || '';
      const generatedName =
        object.id === CREATE_TARGET_TABLE ||
        object.properties?.targetCreator ||
        parentValue === CREATE_TARGET_SCHEMA
          ? ''
          : // Generate sync name that user can modify later
            `${targetConnection?.name || ''} ${objectName.replaceAll('...', '')} sync`;
      setValue('name', generatedName);
    },
    [getValues, getTargetObject, parentValue, setValue, targetConnection]
  );

  const handleChildOnChange = React.useCallback(
    (option: Selectable) => {
      handleTargetObjectEffects({
        id: option.value,
        name: option.label
      } as TargetObjectFragment);
    },
    [handleTargetObjectEffects]
  );

  return (
    <StageCard step={1} hasStickyHeader={!!child} header="Choose destination">
      <div className="grid grid-cols-2 gap-x-4 px-6">
        <div className="col-start-1">
          <label className="label">Destination system</label>
          <ConnectionSelect
            isLoading={loading}
            autoFocus={!field.value}
            options={filterConnections(connectionsData, Operation.Target, Action.SyncTo)}
            value={field.value}
            onChange={handleConnection}
          />
        </div>
        {hasInlineConfig ? (
          <section className="grid w-full max-w-xl animate-fadeIn gap-3">
            <div>
              <MyInput
                label="Filename"
                {...register('targetObjectIdDraft', {
                  required: 'Filename is required',
                  value: targetObject?.id || ''
                })}
                errors={errors}
              />
            </div>
            {targetObject && (
              <JSONSchemaForm
                formData={watch('targetObjectConfiguration')}
                schema={targetObject?.advancedConfiguration.jsonschema}
                uiSchema={targetObject?.advancedConfiguration?.uischema}
                onChange={value => {
                  setValue('targetObjectConfiguration', value);
                }}
              />
            )}
          </section>
        ) : (
          targetConnection &&
          schema && (
            <div className="col-start-2 space-y-2">
              <React.Fragment key={targetConnection.id}>
                {
                  // loop over path, showing a select for each level
                  path.map(
                    (field, index) =>
                      (index === 0 || getValues(`targetSearchValues.${path[index - 1]}`)) && (
                        <DestDataArchitectureSelect
                          key={field}
                          connectionId={targetConnection.id}
                          field={schema[field]}
                          name={field}
                          path={path
                            .slice(0, index)
                            .map(field => getValues(`targetSearchValues.${field}`))
                            .join('.')}
                          callback={o => {
                            // if this is the final element, fetch the target object
                            if (index === path.length - 1) {
                              handleChildOnChange(o);
                            }
                          }}
                        />
                      )
                  )
                }
              </React.Fragment>
            </div>
          )
        )}
      </div>
    </StageCard>
  );
};

if (import.meta.env.MODE === 'development') {
  StageTarget.displayName = 'StageTarget';
}
