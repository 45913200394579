import { Menu } from '@headlessui/react';
import cx from 'clsx';
import * as React from 'react';

import { UserFragment } from '~/generated/graphql';
import { UserAccountsRepsonse } from '~/utils';
import { Tooltip } from '~/components';
import {
  menuItemActiveStyles,
  menuItemStyles,
  menuPaddingStyles
} from '~/components/v2/layout/SideNavOuter';
import { Icon } from '~/components';

function useOrgCount() {
  const [response, setResponse] = React.useState<boolean>(false);
  React.useEffect(() => {
    async function getOrgs() {
      try {
        const res = await fetch(`${import.meta.env.VITE_API_URL || ''}/auth/identity`, {
          method: 'GET',
          cache: 'no-cache',
          redirect: 'manual',
          credentials: 'include',
          referrerPolicy: 'no-referrer'
        });
        const data = (await res.json()) as UserAccountsRepsonse;
        const { memberOf = [], supportOrgs = [] } = data || {};
        setResponse(memberOf.length + supportOrgs.length > 1);
      } catch (e) {
        setResponse(false);
      }
    }
    void getOrgs();
  }, []);

  return response;
}

export function UserMenu(props: { user?: UserFragment }) {
  const multiOrg = useOrgCount();

  return (
    <div className="bg-gray-800 p-2">
      <Menu>
        {({ open }) => (
          <>
            <Tooltip placement="right" className="block xl:hidden" content="User account">
              <Menu.Button
                className={cx(
                  'z-10 xl:hidden',
                  menuPaddingStyles,
                  menuItemStyles,
                  open && menuItemActiveStyles
                )}
              >
                <Icon name="UserCircle" className="h-5 w-5" />
              </Menu.Button>
            </Tooltip>
            <Menu.Items className="fixed bottom-[3.25rem] left-2 z-40 my-2 origin-bottom-left divide-y divide-gray-100 rounded border border-gray-300 bg-white py-1 text-sm shadow-flyout outline-none">
              <Menu.Item>
                <div className="py-2 px-3">
                  <p className="text-gray-800">{props.user?.email}</p>
                  {multiOrg && <p className="text-gray-500">{props.user?.organization.name}</p>}
                </div>
              </Menu.Item>
              <div>
                {multiOrg && (
                  <Menu.Item>
                    {({ active }) => (
                      <p className="text-gray-800">
                        <a
                          href={`${import.meta.env.VITE_API_URL || ''}/reset`}
                          className={cx('my-1 block py-2 px-3', active && 'bg-indigo-50')}
                        >
                          Switch organizations
                        </a>
                      </p>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }) => (
                    <p className="text-gray-800">
                      <a
                        href={`${import.meta.env.VITE_API_URL || ''}/logout`}
                        className={cx('mt-1 block py-2 px-3', active && 'bg-indigo-50')}
                      >
                        Sign out
                      </a>
                    </p>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </>
        )}
      </Menu>
      <div className="hidden w-full grid-cols-[1.25rem_1fr] gap-2.5 py-3 pl-3.5 pr-1 xl:grid">
        <Icon name="UserCircle" className="row-span-full h-5 w-5 text-gray-500" />
        <div className="hidden truncate text-sm xl:block">
          <p className="truncate">{props.user?.email}</p>
          {multiOrg && <p className="truncate">{props.user?.organization.name}</p>}
        </div>
        <p className="col-start-2 space-x-4 text-sm font-medium text-gray-500">
          <a
            className="rounded-sm hover:underline focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800"
            href={`${import.meta.env.VITE_API_URL || ''}/logout`}
          >
            Sign out
          </a>
          {multiOrg && (
            <a
              className="rounded-sm hover:underline focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800"
              href={`${import.meta.env.VITE_API_URL || ''}/reset`}
            >
              Switch
            </a>
          )}
        </p>
      </div>
    </div>
  );
}
