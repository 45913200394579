import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';

import { Button, EditableField, Icon, Permission, SideBySide } from '~/components';
import { Dialog } from '~/components/v3';
import {
  Action,
  ApiKeyDocument,
  ApiKeyRecordFragment,
  CreateApiKeyDocument,
  DeleteApiKeyDocument
} from '~/generated/graphql';
import { AclProvider, useBannerDispatch, useToggle } from '~/hooks';
import { getLongLocalTime } from '~/utils';

const wrapperStyles = 'px-3 pt-3';

export function ApiKey() {
  const dispatchBanner = useBannerDispatch();
  const [showDialog, toggleShowDialog] = useToggle();
  const [data, setData] = React.useState<ApiKeyRecordFragment>();
  const [acl, setAcl] = React.useState<Action[]>();
  const [secret, setSecret] = React.useState<ApiKeyRecordFragment>();

  const { loading: getApiKeyloading } = useQuery(ApiKeyDocument, {
    onCompleted: data => {
      if (!data || !data.getApiKey) {
        return;
      }
      setAcl(data.getApiKey.acl);
      if (data.getApiKey.apiKey) {
        setData(data.getApiKey.apiKey);
      }
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } }),
    fetchPolicy: 'no-cache'
  });

  const [createApiKey, { loading: createApiKeyLoading }] = useMutation(CreateApiKeyDocument, {
    onCompleted: data => {
      if (!data || !data.createApiKey) {
        return;
      }
      setSecret(data.createApiKey);
      toggleShowDialog();
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } }),
    fetchPolicy: 'no-cache'
  });

  const [deleteApiKey, { loading: deleteApiKeyLoading }] = useMutation(DeleteApiKeyDocument, {
    onCompleted: data => {
      if (!data || !data.deleteApiKey) {
        return;
      }
      setData(undefined);
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } }),
    fetchPolicy: 'no-cache'
  });

  function handleGenerate() {
    if (data) {
      return void createApiKey({ variables: { replaceExisting: true } });
    }
    void createApiKey();
  }

  function handleDelete() {
    void deleteApiKey();
  }

  function handleDismiss() {
    if (secret) {
      const mask = '*****-****-****-****-*********';
      setData({
        apiKey: `${secret.apiKey.slice(0, 3)}${mask}${secret.apiKey.slice(-3)}`,
        createdAt: secret.createdAt
      });
    }
    setSecret(undefined);
    toggleShowDialog();
  }

  return (
    <AclProvider value={acl}>
      <SideBySide hasSectionWrap heading="API key">
        <div className="flex animate-fadeIn space-x-2">
          <div className="w-[21rem]">
            <EditableField
              placeholder="No API key generated"
              readOnly={true}
              className="font-mono"
              type="default"
              value={data?.apiKey}
            />
            {data?.createdAt && (
              <label className="text-xs text-gray-500">
                Generated {getLongLocalTime(data?.createdAt)}
              </label>
            )}
          </div>
          <Permission type={Action.Create}>
            <Button
              disabled={getApiKeyloading || deleteApiKeyLoading}
              loading={createApiKeyLoading}
              onClick={handleGenerate}
              iconEnd={data?.apiKey ? 'Refresh' : undefined}
            >
              {data?.apiKey ? 'Regenerate' : 'Generate'}
            </Button>
          </Permission>
          {data?.apiKey && (
            <Permission type={Action.Delete}>
              <Button
                theme="danger"
                disabled={getApiKeyloading || createApiKeyLoading}
                loading={deleteApiKeyLoading}
                onClick={handleDelete}
                iconEnd="Trashcan"
              >
                Delete
              </Button>
            </Permission>
          )}
        </div>
      </SideBySide>
      <Dialog
        show={showDialog}
        size="sm"
        heading="Your new API key"
        headingLogo={<Icon name="Api" />}
        actions={
          <>
            <Button theme="primary" onClick={handleDismiss}>
              Done
            </Button>
          </>
        }
      >
        <div className="space-y-4">
          <p className="text-sm text-gray-800">
            This key will only be displayed once. Save it in a secure place, such as a password
            manager.
          </p>
          <EditableField
            readOnly={true}
            className="break-all font-mono"
            type="expandableHeight"
            value={secret?.apiKey}
          />
        </div>
      </Dialog>
    </AclProvider>
  );
}
