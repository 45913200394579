import { useEffect, useState } from 'react';

type Status = 'idle' | 'loading' | 'ready' | 'error';

export function useScript(url: string) {
  const [status, setStatus] = useState<Status>('idle');

  useEffect(() => {
    // If the script is already loaded, do nothing
    if (status === 'ready') return;

    const existing = document.querySelector(`script[src="${url}"]`);
    if (existing) {
      setStatus(existing.getAttribute('data-status') as Status);
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = url;
    script.setAttribute('data-status', 'loading');

    const handleLoad = () => {
      script.setAttribute('data-status', 'ready');
      setStatus('ready');
    };

    const handleError = () => {
      script.setAttribute('data-status', 'error');
      setStatus('error');
    };

    // Set event listeners
    script.onload = handleLoad;
    script.onerror = handleError;

    // Append the script to the document
    document.head.appendChild(script);

    // Cleanup: remove event listeners when the component unmounts
    return () => {
      script.onload = null;
      script.onerror = null;
    };
  }, [status, url]);

  return status;
}
