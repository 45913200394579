import clsx from 'clsx';
import * as React from 'react';
import { v4 as uuid } from 'uuid';

import { useOnClickOutside } from '../../hooks';
import { isStr } from '../../utils';

import { Icon } from '~/components';

function setSafeValues(values?: unknown): string[] {
  if (!values) {
    return [];
  }
  if (!Array.isArray(values) && typeof values === 'string') {
    return [values];
  }
  if (Array.isArray(values) && values.length > 0) {
    return values.filter(isStr);
  }
  return [];
}

function handleAdd(values: string[], value: string) {
  if (value === '' || values.includes(value)) {
    return values;
  }
  return values.concat([value]);
}

interface Props {
  values?: unknown | unknown[] | string[];
  onUpdate: (values: string[]) => void;
  // Todo -> turn into enum type for all inputs
  variant?: 'outlined' | 'flat';
}

const defaultPlaceholder = 'Type values...';

export function MultiInput({ onUpdate, variant = 'outlined', ...props }: Props) {
  const [values, setValues] = React.useState<string[]>(() => setSafeValues(props.values));
  const [input, setInput] = React.useState('');
  const [inputVisible, setInputVisible] = React.useState(false);

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const addValue = React.useCallback((value: string) => {
    setValues(values => handleAdd(values, value));
    setInput('');
  }, []);

  const blurInput = React.useCallback(() => {
    if (!inputVisible) {
      return;
    }
    setInputVisible(false);
    addValue(input);
    onUpdate(handleAdd(values, input));
    wrapperRef.current?.blur();
  }, [inputVisible, input, onUpdate, values, addValue]);

  useOnClickOutside(wrapperRef, blurInput);

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Escape') {
      blurInput();
      return;
    }
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault(); // avoid submitting if nested in <form>
      e.stopPropagation();
      addValue(input); // Add input to tag list
      return;
    }
  }

  function onChange(e: React.FormEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();
    setInput(e.currentTarget.value);
  }

  function onDelete(e: React.FormEvent<HTMLButtonElement>, index: number) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (inputVisible) {
      setValues(prev => prev.filter((_, idx) => idx !== index));
      inputRef.current?.focus();
    } else {
      onUpdate(values.filter((_, idx) => idx !== index));
    }
  }

  function showInnerInput() {
    if (!inputVisible) {
      setInputVisible(true);
    }
  }

  function onFocus(e: React.FocusEvent<HTMLDivElement>) {
    if (e.target.localName === 'button') {
      return;
    }
    if (!inputVisible) {
      setInputVisible(true);
    }
    inputRef.current?.focus();
  }

  const MULTI_INPUT_OUTLINED_SX = `relative flex h-auto min-h-[2rem] w-full cursor-pointer flex-wrap items-start overflow-y-auto rounded border border-gray-400 bg-white px-2 pb-1.25 text-sm text-gray-800 shadow-input focus-within:border-indigo-500 focus-within:shadow-none focus-within:ring-1 focus-within:ring-indigo-500 focus:outline-none`;
  const MULTI_INPUT_FLAT_SX = `relative flex h-auto min-h-[2rem] w-full cursor-pointer flex-wrap items-start overflow-y-auto rounded border border-gray-50 bg-gray-50 px-2 pb-1.25 text-sm text-gray-800  focus-within:shadow-none focus:outline-none`;
  return (
    <>
      <div
        ref={wrapperRef}
        onClick={showInnerInput}
        onFocus={onFocus}
        tabIndex={0}
        className={clsx(variant === 'outlined' ? MULTI_INPUT_OUTLINED_SX : MULTI_INPUT_FLAT_SX)}
      >
        {values.map((value, index) => (
          <div
            key={uuid()}
            className="relative mt-1.25 mr-1 flex items-center rounded bg-gray-200 text-sm"
          >
            <div className="break-all py-0.5 pl-1.25 pr-0 text-sm font-normal leading-none text-gray-600">
              {value}
            </div>
            <button
              disabled={!inputVisible}
              onClickCapture={e => onDelete(e, index)}
              className={clsx(inputVisible && 'group ', 'focus:outline-none')}
            >
              <Icon
                name="CloseXSmall"
                className="h-5 w-5 text-gray-400 group-hover:text-gray-800"
              />
            </button>
          </div>
        ))}
        {inputVisible ? (
          <input
            ref={inputRef}
            autoFocus={true}
            name="multi-text-input"
            value={input}
            className="-mb-0.75 h-7 w-full grow bg-transparent pt-0.5 text-sm placeholder-gray-400 outline-none focus:outline-none"
            placeholder={defaultPlaceholder}
            onChange={onChange}
            onKeyDown={onInputKeyDown}
          />
        ) : (
          values.length === 0 && (
            <span className="mt-2 text-sm leading-none text-gray-400">{defaultPlaceholder}</span>
          )
        )}
      </div>
      {inputVisible && (
        <p className="absolute mt-0.5 text-xs text-gray-500">
          Use <kbd className="text-gray-800">enter</kbd> or <kbd className="text-gray-800">tab</kbd>{' '}
          to add values
        </p>
      )}
    </>
  );
}
