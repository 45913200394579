import { Reference, useApolloClient, useMutation } from '@apollo/client';
import cx from 'clsx';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select, { OptionsType, Props as SelectProps, components, createFilter } from 'react-select';
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue';

import { BannerInline, Button, Icon, Label, Lister, MyInput } from '~/components';
import { Dialog } from '~/components/v3';
import {
  Action,
  CreateTagDocument,
  PermissionRoleFragment,
  PermissionRoleFragmentDoc,
  PermissionTagFragment,
  PolicyActionInput,
  UpdateTagDocument
} from '~/generated/graphql';
import {
  ADMIN_ROLE_ID,
  DropdownIndicator,
  RoleOption,
  isRequiredMsg,
  selectStyles,
  toSelectables,
  truthyBoolean
} from '~/utils';

interface TagFormRoles {
  [action: string]: PermissionRoleFragment[];
}

function getNewActions(policy: TagFormRoles) {
  return Object.entries(policy).reduce((acc: PolicyActionInput[], [action, roles]) => {
    acc.push({
      action: action as Action,
      roleIDs: roles?.map(r => r.id) || []
    });
    return acc;
  }, []);
}

const actions = Object.values(Action).sort((a, b) => a.localeCompare(b));

interface TagForm {
  name: string;
  policy: TagFormRoles;
}

type Props =
  | {
      roles: RoleOption[];
      type: 'create';
      tag?: never;
      toggleDialog: () => void;
      setToggle: (id: string) => void;
    }
  | {
      roles: RoleOption[];
      type: 'edit';
      tag: PermissionTagFragment;
      toggleDialog: () => void;
      setToggle?: never;
    };

export function EditTagDialog({ roles, type, tag, setToggle, toggleDialog }: Props) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const client = useApolloClient();

  const policyActions = tag?.policy.policyActions.reduce((acc: TagFormRoles, v) => {
    const action = v.action.toString();
    const roles = v.roleIDs
      .map(id =>
        client.readFragment({
          id: `PermissionRole:${id}`,
          fragment: PermissionRoleFragmentDoc
        })
      )
      .filter(truthyBoolean)
      .sort((a, b) => a.name.localeCompare(b.name));
    acc[action] = roles;
    return acc;
  }, {});

  const { control, register, handleSubmit, formState } = useForm<TagForm>({
    defaultValues: { name: tag?.name, policy: policyActions }
  });
  const { errors } = formState;

  const [createTag, { loading: createTagLoading, error: createError }] = useMutation(
    CreateTagDocument,
    {
      update: (cache, { data }) => {
        if (!data?.createTag) {
          return;
        }
        cache.modify({
          fields: {
            allTags: (existing: Reference[], { toReference }) => {
              return [...existing, toReference(data.createTag)];
            }
          }
        });
        // open the new tag's table matching new model behavior
        setToggle?.(data.createTag.id);
        toggleDialog();
      }
    }
  );
  const [updateTag, { loading: updateTagLoading, error: updateError }] = useMutation(
    UpdateTagDocument,
    {
      onCompleted: () => {
        toggleDialog();
      }
    }
  );

  const handleCreate: SubmitHandler<TagForm> = form => {
    void createTag({
      variables: {
        name: form.name,
        policy: {
          policyActions: getNewActions(form.policy)
        }
      }
    });
  };

  const handleEdit: SubmitHandler<TagForm> = form => {
    if (type !== 'edit') {
      return;
    }
    void updateTag({
      variables: {
        update: {
          id: tag.id,
          name: form.name || tag?.name,
          policy: {
            policyActions: getNewActions(form.policy)
          }
        }
      }
    });
  };

  return (
    <Dialog
      show
      initialFocusRef={cancelRef}
      size="lg"
      heading={cx(type === 'create' ? 'Create' : 'Edit', 'policy')}
      actions={
        <>
          <Button ref={cancelRef} onClick={toggleDialog}>
            Cancel
          </Button>
          <Button
            theme="primary"
            onClick={handleSubmit(type === 'create' ? handleCreate : handleEdit)}
            disabled={createTagLoading || updateTagLoading}
          >
            {cx(type === 'create' ? 'Create' : 'Save', 'policy')}
          </Button>
        </>
      }
      classNames={{ body: 'relative max-h-[30.25rem] space-y-4 overflow-auto p-6' }}
    >
      {!!(createError || updateError) && (
        <BannerInline>
          <Lister items={createError || updateError} />
        </BannerInline>
      )}
      {type === 'create' || !tag.system ? (
        <div>
          <MyInput
            label="Policy name"
            placeholder="Enter name..."
            {...register('name', { required: true && isRequiredMsg('Policy name') })}
            errors={errors}
          />
        </div>
      ) : (
        <div className="flex items-baseline space-x-2">
          <p className="text-sm font-medium text-gray-800">{tag?.name}</p>
          {tag.system && <p className="text-xs text-amber-600">Built-in policy</p>}
        </div>
      )}
      <hr className="border-t border-gray-300" />
      {actions.map(name => (
        <div key={name}>
          <Label>{name}</Label>
          <Controller
            name={`policy.${name}`}
            control={control}
            render={({ field }) => (
              <MultiSelect
                isSystem={tag?.system}
                placeholder="Choose roles..."
                value={field.value ? toSelectables(field.value) : null}
                options={roles}
                onChange={field.onChange}
                menuPosition="fixed"
              />
            )}
          />
        </div>
      ))}
    </Dialog>
  );
}

function MultiValueRemoveSystem(props: MultiValueRemoveProps<RoleOption>) {
  if (props.data.id === ADMIN_ROLE_ID) {
    return <div className="w-1" />;
  }
  return (
    <components.MultiValueRemove {...props}>
      <Icon name="CloseXFat" className="h-2.5 w-2.5 shrink-0" />
    </components.MultiValueRemove>
  );
}
function MultiValueRemove(props: MultiValueRemoveProps<RoleOption>) {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name="CloseXFat" className="h-2.5 w-2.5 shrink-0" />
    </components.MultiValueRemove>
  );
}

export type MultiSelectProps = Exclude<SelectProps<RoleOption, true>, 'onChange'> & {
  onChange: (option: OptionsType<RoleOption>) => void;
  disabled?: boolean;
  readOnly?: boolean;
  isSystem?: boolean;
};

function MultiSelect({ value, onChange, options, disabled, readOnly, ...props }: MultiSelectProps) {
  function handleChange(option: OptionsType<RoleOption>) {
    onChange(option);
  }

  return (
    <Select
      isDisabled={disabled || readOnly}
      {...props}
      styles={selectStyles}
      filterOption={createFilter({ ignoreAccents: false })}
      components={{
        DropdownIndicator,
        MultiValueRemove: props.isSystem ? MultiValueRemoveSystem : MultiValueRemove,
        ClearIndicator: null,
        IndicatorSeparator: null
      }}
      value={value}
      options={options}
      onChange={handleChange}
      isClearable={false}
      backspaceRemovesValue={false}
      isMulti
    />
  );
}
