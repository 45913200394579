import { useQuery } from '@apollo/client';
import { atom, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { Button, Permission } from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import EmptyPage from '~/components/v2/templates/EmptyPage';
import Route404 from '~/components/v2/templates/Route404';
import {
  ConnectionsDocument,
  ModelListDocument,
  Operation,
  ResourceType
} from '~/generated/graphql';
import { filterConnections, hasItems, routes } from '~/utils';
import { FieldConfig } from '../field-config/field-config';
import { FieldsetConfig } from '../model-config/fieldset-config';
import { ModelsListColumn } from './models-list-column';

export type ModelsStateAtomType = {
  expandedFieldsetIds: string[];
};
export const ModelsStateAtom = atom<ModelsStateAtomType>({
  expandedFieldsetIds: []
});

const Models = () => {
  // state
  const checkSingleRef = useRef(false);
  const setModelsState = useSetAtom(ModelsStateAtom);
  // hooks
  const history = useHistory();
  const editingField = useRouteMatch<{ fieldId: string }>({ path: routes.editField, exact: true });
  const editingModel = useRouteMatch<{ fieldsetId: string }>({
    path: routes.editModel,
    exact: true
  });
  // queries
  const { data: connectionsData, loading: connectionsLoading } = useQuery(ConnectionsDocument);
  const { data, loading, error } = useQuery(ModelListDocument, { errorPolicy: 'all' });
  // vars
  const hasConnection = hasItems(filterConnections(connectionsData, Operation.Fieldsetquery));
  const fieldsets = useMemo(() => data?.model?.fieldsets || [], [data?.model?.fieldsets]);
  const isLoading = connectionsLoading || loading;

  useEffect(() => {
    if (!checkSingleRef.current && fieldsets.length === 1) {
      setModelsState(s => ({
        ...s,
        expandedFieldsetIds: [...s.expandedFieldsetIds, fieldsets[0].id]
      }));
      checkSingleRef.current = true;
    }
  }, [fieldsets, setModelsState]);

  if (!connectionsLoading && !hasConnection) {
    return (
      <EmptyPage
        loading={isLoading}
        topNavHeading="Data models"
        message="Add source connections to your systems before creating your model."
      >
        <Link to={routes.connectionsPicker}>
          <Button theme="primary">Add connections</Button>
        </Link>
      </EmptyPage>
    );
  }

  return (
    <PageLayout
      loading={isLoading}
      sideNavInnerHeading="Data models"
      sideNavInnerContent={
        <ModelsListColumn loading={isLoading} fieldsets={fieldsets} error={error} />
      }
    >
      <Switch>
        <Route exact={true} path={routes.createModel}>
          <Permission
            hideTooltip={true}
            type={ResourceType.Model}
            fallback={<Redirect to={routes.models} />}
          >
            <FieldsetConfig key="adding-model" />
          </Permission>
        </Route>
        <Route exact={true} path={routes.editModel}>
          <FieldsetConfig key={editingModel?.params?.fieldsetId} />
        </Route>
        <Route exact={true} path={routes.editField}>
          <FieldConfig key={editingField?.params?.fieldId} />
        </Route>
        <Route exact={true} path={routes.models}>
          <EmptyPage
            loading={isLoading}
            message={
              <>
                Click <strong>Add model</strong> in the top-right to add fields to your model.
              </>
            }
            topNavActions={
              !error && (
                <Permission type={ResourceType.Model}>
                  <Button theme="outline" onClick={() => history.push(routes.createModel)}>
                    Add model
                  </Button>
                </Permission>
              )
            }
          />
        </Route>
        <Route path="*">
          <Route404 />
        </Route>
      </Switch>
    </PageLayout>
  );
};

export default Models;
