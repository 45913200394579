import { defaultCron } from '~/utils';
import { MyCombobox } from '../form-components';
import { Frequency } from '~/generated/graphql';
import { cn } from '~/lib/utils';
import { ReactElement, useMemo, useState } from 'react';
import { FrequencyHourly } from './frequency-hourly';
import { FrequencyDaily } from './frequency-daily';
import { FrequencyCustom } from './frequency-custom';
import { FrequencyBuilder } from './frequency-builder';
import { FrequencyWeekly } from './frequency-weekly';
import { FrequencyMulti } from './frequency-multi';
import { FrequencyRunafter } from './frequency-runafter';
import { RJSFSchema } from '@rjsf/utils';
import { FrequencyDbtcloud } from './frequency-dbtcloud';
import { Tooltip } from '../tooltip';

export type RunAfterSync = { __typename: 'BulkSync' | 'Sync'; id: string; name: string };

export interface Schedule {
  frequency: Frequency;
  minute?: string;
  hour?: string;
  dayOfMonth?: string;
  month?: string;
  dayOfWeek?: string;
  multi?: {
    type: string;
    schedules: {
      item: string;
      schedule: Schedule;
    }[];
  };
}

export interface ScheduleProps {
  schedule: Schedule;
  onScheduleChange: (schedule: Schedule) => void;
  // Required for run after syncs
  syncId?: string;
  runAfterSyncs?: RunAfterSync[];
  onRunAfterSyncsChange?: (runAfterSyncs: RunAfterSync[]) => void;
  // Custom configuration
  schema?: { jsonschema: RJSFSchema };
}

export interface FrequencyOption {
  label: string;
  value: Frequency;
  schema?: { jsonschema: RJSFSchema };
  component?: (props: ScheduleProps) => ReactElement;
  isDisabled?: boolean;
  disabledMessage?: string;
}

export const FrequencyOptions: { [key in Frequency]: FrequencyOption } = {
  manual: {
    label: 'Manual',
    value: Frequency.Manual
  },
  continuous: {
    label: 'Continuous',
    value: Frequency.Continuous
  },
  daily: {
    label: 'Daily',
    value: Frequency.Daily,
    component: FrequencyDaily
  },
  hourly: {
    label: 'Hourly',
    value: Frequency.Hourly,
    component: FrequencyHourly
  },
  weekly: {
    label: 'Weekly',
    value: Frequency.Weekly,
    component: FrequencyWeekly
  },
  custom: {
    label: 'Cron expression',
    value: Frequency.Custom,
    component: FrequencyCustom
  },
  builder: {
    label: 'Custom schedule',
    value: Frequency.Builder,
    component: FrequencyBuilder
  },
  runafter: {
    label: 'Sync after',
    value: Frequency.Runafter,
    component: FrequencyRunafter
  },
  multi: {
    label: 'Per field type',
    value: Frequency.Multi,
    component: FrequencyMulti
  },
  dbtcloud: {
    label: 'dbt Cloud run',
    value: Frequency.Dbtcloud,
    component: FrequencyDbtcloud,
    disabledMessage: 'Create a Polytomic connection to dbt Cloud to enable'
  }
};

interface SchedulePickerProps extends ScheduleProps {
  options: FrequencyOption[];
}

export function SchedulePicker({
  options,
  syncId,
  schedule,
  onScheduleChange,
  runAfterSyncs,
  onRunAfterSyncsChange
}: SchedulePickerProps) {
  const [selected, setSelected] = useState<FrequencyOption>(
    options.find(o => o.value === schedule?.frequency)
  );

  function handleFrequencyChange(option: FrequencyOption) {
    if (!option || option.value === schedule?.frequency) {
      return;
    }
    let newSchedule = { frequency: option.value as Frequency };
    if ([Frequency.Builder, Frequency.Custom].includes(option.value as Frequency)) {
      const [minute, hour, dayOfMonth, month, dayOfWeek] = defaultCron(schedule).split(/[ ]+/);
      newSchedule = { ...newSchedule, ...{ minute, hour, dayOfMonth, month, dayOfWeek } };
    }
    onScheduleChange(newSchedule);
    onRunAfterSyncsChange?.([]);
    setSelected(option);
  }

  const Component = useMemo(() => selected?.component, [selected]);

  return (
    <>
      <MyCombobox
        placeholder="Select frequency..."
        options={options ?? []}
        value={selected}
        className={cn('w-1/2', schedule?.frequency !== Frequency.Custom && 'mb-3 last:mb-0')}
        onChange={handleFrequencyChange}
        formatOptionLabel={formatOptionLabel}
        isOptionDisabled={o => o.isDisabled}
        maxMenuHeight={600}
      />
      {Component && (
        <Component
          schedule={schedule}
          schema={selected?.schema}
          onScheduleChange={onScheduleChange}
          syncId={syncId}
          runAfterSyncs={runAfterSyncs}
          onRunAfterSyncsChange={onRunAfterSyncsChange}
        />
      )}
    </>
  );
}

function formatOptionLabel(option: FrequencyOption) {
  if (option.isDisabled) {
    return (
      <Tooltip content={option.disabledMessage}>
        <p className="text-sm text-gray-500">{option.label}</p>
      </Tooltip>
    );
  }
  return <p className="text-sm">{option.label}</p>;
}
