import { useMutation } from '@apollo/client';
import * as React from 'react';

import { Button, Permission } from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { Action, CancelSyncDocument, ExecutionStatus, SyncFragment } from '~/generated/graphql';
import { useBannerDispatch } from '~/hooks';

interface Props {
  sync: SyncFragment;
  status?: ExecutionStatus;
}

export function CancelButton({ sync, status }: Props) {
  const dispatchBanner = useBannerDispatch();
  const [called, setCalled] = React.useState(false);
  const [cancelSync, { loading }] = useMutation(CancelSyncDocument, {
    variables: { id: sync.id },
    notifyOnNetworkStatusChange: true,
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } })
  });

  function cancel() {
    setCalled(true);
    void cancelSync();
  }

  React.useEffect(() => {
    if (status === ExecutionStatus.Canceled) {
      setCalled(false);
    }
    return () => setCalled(false);
  }, [status]);

  return (
    <div className="flex items-center space-x-2">
      <Permission type={Action.Trigger}>
        <Button
          onClick={cancel}
          disabled={loading || called || status === ExecutionStatus.Canceling}
        >
          Cancel sync
        </Button>
      </Permission>
      {(loading || called || status === ExecutionStatus.Canceling) && <LoadingDots />}
    </div>
  );
}
