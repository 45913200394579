import { useLazyQuery, useReactiveVar } from '@apollo/client';
import * as React from 'react';

import { schemaCacheVar } from '../apollo';
import { SchemaInfoDocument, SqlRunnerSchemaV2Fragment } from '../generated/graphql';
import { handleHierarchy, mergeSchemaCacheData, SchemaFetchVars } from '../utils';
import { useBannerDispatch } from './banner-context';
import { dispatchExpandedSchemas } from './use-expanded-schemas';
import { dispatchRefreshableSchemas } from './use-refreshable-schemas';

function updateSchemaCache(
  vars: SchemaFetchVars,
  newData: SqlRunnerSchemaV2Fragment[] | undefined | null
) {
  if (!newData || newData.length === 0) {
    return;
  }
  const cache = schemaCacheVar();
  // modifying a reactiveVar will rebroadcast it's value (so says docs)
  if (!cache[vars.connectionId]) {
    schemaCacheVar({ ...cache, [vars.connectionId]: newData });
  } else {
    schemaCacheVar({
      ...cache,
      [vars.connectionId]: mergeSchemaCacheData(cache[vars.connectionId], newData, vars)
    });
  }
}

export const useFetchSchemaCache = (connectionId: string) => {
  const dispatchBanner = useBannerDispatch();
  const [getSchema, { loading, variables }] = useLazyQuery(SchemaInfoDocument, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      updateSchemaCache(variables || { connectionId }, data?.schemaInfo);
      if (variables?.database) {
        dispatchExpandedSchemas(connectionId, {
          type: 'initial',
          id:
            variables?.table && variables.table !== variables.database
              ? `${variables.database}.${variables.table}`
              : variables.database
        });
        dispatchRefreshableSchemas(connectionId, {
          type: 'add',
          payload: variables?.table
            ? `${variables.database}^${variables.table}`
            : variables.database
        });
      }
    },
    onError: error => dispatchBanner({ type: 'show', payload: { message: error } })
  });

  return { getSchema, loading };
};

export const useSchemaCache = (connectionId: string) => {
  const { getSchema, loading } = useFetchSchemaCache(connectionId);
  const cache = useReactiveVar(schemaCacheVar);

  const schema = handleHierarchy(cache[connectionId]);

  React.useEffect(() => {
    if (schema.length === 0) {
      void getSchema({ variables: { connectionId } });
    }
  }, [connectionId, getSchema, schema.length]);

  return { schema, loading };
};
