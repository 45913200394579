import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import { HTMLAttributes, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Banner } from '~/components/banner';
import { Lister } from '~/components/lister';

import { BottomNavStateAtom } from '~/components/v2/layout/BottomNav';
import { useBannerState, useBannerDispatch } from '~/hooks';

export type PageContentProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const PageContent = ({ children }: PageContentProps) => {
  const bottomNavState = useAtomValue(BottomNavStateAtom);

  const { message, show, ...props } = useBannerState();
  const dispatchBanner = useBannerDispatch();
  const location = useLocation();
  useEffect(() => {
    return () => dispatchBanner({ type: 'hide' });
  }, [dispatchBanner, location.pathname]);

  return (
    <div
      className={clsx(
        'flex h-full w-full min-w-0 flex-col overflow-clip transition-all',
        bottomNavState.bottomNavShow ? 'bg-gray-200' : 'bg-gray-100'
      )}
    >
      <Banner
        {...props}
        show={show || false}
        handleDismiss={() => dispatchBanner({ type: 'hide' })}
      >
        <Lister items={message} />
      </Banner>
      {children}
    </div>
  );
};

export default PageContent;
