import { sql } from '@codemirror/lang-sql';
import cx from 'clsx';
import * as React from 'react';

import { Icon } from '~/components';
import { ReadOnlyEditor, TableTopper } from '../../components';
import { useToggle } from '../../hooks';

export function LastQuery({
  lastQuery,
  rightSlot
}: {
  lastQuery: string;
  rightSlot: React.ReactNode;
}) {
  const isOneLine = !lastQuery.includes('\n');
  const [show, toggle] = useToggle(isOneLine);

  return (
    <TableTopper
      className={cx('flex min-w-full items-start whitespace-nowrap bg-white px-3.5 py-3')}
    >
      <div className="flex w-full truncate">
        <p className="mt-0.5 mr-1.5">Query</p>
        {!show ? (
          <button
            className="group ml-1 inline-flex h-6 max-w-[85%] items-center overflow-visible truncate rounded bg-gray-800 bg-opacity-0 px-2 pt-0.5 font-mono text-xs font-normal text-gray-500 hover:bg-opacity-5 hover:text-gray-800 focus:outline-none focus-visible:border-indigo-400 focus-visible:ring-1 focus-visible:ring-indigo-400 active:bg-opacity-10"
            onClick={toggle}
          >
            <span className="truncate">{lastQuery}</span>
          </button>
        ) : (
          <div className="w-[85%] rounded bg-gray-100">
            <ReadOnlyEditor maxHeight="16rem" defaultDoc={lastQuery} language={sql()} />
          </div>
        )}
        {!isOneLine && (
          <button
            className="group ml-1 flex h-6 w-8 items-center justify-center rounded bg-gray-800 bg-opacity-0 text-gray-500 hover:bg-opacity-5 hover:text-gray-800 focus:outline-none focus-visible:border-indigo-400 focus-visible:ring-1 focus-visible:ring-indigo-400 active:bg-opacity-10"
            onClick={toggle}
          >
            <Icon
              name="Disclosure"
              className={cx(
                show ? '-rotate-90' : 'translate-y-px rotate-90',
                'h-5 w-5 text-gray-400 group-hover:text-gray-600'
              )}
            />
          </button>
        )}
      </div>
      <div className="ml-4 mt-0.5 self-start">{rightSlot}</div>
    </TableTopper>
  );
}
