import { useState } from 'react';
import { ConfirmationRequestFragment } from '~/generated/graphql';

export function useConfirmation() {
  const [confirmation, setConfirmation] = useState<ConfirmationRequestFragment>();

  // Returns true if request was handled
  const handleConfirmation = (data): data is ConfirmationRequestFragment => {
    if ((data as ConfirmationRequestFragment)?.__typename === 'ConfirmationRequest') {
      setConfirmation(data);
      return true;
    }
    return false;
  };

  const resetConfirmation = () => {
    setConfirmation(null);
  };

  return {
    confirmationMessage: confirmation?.message,
    confirmation: confirmation?.confirm,
    handleConfirmation,
    resetConfirmation
  };
}
