import { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';
import { cn, getClassNames, type VariantProps } from '~/lib/utils';
import { CheckSmallIcon, CloseIcon, ExclamationIcon, InfoIcon, WarningIcon } from './Icons';

const alertVariants = tv({
  slots: {
    container: 'flex items-start justify-start gap-1 rounded border p-4 text-white shadow-lg',
    icon: 'h-5 w-5',
    text: 'text-sm text-white'
  },
  variants: {
    variant: {
      danger: {
        container: 'border-red-600 bg-red-500',
        icon: 'text-red-200'
      },
      info: {
        container: 'border-sky-600 bg-sky-500',
        icon: 'text-blue-200  '
      },
      success: {
        container: 'border-emerald-600 bg-emerald-500',
        icon: 'text-emerald-200 '
      },
      warning: {
        container: 'border-orange-600 bg-orange-500 ',
        icon: 'text-orange-200'
      }
    }
  }
});

export interface AlertProps extends VariantProps<typeof alertVariants> {
  children?: React.ReactNode;
  className?: string;
  isCloseable?: boolean;
  timeout?: number;
}

const VariantIcon = {
  danger: ExclamationIcon,
  info: InfoIcon,
  success: CheckSmallIcon,
  warning: WarningIcon
};

export function Alert({ children, timeout, isCloseable, ...props }: AlertProps) {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    timeout && setTimeout(() => setHidden(true), timeout);
  }, [timeout]);

  const { container, icon, text } = getClassNames(alertVariants, props);
  const Icon = VariantIcon[props.variant];
  return (
    <div className={cn(container, hidden && 'hidden')}>
      <Icon className={icon} />
      <p className={text}>{children}</p>
      <div className="flex-1" />
      {isCloseable && (
        <button className="cursor-pointer" onClick={() => setHidden(true)}>
          <CloseIcon className={cn(icon, 'h-4 w-4')} />
        </button>
      )}
    </div>
  );
}
