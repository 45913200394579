import { ReactNode } from 'react';
import { tv } from 'tailwind-variants';
import { cn, getClassNames, type VariantProps } from '~/lib/utils';

const detailsVariants = tv({
  slots: {
    container: 'group',
    summary: 'flex flex-row-reverse items-center justify-end gap-2',
    show: 'inline cursor-pointer underline group-open:hidden',
    hide: 'hidden cursor-pointer underline group-open:inline',
    content: 'mt-2'
  }
});

interface Props extends VariantProps<typeof detailsVariants> {
  children: ReactNode;
  summary: ReactNode;
  hide?: ReactNode;
  show?: ReactNode;
}

export function Details({ children, summary, hide, show, ...props }: Props) {
  const classNames = getClassNames(detailsVariants, props);
  return (
    <details className={cn(classNames.container)}>
      <summary className={cn(classNames.summary)}>
        <span className={cn(classNames.show)}>{show || 'Show'}</span>
        <span className={cn(classNames.hide)}>{hide || 'Hide'}</span>
        <p>{summary}</p>
      </summary>
      <div className={cn(classNames.content)}>{children}</div>
    </details>
  );
}
