import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  FormBuilder,
  MyCombobox,
  MyInput,
  SideBySide
} from '../../../components';
import { CompletionValue, ConnectionTypeFragment } from '../../../generated/graphql';
import { getSchemaNormalized, isRequiredMsg, Selectable } from '../../../utils';
import { ConnectionFormValues, ConnectionWithoutType } from '../connection-config';
import { OauthButton } from '../connection-components';
import { omit } from 'lodash';

const GONG_AUTH_METHODS = {
  TOKEN: { label: 'Access key and secret', value: 'token' },
  OAUTH: { label: 'OAuth', value: 'oauth' }
};

interface Props {
  connection?: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
  features?: string[];
  oauthLoading: boolean;
  saved: boolean;
  promiseOptions: (field: string, query?: string) => Promise<CompletionValue[]>;
  onSave: (force: boolean) => (data: ConnectionFormValues) => Promise<void>;
  setConnectionType: React.Dispatch<React.SetStateAction<ConnectionTypeFragment>>;
}

export function GongConnectionConfig(props: Props) {
  const {
    connection,
    connectionType,
    features,
    oauthLoading,
    saved,
    promiseOptions,
    onSave,
    setConnectionType
  } = props;

  const { control, setValue, register, getValues, formState } =
    useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  const [authMethod, setAuthMethod] = React.useState<Selectable>(() => {
    const auth = getValues('configuration.auth_method');
    return auth === 'oauth' ? GONG_AUTH_METHODS.OAUTH : GONG_AUTH_METHODS.TOKEN;
  });

  const fields = getSchemaNormalized(connectionType.configurationSchema);
  const accessKey = useWatch({ control, name: 'configuration.access_key' });
  const accessSecret = useWatch({ control, name: 'configuration.access_secret' });

  const handleAuthMethodChange = (option: Selectable | null) => {
    if (option) {
      setValue('configuration.auth_method', option.value, {
        shouldValidate: true,
        shouldDirty: true
      });
      setAuthMethod(option);
      setConnectionType({ ...connectionType, useOAuth: option.value === 'oauth' });
    }
  };

  return (
    <>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Authentication method">
        <EditPermission
          fallback={<DisabledSelect valueLabel={authMethod?.label} className="max-w-xs" />}
        >
          <MyCombobox
            className="max-w-xs"
            value={authMethod}
            options={Object.values(GONG_AUTH_METHODS)}
            onChange={handleAuthMethodChange}
          />
        </EditPermission>
      </SideBySide>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Options">
        {authMethod?.value === GONG_AUTH_METHODS.TOKEN.value && (
          <div className="max-w-xs">
            <EditPermission>
              <MyInput
                {...register('configuration.access_key', {
                  required: isRequiredMsg(fields?.['access_key']?.title)
                })}
                label={fields?.['access_key']?.title}
                description={fields?.['access_key']?.description}
                errors={errors}
              />
            </EditPermission>
            <EditPermission>
              <div className="mt-3">
                <MyInput
                  {...register('configuration.access_secret', {
                    required: isRequiredMsg(fields?.['access_secret']?.title)
                  })}
                  label={fields?.['access_secret']?.title}
                  description={fields?.['access_secret']?.description}
                  errors={errors}
                />
              </div>
            </EditPermission>
            <EditPermission>
              <div className="mt-3">
                <MyInput
                  {...register('configuration.subdomain', {
                    required: isRequiredMsg(fields?.['subdomain']?.title)
                  })}
                  label={fields?.['subdomain']?.title}
                  description={fields?.['subdomain']?.description}
                  errors={errors}
                />
              </div>
            </EditPermission>
          </div>
        )}
        {authMethod?.value === GONG_AUTH_METHODS.OAUTH.value && (
          <div className="max-w-xs">
            <OauthButton
              isConnected={
                saved ||
                (!!connection?.saved && authMethod?.value === GONG_AUTH_METHODS.OAUTH.value)
              }
              oauthLoading={oauthLoading}
              connectionTypeName={connectionType.name}
              onSave={onSave}
            />
          </div>
        )}
        {((accessKey && accessSecret) || connection?.saved) && (
          <FormBuilder<ConnectionFormValues>
            features={features}
            schema={omit(props.connectionType.configurationSchema, [
              'definitions.ConnectionConf.properties.auth_method',
              'definitions.ConnectionConf.properties.access_key',
              'definitions.ConnectionConf.properties.access_secret',
              'definitions.ConnectionConf.properties.subdomain'
            ])}
            prefix="configuration"
            promiseOptions={promiseOptions}
          />
        )}
      </SideBySide>
    </>
  );
}
