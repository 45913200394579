import { json } from '@codemirror/lang-json';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  Label,
  MyCombobox,
  Section,
  SideBySide
} from '~/components';
import { MongoDbConfiguration } from '~/generated/graphql';
import { useFieldsetState, useModeSwitcher, useModelQueryRef } from '~/hooks';
import {
  FieldsetFormValues,
  ModeConfiguration,
  filterToIds,
  findName,
  getSchemaAsList,
  hasItems,
  objToSelectables
} from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { EnumPicker, FieldsTable, ModelQueryEditor, TrackingColumns } from '../components';

const modeConfiguration: ModeConfiguration<MongoDbConfiguration> = {
  modes: [
    { name: 'fields', label: 'Field select' },
    { name: 'query', label: 'Query' },
    { name: 'aggregation', label: 'Aggregation' }
  ],
  saveObj: (mode, conf) => ({
    configuration: {
      database: conf.database,
      collection: conf.collection,
      query: mode === 'query' ? conf.query : '',
      aggregation: mode === 'aggregation' ? conf.aggregation : '',
      trackingColumns: conf.trackingColumns != null ? [...conf.trackingColumns] : []
    }
  }),
  reset: conf => ({
    configuration: {
      database: conf.database,
      collection: conf.collection,
      query: '',
      aggregation: '',
      trackingColumns: []
    }
  })
};

const queryUpdateObj = (conf: MongoDbConfiguration | undefined) => ({
  configuration: {
    database: conf?.database || '',
    collection: conf?.collection || '',
    query: conf?.query || '',
    aggregation: '',
    trackingColumns: []
  }
});

const aggregationUpdateObj = (conf: MongoDbConfiguration | undefined) => ({
  configuration: {
    database: conf?.database || '',
    collection: conf?.collection || '',
    query: '',
    aggregation: conf?.aggregation || '',
    trackingColumns: []
  }
});

export function MongoFieldsetConfig() {
  const { fieldset, loading, refreshing, applyUpdate } = useFieldsetState();

  const { control, getValues } = useFormContext<FieldsetFormValues>();

  const database = useWatch({ control, name: 'configuration.database' });
  const collection = useWatch({ control, name: 'configuration.collection' });

  const list = React.useMemo(
    () => getSchemaAsList(fieldset?.configurationSchema, 'configuration'),
    [fieldset?.configurationSchema]
  );

  const defaultQueryDoc = (fieldset?.configuration as MongoDbConfiguration)?.query;
  const queryRef = useModelQueryRef('query', defaultQueryDoc);

  const defaultAggregationDoc = (fieldset?.configuration as MongoDbConfiguration)?.aggregation;
  const aggregationRef = useModelQueryRef('aggregation', defaultAggregationDoc);

  const handleCollectionUpdate = React.useCallback(() => {
    applyUpdate(
      {
        database: getValues('configuration.database'),
        collection: getValues('configuration.collection'),
        query: '',
        aggregation: '',
        trackingColumns: filterToIds(getValues('configuration.trackingColumns'))
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  const { modes, mode, handleMode } = useModeSwitcher<MongoDbConfiguration>(modeConfiguration, {
    fields: handleCollectionUpdate
  });

  const handleDatabaseUpdate = React.useCallback(() => {
    applyUpdate(
      {
        database: getValues('configuration.database'),
        collection: '',
        query: '',
        aggregation: '',
        trackingColumns: []
      },
      { refresh: true, resetFields: true }
    );
  }, [applyUpdate, getValues]);

  const handleRefresh = React.useCallback(() => {
    applyUpdate(
      {
        ...getValues('configuration'),
        trackingColumns: filterToIds(getValues('configuration.trackingColumns'))
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          <div className="w-full max-w-xs animate-fadeIn space-y-3">
            <EnumPicker
              item={findName(list, 'configuration.database')}
              fieldOptions={{ required: 'Must specify database' }}
              onChange={handleDatabaseUpdate}
            />
            {database && (
              <EnumPicker
                item={findName(list, 'configuration.collection')}
                fieldOptions={{ required: 'Must specify collection' }}
                onChange={handleCollectionUpdate}
              />
            )}
            {database && collection && (
              <div>
                <Label>Mode</Label>
                <EditPermission
                  fallback={<DisabledSelect className="max-w-xs" valueLabel={modes[mode]} />}
                >
                  <MyCombobox
                    className="max-w-xs"
                    value={{ label: modes[mode], value: mode }}
                    options={objToSelectables(modes, true)}
                    onChange={handleMode}
                    isDisabled={loading}
                  />
                </EditPermission>
              </div>
            )}
          </div>
        </SideBySide>
        {database && collection && (
          <>
            {mode === 'query' && (
              <ModelQueryEditor
                key="mongo-query"
                language={json()}
                heading="MongoDB query"
                placeholder="Enter query as MongoDB Extended JSON v2..."
                path="configuration.query"
                updateObj={queryUpdateObj}
                defaultDoc={defaultQueryDoc}
                queryRef={queryRef}
              />
            )}
            {mode === 'aggregation' && (
              <ModelQueryEditor
                key="mongo-aggregation"
                language={json()}
                heading="MongoDB aggregation editor"
                placeholder="Enter aggregation as MongoDB Extended JSON v2..."
                path="configuration.aggregation"
                updateObj={aggregationUpdateObj}
                defaultDoc={defaultAggregationDoc}
                queryRef={aggregationRef}
              />
            )}
          </>
        )}
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || (loading && !fieldset?.fields)}
          disabled={loading}
          refresh={mode === 'fields' ? handleRefresh : undefined}
          hasWriteinFields={fieldset?.properties.writeinFields}
        />
      </Section>
      {database && collection && hasItems(fieldset?.fields) && (
        <>
          <AdditionalConfig />
          {mode === 'fields' && <TrackingColumns />}
        </>
      )}
    </>
  );
}
