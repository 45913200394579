import { ColumnDef } from '@tanstack/react-table';

import Table from '~/components/v2/table/Table';
import { SqlRunnerResult } from '~/generated/graphql';

export type RunnerResultsTableProps = {
  data: SqlRunnerResult;
};

export function RunnerResultsTable({ data }: RunnerResultsTableProps) {
  const tableData = data.rows.map(row =>
    row.reduce((a, c, i) => ({ ...a, [data.columnNames[i]]: c }), {})
  );
  const tableCols: ColumnDef<Record<string, unknown>>[] = data.columnNames.map(v => ({
    id: v,
    accessorKey: v,
    accessorFn: row => row[v],
    header: v,
    cell: info => info?.getValue() || null,
    minSize: 75,
    size: 175
  }));
  return (
    <Table
      data={tableData}
      columns={tableCols}
      enableColumnResizing
      alternatingRowColors
      TableWrapperProps={{ className: 'flex-1 h-full overflow-auto' }}
      TableBodyRowProps={{ enableRowHover: true }}
      TableBodyColProps={{ className: 'border-r border-gray-300 py-2.5' }}
    />
  );
}
