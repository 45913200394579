import useStateMachine, { t } from '@cassiozen/usestatemachine';
import {
  createConnection,
  fetchConnection,
  fetchConnectionTypes,
  fetchMe,
  updateConnection
} from './api';

const readText = async (file: File) =>
  new Promise((accept, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => accept(reader.result));
    reader.addEventListener('error', () => reject(reader.error));
    reader.readAsText(file);
  });

const usesOAuth = (useOAuth: boolean, configuration: Record<string, unknown>) => {
  for (const [k, v] of Object.entries(configuration)) {
    switch (k) {
      case 'auth_method':
        return v === 'oauth';
      case 'connect_mode':
        return v === 'browser';
    }
  }
  return useOAuth;
};

interface ConnectionType {
  id: string;
  envConfig?: Record<string, unknown>;
  name: string;
  schema: Record<string, unknown>;
  useOAuth?: boolean;
}

interface Connection {
  id?: string;
  authUrl?: string;
  configuration?: Record<string, unknown>;
  name: string;
  status?: string;
  statusError?: string;
}

interface Initialize {
  connectionId?: string;
  name: string;
  token: string;
  typeId?: string;
  redirectUrl: string;
  whitelist?: string[];
}

interface Context {
  connection: Connection;
  connectionType: ConnectionType;
  failure?: string;
  organizationName: string;
  name: string;
  redirectUrl: string;
  token: string;
  whitelisted: ConnectionType[];
}

export function useConnect() {
  const [state, send] = useStateMachine({
    schema: {
      context: t<Context>(),
      events: {
        CONNECT: t<{ form: Record<string, unknown> }>(),
        FAIL: t<{ failure: string }>(),
        INITIALIZE: t<Initialize>(),
        RESTART: t<Context>(),
        SELECT: t<{ typeId: string }>()
      }
    },
    context: {
      connection: {
        name: ''
      },
      connectionType: {
        id: '',
        name: '',
        schema: {}
      },
      name: '',
      organizationName: '',
      token: '',
      redirectUrl: '',
      whitelisted: []
    },
    initial: 'idle',
    states: {
      canceled: {
        effect({ context }) {
          const href = new URL(context.redirectUrl);
          href.searchParams.append('status', 'canceled');
          window.location.href = href.toString();
        }
      },
      failure: {
        on: {
          CANCEL: 'canceled'
        },
        effect({ setContext, event }) {
          switch (event.type) {
            case 'FAIL':
              setContext(prev => ({ ...prev, failure: event.failure }));
              break;
          }
        }
      },
      idle: {
        on: {
          INITIALIZE: 'initializing',
          RESTART: 'connecting'
        }
      },
      initializing: {
        on: {
          INITIALIZED: 'selecting'
        },
        effect({ setContext, event }) {
          switch (event.type) {
            case 'INITIALIZE': {
              const { connectionId, name, token, typeId, redirectUrl, whitelist } = event;
              const exec = async () => {
                try {
                  const [{ data: connection }, { data: user }, { data: types }] = await Promise.all(
                    [
                      connectionId
                        ? fetchConnection(token, connectionId)
                        : Promise.resolve({ data: { name } }),
                      fetchMe(token),
                      fetchConnectionTypes(token)
                    ]
                  );

                  const connectionType = types.find(({ id }) => id === typeId) || {
                    id: '',
                    name: '',
                    schema: {}
                  };
                  const whitelisted = types.filter(item =>
                    whitelist.length === 0 ? true : whitelist.includes(item.id)
                  );

                  setContext(prev => ({
                    ...prev,
                    connection,
                    connectionType,
                    name,
                    organizationName: user.organization_name,
                    token,
                    redirectUrl,
                    whitelisted
                  })).send('INITIALIZED');
                } catch (error) {
                  const { message } = error as Error;
                  send({ type: 'FAIL', failure: message });
                }
              };

              exec();
            }
          }
        }
      },
      selecting: {
        on: {
          BYPASS_SELECT: 'confirming',
          SELECT: {
            target: 'confirming'
          }
        },
        effect(args) {
          if (args.context.connectionType.id.length > 0) {
            args.send('BYPASS_SELECT');
          }
        }
      },
      confirming: {
        on: {
          BYPASS_CONFIRMING: 'connecting',
          CANCEL: 'canceled',
          CONFIRM: 'connecting'
        },
        effect(args) {
          switch (args.event.type) {
            case 'SELECT':
              const typeId = args.event.typeId;
              const connectionType = args.context.whitelisted.find(({ id }) => id === typeId);
              args.setContext(prev => ({ ...prev, connectionType }));
              break;
            default:
              if (!!args.context.connection.id) {
                args.send('BYPASS_CONFIRMING');
              }
          }
        }
      },
      connecting: {
        on: {
          BYPASS_CONNECTING: 'reviewing',
          CANCEL: 'canceled',
          CONNECT: 'saving',
          FAIL: 'failure'
        },
        effect({ event, setContext }) {
          switch (event.type) {
            case 'RESTART':
              const exec = async () => {
                try {
                  const { data: connection } = await fetchConnection(
                    event.token,
                    event.connection.id
                  );
                  setContext(prev => ({ ...prev, ...event, connection }));
                  if (connection.status === 'healthy') {
                    send('BYPASS_CONNECTING');
                  }
                } catch (error) {
                  const { message } = error as Error;
                  send({ type: 'FAIL', failure: message });
                }
              };
              exec();
          }
        }
      },
      saving: {
        on: {
          SAVED: 'authorizing',
          SAVED_RECONNECT: 'authorizing',
          FAIL: 'failure'
        },
        effect({ context, event, send, setContext }) {
          const exec = async () => {
            const { connection, connectionType } = context;
            const configuration = event.form;

            // check for an OAuth reconnect flag
            const isReconnect = Boolean(configuration['__reconnect']);
            delete configuration['__reconnect'];

            // read files in the configuration
            for (const key in configuration) {
              if (configuration[key] instanceof FileList) {
                const [file] = configuration[key] as FileList;
                configuration[key] = await readText(file);
              }
            }

            // remove invalid fields
            switch (connectionType.id) {
              case 'gsheets':
                delete configuration['oauth_token_expiry'];
            }

            const redirect_url = usesOAuth(connectionType.useOAuth, configuration)
              ? `${window.location.origin}/polytomic-connect/${connectionType.id}/?token=${context.token}`
              : undefined;

            const args = {
              configuration,
              redirect_url,
              name: connection.name,
              type: connectionType.id
            };

            try {
              const { data } = await (connection.id
                ? updateConnection(context.token, connection.id, args)
                : createConnection(context.token, args));

              setContext(prev => ({
                ...prev,
                connection: data
              })).send(isReconnect ? 'SAVED_RECONNECT' : 'SAVED');
            } catch (error) {
              const { message } = error as Error;
              send({ type: 'FAIL', failure: message });
            }
          };

          exec();
        }
      },
      authorizing: {
        on: {
          AUTHORIZED: 'reviewing',
          UPDATING: 'connecting'
        },
        effect({ context, send, event }) {
          switch (event.type) {
            case 'SAVED_RECONNECT':
              window.location.href = context.connection.authUrl;
              return;
            case 'SAVED':
              const isUnhealthy = context.connection.status !== 'healthy';
              const isUnsaved = context.connection.status === '';

              if (isUnhealthy && context.connection.authUrl) {
                window.location.href = context.connection.authUrl;
                return;
              }
              if (isUnsaved) {
                send('UPDATING');
              }
              send('AUTHORIZED');
          }
        }
      },
      reviewing: {
        on: {
          COMPLETE: 'completed'
        }
      },
      completed: {
        effect({ context }) {
          const href = new URL(context.redirectUrl);
          href.searchParams.append('connection', context.connection.id);
          href.searchParams.append('name', context.connection.name);
          href.searchParams.append('status', context.connection.status || 'healthy');
          window.location.href = href.toString();
        }
      }
    }
  });

  return {
    context: state.context,
    value: state.value,

    initialize: (args: Initialize) => send({ type: 'INITIALIZE', ...args }),
    cancel: () => send('CANCEL'),
    complete: () => send('COMPLETE'),
    confirm: () => send({ type: 'CONFIRM' }),
    connect: (form: Record<string, unknown>) => send({ type: 'CONNECT', form }),
    restart: (args: Context) => send({ type: 'RESTART', ...args }),
    select: (typeId: string) => send({ type: 'SELECT', typeId })
  };
}
