import { Selectable } from './custom-types.util';

export const ADMIN_ROLE_ID = '00000000-0000-0000-0000-000000000001';
export const WINDOW_THRESHOLD = 200;
export const DAYS: Selectable[] = [
  { value: '0', label: 'Sunday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tueday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' }
];
export const EMPTY_MAPPING = { model: null, target: null };
export const EMPTY_FILTER = {
  column: '',
  comparator: undefined,
  param: ''
};
export const HISTORY_FILTERS: Record<string, string> = {
  time: 'Sync time',
  trigger: 'Type',
  schema: 'Schema',
  status: 'Status',
  recordCount: 'Total records',
  insertCount: 'Inserted',
  updateCount: 'Updated',
  errorCount: 'Errors',
  warningCount: 'Warnings'
};
export const CREATE_TARGET_TABLE = '__pt_new_target';
export const CREATE_TARGET_SCHEMA = '__pt_new_schema';
export const SYNC_FAILURE_ERROR_SUFFIX = ' See sync history page for details.';
export const NO_PERMISSION = 'You do not have permission to perform this operation.';
export const NO_EDIT_PERMISSION = 'You do not have edit permissions.';
export const PRIMARY_LINK_BTN_STYLES =
  'inline-flex h-8 items-center rounded border border-indigo-700 bg-indigo-500 px-3 text-sm font-medium text-white shadow-button hover:bg-indigo-600 focus:outline-none focus-visible:border-gray-800 focus-visible:ring-4 focus-visible:ring-indigo-300 active:bg-indigo-700';
