import { Button, Permission } from '~/components';
import { ResourceType } from '~/generated/graphql';
import { useToggle } from '~/hooks';
import { RoleOption } from '~/utils';
import { EditTagDialog } from './edit-tag-dialog';

export function AddTag({
  setToggle,
  roles
}: {
  roles: RoleOption[];
  setToggle: (id: string) => void;
}) {
  const [show, toggle] = useToggle();

  return (
    <>
      <Permission type={ResourceType.Tag}>
        <Button theme="outline" onClick={toggle}>
          Create policy
        </Button>
      </Permission>
      {show && (
        <EditTagDialog roles={roles} type="create" setToggle={setToggle} toggleDialog={toggle} />
      )}
    </>
  );
}
