import { Reference, useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { isEmpty } from 'lodash';
import { Button, EditPermission, PromptUnsaved } from '~/components';
import StageMappingsV2 from '~/components/v2/experimental/StageMappings';
import Dots from '~/components/v2/feedback/Dots';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import PageLayout from '~/components/v2/layout/PageLayout';
import { syncToSyncPeek } from '~/components/v2/util/DataConverters';
import { Dialog } from '~/components/v3';
import {
  ConnectionFragment,
  CreateSyncDocument,
  FieldReferenceType,
  FilterFunction,
  ModelFieldFragment,
  Operation,
  PreviewSyncDocument,
  PreviewSyncQuery,
  PreviewSyncQueryVariables,
  SyncDocument,
  SyncFragment,
  SyncPreviewFragment,
  SyncRecords,
  TargetFieldFragment,
  TargetObjectDocument,
  TargetObjectWithFieldsFragment,
  UpdateSyncDocument
} from '~/generated/graphql';
import { SyncPeekFragment } from '~/graphql/fragments';
import {
  useBannerDispatch,
  useFilterFunctions,
  useLazyContinuationQuery,
  useModelFields,
  useReachableFields,
  useSanitizeIdText,
  useToggle
} from '~/hooks';
import { ExploreFilter } from '~/pages/explore/ExploreFilters';
import {
  StageCard,
  StageMode,
  StageSchedule,
  StageSummary,
  StageTarget,
  SyncConfigPreview
} from '~/pages/syncs/sync-config';
import {
  CREATE_TARGET_SCHEMA,
  EMPTY_MAPPING,
  Selectable,
  SyncConfigFormValues,
  getDataArchitecturePath,
  getMapping,
  getSchemaHierarchy,
  getSchemaNormalized,
  getTargetConnectionObjectLabel,
  handleMappingsUpdates,
  handleTargetSearchValues,
  hasItems,
  isModelMapping,
  plural,
  prepareSyncUpdate,
  routes,
  splitFilters,
  truthyBoolean,
  validateMappings,
  validateNames,
  validateSchedule
} from '~/utils';

export const PARTIAL_SYNC_STORAGE_KEY = 'polytomic.partialSync';
interface PartialSync {
  targetObject: TargetObjectWithFieldsFragment;
  targetConnection: ConnectionFragment;
  searchValues: Record<string, string>;
  fields: ModelFieldFragment[];
  identity: ModelFieldFragment;
  filters: ExploreFilter[];
  conditionLogic: string;
}

function setSyncFromDefaultValues(sync: SyncFragment | undefined) {
  if (!sync) {
    return {};
  }
  const { fields, overrideFields, ...rest } = sync;
  const { modelFilters, targetFilters } = splitFilters(rest.filters);
  const mappings = [...fields, ...overrideFields];
  const targetSearchValues = handleTargetSearchValues(rest);
  const runAfterSyncs = [...sync.runAfterSyncs, ...sync.runAfterBulkSyncs];

  return {
    defaultValues: {
      ...rest,
      mode: sync.mode || null,
      identity: sync?.identity || EMPTY_MAPPING,
      mappings: hasItems(mappings) ? mappings : [EMPTY_MAPPING],
      ...targetSearchValues,
      modelFilters: { logic: rest.filterLogic, filters: modelFilters },
      targetFilters: { logic: rest.targetFilterLogic, filters: targetFilters },
      runAfterSyncs
    }
  };
}

const wrapperStyles = 'px-3 pt-3 max-w-5xl mx-auto';

const SyncConfig = (props: { sync?: SyncFragment }) => {
  const history = useHistory();
  // @ts-expect-error filter.value types are messed up
  const methods = useForm<SyncConfigFormValues>({
    ...setSyncFromDefaultValues(props.sync)
  });
  const { setValue, getValues, reset } = methods;

  const dispatchBanner = useBannerDispatch();
  const { modelFields } = useModelFields();
  const { getFilterFunction } = useFilterFunctions();
  const getReachableFields = useReachableFields();

  const [sync, setSync] = useState<SyncFragment | undefined>(props.sync);
  const [targetObjectFields, setTargetObjectFields] = useState<TargetFieldFragment[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [newParentName, setNewParentName] = useState('');
  const [newTargetName, setNewTargetName] = useState('');
  const [dataArchitectureList, setDataArchitectureList] = useState<Selectable[]>([]);

  const [confirmationDialog, setConfirmationDialog] = useState<
    'targetCreator' | 'fieldCreation' | null
  >(null);
  const clearConfirmationDialog = () => setConfirmationDialog(null);

  const [createdFieldsCount, setCreatedFieldsCount] = useState(0);

  const isEditing = !!props.sync;
  const [showScheduleStage, setShowScheduleStage] = useState(isEditing);
  const [showSummary, setShowSummary] = useState(isEditing);

  const [previewData, setPreviewData] = useState<SyncPreviewFragment>();
  const [showPreviewDialog, togglePreviewDialog] = useToggle();

  const [loadingSync, setLoadingSync] = useState(!!localStorage.getItem(PARTIAL_SYNC_STORAGE_KEY));

  const [createSync, { loading: createSyncLoading }] = useMutation(CreateSyncDocument, {
    fetchPolicy: 'no-cache',
    onCompleted: async data => {
      if (!data || !data.createSync) {
        return;
      }
      const {
        targetConnection,
        targetObject,
        searchValues,
        identity,
        fields,
        filters,
        conditionLogic
      } = (JSON.parse(localStorage.getItem(PARTIAL_SYNC_STORAGE_KEY)) as PartialSync) ?? {};
      localStorage.removeItem(PARTIAL_SYNC_STORAGE_KEY);
      const sync = data.createSync;
      setSync(sync);
      setTargetObjectFields(targetObject?.fields ?? []);

      methods.reset({
        id: sync.id,
        name: '',
        notes: '',
        targetConnection,
        targetObject,
        targetObjectConfiguration: targetObject?.advancedConfiguration?.jsonschema ?? {},
        targetSearchValues: searchValues ?? null,
        mode: !isEmpty(targetObject) ? targetObject.modes[0].mode : null,
        identity: !isEmpty(identity)
          ? getMapping({ targetObject, field: identity, identity })
          : EMPTY_MAPPING,
        mappings: !isEmpty(fields)
          ? fields.map(field => getMapping({ targetObject, field, identity }))
          : [EMPTY_MAPPING],
        overrides: [],
        // When rewriting, we shouldn't depend on filters having a fieldFragment,
        // but use only props needed like the ExploreFilter type
        modelFilters: !isEmpty(filters)
          ? {
              logic: conditionLogic,
              filters: filters.map(filter => ({
                label: filter.label,
                value: filter.value,
                function: filter.function as FilterFunction,
                field: modelFields.find(field => field.id === filter.fieldID)
              }))
            }
          : { logic: '', filters: [] },
        targetFilters: { logic: '', filters: [] },
        schedule: null,
        runAfterSyncs: [],
        syncAllRecords: false
      });
      setLoadingSync(false);
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } })
  });

  // I think there is a bug with loading state on useQuery when
  // skip has been employed and we try to refetch. Loading state
  // doesn't get updated. It just shows 7 (ready) even though a
  // network call is in flight.
  const [getTargetObject, { loading: targetFieldsLoading }] = useLazyQuery(TargetObjectDocument, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (!data || !data.targetObject) {
        return;
      }
      const { fields, ...newTargetObj } = data.targetObject;
      setTargetObjectFields(fields);
      setValue('targetObject', data.targetObject);
      if (newTargetObj?.modes.length === 1) {
        setValue('mode', newTargetObj.modes[0].mode);
      }
    },
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
    }
  });

  useEffect(() => {
    if (!sync && !isEditing && !isEmpty(modelFields)) {
      createSync();
    }
  }, [modelFields]);

  useEffect(() => {
    if (props.sync?.targetConnection?.id && props.sync?.targetObject?.id) {
      void getTargetObject({
        variables: {
          connectionId: props.sync?.targetConnection?.id || '',
          targetObject: props.sync?.targetObject?.id || '',
          refresh: false
        }
      });
    }
  }, []);

  const [updateSync, { loading: updateSyncLoading }] = useMutation(UpdateSyncDocument, {
    refetchQueries: [SyncDocument],
    onError: error => {
      clearConfirmationDialog();
      if (error.message.startsWith('Google Ads only allows one of these user identifiers:')) {
        const message = (
          <p dangerouslySetInnerHTML={{ __html: error.message.replaceAll('\n', '<br />') }} />
        );
        dispatchBanner({
          type: 'show',
          payload: { message, wrapper: `${wrapperStyles} h-32` }
        });
      } else {
        dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
      }
    },
    update: (cache, { data }) => {
      if (!data || !data.updateSync) {
        return;
      }
      const sync = data.updateSync;
      // make sure get sync doesn't make network call
      cache.writeQuery({
        query: SyncDocument,
        variables: { id: sync.id },
        data: {
          sync
        }
      });
      cache.writeFragment({
        fragment: SyncPeekFragment,
        variables: { id: sync.id },
        data: syncToSyncPeek(sync)
      });
      // update the syncs list in the cache with
      // new/updated sync to avoid network call
      cache.modify({
        fields: {
          peekSyncs: (existingRefs: Reference[], { toReference, readField }) => {
            const syncRef = toReference({
              __typename: 'SyncPeek',
              id: sync.id
            });
            if (!existingRefs) {
              return [syncRef];
            }
            const found = existingRefs.some(ref => readField('id', ref) === sync.id);
            if (found) {
              return existingRefs;
            }
            return [...existingRefs, syncRef];
          },
          syncs: (existingRefs: Reference[], { toReference, readField }) => {
            const syncRef = toReference({
              __typename: 'Sync',
              id: sync.id
            });
            if (!existingRefs) {
              return [syncRef];
            }
            const found = existingRefs.some(ref => readField('id', ref) === sync.id);
            if (found) {
              return existingRefs;
            }
            return [...existingRefs, syncRef];
          }
        }
      });
      cache.gc();
      setIsDirty(false);
      history.push(generatePath(routes.syncStatus, { id: sync.id }));
    }
  });

  const mode = methods.watch('mode');
  const targetConnection = methods.watch('targetConnection');
  const targetObject = methods.watch('targetObject');

  // ToDo -> Refactor this (lift out of layoutEffect)
  // handles all the bad states the mappings get into if targetObject changes
  const targetObjRef = useRef<{ targetObjectId: string; connectionId: string } | null>(null);
  useLayoutEffect(() => {
    let mounted = true;
    if (!targetObject || !targetObject?.id) {
      return;
    }
    // If there is a targetObject selected && connection selected
    if (targetObject && targetObject?.id && targetObject?.connection?.id) {
      // If the component is mounted
      if (mounted) {
        // If the current state is not saved to a ref
        // then set the targetObject.id & targetObject.connection.id to the ref
        if (!targetObjRef.current) {
          targetObjRef.current = {
            targetObjectId: targetObject?.id,
            connectionId: targetObject?.connection?.id
          };
          return;
        }
        // if ref is not equal to targetObject.id & targetObject.connection.id
        // then reset the state
        if (
          targetObjRef.current.targetObjectId !== targetObject?.id ||
          targetObjRef.current.connectionId !== targetObject.connection.id
        ) {
          // finally update the current ref to be the targetObject.id & targetObject.connection.id
          targetObjRef.current = {
            targetObjectId: targetObject?.id,
            connectionId: targetObject?.connection?.id
          };
          reset(handleMappingsUpdates(getValues(), targetObjectFields));
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [
    getValues,
    reset,
    targetObject,
    targetObject?.id,
    targetObject?.connection?.id,
    targetObjectFields
  ]);

  const schema = useMemo(
    () => getSchemaNormalized(targetConnection?.type.destinationDataArchitecture),
    [targetConnection?.type.destinationDataArchitecture]
  );
  const { parent, child } = useMemo(
    () => getSchemaHierarchy(targetConnection?.type.destinationDataArchitecture),
    [targetConnection?.type.destinationDataArchitecture]
  );
  const parentValue = parent ? methods.watch(`targetSearchValues.${parent}`) : null;
  const parentKey = useMemo(
    () => schema?.[parent]?.title?.toLocaleLowerCase() || 'schema',
    [parent, schema]
  );
  const childKey = useMemo(
    () => schema?.[child]?.title?.toLocaleLowerCase() || 'table',
    [child, schema]
  );
  const parentLabel = useMemo(
    () => dataArchitectureList.find(item => item.value === parentValue)?.label,
    [dataArchitectureList, parentValue]
  );
  const path = useMemo(
    () => getDataArchitecturePath(targetConnection?.type.destinationDataArchitecture),
    [targetConnection?.type.destinationDataArchitecture]
  );

  const hasInlineConfig = useMemo(
    () => !!targetConnection?.type.operations.includes(Operation.DestinationRequireConfiguration),
    [targetConnection?.type.operations]
  );
  const isTargetCreator = useMemo(
    () => targetObject?.properties?.targetCreator,
    [targetObject?.properties?.targetCreator]
  );
  const supportsFieldCreation = useMemo(
    () => targetObject?.properties?.supportsFieldCreation,
    [targetObject?.properties?.supportsFieldCreation]
  );
  const supportsFieldTypeSelection = useMemo(
    () => targetObject?.properties?.supportsFieldTypeSelection,
    [targetObject?.properties?.supportsFieldTypeSelection]
  );
  const isDynamicTarget = useMemo(
    () => !!targetConnection?.type.destinationDataArchitecture,
    [targetConnection?.type.destinationDataArchitecture]
  );

  const showMappingsStage = [
    mode,
    modelFields,
    isEditing ? hasItems(targetObjectFields) : supportsFieldCreation || hasItems(targetObjectFields)
  ].every(truthyBoolean);

  useEffect(() => {
    if (props.sync?.targetConnection?.id && props.sync?.targetObject?.id) {
      if (
        targetObjRef?.current?.targetObjectId !== props.sync?.targetObject?.id ||
        targetObjRef?.current?.connectionId !== props.sync?.targetConnection?.id
      ) {
        void getTargetObject({
          variables: {
            connectionId: props.sync?.targetConnection?.id || '',
            targetObject: props.sync?.targetObject?.id || '',
            refresh: false
          }
        });
      }
    }
  }, [getTargetObject, props.sync?.targetConnection?.id, props.sync?.targetObject?.id]);

  function handleCancel() {
    if (isEditing) {
      return history.push(generatePath(routes.syncStatus, { id: sync?.id }));
    }
    history.push(routes.syncs);
  }

  const [previewSync, { loading: previewSyncLoading, error: previewSyncError }] =
    useLazyContinuationQuery<PreviewSyncQuery, PreviewSyncQueryVariables>(PreviewSyncDocument, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        if (!data || !data.previewSync) {
          return;
        }
        setPreviewData(data.previewSync as SyncRecords);
      }
    });

  const getPreviewData = useCallback(
    (identityValue?: string) => {
      if (!sync) {
        return null;
      }
      const update = prepareSyncUpdate(Object.assign({}, getValues()), sync.active);
      if (!update) {
        return;
      }
      const identityMapping = getValues('identity');
      void previewSync({
        variables: {
          id: sync.id,
          update,
          continuation: uuid(),
          previewFilters:
            identityValue && identityMapping?.model
              ? [
                  {
                    fieldID: identityMapping.model.id,
                    fieldType: FieldReferenceType.Model,
                    function: FilterFunction.Equality,
                    label: '',
                    value: identityValue
                  }
                ]
              : []
        }
      });
    },
    [getValues, previewSync, sync]
  );

  const validateForPreview = useCallback(() => {
    if (!sync) {
      return;
    }
    dispatchBanner({ type: 'hide' });
    const localErrors = validateMappings(
      getValues(),
      getReachableFields,
      modelFields,
      targetObjectFields,
      getFilterFunction
    );
    if (hasItems(localErrors)) {
      dispatchBanner({ type: 'show', payload: { message: localErrors, wrapper: wrapperStyles } });
    } else {
      getPreviewData();
      togglePreviewDialog();
    }
  }, [
    getPreviewData,
    getReachableFields,
    getValues,
    dispatchBanner,
    modelFields,
    sync,
    targetObjectFields,
    togglePreviewDialog
  ]);

  function handleUpdateSync(newTargetName?: string) {
    const values = getValues();
    const update = prepareSyncUpdate(Object.assign({}, values), sync?.active || false);
    if (!update) {
      return;
    }
    const targetSearchValues = Object.assign({}, update.targetSearchValues);
    if (newTargetName && isDynamicTarget) {
      if (parentValue && parentValue === CREATE_TARGET_SCHEMA) {
        if (targetSearchValues) {
          targetSearchValues[parent] = newParentName;
          targetSearchValues[child] = newTargetName;
        }
      } else {
        if (targetSearchValues) {
          targetSearchValues[child] = newTargetName;
        }
      }
    }
    newTargetName = path.map(field => targetSearchValues[field]).join('.');
    void updateSync({
      variables: {
        update: {
          ...update,
          targetSearchValues,
          newTargetName
        },
        tags: values?.tags?.map(tag => tag.id) || []
      }
    });
  }

  async function onNextStageOrSave() {
    dispatchBanner({ type: 'hide' });
    if (hasInlineConfig) {
      const isTargetObjectIdDraftValid = await methods.trigger('targetObjectIdDraft');
      if (!isTargetObjectIdDraftValid) {
        dispatchBanner({
          type: 'show',
          payload: {
            message: [`${targetConnection?.name || 'Destination'} Filename is required`],
            wrapper: wrapperStyles
          }
        });
        return;
      }
    }
    let localErrors = validateMappings(
      getValues(),
      getReachableFields,
      modelFields,
      targetObjectFields,
      getFilterFunction
    );
    if (showScheduleStage) {
      const scheduleErrors = validateSchedule(getValues());
      localErrors = localErrors.concat(scheduleErrors);
      if (localErrors.length === 0) {
        setShowSummary(true);
      }
    } else {
      if (localErrors.length === 0) {
        setShowScheduleStage(true);
      }
    }
    if (hasItems(localErrors)) {
      dispatchBanner({ type: 'show', payload: { message: localErrors, wrapper: wrapperStyles } });
    }
    if (showSummary) {
      const namingErrors = validateNames(
        !!isTargetCreator,
        parentValue === CREATE_TARGET_SCHEMA
          ? path.map(field => getValues(`targetSearchValues.${field}`)).join('.')
          : null,
        path.map(field => getValues(`targetSearchValues.${field}`)).join('.'),
        getValues('name'),
        schema?.[parent]?.title,
        schema?.[child]?.title
      );
      if (isTargetCreator && schema?.target?.required && !newTargetName?.length) {
        localErrors = localErrors.concat(
          `${
            targetConnection?.type?.id === 'googleads' ? 'List' : schema?.[child]?.title || 'Table'
          } name is required`
        );
      }
      localErrors = localErrors.concat(namingErrors);
      if (hasItems(localErrors)) {
        dispatchBanner({
          type: 'show',
          payload: { message: localErrors, wrapper: wrapperStyles }
        });
      }
      if (localErrors.length === 0 && sync) {
        if (isTargetCreator) {
          setConfirmationDialog('targetCreator');
          return;
        }
        if (supportsFieldCreation || supportsFieldTypeSelection) {
          const mappings =
            // workaround for something like BigQuery that allows
            // adding a table with an empty schema
            targetObjectFields.length === 0
              ? (getValues('mappings') || []).concat(getValues('identity') || [])
              : getValues('mappings') || [];
          const count = mappings.reduce((sum, mapping) => {
            if (
              isModelMapping(mapping) &&
              mapping.target?.id &&
              mapping.target?.name &&
              mapping.newField === true
            ) {
              return sum + 1;
            }
            return sum;
          }, 0);
          if (count > 0) {
            setCreatedFieldsCount(count);
            setConfirmationDialog('fieldCreation');
            return;
          }
        }
        handleUpdateSync();
      }
    }
  }

  const { sanitizeIdText } = useSanitizeIdText();

  async function handleConfirmationDialog() {
    setIsDirty(false);
    const sanitizedParentName = await sanitizeIdText(targetConnection.id, newParentName);
    const sanitizedTargetName = await sanitizeIdText(targetConnection.id, newTargetName);
    setNewParentName(sanitizedParentName);
    setNewTargetName(sanitizedTargetName);
    handleUpdateSync(confirmationDialog === 'targetCreator' ? newTargetName : undefined);
  }

  return (
    <FormProvider {...methods}>
      <PageLayout
        topNavHeading={isEditing && sync ? sync.name : 'Create sync'}
        topNavActions={
          <>
            <Button theme="outline" iconEnd="CloseX" onClick={handleCancel}>
              Cancel
            </Button>
            {(isEditing || showSummary) && targetConnection && targetObject && (
              <EditPermission>
                <Button
                  theme="outline"
                  loading={updateSyncLoading}
                  onClick={onNextStageOrSave}
                  iconEnd="Check"
                >
                  Save
                </Button>
              </EditPermission>
            )}
          </>
        }
        loading={createSyncLoading}
      >
        <div className="animate-fadeIn px-3 pt-6 pb-[35vh]">
          {sync && (
            <>
              {(targetConnection || (!isEditing && !loadingSync)) && (
                <StageTarget
                  getTargetObject={getTargetObject}
                  setIsDirty={setIsDirty}
                  setDataArchitectureList={setDataArchitectureList}
                />
              )}
              <Dots />
              {targetConnection?.id && targetObject?.id && (
                <>
                  <StageMode modes={targetObject?.modes || []} />
                  <Dots />
                  {mode != null && showMappingsStage ? (
                    <>
                      <StageMappingsV2
                        key={targetConnection?.id || sync.id}
                        targetConnection={targetConnection}
                        targetObject={targetObject}
                        syncMode={mode}
                        targetObjectFields={targetObjectFields}
                        setIsDirty={setIsDirty}
                        validateForPreview={validateForPreview}
                        getTargetObject={getTargetObject}
                        targetFieldsLoading={targetFieldsLoading}
                      />
                      <Dots />
                      {showScheduleStage && (
                        <>
                          <StageSchedule
                            syncId={sync.id}
                            setIsDirty={setIsDirty}
                            options={targetObject?.scheduleOptions}
                          />
                          <Dots />
                        </>
                      )}
                      {showSummary && (
                        <StageSummary
                          sync={sync}
                          setIsDirty={setIsDirty}
                          newParentName={newParentName}
                          setNewParentName={setNewParentName}
                          newTargetName={newTargetName}
                          setNewTargetName={setNewTargetName}
                          parentLabel={parentLabel}
                        />
                      )}
                    </>
                  ) : (
                    isEditing &&
                    sync.targetConnection &&
                    sync.targetObject && (
                      <>
                        <StageCard step={3} header="Specify field mappings">
                          <div className="flex h-48 items-center justify-center">
                            <LoadingDots />
                          </div>
                        </StageCard>
                        <Dots />
                      </>
                    )
                  )}
                </>
              )}
            </>
          )}
          {!isEditing && targetConnection && targetObject && showMappingsStage && !showSummary && (
            <div className="flex w-full justify-center">
              <Button loading={updateSyncLoading} onClick={onNextStageOrSave}>
                Continue
              </Button>
            </div>
          )}
        </div>
        {sync && (
          <SyncConfigPreview
            sync={{
              ...sync,
              targetConnection: getValues('targetConnection'),
              targetObject: getValues('targetObject'),
              identity: getValues('identity')
            }}
            getPreviewData={getPreviewData}
            heading="Field preview sample"
            data={previewData}
            show={showPreviewDialog}
            loading={previewSyncLoading}
            error={previewSyncError}
            handleClose={() => {
              togglePreviewDialog();
              setPreviewData(undefined);
            }}
          />
        )}
        <Dialog
          show={confirmationDialog === 'targetCreator'}
          heading={`Save sync and create new
                        ${
                          parentValue === CREATE_TARGET_SCHEMA && newParentName
                            ? `${parentKey || 'schema'}/`
                            : ''
                        }${childKey || 'table'}?
                    `}
          onDismiss={clearConfirmationDialog}
          size="sm"
          classNames={{ body: 'p-6 space-y-2' }}
          actions={
            <>
              <Button onClick={clearConfirmationDialog}>Cancel</Button>
              <Button
                theme="primary"
                loading={updateSyncLoading}
                onClick={handleConfirmationDialog}
              >
                Save and create
              </Button>
            </>
          }
        >
          <p>
            Saving this sync will create a new{' '}
            {parentValue === CREATE_TARGET_SCHEMA && newParentName && (
              <>
                {parentKey} <strong>'{newParentName}'</strong>
                {` and ${childKey} `}
                <strong>'{newTargetName}'</strong>
              </>
            )}
            {parentValue && parentValue !== CREATE_TARGET_SCHEMA && (
              <>
                {childKey} <strong>'{newTargetName}'</strong> in {parentKey}{' '}
                <strong>'{parentLabel || parentValue}'</strong>
              </>
            )}
            {!parentValue && (
              <>
                {childKey} <strong>'{newTargetName}'</strong>
              </>
            )}{' '}
            in {targetConnection?.type?.name}.
          </p>
        </Dialog>
        <Dialog
          show={confirmationDialog === 'fieldCreation'}
          heading={`Save sync and create ${createdFieldsCount} additional ${plural(
            getTargetConnectionObjectLabel(targetConnection?.type?.id),
            createdFieldsCount > 1
          )}?`}
          onDismiss={clearConfirmationDialog}
          size="sm"
          actions={
            <>
              <Button onClick={clearConfirmationDialog}>Cancel</Button>
              <Button
                theme="primary"
                loading={updateSyncLoading}
                onClick={handleConfirmationDialog}
              >
                Save and create
              </Button>
            </>
          }
        >
          <p>
            Saving this sync will create {createdFieldsCount} additional{' '}
            {plural(
              getTargetConnectionObjectLabel(targetConnection?.type?.id),
              createdFieldsCount > 1
            )}{' '}
            in your {targetConnection?.type?.name} {childKey || parentKey || 'database'}.
          </p>
        </Dialog>
      </PageLayout>
      <PromptUnsaved when={isDirty} />
    </FormProvider>
  );
};

export default SyncConfig;
