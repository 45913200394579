import { Icon } from '~/components';

export function ConnectionStub() {
  return (
    <div className="flex items-center justify-between rounded bg-indigo-100 px-4 py-1.5">
      <span>
        <h3 className="mb-0.5 text-base font-medium leading-5">New connection</h3>
        <h4 className="text-sm font-medium text-indigo-500">Configuring...</h4>
      </span>
      <Icon name="FastForward" className="h-5 w-5 text-indigo-500" />
    </div>
  );
}

export function ModelStub() {
  return (
    <section className="border-b border-gray-300 p-2">
      <div className="flex items-center justify-between rounded bg-indigo-100 px-4 py-1.5">
        <span>
          <h3 className="mb-0.5 text-base font-medium leading-5">New model</h3>
          <h4 className="text-sm leading-5 text-indigo-500">Adding...</h4>
        </span>
        <Icon name="FastForward" className="h-5 w-5 text-indigo-500" />
      </div>
    </section>
  );
}
