import { cloneDeep } from 'lodash';

import { DatabaseSchemaFragment, SqlRunnerSchemaV2Fragment } from '../generated/graphql';
import { SchemaFetchVars } from './custom-types.util';
import {
  isRunnerCatalog,
  isRunnerColumn,
  isRunnerSchema,
  isRunnerTable,
  isStr
} from './predicates.util';
import { SchemaFlatTypes, SchemaNestedTypes } from './union-types.util';

const BORDER_HEIGHT = 17; // includes padding and 1px border
const BIG_ROW = 52;
const SMALL_ROW = 32;

export function getItemHeight(
  item: SchemaFlatTypes | undefined | null,
  index: number,
  supportsDatabaseSelection?: boolean
) {
  if (!item) {
    return 0;
  }
  if (isRunnerCatalog(item)) {
    if (index === 0) {
      return SMALL_ROW;
    }
    return SMALL_ROW + BORDER_HEIGHT;
  }
  if (isRunnerSchema(item)) {
    if (supportsDatabaseSelection || index === 0) {
      return BIG_ROW;
    }
    return BIG_ROW + BORDER_HEIGHT;
  }
  return SMALL_ROW;
}

export function matchedName(item: SchemaNestedTypes, query: string) {
  return !!item.name?.toLocaleLowerCase().includes(query.toLocaleLowerCase());
}

export function filterBySearch<T extends SchemaNestedTypes>(data: T[], query: string) {
  return data.reduce((acc: T[], v: T) => {
    const item = { ...v };
    if (isRunnerCatalog(item)) {
      if (matchedName(item, query)) {
        acc.push(item);
      } else if (item.schemas) {
        const inner = filterBySearch(item.schemas, query);
        if (inner.length > 0) {
          acc.push({ ...item, schemas: inner });
        }
      }
    }
    if (isRunnerSchema(item)) {
      if (matchedName(item, query)) {
        acc.push(item);
      } else if (item.tables) {
        const inner = filterBySearch(item.tables, query);
        if (inner.length > 0) {
          acc.push({ ...item, tables: inner });
        }
      }
    }
    if (isRunnerTable(item)) {
      if (matchedName(item, query)) {
        acc.push(item);
      } else if (item.columns) {
        const inner = item.columns.filter(item => matchedName(item, query));
        if (inner.length > 0) {
          acc.push({ ...item, columns: inner });
        }
      }
    }
    return acc;
  }, []);
}

export function filterByExpanded<T extends SchemaNestedTypes>(
  data: T[],
  expanded: Record<string, boolean>,
  supportsDatabaseSelection?: boolean
) {
  return data.reduce((acc: T[], v) => {
    const item = { ...v };
    const isExpanded = isStr(item.name) ? expanded[item.name] : null;

    if (isRunnerCatalog(item)) {
      acc.push({
        ...item,
        schemas: filterByExpanded(item.schemas, expanded, supportsDatabaseSelection)
      });
    }
    if (isRunnerSchema(item)) {
      acc.push({
        ...item,
        tables:
          !supportsDatabaseSelection || (supportsDatabaseSelection && isExpanded !== false)
            ? filterByExpanded(item.tables, expanded, supportsDatabaseSelection)
            : []
      });
    }
    if (isRunnerTable(item)) {
      acc.push({ ...item, columns: isExpanded !== false ? item.columns : [] });
    }
    return acc;
  }, []);
}

export function recurseSchema(item: SchemaNestedTypes, arr: SchemaFlatTypes[] = []) {
  if (isRunnerCatalog(item)) {
    const { schemas: innerItems, ...rest } = item;
    arr.push(rest);
    if (innerItems) {
      for (const innerItem of innerItems) {
        recurseSchema(innerItem, arr);
      }
    }
  }
  if (isRunnerSchema(item)) {
    const { tables: innerItems, ...rest } = item;
    arr.push(rest);
    if (innerItems) {
      for (const innerItem of innerItems) {
        recurseSchema(innerItem, arr);
      }
    }
  }
  if (isRunnerTable(item)) {
    arr.push(item);
    if (item.columns && item.columns.length > 0) {
      arr.push(...item.columns);
    }
  }
  return arr;
}

export function flattenSchema(
  data: Array<SqlRunnerSchemaV2Fragment | DatabaseSchemaFragment> | undefined | null
) {
  if (!data) {
    return [];
  }
  return data.reduce(
    (acc: SchemaFlatTypes[], item: SqlRunnerSchemaV2Fragment | DatabaseSchemaFragment) => {
      return acc.concat(recurseSchema(item));
    },
    []
  );
}

export function handleHierarchy(
  data: SqlRunnerSchemaV2Fragment[] | undefined | null
): Array<SqlRunnerSchemaV2Fragment | DatabaseSchemaFragment> {
  if (!data || !Array.isArray(data)) {
    return [];
  }
  // check if the top layer (aka Catalog) has a `null` name
  if (data.length === 1 && data[0]?.name == null) {
    return data[0]?.schemas;
  }
  return data;
}

export function getNestedData(data: SchemaNestedTypes, table: boolean): SchemaNestedTypes[] {
  if (isRunnerCatalog(data)) {
    if (data.schemas.length === 1) {
      return getNestedData(data.schemas[0], table);
    }
    return data.schemas;
  }
  if (isRunnerSchema(data)) {
    if (table) {
      return getNestedData(data.tables[0], table);
    }
    return data.tables;
  }
  if (isRunnerTable(data)) {
    return data.columns || [];
  }
  return [];
}

export function mergeSchemaCacheData(
  prev: SqlRunnerSchemaV2Fragment[],
  newData: SqlRunnerSchemaV2Fragment[],
  vars: SchemaFetchVars
) {
  const data = cloneDeep(prev);
  const { database, table } = vars;

  const unwrappedData = getNestedData(newData[0], !!table);

  const foundCatalog =
    data.length === 1
      ? data[0]
      : data.find(catalog => catalog.name == null || database?.includes(catalog.name));

  const foundSchema =
    foundCatalog?.schemas.length === 1
      ? foundCatalog?.schemas[0]
      : foundCatalog?.schemas.find(schema => schema.name === database);

  if (foundSchema && !table && unwrappedData.every(isRunnerTable)) {
    foundSchema.tables = unwrappedData;
    return data;
  }

  const foundTable =
    foundSchema?.tables.length === 1
      ? foundSchema?.tables[0]
      : foundSchema?.tables.find(item => item.label === table || item.name === table);

  if (foundTable && unwrappedData.every(isRunnerColumn)) {
    foundTable.columns = unwrappedData;
    return data;
  }

  return data;
}
