import { LinkButton } from '~/components';
import { SyncFragment, SyncStatusFragment } from '~/generated/graphql';
import { useToggle } from '~/hooks';
import { recordDialogHeading } from '~/utils';
import { StatusLabel } from '.';
import { SyncExecutionRecordsPreview } from '../sync-execution-records-preview';

interface Props {
  sync: SyncFragment;
  syncStatus: SyncStatusFragment;
}

export function SyncErrorCount({ sync, syncStatus }: Props) {
  const [showDialog, toggleDialog] = useToggle();
  if (
    !syncStatus?.execution ||
    typeof syncStatus.execution.errorCount !== 'number' ||
    !syncStatus.execution.errors?.hasData
  ) {
    return null;
  }
  return (
    <StatusLabel text="Errors">
      <p>
        <LinkButton onClick={toggleDialog}>
          {syncStatus.execution.errorCount?.toLocaleString()}
        </LinkButton>
      </p>
      {showDialog && (
        <SyncExecutionRecordsPreview
          heading={recordDialogHeading('errors')}
          executionId={syncStatus.execution?.id}
          recordType="errors"
          targetConnectionTypeId={sync.targetConnection?.type.id}
          targetConnectionName={sync.targetConnection?.name}
          targetObjectName={sync.targetObject?.name}
          handleDismiss={toggleDialog}
        />
      )}
    </StatusLabel>
  );
}
