import { Reference, useMutation } from '@apollo/client';
import cx from 'clsx';

import { Button, Icon, TableWrap } from '~/components';
import { Dialog } from '~/components/v3';
import { DeleteRoleDocument } from '~/generated/graphql';
import { useBannerDispatch } from '~/hooks';
import { RoleWithUser, hasItems, plural } from '~/utils';

const wrapperStyles = 'mb-3';

interface Props {
  role: RoleWithUser;
  toggleDialog: () => void;
}

export function DeleteRoleDialog({ role, toggleDialog }: Props) {
  const dispatchBanner = useBannerDispatch();

  const [deleteRole, { loading: deleteRoleLoading }] = useMutation(DeleteRoleDocument, {
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
      toggleDialog();
    },
    update: (cache, { data }) => {
      if (!data || !data.deleteRole) {
        return;
      }

      cache.modify({
        fields: {
          users: (existing: Reference[], { readField }) => {
            const filtered = existing.filter(ref => readField('id', ref) !== role.id);
            return filtered;
          }
        }
      });
      cache.evict({ id: cache.identify({ __typename: 'PermissionRole', id: role.id }) });
      cache.gc();
      toggleDialog();
    }
  });

  const handleDelete = () => {
    dispatchBanner({ type: 'hide' });
    void deleteRole({ variables: { id: role.id } });
  };

  return (
    <Dialog
      show
      size="lg"
      onDismiss={toggleDialog}
      heading="Delete role?"
      actions={
        <>
          <Button onClick={toggleDialog}>Cancel</Button>
          <Button theme="danger" onClick={handleDelete} disabled={deleteRoleLoading}>
            Delete role
          </Button>
        </>
      }
    >
      <div className="space-y-4">
        <div className="space-y-1">
          <p className="text-sm font-medium text-gray-800">{role.name}</p>
          <p className="text-sm text-gray-800">
            This role will be removed
            {hasItems(role.users) && (
              <>
                {' from '}
                {cx(role.users.length, plural('user', role.users.length > 1))}
              </>
            )}
            {'.'}
          </p>
        </div>
        {hasItems(role.users) && (
          <TableWrap className="max-h-52 overflow-auto">
            <table className="min-w-full">
              <tbody>
                {role.users.map(user => (
                  <tr key={user.id} className="align-top even:bg-gray-50">
                    <td className="flex items-center py-2 px-4">
                      <Icon name="UserCircle" className="mr-2 h-4 w-4 text-gray-500" />
                      <p>{user.email}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableWrap>
        )}
      </div>
    </Dialog>
  );
}
