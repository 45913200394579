import { cloneDeep, differenceBy } from 'lodash';
import {
  FieldType,
  ModelFieldFragment,
  SyncMode,
  TargetFieldFragment,
  TargetObjectWithFieldsFragment,
  TargetProperties
} from '../generated/graphql';
import { LocalIdentityMapping, SyncConfigFormValues } from './custom-types.util';
import { hasItems, isModelMapping, isTargetField } from './predicates.util';
import { supportsSyncMode } from './sync-config.util';
import { Mapping } from './union-types.util';

export function removeExistingMappings(
  modelFields: ModelFieldFragment[],
  fieldMappings: Mapping[]
) {
  if (modelFields.length === 0) {
    return [];
  }
  if (fieldMappings.length === 0) {
    return modelFields;
  }
  const mappedFieldIds = fieldMappings.filter(isModelMapping).map(m => m.model?.id);

  return modelFields.filter(item => !mappedFieldIds.includes(item.id));
}

export function isUnreachableMapping(mapping: Mapping | null, fieldsetIds: string[] | null) {
  let unreachable = false;
  if (!mapping || !fieldsetIds || fieldsetIds.length === 0) {
    return unreachable;
  }
  if (hasItems(fieldsetIds) && isModelMapping(mapping)) {
    const fieldsetId = mapping.model?.fieldset.id;
    const reachable = fieldsetId ? fieldsetIds.includes(fieldsetId) : false;
    unreachable = !!mapping.model && !reachable;
  }
  return unreachable;
}

export function getRemainingTargetFields({
  targetFields,
  identity,
  mappings,
  selectedField
}: {
  targetFields: TargetFieldFragment[];
  identity: LocalIdentityMapping | undefined;
  mappings: Mapping[];
  selectedField?: TargetFieldFragment | null;
}): TargetFieldFragment[] {
  const mappedTargetFields = mappings
    ?.map(mapping => mapping?.target)
    // we don't consider Object fields mapped because you can sync multiple
    // keys to that field
    .filter(targetField => targetField && targetField.type !== FieldType.Object);
  if (identity?.target) {
    mappedTargetFields.push(identity.target);
  }
  const remainingFields = differenceBy(targetFields, mappedTargetFields, 'id');
  // Always include a pre-selected field in the dropdown options, assuming it's still a valid option (it might not be!)
  if (selectedField && targetFields.find(f => f.id === selectedField.id)) {
    remainingFields.push(selectedField);
  }
  remainingFields.sort((a, b) =>
    a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase())
  );
  return remainingFields;
}

export function getMatchingTargetField({
  modelField,
  targetFields,
  mappings,
  identity,
  isIdentity
}: {
  modelField?: ModelFieldFragment | null;
  targetFields: TargetFieldFragment[];
  mappings: Mapping[];
  identity: LocalIdentityMapping;
  isIdentity?: boolean;
}): TargetFieldFragment | null {
  const targets = getRemainingTargetFields({ targetFields, identity, mappings }).filter(
    targetField => (isIdentity ? targetField.supportsIdentity : true)
  );
  if (!modelField) {
    return null;
  }
  return (
    targets.find(targetField => {
      const targetId = targetField.id.toLocaleLowerCase();
      const targetName = targetField.name.toLocaleLowerCase();
      const fieldLabel = modelField.label.toLocaleLowerCase();
      const fieldName = modelField.sourceName.toLocaleLowerCase();
      return (
        fieldLabel === targetName ||
        fieldLabel === targetId ||
        fieldName === targetName ||
        fieldName === targetId // check every combination of label,sourceName with id,name
      );
    }) || null
  );
}

export function getTargetOptions(
  fields: TargetFieldFragment[],
  identity: LocalIdentityMapping,
  mappings: Mapping[],
  mode: SyncMode | undefined | null
) {
  const opts = fields.filter(
    field =>
      // filter in field bags to have multiples mapped to it
      field.isFieldBag ||
      // filter out identity mapping
      (field.id !== identity?.target?.id &&
        // filter out mapped options
        !mappings?.find(m => m?.target?.id === field.id) &&
        // filter in if it supports sync mode
        supportsSyncMode(field, mode))
  );
  const identityOpts = fields.filter(
    field =>
      // filter out mapped options
      !mappings?.find(m => m?.target?.id === field.id) &&
      // filter in if it supports identity
      field.supportsIdentity &&
      !field.isFieldBag
  );
  return [opts, identityOpts];
}

export function handleMappingsUpdates(
  config: SyncConfigFormValues,
  targetFields: TargetFieldFragment[]
) {
  const cfg = cloneDeep(config);

  const isSourceOnly =
    cfg.targetObject?.properties?.optionalTargetMappings &&
    !cfg.targetObject?.properties?.supportsFieldCreation &&
    !cfg.targetObject?.properties?.supportsFieldTypeSelection;

  const targetMode = cfg.targetObject?.modes.find(m => m.mode === cfg.mode);

  if (!targetMode) {
    cfg.mode = null;
  }

  if (
    cfg.targetObject?.properties?.optionalTargetMappings &&
    (cfg.targetObject?.properties?.supportsFieldCreation ||
      cfg.targetObject?.properties?.supportsFieldTypeSelection)
  ) {
    if (cfg.identity?.model) {
      if (targetMode?.requiresIdentity) {
        cfg.identity.target = {
          id: cfg.identity.model.label,
          name: cfg.identity.model.label,
          type: cfg.identity.model.type
        } as TargetFieldFragment;
        cfg.identity.newField = true;
      } else {
        cfg.identity = { model: null, target: null, newField: false };
      }
    }
    if (hasItems(cfg.mappings)) {
      for (let i = 0; i < cfg.mappings.length; i++) {
        const mapping = cfg.mappings[i];
        if (isModelMapping(mapping) && mapping.model?.label) {
          mapping.target = {
            id: mapping.model.label,
            name: mapping.model.label,
            type: mapping.model?.type
          } as TargetFieldFragment;
          mapping.newField = true;
        }
      }
    }
    return cfg;
  }

  // these flags will hide the target selects so, switching to them,
  // we need to map all mappings behind the scenes since user can't
  if (isSourceOnly) {
    if (hasItems(cfg.mappings)) {
      for (let i = 0; i < cfg.mappings.length; i++) {
        const mapping = cfg.mappings[i];
        if (isModelMapping(mapping) && !mapping?.target) {
          mapping.target = targetFields[0];
        }
      }
    }
    return cfg;
  }

  if (cfg.identity?.model || cfg.identity?.target) {
    if (targetMode?.requiresIdentity) {
      const found = targetFields.find(f => f.id === cfg.identity.target?.id);
      if (cfg.identity.newField || !found) {
        cfg.identity.target = null;
      } else {
        if (!cfg.identity.newField && found && found.name !== cfg.identity.target?.name) {
          cfg.identity.target = found;
        }
      }
      cfg.identity.newField = false;
    } else {
      cfg.identity = { model: null, target: null, newField: true };
    }
  }
  if (hasItems(cfg.mappings)) {
    const filtered = targetFields.filter(field => supportsSyncMode(field, cfg.mode));
    for (let i = 0; i < cfg.mappings.length; i++) {
      const mapping = cfg.mappings[i];
      const found = filtered.find(field => field.id === mapping.target?.id);
      if (
        (isModelMapping(mapping) && mapping.newField) ||
        (isTargetField(mapping.target) && !found)
      ) {
        mapping.target = null;
      } else {
        if (isModelMapping(mapping)) {
          if (!mapping.newField && found && found.name !== mapping.target?.name) {
            mapping.target = found;
          }
          mapping.newField = false;
        }
      }
    }
  }
  // target filters are based on target fields
  if (hasItems(cfg.targetFilters?.filters)) {
    if (targetMode?.supportsTargetFilters && cfg.targetObject?.properties?.supportsTargetFilters) {
      const newTargetFields = targetFields.filter(field =>
        hasItems(field.filterFunctions)
      ) as TargetFieldFragment[];
      for (let i = 0; i < cfg.targetFilters.filters.length; i++) {
        const filter = cfg.targetFilters.filters[i];
        const found = newTargetFields.find(field => field.id === filter.field?.id);
        if (isTargetField(filter.field) && !found) {
          filter.field = null;
        }
        if (isTargetField(filter.field) && found?.name !== filter.field?.name) {
          filter.field = found;
        }
      }
    } else {
      cfg.targetFilters = { filters: [], logic: '' };
    }
  }
  return cfg;
}

export const targetObjectIsSourceOnly = (p?: TargetProperties | null) =>
  p?.optionalTargetMappings && !p?.supportsFieldCreation && !p?.supportsFieldTypeSelection;

export const targetObjectIsCreatingTargets = (p?: TargetProperties | null) =>
  p?.optionalTargetMappings && (p.supportsFieldCreation || p.supportsFieldTypeSelection);

export const getMapping = ({
  targetObject,
  field,
  identity
}: {
  targetObject: TargetObjectWithFieldsFragment;
  field: ModelFieldFragment;
  identity: ModelFieldFragment;
}) => {
  const newField = targetObjectIsCreatingTargets(targetObject?.properties);
  const isSourceOnly = targetObjectIsSourceOnly(targetObject?.properties);

  // ie. for webhooks, there is a single field that all model fields should map to
  if (isSourceOnly) {
    return { model: field, target: targetObject?.fields[0], newField };
  }

  // Creating a new target, default name / value to the incoming field
  if (newField) {
    return {
      model: field,
      target: { id: field?.label, name: field?.label, type: field?.type },
      newField
    };
  }

  // Try to match up the name to an existing field
  return {
    model: field,
    target: getMatchingTargetField({
      modelField: field,
      mappings: [],
      identity: { model: identity },
      isIdentity: field.id === identity?.id,
      targetFields: targetObject?.fields ?? []
    }),
    newField
  };
};
