import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { cn } from '~/lib/utils';
import { Calendar } from './Calendar';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';

const toBeginOfDay = (date: Date) => {
  const modified = new Date(date.toISOString());
  modified.setHours(0, 0, 0);
  return modified;
};

const toEndOfDay = (date?: Date) => {
  if (date) {
    const modified = new Date(date.toISOString());
    modified.setHours(23, 59, 59, 999);
    return modified;
  }
};

export interface DateRangePickerProps {
  className?: string;
  numberOfMonths?: number;
  placeholder?: string;
  range?: DateRange;
  onSelect?(range: DateRange): unknown;
}

export function DateRangePicker(props: DateRangePickerProps) {
  const [range, setRange] = useState<DateRange>();

  useEffect(() => {
    setRange(props.range);
  }, [props.range, setRange]);

  const handleSelect = (selected?: DateRange) => {
    const next = selected
      ? {
          from: toBeginOfDay(selected.from),
          to: toEndOfDay(selected.to)
        }
      : undefined;
    setRange(next);
    props.onSelect && props.onSelect(next);
  };

  const getText = () => {
    if (range) {
      return [range.from, range.to]
        .filter(Boolean)
        .map(dt => dayjs(dt).format('MMM DD, YYYY'))
        .join(' — ');
    }
    if (props.placeholder) {
      return props.placeholder;
    }
    return null;
  };
  const defaultMonth = range ? range.from : new Date();

  return (
    <div className={cn('grid gap-2', props.className)}>
      <Popover>
        <PopoverTrigger
          asChild
          className={cn(
            `
              w-full justify-start rounded border-none bg-white py-2 px-3 text-left text-sm font-normal leading-none shadow-input ring-1 ring-gray-400
              focus:ring-gray-400
            `,
            !range && 'text-gray-400',
            'data-[state=open]:ring-2 data-[state=open]:ring-indigo-500'
          )}
        >
          <button>{getText()}</button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            defaultMonth={defaultMonth}
            initialFocus
            mode="range"
            numberOfMonths={props.numberOfMonths || 2}
            selected={range}
            onSelect={handleSelect}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
