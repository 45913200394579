import { makeVar } from '@apollo/client';

import { SqlRunnerSchemaV2Fragment } from '../generated/graphql';

export const apiVersionVar = makeVar<string | null>(null);

export const needsReloadVar = makeVar<boolean>(false);

export const resetStoreVar = makeVar<boolean>(false);

export const schemaCacheVar = makeVar<{ [connectionId: string]: SqlRunnerSchemaV2Fragment[] }>({});

export const expandedSchemasVar = makeVar<{ [connectionId: string]: Record<string, boolean> }>({});

export const expandedSearchedSchemasVar = makeVar<Record<string, boolean>>({});

export const refreshableSchemasVar = makeVar<{ [connectionId: string]: string[] }>({});
