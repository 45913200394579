import clsx from 'clsx';
import { ReactNode } from 'react';

export type DividerProps = {
  textAlign?: 'center' | 'left' | 'right';
  dense?: boolean;
  light?: boolean;
  orientation?: 'horizontal' | 'vertical';
  children?: ReactNode;
};

const Divider = ({
  textAlign = 'center',
  light = false,
  dense = false,
  orientation = 'horizontal',
  children
}: DividerProps) => {
  // VARS
  const isVert = orientation === 'vertical';

  // SX
  const ROOT_SX = clsx('relative flex w-full items-center', isVert && 'flex-col');
  const CHILD_SX = clsx(
    'flex-shrink text-gray-400',
    isVert ? (dense ? 'my-1' : 'my-2') : dense ? 'mx-1' : 'mx-2'
  );
  const DIVIDER_SX = clsx(
    light ? 'border-gray-200' : 'border-gray-300',
    isVert ? (dense ? 'border-r py-1' : 'border-r py-2') : dense ? 'border-t px-1' : 'border-t px-4'
  );
  const DIVIDER_L_SX = clsx(DIVIDER_SX, textAlign === 'left' ? 'shrink' : 'grow');
  const DIVIDER_R_SX = clsx(DIVIDER_SX, textAlign === 'right' ? 'shrink' : 'grow');
  return !children ? (
    <div className={ROOT_SX}>
      <div className={DIVIDER_L_SX} />
    </div>
  ) : (
    <div className={ROOT_SX}>
      <div className={DIVIDER_L_SX} />
      <span className={CHILD_SX}>{children}</span>
      <div className={DIVIDER_R_SX} />
    </div>
  );
};

export default Divider;
