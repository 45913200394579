import { ResourceType } from '../generated/graphql';
import { useAuth } from './auth-context';

export function useHasCreatePermission(permission: ResourceType | undefined) {
  const { user } = useAuth();
  // no user means we can't verify, and !permission means "ignore"
  if (!user || !permission) {
    return true;
  }
  const { createPermissions } = user;
  if (!createPermissions) {
    return false;
  }
  return createPermissions.includes(permission);
}
