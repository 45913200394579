import { useLazyQuery, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Card from '~/components/v2/display/Card';
import Select, { SelectOption } from '~/components/v2/inputs/Select';
import {
  ActivityLogConnectionDocument,
  ActivityLogFragment,
  ActivityLogConnectionQueryVariables,
  ActorType,
  UsersDocument,
  EditableFieldsetFragment
} from '~/generated/graphql';
import * as Sentry from '@sentry/react';
import { chain, groupBy, isFunction, isString } from 'lodash';

import { getUserDisplayName } from '~/utils/v2/user';
import ActivityLogTimeLineDivider from './ActivityLogTimeLineDivider';
import { ActivityLogItem } from './ActivityLogItem';
import React from 'react';
import clsx from 'clsx';
import { Resizable } from 're-resizable';
import { type Change, categories, activityTypes, filterRules } from './ActivityTypes';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Icon } from '../../../Icon';

type ActivityLogProps = {
  title?: React.ReactNode;
  objectId: string;
  fieldset?: EditableFieldsetFragment;
};

interface PaginatedQueryResult<TData> {
  edges?: Array<{
    cursor: string;
    node: TData;
  }> | null;
  pageInfo: {
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}
export interface PaginatedQueryParams {
  after?: string;
  before?: string;
}

const ActivityLog = ({ title, objectId, fieldset }: ActivityLogProps) => {
  const [getActivityLog, { fetchMore }] = useLazyQuery(ActivityLogConnectionDocument, {
    fetchPolicy: 'network-only'
  });
  const { data: users } = useQuery(UsersDocument);
  const [expanded, setExpanded] = useState<boolean>(false);

  const [filters, setFilters] = useState<
    Omit<ActivityLogConnectionQueryVariables, 'after' | 'before' | 'first' | 'last'>
  >({
    objectId,
    activityTypes: [],
    actorId: null,
    actorType: null,
    includeAssociated: true
  });

  const filtersOptions: { id: string; defaultValue: string; options: SelectOption[] }[] =
    useMemo(() => {
      const userOpts = users?.users
        ?.filter(u => u.status !== 'invited')
        .map(u => ({ label: getUserDisplayName(u), value: u.id }))
        .sort((a, b) => a.label.localeCompare(b.label));
      return [
        {
          id: 'dataType',
          defaultValue: '',
          options: [{ label: 'All activity', value: '' }].concat(
            Object.keys(categories)
              .filter(key => {
                const category = categories[key];
                return isFunction(category.shouldRender)
                  ? category.shouldRender({ fieldset })
                  : true;
              })
              .map(key => {
                const category = categories[key];
                return {
                  label: isString(category.label) ? category.label : category.label({ fieldset }),
                  value: key
                };
              })
          )
        },
        {
          id: 'actors',
          defaultValue: '',
          options: [{ label: 'All people', value: '' }].concat(userOpts)
        }
      ];
    }, [users, fieldset, objectId]);

  const handleFilterChange = (id: string, option: SelectOption) => {
    switch (id) {
      case 'dataType':
        setFilters(f => ({
          ...f,
          activityTypes:
            Object.keys(activityTypes).filter(
              key => activityTypes[key].category?.value === option?.value
            ) ?? []
        }));
        break;
      case 'actors':
        if (option.value === '') {
          setFilters(f => ({ ...f, actorId: null, actorType: null }));
        } else {
          setFilters(f => ({ ...f, actorId: option.value, actorType: ActorType.User }));
        }
        break;
    }
  };

  const scrollContainerRef = useRef<HTMLDivElement>();

  const { data, fetchPreviousPage, isFetching, refetch, isLoading, hasPreviousPage } =
    useInfiniteQuery<PaginatedQueryResult<ActivityLogFragment>>(
      ['table-data'],
      async ({ pageParam }) => {
        const { data } = await fetchMore({
          variables: {
            objectId,
            ...filters,
            before: pageParam,
            last: 20
          }
        });
        return data.activityLogConnection;
      },
      {
        getPreviousPageParam: prevGroup =>
          prevGroup.pageInfo.hasPreviousPage ? prevGroup.pageInfo.endCursor : undefined,
        keepPreviousData: true,
        refetchOnWindowFocus: false
      }
    );

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop - clientHeight < 300 && !isFetching) {
          fetchPreviousPage();
        }
      }
    },
    [fetchPreviousPage, isFetching]
  );

  useEffect(() => {
    fetchMoreOnBottomReached(scrollContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    objectId &&
      getActivityLog({
        variables: {
          objectId,
          ...filters,
          last: 20
        }
      });
  }, [filters]);

  useEffect(() => {
    refetch();
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [filters]);

  const changes: Change[] = useMemo(
    () =>
      chain(data?.pages.flatMap(page => page.edges.map(edge => edge.node) ?? []))
        .filter((log, _, logs) => {
          // Apply any filtering rules
          if (!filterRules.every(rule => rule({ log, logs: logs as ActivityLogFragment[] }))) {
            return false;
          }
          // Filter out logs with unimplemented activityTypes so they aren't rendered, and report to Sentry
          if (!activityTypes[log.activityType]) {
            Sentry.captureMessage(`Unimplemented activity type: ${log.activityType}`, 'warning');
            return false;
          }
          return true;
        })
        .uniqBy(log => log.id)
        // Populate labels using log context. Could this happen on backend?
        // In a future step, child logs will be grouped by this label
        .map(log => {
          const label = activityTypes[log.activityType].label;
          return { ...log, label: isString(label) ? label : label({ log, fieldset }) };
        })
        // Group logs by changeID, then children by label,
        // since multiple activityTypes can appear in a single change
        .groupBy(log => log.changeID)
        .mapValues<Change>(logs => {
          const { changeID, performedAt, performedBy } = logs[0];
          return {
            changeID,
            performedAt,
            performedBy,
            logs: groupBy(logs, log => log.label)
          };
        })
        .values()
        .sort((a, b) => b.performedAt.localeCompare(a.performedAt))
        .value(),
    [data]
  );

  return (
    <>
      <div className="w-full space-y-2">
        <div className="space-y-1">
          {title}
          <div
            className="flex flex-row items-center justify-start space-x-1 text-blue-500 hover:cursor-pointer hover:underline"
            onClick={() => setExpanded(e => !e)}
          >
            <div className="text-sm ">{expanded ? 'Show less' : 'Show more'}</div>
            <div className="h-5 w-5 rounded bg-gray-200">
              <Icon
                name="Disclosure"
                className={clsx('text-gray-500 transition', expanded && 'rotate-90')}
              />
            </div>
          </div>
        </div>
        {expanded && (
          <Resizable
            handleClasses={{
              top: 'pointer-events-none',
              bottom: 'pointer-events-none',
              right: 'pointer-events-none',
              left: 'pointer-events-none',
              topRight: 'pointer-events-none',
              bottomLeft: 'pointer-events-none',
              topLeft: 'pointer-events-none'
            }}
            enable={{
              top: false,
              right: false,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: true,
              bottomLeft: false,
              topLeft: false
            }}
            defaultSize={{ height: '300px', width: '100%' }}
            minHeight={300}
            maxHeight={600}
            minWidth={400}
            maxWidth="100%"
          >
            <Card
              headerDivider
              noContentPadding
              className="h-full"
              contentClasses="overflow-hidden"
              header={
                <div className="flex flex-row items-center justify-start space-x-3">
                  {filtersOptions.map(filter => {
                    return (
                      <Select
                        key={filter.id}
                        variant="filled"
                        inputWidth="w-36"
                        optionsWidth="w-72"
                        defaultValue={filter.options.find(o => o.value === filter.defaultValue)}
                        options={filter.options.map(o => ({
                          label: o?.label,
                          value: o?.value,
                          isTopOption: o?.value === ''
                        }))}
                        value={filter.options.find(
                          o =>
                            o.id === filters[filter.id as keyof ActivityLogConnectionQueryVariables]
                        )}
                        onChange={option => handleFilterChange(filter.id, option)}
                      />
                    );
                  })}
                </div>
              }
            >
              <div
                ref={scrollContainerRef}
                onScroll={e => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
                className="overflow-auto bg-white"
              >
                <div className="flex px-1 pl-6 pt-4">
                  <ol className="relative w-full border-l-2 border-blue-300 pl-2 pr-1">
                    {changes.map((change, idx) => {
                      const prevDay = dayjs(changes[idx - 1]?.performedAt).startOf('day');
                      const curDay = dayjs(changes[idx]?.performedAt).startOf('day');

                      return (
                        <Fragment key={change.changeID}>
                          {(idx === 0 || !dayjs(curDay).isSame(prevDay)) && (
                            <ActivityLogTimeLineDivider>
                              {dayjs(curDay).format('dddd DD MMMM YYYY')}
                            </ActivityLogTimeLineDivider>
                          )}
                          <ActivityLogItem change={change} />
                        </Fragment>
                      );
                    })}
                  </ol>
                </div>
                <div className="flex flex-row items-center justify-center">
                  {!isLoading && !hasPreviousPage && changes.length > 0 && (
                    <span className="pb-2 text-sm text-gray-500">No more events</span>
                  )}
                  {!isLoading && !hasPreviousPage && changes.length === 0 && (
                    <span className="pb-2 text-sm text-gray-500">No change events</span>
                  )}
                </div>
              </div>
              <Icon
                className="pointer-events-none absolute bottom-0.5 right-0.5 h-3 w-3 opacity-30"
                name="ResizeIndicator"
              />
            </Card>
          </Resizable>
        )}
      </div>
    </>
  );
};

export default ActivityLog;
