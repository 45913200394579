import { DAYS, daySelectable, minuteHourSelectable, minuteHourSelectables } from '~/utils';
import { type ScheduleProps } from './SchedulePicker';
import { MyCombobox } from '../form-components';
import { range } from 'lodash';

export function FrequencyWeekly({ schedule, onScheduleChange }: ScheduleProps) {
  return (
    <section>
      <div className="grid grid-cols-2 items-center">
        <label className="label col-span-full">On</label>
        <MyCombobox
          placeholder="Select day..."
          options={DAYS}
          value={daySelectable(schedule.dayOfWeek)}
          onChange={({ value }) => onScheduleChange({ ...schedule, dayOfWeek: value })}
        />
      </div>
      <div className="mt-3 grid grid-cols-[3fr,1.25rem,3fr,1fr] items-center">
        <label className="label col-span-full">At</label>
        <MyCombobox
          placeholder="Hour"
          options={minuteHourSelectables(range(0, 24).map(v => v.toString()))}
          value={minuteHourSelectable(schedule.hour)}
          maxMenuHeight={200}
          onChange={({ value }) => onScheduleChange({ ...schedule, hour: value })}
        />
        <span className="text-center text-sm font-semibold text-gray-600">:</span>
        <MyCombobox
          placeholder="Minute"
          options={minuteHourSelectables(range(0, 60).map(v => v.toString()))}
          value={minuteHourSelectable(schedule.minute)}
          maxMenuHeight={200}
          onChange={({ value }) => onScheduleChange({ ...schedule, minute: value })}
        />
        <p className="ml-2 text-sm text-gray-600">(UTC)</p>
      </div>
    </section>
  );
}
