import { cn } from '~/lib/utils';
import { Icon } from '../Icon';
import { Tooltip } from '../tooltip';
import { truncateMiddle } from '~/utils';

interface PillProps {
  icon: string;
  text: string;
  href?: string;
  tooltipContent?: React.ReactNode;
  truncateLength?: number;
}

export function Pill({ text, icon, href, tooltipContent, truncateLength }: PillProps) {
  if (!text) {
    return null;
  }
  const { truncated, shouldTruncate } = truncateMiddle(text, truncateLength);
  return (
    <Tooltip disabled={!tooltipContent && !shouldTruncate} content={tooltipContent ?? text}>
      <a
        className={cn(
          'group relative flex w-max items-center space-x-1.5 rounded-full border border-gray-200 bg-white py-1.75 px-3',
          href && 'cursor-pointer hover:ring-2 hover:ring-blue-500'
        )}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <Icon match={icon} className="h-5 w-5" />
        <p className="text-xs font-medium text-gray-800">{truncated}</p>
        {!!href && (
          <div className="invisible absolute -top-3 right-1 flex h-5 w-5 items-center justify-center rounded-full bg-white font-bold text-blue-500 shadow group-hover:visible">
            ↗
          </div>
        )}
      </a>
    </Tooltip>
  );
}
