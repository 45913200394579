import { FilterFragment } from '../generated/graphql';
import { isModelField, isTargetField } from './predicates.util';
import { sortSyncFilters } from './sync-config.util';
import { SyncFilterField } from './union-types.util';

export function isUnreachableFilter(target: FilterFragment, fields?: SyncFilterField[]) {
  if (!fields || fields.length === 0 || (isTargetField(target.field) && target.field.filterable)) {
    return false;
  }
  const isReachable = !!fields.find(field => field?.id === target.field?.id);
  if (isReachable) {
    return false;
  } else {
    return true;
  }
}

export function splitFilters(filters: FilterFragment[]) {
  const { modelFilters, targetFilters } = filters.reduce(
    (
      obj: { modelFilters: FilterFragment[]; targetFilters: FilterFragment[] },
      filter: FilterFragment
    ) => {
      if (isModelField(filter.field)) {
        obj.modelFilters.push(filter);
      } else if (isTargetField(filter.field)) {
        obj.targetFilters.push(filter);
      }
      return obj;
    },
    {
      modelFilters: [],
      targetFilters: []
    }
  );
  sortSyncFilters(modelFilters);
  sortSyncFilters(targetFilters);
  return { modelFilters, targetFilters };
}
