import { ErrorMessage } from '@hookform/error-message';
import * as React from 'react';
import { FieldErrors } from 'react-hook-form';

import { Icon } from '~/components';

interface Props {
  name?: string;
  errors?: FieldErrors;
}

export function InlineFormError({ name, errors }: Props) {
  if (!name || !errors || Object.keys(errors).length === 0) {
    return null;
  }
  return <ErrorMessage errors={errors} name={name} as={<ErrorMessageWrapper />} />;
}

function ErrorMessageWrapper({ children }: { children?: React.ReactNode }) {
  return (
    <p className="mt-1 flex items-center space-x-1" role="alert">
      <Icon name="DangerFilled" className="h-3.5 w-3.5 text-red-500" />
      <span className="text-xs font-medium text-red-500">{children}</span>
    </p>
  );
}
