'use client';

import { Root, Portal, Overlay, Content } from '@radix-ui/react-dialog';
import { tv } from 'tailwind-variants';
import { getClassNames, type VariantProps } from '~/lib/utils';

const dialog = tv({
  slots: {
    wrapper: 'fixed inset-0 z-[99] flex justify-center',
    overlay: 'fixed inset-0 z-50 bg-gray-900/75',
    content: `
      fixed
      z-50 m-[10vh] w-full overflow-x-hidden rounded-md border bg-background
    `,
    header: `
      relative flex items-center justify-between rounded-t-md
      border-b border-gray-300 bg-gray-50 p-6
    `,
    body: 'p-6',
    footer: `
      flex items-center justify-end space-x-3 rounded-b-md
      border-t border-gray-300 bg-gray-50 p-6
    `
  },
  variants: {
    size: {
      xs: { content: 'max-w-md' },
      sm: { content: 'max-w-lg' },
      md: { content: 'max-w-xl' },
      lg: { content: 'max-w-2xl' },
      xl: { content: 'max-w-4xl' }
    }
  },
  defaultVariants: {
    size: 'md'
  }
});

export interface DialogProps extends VariantProps<typeof dialog> {
  heading?: string;
  headingLogo?: React.ReactNode;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  onDismiss?: () => void;
  show?: boolean;
  initialFocusRef?: React.RefObject<HTMLElement>;
}

const Dialog = ({
  show,
  heading,
  headingLogo,
  actions,
  children,
  onDismiss,
  initialFocusRef,
  ...rest
}: DialogProps) => {
  const { wrapper, overlay, content, header, body, footer } = getClassNames(dialog, rest);

  const onOpenChange = (open: boolean) => {
    if (!open) {
      onDismiss?.();
    }
  };

  const onOpenAutoFocus = (e: Event) => {
    if (initialFocusRef) {
      e.preventDefault();
      initialFocusRef?.current?.focus();
    }
  };

  const stopEvents = e => {
    e.stopPropagation();
  };

  return (
    <Root open={show} onOpenChange={onOpenChange} modal={true}>
      <Portal>
        <div className={wrapper} onMouseDown={stopEvents} onTouchStart={stopEvents}>
          <Overlay className={overlay} />
          <Content className={content} onOpenAutoFocus={onOpenAutoFocus}>
            {heading && (
              <div className={header}>
                <h2 className={'text-base font-medium'}>{heading}</h2>
                {headingLogo}
              </div>
            )}
            <div className={body}>{children}</div>
            {actions && <div className={footer}>{actions}</div>}
          </Content>
        </div>
      </Portal>
    </Root>
  );
};

Dialog.displayName = 'Dialog';

export { Dialog };
