// EXT
import clsx from 'clsx';

// INT
import { COLOR, SIZE, VARIANT } from '~/components/v2/configs/SX';

// Todo - add comments
export const ChipColor = {
  [COLOR.DEFAULT]: 'bg-gray-200    text-gray-600    outline-gray-200', // v1: colorhover
  [COLOR.PRIMARY]: 'bg-indigo-100  text-indigo-700  outline-indigo-100', // v1: hilite
  [COLOR.SECONDARY]: 'bg-gray-700    text-gray-300    outline-gray-700', // v1: darkdefault
  [COLOR.SUCCESS]: 'bg-green-500   text-white       outline-green-500', // v1: success
  [COLOR.INFO]: 'bg-blue-500    text-white       outline-blue-500', // v1: info
  [COLOR.WARNING]: 'bg-orange-500  text-white       outline-orange-500', // v1: warning
  [COLOR.ERROR]: 'bg-red-500     text-white       outline-red-500' // v1: danger
};
// Todo - add comments
export const ChipColorHover = {
  [COLOR.DEFAULT]: 'hover:bg-indigo-200  hover:text-indigo-600', // v1: colorhover
  [COLOR.PRIMARY]: 'hover:bg-indigo-300  hover:text-indigo-700', // v1: hilite
  [COLOR.SECONDARY]: 'hover:bg-gray-400    hover:text-gray-200', // v1: darkdefault
  [COLOR.SUCCESS]: 'hover:bg-green-600   hover:text-white', // v1: success
  [COLOR.INFO]: 'hover:bg-blue-600    hover:text-white', // v1: info
  [COLOR.WARNING]: 'hover:bg-orange-600  hover:text-white', // v1: warning
  [COLOR.ERROR]: 'hover:bg-red-600     hover:text-white' // v1: danger
};

// Todo - add comments
export const ChipColorHighlight = {
  [COLOR.DEFAULT]: 'bg-gray-50     text-gray-600    outline-gray-50', // v1: colorhover
  [COLOR.PRIMARY]: 'bg-indigo-50   text-indigo-700  outline-indigo-50', // v1: hilite
  [COLOR.SECONDARY]: 'bg-gray-300    text-gray-700    outline-gray-300', // v1: darkdefault
  [COLOR.SUCCESS]: 'bg-green-100   text-green-500   outline-green-100', // v1: success
  [COLOR.INFO]: 'bg-blue-100    text-blue-500    outline-blue-100', // v1: info
  [COLOR.WARNING]: 'bg-orange-100  text-orange-500  outline-orange-100', // v1: warning
  [COLOR.ERROR]: 'bg-red-100     text-red-500     outline-red-100' // v1: danger
};

export const ChipColorHighlightHover = {
  [COLOR.DEFAULT]: 'hover:bg-indigo-200  hover:text-indigo-600', // v1: colorhover
  [COLOR.PRIMARY]: 'hover:bg-indigo-300  hover:text-indigo-700', // v1: hilite
  [COLOR.SECONDARY]: 'hover:bg-gray-400    hover:text-gray-200', // v1: darkdefault
  [COLOR.SUCCESS]: 'hover:bg-green-200   hover:text-green-700', // v1: success
  [COLOR.INFO]: 'hover:bg-blue-200    hover:text-blue-700', // v1: info
  [COLOR.WARNING]: 'hover:bg-orange-200  hover:text-orange-700', // v1: warning
  [COLOR.ERROR]: 'hover:bg-red-200     hover:text-red-700' // v1: danger
};

// Todo - add comments
export const ChipVariant = {
  [VARIANT.OUTLINED]: 'outline outline-2 bg-transparent text-inherit',
  [VARIANT.FILLED]: ''
};

// Todo - add comments
export const ChipSize = {
  [SIZE.SMALL]: 'px-1 py-0.5 text-xs',
  [SIZE.MEDIUM]: 'px-1 py-0.5 text-base',
  [SIZE.LARGE]: 'px-1 py-0.5 text-lg'
};

// Todo - add comments
export type ChipProps = {
  // Enums
  color?: keyof typeof ChipColor;
  variant?: keyof typeof ChipVariant;
  size?: keyof typeof ChipSize;
  // Sockets
  children?: React.ReactNode;
  // F(x)
  onClick?: (e?: React.MouseEvent<HTMLSpanElement>) => void;
  // Booleans
  disabled?: boolean;
  hidden?: boolean;
  truncate?: boolean;
  bold?: boolean;
  enableHighlight?: boolean;
  enableHover?: boolean;
  enablePointer?: boolean;
};

// Todo - add comments
const Chip = ({
  //  Enums
  color = COLOR.PRIMARY,
  variant = VARIANT.FILLED,
  size = SIZE.SMALL,
  // Sockets
  children = null,
  // F(x)
  onClick = undefined,
  // Booleans
  disabled = false,
  hidden = false,
  truncate = false,
  bold = false,
  enableHighlight = false,
  enableHover = false,
  enablePointer = false
}: ChipProps) => {
  // SX
  const ROOT_SX = clsx(
    'inline-flex items-center rounded px-1 py-0.5 text-xs',
    ChipColor[color],
    ChipSize[size],
    ChipVariant[variant],
    truncate && 'truncate',
    bold && 'font-medium',
    enableHighlight && ChipColorHighlight[color],
    enableHover && (enableHighlight ? ChipColorHighlightHover[color] : ChipColorHover[color]),
    enablePointer ? 'cursor-pointer' : 'cursor-default',
    disabled && 'pointer-events-none',
    hidden && 'invisible'
  );

  // F(x)
  // Todo - add comments
  const handleOnClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    onClick?.(e);
  };

  return (
    <span className={ROOT_SX} onClick={handleOnClick}>
      {children}
    </span>
  );
};

export default Chip;
