import PageLayout from '~/components/v2/layout/PageLayout';
import { Icon } from '~/components';

export type Route404Props = null;

export default function Route404() {
  return (
    <PageLayout>
      <div className="mx-auto p-8" />
      <div className="max-h-48 w-full max-w-lg space-y-2 px-8 pt-6 pb-7">
        <Icon name="DangerFilled" className="h-8 w-8 text-orange-500" />
        <p className="text-sm font-medium uppercase text-gray-800">ERROR 404 – Page not found</p>
      </div>
    </PageLayout>
  );
}
