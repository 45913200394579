import LoadingDots from '~/components/v2/feedback/LoadingDots';

const PageLoader = () => {
  return (
    <div className="flex grow items-center justify-center">
      <LoadingDots />
    </div>
  );
};

export default PageLoader;
