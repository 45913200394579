import { Transition } from '@headlessui/react';
import { uuid4 } from '@sentry/utils';
import clsx from 'clsx';
import { ReactNode, useMemo } from 'react';

export enum ANIMATION_VARIANT {
  FADE = 'FADE',
  FLYUP = 'FLYUP',
  FLYDOWN = 'FLYDOWN'
}
export type ANIMATION_VARIANT_TYPE = ANIMATION_VARIANT | keyof typeof ANIMATION_VARIANT;
//  Record<ANIMATION_VARIANT, {start: string; end: string}>
export const AnimationSX = {
  [ANIMATION_VARIANT.FADE]: {
    start: 'opacity-100',
    end: 'opacity-0'
  },
  [ANIMATION_VARIANT.FLYDOWN]: {
    start: '-translate-y-4',
    end: 'translate-y-0'
  },
  [ANIMATION_VARIANT.FLYUP]: {
    start: 'translate-y-0',
    end: '-translate-y-1'
  }
};

export type PopoverProps = {
  id?: string;
  show?: boolean;
  x?: number;
  y?: number;
  // liveDuration?: number
  enterDuration?: number;
  leaveDuration?: number;
  enterAnimation?: ANIMATION_VARIANT_TYPE;
  leaveAnimation?: ANIMATION_VARIANT_TYPE;
  preventFocus?: boolean;
  children?: ReactNode;
};
// TODO: Fix Animations
export const Popover = ({
  id = uuid4(),
  show = false,
  x = 0,
  y = 0,
  // liveDuration = 1000,
  enterDuration = 300,
  leaveDuration = 300,
  enterAnimation = ANIMATION_VARIANT.FADE,
  leaveAnimation = ANIMATION_VARIANT.FADE,
  preventFocus = false,
  children = null
}: PopoverProps) => {
  const enterFX = useMemo(() => AnimationSX[enterAnimation], [enterAnimation]);
  const leaveFX = useMemo(() => AnimationSX[leaveAnimation], [leaveAnimation]);

  const PREVENT_FOCUS = 'pointer-events-none cursor-none';
  const ROOT_SX = clsx('absolute z-100 translate-x-[50%]', preventFocus && PREVENT_FOCUS);
  const ENTER_SX = clsx('transition-transform ease-out', `duration-[${enterDuration}ms]`);
  const ENTER_FROM_SX = clsx(enterFX?.start);
  const ENTER_TO_SX = clsx(enterFX?.end);
  const LEAVE_SX = clsx('transition-opacity ease-out', `duration-[${leaveDuration}ms]`);
  const LEAVE_FROM_SX = clsx(leaveFX?.start, enterFX?.end);
  const LEAVE_TO_SX = clsx(leaveFX?.end, enterFX?.end);

  const HTML_SX = { bottom: y, right: x };

  return (
    <Transition
      id={id?.toString()}
      appear
      show={show}
      style={HTML_SX}
      className={ROOT_SX}
      enter={ENTER_SX}
      enterFrom={ENTER_FROM_SX}
      enterTo={ENTER_TO_SX}
      leave={LEAVE_SX}
      leaveFrom={LEAVE_FROM_SX}
      leaveTo={LEAVE_TO_SX}
    >
      {children}
    </Transition>
  );
};
