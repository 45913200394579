import clsx from 'clsx';
import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import {
  FieldDetail,
  FilterValueElements,
  LinkButton,
  MyCombobox,
  MyInput,
  ParamButton,
  Tooltip
} from '~/components';
import FieldMappingBottomNav from '~/components/v2/experimental/FieldMappingBottomNav';
import FieldMappingButton from '~/components/v2/experimental/FieldMappingButton';
import { FIELD_MAPPING_TYPE } from '~/components/v2/experimental/StageMappings';
import PageLayout from '~/components/v2/layout/PageLayout';
import { ModelFieldFragment, SyncMode, TargetFieldFragment } from '~/generated/graphql';
import {
  capsFirst,
  getLetters,
  hasItems,
  isModelField,
  isTargetField,
  isUnreachableFilter,
  LocalConnection,
  Mapping,
  setLetterIndex,
  SyncConfigFormValues,
  SyncFilterField,
  toSelectables,
  truthyBoolean
} from '~/utils';
import { Icon } from '../../Icon';
import { ModelField, useFilterFunctions, useModelFields } from '~/hooks';

export enum FILTER_TYPE {
  MODEL_FILTER = 'modelFilters',
  TARGET_FILTER = 'targetFilters'
}
export type StageMappingsFiltersProps = {
  heading: string;
  showFieldDetails?: boolean;
  mappings?: Mapping[];
  fields: SyncFilterField[];
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  showBottomNav: boolean;
  setShowBottomNav: React.Dispatch<React.SetStateAction<boolean>>;
  bottomNavConfig: { config: FIELD_MAPPING_TYPE; mappingIndex: number };
  setBottomNavConfig: React.Dispatch<
    React.SetStateAction<{ config: FIELD_MAPPING_TYPE; mappingIndex: number }>
  >;
  buttonRefs?: MutableRefObject<Record<string | number, HTMLButtonElement | null>>;
  // F(x)
  focusOnMappingButton?: (id?: string) => void;
};

const StageMappingsFilters = ({
  heading,
  showFieldDetails,
  mappings,
  fields,
  setIsDirty,
  showBottomNav,
  setShowBottomNav,
  bottomNavConfig,
  setBottomNavConfig,
  buttonRefs,
  focusOnMappingButton
}: StageMappingsFiltersProps) => {
  // vars
  const filterType = heading === 'model' ? FILTER_TYPE.MODEL_FILTER : FILTER_TYPE.TARGET_FILTER;
  const bottomNavType =
    heading === 'model' ? FIELD_MAPPING_TYPE.FILTER_MODEL : FIELD_MAPPING_TYPE.FILTER_TARGET;
  // hooks
  const { register, control, setValue, getValues } = useFormContext<SyncConfigFormValues>();
  const logic = useWatch({ control, name: `${filterType}.logic` });
  const filters = useWatch({ control, name: `${filterType}.filters` });
  const targetConnection = getValues('targetConnection');
  const targetObject = getValues('targetObject');
  const isNotSyncModeUpdate = getValues('mode') !== SyncMode.Update;
  const {
    fields: rhfFields,
    append,
    remove,
    replace
  } = useFieldArray({ control, name: `${filterType}.filters` });
  const { modelFields } = useModelFields();
  const { filterFunctionsByType, getFilterFunctions: _getFilterFunctions } = useFilterFunctions();
  const getFilterFunctions = useCallback(
    (field: ModelFieldFragment | TargetFieldFragment) =>
      isModelField(field) ? _getFilterFunctions(field?.type) ?? [] : field?.filterFunctions ?? [],
    [filterFunctionsByType]
  );

  // state
  const [autoSelectedModelId, setAutoSelectedModelId] = useState<string>('');
  const [selectedFieldIds, setSelectedFieldIds] = useState<string[]>([]);
  const [showLogic, setLogicVisibility] = useState(hasItems(filters) && logic !== '');
  const [letterIdx, setLetterIdx] = useState(logic ? setLetterIndex(filters) : 0);
  const hideFilters = fields.length === 0 && (!filters || filters.length === 0);
  // f(x)
  const handleAdd = useCallback(() => {
    setIsDirty(true);
    append({
      field: null,
      function: null,
      value: null,
      label: showLogic ? getLetters(letterIdx) : ''
    });
    focusOnMappingButton?.(`${bottomNavConfig.mappingIndex + 1}${bottomNavType}`);
    if (showLogic) {
      setValue(`${filterType}.logic`, `${logic} AND ${getLetters(letterIdx)}`);
      setLetterIdx(prev => prev + 1);
    }
  }, [
    append,
    bottomNavConfig.mappingIndex,
    filterType,
    focusOnMappingButton,
    letterIdx,
    logic,
    setIsDirty,
    setValue,
    showLogic
  ]);

  const handleDelete = useCallback(
    (index: number) => {
      if (filters.length === 2) {
        setValue(`${filterType}.logic`, '');
        const unlabeled = filters.map(filter => ({ ...filter, label: '' }));
        replace(unlabeled);
        setLetterIdx(0);
        setLogicVisibility(false);
      }
      setIsDirty(true);
      remove(index);
    },
    [filterType, filters, remove, replace, setIsDirty, setValue]
  );
  const toggleLogic = useCallback(() => {
    if (!showLogic) {
      let modelCount = 0;
      const str = filters
        .map(() => {
          const newLetter = getLetters(modelCount);
          modelCount++;
          return newLetter;
        })
        .filter(truthyBoolean);
      setLetterIdx(modelCount);
      const logicStr = str.join(' AND ');
      setValue(`${filterType}.logic`, logicStr);
      const labeled = filters.map((filter, i) => ({ ...filter, label: getLetters(i) }));
      replace(labeled);
      setLogicVisibility(true);
    } else {
      setValue(`${filterType}.logic`, '');
      const unlabeled = filters.map(filter => ({ ...filter, label: '' }));
      replace(unlabeled);
      setLetterIdx(0);
      setLogicVisibility(false);
    }
  }, [filterType, filters, replace, setValue, showLogic]);

  const currentFieldId = useMemo(() => {
    if (rhfFields[bottomNavConfig.mappingIndex]) {
      return rhfFields[bottomNavConfig.mappingIndex]?.field?.id || undefined;
    } else {
      return undefined;
    }
  }, [rhfFields, bottomNavConfig.mappingIndex]);

  const getModelFieldDetails = _field => {
    const field = modelFields?.find(f => f.id === _field?.id) as ModelField;
    return {
      logoId: field?.fieldset?.connection?.type?.id,
      enrichmentLogoId: field?.enrichmentFieldset?.connection?.type?.id,
      modelId: field?.fieldset?.id,
      modelName: field?.fieldset?.name,
      columnName: field?.sourceName,
      type: field?.type
    };
  };

  return hideFilters ? null : (
    <>
      {bottomNavConfig.config === bottomNavType && (
        <PageLayout
          bottomNavShow={showBottomNav}
          bottomNavContent={
            bottomNavConfig.config === bottomNavType && (
              <FieldMappingBottomNav
                autoSelectedModelId={autoSelectedModelId}
                setAutoSelectedModelId={setAutoSelectedModelId}
                mappingIndex={bottomNavConfig.mappingIndex}
                targetConnection={targetConnection as LocalConnection}
                setShowBottomNav={setShowBottomNav}
                fieldType={bottomNavType}
                title={'Select filter field'}
                fields={fields as ModelFieldFragment[]}
                selectedFieldIds={selectedFieldIds}
                setSelectedFieldIds={setSelectedFieldIds}
                disabledFieldIds={[]}
                multiSelect={false}
                currentFieldId={currentFieldId}
                handleAddFields={(
                  fieldType: FIELD_MAPPING_TYPE,
                  mappingIndex: number,
                  fieldIds: string[]
                ) => {
                  const targetField = fields.find(f => f?.id === fieldIds[0]);
                  const targetValue =
                    rhfFields[mappingIndex]?.field?.type !== targetField?.type
                      ? null
                      : rhfFields[mappingIndex]?.value;
                  const targetFunction =
                    getFilterFunctions(targetField)?.find(
                      f => f?.id === rhfFields[mappingIndex]?.function
                    )?.id || null;
                  setIsDirty(true);
                  replace([
                    ...rhfFields.slice(0, mappingIndex),
                    ...[
                      {
                        ...rhfFields[mappingIndex],
                        field: targetField,
                        function: targetFunction,
                        value: targetValue
                      }
                    ],
                    ...rhfFields.slice(mappingIndex + 1, rhfFields.length)
                  ]);
                  setIsDirty(true);
                  focusOnMappingButton?.();
                }}
                linkButton={undefined}
                focusOnMappingButton={focusOnMappingButton}
              />
            )
          }
        />
      )}
      <div className="col-span-full mt-6 border-t border-gray-300 px-6 pt-6">
        <div className="mb-2 flex items-baseline justify-between">
          <div className="flex items-center space-x-1">
            <label className="block text-sm font-semibold">
              {heading !== 'model' && heading !== 'target' ? heading : capsFirst(heading)} filters
            </label>
            <Tooltip
              content={`Filter conditions for ${
                filterType === FILTER_TYPE.MODEL_FILTER ? 'source data' : 'destination records'
              }`}
              offset={[0, 4]}
            >
              <Icon name="InfoFilled" className="h-4 w-4 text-blue-500 hover:text-blue-400" />
            </Tooltip>
          </div>
          {filters && filters.length > 1 && (
            <LinkButton className="animate-fadeIn text-right text-xs" onClick={toggleLogic}>
              {showLogic ? 'Remove' : 'Add'} {heading} field filter logic
            </LinkButton>
          )}
        </div>

        {showLogic && (
          <div className="flex items-center rounded bg-gray-200 py-0.5">
            <div className="flex h-full items-center space-x-1 pl-2.5 pr-1.5">
              <span className="thead-text text-gray-500">Logic</span>
            </div>

            <MyInput {...register(`${filterType}.logic`)} className="rounded-l-none font-mono" />
          </div>
        )}

        {hasItems(rhfFields) ? (
          <section className={clsx(showLogic ? 'mt-4 space-y-2' : 'space-y-2')}>
            {rhfFields.map((rhfField, index) => {
              const filter = rhfFields && rhfFields.length >= index ? rhfFields[index] : null;
              let unreachable = false;
              let invalidTarget = false;
              if (
                filter &&
                hasItems(fields) &&
                isModelField(filter.field) &&
                isUnreachableFilter(filter, fields)
              ) {
                unreachable = true;
              }
              if (filter && isTargetField(filter.field) && isNotSyncModeUpdate) {
                invalidTarget = true;
              }

              const targetField = rhfField?.field || null;
              const targetFieldLabel = !targetField
                ? ''
                : isModelField(targetField)
                  ? targetField?.label
                  : targetField.name;
              const targetFieldIcon = !targetField
                ? ''
                : isModelField(targetField)
                  ? (targetField as ModelField)?.enrichmentFieldset?.connection?.type?.id ??
                    targetField?.fieldset?.connection?.type?.id
                  : targetConnection.type.id;

              return (
                <div
                  key={rhfField.id}
                  aria-label={filter?.label || ''}
                  className={clsx(showLogic && 'flex items-center rounded bg-gray-200 px-2 py-0.5')}
                >
                  {!showLogic && (
                    <label
                      className={clsx(
                        showLogic ? '-mt-1.25' : '-mt-px',
                        'thead-text mb-px block text-gray-500'
                      )}
                    >
                      {index === 0 ? 'WHERE' : 'AND'}
                    </label>
                  )}
                  {showLogic && (
                    <p className="min-w-10 flex min-h-full items-start justify-center self-stretch rounded-l px-2 pt-2.5  font-semibold uppercase text-gray-800">
                      {filter?.label || ''}
                    </p>
                  )}
                  <div className={'flex w-full space-x-1 pr-2'}>
                    <div className="h-full w-[35%] min-w-[35%] shrink-0">
                      <div className="h-full w-full">
                        <FieldMappingButton
                          buttonRef={el => {
                            if (buttonRefs) {
                              buttonRefs.current[`${index}${bottomNavType}`] = el;
                            }
                          }}
                          fieldType={bottomNavType}
                          mappingIndex={index}
                          placeholder={'Field...'}
                          disabled={false}
                          sanitize={false}
                          iconId={targetFieldIcon}
                          label={targetFieldLabel}
                          selected={
                            bottomNavConfig.mappingIndex === index &&
                            bottomNavConfig.config === bottomNavType &&
                            showBottomNav
                          }
                          onClick={() => {
                            setBottomNavConfig({ config: bottomNavType, mappingIndex: index });
                            setAutoSelectedModelId('');
                            setShowBottomNav(true);
                          }}
                        />
                      </div>
                      {(unreachable || invalidTarget) && (
                        <p className="mt-0.5 text-xs font-medium text-red-500">
                          {unreachable && 'Invalid filter: model field unreachable'}
                          {invalidTarget && 'Invalid filter: sync mode is not Update'}
                        </p>
                      )}
                      {showFieldDetails &&
                        filter?.field &&
                        (isModelField(filter.field) ? (
                          <FieldDetail
                            defaultStyles={'self-stretch p-2 pl-2.25'}
                            {...getModelFieldDetails(filter.field)}
                          />
                        ) : (
                          <FieldDetail
                            defaultStyles={'self-stretch p-2 pl-2.25'}
                            logoId={targetConnection?.type.id}
                            enrichmentLogoId={
                              (targetField as ModelField).enrichmentFieldset?.connection?.type?.id
                            }
                            modelName={targetObject?.name}
                            columnName={filter.field.id}
                            type={filter.field.type}
                          />
                        ))}
                    </div>
                    <div className="w-[22%] min-w-[22%] shrink-0 px-1 pt-1">
                      <MyCombobox
                        variant="flat"
                        options={
                          filter?.field ? toSelectables(getFilterFunctions(filter?.field)) : []
                        }
                        // @ts-expect-error for some reason
                        value={
                          rhfFields[index].function || null
                            ? {
                                label:
                                  getFilterFunctions(rhfFields[index]?.field)?.find(
                                    f => f.id === rhfFields[index]?.function
                                  )?.label ?? rhfFields[index]?.function,
                                value: rhfFields[index]?.value?.toString()
                              }
                            : null
                        }
                        onChange={option => {
                          setIsDirty(true);
                          let filterValue = rhfFields[index]?.value || null;
                          if (!option?.requiresValue && filterValue) {
                            filterValue = null;
                          } else if (option?.multiValue && !Array.isArray(filterValue)) {
                            filterValue = [filterValue];
                          } else if (option?.values && option.values.length > 0) {
                            filterValue = null;
                          } else if (!option?.multiValue && Array.isArray(filterValue)) {
                            if (filterValue.length === 1) {
                              filterValue = filterValue[0];
                            } else {
                              filterValue = null;
                            }
                          }
                          replace([
                            ...rhfFields.slice(0, index),
                            ...[
                              {
                                ...rhfFields[index],
                                function: option?.value || rhfFields[index].function || null,
                                value: filterValue || rhfFields[index]?.value || null
                              }
                            ],
                            ...rhfFields.slice(index + 1, rhfFields.length)
                          ]);
                          setIsDirty(true);
                        }}
                        isDisabled={!filter?.field}
                      />
                    </div>

                    <div className="w-[35%] min-w-[35%] shrink-0 px-1 pt-1 pb-1">
                      <FilterValueElements
                        variant="flat"
                        obj={rhfField}
                        fieldType={rhfField.field?.type}
                        filterFunction={getFilterFunctions(rhfField?.field)?.find(
                          func => func.id === rhfField.function
                        )}
                        value={rhfField.value}
                        handleValue={value => {
                          replace(
                            rhfFields.map((field, i) =>
                              i === index ? { ...rhfField, value } : field
                            )
                          );
                        }}
                      />
                    </div>
                    <div className="flex w-[8%] min-w-[8%] space-x-2">
                      <div className="min-w-6 flex shrink-0 justify-center pt-2">
                        <ParamButton
                          className="h-6 w-6"
                          action="delete"
                          onClick={() => handleDelete(index)}
                        />
                      </div>
                      <div
                        className={clsx(
                          'min-w-6 flex shrink-0 justify-center pt-2',
                          (!filters || index !== filters.length - 1) && 'invisible'
                        )}
                      >
                        <ParamButton className="h-6 w-6" action="add" onClick={handleAdd} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </section>
        ) : (
          (hasItems(mappings) || hasItems(fields)) && (
            <ParamButton action="add" onClick={handleAdd} />
          )
        )}
      </div>
    </>
  );
};

export default StageMappingsFilters;
