import { useApolloClient, useReactiveVar } from '@apollo/client';
import * as React from 'react';

import { resetStoreVar } from '../apollo';

export const useResetStore = () => {
  const client = useApolloClient();
  const shouldReset = useReactiveVar(resetStoreVar);

  React.useEffect(() => {
    if (shouldReset) {
      void client.resetStore();
      resetStoreVar(false);
    }
  }, [client, shouldReset]);
};

export function dispatchResetStore() {
  resetStoreVar(true);
}
