import clsx from 'clsx';
import { ReactNode } from 'react';

import PageLayout, { PageLayoutProps } from '~/components/v2/layout/PageLayout';
import { Icon } from '~/components';

export type EmptyPageProps = PageLayoutProps & {
  // Socket
  message?: ReactNode;
  // Boolean
  error?: boolean;
};

const EmptyPage = ({
  // Sockets
  topNavActions,
  topNavHeading,
  error = false,
  message,
  // Boolean
  loading = false,
  children
}: EmptyPageProps) => {
  //SX
  const SECTION_SX = clsx('mx-auto mt-16 w-full max-w-lg px-6');
  const ICON_SX = clsx('h-9 w-9', error ? 'text-orange-500' : 'text-gray-400');
  const TEXT_SX = clsx('mt-2 mb-3 text-sm');

  return (
    <PageLayout loading={loading} topNavHeading={topNavHeading} topNavActions={topNavActions}>
      <section className={SECTION_SX}>
        <Icon name={error ? 'DangerFilled' : 'InfoCircle'} className={ICON_SX} />
        <p className={TEXT_SX}>{message}</p>
        {children}
      </section>
    </PageLayout>
  );
};

export default EmptyPage;
