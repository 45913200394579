import cx from 'clsx';
import { JSONSchema4, JSONSchema4TypeName } from 'json-schema';
import * as React from 'react';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';

import { CompletionValue } from '../../../generated/graphql';
import { Selectable } from '../../../utils';
import { Checkbox, EditPermission, JsonViewer, MyInput, Textarea } from '../..';
import { NestedSchemaForm, RenderFunc } from '.';
import { ArrayElement } from './array';
import { EnumElement, EnumSelect } from './enum';
import { TypeAheadInput } from './typeahead';
import { TypeAheadParameterInput } from './typeaheadParam';
import FormHierarchyCompletion from '~/components/form-components/FormHierarchyCompletion';
import { cn } from '~/lib/utils';

type TypeName = JSONSchema4TypeName | JSONSchema4TypeName[];

export interface SchemaFormElementProps {
  id: string;
  field: JSONSchema4;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any;
  readOnly: boolean;
  type?: TypeName;
  className?: string;
  required?: boolean;
  errors?: FieldErrors;
  dependencies?: string[];
  getOptions?: (field: string, query?: string) => Promise<CompletionValue[]>;
  onChange: (values?: Record<string, unknown>) => void;
  renderFields?: RenderFunc;
}

export const SchemaFormElement = (props: SchemaFormElementProps) => {
  const [inputMask, setInputMask] = React.useState<'password' | 'text'>('password');

  const { register, setValue, formState } = useFormContext();
  const { errors } = formState;

  if (props.renderFields) {
    return (
      <Controller
        name={props.name}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        defaultValue={props.defaultValue}
        render={({ field: { value, onChange } }) =>
          props.renderFields?.({
            field: props.field,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value,
            onChange: props.onChange,
            setValue: updated => {
              onChange(updated);
              (props.dependencies || []).forEach((dep: string) => {
                setValue(dep, null);
              });
            }
          })
        }
      />
    );
  }

  if (props.field.widget) {
    // this field has a widget override
    switch (props.field.widget) {
      case 'json':
        return (
          <div className={cx('h-40 resize overflow-scroll', props.className)}>
            <JsonViewer data={props.defaultValue as string} />
          </div>
        );
      case 'hierarchyCompletion':
        return (
          <Controller
            name={props.name}
            render={({ field, formState }) => (
              <FormHierarchyCompletion
                item={{
                  name: props.name,
                  ...props.field
                }}
                field={{
                  ...field,
                  onChange: v => {
                    field.onChange(v.map(s => s.value));
                    if (formState.dirtyFields.topics) {
                      props.onChange(v.map(s => s.value));
                    }
                  }
                }}
                promiseOptions={async (): Promise<CompletionValue[]> => {
                  const opts =
                    ((props.field.enum || []) as string[]).map((item: string) => ({
                      label: item,
                      value: item
                    })) || [];
                  return opts;
                }}
              />
            )}
            rules={{ required: props.required && `${props.field.title || ''} is required` }}
          />
        );
    }
    return (
      <Controller
        name={props.name}
        render={({ field: { onChange } }) => (
          <EditPermission>
            <Textarea
              name={props.name}
              className={props.className}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              value={props.defaultValue}
              readOnly={props.readOnly}
              onChange={e => {
                onChange(e.target.value);
                props.onChange();
              }}
              errors={errors}
            />
          </EditPermission>
        )}
        rules={{ required: props.required && `${props.field.title || ''} is required` }}
      />
    );
  }
  const registration = register(props.name, { required: props.required });
  switch (props.type) {
    case 'boolean':
      return (
        <div className="flex items-center">
          <EditPermission>
            <Checkbox
              label={props.field.title}
              defaultChecked={props.defaultValue === 'true'}
              readOnly={props.readOnly}
              {...registration}
              onChange={e => {
                void registration.onChange(e);
                props.onChange();
              }}
            />
          </EditPermission>
        </div>
      );

    case 'number':
      return (
        <Controller
          name={props.name}
          render={({ field: { value, onChange } }) => (
            <EditPermission>
              <MyInput
                name={props.name}
                className={cn('max-w-[320px]', props.className)}
                type="number"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                defaultValue={value}
                readOnly={props.readOnly}
                onChange={e => {
                  onChange(e.target.value);
                  props.onChange();
                }}
                errors={errors}
              />
            </EditPermission>
          )}
          rules={{
            required: props.required && `${props.field.title || ''} is required`
          }}
        />
      );

    case 'string':
      if (props.field.password) {
        return (
          <Controller
            name={props.name}
            render={({ field: { value, onChange } }) => (
              <EditPermission>
                <MyInput
                  name={props.name}
                  className={cn('max-w-[320px]', props.className)}
                  type="password"
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  defaultValue={value}
                  readOnly={props.readOnly}
                  onChange={e => {
                    onChange(e.target.value);
                    props.onChange();
                  }}
                  errors={errors}
                />
              </EditPermission>
            )}
            rules={{
              required: props.required && `${props.field.title || ''} is required`
            }}
          />
        );
      }
      if (props.field.sensitive) {
        return (
          <Controller
            name={props.name}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            defaultValue={props.defaultValue || ''}
            render={({ field: { value, onChange } }) => (
              <EditPermission>
                <MyInput
                  type={inputMask}
                  name={props.name}
                  className={cn('max-w-[320px]', props.className)}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  defaultValue={value}
                  readOnly={props.readOnly}
                  onChange={e => {
                    onChange(e.target.value);
                    props.onChange();
                  }}
                  onMouseEnter={() => setInputMask('text')}
                  onMouseLeave={() => setInputMask('password')}
                  errors={errors}
                />
              </EditPermission>
            )}
            rules={{
              required: props.required && `${props.field.title || ''} is required`
            }}
          />
        );
      }
      break;

    case 'array':
      return <ArrayElement {...props} />;

    case 'object':
      if (props.field.completions) {
        break;
      }
      // Detect object with label and value to render select
      if (props.field.properties?.label && props.field.properties?.value && props.field.enum) {
        return (
          <div>
            <EnumSelect
              {...props}
              isObject={true}
              options={props.field.enum.map<EnumElement>(t =>
                typeof t === 'object' ? (t as unknown as Selectable) : String(t)
              )}
            />
          </div>
        );
      }
      return (
        <NestedSchemaForm
          schema={props.field}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          values={props.defaultValue}
          prefix={props.name}
          onChange={() => {
            props.onChange();
          }}
        />
      );
  }

  // see if this input supports completions
  if (props.field.enum) {
    return (
      <div>
        <EnumSelect
          {...props}
          options={props.field.enum.map<EnumElement>(t =>
            typeof t === 'object' ? (t as unknown as Selectable) : String(t)
          )}
        />
      </div>
    );
  }

  if (props.field.completions) {
    if (props.field.parameter) {
      // convert the default value to quack like a CompletionOption
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const defaultValue: CompletionValue =
        typeof props.defaultValue === 'object'
          ? {
              ...props.defaultValue,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
              label: props.defaultValue.label || props.defaultValue.DisplayValue,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
              value: props.defaultValue.value || props.defaultValue.id
            }
          : props.defaultValue !== undefined
            ? {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                label: props.defaultValue,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value: props.defaultValue
              }
            : { label: '', value: '' };
      return <TypeAheadParameterInput {...props} defaultValue={defaultValue} />;
    } else {
      return <TypeAheadInput {...props} />;
    }
  }

  const inputRegistration = register(props.name, {
    required: props.required && `${props.field.title || ''} is required`
  });
  // fallback to text input
  return (
    <EditPermission>
      <MyInput
        {...inputRegistration}
        className={cn('max-w-[320px]', props.className)}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        defaultValue={props.defaultValue || ''}
        readOnly={props.readOnly}
        onChange={e => {
          // @ts-expect-error Type is messed up
          void inputRegistration.onChange(e.target.value);
          props.onChange();
        }}
        errors={errors}
      />
    </EditPermission>
  );
};
