import cx from 'clsx';
import * as React from 'react';

export function SummarySectionWrapper(props: { className?: string; children: React.ReactNode }) {
  return (
    <section
      className={cx(
        'grid grid-cols-[6.5rem,1fr] items-start gap-x-6 border-t border-gray-300 pt-4 pb-4 pr-3 first:border-0 first:pt-0 last:pb-0',
        props.className
      )}
    >
      {props.children}
    </section>
  );
}
