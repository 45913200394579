import { useSetAtom } from 'jotai';
import React, {
  createRef,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { ModelFieldFragment, TargetFieldFragment } from '~/generated/graphql';
import {
  isModelField,
  isModelFieldArray,
  isTargetField,
  LocalConnection,
  sortByNameAndId
} from '~/utils';
import { Button, ControlledSearch, LinkButton } from '~/components/form-components';
import List from '~/components/v2/display/List';
import { BottomNavStateAtom } from '~/components/v2/layout/BottomNav';
import FieldMappingTable from '~/components/v2/experimental/FieldMappingTable';
import { FIELD_MAPPING_TYPE } from '~/components/v2/experimental/StageMappings';
import { Icon } from '~/components';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Resizable } from 're-resizable';
import { ModelField, useModelFields } from '~/hooks';
import { findIndex, reduce } from 'lodash';

export type FieldMappingBottomNavProps = {
  currentFieldId?: string;
  isFocusTrapDisabled?: boolean;
  // Target Mapping
  targetConnection: LocalConnection;
  // BottomNav State
  fieldType: FIELD_MAPPING_TYPE;
  mappingIndex: number;
  setShowBottomNav: React.Dispatch<React.SetStateAction<boolean>>;
  // Table Header
  title: ReactNode;
  subtitle?: ReactNode;
  linkButton?: {
    label: string;
    onClick: () => void;
  };
  // TEMP
  autoSelectedModelId: string;
  setAutoSelectedModelId: React.Dispatch<React.SetStateAction<string>>;
  // Table State
  fields: Array<ModelFieldFragment | TargetFieldFragment>;
  mappedFieldIds?: string[];
  selectedFieldIds?: string[];
  setSelectedFieldIds: React.Dispatch<React.SetStateAction<string[]>>;
  disabledFieldIds?: Array<string | undefined>;
  handleAddFields: (t: FIELD_MAPPING_TYPE, mappingIdex: number, fieldIds: string[]) => void;
  multiSelect?: boolean;
  triggerPopoverChip?: ({ x, y }: { x: number; y: number }) => void;
  // F(x)
  focusOnMappingButton?: () => void;
};

// TODO -> Figure out where to move this 🤔
type FieldMappingModel = {
  id: string;
  name: string;
  logoId: string;
  totalFields?: number;
  isEnrichment?: boolean;
};

export const FieldMappingBottomNav = ({
  currentFieldId,
  isFocusTrapDisabled = false,
  targetConnection,
  fieldType,
  mappingIndex,
  setShowBottomNav,
  title,
  subtitle,
  linkButton,
  autoSelectedModelId,
  setAutoSelectedModelId,
  fields,
  mappedFieldIds = [],
  selectedFieldIds = [],
  setSelectedFieldIds,
  disabledFieldIds = [],
  handleAddFields,
  multiSelect = false,
  triggerPopoverChip,
  focusOnMappingButton
}: FieldMappingBottomNavProps) => {
  /**
   * clickAwayRef is a ref that is used to track the click away event
   */
  const clickAwayRef = useRef<HTMLDivElement>(null);
  /**
   * setBottomnavState is a callback that updates the BottomNavStateAtom
   */
  const setBottomnavState = useSetAtom(BottomNavStateAtom);
  /**
   * useEffect is used to update the BottomNavStateAtom with the clickAwayRef
   * This is used to track the click away event
   * The clickAwayRef is used to determine if the click was outside of the BottomNav
   * If the click was outside of the BottomNav, the BottomNav is closed
   */
  useLayoutEffect(
    () => setBottomnavState(s => ({ ...s, bottomNavRef: clickAwayRef })),
    [setBottomnavState]
  );

  /**
   * SearchInput tracks the state of the manual search input from the user
   * handleSearchInput is a callback that updates the searchInput
   * handleResetSearchInput is a callback that clears the searchInput state
   */
  const [searchInput, setSearchInput] = useState<string>('');
  const handleSearchInput = useCallback((v?: string) => setSearchInput(v || ''), []);
  const handleResetSearchInput = useCallback(() => setSearchInput(''), []);

  const { fieldsets, modelFields } = useModelFields();

  /**
   * models is a list of all the models derived by the fields input
   * - If the fields are ModelFields it makes a unique set of all the models
   * - If the fields are TargetFields it returns just the targetConnection
   * - The reason TargetFields only return the targetConnection is
   *      that there cannot be multiple model targets,
   *      thus, the only model in the list is the targetConnection
   */
  const models: FieldMappingModel[] = useMemo(() => {
    // Group by fieldset id and count mappedFieldIds and return a list of models
    return isModelFieldArray(fields)
      ? Object.values(
          reduce(
            fields,
            (acc, field) => {
              // Filter out enrichment fieldsets
              if (!fieldsets?.[field.fieldset.id]) {
                return acc;
              }
              const totalFields = mappedFieldIds?.filter(id => id === field.id).length;
              const connectionObj = {
                id: field.fieldset.id,
                name: field.fieldset.name,
                logoId: field.fieldset.connection.type?.id,
                totalFields
              };
              if (!acc[field.fieldset.id]) {
                acc[field.fieldset.id] = connectionObj;
              } else {
                acc[field.fieldset.id].totalFields += totalFields;
              }
              return acc;
            },
            {} as Record<string, FieldMappingModel>
          )
        ).sort(sortByNameAndId)
      : [
          {
            id: targetConnection.id,
            name: targetConnection.name,
            logoId: targetConnection.type.id
          }
        ];
  }, [fields, targetConnection, mappedFieldIds]);

  /**
   * The GROUP enum is used to track the current group that is focused
   */
  enum GROUP {
    SEARCH = 'search',
    FIELDS = 'fields',
    MODELS = 'models'
  }

  /**
   * focusedField is the index of the field that is currently focused
   * focusedModelId is the id of the model that is currently focused
   * focusedGroup is the group (enum GROUP) that is currently focused
   */
  const [focusedField, setFocusedField] = useState(0);
  const [focusedModelId, setFocusedModelId] = useState('');
  const [focusedGroup, setFocusedGroup] = useState<GROUP>(GROUP.SEARCH);

  /**
   * groupRefs is a map of all the groups that are focusable
   * This is used to focus the correct group when the user uses the keyboard to navigate
   * The key is the GROUP enum
   * The value is the ref of the group
   * The ref is used to focus the group
   */
  const groupRefs = {
    [GROUP.SEARCH]: createRef<HTMLInputElement>(),
    [GROUP.FIELDS]: createRef<HTMLTableRowElement>(),
    [GROUP.MODELS]: createRef<HTMLLIElement>()
  };

  /**
   * getAllFieldIdsByConnectionId is a callback that returns a list of all the field ids that match the connection id
   */
  const getAllFieldIdsByConnectionId = useCallback(
    (id: string) => {
      if (isTargetField(fields[0])) {
        return fields.map(f => f.id);
      }
      if (isModelField(fields[0])) {
        const enrichmentIds = (fields as ModelFieldFragment[])
          .filter(field => field.fieldset.id === id)
          .flatMap(field =>
            field.fieldset.enrichments.flatMap(enrichment => enrichment.provider.id)
          );
        return (fields as ModelFieldFragment[])
          .filter(t => t.fieldset.id === id || enrichmentIds.includes(t.fieldset.id))
          .map(f => f.id);
      }
      return undefined;
    },
    [fields]
  );

  /**
   * FilteredFileds is a memoized list of all the fieldsets that "match" the search input
   * - If the fields are TargetFields
   *    - If the searchInput is empty, return all the fields
   *    - If the searchInput is not empty, return all the fields by name that match the searchInput (case insensitive)
   * - If the fields are ModelFields
   *    - If the searchInput is empty, return all the fields by fieldset.id that match the focusedModelId
   *   - If the searchInput is not empty, return all the fields by fieldset.id that match the focusedModelId and by label that match the searchInput (case insensitive)
   */
  const filteredFields = useMemo<(TargetFieldFragment | ModelField)[]>(() => {
    const sanitizedSearch = searchInput.toLocaleLowerCase();
    if (isTargetField(fields[0])) {
      if (searchInput === '') {
        return fields as TargetFieldFragment[];
      }
      return (fields as TargetFieldFragment[]).filter(
        field => !!field.name.toLocaleLowerCase().includes(sanitizedSearch)
      );
    } else if (isModelField(fields[0])) {
      const enrichmentIds = (modelFields as ModelField[])
        .filter(field => field.fieldset.id === focusedModelId)
        .flatMap(field => field.fieldset.enrichments.flatMap(enrichment => enrichment.provider.id));
      return (fields as ModelField[])
        .filter(
          field => field.fieldset.id === focusedModelId || enrichmentIds.includes(field.fieldset.id)
        )
        .filter(
          field => searchInput === '' || !!field.label.toLocaleLowerCase().includes(sanitizedSearch)
        );
    }
    return [];
  }, [searchInput, fields, focusedModelId]);

  /**
   * filteredModels is a memoized list of all the models that correspond to the search filtered fieldsets
   */
  const filteredModels = useMemo(() => {
    if (searchInput !== '') {
      const sanitizedSearch = searchInput.toLocaleLowerCase();
      if (searchInput !== '' && isModelFieldArray(fields)) {
        return models.filter(conn =>
          fields
            .filter(field => !!field.label.toLocaleLowerCase().includes(sanitizedSearch))
            .find(field => field.fieldset.id === conn.id)
        );
      }
    }
    return models;
  }, [searchInput, fields, models]);

  /*
   * This memoized value reflects the current index of the selected model in the filteredModels list
   */
  const currModelIndex = useMemo(
    () => findIndex(filteredModels, f => f.id === focusedModelId),
    [filteredModels, focusedModelId]
  );

  /** Effect used to determine the autoSelectedModelId */
  useEffect(() => {
    if (filteredModels.length === 0) {
      return;
    }

    const field = fields.find(f => f.id === currentFieldId);
    const [defaultModel] = filteredModels;
    const autoSelectedModel = filteredModels.find(m => m.id === autoSelectedModelId);
    if (autoSelectedModel) {
      return;
    }
    if (isModelField(field)) {
      const model = filteredModels.find(m => m.id === field.fieldset.id);
      if (model) {
        setAutoSelectedModelId(model.id);
        return;
      }
    }
    setAutoSelectedModelId(defaultModel.id);
  }, [filteredModels, currentFieldId, autoSelectedModelId, setAutoSelectedModelId]);

  /** Effect used to set the modelFocusId on autoSelectedModelId change */
  useEffect(() => {
    const autoSelectedModel = filteredModels.find(m => m.id === autoSelectedModelId);
    if (autoSelectedModel) {
      setFocusedModelId(autoSelectedModelId);
    }
  }, [autoSelectedModelId, setFocusedModelId]);

  const handleAdd = useCallback(
    (ids: string[] = selectedFieldIds) => {
      if (ids.length) {
        if (multiSelect) {
          handleAddFields(fieldType, mappingIndex, ids);
          setFocusedGroup(GROUP.SEARCH);
          /* Highlight the text inside the GROUP.SEARCH ref input component */
          // ALT - groupRefs[GROUP.SEARCH].current?.setSelectionRange(0, searchInput.length - 1)
          groupRefs[GROUP.SEARCH].current?.select();
        } else {
          setShowBottomNav(false);
          handleAddFields(fieldType, mappingIndex, ids);
          handleResetSearchInput();
        }
      }
    },
    [
      fieldType,
      handleAddFields,
      handleResetSearchInput,
      mappingIndex,
      multiSelect,
      selectedFieldIds,
      setShowBottomNav
    ]
  );

  /**
   * This callback is used to process a close event
   * - It returns focus to the mapping button
   * - It hides the bottom nav
   * - It resets the auto selected model id
   * - It resets the search input
   * - It resets the selected field ids
   */
  const handleClose = useCallback(() => {
    focusOnMappingButton && focusOnMappingButton();
    setShowBottomNav(false);
    setAutoSelectedModelId('');
    handleResetSearchInput();
    setSelectedFieldIds([]);
  }, [focusOnMappingButton, handleResetSearchInput, setSelectedFieldIds, setShowBottomNav]);

  /**
   * this function is used to determine if the Focus trap should deactivate on click
   */
  const shouldFocusTrapDeactiveOnClick = (event: MouseEvent | TouchEvent) => {
    const clickAwayExceptionIds = ['FieldMappingButton', 'BottomNav'];
    return event.composedPath().every(v => !clickAwayExceptionIds.includes((v as Element).id));
  };

  /**
   * This effect adds a click listener to the document to close the bottom nav if the user clicks outside of it
   */
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
      const clickAwayExceptionIds = [
        'FieldMappingButton',
        'StringInputDialog',
        'NewFieldDialog',
        'BottomNav',
        'SheetContent'
      ];
      if (clickAwayRef.current && !clickAwayRef.current.contains(event.target as Element)) {
        if (
          !clickAwayExceptionIds.includes((event.target as Element).id) &&
          event.composedPath().every(v => !clickAwayExceptionIds.includes((v as Element).id))
        ) {
          handleClose();
        }
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [clickAwayRef, handleClose]);

  /**
   * This effect resets the search input when the fieldType changes
   */
  useEffect(() => handleResetSearchInput(), [fieldType]);

  /**
   * Any time the fieldType or mappingIndex changes, focus the search input
   * This is to ensure that every time the target FieldMappingButton is clicked, the search input is focused
   */
  useEffect(() => setFocusedGroup(GROUP.SEARCH), [fieldType, setFocusedGroup, mappingIndex]);

  /**
   * This effect handles keyboard events
   * - Spacebar: Prevents the default behavior of scrolling the page down
   * - Escape: If the search input is not empty, it will clear the search input, else close the drawer
   * - ArrowDown:
   *   - If the focused group is fields, it will increment the focused field index
   *   - If the focused group is models, it will find the next modelId in the filtered models list and set it as the focused model id
   * - ArrowUp:
   *   - If the focused group is fields, it will decrement the focused field index
   *   - If the focused group is models, it will find the previous modelId in the filtered models list and set it as the focused model id
   * - Enter: if the focused group is not MODELS then it will add the selected field id
   * - Tab: It will change the focus to the next focus group
   * - Shift + Tab: It will change the fous to the previous focus group
   */
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        if (focusedGroup !== GROUP.SEARCH) {
          e.preventDefault();
        }
      }
      if (e.key === 'Escape') {
        e.preventDefault();
        if (searchInput !== '') {
          handleResetSearchInput();
        } else {
          handleClose();
        }
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (focusedGroup === GROUP.FIELDS) {
          setFocusedField(f => (f < filteredFields.length - 1 ? f + 1 : f));
        }
        if (focusedGroup === GROUP.MODELS) {
          if (currModelIndex < filteredModels.length - 1) {
            setAutoSelectedModelId(filteredModels[currModelIndex + 1].id);
          }
        }
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (focusedGroup === GROUP.FIELDS) {
          setFocusedField(f => (f > 0 ? f - 1 : f));
        }
        if (focusedGroup === GROUP.MODELS) {
          if (currModelIndex > 0) {
            setAutoSelectedModelId(filteredModels[currModelIndex - 1].id);
          }
        }
      }
      if (e.key === 'Enter') {
        e.preventDefault();
        if (focusedGroup !== GROUP.MODELS) {
          const firstRow = groupRefs[GROUP.FIELDS]?.current;
          if (firstRow) {
            const rect = firstRow.getBoundingClientRect();
            const y = document.body.offsetHeight - rect.y - rect.height / 1.5; // 1.5 To accomodate for popover y offset
            const x = document.body.offsetWidth - rect.x - rect.width / 2;
            firstRow.click();
            multiSelect && triggerPopoverChip({ x, y });
          }
        }
      }
      if (e.key === 'Tab') {
        e.preventDefault();
        if (e.shiftKey) {
          setFocusedGroup(f => {
            if (f === GROUP.SEARCH) return GROUP.MODELS;
            else if (f === GROUP.MODELS) return GROUP.FIELDS;
            else if (f === GROUP.FIELDS) return GROUP.SEARCH;
            else return GROUP.SEARCH;
          });
        } else {
          setFocusedGroup(f => {
            if (f === GROUP.SEARCH) return GROUP.FIELDS;
            else if (f === GROUP.FIELDS) return GROUP.MODELS;
            else if (f === GROUP.MODELS) return GROUP.SEARCH;
            else return GROUP.SEARCH;
          });
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown, false);
    return () => {
      window.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [
    setFocusedGroup,
    setFocusedField,
    setAutoSelectedModelId,
    currModelIndex,
    filteredFields,
    focusedField,
    focusedGroup
  ]);

  /**
   * This effect handles scrolling the focused field into view
   * It will only scroll if the focused group is fields
   * SEARCH does not needs scroll and FIELDS handles it's on scroll via the Table component
   */
  useEffect(() => {
    setTimeout(() => {
      groupRefs[GROUP.MODELS].current &&
        scrollIntoView(groupRefs[GROUP.MODELS].current, {
          scrollMode: 'if-needed',
          behavior: 'smooth',
          block: 'start'
        });
    });
  }, [focusedGroup, focusedModelId, focusedField]);

  /**
   * This effect will focus the focused group
   */
  useEffect(() => {
    groupRefs[focusedGroup]?.current?.focus();
  }, [groupRefs, focusedGroup, focusedModelId, focusedField]);

  /**
   * This effect will set the focused field index to the index of the currentFieldId
   */
  useEffect(() => {
    if (currentFieldId) {
      const index = filteredFields.findIndex(f => f.id === currentFieldId);
      setFocusedField(index > -1 ? index : 0);
    } else {
      setFocusedField(0);
    }
  }, [setFocusedField, currentFieldId, filteredFields]);

  /**
   * This function is the click handler for model items
   * It will set the focusedModelId to the modelId passed in
   * It will set the autoSelectedModelId to the modelId passed in
   * It will set the focusedField index to the index of the currentFieldId
   * It will set the focusedGroup to MODELS
   */
  const handleModelClick = (modelId: string) => {
    setFocusedModelId(modelId);
    setAutoSelectedModelId(modelId);
    const index = filteredFields.findIndex(f => f.id === currentFieldId);
    setFocusedField(index > -1 ? index : 0);
    setFocusedGroup(GROUP.MODELS);
  };

  /**
   * TODO
   * This function is the click handler for field items
   * It will set the focusedField to the fieldIndex passed in
   * It will set the focusedGroup to FIELDS
   * ...
   *  const handleFieldClick = (fieldIndex: number) => {
   *     setFocusedField(fieldIndex)
   *     setFocusedGroup(GROUP.FIELDS)
   *  }
   */

  /**
   * This function is the click handler for the add all button
   * It will call the handleAdd function with all the field ids for the connectionId passed in
   */
  const handleAddAllClick = (connId: string) => {
    const fields = getAllFieldIdsByConnectionId(connId);
    handleAdd(fields);
    handleClose();
  };

  /**
   * fieldScrollIndex is a memoized value that will be used to scroll the focused field into view
   * It will only be set if the focusedGroup is FIELDS or if the focusedGroup is SEARCH & the searchInput is not empty
   * This value will update any time the focusedGroup, searchInput, or focusedField changes
   */
  const fieldScrollIndex = useMemo(() => {
    if (
      focusedGroup === GROUP.FIELDS ||
      (focusedGroup === GROUP.SEARCH && searchInput.length > 0)
    ) {
      return focusedField;
    } else {
      return undefined;
    }
  }, [focusedGroup, searchInput, focusedField]);

  /**
   * width manages the width of the model list component
   */
  const [width, setWidth] = useState(224);

  /**
   * This effect will pull the width of the model list component from localStorage
   * It will set the width to the value pulled from localStorage if it exists
   * It will set the width to 224 if the value pulled from localStorage does not exist
   * It will set the width to 224 if the value pulled from localStorage is less than 224
   */
  useEffect(() => {
    const width = localStorage.getItem('modelListWidth');
    if (width) {
      const parsedWidth = parseInt(width);
      if (parsedWidth >= 224) {
        setWidth(parsedWidth);
      } else {
        setWidth(224);
      }
    } else {
      setWidth(224);
    }
  }, []);

  /**
   * This effect will set the width of the model list component in localStorage
   * It will only set the width if the width is greater than 100
   * It will only set the width if the width is different than the width in localStorage
   * It will only set the width if the width is different than the width in state
   * It will only set the width if the width is different than the width in props
   */
  useEffect(() => {
    if (width > 100) {
      const modelListWidth = localStorage.getItem('modelListWidth');
      if (modelListWidth) {
        const parsedWidth = parseInt(modelListWidth);
        if (parsedWidth !== width) {
          localStorage.setItem('modelListWidth', width.toString());
        }
      } else {
        localStorage.setItem('modelListWidth', width.toString());
      }
    }
  }, [width]);

  return (
    <div className="grid h-full w-full grid-cols-1 grid-rows-[auto,1fr] overflow-clip px-6 pb-4">
      {/* Header */}
      <div className="flex w-full items-center py-4">
        {/* Header Title & Subtitle */}
        <div className="flex w-full flex-col items-start gap-0 xl:flex-row xl:gap-2.5">
          <div className="text-base font-medium leading-5 text-gray-800">{title}</div>
          {linkButton ? (
            <LinkButton
              className="text-xs font-normal leading-5"
              onClick={linkButton.onClick}
              tabIndex={-1}
            >
              {linkButton.label}
            </LinkButton>
          ) : (
            subtitle
          )}
        </div>
        {/* Header Search */}
        <div className="flex grow justify-center">
          <ControlledSearch
            value={searchInput}
            autoFocus={false}
            tabIndex={focusedGroup === GROUP.SEARCH ? 0 : -1}
            setValue={handleSearchInput}
            wrapperStyles="h-8 w-60 2xl:w-80"
            searchInputRef={focusedGroup === GROUP.SEARCH ? groupRefs[GROUP.SEARCH] : null}
            onClick={() => setFocusedGroup(GROUP.SEARCH)}
          />
        </div>
        {/* Header Actions */}
        <div className="flex w-full">
          <div className="flex w-full items-start justify-end gap-2">
            {/* Close Button */}
            <Button tabIndex={-1} onClick={handleClose} theme="primary">
              Close
            </Button>
          </div>
        </div>
      </div>
      {/* Content */}
      <div className="flex h-full w-full items-start gap-4 self-stretch overflow-y-auto overflow-x-clip">
        {/* No-Model search message */}
        {!filteredModels.length || (!isModelFieldArray(fields) && !filteredFields.length) ? (
          <div className="flex w-full">
            <p className="text-lg text-gray-600">No matching fields</p>
          </div>
        ) : (
          <>
            {/* Model List*/}
            <div className="flex h-full self-stretch overflow-y-clip">
              <Resizable
                handleClasses={{
                  top: 'pointer-events-none',
                  bottom: 'pointer-events-none',
                  left: 'pointer-events-none',
                  topRight: 'pointer-events-none',
                  bottomRight: 'pointer-events-none',
                  bottomLeft: 'pointer-events-none',
                  topLeft: 'pointer-events-none',
                  right: `
                      h-full flex flex-col items-center justify-center
                      bg-transparent hover:bg-gray-200 active:bg-gray-300 transition
                      absolute !-right-2.5 text-gray-400 hover:text-gray-500 active:text-gray-600
                      rounded !w-[5px]
                    `
                }}
                handleComponent={{ right: <Icon name="ResizeHandleV" size="lg" /> }}
                minHeight="100%"
                size={{ width: width, height: '100%' }}
                onResizeStop={(e, direction, ref, d) => {
                  setWidth(w => w + d.width);
                }}
              >
                <List
                  dense
                  variant="outlined"
                  items={filteredModels.map(conn => {
                    return {
                      label: conn.name,
                      icon: <Icon match={conn.logoId} />,
                      key: conn.id,
                      width: width,
                      focusRef: conn.id === focusedModelId ? groupRefs[GROUP.MODELS] : createRef(),
                      endAdornment:
                        conn.id === focusedModelId ? (
                          <div className="flex h-5 shrink-0 grow items-center justify-end">
                            {multiSelect ? (
                              <Button
                                tabIndex={-1}
                                className="shrink-0 grow-0 p-0"
                                size="mini"
                                onClick={() => handleAddAllClick(conn.id)}
                              >
                                Add all
                              </Button>
                            ) : (
                              <span className="flex w-7 justify-end">
                                <Icon
                                  name="FastForward"
                                  className="h-5 w-5 self-end text-indigo-500"
                                />
                              </span>
                            )}
                          </div>
                        ) : (
                          conn.totalFields > 0 && (
                            <p className="text-gray-500">({String(conn.totalFields)})</p>
                          )
                        ),
                      selected: conn.id === focusedModelId,
                      onClick: () => handleModelClick(conn.id)
                    };
                  })}
                />
              </Resizable>
            </div>
            {/* Field Table */}
            <FieldMappingTable
              focusedRowIndex={focusedField}
              fieldScrollIndex={fieldScrollIndex}
              focusedRowRef={groupRefs[GROUP.FIELDS]}
              fieldType={fieldType}
              fields={filteredFields}
              setSelectedFieldIds={setSelectedFieldIds}
              disabledFieldIds={disabledFieldIds}
              multiSelect={multiSelect}
              handleAdd={handleAdd}
              triggerPopoverChip={triggerPopoverChip ? triggerPopoverChip : () => null}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FieldMappingBottomNav;
