import { useLazyQuery } from '@apollo/client';
import { setUser as setSentryUser } from '@sentry/react';
import { createContext, useContext, useEffect, useState } from 'react';
import { CurrentUserDocument, UserFragment } from '../generated/graphql';

type ContextValue =
  | {
      loading: boolean;
      called: boolean;
      user?: UserFragment;
      refresh?(): void;
    }
  | undefined;

export const UserContext = createContext<ContextValue>({
  called: false,
  loading: false
});

export function AuthProvider(props: { children: React.ReactNode }) {
  const [refresh, result] = useLazyQuery(CurrentUserDocument, {
    fetchPolicy: 'no-cache'
  });
  const [ctx, setContext] = useState<ContextValue>({
    loading: false,
    called: false,
    refresh
  });

  useEffect(() => {
    const { called, loading, data } = result;
    const { me: user } = data || { me: undefined };

    setContext(prev => ({ ...prev, loading, called, user }));
    setSentryUser(user);
  }, [result]);

  return <UserContext.Provider {...props} value={ctx} />;
}

export function useAuth(): ContextValue {
  const ctx = useContext(UserContext);
  const { called, refresh } = ctx;

  useEffect(() => {
    if (called) return;

    // Refresh the user information if context is not ready
    refresh && refresh();
  }, [called, refresh]);

  return ctx;
}
