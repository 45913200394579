import { FieldFunctionOptions, Reference } from '@apollo/client';

export function cacheRedirect(typename: string) {
  return (_: Reference, { args, toReference }: FieldFunctionOptions) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const id = args?.id;
    if (typeof id !== 'string') {
      return;
    }
    return toReference({
      id,
      __typename: typename
    });
  };
}
