import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Icon, LinkButton, Search } from '~/components';
import TooltipIcon from '~/components/tooltip-icon';
import { DataTable, Dialog } from '~/components/v3';
import { Sheet, SheetContent } from '~/components/v3/Sheet';

import {
  BulkExecutionStatus,
  BulkSyncExecutionDocument,
  BulkSyncPeekFragment,
  BulkSyncSchemaExecution,
  SchemaExecutionStatus
} from '~/generated/graphql';
import { useBannerDispatch, useLocalStorageState } from '~/hooks';
import { useConfiguration } from '~/hooks/configuration';
import { emptyCell, getLongLocalTime, getShortLocalTime } from '~/utils';
import {
  getDurationInMilliseconds,
  getFormattedDuration,
  getSyncStatusOrder
} from '~/utils/bulk-sync-history.util';
import { ExecutionStatusDisplay } from '../components/ExecutionStatusDisplay';
import { HistoryItem } from './bulk-sync-history';

const getColumns = (schemaLabel: string): ColumnDef<BulkSyncSchemaExecution>[] => [
  {
    id: 'schema',
    accessorKey: 'schema',
    header: () => schemaLabel,
    accessorFn: row => row.schema.name,
    cell: ({ row }) =>
      row.original.slowMode ? (
        <div className="flex items-center gap-1">
          <span>{row.original.schema.name}</span>
          <TooltipIcon
            message={
              row.original.status === SchemaExecutionStatus.Completed
                ? 'Object synced non-incrementally'
                : 'Object will be synced non-incrementally'
            }
            icon={<Icon className="align-top" name="Turtle" size="sm" />}
          />
        </div>
      ) : (
        <span>{row.original.schema.name}</span>
      ),
    size: 250,
    sortDescFirst: false,
    enableGlobalFilter: true
  },
  {
    accessorKey: 'startTime',
    accessorFn: row => getDurationInMilliseconds(row.startedAt, row.completedAt),
    header: 'Start time',
    cell: ({ row }) => getShortLocalTime(row.original.startedAt) || emptyCell,
    size: 160,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'endTime',
    accessorFn: row => getDurationInMilliseconds(row.startedAt, row.completedAt),
    header: 'End Time',
    cell: ({ row }) => getShortLocalTime(row.original.completedAt) || emptyCell,
    size: 160,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'duration',
    accessorFn: row => getDurationInMilliseconds(row.startedAt, row.completedAt),
    header: 'Duration',
    cell: ({ row }) =>
      getFormattedDuration(row.original.startedAt, row.original.completedAt) || emptyCell,
    size: 100,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'recordCount',
    accessorFn: row => row.recordCount,
    header: 'Total Records',
    cell: ({ row }) => row.original?.recordCount?.toLocaleString() || emptyCell,
    size: 80,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'warningCount',
    accessorFn: row => row.warnings?.count,
    header: 'Warnings',
    cell: ({ row }) => (
      <CountPreview
        heading="Warnings"
        count={row.original.warnings.count}
        downloadUrl={row.original.warnings.downloadUrl}
        preview={row.original.warnings.preview}
      />
    ),
    size: 80,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'errorCount',
    accessorFn: row => row.errors?.count,
    header: 'Errored',
    cell: ({ row }) => (
      <CountPreview
        heading="Errors"
        count={row.original.errors.count}
        downloadUrl={row.original.errors.downloadUrl}
        preview={row.original.errors.preview}
      />
    ),
    size: 80,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'status',
    accessorFn: row => getSyncStatusOrder(row.status),
    header: 'Status',
    cell: ({ row }) =>
      (
        <ExecutionStatusDisplay message={row.original.statusMessage} status={row.original.status} />
      ) || emptyCell,
    size: 100,
    sortDescFirst: false,
    enableGlobalFilter: false
  }
];

enum DownloadStatus {
  None = 'none',
  Downloading = 'downloading',
  Success = 'success',
  Error = 'error'
}

interface CountPreviewProps {
  heading: string;
  count: number;
  downloadUrl: string;
  preview: {
    polytomicID: string;
    message: string;
  }[];
}

function CountPreview({ count, downloadUrl, heading, preview }: CountPreviewProps) {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>(DownloadStatus.None);
  const closeRef = useRef<HTMLButtonElement>(null);

  if (!!!count) {
    return emptyCell;
  }

  const handleDownload = () => {
    if (downloadUrl) {
      setDownloadStatus(DownloadStatus.Downloading);
      fetch(downloadUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json'
        }
      })
        .then(res => {
          if (res.status !== 200 && res.status !== 204) {
            throw new Error(
              `Failed to download bulk sync schema data - unexpected status code ${res.status}`
            );
          } else {
            setDownloadStatus(DownloadStatus.Success);
          }
        })
        .catch(e => {
          setDownloadStatus(DownloadStatus.Error);
          Sentry.captureException(e, {
            extra: {
              downloadUrl: downloadUrl || 'no url'
            }
          });
        });
    }
  };

  const hideDialog = () => {
    setTimeout(() => {
      setShowPreview(false);
    });
  };

  return (
    <>
      <LinkButton onClick={() => setShowPreview(true)}>{count.toLocaleString()}</LinkButton>
      <Dialog
        show={showPreview}
        onDismiss={hideDialog}
        heading={heading}
        size="xl"
        actions={
          <>
            {downloadUrl && (
              <div className="flex flex-row items-center space-x-2">
                {downloadStatus === DownloadStatus.Success && (
                  <div className="flex flex-row space-x-2">
                    <Icon match="check" size="sm" variant="success" />
                    <p className="text-sm text-green-500">
                      You will receive an email when results are ready
                    </p>
                  </div>
                )}
                {downloadStatus === DownloadStatus.Error && (
                  <div className="flex flex-row space-x-2">
                    <Icon name="DangerFilled" className="h-4 w-4 text-red-500" />
                    <p className="text-sm text-red-500">Unable to complete download</p>
                  </div>
                )}
                <Button
                  theme="default"
                  disabled={downloadStatus !== DownloadStatus.None}
                  onClick={handleDownload}
                  loading={downloadStatus === DownloadStatus.Downloading}
                >
                  Download full log
                </Button>
              </div>
            )}
            <Button ref={closeRef} theme="primary" onClick={() => setShowPreview(false)}>
              Close
            </Button>
          </>
        }
      >
        <DataTable
          data={preview}
          columns={[
            {
              accessorKey: 'polytomicID',
              accessorFn: row => row.polytomicID,
              header: 'Polytomic ID'
            },
            {
              accessorKey: 'message',
              accessorFn: row => row.message,
              header: 'Message'
            }
          ]}
          classNames={{ wrapper: 'max-h-[20rem]', table: 'table-auto' }}
          disableVirtualization={true}
        />
      </Dialog>
    </>
  );
}

interface ExecutionDetailProps {
  row: HistoryItem;
  sync: BulkSyncPeekFragment;
  sheetRef?: React.RefObject<HTMLDivElement>;
  onClose: () => void;
}

export function ExecutionDetailDrawer({ row, sync, sheetRef, onClose }: ExecutionDetailProps) {
  const { state: configuration } = useConfiguration();

  const dispatchBanner = useBannerDispatch();

  const { data, error, loading, refetch } = useQuery(BulkSyncExecutionDocument, {
    skip: !row,
    notifyOnNetworkStatusChange: true,
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    variables: {
      syncId: row?.syncId,
      executionId: row?.id
    }
  });

  useEffect(() => {
    row && refetch();
  }, [row, refetch]);

  const [search, setSearch] = useState<string>('');
  const [sorting, setSorting] = useLocalStorageState<SortingState>(
    [{ id: 'schema', desc: false }],
    'bulkSyncExecutionDetailSort'
  );

  const schemaLabel = sync?.source?.schemaLabel?.singular ?? 'object';
  const columns = useMemo(() => getColumns(schemaLabel), [schemaLabel]);

  const shouldShowDowloadLogButton = useMemo(() => {
    return (
      row &&
      configuration.on_premises &&
      configuration.execution_logs_v2 &&
      ![BulkExecutionStatus.Created, BulkExecutionStatus.Scheduled].includes(row?.status)
    );
  }, [configuration, row]);

  const downloadSyncLog = () => {
    if (row?.syncId && row?.id) {
      window.open(`/api/executions/${row?.syncId}/${row?.id}/log.json`);
    }
  };

  return (
    <Sheet open={!!row} modal={false}>
      <SheetContent
        header={
          <>
            <div className="flex flex-1 flex-wrap items-center">
              <h2 className="mr-2 text-base font-medium">Sync details</h2>
              <p>{getLongLocalTime(row?.startedAt || row?.createdAt)}</p>
            </div>
            <div className="flex flex-1 justify-center">
              <Search
                className="font-normal"
                wrapperStyles="h-8 w-80"
                placeholder="Search objects..."
                defaultValue={search}
                onChange={v => setSearch(v ?? '')}
                onReset={() => setSearch('')}
                disabled={!!error}
              />
            </div>
            <div className="flex flex-1 items-center justify-end space-x-2">
              {shouldShowDowloadLogButton && (
                <Button onClick={downloadSyncLog}>Download sync log</Button>
              )}
              <Button theme="primary" onClick={onClose}>
                Close
              </Button>
            </div>
          </>
        }
        onEscapeKeyDown={onClose}
        storageKey="bulkSyncHistorySheet"
        ref={sheetRef}
      >
        <DataTable
          data={data?.bulkSync?.execution?.schemas as BulkSyncSchemaExecution[]}
          columns={columns}
          loading={loading}
          // Filtering
          globalFilter={search}
          onGlobalFilterChange={setSearch}
          //Sorting
          sorting={sorting}
          onSortingChange={setSorting}
        />
      </SheetContent>
    </Sheet>
  );
}
