import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  Label,
  MyCombobox,
  Section,
  SideBySide
} from '~/components';
import { GSheetsConfiguration, SheetSelection } from '~/generated/graphql';
import { useFieldsetState } from '~/hooks';
import { FieldsetFormValues, getSchemaNormalized } from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { FieldsTable } from '../components';

export function GsheetsFieldsetConfig() {
  const { fieldset, loading, refreshing, applyUpdate } = useFieldsetState();
  const { control, getValues, setValue } = useFormContext<FieldsetFormValues>();
  const { field } = useController({ control, name: 'configuration.selectedSheet' });

  const schema = React.useMemo(
    () => getSchemaNormalized(fieldset?.configurationSchema),
    [fieldset?.configurationSchema]
  );

  const handleRefresh = React.useCallback(() => {
    const conf = getValues('configuration') as GSheetsConfiguration;
    if (!conf) {
      return;
    }
    applyUpdate(
      {
        selectedSheet: {
          label: conf.selectedSheet?.label,
          value: conf.selectedSheet?.value
        }
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          {schema && (
            <div className="w-full max-w-xs animate-fadeIn">
              <Label className="mb-1 flex justify-between font-medium">
                <span>{schema?.selectedSheet?.title}</span>
                {
                  // @ts-expect-error no type for gsheet cxn config
                  fieldset?.connection?.configuration?.spreadsheet_id?.value && field.value && (
                    <a
                      className="link font-normal"
                      // @ts-expect-error no type for gsheet cxn config
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      href={`https://docs.google.com/spreadsheets/d/${fieldset.connection.configuration.spreadsheet_id.value}/edit#gid=${field.value.value}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open ↗
                    </a>
                  )
                }
              </Label>
              <EditPermission fallback={<DisabledSelect valueLabel={field.value?.label} />}>
                <MyCombobox
                  value={{ label: field?.value?.label, value: field?.value?.value }}
                  options={schema?.selectedSheet?.enum?.map(s => {
                    const sheet = s as unknown as SheetSelection;
                    return { label: sheet?.label, value: sheet?.value };
                  })}
                  onChange={option => {
                    if (option == null) {
                      return;
                    }
                    field.onChange({
                      label: option.label,
                      value: option.value
                    });
                    // clear primaryKey value as it will error on a different selection
                    setValue('primaryKey', null, { shouldDirty: true });
                    applyUpdate({
                      selectedSheet: {
                        label: option.label,
                        value: option.value
                      }
                    });
                  }}
                />
              </EditPermission>
            </div>
          )}
        </SideBySide>
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || (loading && !fieldset?.fields)}
          disabled={loading}
          refresh={handleRefresh}
          hasWriteinFields={fieldset?.properties.writeinFields}
          userTypeSelection={fieldset?.properties.userTypeSelection}
        />
      </Section>
      <AdditionalConfig />
    </>
  );
}
