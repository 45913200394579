import { omit } from 'lodash';
import {
  ExecutionCountField,
  ExecutionsCondition,
  ExecutionStatus,
  ExecutionTrigger,
  NumericComparator,
  SyncExecutionFragment,
  SyncFragment,
  SyncMode
} from '../generated/graphql';
import { HISTORY_FILTERS } from './constants.util';
import { ExecutionRecordType, SyncHistoryFilter } from './custom-types.util';

export const transformFiltersForWhere = (filters: SyncHistoryFilter[]) => {
  if (!filters || filters.length === 0) {
    return {};
  }
  const where: ExecutionsCondition = {
    betweenTime: undefined,
    status: undefined,
    time: undefined,
    trigger: undefined,
    counts: undefined
  };

  filters.forEach(filter => {
    // this guard expects string or undefined on all values
    if (!(filter.column && filter.comparator && filter.param)) {
      return;
    }
    switch (filter.column) {
      case 'status':
        if (filter.param === 'completed with errors') {
          where.status = { status: ExecutionStatus.Completed };
          if (!where.counts) {
            where.counts = [];
          }
          where.counts.push({
            field: ExecutionCountField.ErrorCount,
            comparator: NumericComparator.Gt,
            value: 0
          });
          break;
        }
        where.status = { status: filter.param as ExecutionStatus };
        break;
      case 'time':
        switch (filter.comparator) {
          case 'between': {
            const [from, to] = filter.param.split('@');
            where.betweenTime = { from, to };
            break;
          }
          case 'eq': {
            where.time = { seconds: parseInt(filter.param) };
            break;
          }
        }
        break;
      case 'trigger':
        where.trigger = { trigger: filter.param as ExecutionTrigger };
        break;
      default:
        if (!where.counts) {
          where.counts = [];
        }
        where.counts.push({
          field: filter.column as ExecutionCountField,
          comparator: filter.comparator,
          value: parseInt(filter.param)
        });
        break;
    }
  });

  return where;
};

export function recordDialogHeading(type: ExecutionRecordType | null) {
  if (!type) {
    return '';
  }
  switch (type) {
    case 'records':
      return 'Total records sample';
    case 'inserts':
      return 'Inserted records sample';
    case 'updates':
      return 'Updated records sample';
    case 'errors':
      return 'Sync errors sample';
    case 'warnings':
      return 'Sync warnings sample';
  }
}

export function prepareRecordDisplay(type: ExecutionRecordType, execution: SyncExecutionFragment) {
  switch (type) {
    case 'records':
      return {
        count: execution.recordCount,
        hasData: !!execution.records?.hasData
      };
    case 'inserts':
      return {
        count: execution.insertCount,
        hasData: !!execution.inserts?.hasData
      };
    case 'updates':
      return {
        count: execution.updateCount,
        hasData: !!execution.updates?.hasData
      };
    case 'deletes':
      return {
        count: execution.deleteCount,
        hasData: !!execution.deletes?.hasData
      };
    case 'warnings':
      return {
        count: execution.warningCount,
        hasData: !!execution.warnings?.hasData
      };
    case 'errors':
      return {
        count: execution.errorCount,
        hasData: !!execution.errors?.hasData || execution.executionErrors?.length > 0
      };
  }
}

export function getHistoryFilters(sync?: SyncFragment) {
  const mode = sync?.mode ?? null;

  let filters: Record<string, string> = {};
  switch (mode) {
    case SyncMode.Update: {
      const { insertCount, ...relevantFilters } = HISTORY_FILTERS;
      filters = relevantFilters;
    }
    case SyncMode.Create: {
      const { updateCount, ...relevantFilters } = HISTORY_FILTERS;
      filters = relevantFilters;
    }
    case SyncMode.Replace:
    case SyncMode.Append: {
      const { insertCount, updateCount, ...relevantFilters } = HISTORY_FILTERS;
      filters = relevantFilters;
    }
    default:
      filters = HISTORY_FILTERS;
  }

  if (sync.targetObject?.properties?.doesNotReportOperationCounts) {
    filters = omit(filters, ['insertCount', 'updateCount']);
  }

  return filters;
}
