import { captureMessage } from '@sentry/react';
import { ForwardedRef, forwardRef, SVGProps, useEffect } from 'react';
import { tv } from 'tailwind-variants';
import { IconName, Icons } from '~/assets';
import { cn, getClassNames, type VariantProps } from '~/lib/utils';

const iconVaraiants = tv({
  slots: {
    container: '',
    icon: ''
  },
  variants: {
    size: {
      xs: { icon: 'h-3 w-3' },
      sm: { icon: 'h-4 w-4' },
      md: { icon: 'h-5 w-5' },
      lg: { icon: 'h-6 w-6' },
      xl: { icon: 'h-8 w-8' }
    },
    variant: {
      disabled: { container: 'text-gray-500' },
      primary: { container: '' },
      secondary: { container: 'text-gray-500' },
      success: { container: 'text-green-500' },
      error: { container: 'text-red-500' },
      warning: { container: 'text-amber-500' },
      info: { container: 'text-blue-500' }
    }
  },
  defaultVariants: {
    size: 'md',
    variant: 'primary'
  }
});

const IconLookup = Object.keys(Icons).reduce(
  (prev, key) => ({
    ...prev,
    [key.toLowerCase()]: Icons[key]
  }),
  {}
);

function captureNotFoundMessage(...args: (string | undefined)[]) {
  useEffect(() => {
    const name = args.reduce((prev, next) => prev || next, undefined);
    if (!name) {
      // No name defined, no message
      return;
    }

    if (IconLookup[name.toLowerCase()]) {
      return;
    }

    captureMessage(`Icon '${name}' not found, displaying Generic icon.`, {
      level: 'warning'
    });
  }, [args]);
}

type BaseIconProps = SVGProps<SVGSVGElement> & VariantProps<typeof iconVaraiants>;

interface NamedIconProps extends BaseIconProps {
  /** Exlcude if using exact name */
  match?: never;
  /** Exact name of the icon to be shown */
  name: IconName;
}

interface FindIconProps extends BaseIconProps {
  /** Name to match in the icon list, is not case sensitive, and does not use IconName type */
  match: string;
  /** Exclude if using match prop */
  name?: never;
}

export type IconProps = NamedIconProps | FindIconProps;

function IconInternal(
  { className, match, name, ...props }: IconProps,
  ref: ForwardedRef<HTMLSpanElement>
) {
  captureNotFoundMessage(name, match);

  const classNames = getClassNames(iconVaraiants, props);
  const lookupName = (name || match || '').toLowerCase();
  const Icon = IconLookup[lookupName] || Icons['Generic'];

  return (
    <span ref={ref} className={classNames.container}>
      <Icon {...props} className={cn(classNames.icon, className)} />
    </span>
  );
}

export const Icon = forwardRef<HTMLSpanElement, IconProps>(IconInternal);
Icon.displayName = 'Icon';
