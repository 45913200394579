import Divider from './Divider';
import { cn } from '~/lib/utils';

// Todo - add comments
export type CardProps = {
  className?: string;
  header?: React.ReactNode;
  headerTitle?: React.ReactNode;
  headerSubtitle?: React.ReactNode;
  headerActions?: React.ReactNode;
  headerDivider?: boolean;
  footerTitle?: React.ReactNode;
  footerActions?: React.ReactNode;
  noContentPadding?: boolean;
  children?: React.ReactNode;
  contentClasses?: string;
};

// Todo - add comments
const Card = ({
  className,
  header,
  headerActions,
  headerTitle,
  headerSubtitle,
  headerDivider = false,
  footerTitle,
  footerActions,
  noContentPadding = false,
  children,
  contentClasses
}: CardProps) => {
  return (
    <div
      className={cn(
        'flex w-full flex-col rounded-md border border-gray-300 bg-white shadow shadow-card',
        className
      )}
    >
      <div className="flex w-full items-center justify-between bg-gray-50 px-4 py-3">
        {header || (
          <>
            <div>
              <p className="text-sm text-gray-800">{headerTitle}</p>
              <p className="text-xs font-light text-gray-600">{headerSubtitle}</p>
            </div>
            <div className="flex gap-2">{headerActions}</div>
          </>
        )}
      </div>
      {headerDivider && <Divider />}
      <div className={cn('flex flex-1 flex-col', !noContentPadding && 'p-4 py-2', contentClasses)}>
        {children}
      </div>
      {(!!footerTitle || !!footerActions) && (
        <div className="flex w-full items-center justify-between p-4">
          <p className="text-sm font-light text-gray-600">{footerTitle}</p>
          <div className="flex gap-2">{footerActions}</div>
        </div>
      )}
    </div>
  );
};

export default Card;
