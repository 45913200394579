import { JSONSchema4 } from 'json-schema';
import { useEffect, useState } from 'react';
import { ControllerRenderProps, FieldValues, useController } from 'react-hook-form';
import { CompletionValue } from '~/generated/graphql';
import HierarchySelect, { HierarchySelectOption } from '~/components/v2/inputs/HierarchySelect';
import { isObject } from 'lodash';
type Props = {
  item: JSONSchema4;
  promiseOptions?: (field: string, query?: string) => Promise<CompletionValue[]>;
  className?: string;
  field?: ControllerRenderProps<FieldValues, any>;
};

const FormHierarchyCompletion = ({
  item,
  promiseOptions,
  className,
  field: defaultField
}: Props) => {
  const { field } = defaultField ? { field: defaultField } : useController({ name: item.name });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<HierarchySelectOption[] | []>([]);
  const [options, setOptions] = useState<CompletionValue[] | []>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [error, setError] = useState(null);

  const load = async () => {
    setLoading(true);
    setError(null);
    try {
      const name: string = item.name.split('.')[1];
      const res = await promiseOptions(name, '');
      setOptions(res);

      // Filter out values no longer in list
      const available = field?.value?.filter(
        value => !!res.find(option => String(option?.value) === value?.value)
      );
      if (available?.length < field?.value?.length) {
        field.onChange(available);
      }

      const formatedData = res
        .map(option => {
          return {
            id: String(option.value),
            value: String(option.value),
            label: option.label,
            parentId: null
          } as HierarchySelectOption;
        })
        .map((item, _idx, items) => {
          let parent;
          if (String(item.id).includes('.')) {
            const splitId = item?.id?.split('.');
            const parentId = splitId.slice(1, splitId.length - 1).join('.');
            parent = items.find(x => {
              const xSplit = x?.id?.split('.');
              const xid = xSplit?.slice(1, xSplit.length).join('.');
              return xid === parentId && x.id !== item.id;
            });
          }

          return {
            ...item,
            parentId: parent?.id ?? null
          };
        });

      setData(formatedData);
    } catch (error) {
      setError(String(error) || 'Error loading data');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    field.onChange(options?.filter(x => selected.includes(String(x.value))) ?? []);
  }, [selected]);

  const primaryColLabel = item.name.split('.')[1] || item.name;

  const getTempColName = () => {
    switch (primaryColLabel) {
      case 'repositories':
        return 'Repositories';
      case 'accounts':
        return 'Account Name';
      default:
        return primaryColLabel;
    }
  };

  return (
    <div className={className}>
      <HierarchySelect
        options={data}
        cols={[{ id: 'label', label: `${getTempColName()}`, accessor: 'label' }]}
        loading={loading}
        setSelected={setSelected}
        selected={field.value?.map(x => (isObject(x) ? String(x['value']) : x)) || []}
        handleRefresh={() => load()}
      />
    </div>
  );
};

export default FormHierarchyCompletion;
