import { RadioGroup } from '@headlessui/react';
import cx from 'clsx';
import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Icon } from '~/components';
import { SupportedMode } from '~/generated/graphql';
import { hasItems, SyncConfigFormValues } from '~/utils';
import { StageCard } from './stage-card';

interface Props {
  modes: SupportedMode[];
}

export const StageMode = React.memo<Props>(({ modes }) => {
  const { control } = useFormContext<SyncConfigFormValues>();
  const { field } = useController({ control, name: 'mode' });

  if (!hasItems(modes)) {
    return null;
  }

  return (
    <StageCard hasStickyHeader={modes.length > 2} step={2} header="Choose sync mode">
      <RadioGroup value={field.value} onChange={field.onChange}>
        <div className="space-y-4 px-6">
          {modes.map(display => (
            <RadioGroup.Option
              key={display.mode}
              value={display.mode}
              className="group max-w-fit focus:outline-none"
            >
              {({ checked }) => (
                <span className="flex items-start space-x-1.5 group-hover:cursor-pointer">
                  <Icon
                    name={checked ? 'ActiveCircle' : 'EmptyCircle'}
                    className={cx(
                      'h-4 w-4 rounded-full group-hover:cursor-pointer group-focus-visible:ring-2 group-focus-visible:ring-indigo-500 group-focus-visible:ring-offset-2',
                      checked ? 'text-green-500' : 'text-gray-500'
                    )}
                  />
                  <span className="-mt-px">
                    <RadioGroup.Label className="leading-none group-hover:cursor-pointer">
                      {display.label}
                    </RadioGroup.Label>
                    <RadioGroup.Description className="block text-sm text-gray-500">
                      {display.description}
                    </RadioGroup.Description>
                  </span>
                </span>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </StageCard>
  );
});

if (import.meta.env.MODE === 'development') {
  StageMode.displayName = 'StageMode';
}
