import cx from 'clsx';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { EditPermission, Label, MyInput, ParamButton } from '~/components';
import { FieldsetFormValues, hasItems } from '~/utils';

interface Props {
  type: 'headers' | 'parameters';
  label: string;
  className?: string;
}

export function ApiParam(props: Props) {
  const { control, register } = useFormContext<FieldsetFormValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `configuration.${props.type}`
  });

  function add() {
    append({ name: '', value: '' });
  }

  return (
    <div className={props.className}>
      <Label>{props.label}</Label>
      <div className="grid grid-cols-[8rem,1fr,repeat(2,1.25rem)] items-center gap-2">
        {hasItems(fields) ? (
          fields.map((field, index) => {
            return (
              <React.Fragment key={field.id}>
                <EditPermission>
                  <MyInput
                    {...register(`configuration.${props.type}.${index}.name` as const)}
                    placeholder="Enter name..."
                  />
                </EditPermission>
                <EditPermission>
                  <MyInput
                    {...register(`configuration.${props.type}.${index}.value` as const)}
                    placeholder="Enter value..."
                  />
                </EditPermission>
                <EditPermission>
                  <ParamButton
                    action="delete"
                    className="focus-visible:ring-offset-gray-100"
                    onClick={() => remove(index)}
                  />
                </EditPermission>
                <EditPermission>
                  <ParamButton
                    className={cx(
                      'focus-visible:ring-offset-gray-100',
                      index === fields.length - 1 ? 'visible' : 'invisible'
                    )}
                    action="add"
                    onClick={add}
                  />
                </EditPermission>
              </React.Fragment>
            );
          })
        ) : (
          <EditPermission>
            <ParamButton
              action="add"
              className="focus-visible:ring-offset-gray-100"
              onClick={add}
            />
          </EditPermission>
        )}
      </div>
    </div>
  );
}
