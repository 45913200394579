import { Header } from '@tanstack/react-table';
import clsx from 'clsx';
import { HTMLAttributes } from 'react';

import TableHeaderCol, { TableHeaderColProps } from '~/components/v2/table/TableHeaderCol';

export type TableHeaderRowProps<TData, TValue> = {
  headers: Header<TData, TValue>[];
  TableHeaderColProps?: Partial<TableHeaderColProps<TData, TValue>>;
  enableColumnResizing?: boolean;
} & HTMLAttributes<HTMLTableRowElement>;

const TableHeaderRow = <TData, TValue>({
  headers,
  TableHeaderColProps,
  enableColumnResizing,
  className,
  ...rest
}: TableHeaderRowProps<TData, TValue>) => {
  const baseClassName = clsx(
    'w-fit border-b border-gray-200 bg-gray-50',
    className,
    enableColumnResizing && 'flex'
  );
  return (
    <tr className={baseClassName} {...rest}>
      {headers.map(header => (
        <TableHeaderCol key={header.id} header={header} {...TableHeaderColProps} />
      ))}
    </tr>
  );
};

export default TableHeaderRow;
