import * as React from 'react';

import { CustomProviderProps } from '../utils/custom-types.util';
import { createUseCtx } from './context-factories';

export function createStateCtx<StateType>(defaultState?: StateType) {
  const defaultUpdate: React.Dispatch<React.SetStateAction<StateType | undefined>> = () =>
    defaultState;
  const Ctx = React.createContext({ state: defaultState, update: defaultUpdate });

  function Provider({ children, initialState = defaultState }: CustomProviderProps<StateType>) {
    const [state, update] = React.useState(initialState);
    return <Ctx.Provider value={{ state, update }}>{children}</Ctx.Provider>;
  }

  const useCtx = createUseCtx(Ctx);

  return { Ctx, useCtx, Provider };
}
