import cx from 'clsx';
import * as React from 'react';

import { Action, PermissionTagFragment } from '../../generated/graphql';
import { AclProvider, useOnClickOutside } from '../../hooks';
import { hasItems } from '../../utils';
import { EditPermission } from '../edit-permission';
import { MyInput } from '../form-components';
import LoadingDots from '../v2/feedback/LoadingDots';
import { Permission } from '../permission';
import { Tooltip } from '../tooltip';
import { Icon } from '../Icon';
import { AccessControlTagInfo } from './access-control-tag-info';

interface Props {
  tags: PermissionTagFragment[];
  addTag: (tag: PermissionTagFragment) => void;
  loading?: boolean;
}

export const AccessControlTagInput = ({ tags, addTag, loading }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [block, setBlock] = React.useState(false);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleClose = React.useCallback(() => {
    setBlock(false);
    setQuery('');
    setOpen(false);
  }, []);

  useOnClickOutside(ref, handleClose);

  const sortedTags = React.useMemo(
    () => [...tags].sort((a, b) => +b.system - +a.system || a.name.localeCompare(b.name)),
    [tags]
  );

  const filteredTags =
    query === ''
      ? sortedTags
      : sortedTags.filter(t =>
          t.name
            .toLocaleLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLocaleLowerCase().replace(/\s+/g, ''))
        );

  return (
    <div ref={ref} className="relative z-10">
      <EditPermission>
        <MyInput
          disabled={loading}
          name="tag-control-input"
          placeholder="Add additional policies..."
          value={query}
          onChange={e => setQuery(e.target.value)}
          onFocus={() => setOpen(true)}
          onBlur={() => {
            if (!block) {
              setOpen(false);
            }
          }}
        />
      </EditPermission>
      {loading && (
        <div className="absolute bottom-2.5 right-2 z-20">
          <LoadingDots />
        </div>
      )}
      {open && (
        <div className="absolute mt-1 max-h-44 min-w-full overflow-auto rounded border border-gray-300 bg-white py-1 shadow-lg focus:outline-none">
          {hasItems(filteredTags) ? (
            filteredTags.map(tag => (
              <AclProvider key={tag.id} value={tag.acl}>
                <Permission
                  type={Action.ApplyPolicy}
                  fallback={
                    <Tooltip
                      placement="left"
                      offset={[0, 6]}
                      content="You do not have permission to add this policy."
                    >
                      <div className="group relative flex select-none items-center justify-between space-x-2 py-2 pl-4 pr-2 hover:bg-gray-100">
                        <span className="opacity-50">{tag.name}</span>
                        <AccessControlTagInfo tag={tag} placement="right">
                          <Icon
                            name="InfoFilled"
                            className="invisible h-4 w-4 text-indigo-300 hover:text-indigo-500 focus:outline-none group-hover:visible"
                          />
                        </AccessControlTagInfo>
                      </div>
                    </Tooltip>
                  }
                >
                  <div
                    key={tag.id}
                    onPointerOver={() => {
                      if (!block) {
                        setBlock(true);
                      }
                    }}
                    onClick={() => {
                      addTag(tag);
                      handleClose();
                    }}
                    className={cx(
                      'group relative flex cursor-pointer select-none items-center justify-between space-x-2 py-2 pl-4 pr-2 hover:bg-indigo-100'
                    )}
                  >
                    <span>{tag.name}</span>
                    <AccessControlTagInfo tag={tag} placement="right">
                      <Icon
                        name="InfoFilled"
                        className={cx(
                          'invisible h-4 w-4 text-indigo-300 hover:text-indigo-500 focus:outline-none group-hover:visible'
                        )}
                      />
                    </AccessControlTagInfo>
                  </div>
                </Permission>
              </AclProvider>
            ))
          ) : (
            <p className="w-full py-2 text-center text-gray-400">No policies.</p>
          )}
        </div>
      )}
    </div>
  );
};
