import { useApolloClient } from '@apollo/client';
import * as React from 'react';

import { Action, PermissionRoleFragmentDoc, PermissionTagFragment } from '../../generated/graphql';
import { truthyBoolean } from '../../utils';
import { Tooltip, TooltipProps } from '../tooltip';
import { COLOR } from '../v2/configs/SX';
import Chip from '../v2/display/Chip';

interface Props extends Pick<TooltipProps, 'placement' | 'children'> {
  tag: PermissionTagFragment;
}

export const AccessControlTagInfo = ({ tag, placement, children }: Props) => {
  const client = useApolloClient();

  const policyActions =
    tag.policy.policyActions
      .map(policyAction => ({
        action: policyAction.action,
        roleNames: policyAction.roleIDs
          .map(
            id =>
              client.readFragment({
                id: `PermissionRole:${id}`,
                fragment: PermissionRoleFragmentDoc
              })?.name
          )
          .filter(truthyBoolean)
          .sort((a, b) => a.localeCompare(b))
      }))
      .filter(item => item.roleNames.length > 0)
      .sort((a, b) => a.action.localeCompare(b.action)) || [];

  return (
    <Tooltip
      removePadding={true}
      offset={[0, placement === 'right' ? 12 : 8]}
      className="w-full max-w-sm"
      placement={placement}
      content={
        <BuiltInTag isBuiltIn={tag.system}>
          <PermissionActions actions={policyActions} />
        </BuiltInTag>
      }
    >
      <span>{children}</span>
    </Tooltip>
  );
};

const BuiltInTag = ({ children, isBuiltIn }: { isBuiltIn: boolean; children: React.ReactNode }) => {
  return (
    <>
      {isBuiltIn && (
        <p className="px-4 pt-3 pb-2 text-xs leading-none text-amber-600">Built-in policy</p>
      )}
      {children}
    </>
  );
};

const PermissionActions = ({
  actions
}: {
  actions: Array<{ action: Action; roleNames: string[] }>;
}) => {
  return (
    <table className="min-w-full text-left">
      <tbody className="divide-y divide-gray-800">
        {actions.map(action => (
          <tr key={action.action}>
            <td className="px-4 pt-3 pb-2 align-top text-xs leading-none text-gray-400">
              {action.action.toLocaleUpperCase()}
            </td>
            <td className="flex flex-wrap gap-2 py-2 pr-4">
              {action.roleNames.map((name, idx) => (
                <Chip key={idx} color={COLOR.SECONDARY} disabled>
                  {name}
                </Chip>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
