import { JSONSchema4 } from 'json-schema';
import { useController } from 'react-hook-form';
import { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';

import { CompletionValue, ParameterValue, ParameterValueSource } from '../../generated/graphql';
import { DropdownIndicator, formatOptionLabel, selectStyles } from '../../utils';
import { EditPermission } from '../edit-permission';
import { DisabledSelect } from './disabled-select';
import { Label } from './label';

interface Props {
  item: JSONSchema4;
  promiseOptions?: (field: string, query?: string) => Promise<CompletionValue[]>;
  className?: string;
}

export function FormCompletionsSelect({ item, className, promiseOptions }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { field } = useController({ name: item.name });

  async function loadOptions(inputValue: string): Promise<CompletionValue[]> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const name: string = item.name.split('.')[1];
    if (promiseOptions) {
      return await promiseOptions(name, inputValue);
    }
    return [];
  }

  return (
    <div className={className}>
      <Label>{item.title}</Label>
      <EditPermission
        fallback={
          <DisabledSelect
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            valueLabel={field.value?.label as string | undefined}
          />
        }
      >
        <AsyncSelect<CompletionValue>
          filterOption={createFilter({ ignoreAccents: false })}
          components={{
            DropdownIndicator,
            ClearIndicator: null,
            IndicatorSeparator: null
          }}
          styles={selectStyles}
          value={field.value as CompletionValue}
          defaultOptions={true}
          getOptionValue={(option: CompletionValue): string => String(option.value)}
          getNewOptionData={(inputValue: string): ParameterValue => ({
            displayValue: inputValue,
            value: inputValue,
            source: ParameterValueSource.Literal
          })}
          loadOptions={loadOptions}
          formatOptionLabel={formatOptionLabel}
          onChange={field.onChange}
        />
      </EditPermission>
    </div>
  );
}
