import cx from 'clsx';
import * as React from 'react';
import { generatePath, Link } from 'react-router-dom';

import { FieldType } from '../generated/graphql';
import { addLineBreaks, fieldTypeIconName, routes } from '../utils';
import { Icon } from './Icon';

const divStyles = 'flex items-start space-x-2';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  theme?: 'default' | 'dark';
  logoId?: string;
  enrichmentLogoId?: string;
  modelName?: string;
  modelId?: string;
  columnName?: string;
  className?: string;
  type?: FieldType;
  options?: React.ReactNode;
  defaultStyles?: string;
  name?: string;
}

export const FieldDetail = React.memo<Props>(
  ({
    theme = 'default',
    logoId,
    enrichmentLogoId,
    modelName,
    modelId,
    columnName,
    className,
    type,
    options,
    defaultStyles = 'mt-0.5 py-2 px-3.25 bg-gray-100',
    name,
    ...props
  }) => {
    if (!modelName && !columnName) {
      return null;
    }

    const Detail = ({ text }: { text?: string }) => {
      if (!text) {
        return null;
      }
      return (
        <div className={divStyles}>
          {(enrichmentLogoId || logoId) && (
            <Icon match={enrichmentLogoId ?? logoId} className="h-4 w-4" />
          )}
          <span className="text-xs">{addLineBreaks(text)}</span>
        </div>
      );
    };

    return (
      <div
        className={cx(
          'animate-fadeIn space-y-1.25 rounded',
          theme === 'default' && ['text-gray-500', defaultStyles],
          theme === 'dark' && 'text-white',
          className
        )}
        {...props}
      >
        {!!name && <div className="text-xs">{name}</div>}
        <Detail text={columnName} />
        {type && (
          <div className={divStyles}>
            <Icon name={fieldTypeIconName(type)} className="h-4 w-4 " />
            <span className="text-xs">{type}</span>
          </div>
        )}
        {modelName && (
          <div className={divStyles}>
            {logoId && <Icon match={logoId} className="h-4 w-4" />}
            <span className="text-xs">
              {modelId ? (
                <Link
                  to={generatePath(routes.editModel, { fieldsetId: modelId })}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  {addLineBreaks(modelName)}
                </Link>
              ) : (
                modelName
              )}
            </span>
          </div>
        )}
        {options && <div className={divStyles}>{options}</div>}
      </div>
    );
  }
);

if (import.meta.env.MODE === 'development') {
  FieldDetail.displayName = 'FieldDetail';
}
