import { useFormContext, useWatch } from 'react-hook-form';
import { FormElements, Label, MyCombobox } from '~/components';
import { getSchemaAsList } from '~/utils';
import { OAuthMessage } from './OAuthMessage';
import type { FormBuilderProps } from './types';

const Options = {
  oauth: {
    label: 'OAuth',
    value: 'oauth',
    fields: ['domain', 'custom_api_limits']
  },
  apitoken: {
    label: 'Token',
    value: 'apitoken',
    fields: ['domain', 'email', 'api_token', 'custom_api_limits']
  }
};
const DefaultOption = Options['oauth'];
const AuthMethodKey = 'auth_method';

export function ZenDesk(props: FormBuilderProps) {
  const { control, setValue } = useFormContext();
  const auth = useWatch({ control, name: AuthMethodKey });
  const selected = Options[auth] || DefaultOption;

  const items = getSchemaAsList(props.schema).filter(item =>
    selected.fields.includes(item.name as string)
  );

  const handleSelect = (selected: { label: string; value: string; fields: string[] }) => {
    setValue(AuthMethodKey, selected.value);
  };

  return (
    <>
      <div>
        <Label>Authentication method</Label>
        <MyCombobox options={Object.values(Options)} value={selected} onChange={handleSelect} />
      </div>
      {selected.value === 'oauth' && <OAuthMessage {...props} />}
      {items.map(item => (
        <FormElements key={item.name as string} item={item} />
      ))}
    </>
  );
}
